<template>
    <div
        :class="$style.natlist"
        class="row"
    >
        <div class="col">
            <div
                class="d-none px-4"
                :class="[
                    $style.header,
                    {'d-xl-block': visible_rules.length}
                ]"
            >
                <div
                    class="row mx-xl-0"
                >
                    <div
                        v-translate
                        class="px-xl-0 col-1"
                    >
                        Name
                    </div>
                    <div
                        v-translate
                        class="col-1"
                    >
                        Protocol
                    </div>
                    <div
                        v-translate
                        class="col-2"
                    >
                        Public IP
                    </div>
                    <div
                        v-translate
                        class="col-2"
                    >
                        Public Port
                    </div>
                    <div
                        v-translate
                        class="col-2"
                    >
                        Private IP
                    </div>
                    <div
                        v-translate
                        class="col-2"
                    >
                        Private Port
                    </div>
                    <div
                        v-translate
                        class="col-1"
                    >
                        State
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <nat-item
                    v-for="(natrule, index) in visible_rules"
                    :key="index"
                    :natrule="natrule"
                    @delete_rule="deleteRule(natrule.index)"
                    @edit_rule="editRule(natrule)"
                    @toggle_activation="toggleActivation(natrule.index)"
                />
            </div>
            <div :class="$style.bottom">
                <PaginateVue
                    v-if="nb_pages > 1"
                    v-model="current_page"
                    :items-count="filtered_rules.length"
                    :items-per-page="page_size"
                />
            </div>
            <ComponentEmptySearch
                v-show="!visible_rules.length"
                size="small"
            >
                <template v-slot:title>
                    <div v-translate>
                        No result found
                    </div>
                </template>
                <template v-slot:text>
                    <div v-translate>
                        Please try again with an other search
                    </div>
                </template>
            </ComponentEmptySearch>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PaginateVue from '@/components/common/paginate.vue';
import {
    showSwalError,
    showToast,
} from '@/utils/utils';
import {
    actionsControl,
} from './utils/utils';
import NatItem from './nat-listitem.vue';
import ComponentEmptySearch from './component-empty-search.vue';

const AddNatRuleModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-addnatrule" */
    './modals/addnatrule.vue'
);

const PAGE_SIZE_DESKTOP = 5;
const PAGE_SIZE_MOBILE = 5;

export default {
    components: {
        NatItem,
        PaginateVue,
        ComponentEmptySearch,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        filterByStatus: {
            type: [String, Boolean],
            default: null,
        },
    },
    data() {
        return {
            current_page: 0,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconf',
            'capabilities',
        ]),
        rules() {
            if (!this.linkequipmentconf || !this.linkequipmentconf) {
                return [];
            }
            return this.linkequipmentconf.nat || [];
        },
        page_size() {
            if (this.$mq !== 'xs' && this.$mq !== 'sm' && this.$mq !== 'md') {
                return PAGE_SIZE_DESKTOP;
            }
            return PAGE_SIZE_MOBILE;
        },
        nb_pages() {
            return Math.ceil(this.filtered_rules.length / this.page_size);
        },
        filtered_rules() {
            // Saving original indexes for later manipulations
            let filteredRules = this.rules.map((item, index) => {
                const filtered = JSON.parse(JSON.stringify(item));
                filtered.index = index;
                return filtered;
            });

            if (filteredRules.length) {
                const _this = this;

                const matchesSearch = (str) => {
                    if (!str) {
                        return false;
                    }
                    if (typeof str === 'object') {
                        str = str.join(',');
                    }
                    return str.toLowerCase().indexOf(_this.search.toLowerCase()) !== -1;
                };

                filteredRules = filteredRules.filter((item) => {
                    if (item.enabled !== _this.filterByStatus && _this.filterByStatus !== 'all') {
                        return false;
                    }
                    return (
                        matchesSearch(item.name)
                        || matchesSearch(item.source_ips)
                        || matchesSearch(item.public_destination.ip)
                        || matchesSearch(item.public_destination.ports)
                        || matchesSearch(item.lan_destination.ip)
                        || matchesSearch(item.lan_destination.ports));
                });
            }
            return filteredRules;
        },
        visible_rules() {
            if (this.current_page > (this.nb_pages - 1)) {
                this.setPage(this.nb_pages - 1);
            }

            return this.filtered_rules.slice(
                this.current_page * this.page_size,
                (this.current_page + 1) * this.page_size,
            );
        },
        canAddMultiIPsSourceOnNatRule() {
            let canAddMultiIPsSourceOnNatRule = true;

            this.capabilities.forEach((item) => {
                canAddMultiIPsSourceOnNatRule = canAddMultiIPsSourceOnNatRule && item.capabilities.includes('can_nat_multiple_source_ips');
            });

            return canAddMultiIPsSourceOnNatRule;
        },
    },
    mounted() {
        this.fetch_linkequipmentconf().catch(showSwalError);
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_linkequipmentconf',
            'set_conf_section',
        ]),
        setPage(page) {
            if (page < 0) page = 0;
            this.current_page = page;
        },
        toggleActivation(index) {
            actionsControl(this.linkequipment).then(() => {
                this.rules[index].enabled = !this.rules[index].enabled;
                this.set_conf_section({
                    section: 'nat',
                    conf: this.rules,
                }).then(() => {
                    showToast(this.$gettext('Nat updated'));
                }).catch(showSwalError);
            }).catch(showSwalError);
        },
        deleteRule(index) {
            actionsControl(this.linkequipment).then(() => {
                this.rules.splice(index, 1);
                this.set_conf_section({
                    section: 'nat',
                    conf: this.rules,
                }).then(() => {
                    showToast(this.$gettext('Nat deleted'));
                }).catch(showSwalError);
            }).catch(showSwalError);
        },
        editRule(natRule) {
            const { canAddMultiIPsSourceOnNatRule } = this;
            this.show_modal(
                AddNatRuleModal,
                {
                    'default-rule': natRule,
                    'is-new-rule': false,
                    canAddMultiIPsSource: canAddMultiIPsSourceOnNatRule,
                },
                {
                    maxHeight: 702,
                },
            );
        },
    },
};
</script>

<style lang="scss" module>
.natlist {
    .header {
        margin-top: 26px;
        margin-bottom: 20px;
        font-size: 12px;
        white-space: nowrap;

        @include themify($themes) {
            color: themed('main_secondary_color');
        }
    }

    .bottom {
        margin-top: 10px;
    }
}

.main {
    .natlist {
        .body2 {
            font-weight: normal;

            @include themify($themes) {
                color: themed("gray_scale_dark");
            }
        }
    }
}
</style>
