var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.lansection }, [
    _c("div", { class: _vm.$style.lanitem_wrapper }, [
      _c(
        "div",
        { class: _vm.$style.header },
        [
          _c("span", { class: _vm.$style.body6 }, [
            _vm._v(
              "\n                LAN " +
                _vm._s(_vm.indexLan + 1) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "ml-2 mr-auto ml-lg-3",
              class: _vm.$style.placeholder1
            },
            [
              _vm._v(
                "\n                " + _vm._s(_vm.lan.name) + "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c("Button", {
            class: _vm.$style.b1,
            attrs: {
              role: "link",
              text: _vm.$gettext("Delete this LAN"),
              icon: "error",
              size: "large"
            },
            on: {
              click: function($event) {
                return _vm.$emit("remove")
              }
            }
          }),
          _vm._v(" "),
          _c("span", { class: _vm.$style.separate }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.$style.hidearrow,
                ((_obj = {}), (_obj[_vm.$style.bottom] = _vm.collapsed), _obj)
              ],
              on: {
                click: function($event) {
                  return _vm.toggleCollapse()
                }
              }
            },
            [_c("MasterSvg", { attrs: { use: "arrow", width: "20px" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapsed,
              expression: "!collapsed"
            }
          ],
          class: _vm.$style.categories
        },
        [
          _c(
            "li",
            {
              staticClass: "nav-item",
              class: [
                _vm.$style.category,
                ((_obj$1 = {}),
                (_obj$1[_vm.$style.active] = _vm.active_category === "network"),
                _obj$1),
                ((_obj$2 = {}),
                (_obj$2[_vm.$style.error] = _vm.not_empty_object(
                  _vm.network_errors
                )),
                _obj$2)
              ],
              attrs: { section: "network" },
              on: {
                click: function($event) {
                  return _vm.change_category("network")
                }
              }
            },
            [
              _c(
                "div",
                { class: _vm.$style.category_title },
                [
                  _c("translate", [_vm._v("Network")]),
                  _vm._v(" "),
                  _vm.not_empty_object(_vm.network_errors)
                    ? _c(
                        "div",
                        { class: _vm.$style.warning },
                        [_c("MasterSvg", { attrs: { use: "warningicon" } })],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.equipmentsConfigurations || [], function(
            equipment_configurations,
            index
          ) {
            var _obj, _obj$1
            return _c(
              "li",
              {
                key: index,
                staticClass: "nav-item",
                class: [
                  _vm.$style.category,
                  ((_obj = {}),
                  (_obj[_vm.$style.active] =
                    (_vm.active_category === "port",
                    equipment_configurations.link_id ===
                      _vm.equipment_link_id)),
                  _obj),
                  ((_obj$1 = {}),
                  (_obj$1[_vm.$style.error] = _vm.not_empty_object(
                    _vm.lan_equipment_errors[index]
                  )),
                  _obj$1)
                ],
                attrs: { section: "port" + index },
                on: {
                  click: function($event) {
                    return _vm.change_category(
                      "port",
                      equipment_configurations.link_id,
                      index
                    )
                  }
                }
              },
              [
                _c("div", { class: _vm.$style.category_title }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.get_port_section_label(index)))
                  ]),
                  _vm._v(" "),
                  _vm.not_empty_object(_vm.lan_equipment_errors[index])
                    ? _c(
                        "div",
                        { class: _vm.$style.warning },
                        [_c("MasterSvg", { attrs: { use: "warningicon" } })],
                        1
                      )
                    : _vm._e()
                ])
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.lan.addressing, function(addressing_block, index) {
            var _obj, _obj$1
            return _c(
              "li",
              {
                key: _vm.indexLan + "-" + addressing_block.ip_version,
                staticClass: "nav-item",
                class: [
                  _vm.$style.category,
                  ((_obj = {}),
                  (_obj[_vm.$style.active] =
                    _vm.selectedIpVersion === addressing_block.ip_version),
                  _obj),
                  ((_obj$1 = {}),
                  (_obj$1[_vm.$style.error] = _vm.not_empty_object(
                    _vm.optionsErrors[index]
                  )),
                  _obj$1)
                ],
                attrs: { section: "options" },
                on: {
                  click: function($event) {
                    return _vm.change_category(
                      "options",
                      null,
                      0,
                      addressing_block.ip_version
                    )
                  }
                }
              },
              [
                _c("div", { class: _vm.$style.category_title }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.get_options_section_label(
                          addressing_block.ip_version
                        )
                      ) +
                      "\n                    "
                  ),
                  _vm.not_empty_object(_vm.optionsErrors[index])
                    ? _c(
                        "div",
                        { class: _vm.$style.warning },
                        [_c("MasterSvg", { attrs: { use: "warningicon" } })],
                        1
                      )
                    : _vm._e()
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapsed,
              expression: "!collapsed"
            }
          ],
          class: _vm.$style.lanform
        },
        [
          _vm.render_component
            ? _c(_vm.currentTabComponent, {
                tag: "component",
                attrs: {
                  lan: _vm.lan,
                  addressing: _vm.computedAddressing,
                  "ip-version": _vm.selectedIpVersion,
                  errors: _vm.current_errors,
                  "index-lan": _vm.indexLan,
                  "lan-equipment-configurations":
                    _vm.lan_equipment_configurations,
                  "selected-interfaces": _vm.selected_interfaces_equipment,
                  "equipment-link-id": _vm.equipment_link_id,
                  "wan-choices": _vm.wanChoices
                },
                on: { "update-main-exit-wan": _vm.handleUpdateMainExitWan }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }