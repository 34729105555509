import Vue from 'vue';

const state = {
    items: [],
    start_date: null,
    end_date: null,
    errors: [],
    loading: false,
};

const getters = {
    items: state => state.items,
    start_date: state => state.start_date,
    end_date: state => state.end_date,
    errors: state => state.errors,
    loading: state => state.loading,
};

const mutations = {
    update_items(state, items) {
        state.items = items;
    },
    add_item(state, item) {
        state.items.push(item);
    },
    update_start_date(state, startDate) {
        state.start_date = startDate;
        state.errors = [];
        state.items = [];
    },
    update_end_date(state, endDate) {
        state.end_date = endDate;
        state.errors = [];
        state.items = [];
    },
    update_errors(state, items) {
        state.items = items;
    },
    add_error(state, error) {
        state.errors.push(error);
    },
    update_loading(state, loading) {
        state.loading = loading;
    },
};

const UNEXPECTED_ERROR = Vue.prototype.$gettext('An unexpected error happened');
const UNEXPECTED_ERROR_CALLBACK_OBJECT = {
    success: false,
    errors: {
        non_field_errors: [UNEXPECTED_ERROR],
    },
    error: UNEXPECTED_ERROR,
};

const fiberProviders = [
    'altitude', 'asc', 'axione', 'bouygues telecom', 'covage', 'eurofiber', 'gigalis',
    'ielo liazo', 'kosc', 'laval thd', 'leonix telecom', 'liain',
    'loire atlantique numerique', 'orange', 'pacwan', 'sfr', 'sipartech', 'vendee numerique',
];

const copperProviders = [
    'axione', 'bouygues telecom', 'kosc', 'nerim', 'pacwan', 'sfr',
];

const gtrCodes = [
    '4h_24_7', '4h_bhbd', 'AVANCE', 'BASIQUE', 'GTR 4h HNO 24/7 SDSL', 'GTR 4h HO 5/7 SDSL',
    'GTR S1', 'GTR S2', 'GTR10HO', 'GTR4HO', 'NORMAL', 'SLA_LIGNE', 'SLA_PLUS',
];

const noGtrCodes = [
    'NOGTR', '',
];

const legacyIpLinksBaseUrl = '/api/legacy/iplinks/';

const orangeAdslFilter = {
    queryparams: 'offer_provider_in=orange&offer_type=ADSL',
    label: Vue.prototype.$gettext('ADSL (Orange)'),
};

const othersAdslFilter = {
    queryparams: `offer_provider_in=${copperProviders.join(',')}&offer_type=ADSL`,
    label: Vue.prototype.$gettext('ADSL (other providers)'),
};

const orangeVdslFilter = {
    queryparams: 'offer_provider_in=orange&offer_type=VDSL',
    label: Vue.prototype.$gettext('VDSL (Orange)'),
};

const orangeSdslFilter = {
    queryparams: `offer_provider_in=orange&offer_type=SDSL&gtr_in=${gtrCodes.join(',')}`,
    label: Vue.prototype.$gettext('SDSL (Orange with GTR)'),
};

const othersSdslWithGtrFilter = {
    queryparams: `offer_provider_in=${copperProviders.join(',')}&
offer_type=SDSL&gtr_in=${gtrCodes.join(',')}`,
    label: Vue.prototype.$gettext('SDSL (other providers with GTR)'),
};

const othersSdslWitouthGtrFilter = {
    queryparams: `offer_provider_in=${copperProviders.join(',')}&
offer_type=SDSL&gtr_in=${noGtrCodes.join(',')}`,
    label: Vue.prototype.$gettext('SDSL (other providers without GTR)'),
};

const fiberWithGtrFilter = {
    queryparams: `offer_provider_in=${fiberProviders.join(',')}&
offer_type=Fibre&gtr_in=${gtrCodes.join(',')}`,
    label: Vue.prototype.$gettext('Fiber (with GTR)'),
};

const fiberWithoutGtrFilter = {
    queryparams: `offer_provider_in=${fiberProviders.join(',')}&
offer_type=Fibre&gtr_in=${noGtrCodes.join(',')}`,
    label: Vue.prototype.$gettext('Fiber (without GTR)'),
};

const bsod30mWithoutGtrFilter = {
    queryparams: 'offer_type=BSOD&gtr=NOGTR&offer_download_rate=30',
    label: Vue.prototype.$gettext('BSOD 30M (without GTR)'),
};

const bsodGte100mWithoutGtrFilter = {
    queryparams: 'offer_type=BSOD&gtr=NOGTR&offer_download_rate_gte=100',
    label: Vue.prototype.$gettext('BSOD >= 100M (without GTR)'),
};

const bsodGte100mWithGtrFilter = {
    queryparams: 'offer_type=BSOD&gtr=GTR4HO&offer_download_rate_gte=100',
    label: Vue.prototype.$gettext('BSOD >= 100M (with GTR)'),
};

const satelliteFilter = {
    queryparams: 'offer_type=Satellite',
    label: Vue.prototype.$gettext('Satellite'),
};


const lowerThan30m = {
    queryparams: 'offer_download_rate_lt=30',
    label: Vue.prototype.$gettext('Lower than 30M'),
};

const greaterThanOrEqual30m = {
    queryparams: 'offer_download_rate_gte=30',
    label: Vue.prototype.$gettext('Greater than or equal 30M'),
};

const filters = [
    orangeAdslFilter, othersAdslFilter, orangeVdslFilter, orangeSdslFilter,
    othersSdslWithGtrFilter, othersSdslWitouthGtrFilter, fiberWithGtrFilter, fiberWithoutGtrFilter,
    bsod30mWithoutGtrFilter, bsodGte100mWithoutGtrFilter, bsodGte100mWithGtrFilter, satelliteFilter,
    lowerThan30m, greaterThanOrEqual30m,
];

const actions = {

    async load_from_url({ state, commit, rootState }, params) {
        if (params.first) {
            commit('update_loading', true);
        }

        rootState.axios_api.get(params.url)
            .then((response) => {
                commit('add_item', {
                    label: params.val.label,
                    count: response.data.count,
                });

                if (params.last) {
                    commit('update_loading', false);
                }
            })
            .catch((error) => {
                // Fixed error displayed on Internal Server Error. refs #32
                if (error.response.status === 500) {
                    commit('add_error', {
                        key: 'error',
                        label: error.response.statusText,
                    });
                } else {
                    Object.entries(error.response.data).forEach((entry) => {
                        const found = state.errors.find(element => element.key === entry[0]);

                        // Add error only if this error was not stored yet
                        if (!found) {
                            commit('add_error', {
                                key: entry[0],
                                label: entry[1][0],
                            });
                        }
                    });
                }

                if (params.last) {
                    commit('update_loading', false);
                }

                if (!params || !params.callback) {
                    return;
                }

                params.callback(UNEXPECTED_ERROR_CALLBACK_OBJECT);
            });
    },

    async load_sells_by_range({ state, commit, dispatch }) {
        commit('update_items', []);
        commit('update_errors', []);

        filters.map((value, index) => dispatch('load_from_url', {
            url: `${legacyIpLinksBaseUrl}count/?${value.queryparams}&
delivery_date_gte=${state.start_date}&delivery_date_lte=${state.end_date}`,
            val: value,
            first: !index,
            last: index === filters.length - 1,
        }));
    },

    async load_sells_by_date({ state, commit, dispatch }) {
        commit('update_items', []);
        commit('update_errors', []);

        filters.map((value, index) => dispatch('load_from_url', {
            url: `${legacyIpLinksBaseUrl}count/?${value.queryparams}&
delivery_date_lte=${state.start_date}&termination_date_gte=${state.start_date}`,
            val: value,
            first: !index,
            last: index === filters.length - 1,
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
