var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.little_card,
        ((_obj = {}), (_obj[_vm.$style.selected] = _vm.selected), _obj),
        ((_obj$1 = {}), (_obj$1[_vm.$style.disabled] = _vm.disabled), _obj$1)
      ],
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.click.apply(null, arguments)
        }
      }
    },
    [
      _vm.icon
        ? _c(
            "div",
            { class: _vm.$style.icon },
            [
              _c("MasterSvg", {
                attrs: { use: _vm.icon, width: "60px", height: "auto" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.$style.content }, [
        _c("span", { class: _vm.$style.title }, [
          _vm._v("\n            " + _vm._s(_vm.title) + "\n        ")
        ]),
        _vm._v(" "),
        _c("span", { class: _vm.$style.description }, [
          _vm._v("\n            " + _vm._s(_vm.description) + "\n        ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }