<template>
    <div :class="$style.lanSectionOptions">
        <SLAACConfiguration
            v-if="addressing.ip_version === 6"
            :addressing="addressing"
            :errors="slaacErrors"
        />
        <hr
            v-if="addressing.ip_version === 6"
            :class="$style.separator"
        >
        <DHCPConfiguration
            :addressing="addressing"
            :errors="dhcpErrors"
            :disabled="disbaledDhcpConfig"
        />
    </div>
</template>

<script>
import DHCPConfiguration from './lans/lan-item-option-dhcp.vue';
import SLAACConfiguration from './lans/lan-item-option-slaac.vue';

export default {
    components: {
        DHCPConfiguration,
        SLAACConfiguration,
    },
    props: {
        addressing: {
            type: Object,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        disbaledDhcpConfig: false,
    }),
    computed: {
        dhcpErrors() {
            return (this.errors || {}).dhcp;
        },
        slaacErrors() {
            return (this.errors || {}).slaac;
        },
    },
    watch: {
        'addressing.slaac.enabled': {
            handler(value) {
                this.disbaledDhcpConfig = !value;
                if (!value) {
                    this.addressing.dhcp.enabled = false;
                }
            },
        },
    },
    mounted() {
        const { addressing } = this;
        if (addressing.ip_version === 6) {
            this.disbaledDhcpConfig = !addressing.slaac.enabled;
        }
    },
};
</script>

<style lang="scss" module>
.lanSectionOptions {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: inherit;

    .separator {
        @include themify($themes) {
            margin: 0;
            border-top: 1px solid themed('grayscale_extralight');
        }
    }
}
</style>
