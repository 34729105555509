<template>
    <!-- eslint-disable max-len -->
    <HeaderAdviceLayout
        :advice-display.sync="adviceDisplay"
        :class="$style.snmpCommunitiesSection"
    >
        <!-- Advice -->
        <template v-slot:advice-title>
            <translate>SNMP communities</translate>
        </template>
        <template v-slot:advice-content>
            <translate>
                Communication protocol that allows network administrators to manage network equipment, monitor and diagnose network and hardware issues remotely.
            </translate>
            <translate>
                Fill in an access name and for more security you can add IP address of your server.
            </translate>
        </template>

        <!-- Updated section block -->
        <BannerUpdatedSection
            v-if="isUpdated"
            :disabled="readonlyMode"
        />

        <!-- Content -->
        <TitleWithAdviceState v-model="adviceDisplay">
            <template #before>
                <div
                    :class="$style.goBack"
                    @click="closeSection"
                >
                    <MasterSvg
                        use="arrow-left"
                        width="17.5px"
                        height="15px"
                    />
                </div>
            </template>
            <translate>SNMP communities</translate>
            <template #after>
                <DesktopAndMobileButton
                    role="primary"
                    icon="add"
                    :disabled="readonlyMode"
                    :text="$gettext('Add community')"
                    @click="addCommunity"
                />
            </template>
        </TitleWithAdviceState>

        <!-- Steps -->
        <StepsList
            v-if="!communities.length"
            image="snmp"
        >
            <template #step-1>
                <translate>
                    To add an SNMP community click on "Add community"
                </translate>
            </template>
            <template #step-2>
                <translate>
                    Add a community name
                </translate>
            </template>
            <template #step-3>
                <translate>
                    For more security add IP address or subnet of your server
                </translate>
            </template>
        </StepsList>

        <!-- List -->
        <SnmpCommunitiesList
            v-else
            :communities="communities"
            :form-errors="formErrors"
            :readonly-mode="readonlyMode"
            @remove="removeCommunity"
        />

        <!-- Submit modifications -->
        <SubmitButtons
            v-if="isModified"
            :disabled="readonlyMode"
            @cancel="initLinkEquipmentConfSnmpCommunities"
            @submit="validate"
        />
    </HeaderAdviceLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { showSwal, showSwalError } from '@/utils/utils';

import MasterSvg from '@/components/common/master-svg.vue';

import { actionsControl } from '../../utils/utils';

import BannerUpdatedSection from '../../common/banner-updated-section.vue';
import DesktopAndMobileButton from '../../common/desktop-and-mobile-button.vue';
import HeaderAdviceLayout from '../../layout/header-advice-layout.vue';
import StepsList from '../../common/steps-list.vue';
import SubmitButtons from '../../common/submit-buttons.vue';
import TitleWithAdviceState from '../../common/title-with-advice-state.vue';

import SnmpCommunitiesList from './snmp-communities-list.vue';

import AdviceMixin from '../../mixins/advice.js';

export default {
    components: {
        BannerUpdatedSection,
        DesktopAndMobileButton,
        HeaderAdviceLayout,
        MasterSvg,
        SnmpCommunitiesList,
        StepsList,
        SubmitButtons,
        TitleWithAdviceState,
    },
    mixins: [
        AdviceMixin,
    ],
    props: {
        readonlyMode: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        adviceId: 'snmp_communities',
        communities: [],
        formErrors: [],
    }),
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconf',
            'updated_sections',
        ]),
        isModified() {
            if (!this.linkequipmentconf) return false;

            return JSON.stringify(this.communities) !== JSON.stringify(
                this.linkequipmentconf.snmp_communities || [],
            );
        },
        isUpdated() {
            return (this.updated_sections || []).includes('snmp_communities');
        },
    },
    watch: {
        linkequipmentconf: {
            handler() {
                this.initLinkEquipmentConfSnmpCommunities();
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.fetch_linkequipmentconf().catch(showSwalError);
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_linkequipmentconf',
            'set_conf_section',
        ]),
        closeSection() {
            this.$emit('close-section');
        },
        addCommunity() {
            this.communities.push({});
            this.formErrors.push({});
        },
        removeCommunity(index) {
            this.communities.splice(index, 1);
            this.formErrors.splice(index, 1);
        },
        initLinkEquipmentConfSnmpCommunities() {
            if (!this.linkequipmentconf) return;

            this.communities = JSON.parse(JSON.stringify(
                this.linkequipmentconf.snmp_communities || [],
            ));

            this.formErrors = this.communities.map(() => ({}));
        },
        handleFormErrors(result) {
            this.formErrors = result.errors.snmp_communities || [];

            let content = `<div class=${this.$style.sweeterrors}><ul>`;
            if (this.formErrors.length) {
                content += `<li>${this.$gettext('Fields errors, check the form again')}</li>`;
            }

            (result.errors.non_field_errors || []).forEach((error) => {
                content += `<li>${error}</li>`;
            });
            content += '</ul></div>';

            showSwal({
                title: this.$gettext('SNMP configuration save failed'),
                html: content,
                type: 'error',
                confirmButtonText: this.$gettext('Edit configuration'),
                showCloseButton: true,
            });
        },
        handleSuccess() {
            showSwal({
                title: this.$gettext('SNMP configuration saved'),
                text: this.$gettext('You must deploy configuration in order to apply modifications on equipment'),
                type: 'success',
                showCancelButton: true,
                confirmButtonText: this.$gettext('Deploy now'),
                cancelButtonText: this.$gettext('Later'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) return;

                this.$emit('deploy-configuration');
            });
        },
        validate() {
            actionsControl(this.linkequipment).then(() => {
                this.set_conf_section({
                    section: 'snmp_communities',
                    conf: this.communities,
                }).then(
                    this.handleSuccess,
                ).catch(
                    this.handleFormErrors,
                );
            }).catch(showSwalError);
        },
    },
};
</script>

<style lang="scss" module>
.snmpCommunitiesSection {
    .goBack {
        display: flex;
        align-self: center;
        width: 17.5px;
        height: 15px;
        cursor: pointer;

        @include themify($themes) {
            fill: themed('primary_color');
        }
    }
}
</style>
