<template>
    <ListItem
        :class="$style.connectedDevicesTableItem"
        breakpoint="xl"
    >
        <span
            :style="{'font-weight': 'bold', 'text-transform': 'uppercase'}"
        >
            {{ connectedDevice.mac_address }}
        </span>
        <span>{{ connectedDevice.hostname || ' ' }}</span>
        <span v-if="canIPv4">{{ ipv4Address || $gettext('No IP address') }}</span>
        <OneOrMore
            v-if="canIPv6"
            :values="ipv6Addresses"
        >
            <template #empty-value>
                <translate>No IP address</translate>
            </template>
        </OneOrMore>
        <span>{{ connectedDevice.interface || ' ' }}</span>
        <template #after>
            <DropdownMenu
                v-if="canAddNatRule || canFirewallRule"
                position="right"
                size="large"
            >
                <DropdownMenuItem
                    v-if="canAddNatRule"
                    icon="key"
                    :name="$gettext('Create NAT rule')"
                    @click="$emit('create_nat_rule', ipv4Address)"
                />
                <DropdownMenuItem
                    v-if="canFirewallRule"
                    icon="wan2lan"
                    :name="$gettext('Create Firewall rule')"
                    @click="$emit('create_firewall_rule', ipv6AddressToUseInFirewallRule)"
                />
            </DropdownMenu>
            <div
                v-else
                :style="{ width: '14px' }"
            />
        </template>
    </ListItem>
</template>

<script>
import { mapGetters } from 'vuex';
import ListItem from '@/components/common/list-item.vue';
import OneOrMore from '@/components/common/one-or-more.vue';
import SpecificationsMixin from './mixins/specifications';

import DropdownMenu from './dropdown-menu.vue';
import DropdownMenuItem from './dropdown-menu-item.vue';

const IPCIDR = require('ip-cidr');

export default {
    components: {
        ListItem,
        OneOrMore,
        DropdownMenu,
        DropdownMenuItem,
    },
    mixins: [
        SpecificationsMixin,
    ],
    props: {
        connectedDevice: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'capabilities',
            'linkequipmentconf',
        ]),
        ipv4Address() {
            return this.connectedDevice.ip_addresses.find(
                ipAddress => IPCIDR.createAddress(ipAddress).v4,
            );
        },
        ipv6Addresses() {
            return this.connectedDevice.ip_addresses.filter(
                ipAddress => !IPCIDR.createAddress(ipAddress).v4,
            );
        },
        ipv6AddressToUseInFirewallRule() {
            let ipAddressToUse = null;
            this.ipv6Addresses.forEach((ipAddress) => {
                if (!ipAddressToUse) {
                    ipAddressToUse = this.ipIncludeInLan(ipAddress);
                }
            });

            return ipAddressToUse;
        },
        canAddNatRule() {
            let canAddNatRule = true;

            this.capabilities.forEach((item) => {
                canAddNatRule = canAddNatRule && item.capabilities.includes('nat');
            });

            return canAddNatRule && !this.iplink.mpls && this.ipIncludeInLan(this.ipv4Address);
        },
        canFirewallRule() {
            let canFirewallRule = true;

            this.capabilities.forEach((item) => {
                canFirewallRule = canFirewallRule && item.capabilities.includes('firewall');
            });

            return canFirewallRule && this.ipIncludeInLan(this.ipv6AddressToUseInFirewallRule);
        },
    },
    methods: {
        ipIncludeInLan(ipAddress) {
            if (!this.linkequipmentconf) {
                return false;
            }
            const foundLan = (this.linkequipmentconf.lans || []).find((lan) => {
                const foundAddressing = (lan.addressing || []).find((addressing) => {
                    const block = new IPCIDR(`${addressing.ip}/${addressing.netmask}`);
                    if (block.contains(ipAddress)) {
                        return true;
                    }
                    if (addressing.secondary_ip) {
                        const secondaryBlock = new IPCIDR(
                            `${addressing.secondary_ip}/${addressing.secondary_netmask}`,
                        );
                        if (secondaryBlock.contains(ipAddress)) {
                            return true;
                        }
                    }
                    return false;
                });
                return !!foundAddressing;
            });
            return foundLan ? ipAddress : null;
        },
    },
};
</script>

<style lang="scss" module>
.connectedDevicesTableItem {
    margin-top: 15px;
}
</style>
