<template>
    <div :class="$style.firewallsection">
        <div :class="$style.groups_firewall">
            <LittleCard
                v-for="group in groupsFirewall"
                :key="group.category"
                :title="group.title"
                :description="group.description"
                :icon="group.icon"
                :disabled="!!group.disabled"
                :selected="current_group === group"
                @click="change_current_group(group)"
            />
        </div>

        <SlideTransition>
            <div v-if="current_group">
                <Advice
                    :title="current_group.title"
                    :subtitle="current_group.subtitle"
                    :can-be-closed="current_group.can_close_advise"
                    link-url="https://kiosque.unyc.io/document/administration-des-routeurs/pare-feu-adr/"
                >
                    <!-- eslint-disable max-len -->
                    <template v-slot:text>
                        <template v-if="current_group.category == 'dmz'">
                            <p v-translate>
                                A DMZ (or demilitarized zone) is an isolated subnetwork, separated from the local network (LAN) and the Internet by a firewall.
                            </p>
                            <p v-translate>
                                The firewall rules must be declared on the router positioned in the DMZ.
                            </p>
                        </template>
                        <template v-else>
                            <p v-translate>
                                Firewall is an essential element in the security of a computer network.
                            </p>
                            <p>
                                <translate>
                                    A firewall is a filter gateway that protects a computer or computer network from intrusion from a third party. This firewall makes it possible to filter the data packets exchanged with the network according to various criteria:
                                </translate>
                                <ul :class="$style.list_items">
                                    <li v-translate>
                                        Source / Destination IP address
                                    </li>
                                    <li v-translate>
                                        Destination port number
                                    </li>
                                </ul>
                            </p>
                            <p v-if="current_group.category == 'lan2wan'">
                                <b v-translate>
                                    Did you know : To block all destination ips you will have to enter 0.0.0.0/0 in the field.
                                </b>
                            </p>
                            <p v-else-if="current_group.category == 'wan2lan' && iplink.mpls">
                                <b v-translate>
                                    Did you know : To block all source ips you will have to enter 0.0.0.0/0 in the field.
                                </b>
                            </p>
                        </template>
                    </template>
                    <template
                        v-if="current_group.category != 'dmz'"
                        v-slot:example
                    >
                        <template v-if="current_group.category == 'lan2lan'">
                            <p v-translate>
                                Exemple: You want block communications between LAN 1 (192.168.0.0/24) and LAN 2 (192.168.100.0/24). You will need to set the following rule:
                            </p>
                            <ul>
                                <li v-translate="{name_field: current_group.add_modal.label_source_ip}">
                                    %{name_field}: 192.168.0.0/24
                                </li>
                                <li v-translate="{name_field: current_group.add_modal.label_destination_ip}">
                                    %{name_field}: 192.168.100.0/24
                                </li>
                            </ul>
                        </template>
                        <template v-else-if="current_group.category == 'lan2wan'">
                            <p v-translate>
                                Exemple: You want block communications from LAN 1 (192.168.0.0/24) to 32.65.32.15 IP address. You will need to set the following rule:
                            </p>
                            <ul>
                                <li v-translate="{name_field: current_group.add_modal.label_source_ip}">
                                    %{name_field}: 192.168.0.0/24
                                </li>
                                <li v-translate="{name_field: current_group.add_modal.label_destination_ip}">
                                    %{name_field}: 32.65.32.15
                                </li>
                            </ul>
                        </template>
                        <template v-else-if="current_group.category == 'wan2lan' && iplink.mpls">
                            <p v-translate>
                                Exemple: You want block communications from everyone to LAN 1 (192.168.0.0/24). You will need to set the following rule:
                            </p>
                            <ul>
                                <li v-translate="{name_field: current_group.add_modal.label_source_ip}">
                                    %{name_field}: 0.0.0.0/0
                                </li>
                                <li v-translate="{name_field: current_group.add_modal.label_destination_ip}">
                                    %{name_field}: 192.168.0.0/24
                                </li>
                            </ul>
                        </template>
                    </template>
                </Advice>
                <UpdatedSection
                    v-if="current_group.can_add_rule"
                    section="firewall"
                />

                <div v-if="containsRules && current_group.can_add_rule">
                    <div class="row mt-3 mb-4">
                        <Title
                            :title="current_group.title_section"
                        />
                        <Button
                            role="primary"
                            :text="current_group.button"
                            :disabled="current_group.disabled_button || false"
                            size="large"
                            :class="[
                                $style.rightbutton,
                                {[$style.flyinginfotop]: current_group.info_button}
                            ]"
                            :data-content="current_group.info_button"
                            class="col-4 ml-auto d-none d-lg-flex"
                            @click="addFirewallRule()"
                        />
                        <Button
                            role="icon_primary"
                            icon="add"
                            :class="$style.bottombutton"
                            :disabled="current_group.disabled_button || false"
                            class="d-lg-none"
                            @click="addFirewallRule()"
                        />
                    </div>

                    <div
                        class="row"
                        :class="$style.search"
                    >
                        <Field
                            v-model="search"
                            :placeholder="$gettext('Search for a firewall rule')"
                            :class="$style.searchField"
                            name="search"
                            size="small"
                            icon-left="search"
                        />
                        <Select
                            v-model="filteringByStatus"
                            role="small"
                            name="status"
                            :class="$style.selectField"
                            :data-list="statusFiltersValues"
                        />
                        <!-- table filters -->
                        <ComponentSimpleFilters
                            v-if="IpVersions.length > 1"
                            v-model="selectedIpVersion"
                            :filter-choices="IpVersions"
                        />
                    </div>
                    <FirewallList
                        :search="search"
                        :filter-by-status="filteringByStatus"
                        :filter-by-ip-version="selectedIpVersion"
                        :group="current_group"
                    />
                </div>
                <div v-else-if="current_group.can_add_rule">
                    <Empty
                        :steps="emptysteps"
                        image="firewall"
                        :subtitle="current_group.title_section"
                        @link_clicked="addFirewallRule()"
                    />
                    <Button
                        role="primary"
                        :text="current_group.button"
                        :disabled="current_group.disabled_button || false"
                        :class="
                            {[$style.flyinginfotop]: current_group.info_button}
                        "
                        :data-content="current_group.info_button"
                        size="large"
                        class="mt-4 col-4 d-none d-lg-flex"
                        @click="addFirewallRule()"
                    />
                    <Button
                        role="icon_primary"
                        icon="add"
                        :class="$style.bottombutton"
                        :disabled="current_group.disabled_button || false"
                        class="d-lg-none"
                        @click="addFirewallRule()"
                    />
                </div>
            </div>
        </SlideTransition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/common/button.vue';
import Field from '@/components/common/field.vue';
import SlideTransition from '@/components/common/transitions/slide.vue';
import Select from '@/components/common/select.vue';
import Advice from './advice.vue';
import FirewallList from './firewall-list.vue';
import Empty from './component-empty.vue';
import Title from './component-title.vue';
import UpdatedSection from './component-updated-section.vue';
import LittleCard from './little-card.vue';
import ComponentSimpleFilters from './component-simple-filters.vue';
import SpecificationsMixin from './mixins/specifications';

const AddFirewallRuleModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-addfirewallrule" */
    './modals/addfirewallrule.vue'
);

export default {
    components: {
        FirewallList,
        Advice,
        Button,
        Empty,
        UpdatedSection,
        Field,
        Title,
        LittleCard,
        SlideTransition,
        ComponentSimpleFilters,
        Select,
    },
    mixins: [
        SpecificationsMixin,
    ],
    data() {
        return {
            filteringByStatus: 'all',
            selectedIpVersion: 'all',
            search: '',
            current_group: null,
            groupsFirewall: [
                {
                    title: this.$gettext('LAN → LAN'),
                    subtitle: this.$gettext('Communications allowed by default'),
                    description: this.$gettext('Block communications between your LANs'),
                    button: this.$gettext('Block new communication'),
                    title_section: this.$gettext('List of blocked communications'),
                    can_close_advise: true,
                    can_add_rule: true,
                    add_modal: {
                        title: this.$gettext('Block communications between your LANs'),
                        label_source_ip: this.$gettext('IPs/Subnets LAN'),
                        label_destination_ip: this.$gettext('IP/Subnet LAN'),
                    },
                    icon: 'lan2lan',
                    category: 'lan2lan',
                },
            ],
            emptysteps: [
                {
                    text: this.$gettext('Fill in all the fields correctly and validate by clicking on the button'),
                    textmobile: this.$gettext('Fill in all the fields correctly and validate by clicking on the button'),
                },
                {
                    text: this.$gettext('Do not forget to deploy the configuration for equipment to take new changes'),
                    textmobile: this.$gettext('Do not forget to deploy the configuration for equipment to take new changes'),
                },
            ],
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'linkequipmentconf',
        ]),
        containsRules() {
            let filteredRules = (this.linkequipmentconf && this.linkequipmentconf.firewall) || [];
            filteredRules = filteredRules.filter((item) => {
                if (this.current_group.category !== item.category) {
                    return false;
                }
                return true;
            });
            return filteredRules.length > 0;
        },
        IpVersions() {
            const choices = [];
            if (this.validIPVersions.includes(4)) {
                choices.push({ label: 'IPv4', value: 4 });
            }
            if (this.validIPVersions.includes(6)) {
                choices.push({ label: 'IPv6', value: 6 });
            }
            return choices;
        },
        statusFiltersValues() {
            return [
                {
                    id: 'all',
                    text: this.$gettext('Active and inactive rules'),
                    selected: 1,
                },
                {
                    id: 'active',
                    text: this.$gettext('Active rules'),
                },
                {
                    id: 'inactive',
                    text: this.$gettext('Inactive rules'),
                },
            ];
        },
    },
    watch: {
        current_group: {
            handler() {
                if (!this.current_group) {
                    return;
                }
                if (!this.current_group.can_add_rule) {
                    return;
                }


                if (this.emptysteps.length === 3) {
                    this.emptysteps.shift();
                }

                this.emptysteps.unshift({
                    text: this.$gettextInterpolate(
                        this.$gettext('Click on "%{button}"'),
                        { button: this.current_group.button },
                    ),
                    textmobile: this.$gettext('Click on "+" button'),
                });
            },
            immediate: true,
            depp: true,
        },
    },
    created() {
        [this.current_group] = this.groupsFirewall;

        if (this.iplink.mpls) {
            this.groupsFirewall.push(
                {
                    title: this.$gettext('LAN → MPLS'),
                    subtitle: this.$gettext('Communications allowed by default'),
                    description: this.$gettext('Block communications from your LANs to MPLS LANs'),
                    button: this.$gettext('Block new communication'),
                    title_section: this.$gettext('List of blocked communications'),
                    can_close_advise: true,
                    can_add_rule: true,
                    add_modal: {
                        title: this.$gettext('Block communications from your LANs to MPLS LANs'),
                        label_source_ip: this.$gettext('IPs/Subnets LAN'),
                        label_destination_ip: this.$gettext('IP/Subnet MPLS LAN'),
                    },
                    icon: 'lan2wan',
                    category: 'lan2wan',
                },
            );
            this.groupsFirewall.push(
                {
                    title: this.$gettext('MPLS → LAN'),
                    subtitle: this.$gettext('Communications allowed by default'),
                    description: this.$gettext('Block communications from MPLS LANs to your LANs'),
                    button: this.$gettext('Block new communication'),
                    title_section: this.$gettext('List of blocked communications'),
                    can_close_advise: true,
                    can_add_rule: true,
                    add_modal: {
                        title: this.$gettext('Block communications from MPLS LANs to your LANs'),
                        label_source_ip: this.$gettext('IPs/Subnets MPLS LAN'),
                        label_destination_ip: this.$gettext('IP/Subnet LAN'),
                    },
                    icon: 'wan2lan',
                    category: 'wan2lan',
                },
            );
        } else {
            this.groupsFirewall.push(
                {
                    title: this.$gettext('LAN → Internet'),
                    subtitle: this.$gettext('Communications allowed by default'),
                    description: this.$gettext('Block communications from your LANs to internet'),
                    button: this.$gettext('Block new communication'),
                    title_section: this.$gettext('List of blocked communications'),
                    can_close_advise: true,
                    can_add_rule: true,
                    add_modal: {
                        title: this.$gettext('Block communications from your LANs to internet'),
                        label_source_ip: this.$gettext('IPs/Subnets LAN'),
                        label_destination_ip: this.$gettext('IP/Subnet internet'),
                    },
                    icon: 'lan2wan',
                    category: 'lan2wan',
                },
            );
            this.groupsFirewall.push(
                {
                    title: this.$gettext('Internet → LAN'),
                    subtitle: this.$gettext('Communications blocked by default'),
                    description: this.$gettext('Allow communications from internet to your LANs'),
                    button: this.$gettext('Allow new communication'),
                    title_section: this.$gettext('List of allowed communications'),
                    can_close_advise: true,
                    can_add_rule: true,
                    add_modal: {
                        title: this.$gettext('Allow communications from internet to your LANs'),
                        label_source_ip: this.$gettext('IPs/Subnets internet'),
                        label_destination_ip: this.$gettext('IP/Subnet LAN'),
                    },
                    icon: 'wan2lan',
                    category: 'wan2lan',
                },
            );
        }
        this.groupsFirewall.push(
            {
                title: this.$gettext('Internet → DMZ'),
                subtitle: this.$gettext('Communications allowed by default'),
                description: this.$gettext('No firewalling action available for DMZ'),
                can_close_advise: false,
                can_add_rule: false,
                icon: 'wan2dmz',
                category: 'dmz',
            },
        );
    },
    methods: {
        addFirewallRule() {
            this.show_modal(
                AddFirewallRuleModal,
                {
                    defaultRule: {
                        ip_version: this.selectedIpVersion !== 'all' ? this.selectedIpVersion : 4,
                    },
                    group: this.current_group,
                },
                {
                    maxHeight: 512,
                },
            );
        },
        change_current_group(group) {
            if (this.current_group === group) {
                return;
            }

            this.current_group = null;
            this.$nextTick(() => {
                this.current_group = group;
            });
        },
    },
};
</script>

<style lang="scss" module>
.firewallsection {
    min-height: 800px;

    .flyinginfotop {
        position: relative;
        display: flex;
        width: fit-content;

        &:before {
            position: absolute;
            top: -15px;
            left: 50%;
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            margin-top: 2px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        &:after {
            position: absolute;
            top: -8px;
            left: 50%;
            z-index: 4;
            display: none;
            width: max-content;
            max-width: 270px;
            padding: 10px 20px;
            font-size: 12px;
            font-weight: 300;
            line-height: 1.5;
            color: black;
            text-align: center;
            white-space: pre-wrap;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            transition: 0.3s ease all;
            transform: translateY(-100%) translateX(-50%);
        }

        &:hover {
            &:after,
            &:before {
                display: block;
                transition: 0.3s ease all;
            }
        }
    }

    .groups_firewall {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 30px;

        @include under-to(lg) {
            flex-direction: column;

            & > div:not(:first-child) {
                margin-top: 20px;
            }

            .disabled {
                display: none;
            }
        }

        & > div:not(:last-child) {
            @include under-to(lg) {
                margin-right: 0;
            }
        }
    }

    .list_items {
        margin-top: 15px;

        li {
            margin-left: 20px;
            list-style: initial;
        }
    }

    .search {
        display: flex;
        gap: 20px;
        margin: 26px 0;

        .selectField,
        .searchField {
            flex: 1;
        }

        .searchField {

            @include under-to(lg) {
                flex-basis: 100%;
            }

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .bottombutton {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1039;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}
</style>
