var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HeaderAdviceLayout",
    {
      class: _vm.$style.wifiSection,
      attrs: { "advice-display": _vm.adviceDisplay },
      on: {
        "update:adviceDisplay": function($event) {
          _vm.adviceDisplay = $event
        },
        "update:advice-display": function($event) {
          _vm.adviceDisplay = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "advice-title",
          fn: function() {
            return [_c("translate", [_vm._v("WIFI")])]
          },
          proxy: true
        },
        {
          key: "advice-content",
          fn: function() {
            return [
              _c("translate", [
                _vm._v(
                  "\n            Wi-Fi network makes it possible to connect several devices within a network by radio waves in order to allow data transmission between them.\n        "
                )
              ]),
              _vm._v(" "),
              _c("translate", [
                _vm._v(
                  "\n            To identify your network you must complete the SSID and the key. Using a complex key will secure your network.\n        "
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm.isUpdated
        ? _c("BannerUpdatedSection", { attrs: { disabled: _vm.readonlyMode } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TitleWithAdviceState",
        {
          scopedSlots: _vm._u([
            {
              key: "before",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      class: _vm.$style.goBack,
                      on: { click: _vm.closeSection }
                    },
                    [
                      _c("MasterSvg", {
                        attrs: {
                          use: "arrow-left",
                          width: "17.5px",
                          height: "15px"
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "after",
              fn: function() {
                return [
                  _c("DesktopAndMobileButton", {
                    attrs: {
                      role: "primary",
                      icon: "add",
                      disabled:
                        _vm.wifis.length >= _vm.nbMaxSsid || _vm.readonlyMode,
                      text: _vm.$gettext("Add guest SSID")
                    },
                    on: { click: _vm.addWifi }
                  })
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.adviceDisplay,
            callback: function($$v) {
              _vm.adviceDisplay = $$v
            },
            expression: "adviceDisplay"
          }
        },
        [_vm._v(" "), _c("translate", [_vm._v("WIFI")])],
        1
      ),
      _vm._v(" "),
      _c("WifisList", {
        attrs: {
          "form-errors": _vm.formErrors,
          wifis: _vm.wifis,
          "can-have-default-credentials": _vm.canHaveDefaultCredentials,
          "can-manage-wps": _vm.canManageWps,
          "readonly-mode": _vm.readonlyMode
        },
        on: { "remove-wifi": _vm.removeWifi }
      }),
      _vm._v(" "),
      _vm.isModified
        ? _c("SubmitButtons", {
            attrs: { disabled: _vm.readonlyMode },
            on: { cancel: _vm.initLinkEquipmentConfWifi, submit: _vm.validate }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }