<template>
    <div :class="$style.adminBox">
        <h3 v-translate>
            Manage the equipment by yourself
        </h3>
        <div :class="$style.content">
            <div :class="$style.description">
                <slot />
            </div>
            <Button
                role="primary"
                :text="buttonText"
                :disabled="buttonDisable"
                icon="user"
                size="large"
                @click="$emit('click')"
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/common/button.vue';

export default {
    name: 'GeneralAdminBox',
    components: {
        Button,
    },
    props: {
        buttonText: {
            required: true,
            type: String,
        },
        buttonDisable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
.adminBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px;
    border-radius: 4px;

    @include themify($themes) {
        border: solid 1px themed('primary_color');
    }

    .content {
        display: flex;
        gap: 20px;
        align-items: center;

        @include under-to(lg) {
            flex-direction: column;
        }

        & > * {
            flex: 1;
            width: 100%;
        }
    }
}
</style>
