<template>
    <div
        class="row"
        :class="[
            $style.results,
            $style.empty,
            {
                'flex-column-reverse': size === 'small',
                [$style.empty_trans]: size === 'large',
            }
        ]"
    >
        <div
            :class="[
                $style.result_text,
                {
                    'col-xl-6 col-12': size === 'large',
                    'mt-4 col-8 col-lg-5': size === 'small',
                }
            ]"
        >
            <div
                class="col-12"
                :class="[
                    $style.no_result_title,
                    {
                        'text-left': size === 'large',
                        'mb-0 text-center': size === 'small',
                    }
                ]"
            >
                <slot name="title" />
            </div>
            <div
                class="col-12"
                :class="[
                    $style.no_result_body,
                    {
                        'mt-3 text-left': size === 'large',
                        'mt-1 text-center': size === 'small',
                    }
                ]"
            >
                <slot name="text" />
            </div>
            <slot name="action" />
        </div>
        <div
            :class="[
                $style.result_img,
                {
                    'col-xl-6 col-12': size === 'large',
                    'mt-3 col-8 col-lg-5 col-xl-4': size === 'small',
                    [$style.img_reverse]: size === 'small',
                }
            ]"
        >
            <MasterSvg use="equipment" />
        </div>
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';

export default {
    components: {
        MasterSvg,
    },
    props: {
        size: {
            type: String,
            required: false,
            default: 'large',
            validator(value) {
                return [
                    'small',
                    'large',
                ].indexOf(value) !== -1;
            },
        },
    },
};
</script>

<style lang="scss" module>
.empty {
    display: None;
    align-items: center;
    font-size: 14px;
    transition: all ease-in-out 0.15s;

    &.empty_trans {
        display: flex;

        .img_reverse {
            svg {
                transform: scaleX(-1);
            }
        }

        .result_img {
            text-align: center;
            animation: slide-right 0.3s ease-out;

            svg {
                width: 80%;
                max-height: 340px;

                @include under-to(md) {
                    width: 100%;
                }
            }
        }

        .result_text {
            animation: slide-up 0.3s ease-out;
        }
    }

    .no_result_title {
        margin-bottom: 10px;
        font-family: 'Orkney', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: bold;

        @include under-to(md) {
            margin-top: 20px;
        }
    }

    .no_result_body {
        margin-bottom: 10px;
        font-family: 'Orkney', sans-serif;
        font-size: 14px;
        font-style: normal;
    }

    @keyframes slide-up {
        0% {
            opacity: 0;
            -webkit-transform: translateY(100%);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
        }
    }

    @keyframes slide-right {
        0% {
            opacity: 0;
            -webkit-transform: translateX(100%);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateX(0);
        }
    }
}
</style>
