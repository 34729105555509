<template>
    <div :class="$style.menu">
        <ul :class="[$style.items, $style.tab,{'sticky-top': ($mq !== 'xs' && $mq !== 'sm')}]">
            <Item
                section="general"
                :active="section == 'general'"
                :updated-section="updated_sections.includes('interfaces')"
                :name="$gettext('General')"
                @click="change_section('general')"
            />
            <Item
                v-show="hasCapability('wans')"
                section="wan"
                :active="section == 'wan'"
                :updated-section="updated_sections.includes('wans')"
                :name="$gettext('WAN')"
                @click="change_section('wan')"
            />
            <Item
                v-show="hasCapability('lans') && !linkequipment.discharged"
                section="lan"
                :active="section == 'lan'"
                :updated-section="updated_sections.includes('lans')"
                :name="$gettext('LAN')"
                @click="change_section('lan')"
            />
            <Item
                v-show="hasCapability('nat') && !linkequipment.discharged"
                section="nat"
                :active="section == 'nat'"
                :updated-section="updated_sections.includes('nat')"
                :name="$gettext('NAT')"
                @click="change_section('nat')"
            />
            <Item
                v-show="hasCapability('firewall') && !linkequipment.discharged"
                section="firewall"
                :active="section == 'firewall'"
                :updated-section="updated_sections.includes('firewall')"
                :name="$gettext('Firewall')"
                @click="change_section('firewall')"
            />
            <Item
                v-show="hasCapability('routes') && !linkequipment.discharged"
                section="routes"
                :active="section == 'routes'"
                :updated-section="updated_sections.includes('routes')"
                :name="$gettext('Routes')"
                @click="change_section('routes')"
            />
            <Item
                v-show="hasCapability('dmz') && !linkequipment.discharged"
                section="dmz"
                :active="section == 'dmz'"
                :updated-section="updated_sections.includes('dmz')"
                :name="$gettext('DMZ')"
                @click="change_section('dmz')"
            />
            <Item
                v-show="!linkequipment.discharged"
                section="connectedDevices"
                :active="section == 'connectedDevices'"
                :name="$gettext('Connected devices')"
                @click="change_section('connectedDevices')"
            />
            <Item
                v-show="!linkequipment.discharged"
                section="confs"
                :active="section == 'confs'"
                :name="$gettext('Saved configurations')"
                @click="change_section('confs')"
            />
            <Item
                v-show="canReadEvents && !linkequipment.discharged"
                section="events"
                :active="section == 'events'"
                :name="$gettext('Events')"
                @click="change_section('events')"
            />
            <Item
                v-show="hasCapability('snmp_communities') || hasCapability('wifis')"
                section="advanced"
                :active="section == 'advanced'"
                :updated-section="advancedSectionIsUpdated"
                :name="$gettext('Advanced')"
                @click="change_section('advanced')"
            />
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Item from './menu-item.vue';

export default {
    components: {
        Item,
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'section',
            'hash_prefix',
            'updated_sections',
            'full_capabilities',
            'linkequipment',
            'linkequipmentconf',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        advancedSectionIsUpdated() {
            const childSections = [
                'wifis',
                'snmp_communities',
            ];

            return childSections.filter(
                value => this.updated_sections.includes(value),
            ).length !== 0;
        },
    },
    methods: {
        ...mapMutations('equipment', [
            'navigate',
            'hideMenu',
        ]),
        hasCapability(capability) {
            return this.full_capabilities.includes(capability);
        },
        change_section(section) {
            const currenthash = this.$route.hash;
            const sectionhash = `#${this.hash_prefix}${section}`;

            if (currenthash !== sectionhash) {
                this.$router.push(sectionhash);
            }
            this.hideMenu();
        },
        canReadEvents() {
            return (
                this.permissions.filter(perm => perm.app_label === 'equipments') || []
            ).find(perm => perm.codename === 'view_linkequipmentaction') !== undefined;
        },
    },
};
</script>

<style lang="scss" module>
.menu {
    @include under-to(md) {
        width: 100%;

        .tab {
            font-size: 20px;
            line-height: 1.4;
        }
    }

    ul {
        @include under-to(md) {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
        }
    }

    ul.items {
        top: 30px;
    }
}
</style>
