<template>
    <div :class="$style.staticaddresslistitem">
        <Field
            v-model="address.ip"
            :disabled="disabled"
            :errors="errors.ip"
            :label="$gettext('IP address')"
            :required="true"
            class="col-12 pb-4 col-lg-5 pb-lg-0"
            name="ip"
        />
        <Field
            v-model="address.mac_address"
            :disabled="disabled"
            :errors="errors.mac_address"
            :label="$gettext('MAC address')"
            :required="true"
            class="col-12 pb-4 col-lg-5 pb-lg-0"
            name="mac_address"
        />
        <div
            :class="[
                'col-12',
                'col-lg-2',
                $style.action_delete,
                {[$style.disabled]: disabled}
            ]"
            @click="handleRemoveButton"
        >
            <Tooltip
                :content="$gettext('Delete static address')"
                :grey-border="disabled"
                :class="$style.trash_bin"
            >
                <MasterSvg use="trash-bin" />
            </Tooltip>
        </div>
    </div>
</template>

<script>
import Field from '@/components/common/field.vue';
import Tooltip from '@/components/common/tooltip.vue';
import MasterSvg from '../master-svg.vue';

export default {
    components: {
        Field,
        MasterSvg,
        Tooltip,
    },
    props: {
        address: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleRemoveButton() {
            if (this.disabled) return;
            this.$emit('remove');
        },
    },
};
</script>

<style lang="scss" module>
.staticaddresslistitem {
    box-sizing: initial;
    display: flex;
    flex-wrap: wrap;
    width: auto;
    padding: 26px;
    margin: 10px -40px 10px -40px;
    text-align: left;
    border-radius: 0;
    transition: 0.3s ease all;
    animation: fade-up 0.3s ease-out;

    @include themify($themes) {
        background-color: themed('gray_scale_light');
    }

    @include under-to(lg) {
        padding: 26px 2px 26px 2px;
        margin-right: -20px;
        margin-left: -20px;
    }

    &:hover .action_delete .trash_bin {
        display: block;
    }

    .action_delete {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;

        .trash_bin {
            display: none;
            cursor: pointer;
            object-fit: contain;

            svg {
                width: 23px;
                height: 26px;
            }

            @include under-to(xl) {
                display: block;
            }
        }

        @include themify($themes) {
            color: themed('primary_color');
            fill: themed('primary_color');
        }

        &.disabled {
            @include themify($themes) {
                color: themed('grayscale_bold');
                fill: themed('grayscale_bold');
            }

            .trash_bin {
                cursor: not-allowed;
            }
        }
    }
}
</style>
