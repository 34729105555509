<template>
    <div :class="$style.circleLoaderContent">
        <div :class="$style.circleLoader" />
    </div>
</template>

<script>
export default { name: 'CircleLoader' };
</script>

<style lang="scss" module>
.circleLoaderContent {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include themify($themes) {
        background-color: rgba(themed('gray_scale_dark'), 0.4);
    }

    .circleLoader {
        width: 8vmax;
        height: 8vmax;
        border-radius: 100%;
        animation: spinRight 800ms linear infinite;

        @include themify($themes) {
            border-right: solid 1px themed("primary_color");
        }

        &:after {
            position: absolute;
            top: calc(50% - 3vmax);
            left: calc(50% - 3vmax);
            display: block;
            width: 6vmax;
            height: 6vmax;
            content: '';
            border-radius: 100%;
            animation: spinLeft 800ms linear infinite;

            @include themify($themes) {
                border-left: 4px solid themed("primary_color");
            }
        }
    }
}

@keyframes spinLeft {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

@keyframes spinRight {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}
</style>
