var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.canBeClosed || _vm.current_section_advice_visible
    ? _c("div", { staticClass: "row", class: _vm.$style.advicesection }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { class: _vm.$style.container },
            [
              _vm.canBeClosed
                ? _c("Button", {
                    class: _vm.$style.closeadvice,
                    attrs: { role: "icon_secondary", icon: "cross" },
                    on: {
                      click: function($event) {
                        return _vm.hideCurrentSectionAdvice()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { class: _vm.$style.advice_title }, [
                _c("span", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.title) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _vm.subtitle
                  ? _c("span", { class: _vm.$style.subtitle }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.subtitle) +
                          "\n                "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("p", { class: _vm.$style.content }, [_vm._t("text")], 2),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.haveExample && _vm.showexample,
                      expression: "haveExample && showexample"
                    }
                  ],
                  staticClass: "mb-2",
                  class: _vm.$style.example
                },
                [_vm._t("example")],
                2
              ),
              _vm._v(" "),
              _c("Button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.haveExample && !_vm.showexample,
                    expression: "haveExample && !showexample"
                  }
                ],
                staticClass: "mb-2",
                class: [_vm.$style.showexample, _vm.$style.b1],
                attrs: { role: "link", text: _vm.$gettext("Show example") },
                on: {
                  click: function($event) {
                    return _vm.showExample()
                  }
                }
              }),
              _vm._v(" "),
              _vm.linkUrl
                ? _c("Button", {
                    class: [_vm.$style.know_more, _vm.$style.b1],
                    attrs: {
                      role: "link",
                      knowmore: true,
                      text: _vm.$gettext("Know more about it on the kiosque")
                    },
                    on: {
                      click: function($event) {
                        return _vm.knowMore()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }