<template>
    <div
        :class="[
            $style.dropdownMenuItem,
            $style[color],
            {[$style.disabled]: disabled}
        ]"
        @click.prevent="handleClick"
    >
        <div :class="$style.dropdownMenuItemIcon">
            <MasterSvg :use="icon" />
        </div>
        <span :class="$style.dropdownMenuItemName">
            {{ name }}
        </span>
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';

export default {
    name: 'DropdownMenuItem',
    components: {
        MasterSvg,
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary',
            validator(value) {
                return [
                    'primary',
                    'red',
                    'orange',
                    'yellow',
                ].indexOf(value) !== -1;
            },
        },
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick() {
            if (this.disabled) return;
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" module>
.dropdownMenuItem {
    $header_colors: none;

    display: flex;
    align-items: center;
    padding: 6px 8px;
    margin: 3px 7px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    color: black;
    cursor: pointer;
    border-radius: 3px;

    @include themify($themes) {
        $header_colors: (
            (
                name: 'primary',
                attributes: (
                    hover_color: themed('primary_color'),
                    hover_bg_color: rgba(themed('primary_color'), 0.09),
                    hover_icon_bg_color: rgba(themed('primary_color'), 0.15),
                    icon_bg_color:  rgba(themed('primary_color'), 0.1),
                    svg_color:  themed('primary_color'),
                    svg_fill:  themed('primary_color'),
                )
            ),
            (
                name: 'red',
                attributes: (
                    hover_color: themed('error_color'),
                    hover_bg_color: rgba(themed('error_color'), 0.09),
                    hover_icon_bg_color: rgba(themed('error_color'), 0.15),
                    icon_bg_color:  rgba(themed('error_color'), 0.1),
                    svg_color:  themed('error_color'),
                    svg_fill:  themed('error_color'),
                )
            ),
            (
                name: 'orange',
                attributes: (
                    hover_color: themed('warning_color'),
                    hover_bg_color: rgba(themed('warning_color'), 0.09),
                    hover_icon_bg_color: rgba(themed('warning_color'), 0.15),
                    icon_bg_color:  rgba(themed('warning_color'), 0.1),
                    svg_color:  themed('warning_color'),
                    svg_fill:  themed('warning_color'),
                )
            ),
            (
                name: 'yellow',
                attributes: (
                    hover_color: #FFB700,
                    hover_bg_color: rgba(#FFB700, 0.09),
                    hover_icon_bg_color: rgba(#FFB700, 0.15),
                    icon_bg_color:  rgba(#FFB700, 0.1),
                    svg_color:  #FFB700,
                    svg_fill:  #FFB700,
                )
            ),
        );
    }

    @each $color in $header_colors {
        $name: map-get($color, name);
        $attributes: map-get($color, attributes);

        &.#{$name}:not(.disabled) {
            &:hover {
                font-weight: 500;
                color: map-get($attributes, hover_color);
                background-color: map-get($attributes, hover_bg_color);

                .dropdownMenuItemIcon {
                    background-color: map-get($attributes, hover_icon_bg_color);
                }
            }

            .dropdownMenuItemIcon {
                background-color: map-get($attributes, icon_bg_color);
            }

            svg {
                color: map-get($attributes, svg_color);
                fill: map-get($attributes, svg_fill);
            }
        }
    }

    .dropdownMenuItemName {
        margin-left: 16px;
    }

    .dropdownMenuItemIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        min-width: 28px;
        height: 28px;
        border-radius: 4px;

        svg {
            width: 17px;
            height: 17px;
        }
    }

    &.disabled {
        cursor: not-allowed;

        @include themify($themes) {
            color: themed("gray_scale_dark");
        }

        &:hover {
            @include themify($themes) {
                background-color: themed("gray_scale_light");
            }
        }

        .dropdownMenuItemIcon {
            @include themify($themes) {
                background-color: themed("gray_scale_normal");
            }
        }

        svg {
            @include themify($themes) {
                color: themed("gray_scale_dark");
                fill: themed("gray_scale_dark");
            }
        }
    }
}
</style>
