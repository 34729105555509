var render = function() {
  var _obj, _obj$1, _obj$2, _obj$3
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.prettyinputchoice,
        ((_obj = {}), (_obj[_vm.$style.disabled] = _vm.disabled), _obj),
        ((_obj$1 = {}), (_obj$1[_vm.$style.error] = _vm.errors), _obj$1),
        ((_obj$2 = {}),
        (_obj$2[_vm.$style.flyinginfo] = _vm.infoTooltips),
        _obj$2)
      ],
      attrs: { "data-content": _vm.infoTooltips }
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.$style["pretty" + _vm.type],
            ((_obj$3 = {}), (_obj$3[_vm.$style.checked] = _vm.checked), _obj$3),
            _vm.customClass
          ],
          on: {
            click: function($event) {
              return _vm.toggleCheck()
            }
          }
        },
        [
          _c("div", { class: _vm.$style.inputwrapper }, [
            _c("input", {
              attrs: {
                id: _vm.name,
                type: _vm.realType,
                disabled: _vm.disabled
              },
              domProps: { checked: _vm.checked }
            }),
            _vm._v(" "),
            _c("label", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.label_input) +
                  "\n            "
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.errors
        ? _c(
            "div",
            { class: _vm.$style.errors },
            _vm._l(_vm.errors, function(error, index) {
              return _c("div", { key: index, class: _vm.$style.error }, [
                _vm._v("\n            " + _vm._s(error) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }