var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$style.badge, _vm.$style[_vm.type]],
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.$slots.before ? _vm._t("before") : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.$style.content }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.$slots.after ? _vm._t("after") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }