<template>
    <div :class="$style.eventItem">
        <div
            :class="[
                $style.header,
                {
                    [$style.scheduledEvent]: status === 'scheduled',
                    [$style.failureEvent]: status === 'failure'
                },
            ]"
        >
            <div :class="$style.iconAndTitle">
                <MasterSvg
                    v-if="iconStyle"
                    :use="iconStyle.name"
                    :width="iconStyle.width"
                    :height="iconStyle.height"
                />
                <div :class="$style.titleAndDatetime">
                    <span>{{ actionName }}</span>
                    <span
                        v-if="status === 'scheduled' && formattedEta"
                        :class="$style.datetime"
                    >
                        - {{ formattedEta }}
                    </span>
                </div>
            </div>
            <div>
                {{ formattedCreatedAt }}
            </div>
        </div>
        <div :class="$style.itemContent">
            <span
                v-if="createdBy"
                v-translate="{createdBy}"
            >
                By %{createdBy}
            </span>
            <span
                v-if="actionComment"
                :class="$style.actionComment"
            >
                {{ actionComment }}
            </span>
            <Banner
                v-if="errorMessage"
                background
                icon="solid-info"
                type="error"
                :can-be-closed="false"
            >
                {{ errorMessage }}
            </Banner>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

import Banner from '@/components/common/banner.vue';
import MasterSvg from '@/components/common/master-svg.vue';

export default {
    components: {
        Banner,
        MasterSvg,
    },
    props: {
        args: {
            default: () => [],
            validator: item => Array.isArray(item) || item === null,
        },
        kwargs: {
            default: () => {},
            validator: item => typeof item === 'object' || item === null,
        },
        status: {
            type: String,
            required: true,
        },
        method: {
            type: String,
            required: true,
        },
        createdBy: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        createdAt: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        updatedAt: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        errorCode: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        errorMessage: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        eta: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
    },
    computed: {
        iconStyle() {
            if (this.method === 'deploy_linkequipment_conf') {
                return {
                    name: 'deploy',
                    width: '16px',
                    height: '16px',
                };
            }
            if (this.method === 'reboot_linkequipment') {
                return {
                    name: 'restart',
                    width: '18px',
                    height: '18px',
                };
            }
            if (this.method === 'upgrade_firmware') {
                return {
                    name: 'ic-switch',
                    width: '18px',
                    height: '18px',
                };
            }
            if (this.method === 'provisioning') {
                return {
                    name: 'upload',
                    width: '18px',
                    height: '18px',
                };
            }
            return null;
        },
        actionName() {
            let name = this.$gettext('Unknown event');
            if (this.method === 'deploy_linkequipment_conf') {
                name = this.deploymentName;
            }
            if (this.method === 'reboot_linkequipment') {
                name = this.$gettext('Reboot');
            }
            if (this.method === 'upgrade_firmware') {
                name = this.$gettext('Upgrade firmware');
            }
            if (this.method === 'provisioning') {
                name = this.$gettext('First provisioning of equipment');
            }
            if (this.status === 'scheduled') {
                return this.$gettextInterpolate(
                    this.$gettext('Scheduled %{name}'),
                    { name },
                );
            }

            return name;
        },
        actionComment() {
            if (this.method === 'upgrade_firmware' && (this.kwargs || {}).new_version) {
                return this.$gettextInterpolate(
                    this.$gettext('Installed version: %{version}'),
                    { version: this.kwargs.new_version },
                );
            }
            return (this.kwargs || {}).comment;
        },
        formattedCreatedAt() {
            if (!this.createdAt) return '';
            moment.locale(Vue.config.language);
            return moment(this.createdAt).format('LLL');
        },
        formattedEta() {
            if (!this.eta) return null;
            moment.locale(Vue.config.language);
            const etaMoment = moment(this.eta);
            return this.$gettextInterpolate(
                this.$gettext('%{date} at %{time}'),
                {
                    date: etaMoment.format('LL'),
                    time: etaMoment.format('LT'),
                },
            );
        },
        deploymentName() {
            const { hotpatch } = (this.kwargs || {});
            if (hotpatch) return this.$gettext('Partial deployment');
            return this.$gettext('Full deployment');
        },
    },
};
</script>

<style lang="scss" module>
.eventItem {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;

    @include themify($themes) {
        border: 1px solid themed('grayscale_extralight');
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;

        @include themify($themes) {
            border-bottom: 1px solid themed('grayscale_extralight');
        }

        .iconAndTitle {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            font-size: 16px;
            font-weight: 500;

            svg {
                max-width: 18px;
                max-height: 18px;

                @include themify($themes) {
                    color: themed('primary_color');
                    fill: themed('primary_color');
                }
            }

            .titleAndDatetime {
                display: flex;
                flex-direction: row;
                gap: 4px;

                .datetime {
                    font-weight: 300;
                }
            }
        }

        &.failureEvent {
            .iconAndTitle {
                svg {
                    @include themify($themes) {
                        color: themed('error_color');
                        fill: themed('error_color');
                    }
                }
            }
        }

        &.scheduledEvent {
            @include themify($themes) {
                background-color: rgba(themed('primary_color'), 0.2);
            }
        }
    }

    .itemContent {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;
        font-size: 14px;

        .actionComment {
            @include themify($themes) {
                color: themed('grayscale_medium');
            }
        }
    }
}
</style>
