<template>
    <span
        v-if="values.length > 1"
        :class="$style.oneOrMoreComponent"
    >
        <slot
            name="multiple-value"
            :count="values.length"
        >
            <span v-translate="{count: values.length}">
                Multiple (%{count})
            </span>
        </slot>
        <Tooltip :content="tooltipContent">
            <MasterSvg use="info" />
        </Tooltip>
    </span>
    <span v-else-if="values.length === 0">
        <slot name="empty-value">
            <translate>No data</translate>
        </slot>
    </span>
    <span v-else>{{ values[0] }}</span>
</template>

<script>
import Vue from 'vue';

import MasterSvg from './master-svg.vue';
import Tooltip from './tooltip.vue';

export default {
    components: {
        MasterSvg,
        Tooltip,
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
        maxDisplayedValues: {
            type: Number,
            default: 5,
        },
    },
    computed: {
        tooltipContent() {
            let content = '';
            const others = this.values.length - this.maxDisplayedValues;

            if (this.values.length > 0) {
                this.values.forEach((value, index) => {
                    if (index >= this.maxDisplayedValues) {
                        if (index === this.maxDisplayedValues) {
                            const translated = Vue.prototype.$ngettext(
                                '+ %{ n } other', '+ %{ n } others', others,
                            );
                            const interpolated = Vue.prototype.$gettextInterpolate(
                                translated, { n: others },
                            );
                            content += interpolated;
                        }
                    } else {
                        content += `${value}\u000A`;
                    }
                });
            }

            return content;
        },
    },
};
</script>

<style lang="scss" module>
.oneOrMoreComponent {
    display: flex;
    gap: 3px;

    svg {
        width: 16px;
        height: 16px;

        @include themify($themes) {
            fill: themed('primary_color');
        }
    }
}
</style>
