var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Badge", {
    attrs: { type: _vm.thirdPartyEquipment ? "warning" : "success" },
    scopedSlots: _vm._u([
      {
        key: "before",
        fn: function() {
          return [
            _c("MasterSvg", {
              attrs: {
                use: _vm.thirdPartyEquipment ? "ic-external" : "ic-unyc",
                width: "18px",
                height: "18px"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _vm.thirdPartyEquipment
              ? _c("translate", [
                  _vm._v("\n            Third party equipment\n        ")
                ])
              : _c(
                  "translate",
                  {
                    attrs: {
                      "translate-params": {
                        provider: _vm.equipment._provider.name
                      }
                    }
                  },
                  [_vm._v("\n            %{provider} equipment\n        ")]
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }