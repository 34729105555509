<template>
    <div :class="$style.wansList">
        <ListItem
            v-for="(wan, index) in mainWans"
            :key="wan.uuid"
            :class="$style.wanItem"
            :main-wan="wan"
            :backup-wans="backupsOfWan(wan.name)"
            :equipment="current_equipment"
            :index-wan="index"
            :errors="errors.wans || []"
            :interfaces="currentEquipmentConf.interfaces || []"
            :show-delete-button="mainWans.length > 1"
            :show-placeholder-backup="!checkConfigOfBackupExists(wan.name)"
            :readonly-mode="readonlyMode"
            @add-modem="showAttachModal(wan.name, $event)"
        />
        <div
            v-if="errors.non_field_errors"
            :class="[$style.globalErrors, $style.errors]"
        >
            <div
                v-for="(error, index) in errors.non_field_errors"
                :key="index"
                :class="$style.error"
            >
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { showSwalError } from '@/utils/utils';
import { actionsControl } from './utils/utils';
import ListItem from './wans-listitem.vue';

const AttachModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-attach" */
    './modals/attach.vue'
);

export default {
    components: {
        ListItem,
    },
    props: {
        currentEquipmentConf: {
            type: Object,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
        readonlyMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'activatedAccesses',
            'iplink',
            'linkequipment',
            'linkequipmentconfoptions',
        ]),
        current_equipment() {
            if (!this.linkequipmentconfoptions) {
                return {};
            }
            if (!this.linkequipmentconfoptions.equipments_informations) {
                return {};
            }
            return this.linkequipmentconfoptions.equipments_informations.find(
                item => item.link_id === this.currentEquipmentConf.link_id,
            ) || {};
        },
        mainWans() {
            return ((this.currentEquipmentConf || {}).wans || []).filter(
                wan => wan.type === 'main',
            );
        },
        backupWans() {
            return ((this.currentEquipmentConf || {}).wans || []).filter(
                wan => wan.type === 'backup',
            );
        },
        accessesNotConfigured() {
            return this.activatedAccesses.filter(
                access => !((this.currentEquipmentConf || {}).wans || []).map(
                    wan => wan.name,
                ).includes(access.link_id),
            );
        },
    },
    created() {
        this.$eventHub.on('show-attach-backup-modem-modal', this.showAttachBackupModemModal);
    },
    beforeDestroy() {
        this.$eventHub.off('show-attach-backup-modem-modal');
    },
    methods: {
        backupsOfWan(linkName) {
            return this.backupWans.filter(wan => wan.backup_of === linkName);
        },
        checkConfigOfBackupExists(linkName) {
            if (this.activatedAccesses.length < 2) return true;
            if (!this.accessesNotConfigured) return true;
            const mainAccess = this.activatedAccesses.find(
                access => access.link_id === linkName,
            );
            if (!mainAccess) return true;
            return !this.accessesNotConfigured.map(
                access => access.backup_of,
            ).includes(mainAccess.pk);
        },
        showAttachBackupModemModal(wanData) {
            this.showAttachModal(wanData.linkName, wanData.router);
        },
        showAttachModal(linkName, router) {
            const accessesNumber = (this.activatedAccesses || []).length;
            const mainAccess = this.activatedAccesses.find(
                access => access.link_id === linkName,
            );
            const capabilities = [
                `can_manage_${this.iplink.ipstack}`,
            ];
            if (mainAccess.redundancy === 'multiwan' || accessesNumber > 1) {
                capabilities.push('can_multiwan');
            }
            if (accessesNumber > 1) {
                capabilities.push('backup');
            }

            actionsControl(this.linkequipment).then(() => {
                this.show_modal(
                    AttachModal,
                    {
                        iplink: this.iplink,
                        action: 'update',
                        capabilities,
                        selectedRouter: router,
                    },
                    {
                        maxWidth: Infinity,
                        maxHeight: Infinity,
                    },
                );
            }).catch(showSwalError);
        },
    },
};
</script>

<style lang="scss" module>
.wansList {
    .wanItem {
        margin-top: 20px;
    }

    .globalErrors {
        padding: 25px 24px;
        margin-top: 20px;

        @include themify($themes) {
            box-shadow: 0 0 10px 0 rgba(themed('error_color'), 0.1);
        }

        @include under-to(lg) {
            padding: 10px 20px;
        }
    }
}
</style>
