var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.sectionGeneral },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-12 mb-4" }, [
            _c(
              "span",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                class: _vm.$style.body8
              },
              [_vm._v("\n                General\n            ")]
            )
          ]),
          _vm._v(" "),
          _c("Field", {
            staticClass: "col-12 col-lg-6",
            class: _vm.$style.margin_field,
            attrs: {
              errors: _vm.formErrors.name,
              label: _vm.$gettext("Name"),
              required: true,
              name: "name"
            },
            model: {
              value: _vm.lan.name,
              callback: function($$v) {
                _vm.$set(_vm.lan, "name", $$v)
              },
              expression: "lan.name"
            }
          }),
          _vm._v(" "),
          _c("Field", {
            staticClass: "col-12 col-lg-6",
            class: _vm.$style.margin_field,
            attrs: {
              errors: _vm.formErrors.vlan_id,
              label: _vm.$gettext("VLAN ID"),
              required: true,
              name: "vlan_id"
            },
            model: {
              value: _vm.lan.vlan_id,
              callback: function($$v) {
                _vm.$set(_vm.lan, "vlan_id", $$v)
              },
              expression: "lan.vlan_id"
            }
          }),
          _vm._v(" "),
          _vm.wanChoices.length > 1
            ? _c("Select", {
                staticClass: "col-12 col-lg-6",
                class: _vm.$style.margin_field,
                attrs: {
                  errors: _vm.formErrors.main_exit_wan,
                  "data-list": _vm.wanChoices,
                  label: _vm.$gettext("Priority WAN exit"),
                  "info-tooltips": _vm.tooltipWanContent,
                  required: true,
                  name: "main_exit_wan"
                },
                model: {
                  value: _vm.internalMainExitWan,
                  callback: function($$v) {
                    _vm.internalMainExitWan = $$v
                  },
                  expression: "internalMainExitWan"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { class: _vm.$style.separator }),
      _vm._v(" "),
      _vm.canIPv4
        ? _c("IpAddressBlock", {
            attrs: {
              "ip-version": 4,
              addressing: _vm.lan.addressing[_vm.ipv4Index],
              errors: _vm.formErrors.addressing[_vm.ipv4Index],
              netmasks: _vm.ipv4Netmasks,
              "default-netmask": 24,
              "can-update-netmask": "",
              "is-vrrp": _vm.isVrrp
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canIPv6 ? _c("hr", { class: _vm.$style.separator }) : _vm._e(),
      _vm._v(" "),
      _vm.canIPv6
        ? _c("IpAddressBlock", {
            attrs: {
              "ip-version": 6,
              addressing: _vm.lan.addressing[_vm.ipv6Index],
              errors: _vm.formErrors.addressing[_vm.ipv6Index],
              netmasks: _vm.ipv6Netmasks,
              "default-netmask": 64,
              "is-vrrp": _vm.isVrrp
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }