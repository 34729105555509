var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.tag }, [
    _vm.$slots.before ? _c("div", [_vm._t("before")], 2) : _vm._e(),
    _vm._v(" "),
    _c("div", { class: _vm.$style.content }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c("div", { class: [_vm.$style.statusbullet, _vm.statusClass] }),
    _vm._v(" "),
    _vm.$slots.after ? _c("div", [_vm._t("after")], 2) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }