<template>
    <div :class="$style.componentSimpleFilters">
        <ul :class="$style.filterChoices">
            <li
                :class="[{[$style.active]: value == defaultValue.value || null}]"
                @click="on_selection(defaultValue.value)"
            >
                {{ defaultValue.label }}
            </li>
            <li
                v-for="choice in filterChoices"
                :key="choice.value"
                :class="{[$style.active]: value == choice.value}"
                @click="on_selection(choice.value)"
            >
                {{ choice.label }}
            </li>
        </ul>
    </div>
</template>

<script>

import Vue from 'vue';

export default {
    props: {
        value: {
            type: [String, Boolean, Number],
            required: true,
        },
        filterChoices: {
            type: Array,
            default: (() => []),
            required: false,
            validator: (items) => {
                const validItems = items.filter(obj => ['label', 'value'].every(key => key in obj));
                return validItems.length === items.length;
            },
        },
        defaultValue: {
            type: Object,
            default() {
                return { label: Vue.prototype.$gettext('All'), value: 'all' };
            },
            required: false,
        },
    },
    methods: {
        on_selection(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" module>
.componentSimpleFilters {
    display: flex;
    align-items: flex-start;

    .filterChoices {
        position: relative;
        display: flex;
        gap: 16px;
        height: 100%;

        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            content: '';

            @include themify($themes) {
                border-bottom: 1px solid themed('grayscale_medium');
            }
        }

        li {
            position: relative;
            color: black;
            cursor: pointer;

            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                content: '';

                @include themify($themes) {
                    border-bottom: 1px solid themed('grayscale_medium');
                }
            }

            &:hover {
                @include themify($themes) {
                    &:before {
                        border-bottom: 2px solid themed('primary_color');
                    }
                }
            }

            &.active {
                @include themify($themes) {
                    color: themed('primary_color');

                    &:before {
                        border-bottom: 2px solid themed('primary_color');
                    }
                }
            }
        }
    }
}
</style>
