var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.section_general }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-12 mb-4" }, [
          _c(
            "span",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.body7
            },
            [_vm._v("\n                Description\n            ")]
          )
        ]),
        _vm._v(" "),
        _c("Field", {
          staticClass: "col-12 col-lg-6",
          attrs: {
            errors: _vm.formErrors.name,
            label: _vm.$gettext("Name"),
            required: true,
            name: "name"
          },
          model: {
            value: _vm.dmz.name,
            callback: function($$v) {
              _vm.$set(_vm.dmz, "name", $$v)
            },
            expression: "dmz.name"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-12 mt-4 mb-4" }, [
          _c(
            "span",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.body7
            },
            [_vm._v("\n                General\n            ")]
          )
        ]),
        _vm._v(" "),
        _c("Field", {
          staticClass: "col-12 col-lg-6",
          class: _vm.$style.margin_field,
          attrs: {
            errors: _vm.formErrors.ip,
            label: _vm.$gettext("IP address"),
            required: true,
            name: "ip"
          },
          model: {
            value: _vm.dmz.ip,
            callback: function($$v) {
              _vm.$set(_vm.dmz, "ip", $$v)
            },
            expression: "dmz.ip"
          }
        }),
        _vm._v(" "),
        _c("Field", {
          staticClass: "col-12 col-lg-6",
          class: _vm.$style.margin_field,
          attrs: {
            errors: _vm.formErrors.subnet,
            label: _vm.$gettext("Subnet"),
            required: true,
            name: "subnet",
            disabled: true
          },
          model: {
            value: _vm.dmz.subnet,
            callback: function($$v) {
              _vm.$set(_vm.dmz, "subnet", $$v)
            },
            expression: "dmz.subnet"
          }
        }),
        _vm._v(" "),
        _vm.exit_wan_choices.length > 1
          ? _c("Select", {
              staticClass: "col-12 col-lg-6",
              class: _vm.$style.margin_field,
              attrs: {
                errors: _vm.formErrors.main_exit_wan,
                "data-list": _vm.exit_wan_choices,
                label: _vm.$gettext("Priority WAN exit"),
                required: true,
                name: "main_exit_wan"
              },
              model: {
                value: _vm.dmz.main_exit_wan,
                callback: function($$v) {
                  _vm.$set(_vm.dmz, "main_exit_wan", $$v)
                },
                expression: "dmz.main_exit_wan"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }