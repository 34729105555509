<template>
    <div :class="$style.eventItemLoader">
        <div :class="$style.header">
            <div :class="$style.iconAndTitle">
                <span :class="$style.icon" />
                <span />
            </div>
            <div :class="$style.updatedAt">
                <span />
            </div>
        </div>
        <div :class="$style.itemContent">
            <span />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventsListLoader',
};
</script>

<style lang="scss" module>
@import '@/assets/css/_skeleton.scss';

.eventItemLoader {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;

    @include themify($themes) {
        border: 1px solid themed('grayscale_extralight');
    }

    .spanSkeleton {
        @extend .skeleton;

        width: 100px;
        height: 25px;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;

        @include themify($themes) {
            border-bottom: 1px solid themed('grayscale_extralight');
        }

        .iconAndTitle,
        .updatedAt {
            display: flex;
            flex-direction: row;
            gap: 12px;

            span {
                @extend .spanSkeleton;

                width: 150px;

                &.icon {
                    width: 25px;
                }
            }
        }
    }

    .itemContent {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px;

        span {
            @extend .spanSkeleton;

            width: 200px;
        }
    }
}
</style>
