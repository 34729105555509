import { retrieveStartEndMonthDate } from '@/utils/utils';

const state = {
    data: [],
    data2: [],
    loading: false,
    loading_data1: false,
    loading_data2: false,
};

const getters = {
    data: state => state.data,
    data2: state => state.data2,
    loading: state => state.loading,
    loading_data1: state => state.loading_data1,
    loading_data2: state => state.loading_data2,
};

const mutations = {
    update_data(state, data) {
        state.data = data;
    },
    update_data2(state, data2) {
        state.data2 = data2;
    },

    update_loading(state, loading) {
        state.loading = loading;
    },
    update_loading_data1(state, loading) {
        state.loading_data1 = loading;
        state.loading = state.loading_data1 || state.loading_data2;
    },
    update_loading_data2(state, loading) {
        state.loading_data2 = loading;
        state.loading = state.loading_data1 || state.loading_data2;
    },
};


const actions = {

    async export_to_csv_request({ commit, rootState }, params) {
        commit('update_loading', true);
        const values = retrieveStartEndMonthDate(params.period);
        const firstDate = values[0];
        const lastDate = values[1];
        const fileDownload = require('js-file-download'); // eslint-disable-line
        rootState.axios_api.get('/api/legacy/billingarchive/dailycountbyservice/', {
            params: {
                period: params.period, call_start_gte: firstDate, call_start_lte: lastDate, format: 'csv',
            },
        })
            .then((response) => {
                commit('update_loading', false);
                fileDownload(response.data, `${params.period}.csv`);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                commit('update_loading', false);
                alert('error');// eslint-disable-line
            });
    },

    async load_data({ commit, rootState }, params) {
        commit('update_loading_data1', true);
        const values = retrieveStartEndMonthDate(params.period);
        const firstDate = values[0];
        const lastDate = values[1];
        rootState.axios_api.get('/api/legacy/billingarchive/dailycountbyservice/', { params: { period: params.period, call_start_gte: firstDate, call_start_lte: lastDate } })
            .then((response) => {
                commit('update_loading_data1', false);
                commit('update_data', response.data);
            })
            .catch((error) => {
                commit('update_loading_data1', false);
                alert('error');// eslint-disable-line
                // eslint-disable-next-line no-console
                console.log(error);
            });
    },

    async load_data2({ commit, rootState }, params) {
        commit('update_loading_data2', true);
        const values = retrieveStartEndMonthDate(params.period);
        const firstDate = values[0];
        const lastDate = values[1];
        rootState.axios_api.get('/api/legacy/billingarchive/dailycountbyservice/', { params: { period: params.period, call_start_gte: firstDate, call_start_lte: lastDate } })
            .then((response) => {
                commit('update_loading_data2', false);
                commit('update_data2', response.data);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                commit('update_loading_data2', false);
                alert('error'); // eslint-disable-line 
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
