<template>
    <div class="container">
        <div class="row">
            <IPSubnet
                v-if="ipsubnet"
                :ipsubnet="ipsubnet"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import IPSubnet from '@/components/ipmanager/ipsubnet.vue';
import {
    showSwalError,
} from '@/utils/utils';

export default {
    components: {
        IPSubnet,
    },
    computed: {
        ...mapGetters('ipmanager', [
            'ipsubnet',
        ]),
    },
    created() {
        this.change_ipsubnet(null);
    },
    mounted() {
        this.fetch_ipsubnet({
            id: this.$route.params.subnet_id,
        }).catch(showSwalError);
    },
    methods: {
        ...mapMutations('ipmanager', [
            'change_ipsubnet',
        ]),
        ...mapActions('ipmanager', [
            'fetch_ipsubnet',
        ]),
    },
};
</script>

<style lang="scss" module>

</style>
