var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-good-table", {
        attrs: {
          mode: "remote",
          "style-class": "vgt-table",
          "total-rows": _vm.totalItems,
          rows: _vm.rows,
          columns: _vm.columns,
          "sort-options": _vm.sortOptions,
          "global-search": true,
          "search-options": _vm.searchOptions,
          "pagination-options": _vm.pagination,
          "is-loading": _vm.isLoading
        },
        on: {
          "on-page-change": _vm.onPageChange,
          "on-sort-change": _vm.onSortChange,
          "on-column-filter": _vm.onColumnFilter,
          "on-search": _vm.onSearch,
          "on-per-page-change": _vm.onPerPageChange,
          "on-cell-click": _vm.onCellClick
        },
        scopedSlots: _vm._u(
          [
            {
              key: "table-row",
              fn: function(props) {
                return [_vm._t("table-row", null, null, props)]
              }
            },
            {
              key: "loadingContent",
              fn: function() {
                return [
                  _c("Loader", {
                    class: _vm.$style.table_loader,
                    attrs: { show: _vm.isLoading, width: "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "after",
                        fn: function() {
                          return [
                            _c(
                              "h3",
                              {
                                directives: [
                                  { name: "translate", rawName: "v-translate" }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                        Loading results\n                    "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }