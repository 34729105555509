var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { class: _vm.$style.prettyloader },
        [
          _vm._t("before"),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.flex_row },
            [
              _c("MasterSvg", {
                attrs: {
                  use: _vm.svg,
                  width: _vm.width,
                  height: _vm.width,
                  "custom-class": _vm.$style.loader
                }
              }),
              _vm._v(" "),
              _vm._t("next")
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("after")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }