<template>
    <div :class="$style.accessForm">
        <span
            v-if="isBackup"
            v-translate
            :class="$style.labelForm"
        >
            Backup connexion
        </span>
        <span
            v-else
            v-translate
            :class="$style.labelForm"
        >
            Main connexion
        </span>

        <div
            v-if="isBackup && wan"
            :class="$style.flexColunm"
        >
            <span
                v-translate
                :class="$style.accessTitle"
            >
                Modem choice
            </span>
            <Select
                v-model="currentModem"
                :data-list="modemChoices"
                :disabled="readonlyMode"
                :required="true"
                class="mt-3"
                :class="$style.backupModem"
                name="backupModem"
            />
        </div>

        <!-- modem specs  -->
        <div
            v-if="linkequipment"
            :class="$style.modem"
        >
            <ComponentSpecifications
                :image-src="modemPicture"
                :class="{[$style.customerOwnedModem]: customerOwnedModem}"
            >
                <template #title>
                    <span
                        v-translate
                        :class="$style.equipmentTitle"
                    >
                        Modem
                    </span>
                    <div
                        v-if="modemModel"
                        :class="$style.modemName"
                    >
                        <span>
                            {{ modemModel._vendor.name }}
                            {{ modemModel.name }}
                        </span>
                        <span
                            v-if="wan.modulate_by_equipment"
                            v-translate
                        >
                            (Integrated modem)
                        </span>
                    </div>
                    <div
                        v-else-if="usedInfrastructureOperatorModem"
                        v-translate
                        :class="$style.modemName"
                    >
                        Infrastructure operator modem
                    </div>
                    <div
                        v-else
                        v-translate
                        :class="$style.modemName"
                    >
                        You are using your own modem device
                    </div>
                </template>

                <template #specifications>
                    <ComponentSpecificationsItem>
                        <template #label>
                            <translate>Manufacturer</translate>
                        </template>

                        <template #value>
                            <div v-if="modemModel">
                                {{
                                    modemModel._vendor.name
                                }}
                            </div>
                            <div
                                v-else
                                v-translate
                                :class="$style.unavailableInfoValue"
                            >
                                Unavailable information
                            </div>
                        </template>
                    </ComponentSpecificationsItem>

                    <ComponentSpecificationsItem>
                        <template #label>
                            <translate>Serial number</translate>
                        </template>

                        <template #value>
                            <div
                                v-if="!modemModel || usedInfrastructureOperatorModem"
                                v-translate
                                :class="$style.unavailableInfoValue"
                            >
                                Unavailable information
                            </div>
                            <div v-else-if="linkequipment._equipment.serial_number">
                                {{ linkequipment._equipment.serial_number }}
                            </div>
                            <div
                                v-else-if="wan.modulate_by_equipment"
                                v-translate
                            >
                                Not yet connected on site
                            </div>
                            <Button
                                v-else
                                role="link"
                                :text="$gettext('Type Serial number')"
                                size="large"
                                :class="$style.b1"
                                @click="addSerialNumber(linkequipment)"
                            />
                        </template>
                    </ComponentSpecificationsItem>

                    <ComponentSpecificationsItem>
                        <template #label>
                            <translate>Model</translate>
                        </template>

                        <template #value>
                            <div v-if="modemModel">
                                {{ modemModel.name }}
                            </div>
                            <div
                                v-else
                                v-translate
                                :class="$style.unavailableInfoValue"
                            >
                                Unavailable information
                            </div>
                        </template>
                    </ComponentSpecificationsItem>

                    <ComponentSpecificationsItem v-if="!wan.modulate_by_equipment">
                        <template #label>
                            <translate>Configuration</translate>
                        </template>

                        <template #value>
                            <Button
                                v-if="modemModel"
                                role="link"
                                :text="$gettext('Download configuration')"
                                size="large"
                                :class="$style.b1"
                                @click="download_configuration(linkequipment._access)"
                            />
                            <div
                                v-else
                                v-translate
                                :class="$style.unavailableInfoValue"
                            >
                                Unavailable information
                            </div>
                        </template>
                    </ComponentSpecificationsItem>

                    <ComponentSpecificationsItem>
                        <template #label>
                            <translate>Mode</translate>
                        </template>

                        <template #value>
                            <div v-if="modemModel">
                                <translate v-if="wan.modulate_by_equipment">
                                    Integrated modem
                                </translate>
                                <translate v-else>
                                    Bridge
                                </translate>
                            </div>
                            <div
                                v-else
                                v-translate
                                :class="$style.unavailableInfoValue"
                            >
                                Unavailable information
                            </div>
                        </template>
                    </ComponentSpecificationsItem>

                    <ComponentSpecificationsItem v-if="!wan.modulate_by_equipment">
                        <template #label>
                            <translate>Port</translate>
                        </template>

                        <template #value>
                            <div v-if="modemModel">
                                Port 2
                            </div>
                            <div
                                v-else
                                v-translate
                                :class="$style.unavailableInfoValue"
                            >
                                Unavailable information
                            </div>
                        </template>
                    </ComponentSpecificationsItem>
                </template>
            </ComponentSpecifications>
        </div>
        <!-- ports selection -->

        <div
            v-if="wan"
            :class="$style.flexColunm"
        >
            <span
                v-translate
                :class="$style.portTitle"
                class="mb-2"
            >
                Connection port on the router
            </span>

            <RadioButton
                v-model="wan.interface"
                :choices="internalInterfacesChoices"
                :class="$style.interfaces"
                :custom-class="$style.port"
                :errors="(errors || {}).interface"
                :name="`iface-${wan.uuid}-${wan.indexWan}`"
            />
        </div>
        <ManagedSection
            v-else
            :class="$style.flexColunm"
            svg-name="router-4g"
            svg-heigth="40px"
            svg-width="40px"
        >
            <template #title>
                <translate>No 4G modem attached</translate>
            </template>
            <template #body>
                <!-- eslint-disable max-len -->
                <translate>
                    To attach a modem, click on "Add a 4G modem" and choose a compatible model, or detach and re-provision a device from the menu in the header.
                </translate>
                <Button
                    role="secondary"
                    :class="$style.placeholderButton"
                    :text="$gettext('Add a 4g modem')"
                    size="large"
                    @click="$emit('add-modem', router)"
                />
            </template>
        </ManagedSection>
    </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import RadioButton from '@/components/common/radiobutton.vue';
import Select from '@/components/common/select.vue';
import Button from '@/components/common/button.vue';

import {
    showSwalError,
    fetchValueInDictInsensitiveCase,
} from '@/utils/utils';
import ComponentSpecifications from './component-specifications.vue';
import ComponentSpecificationsItem from './component-specifications-item.vue';
import ManagedSection from './component-managed-section.vue';
import svgModem from './assets/ic-modem.svg';
import svgONT from './assets/ic-ont.svg';
import defaultImgRoute from './assets/defaultrouter.png';

const OneFieldFormModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-onefieldform" */
    './modals/onefieldform.vue'
);

export default {
    components: {
        RadioButton,
        Select,
        ComponentSpecifications,
        ComponentSpecificationsItem,
        Button,
        ManagedSection,
    },
    props: {
        linkequipment: {
            type: Object,
            default: null,
        },
        interfacesChoices: {
            type: Array,
            default: null,
        },
        wan: {
            type: Object,
            default: null,
        },
        router: {
            type: Object,
            default: null,
        },
        readonlyMode: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            modemList: [],
            currentModem: null,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
        ]),
        current_access() {
            return (this.linkequipment || {})._access;
        },
        isBackup() {
            if (!this.wan) return true;
            return this.wan.type === 'backup';
        },
        usedInfrastructureOperatorModem() {
            return this.current_access.modem_type === 'infrastructure-operator-modem';
        },
        modemChoices() {
            const choices = [];

            if (this.modemList.length) {
                this.modemList.forEach((item) => {
                    choices.push({
                        id: item.modem_model,
                        text: item._modem_model.name,
                    });
                });
            }

            return choices;
        },
        modemModel() {
            if (
                !this.linkequipment._equipment.model
                || this.linkequipment._equipment._model.name === 'Unknown'
            ) {
                return null;
            }
            return this.linkequipment._equipment._model;
        },
        modemPicture() {
            const { modemModel } = this;

            if (this.usedInfrastructureOperatorModem) {
                return svgONT;
            }
            if (!modemModel) {
                return svgModem;
            }
            if (modemModel.image) {
                return modemModel.image;
            }
            return defaultImgRoute;
        },
        customerOwnedModem() {
            return !this.linkequipment._equipment._model
            && !this.wan.modulate_by_equipment;
        },
        internalInterfacesChoices() {
            return this.interfacesChoices.map(item => ({
                ...item,
                disabled: this.wan.modulate_by_equipment || item.disabled,
            }));
        },
    },
    watch: {
        linkequipment: {
            handler() {
                if (!this.linkequipment) {
                    return;
                }
                this.currentModem = this.linkequipment._equipment.model;
            },
            immediate: true,
        },
        currentModem(newModem, oldModem) {
            if (newModem === oldModem) {
                return;
            }

            const params = [{
                access: this.current_access.link_id,
                equipment_type: 'modem',
                model: newModem,
            }];
            let methodName = 'change_attached_equipment';
            if (oldModem == null) {
                methodName = 'attach_equipment';
            }

            this[methodName](params).then(() => {
                this.$emit('reload');
            }).catch(this.showSwalError);
        },
    },
    created() {
        if (this.isBackup && this.wan) {
            this.fetchLinksetupequipmentmodels();
        }
    },
    methods: {
        ...mapActions('equipment', [
            'remove_link_from_virtual_link',
            'fetch_linkequipment',
            'download_conf',
            'fetch_linksetupequipmentmodels',
            'change_attached_equipment',
            'attach_equipment',
            'edit_equipment',
        ]),
        ...mapMutations('equipment', [
            'resetLinkSetupEquipmentModels',
        ]),
        download_configuration(access) {
            this.$eventHub.emit('show-global-loader', true);
            this.download_conf({
                iplink: access,
                filters: {
                    force_iplink: true,
                    equipment_type: 'modem',
                },
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const element = document.createElement('a');
                const filename = fetchValueInDictInsensitiveCase(
                    response.headers,
                    'content-disposition',
                ).split('filename=')[1];
                element.setAttribute('href', fileURL);
                element.setAttribute('download', filename);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }).catch(
                showSwalError,
            ).finally(() => {
                this.$nextTick(() => {
                    this.$eventHub.emit('show-global-loader', false);
                });
            });
        },
        addSerialNumber(linkequipment) {
            this.show_modal(
                OneFieldFormModal,
                {
                    title: this.$gettext('Modem serial number'),
                    subtitle: this.$gettext('Please enter modem serial number.'),
                    'field-name': this.$gettext('Serial number'),
                    'field-placeholder': this.$gettext('Serial number'),
                },
                {
                    maxHeight: 340,
                    maxWidth: 600,
                },
            ).then((serialNumber) => {
                if (serialNumber) {
                    this.edit_equipment(
                        {
                            equipment: linkequipment.equipment,
                            data: {
                                serial_number: serialNumber,
                            },
                        },
                    ).then((equipment) => {
                        linkequipment._equipment = equipment;
                    }).catch(showSwalError);
                }
            });
        },
        fetchLinksetupequipmentmodels() {
            this.resetLinkSetupEquipmentModels();
            const params = {
                filters: {
                    enabled: true,
                    collect: this.iplink.collect,
                    mpls: this.iplink.mpls,
                    download_rate: this.current_access.real_download_rate,
                    techno: this.current_access.link_type,
                    authentification_type: this.iplink.authentification_type,
                    modem_type: this.current_access.modem_type,
                    access: this.current_access.link_id,
                    router_vendor: this.router.vendor,
                    router_model: this.router.id,
                },
            };

            this.fetch_linksetupequipmentmodels(params).then((result) => {
                this.modemList = result;
            }).catch(() => {});
        },
    },
};
</script>

<style lang="scss" module>
.accessForm {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding: 25px 25px 15px 25px;
    background-color: white;
    border: solid 2px #E2E2E2;
    border-radius: 8px;

    & > div {
        background-color: inherit;
    }

    .flexColunm {
        display: flex;
        flex-direction: column;
    }

    .accessTitle {
        font-family: Orkney, sans-serif;
        font-size: 16px;
        font-weight: 300;
    }

    .backupModem {
        width: 50%;
        background-color: white;
    }

    .labelForm {
        position: absolute;
        top: -32px;
        left: 15px;
        display: flex;
        padding: 2px 10px 2px 8px;

        @include themify($themes) {
            color: themed('primary_color');
        }

        font-family: Orkney, sans-serif;
        font-size: 18px;
        font-weight: 300;
        white-space: nowrap;
        cursor: text;
        background-color: inherit;
        transform: translateY(50%);
    }

    .interfaces {
        flex-wrap: wrap;

        .port {
            display: inline-block;
            width: 110px;
            margin-right: 15px;
            margin-bottom: 15px;
            background-color: white;
            border-radius: 2px;
            transition: 0.3s all;

            @include themify($themes) {
                border: solid 1px themed('tertiary_color');
            }
        }

        input[type="radio"]:before {
            @include themify($themes) {
                border: solid 1px themed('primary_color');
            }
        }

        .disabled {
            @include themify($themes) {
                background-color: themed('grayscale_extralight');
            }

            input[type="radio"]:before {
                @include themify($themes) {
                    background-color: themed('grayscale_extralight');
                    border: solid 1px themed('grayscale_regular');
                }
            }
        }

        .selected {
            @include themify($themes) {
                background-color: lighten(desaturate(themed('main_color'), 0.7), 70);
            }

            input[type="radio"]:before {
                @include themify($themes) {
                    background-color: lighten(desaturate(themed('main_color'), 0.7), 70);
                    border: solid 1px themed('primary_color');
                }
            }
        }
    }

    .disabledInterfaces {
        @include themify($themes) {
            color: themed('grayscale_medium');
        }

        .port {
            @include themify($themes) {
                border: solid 1px themed('grayscale_extralight');
            }
        }
    }

    .placeholderButton {
        align-self: center;
        width: fit-content;
        margin-top: 20px;
    }

    .portTitle {
        font-size: 16px;
        font-weight: 300;
    }

    .modem {
        .equipmentTitle {
            font-size: 18px;
            font-weight: 300;
        }

        .modemName {
            font-size: 12px;
            font-weight: 300;
            line-height: 2.08;
        }

        .customerOwnedModem {
            .picture {
                @include themify($themes) {
                    padding: 30px;
                    $bg: lighten(saturate(adjust-hue(themed('tertiary_color'), 1), 19.1), 1.2);

                    background-color: $bg;
                    border-radius: 3px;
                }
            }
        }

        .unavailableInfoValue {
            @include themify($themes) {
                color: themed('grayscale_medium');
            }
        }
    }
}
</style>
