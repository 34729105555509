<template>
    <div :class="$style.advancedSection">
        <h3
            v-translate
            class="my-3"
        >
            Advanced configurations
        </h3>
        <div :class="$style.advancedList">
            <template
                v-for="(configuration, index) in advancedConfigurations"
            >
                <div
                    v-if="configuration.enabled"
                    :key="index"
                    :class="$style.advancedBlock"
                    @click="$emit(
                        'click-component',
                        configuration.component,
                        configuration.sectionSuffix,
                    )"
                >
                    <div :class="$style.nameBlock">
                        {{ configuration.name }}
                    </div>
                    <div :class="$style.descriptionBlock">
                        {{ configuration.description }}
                    </div>
                    <MasterSvg
                        use="arrow-right"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MasterSvg from '../master-svg.vue';
import SnmpCommunities from './snmp-communities/snmp-communities.vue';
import Wifi from './wifis/wifis.vue';

export default {
    components: {
        MasterSvg,
    },
    props: {
        initChoicedAdvanced: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'full_capabilities',
        ]),
        advancedConfigurations() {
            return [
                {
                    enabled: this.hasCapability('snmp_communities'),
                    name: 'SNMP communities',
                    description: this.$gettext(
                        'Communication protocol that allows network administrators to manage network equipment, monitor and diagnose network and hardware issues remotely.',
                    ),
                    component: SnmpCommunities,
                    sectionSuffix: 'snmp',
                },
                {
                    enabled: this.hasCapability('wifis'),
                    name: 'WIFI',
                    description: this.$gettext(
                        'Wi-Fi network makes it possible to connect several devices within a network by radio waves in order to allow data transmission between them.',
                    ),
                    component: Wifi,
                    sectionSuffix: 'wifis',
                },
            ];
        },
    },
    watch: {
        initChoicedAdvanced: {
            handler() {
                this.initComponent();
            },
            immediate: true,
        },
    },
    methods: {
        initComponent() {
            const avancedConfiguration = this.advancedConfigurations.find(
                conf => conf.sectionSuffix === this.initChoicedAdvanced,
            );

            if (avancedConfiguration) {
                this.$emit(
                    'click-component',
                    avancedConfiguration.component,
                    avancedConfiguration.sectionSuffix,
                );
            } else {
                this.$emit('click-component');
            }
        },
        hasCapability(capability) {
            return this.full_capabilities.includes(capability);
        },
    },
};
</script>

<style lang="scss" module>
.advancedSection {
    .advancedList {
        display: flex;

        .advancedBlock {
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(50% - 10px);
            height: 140px;
            padding: 20px 20px 19px 20px;
            margin: 10px 0;
            text-align: left;
            cursor: pointer;
            border-radius: 4px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

            @include under-to(lg) {
                width: 100%;
            }

            &:nth-of-type(odd) {
                margin-right: 10px;
            }

            &:nth-of-type(even) {
                margin-left: 10px;
            }

            &:hover {
                svg {
                    right: 21px;
                }
            }

            .nameBlock {
                font-size: 18px;
                font-weight: 500;
                line-height: 1.1;

                @include themify($themes) {
                    color: themed('main_color');
                }
            }

            .descriptionBlock {
                margin-top: 10px;
                overflow: hidden;
                font-size: 12px;
                font-weight: 300;
                line-height: 1.57;
                text-overflow: ellipsis;
            }

            svg {
                position: absolute;
                top: 21px;
                right: 28px;
                width: 13.7px;
                height: 11.3px;
                transition: 0.3s ease right;

                @include themify($themes) {
                    fill: themed('primary_color');
                }
            }
        }
    }
}
</style>
