<template>
    <Fade>
        <div
            v-if="show_current_banner"
            :class="[
                $style.banner,
                $style[type],
                {[$style.background]: background}
            ]"
        >
            <div
                v-if="icon || $slots.svg"
                :class="{
                    [$style.small_icon]: smallIcon,
                    [$style.large_icon]: !smallIcon,
                }"
            >
                <slot name="svg">
                    <MasterSvg
                        :use="icon"
                        :width="mainWidthIcon"
                        :height="mainHeightIcon"
                    />
                </slot>
            </div>
            <div :class="$style.content">
                <slot />
            </div>
            <div
                v-if="haveAction"
                :class="$style.action"
            >
                <slot name="action" />
            </div>
            <div
                v-if="canBeClosed"
                :class="$style.cross"
            >
                <span @click="close">
                    <MasterSvg
                        use="cross"
                        width="10px"
                        height="10px"
                    />
                </span>
            </div>
        </div>
    </Fade>
</template>

<script>
import MasterSvg from './master-svg.vue';
import Fade from './transitions/fade.vue';

export default {
    name: 'Banner',
    components: {
        MasterSvg,
        Fade,
    },
    props: {
        id: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        smallIcon: {
            type: Boolean,
            default: true,
        },
        canBeClosed: {
            type: Boolean,
            default: true,
        },
        background: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'info',
            validator(value) {
                return [
                    'info',
                    'help',
                    'error',
                    'warning',
                    'neutral',
                ].indexOf(value) !== -1;
            },
        },
        mainWidthIcon: {
            type: String,
            default: null,
        },
        mainHeightIcon: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            show_current_banner: true,
        };
    },
    computed: {
        haveAction() {
            return this.$slots.action;
        },
    },
    created() {
        if (!this.id) {
            return;
        }

        let bannersSettings = [];
        if (localStorage.banners_settings) {
            bannersSettings = JSON.parse(localStorage.banners_settings);
        }
        const localBanner = bannersSettings.find(
            item => item.id === this.id,
        );
        if (localBanner && this.canBeClosed) {
            this.show_current_banner = !localBanner.hide_banner;
        }
    },
    methods: {
        click() {
            this.$emit('click');
        },
        close() {
            if (!this.id) {
                this.show_current_banner = false;
                return;
            }

            let bannersSettings = [];
            if (localStorage.banners_settings) {
                bannersSettings = JSON.parse(localStorage.banners_settings);
            }

            const localBanner = bannersSettings.find(
                item => item.id === this.id,
            );
            if (!localBanner) {
                bannersSettings.push({
                    id: this.id,
                    hide_banner: true,
                });
            } else {
                localBanner.hide_banner = true;
            }

            this.show_current_banner = false;
            localStorage.banners_settings = JSON.stringify(bannersSettings);
        },
    },
};
</script>

<style lang="scss" module>
.banner {
    $banner_themes: none;

    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 16px 16px 13px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(black, 0.1);

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        content: '';
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    @include themify($themes) {
        $banner_themes: (
            (
                name: 'info',
                attributes: (
                    before_bg_color: themed('primary_color'),
                    bg_color: rgba(themed('primary_color'), 0.1),
                    svg_color:  themed('primary_color'),
                    svg_fill:  themed('primary_color'),
                )
            ),
            (
                name: 'error',
                attributes: (
                    before_bg_color: themed('error_color'),
                    bg_color: rgba(themed('error_color'), 0.1),
                    svg_color:  themed('error_color'),
                    svg_fill:  themed('error_color'),
                )
            ),
            (
                name: 'warning',
                attributes: (
                    before_bg_color: themed('warning_color'),
                    bg_color: rgba(themed('warning_color'), 0.1),
                    svg_color:  themed('warning_color'),
                    svg_fill:  themed('warning_color'),
                )
            ),
            (
                name: 'help',
                attributes: (
                    before_bg_color: #FFB700,
                    bg_color: rgba(#FFB700, 0.1),
                    svg_color:  #FFB700,
                    svg_fill:  #FFB700,
                )
            ),
            (
                name: 'neutral',
                attributes: (
                    before_bg_color: themed('grayscale_bold'),
                    bg_color: rgba(themed('grayscale_bold'), 0.1),
                    svg_color:  themed('grayscale_bold'),
                    svg_fill:  themed('grayscale_bold'),
                )
            ),
        );
    }

    @each $theme in $banner_themes {
        $name: map-get($theme, name);
        $attributes: map-get($theme, attributes);

        &.#{$name} {
            &:before {
                background-color: map-get($attributes, before_bg_color);
            }

            &.background {
                background-color: map-get($attributes, bg_color);
            }

            .icon {
                svg {
                    color: map-get($attributes, svg_color);
                    fill: map-get($attributes, svg_fill);
                }
            }
        }
    }

    .icon {
        display: flex;
        flex: 0;
        align-self: flex-start;
    }

    .small_icon {
        @extend .icon;

        margin-right: 15px;
        margin-left: 5px;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .large_icon {
        @extend .icon;

        margin-right: 12px;

        svg {
            width: 26px;
            height: 26px;
        }
    }

    .content {
        flex: 1;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.5;

        @include themify($themes) {
            color: themed('grayscale_bold');
        }
    }

    .action {
        margin-left: 15px;
    }

    .cross {
        margin-left: 13px;
        cursor: pointer;

        svg {
            width: 10px;
            height: 10px;

            @include themify($themes) {
                fill: themed('grayscale_medium');
            }
        }
    }

    & + & {
        margin-top: 10px;
    }
}
</style>
