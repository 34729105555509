const qs = require('qs');

const listoperator = [
    'ORANGE',
    'SFR',
    'AXIONE',
    'BOUYGUES TELECOM',
    'KOSC',
];

const listoffer = [
    'SDSL',
];

const state = {
    eligibility_number: null,
    eligibility_result: {},
    request_id: null,
    buying_products: [],
    product_relations: [],
    eligible_offers: [],
    allowed_unbundling_types: [],
};

const getters = {
    eligibility_number: state => state.eligibility_number,
    eligibility_result: state => state.eligibility_result,
    request_id: state => state.request_id,
    eligibility_products: state => state.eligibility_products,
    best_offer: state => state.best_offer,
    other_offers: state => state.other_offers,
    buying_products: state => state.buying_products,
    product_relations: state => state.product_relations,
    eligible_offers: state => state.eligible_offers,
    allowed_unbundling_types: state => state.allowed_unbundling_types,
};

const mutations = {
    update_eligibility_number(state, eligibilityNumber) {
        state.eligibility_number = eligibilityNumber;
    },
    update_eligibility_result(state, eligibilityResult) {
        state.eligibility_result = eligibilityResult;
    },
    updateRequestId(state, requestId) {
        state.request_id = requestId;
    },
    update_eligibility_products(state, eligibilityProducts) {
        state.eligibility_products = eligibilityProducts;
    },
    update_buying_products(state, buyingProducts) {
        state.buying_products = buyingProducts;
    },
    update_products_relations(state, productRelations) {
        state.product_relations = productRelations;
    },
    update_eligible_offers(state, eligibleOffers) {
        state.eligible_offers = eligibleOffers;
    },
    update_allowed_unbundling_types(state, allowedUnbundlingTypes) {
        state.allowed_unbundling_types = allowedUnbundlingTypes;
    },
    add_iplink_offer(state, payload) {
        let posSellingArray = [];
        posSellingArray = state.product_relations.reduce((ind, el, i) => {
            if (el._buying_product.code === payload.code) {
                ind.push(i);
            }
            return ind;
        }, []);

        posSellingArray.forEach((index) => {
            const sellingProductRef = state.product_relations[index]._selling_product.code;
            const posoffer = state.eligible_offers.map(
                e => e._product.code,
            ).indexOf(sellingProductRef);
            if (!state.eligible_offers[posoffer]._product.iplink_offer) {
                [state.eligible_offers[posoffer]._product.iplink_offer] = payload.offer;
            }
        });
    },
    update_product_telephony(state, buyingProducts) {
        let productTelephony = [];
        let productCode = '';
        const trunkProduct = buyingProducts.filter(
            product => product._buying_product._product_telephony,
        );
        trunkProduct.forEach((el) => {
            productCode = '';
            if (el._buying_product._product_telephony) {
                productTelephony = el._buying_product._product_telephony;
                productCode = el._selling_product.code;

                state.eligible_offers.forEach((elem) => {
                    if (elem._product.code === productCode) {
                        elem._product._product_telephony = productTelephony;
                    }
                });
            }
        });
    },
};
const actions = {
    fetch_eligibility({
        state,
        rootState,
    }) {
        return new Promise((resolve, reject) => {
            const url = `/api/eligibility/request/${state.request_id}/`;
            rootState.axios_api.get(url)
                .then((response) => {
                    resolve(response.data);
                }).catch(reject);
        });
    },

    fetch_eligibility_products({
        commit,
        dispatch,
        rootState,
    }, params) {
        const queryParams = { eligibility_request: params.request_id };
        if (params.distributorId) {
            queryParams.distributor_id = params.distributorId;
        }

        return new Promise((resolve, reject) => {
            const url = '/api/legacy/pricingcataloglines/';
            rootState.axios_api.get(url, { params: queryParams })
                .then((response) => {
                    commit('update_eligibility_products', response.data);
                    dispatch('fetch_buying_products', params.distributorId).then(() => {
                        resolve();
                    }).catch(error => reject(error));
                }).catch(reject);
        });
    },

    fetch_buying_products({
        commit,
        dispatch,
        state,
        rootState,
    }, distributorId) {
        return new Promise((resolve, reject) => {
            const url = '/api/legacy/productrelations/';
            const sellingProductIds = [];
            state.eligibility_products.forEach((element) => {
                if (!sellingProductIds.includes(element._product.id)) {
                    sellingProductIds.push(element._product.id);
                }
            });
            const config = {
                params: {
                    selling_product: sellingProductIds,
                },
                paramsSerializer(params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            };
            rootState.axios_api.get(url, config)
                .then((response) => {
                    commit('update_buying_products', response.data);
                    dispatch('fetch_product_relations', distributorId).then(resolve).catch(reject);
                }).catch(reject);
        });
    },

    fetch_buying_products_bundle({
        commit,
        state,
        rootState,
    }) {
        return new Promise((resolve, reject) => {
            const url = '/api/legacy/productrelations/';
            const sellingProductIds = [];
            state.product_relations.forEach((element) => {
                if (!sellingProductIds.includes(element._selling_product.id)) {
                    sellingProductIds.push(element._selling_product.id);
                }
            });
            const config = {
                params: {
                    selling_product: sellingProductIds,
                },
                paramsSerializer(params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            };
            rootState.axios_api.get(url, config)
                .then((response) => {
                    commit('update_product_telephony', response.data);
                    resolve();
                }).catch(reject);
        });
    },

    fetch_product_relations({
        dispatch,
        commit,
        state,
        rootState,
    }, distributorId) {
        return new Promise((resolve, reject) => {
            const url = '/api/legacy/productrelations/';
            const buyingProductIds = [];
            state.buying_products.forEach((element) => {
                buyingProductIds.push(element.buying_product);
            });
            const config = {
                params: {
                    buying_product: buyingProductIds,
                    product_type: 'bundle',
                },
                paramsSerializer(params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            };
            rootState.axios_api.get(url, config)
                .then((response) => {
                    commit('update_products_relations', response.data);
                    dispatch('fetch_bundle_princing_catalog_line', distributorId).then((pricingResponse) => {
                        const offersCodes = [];
                        response.data.forEach((element) => {
                            if (!offersCodes.includes(element._buying_product.code)) {
                                offersCodes.push(element._buying_product.code);
                            }
                        });
                        Promise.all(
                            offersCodes.map(
                                element => dispatch('fetch_iplink_offer', element),
                            ),
                        ).then(() => {
                            resolve(pricingResponse);
                        });
                    }).catch(reject);
                }).catch(reject);
        });
    },

    fetch_bundle_princing_catalog_line({
        state,
        rootState,
        dispatch,
        commit,
    }, distributorId) {
        return new Promise((resolve, reject) => {
            const url = '/api/legacy/pricingcataloglines/fetch_with_total_buying/';
            const productCodes = [];
            state.product_relations.forEach((element) => {
                productCodes.push(element._selling_product.code);
            });
            const config = {
                params: {
                    product_code: productCodes,
                },
                paramsSerializer(params) {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
            };

            if (distributorId) {
                config.params.distributor_id = distributorId;
            }
            rootState.axios_api.get(url, config)
                .then((response) => {
                    commit('update_eligible_offers', response.data);
                    dispatch('fetch_buying_products_bundle').then(() => {
                        resolve(response);
                    });
                }).catch(reject);
        });
    },

    fetch_iplink_offer({
        rootState,
        commit,
    }, offer) {
        return new Promise((resolve, reject) => {
            const url = '/api/legacy/iplinkoffers/';
            rootState.axios_api.get(url, {
                params: { buying_product_code: offer },
            }).then((response) => {
                commit('add_iplink_offer', { code: offer, offer: response.data });
                resolve(response);
            }).catch(reject);
        });
    },

    create_eligibility({
        commit,
        rootState,
        state,
    }) {
        return new Promise((resolve, reject) => {
            const url = '/api/eligibility/request/';
            rootState.axios_api.post(url, {
                settings: {
                    providers: listoperator,
                    offer_types: listoffer,
                    nd: state.eligibility_number,
                    inactive_nd: 0,
                },
                priority: 20,
            }).then((response) => {
                commit('updateRequestId', response.data.id);
                resolve(response.data);
            }).catch(reject);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
