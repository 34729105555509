var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.connectedDevicesList },
    [
      _vm.visibleConnectedDevicesTableEntries.length
        ? _c(
            "ListHeader",
            {
              attrs: { breakpoint: "xl" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after",
                    fn: function() {
                      return [_c("span", { style: { width: "14px" } })]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1357073457
              )
            },
            [
              _c(
                "span",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [_vm._v("MAC address")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [_vm._v("Hostname")]
              ),
              _vm._v(" "),
              _vm.canIPv4
                ? _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [_vm._v("\n            IPv4 address\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canIPv6
                ? _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [_vm._v("\n            IPv6 addresses\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [_vm._v("Interface")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.connectedDevicesTableEntriesLoading
        ? _vm._l(_vm.page_size, function(index) {
            return _c("ListItem", {
              key: index,
              class: _vm.$style.oldListItem,
              attrs: {
                disabled: "",
                "column-number": _vm.columnNumber,
                breakpoint: "xl"
              }
            })
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !_vm.connectedDevicesTableEntriesLoading &&
                _vm.visibleConnectedDevicesTableEntries.length,
              expression:
                "\n            !connectedDevicesTableEntriesLoading\n                && visibleConnectedDevicesTableEntries.length\n        "
            }
          ]
        },
        _vm._l(_vm.visibleConnectedDevicesTableEntries, function(
          connectedDevicesEntry,
          index
        ) {
          return _c("ConnectedDevicesItem", {
            key: index,
            attrs: { "connected-device": connectedDevicesEntry },
            on: {
              create_nat_rule: _vm.createNatRule,
              create_firewall_rule: _vm.createFirewallRule
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.bottom },
        [
          _vm.nb_pages > 1
            ? _c("PaginateVue", {
                attrs: {
                  "items-count":
                    _vm.connectedDevicesTableEntriesEquipments.length,
                  "items-per-page": _vm.page_size
                },
                model: {
                  value: _vm.current_page,
                  callback: function($$v) {
                    _vm.current_page = $$v
                  },
                  expression: "current_page"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("ComponentEmptySearch", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.connectedDevicesTableEntriesLoading &&
              !_vm.visibleConnectedDevicesTableEntries.length,
            expression:
              "\n            !connectedDevicesTableEntriesLoading\n                && !visibleConnectedDevicesTableEntries.length\n        "
          }
        ],
        attrs: { size: "small" },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("\n                No result found\n            ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "text",
            fn: function() {
              return [
                _vm.connectedDevicesTableEntries &&
                _vm.connectedDevicesTableEntries.length
                  ? _c(
                      "span",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [
                        _vm._v(
                          "\n                Please try again with an other search\n            "
                        )
                      ]
                    )
                  : _c(
                      "span",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [
                        _vm._v(
                          "\n                Please connect a device to your equipment and refresh the list\n            "
                        )
                      ]
                    )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }