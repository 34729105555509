var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.eventItemLoader }, [
    _c("div", { class: _vm.$style.header }, [
      _c("div", { class: _vm.$style.iconAndTitle }, [
        _c("span", { class: _vm.$style.icon }),
        _vm._v(" "),
        _c("span")
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.updatedAt }, [_c("span")])
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.$style.itemContent }, [_c("span")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }