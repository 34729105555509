var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.dropdownMenuItem,
        _vm.$style[_vm.color],
        ((_obj = {}), (_obj[_vm.$style.disabled] = _vm.disabled), _obj)
      ],
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.handleClick.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { class: _vm.$style.dropdownMenuItemIcon },
        [_c("MasterSvg", { attrs: { use: _vm.icon } })],
        1
      ),
      _vm._v(" "),
      _c("span", { class: _vm.$style.dropdownMenuItemName }, [
        _vm._v("\n        " + _vm._s(_vm.name) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }