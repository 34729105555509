var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.adminBox }, [
    _c("h3", { directives: [{ name: "translate", rawName: "v-translate" }] }, [
      _vm._v("\n        Manage the equipment by yourself\n    ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.content },
      [
        _c("div", { class: _vm.$style.description }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c("Button", {
          attrs: {
            role: "primary",
            text: _vm.buttonText,
            disabled: _vm.buttonDisable,
            icon: "user",
            size: "large"
          },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }