<template>
    <div>
        <header>
            <b-navbar
                toggleable="lg"
                type="dark"
                variant="dark"
            >
                <router-link
                    tag="b-navbar-brand"
                    class="nav-item pointer"
                    href="/"
                    :to="{name: 'home'}"
                    exact
                >
                    unyc
                </router-link>

                <b-navbar-toggle target="nav-collapse" />

                <b-collapse
                    id="nav-collapse"
                    is-nav
                >
                    <b-navbar-nav>
                        <template v-for="item in routes">
                            <router-link
                                :key="item.name"
                                tag="b-nav-item"
                                class="nav-item"
                                :href="item.path"
                                active-class="active"
                                :to="{name: item.name}"
                                exact
                            >
                                {{ item.label }}
                            </router-link>
                        </template>
                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item-dropdown right>
                            <!-- Using 'button-content' slot -->
                            <template slot="button-content">
                                <em><translate>More</translate></em>
                            </template>
                            <b-dropdown-item href="/admin/">
                                <translate>Admin</translate>
                            </b-dropdown-item>
                            <b-dropdown-item
                                href="/logout"
                                @click="onSignout"
                            >
                                <translate>Sign Out</translate>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </header>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import {
    BNavbar,
    BCollapse,
    BNavbarToggle,
    BNavItemDropdown,
    BNavbarNav,
    BDropdownItem,
    BNavbarBrand,
    BNavItem,
} from 'bootstrap-vue';

Vue.component('b-navbar', BNavbar);
Vue.component('b-navbar-toggle', BNavbarToggle);
Vue.component('b-collapse', BCollapse);
Vue.component('b-nav-item', BNavItem);
Vue.component('b-nav-item-dropdown', BNavItemDropdown);
Vue.component('b-navbar-nav', BNavbarNav);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-navbar-brand', BNavbarBrand);
Vue.component('b-navbar-toogle', BNavbarBrand);

export default {
    data() {
        return {
            routes: [],
            onSignout() {
                this.axios_api.delete('/api/account/login/')
                    .then(() => {
                        window.location.reload();
                    });
            },
        };
    },
    computed: {
        ...mapGetters([
            'permissions',
            'axios_api',
        ]),
    },
    created() {
        this.$router.options.routes.forEach((route) => {
            if (!route.header) return;
            this.routes.push({
                name: route.name,
                label: route.label,
                path: route.path,
            });
        });
    },
};

</script>

<style lang="scss" module>

</style>
