var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.lanSectionOptions },
    [
      _vm.addressing.ip_version === 6
        ? _c("SLAACConfiguration", {
            attrs: { addressing: _vm.addressing, errors: _vm.slaacErrors }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.addressing.ip_version === 6
        ? _c("hr", { class: _vm.$style.separator })
        : _vm._e(),
      _vm._v(" "),
      _c("DHCPConfiguration", {
        attrs: {
          addressing: _vm.addressing,
          errors: _vm.dhcpErrors,
          disabled: _vm.disbaledDhcpConfig
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }