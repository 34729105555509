<template>
    <div :class="$style.firewalllist">
        <div
            class="d-none px-4"
            :class="[
                $style.header,
                {'d-xl-block': visible_rules.length}
            ]"
        >
            <div class="row mx-xl-0">
                <div
                    v-translate
                    class="px-xl-0 col-2"
                >
                    Name
                </div>
                <div
                    v-translate
                    class="col-2"
                >
                    Protocol
                </div>
                <div
                    v-translate
                    class="col-2"
                >
                    IP / Subnet source
                </div>
                <div
                    v-translate
                    class="col-2"
                >
                    Destination IP
                </div>
                <div
                    v-translate
                    class="col-2"
                >
                    Port
                </div>
                <div
                    v-translate
                    class="col-1"
                >
                    State
                </div>
            </div>
        </div>
        <div class="mt-3">
            <firewall-item
                v-for="(firewallrule, index) in visible_rules"
                :key="index"
                :firewallrule="firewallrule"
                @delete_rule="deleteRule(firewallrule.index)"
                @edit_rule="editRule(firewallrule)"
                @toggle_activation="toggleActivation(firewallrule.index)"
            />
        </div>
        <div :class="$style.bottom">
            <PaginateVue
                v-if="nb_pages > 1"
                v-model="current_page"
                :items-count="filtered_rules.length"
                :items-per-page="page_size"
            />
        </div>
        <ComponentEmptySearch
            v-show="!visible_rules.length"
            size="small"
        >
            <template v-slot:title>
                <div v-translate>
                    No result found
                </div>
            </template>
            <template v-slot:text>
                <div v-translate>
                    Please try again with an other search
                </div>
            </template>
        </ComponentEmptySearch>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PaginateVue from '@/components/common/paginate.vue';
import {
    showSwalError,
    showToast,
} from '@/utils/utils';
import { actionsControl } from './utils/utils';
import FirewallItem from './firewall-listitem.vue';
import ComponentEmptySearch from './component-empty-search.vue';

const AddFirewallRuleModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-addfirewallrule" */
    './modals/addfirewallrule.vue'
);

const PAGE_SIZE_DESKTOP = 5;
const PAGE_SIZE_MOBILE = 5;

export default {
    components: {
        FirewallItem,
        PaginateVue,
        ComponentEmptySearch,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        filterByStatus: {
            type: [String, Boolean],
            default: null,
        },
        filterByIpVersion: {
            type: [String, Number],
            default: 'all',
        },
        group: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            current_page: 0,
            show_from_nat: true,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconf',
        ]),
        rules() {
            return (this.linkequipmentconf && this.linkequipmentconf.firewall) || [];
        },
        page_size() {
            if (this.$mq !== 'xs' && this.$mq !== 'sm' && this.$mq !== 'md') {
                return PAGE_SIZE_DESKTOP;
            }
            return PAGE_SIZE_MOBILE;
        },
        nb_pages() {
            return Math.ceil(this.filtered_rules.length / this.page_size);
        },
        filtered_rules() {
            // Saving original indexes for later manipulations
            let filteredRules = this.rules.map((item, index) => {
                const filtered = JSON.parse(JSON.stringify(item));
                filtered.index = index;
                return filtered;
            });

            if (filteredRules.length) {
                const _this = this;

                const matchesSearch = (str) => {
                    if (!str) {
                        return false;
                    }
                    if (typeof str === 'object') {
                        str = str.join(',');
                    }
                    return str.toLowerCase().indexOf(_this.search.toLowerCase()) !== -1;
                };

                filteredRules = filteredRules.filter((item) => {
                    if (this.group.category !== item.category) {
                        return false;
                    }
                    if (item.ip_version !== _this.filterByIpVersion && _this.filterByIpVersion !== 'all') {
                        return false;
                    }
                    if (
                        item.enabled !== _this.filterStatusMapping
                        && _this.filterStatusMapping !== null
                    ) {
                        return false;
                    }
                    return (
                        matchesSearch(item.name)
                        || matchesSearch(item.source_ips)
                        || matchesSearch(item.destination_ip)
                        || matchesSearch(item.ports));
                });
            }
            return filteredRules;
        },
        filterStatusMapping() {
            if (this.filterByStatus === 'active') return true;
            if (this.filterByStatus === 'inactive') return false;
            return null;
        },
        visible_rules() {
            if (this.current_page > (this.nb_pages - 1)) {
                this.setPage(this.nb_pages - 1);
            }
            return this.filtered_rules.slice(
                this.current_page * this.page_size,
                (this.current_page + 1) * this.page_size,
            );
        },
    },
    mounted() {
        this.fetch_linkequipmentconf().catch(showSwalError);
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_linkequipmentconf',
            'set_conf_section',
        ]),
        setPage(page) {
            if (page < 0) page = 0;
            this.current_page = page;
        },
        toggleActivation(index) {
            actionsControl(this.linkequipment).then(() => {
                this.rules[index].enabled = !this.rules[index].enabled;
                this.set_conf_section({
                    section: 'firewall',
                    conf: this.rules,
                }).then(() => {
                    showToast(this.$gettext('Firewall rule updated'));
                }).catch(showSwalError);
            }).catch(showSwalError);
        },
        deleteRule(index) {
            actionsControl(this.linkequipment).then(() => {
                this.rules.splice(index, 1);
                this.set_conf_section({
                    section: 'firewall',
                    conf: this.rules,
                }).then(() => {
                    showToast(this.$gettext('Firewall rule deleted'));
                }).catch(showSwalError);
            }).catch(showSwalError);
        },
        editRule(firewallRule) {
            this.show_modal(
                AddFirewallRuleModal,
                {
                    'default-rule': firewallRule,
                    'is-new-rule': false,
                    group: this.group,
                },
                {
                    maxHeight: 512,
                },
            );
        },
    },
};
</script>

<style lang="scss" module>
.firewalllist {
    .header {
        margin-top: 26px;
        margin-bottom: 20px;
        font-size: 12px;
        white-space: nowrap;

        @include themify($themes) {
            color: themed('main_secondary_color');
        }
    }

    .bottom {
        margin-top: 10px;
    }

    .body2 {
        font-weight: normal;

        @include themify($themes) {
            color: themed("gray_scale_dark");
        }
    }
}
</style>
