<template>
    <div :class="[$style.titlesection, {'col': addColClass}]">
        <div
            v-if="showPreviousButton"
            :class="$style.previous_button"
            @click.prevent="$emit('previous_action')"
        >
            <MasterSvg
                use="arrow-right"
                width="17.5px"
                height="15px"
            />
        </div>
        <h3>
            {{ title }}
        </h3>
        <div
            v-if="!current_section_advice_visible"
            :class="[$style.show_advice, $style.flyinginfo]"
            :data-content="$gettext('Show advice')"
            @click="showCurrentSectionAdvice"
        >
            <MasterSvg
                use="interrogation"
                width="18px"
                height="18px"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import MasterSvg from './master-svg.vue';

export default {
    components: {
        MasterSvg,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        showPreviousButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        addColClass: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'current_section_advice_visible',
        ]),
    },
    methods: {
        ...mapMutations('equipment', [
            'showCurrentSectionAdvice',
        ]),
    },
};
</script>

<style lang="scss" module>
.titlesection {
    display: flex;
    align-self: center;

    .previous_button {
        display: flex;
        align-items: center;
        margin-right: 19px;
        cursor: pointer;
        transform: rotate(180deg);

        @include themify($themes) {
            fill: themed('primary_color');
        }
    }

    .show_advice {
        display: flex;
        align-self: center;
        width: 18px;
        height: 18px;
        margin-left: 10px;
        cursor: pointer;

        @include themify($themes) {
            fill: themed('primary_color');
        }
    }

    .flyinginfo {
        position: relative;

        &:before {
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            margin-top: 2px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        &:after {
            position: absolute;
            top: calc(100% + 7px);
            left: 50%;
            z-index: 4;
            display: none;
            width: max-content;
            width: intrinsic;
            padding: 5px 20px;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 1.57;
            color: black;
            text-align: center;
            white-space: pre;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            transition: 0.3s ease all;
            transform: translateX(-50%);

            @include themify($themes) {
                box-shadow: 0 0 12px 0 rgba(themed('primary_color'), 0.4);
            }
        }

        &:hover {
            &:after,
            &:before {
                display: block;
                transition: 0.3s ease all;
            }
        }
    }
}
</style>
