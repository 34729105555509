var render = function() {
  var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.prettybutton }, [
    _vm.role === "primary" ||
    _vm.role === "secondary" ||
    _vm.role === "ghost" ||
    _vm.role === "inverted"
      ? _c(
          "button",
          {
            class: [
              _vm.$style.btn,
              _vm.$style[_vm.role],
              _vm.$style[_vm.size],
              ((_obj = {}), (_obj[_vm.$style.disabled] = _vm.disabled), _obj),
              ((_obj$1 = {}),
              (_obj$1[_vm.$style.knowmore] = _vm.knowmore),
              _obj$1)
            ],
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.clickEvent()
              }
            }
          },
          [
            _vm.display_icon
              ? _c("MasterSvg", { attrs: { use: _vm.display_icon } })
              : _vm._e(),
            _vm._v(" "),
            _vm.text
              ? _c("span", [
                  _vm._v("\n            " + _vm._s(_vm.text) + "\n        ")
                ])
              : _vm._e()
          ],
          1
        )
      : _vm.role === "icon_primary" || _vm.role === "icon_secondary"
      ? _c(
          "div",
          {
            class: [
              _vm.$style[_vm.role],
              ((_obj$2 = {}),
              (_obj$2[_vm.$style.disabled] = _vm.disabled),
              _obj$2),
              ((_obj$3 = {}),
              (_obj$3[_vm.$style.knowmore] = _vm.knowmore),
              _obj$3)
            ],
            on: {
              click: function($event) {
                return _vm.clickEvent()
              }
            }
          },
          [
            _c("MasterSvg", { attrs: { use: _vm.display_icon } }),
            _vm._v(" "),
            _vm.text
              ? _c("span", [
                  _vm._v("\n            " + _vm._s(_vm.text) + "\n        ")
                ])
              : _vm._e()
          ],
          1
        )
      : _c(
          "div",
          {
            class: [
              _vm.$style.link,
              ((_obj$4 = {}),
              (_obj$4[_vm.$style.disabled] = _vm.disabled),
              _obj$4),
              ((_obj$5 = {}),
              (_obj$5[_vm.$style.knowmore] = _vm.knowmore),
              _obj$5)
            ],
            on: {
              click: function($event) {
                return _vm.clickEvent()
              }
            }
          },
          [
            _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.text ? _vm.text : "Link") +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _vm.display_icon
              ? _c("MasterSvg", { attrs: { use: _vm.display_icon } })
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }