<template>
    <span
        :class="[
            $style.interfacesRouterPort,
            {
                [$style.used] : used,
                [$style.selected] : selected,
            },
            ledState,
        ]"
    >
        <span @click="handleCick">
            <MasterSvg use="port" />
        </span>
        <p
            class="text-center"
            :class="$style.number"
        >
            {{ displayPortNumber }}
        </p>
    </span>
</template>

<script>
import MasterSvg from '../../master-svg.vue';

export default {
    components: {
        MasterSvg,
    },
    props: {
        portNumber: {
            type: Number,
            required: true,
        },
        equipmentData: {
            type: Object,
            required: true,
        },
        enabled: {
            type: Boolean,
            default: false,
        },
        used: {
            type: Boolean,
            default: false,
        },
        linked: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ledState() {
            if (!this.enabled) return this.$style.down;
            if (this.linked) return this.$style.up;
            return this.$style.warning;
        },
        displayPortNumber() {
            let firstInterfaceNumber = 0;
            if ((this.equipmentData || {}).first_interface_number) {
                firstInterfaceNumber = this.equipmentData.first_interface_number;
            }

            return this.portNumber + firstInterfaceNumber;
        },
    },
    methods: {
        handleCick() {
            if (!this.used) return;
            this.$emit('selected', this.selected ? null : this.portNumber);
        },
    },
};
</script>

<style lang="scss" module>
.interfacesRouterPort {
    $grayLightColor: none;
    $grayMediumColor: none;
    $mainColor: none;
    $primaryColor: none;
    $secondaryColor: none;
    $tertitaryColor: none;
    $errorColor: none;
    $successColor: none;

    width: 26px;
    height: 26px;

    @include themify($themes) {
        $grayLightColor: themed('grayscale_light');
        $grayMediumColor: themed('grayscale_medium');
        $mainColor: themed('main_color');
        $primaryColor: themed('primary_color');
        $secondaryColor: themed('secondary_color');
        $tertitaryColor: themed('tertiary_color');
        $errorColor: themed('error_color');
        $successColor: themed('success_color');
    }

    .number {
        margin-top: 5px;
        font-size: 9px;
        font-weight: 500;
        text-align: center;
        letter-spacing: normal;

        @include themify($themes) {
            color: themed('main_color');
        }
    }

    svg {
        width: 26px;
        height: 26px;

        @include themify($themes) {
            --border-color: #{$grayMediumColor};
            --pins-color: #{$grayMediumColor};
            --port-color: #{$grayLightColor};
            --led-color: #{$grayMediumColor};
        }
    }

    &.up {
        svg {
            @include themify($themes) {
                --led-color: #{$successColor};
            }
        }
    }

    &.down {
        svg {
            @include themify($themes) {
                --led-color: #{$errorColor};
            }
        }
    }

    &.warning {
        svg {
            @include themify($themes) {
                --led-color: orange;
            }
        }
    }

    &.selected {
        svg {
            @include themify($themes) {
                --border-color: #{$mainColor} !important;
                --pins-color: #{$mainColor} !important;
                --port-color: #{$secondaryColor} !important;
            }
        }
    }

    &.used {
        svg {
            cursor: pointer;

            @include themify($themes) {
                --border-color: #{$primaryColor};
                --pins-color: #{$primaryColor};
                --port-color: white;
            }
        }
    }

    &.selected,
    &.used {
        svg:hover {
            @include themify($themes) {
                --border-color: #{$primaryColor};
                --pins-color: #{$primaryColor};
                --port-color: #{$tertitaryColor};
            }
        }
    }
}
</style>
