var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.linkequipment.discharged && !_vm.canManageRouter
    ? _c(
        "GeneralAdminBox",
        {
          attrs: {
            "button-text": _vm.$gettext("I activate my administrator account")
          },
          on: { click: _vm.showEnableAdminAccountModal }
        },
        [
          _c(
            "p",
            { directives: [{ name: "translate", rawName: "v-translate" }] },
            [
              _vm._v(
                "\n        You can activate an administrator account to apply specific configurations to your equipment via SSH or Telnet.\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            { directives: [{ name: "translate", rawName: "v-translate" }] },
            [
              _vm._v(
                "\n        In case of customer service intervention, the administrator account must be disabled. This operation will reinitialize the router in its latest known configuration and any manual interventions on the equipment (not through Atlas) will be erased.\n    "
              )
            ]
          )
        ]
      )
    : _vm.canManageRouter
    ? _c(
        "GeneralAdminBox",
        {
          attrs: { "button-text": _vm.buttonAdminCredentialsText },
          on: { click: _vm.showAdminCredentials }
        },
        [
          _vm.linkequipment.discharged
            ? [
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            The administrator account is activated you can get administrator credential.\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            To use the equipments management tool again you need to deactivate the administrator account.\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            The specific modifications made to the equipment will be reset by the last known configuration of unyc.\n        "
                    )
                  ]
                )
              ]
            : [
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            You can activate an administrator account to apply specific configurations to the equipment via SSH or Telnet.\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            Once activated, the equipment management tool will no longer be used.\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            It will be necessary to deactivate the administrator account, the equipment will be reset with the last known configuration of unyc.\n        "
                    )
                  ]
                )
              ]
        ],
        2
      )
    : _vm.linkequipment.discharged && !_vm.canManageRouter
    ? _c(
        "GeneralAdminBox",
        {
          attrs: {
            "button-text": _vm.$gettext("Show administrator credentials"),
            "button-disable": !_vm.canDeactivateAdminAccount
          },
          on: { click: _vm.showDisableAdminAccountModal }
        },
        [
          _vm.linkequipment.discharged
            ? [
                _vm.dischargeByOperator
                  ? _c(
                      "p",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [
                        _vm._v(
                          "\n            unyc activated your administrator account. You must contact support to make changes on the equipment or to recover this tool.\n        "
                        )
                      ]
                    )
                  : _c(
                      "p",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [
                        _vm._v(
                          "\n            You activated your administrator account.\n        "
                        )
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            In case of customer service intervention, the administrator account must be disabled. This operation will reinitialize the router in its latest known configuration and any manual interventions on the equipment (not through Atlas) will be erased.\n        "
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }