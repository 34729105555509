var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.interfacesList },
    [
      _vm.items.length
        ? _c(
            "div",
            { class: _vm.$style.items },
            _vm._l(_vm.itemsPerLabel, function(filteredItems, indexLabel) {
              return _c(
                "div",
                {
                  key: indexLabel + "-" + filteredItems.length,
                  class: _vm.$style.itemsGrouped
                },
                [
                  _c("span", { class: _vm.$style.typeLabel }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(filteredItems[0].label) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(filteredItems, function(item, index) {
                    return _c(
                      "InterfacesListItem",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            "interfaces-data": _vm.interfacesList,
                            "selected-port": _vm.selectedPort
                          }
                        },
                        "InterfacesListItem",
                        Object.assign({}, item),
                        false
                      )
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pageNumber > 1
        ? _c("PaginateVue", {
            attrs: {
              "items-count": _vm.items.length,
              "items-per-page": _vm.itemsPerPage
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("InterfacesRouter", {
        staticClass: "d-none d-lg-flex",
        attrs: {
          interfaces: _vm.interfacesList,
          "used-interfaces": _vm.usedInterfaces,
          "equipment-data": _vm.equipmentData,
          "selected-port": _vm.selectedPort,
          loading: _vm.interfacesLoading
        },
        on: {
          "update:selectedPort": function($event) {
            _vm.selectedPort = $event
          },
          "update:selected-port": function($event) {
            _vm.selectedPort = $event
          },
          "refresh-interfaces": function($event) {
            return _vm.$emit("refresh-interfaces")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }