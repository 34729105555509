var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListItem",
    {
      class: _vm.$style.configurationsTableItem,
      attrs: { breakpoint: "lg" },
      scopedSlots: _vm._u([
        {
          key: "after",
          fn: function() {
            return [
              _c(
                "DropdownMenu",
                { attrs: { position: "right", size: "large" } },
                [
                  _c("DropdownMenuItem", {
                    attrs: {
                      icon: "eye",
                      name: _vm.$gettext("View configuration"),
                      disabled: _vm.disabledActions
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("view-configuration")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("DropdownMenuItem", {
                    attrs: {
                      icon: "arrow-time",
                      name: _vm.$gettext("Restore configuration"),
                      disabled: _vm.disabledActions
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("restore-configuration")
                      }
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("span", { class: _vm.$style.synchronizedBy }, [
        _vm._v("\n        " + _vm._s(_vm.synchronizedBy || "unyc") + "\n    ")
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.formattedCreatedAt))]),
      _vm._v(" "),
      _vm.comment
        ? _c("span", { class: _vm.$style.comment }, [
            _vm._v("\n        " + _vm._s(_vm.comment) + "\n    ")
          ])
        : _c(
            "span",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.noComment
            },
            [_vm._v("\n        No associated comment\n    ")]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }