var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.desktopAndMobileButton },
    [
      _c("Button", {
        class: _vm.$style.desktopButton,
        attrs: {
          role: _vm.role,
          disabled: _vm.disabled,
          text: _vm.text,
          icon: _vm.icon,
          size: _vm.size
        },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      }),
      _vm._v(" "),
      _c("Button", {
        class: _vm.$style.mobileButton,
        attrs: {
          role: "icon_" + _vm.role,
          disabled: _vm.disabled,
          icon: _vm.icon
        },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }