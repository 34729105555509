<template>
    <div :class="$style.dnsConfiguration">
        <div :class="$style.body4">
            <translate>DNS</translate>
        </div>
        <div :class="$style.fieldsForm">
            <Field
                v-model="internalPrimaryDns"
                :errors="formErrors.primary_dns"
                :label="$gettext('Primary DNS')"
                required
                :disabled="useDefaultDns || disabled"
                name="primary_dns"
            />
            <Field
                v-model="internalSecondaryDns"
                :errors="formErrors.secondary_dns"
                :label="$gettext('Secondary DNS')"
                :disabled="useDefaultDns || disabled"
                name="secondary_dns"
            />
        </div>
        <Choice
            v-model="useDefaultDns"
            :disabled="disabled"
            :class="$style.defaultDns"
            name="useDefaultDns"
            :label="$gettext('Use default DNS servers')"
            type="checkbox"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Field from '@/components/common/field.vue';
import Choice from '@/components/common/choice.vue';

export default {
    components: {
        Field,
        Choice,
    },
    props: {
        ipVersion: {
            type: Number,
            required: true,
        },
        primaryDns: {
            type: String,
            default: null,
        },
        secondaryDns: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            useDefaultDns: true,
            defaultPrimaryDns: null,
            defaultSecondaryDns: null,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconfoptions',
        ]),
        formErrors() {
            return this.errors || {};
        },
        internalPrimaryDns: {
            get() {
                return this.primaryDns;
            },
            set(value) {
                this.$emit('update:primaryDns', value);
            },
        },
        internalSecondaryDns: {
            get() {
                return this.secondaryDns;
            },
            set(value) {
                this.$emit('update:secondaryDns', value);
            },
        },
    },
    watch: {
        useDefaultDns(value) {
            if (value) {
                this.primaryDns = this.defaultPrimaryDns;
                this.secondaryDns = this.defaultSecondaryDns;
            }
        },
        linkequipmentconfoptions: {
            handler(linkequipmentconfoptions) {
                if (!linkequipmentconfoptions) {
                    return;
                }

                const equipmentOptions = this.linkequipmentconfoptions[`ipv${this.ipVersion}`] || {};

                this.defaultPrimaryDns = equipmentOptions.default_primary_dns;
                this.defaultSecondaryDns = equipmentOptions.default_secondary_dns;

                if (!this.internalPrimaryDns) {
                    this.internalPrimaryDns = this.defaultPrimaryDns;
                }
                if (!this.internalSecondaryDns) {
                    this.internalSecondaryDns = this.defaultSecondaryDns;
                }
                this.$nextTick(() => {
                    this.useDefaultDns = (
                        this.internalPrimaryDns === this.defaultPrimaryDns
                        && this.internalSecondaryDns === this.defaultSecondaryDns
                    );
                });
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" module>
.dnsConfiguration {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: inherit;

    .fieldsForm {
        display: flex;
        gap: 30px;

        & > * {
            flex: 1;
        }
    }

    .defaultDns {
        width: calc(50% - 15px);

        @include under-to(lg) {
            width: 100%;
        }
    }
}
</style>
