<!-- eslint-disable max-len -->
<template>
    <div :class="$style.dmzsection">
        <div v-if="!canIPv4">
            <ManagedSection
                :title="$gettext('Listing DMZ')"
                section="DMZs"
            >
                <template #subtitle>
                    <h3 v-translate>
                        This access is configured on IPv6 stack.
                    </h3>
                </template>
            </ManagedSection>
        </div>
        <div v-else>
            <Advice
                :title="$gettext('Listing DMZ')"
                link-url="https://kiosque.unyc.io/document/administration-des-routeurs/dmz-adr/"
            >
                <template v-slot:text>
                    <p v-translate>
                        A DMZ (or demilitarized zone) is an isolated subnetwork, separated from the local network (LAN) and the Internet by a firewall.
                    </p>
                    <p v-translate>
                        The DMZ hosts internal network machines that need to be accessible from the outside without compromising the security of the company's local network.
                    </p>
                </template>
            </Advice>
            <UpdatedSection
                section="dmz"
            />

            <div
                v-if="dmzs.length"
                class="row mt-3 mb-4"
            >
                <Title
                    :title="$gettext('Listing DMZ')"
                />
            </div>

            <div
                v-show="dmzs.length"
                class="row-12"
            >
                <ul :class="$style.dmz">
                    <li
                        v-for="(dmz, index) in dmzs"
                        :key="index"
                    >
                        <DmzItem
                            :index-dmz="index"
                            :class="$style.dmz_item"
                            :dmz="dmz"
                            :equipments-configurations="equipments_configurations"
                            :selected-interfaces="selected_interfaces"
                            :dmz-errors="dmzs_errors[index]"
                            :equipments-errors="equipments_errors"
                        />
                    </li>
                </ul>
            </div>
            <div
                v-show="!dmzs.length"
            >
                <DMZEmpty
                    :steps="emptysteps"
                    :subtitle="subtitle"
                    :numbers-displayed="false"
                    image="dmz"
                    @link_clicked="goToPartnerPortal()"
                />
            </div>
            <div
                v-show="is_modified"
                :class="$style.validatebuttons"
                class="row offset-0 col-12 offset-lg-6 col-lg-6"
            >
                <Button
                    role="secondary"
                    :text="$gettext('Cancel')"
                    size="large"
                    :class="[$style.rightbutton, $style.validatebutton]"
                    class="p-0 col-12 col-lg-5"
                    @click="initLinkEquipmentConfDmz()"
                />
                <Button
                    role="primary"
                    :text="$gettext('Validate configuration')"
                    size="large"
                    :class="[$style.rightbutton, $style.validatebutton]"
                    class="p-0 offset-0 col-12 offset-lg-1 col-lg-6"
                    @click="validate()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    showSwal,
    showSwalError,
} from '@/utils/utils';
import {
    actionsControl,
} from './utils/utils';
import Advice from './advice.vue';
import DMZEmpty from './component-empty.vue';
import Title from './component-title.vue';
import DmzItem from './dmzitem.vue';
import ManagedSection from './component-managed-section.vue';
import UpdatedSection from './component-updated-section.vue';
import Button from '../common/button.vue';
import SpecificationsMixin from './mixins/specifications';

export default {
    components: {
        DmzItem,
        Advice,
        Button,
        ManagedSection,
        UpdatedSection,
        DMZEmpty,
        Title,
    },
    mixins: [
        SpecificationsMixin,
    ],
    data() {
        return {
            search: null,
            dmzs: [],
            equipments_configurations: [],
            selected_interfaces: [],
            dmzs_errors: [],
            equipments_errors: [],
            emptysteps: [
                {
                    text: this.$gettext('To set up a DMZ you must order an IP pool from your'),
                    textmobile: this.$gettext('To set up a DMZ you must order an IP pool from your'),
                    linktext: this.$gettext('partner portal'),
                },
            ],
            subtitle: this.$gettext('Add DMZ'),
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'linkequipment',
            'linkequipmentconf',
            'linkequipmentconfoptions',
            'capabilities',
        ]),
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        is_modified() {
            if (!this.linkequipmentconf) {
                return false;
            }

            const dmzIsUpdated = JSON.stringify(
                this.dmzs,
            ) !== JSON.stringify(
                this.linkequipmentconf.dmz || [],
            );
            const equipmentIsUpdated = JSON.stringify(
                this.equipments_configurations,
            ) !== JSON.stringify(
                this.linkequipmentconf.equipments || [],
            );

            return dmzIsUpdated || equipmentIsUpdated;
        },
    },
    watch: {
        equipments_configurations: {
            handler() {
                if (!this.equipments_configurations) {
                    return;
                }

                this.selected_interfaces = [];
                this.equipments_configurations.forEach((equipment) => {
                    let selectedInterfacesEquipment = [];
                    const canTrunk = this.get_capabilities_for_equipment(
                        equipment.link_id,
                    ).includes(
                        'can_trunk_interfaces',
                    );

                    if (!canTrunk) {
                        (equipment.dmzs || []).forEach((dmz) => {
                            if (dmz.interfaces) {
                                selectedInterfacesEquipment = Array.from([
                                    ...selectedInterfacesEquipment,
                                    ...dmz.interfaces,
                                ]);
                            }
                        });
                    }
                    (equipment.lans || []).forEach((lan) => {
                        if (lan.interfaces) {
                            selectedInterfacesEquipment = Array.from([
                                ...selectedInterfacesEquipment,
                                ...lan.interfaces,
                            ]);
                        }
                    });
                    this.selected_interfaces.push({
                        link_id: equipment.link_id,
                        interfaces: selectedInterfacesEquipment,
                    });
                });
            },
            deep: true,
            immediate: true,
        },
        linkequipmentconf: {
            handler() {
                this.initLinkEquipmentConfDmz();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.initLinkEquipmentConfDmz();
    },
    mounted() {
        this.fetch_linkequipmentconf().catch(showSwalError);
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_linkequipmentconf',
            'set_conf_section',
        ]),
        goToPartnerPortal() {
            window.open('https://communaute.force.com/home/home.jsp', 'blank');
        },
        handleDmzErrors(result) {
            this.dmzs_errors = result.errors.dmz || [];

            let content = `<div class=${this.$style.sweeterrors}><ul>`;
            if (this.dmzs_errors.length) {
                content += `<li>${this.$gettext('Fields errors, check the form again')}</li>`;
            }

            (result.errors.non_field_errors || []).forEach((error) => {
                content += `<li>${error}</li>`;
            });
            content += '</ul></div>';

            showSwal({
                title: this.$gettext('DMZ configuration save failed'),
                html: content,
                type: 'error',
                confirmButtonText: this.$gettext('Edit configuration'),
                showCloseButton: true,
            });
        },
        handleEquipmentsErrors(result) {
            this.equipments_errors = result.errors.equipments || [];

            let content = `<div class=${this.$style.sweeterrors}>`;
            (this.equipments_errors).forEach((equipmentErrors, index) => {
                const dmzErrors = (equipmentErrors.dmzs || []);
                if (dmzErrors.length || equipmentErrors.non_field_errors) {
                    const name = this.$gettextInterpolate(
                        this.$gettext('Equipment %{n}'),
                        { n: index + 1 },
                    );
                    content += `<span>${name}</span>`;
                }
                content += '<ul>';
                if (dmzErrors.length) {
                    content += `<li>${this.$gettext('Fields errors, check the form again')}</li>`;
                }

                (equipmentErrors.non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });

                content += '</ul>';
            });
            content += '</div>';

            showSwal({
                title: this.$gettext('DMZ configuration save failed'),
                html: content,
                type: 'error',
                confirmButtonText: this.$gettext('Edit configuration'),
                showCloseButton: true,
            });
        },
        handleEquipmentsSuccess() {
            showSwal({
                title: this.$gettext('DMZ configuration saved'),
                text: this.$gettext('You must deploy configuration in order to apply modifications on equipment'),
                type: 'success',
                showCancelButton: true,
                confirmButtonText: this.$gettext('Deploy now'),
                cancelButtonText: this.$gettext('Later'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) {
                    return;
                }

                this.$emit('deploy-configuration');
            });
        },
        validate() {
            showSwal({
                title: this.$gettext('Are you sure you want to save configuration'),
                text: this.$gettext('Modifying DMZ configuration could impact NAT rules.'),
                type: 'question',
                showCancelButton: true,
                confirmButtonText: this.$gettext('Save configuration'),
                cancelButtonText: this.$gettext('Cancel'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) {
                    return;
                }

                actionsControl(this.linkequipment).then(() => {
                    this.set_conf_section({
                        section: 'dmz',
                        conf: this.dmzs,
                        freeze_datas: true,
                    }).then((conf) => {
                        this.linkequipmentconf.equipments.forEach((equipmentConfigurations) => {
                            let usedInterfaces = [];
                            const currentConfiguration = this.equipments_configurations.find(
                                equipment => equipment.link_id === equipmentConfigurations.link_id,
                            );

                            (equipmentConfigurations.dmzs || []).forEach((dmz) => {
                                usedInterfaces = [...usedInterfaces, ...dmz.interfaces || []];
                            });
                            currentConfiguration.interfaces.forEach((iface) => {
                                if (usedInterfaces.includes(iface.port_number)) {
                                    iface.enabled = false;
                                }
                            });
                        });
                        this.equipments_configurations.forEach((equipmentConfigurations) => {
                            let usedInterfaces = [];
                            (equipmentConfigurations.dmzs || []).forEach((dmz, index) => {
                                dmz.dmz_uuid = conf.dmz[index].uuid;
                                usedInterfaces = [...usedInterfaces, ...dmz.interfaces];
                            });
                            equipmentConfigurations.interfaces.forEach((iface) => {
                                if (usedInterfaces.includes(iface.port_number)) {
                                    iface.enabled = true;
                                }
                            });
                        });
                        this.set_conf_section({
                            section: 'equipments',
                            conf: this.equipments_configurations,
                        }).then(
                            this.handleEquipmentsSuccess,
                        ).catch(
                            this.handleEquipmentsErrors,
                        );
                    }).catch(
                        this.handleDmzErrors,
                    );
                }).catch(showSwalError);
            });
        },
        initLinkEquipmentConfDmz() {
            this.dmzs_errors = [];
            this.equipments_errors = [];

            if (!this.linkequipmentconf) {
                return;
            }

            this.dmzs = JSON.parse(JSON.stringify(
                this.linkequipmentconf.dmz || [],
            ));
            this.equipments_configurations = JSON.parse(JSON.stringify(
                this.linkequipmentconf.equipments || [],
            ));

            this.dmzs.forEach((dmz) => {
                this.dmzs_errors.push({});

                this.equipments_configurations.forEach((equipmentConfigurations) => {
                    if (!equipmentConfigurations.dmzs) {
                        equipmentConfigurations.dmzs = [];
                    }

                    const exitedDmz = equipmentConfigurations.dmzs.find(
                        dmzConfig => dmzConfig.dmz_uuid === dmz.uuid,
                    );
                    const defaultManagement = {
                        ipv4: {
                            ip_address: '',
                        },
                    };
                    if (!exitedDmz) {
                        equipmentConfigurations.dmzs.push({
                            dmz_uuid: dmz.uuid,
                            interfaces: [],
                            management: this.isVrrp ? defaultManagement : {},
                        });
                    } else {
                        this.$set(exitedDmz, 'interfaces', exitedDmz.interfaces || []);
                        if (this.isVrrp) {
                            this.$set(
                                exitedDmz,
                                'management',
                                { ...defaultManagement, ...exitedDmz.management },
                            );
                        }
                    }
                });
            });

            this.equipments_configurations.forEach(() => {
                this.equipments_errors.push({});
            });
        },
        get_capabilities_for_equipment(linkId) {
            if (!this.capabilities) {
                return [];
            }
            const currentEquipment = this.capabilities.find(
                item => item.link_id === linkId,
            ) || { capabilities: [] };
            return currentEquipment.capabilities;
        },
    },
};
</script>

<style lang="scss" module>
.dmzsection {
    .validatebuttons {
        @include under-to(lg) {
            margin: auto;
        }
    }

    .rightbutton {
        min-width: 200px;
    }

    .bottombutton {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1039;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .dmz {
        margin-top: 30px;
    }

    .dmz > * + * {
        margin-top: 40px;
    }

    .validatebutton {
        min-width: auto;
        margin-top: 30px;
    }
}

.sweeterrors {
    ul {
        padding: 0;
        margin-top: 10px;

        li {
            margin-top: 10px;
        }
    }
}
</style>
