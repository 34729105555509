var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CollapsableBlockLayout",
    {
      attrs: { breakpoint: "md" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_c("translate", [_vm._v("List of configurations")])]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", { class: _vm.$style.general_confs }, [
        _vm.confs && _vm.confs.length
          ? _c(
              "ul",
              { class: _vm.$style.confs },
              _vm._l(_vm.confs.slice(0, 10), function(conf, index) {
                return _c(
                  "li",
                  { key: "general-confs-" + index, class: _vm.$style.confItem },
                  [
                    _c("div", { class: _vm.$style.who }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(conf.synchronized_by || "unyc") +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.when }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("moment")(conf.creation_date)) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: _vm.$style.see,
                        on: {
                          click: function($event) {
                            return _vm.showConf(conf)
                          }
                        }
                      },
                      [
                        _c(
                          "Tooltip",
                          {
                            attrs: {
                              content: _vm.$gettext("View configuration")
                            }
                          },
                          [_c("MasterSvg", { attrs: { use: "eye" } })],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              {
                class: [
                  _vm.$style.placeholder,
                  ((_obj = {}),
                  (_obj[_vm.$style.loading] = _vm.confs === null),
                  _obj)
                ]
              },
              [
                _c(
                  "div",
                  { class: _vm.$style.wrapper },
                  [
                    _c("MasterSvg", { attrs: { use: "confs" } }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ],
                        class: [_vm.$style.title, _vm.$style.body5]
                      },
                      [
                        _vm._v(
                          "\n                    No configuration has been deployed yet\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ],
                        class: [_vm.$style.text, _vm.$style.body1]
                      },
                      [
                        _vm._v(
                          "\n                    You'll find here the last 10 configurations deployed on your equipment. You'll be able to restore them in order to configure the equipment back to a previous configuration\n                "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }