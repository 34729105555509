<template>
    <div class="container" />
</template>

<script>

export default {
    name: 'Home',
    components: {
    },
};
</script>

<style lang="scss" module>

</style>
