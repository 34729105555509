var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CollapsableBlockLayout",
    {
      attrs: { breakpoint: "md" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_c("translate", [_vm._v("List of ports")])]
          },
          proxy: true
        },
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "div",
                { class: _vm.$style.generalPortsHeader },
                [
                  _vm.isVrrp
                    ? _c("Select", {
                        class: _vm.$style.equipmentsSelect,
                        attrs: {
                          "data-list": _vm.equipmentsList,
                          role: "small",
                          name: "equipmentsList"
                        },
                        model: {
                          value: _vm.equipmentLinkId,
                          callback: function($$v) {
                            _vm.equipmentLinkId = $$v
                          },
                          expression: "equipmentLinkId"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ComponentSimpleFilters", {
                    attrs: { "filter-choices": _vm.interfaceFiltersValues },
                    model: {
                      value: _vm.filterValue,
                      callback: function($$v) {
                        _vm.filterValue = $$v
                      },
                      expression: "filterValue"
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.generalPortsList },
        [
          _c("UpdatedSection", { attrs: { section: "interfaces" } }),
          _vm._v(" "),
          _c("GeneralPortsList", {
            attrs: {
              "equipment-link-id": _vm.equipmentLinkId,
              "filter-value": _vm.filterValue
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }