<template>
    <Badge :type="thirdPartyEquipment ? 'warning' : 'success'">
        <template #before>
            <MasterSvg
                :use="thirdPartyEquipment ? 'ic-external' : 'ic-unyc'"
                width="18px"
                height="18px"
            />
        </template>
        <template #default>
            <translate v-if="thirdPartyEquipment">
                Third party equipment
            </translate>
            <translate
                v-else
                :translate-params="{
                    provider: equipment._provider.name
                }"
            >
                %{provider} equipment
            </translate>
        </template>
    </Badge>
</template>

<script>
import Badge from '@/components/common/badge.vue';
import MasterSvg from '@/components/common/master-svg.vue';

export default {
    name: 'ProviderBadge',
    components: {
        Badge,
        MasterSvg,
    },
    props: {
        equipment: {
            type: Object,
            required: true,
        },
    },
    computed: {
        thirdPartyEquipment() {
            if (this.equipment.serial_number && !this.equipment._provider) {
                return true;
            }
            return false;
        },
    },
};
</script>
