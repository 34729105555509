var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", class: _vm.$style.emptysection },
    [
      _c("Title", {
        staticClass: "d-flex mt-3 mb-4 col-12",
        attrs: {
          title: _vm.subtitle,
          "show-previous-button": _vm.showPreviousButton
        },
        on: {
          previous_action: function($event) {
            return _vm.$emit("previous_action")
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-lg-6" },
        _vm._l(_vm.steps, function(step, index) {
          return _c(
            "div",
            { key: index, staticClass: "d-flex", class: _vm.$style.info },
            [
              _vm.numbersDisplayed
                ? _c(
                    "div",
                    { staticClass: "col-1 mr-3 p-0", class: _vm.$style.number },
                    [_c("h1", [_vm._v(_vm._s(index + 1))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { class: [_vm.$style.text, _vm.$style.body1] }, [
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "d-none d-lg-inline" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(step.text) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-inline d-lg-none" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(step.textmobile) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    step.linktext
                      ? _c("Button", {
                          staticClass: "d-inline-flex",
                          attrs: { role: "link", text: step.linktext },
                          on: {
                            click: function($event) {
                              return _vm.$emit("link_clicked", index)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-lg-6" },
        [
          _c("MasterSvg", {
            staticClass: "img-fluid",
            attrs: { use: _vm.image, "class-css": _vm.$style.img }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }