var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.canBeClosed || _vm.visible
    ? _c("div", { class: _vm.$style.adviceSection }, [
        _c(
          "div",
          { class: _vm.$style.adviceHeader },
          [
            _c("div", { class: _vm.$style.adviceTitle }, [
              _c("span", [_vm._t("title")], 2),
              _vm._v(" "),
              _vm.$slots.subtitle
                ? _c(
                    "span",
                    { class: _vm.$style.adviceSubtitle },
                    [_vm._t("subtitle")],
                    2
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.canBeClosed
              ? _c("Button", {
                  class: _vm.$style.adviceCloseButton,
                  attrs: { role: "icon_secondary", icon: "cross" },
                  on: { click: _vm.hideAdvice }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.adviceContent },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c("Button", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.haveExample && !_vm.showExample,
                  expression: "haveExample && !showExample"
                }
              ],
              class: _vm.$style.adviceButton,
              attrs: { role: "link", text: _vm.$gettext("Show example") },
              on: { click: _vm.handleShowExampleButton }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.haveExample && _vm.showExample,
                    expression: "haveExample && showExample"
                  }
                ],
                class: _vm.$style.adviceExample
              },
              [_vm._t("example")],
              2
            ),
            _vm._v(" "),
            _vm.linkUrl
              ? _c("Button", {
                  class: _vm.$style.adviceButton,
                  attrs: {
                    role: "link",
                    knowmore: "",
                    text: _vm.$gettext("Know more about it on the kiosque")
                  },
                  on: { click: _vm.handleKnowMoreButton }
                })
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }