var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.addressrangelistitem },
    [
      _c("Field", {
        staticClass: "col-12 pb-4 col-lg-5 pb-lg-0",
        attrs: {
          disabled: _vm.disabled,
          errors: _vm.errors.start_ip_address,
          label: _vm.$gettext("Start IP address"),
          required: true,
          name: "start_ip_address"
        },
        model: {
          value: _vm.address.start_ip_address,
          callback: function($$v) {
            _vm.$set(_vm.address, "start_ip_address", $$v)
          },
          expression: "address.start_ip_address"
        }
      }),
      _vm._v(" "),
      _c("Field", {
        staticClass: "col-12 pb-4 col-lg-5 pb-lg-0",
        attrs: {
          disabled: _vm.disabled,
          errors: _vm.errors.end_ip_address,
          label: _vm.$gettext("End IP address"),
          required: true,
          name: "end_ip_address"
        },
        model: {
          value: _vm.address.end_ip_address,
          callback: function($$v) {
            _vm.$set(_vm.address, "end_ip_address", $$v)
          },
          expression: "address.end_ip_address"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "col-12",
            "col-lg-2",
            _vm.$style.action_delete,
            ((_obj = {}), (_obj[_vm.$style.disabled] = _vm.disabled), _obj)
          ],
          on: { click: _vm.handleRemoveButton }
        },
        [
          _c(
            "Tooltip",
            {
              class: _vm.$style.trash_bin,
              attrs: {
                content: _vm.$gettext("Delete address range"),
                "grey-border": _vm.disabled
              }
            },
            [_c("MasterSvg", { attrs: { use: "trash-bin" } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }