
import Vue from 'vue';

const UNEXPECTED_ERROR = Vue.prototype.$gettext('An unexpected error happened');

function buildServerError(error) {
    return {
        type: 'error_server',
        status: error.request.status,
        errors: error.response.data,
    };
}

function handleError(xhrError) {
    const error = {
        success: false,
        code: (xhrError.request && xhrError.request.status) || null,
    };

    if (!xhrError.request) {
        error.details = UNEXPECTED_ERROR;
    } else if (xhrError.response) {
        error.details = xhrError.response.data;
    }
    return error;
}

export default {
    handleError,
    buildServerError,
};
