<template>
    <div
        :class="[
            $style.little_card,
            {[$style.selected]: selected},
            {[$style.disabled]: disabled},
        ]"
        @click.prevent="click"
    >
        <div
            v-if="icon"
            :class="$style.icon"
        >
            <MasterSvg
                :use="icon"
                width="60px"
                height="auto"
            />
        </div>
        <div :class="$style.content">
            <span :class="$style.title">
                {{ title }}
            </span>
            <span :class="$style.description">
                {{ description }}
            </span>
        </div>
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';

export default {
    name: 'LittleCard',
    components: {
        MasterSvg,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            default: '',
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        click() {
            if (!this.disabled) {
                this.$emit('click');
            }
        },
    },
};
</script>

<style lang="scss" module>
.little_card {
    display: flex;
    flex: 1 1 calc(50% - 20px);
    padding: 16px 35px 16px 13px;
    cursor: pointer;
    border-radius: 4px;

    @include themify($themes) {
        border: solid 1px themed('grayscale_regular');
    }

    &:hover:not(.disabled) {
        @include themify($themes) {
            background-color: rgba(themed('primary_color'), 0.05);
        }
    }

    &.disabled {
        cursor: not-allowed;

        @include themify($themes) {
            background-color: rgba(themed('grayscale_regular'), 0.1);
        }
    }

    &:nth-of-type(odd) {
        margin-right: 20px;
    }

    &:nth-child(n+3) {
        margin-top: 20px;
    }

    &.selected {
        @include themify($themes) {
            background-color: rgba(themed('primary_color'), 0.05);
            border: solid 1px themed('primary_color');
        }

        .title {
            @include themify($themes) {
                color: themed('primary_color');
            }
        }
    }

    & > div {
        display: flex;
        flex-direction: column;
    }

    .icon {
        flex: 0;
        align-self: center;
        width: 60px;
        max-height: 40px;
        margin-right: 14px;
    }

    .content {
        flex: 1;

        .title {
            font-size: 14px;
            font-weight: 500;
        }

        .description {
            font-size: 12px;
            font-weight: 300;
            line-height: 1.5;

            @include themify($themes) {
                color: themed('grayscale_bold');
            }
        }
    }
}
</style>
