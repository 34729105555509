<template>
    <div
        :class="[$style.box, $style.access_member]"
    >
        <div
            class="row mb-3"
            :class="$style.info"
        >
            <span
                v-translate="{n: iplinkMember.priority + 1}"
                class="col mb-2 mb-lg-0"
                :class="$style.body6"
            >
                IP link %{n}
            </span>
            <DropdownMenu
                v-if="!is_current_link(iplinkMember._iplink)"
                color="black"
                position="right"
                size="large"
            >
                <DropdownMenuItem
                    icon="shape"
                    color="red"
                    :name="$gettext('Remove link from this equipment')"
                    @click="removeLinkFromVirtualLink()"
                />
            </DropdownMenu>
        </div>
        <div
            class="row"
            :class="$style.info"
        >
            <div
                v-translate
                class="col-6 col-md-12"
                :class="$style.body3"
            >
                IP link name
            </div>
            <div :class="[$style.value, 'col-8 col-md-12']">
                <h3>{{ iplinkMember._iplink.link_id }}</h3>
                <span
                    v-if="is_current_link(iplinkMember._iplink)"
                    v-translate
                    :class="$style.badge"
                >
                    This link
                </span>
                <span
                    v-else
                    :class="$style.external_link"
                    @click="go_to_iplink(iplinkMember._iplink)"
                >
                    <MasterSvg
                        use="ic-external-link"
                        width="14px"
                        height="14px"
                    />
                </span>
            </div>
        </div>
        <div
            class="row"
            :class="$style.info"
        >
            <div
                v-translate
                class="col-6 col-md-12"
                :class="$style.body3"
            >
                Link type
            </div>
            <div :class="[$style.value, 'col-8 col-md-12']">
                <h3>{{ iplinkMember._iplink.link_type_display }}</h3>
            </div>
        </div>
        <div
            v-if="canIPv4(iplinkMember._iplink)"
            class="row"
            :class="$style.info"
        >
            <div
                v-if="iplinkMember._iplink.mpls"
                v-translate
                class="col-6 col-md-12"
                :class="$style.body3"
            >
                Private IPv4
            </div>
            <div
                v-else
                v-translate
                class="col-6 col-md-12"
                :class="$style.body3"
            >
                Public IPv4
            </div>
            <div :class="[$style.value, 'col-8 col-md-12']">
                <h3>{{ mainAccess.connectivity_ipv4_address }}</h3>
            </div>
        </div>
        <div
            v-if="canIPv6(iplinkMember._iplink)"
            class="row"
            :class="$style.info"
        >
            <div
                v-translate
                class="col-6 col-md-12"
                :class="$style.body3"
            >
                Public IPv6
            </div>
            <div :class="[$style.value, 'col-8 col-md-12']">
                <h3>{{ mainAccess.router_ipv6_address }}</h3>
            </div>
        </div>
        <div
            v-if="canIPv6(iplinkMember._iplink)"
            class="row"
            :class="$style.info"
        >
            <div
                v-translate
                class="col-6 col-md-12"
                :class="$style.body3"
            >
                LAN IPv6
            </div>
            <div :class="[$style.value, 'col-8 col-md-12']">
                <h3>{{ mainAccess.ipv6_lan_subnet }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MasterSvg from '@/components/common/master-svg.vue';
import {
    showSwal,
    showSwalError,
} from '@/utils/utils';
import {
    actionsControl,
} from './utils/utils';
import DropdownMenu from './dropdown-menu.vue';
import DropdownMenuItem from './dropdown-menu-item.vue';

export default {
    components: {
        MasterSvg,
        DropdownMenu,
        DropdownMenuItem,
    },
    props: {
        iplinkMember: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            menu_visible: false,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
        ]),
        mainAccess() {
            return this.iplinkMember._iplink.accesses.find(
                access => access.is_main_access,
            );
        },
    },
    methods: {
        ...mapActions('equipment', [
            'remove_link_from_virtual_link',
        ]),
        is_current_link(iplink) {
            return iplink.link_id === this.iplink.link_id;
        },
        go_to_iplink(iplink) {
            const routeData = this.$router.resolve(
                {
                    name: 'iplink',
                    params: {
                        link_id: iplink.link_id,
                        legacy_id: iplink.legacy_id,
                    },
                },
            );
            window.location = routeData.href;
        },
        removeLinkFromVirtualLink() {
            actionsControl(this.iplinkMember).then(() => {
                this.remove_link_from_virtual_link(this.iplinkMember).then(() => {
                    showSwal({
                        title: this.$gettext('IP Link detached'),
                        text: this.$gettext('IP Link has been detached from the equipment'),
                        type: 'success',
                    });
                }).then(() => {
                    this.$eventHub.emit('multiwan-off', {
                        iplink: this.iplinkMember._iplink,
                    });
                }).catch(showSwalError);
            }).catch(showSwalError);
        },
        canIPv4(iplink) {
            return ['dualstack', 'ipv4'].some(type => type === iplink.ipstack);
        },
        canIPv6(iplink) {
            return ['dualstack', 'ipv6'].some(type => type === iplink.ipstack);
        },
    },
};

</script>
<style lang="scss" module>
    .box.access_member {
        min-height: 140px;
        padding: 20px 30px;

        @include under-to(md) {
            padding: 20px 20px;
        }

        .info {
            align-items: center;
            margin-top: 8px;

            @include under-to(md) {
                margin-top: 12px;
            }
        }

        .value {
            display: flex;
            align-items: center;
            margin-top: 5px;

            h3 {
                font-size: 18px;
            }

            .badge {
                display: flex;
                padding: 6px;
                margin-left: 8px;
                font-size: 9px;
                letter-spacing: 0.3px;
                border-radius: 4px;

                @include themify($themes) {
                    background-color: themed('gray_scale_light');
                }
            }

            .external_link {
                display: flex;
                width: 14px;
                height: 14px;
                margin-left: 8px;
                cursor: pointer;

                @include themify($themes) {
                    fill: themed("primary_color");
                }
            }
        }
    }
</style>
