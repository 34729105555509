var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.subnet_details }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12", class: _vm.$style.title }, [
        _c(
          "h2",
          { directives: [{ name: "translate", rawName: "v-translate" }] },
          [_vm._v("\n                Subnet details\n            ")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: [_vm.$style.info_row] }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6"
        },
        [_vm._v("\n            Address type\n        ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.ipsubnet.address_type) + "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: [_vm.$style.info_row] }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6"
        },
        [_vm._v("\n            CIDR address\n        ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.ipsubnet.cidr_address) + "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: [_vm.$style.info_row] }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6"
        },
        [_vm._v("\n            Prefix size\n        ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.ipsubnet.prefix_size) + "\n        "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: [_vm.$style.info_row] }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6"
        },
        [_vm._v("\n            Usage\n        ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _vm._v("\n            " + _vm._s(_vm.ipsubnet.usage) + "\n        ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: [_vm.$style.info_row] }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6"
        },
        [_vm._v("\n            Name\n        ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _vm._v("\n            " + _vm._s(_vm.ipsubnet.name) + "\n        ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: [_vm.$style.info_row] }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6"
        },
        [_vm._v("\n            Description\n        ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.ipsubnet.description) + "\n        "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }