<template>
    <CollapsableBlockLayout breakpoint="md">
        <template #title>
            <translate>Interfaces list</translate>
        </template>
        <template #header>
            <div :class="$style.generalInterfacesHeader">
                <Select
                    v-if="isVrrp"
                    v-model="equipmentLinkId"
                    :data-list="equipmentsList"
                    role="small"
                    :class="$style.equipmentsSelect"
                    name="equipmentLinkId"
                />
                <ComponentSimpleFilters
                    v-model="filterValue"
                    :filter-choices="typeFilterValues"
                />
            </div>
        </template>
        <InterfacesList
            :items="filteredItems"
            :interfaces-list="currentInterfacesList"
            :equipment-data="equipmentData"
            :interfaces-loading="interfacesLoading"
            @refresh-interfaces="refreshInterfaces"
        />
    </CollapsableBlockLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Select from '@/components/common/select.vue';
import { showSwalError } from '@/utils/utils';

import ComponentSimpleFilters from '../../component-simple-filters.vue';
import SpecificationsMixin from '../../mixins/specifications';
import CollapsableBlockLayout from '../../layout/collapsable-block.vue';

import InterfacesList from './general-interfaces-list.vue';


export default {
    name: 'GeneralInterfaces',
    components: {
        CollapsableBlockLayout,
        ComponentSimpleFilters,
        InterfacesList,
        Select,
    },
    mixins: [
        SpecificationsMixin,
    ],
    data: () => ({
        equipmentLinkId: null,
        filterValue: 'all',
    }),
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconf',
            'linkequipmentconfoptions',
            'virtual_linkequipment_members',
            'virtual_iplink_members',
            'interfaces',
            'interfacesLoading',
        ]),
        typeFilterValues() {
            const filters = [];
            const { wans, lans, dmzs } = this;

            if (wans.length) {
                const label = this.$gettextInterpolate(
                    this.$gettext('WAN (%{n})'),
                    { n: wans.length },
                );
                filters.push({ label, value: 'wan' });
            }
            if (lans.length) {
                const label = this.$gettextInterpolate(
                    this.$gettext('LAN (%{n})'),
                    { n: lans.length },
                );
                filters.push({ label, value: 'lan' });
            }
            if (dmzs.length) {
                const label = this.$gettextInterpolate(
                    this.$gettext('DMZ (%{n})'),
                    { n: dmzs.length },
                );
                filters.push({ label, value: 'dmz' });
            }

            return filters || [{ label: this.$gettext('WAN'), value: 'wan' }];
        },
        lans() {
            const items = [];

            if ((this.linkequipmentconf || {}).lans) {
                this.linkequipmentconf.lans.forEach((lan) => {
                    const lanConfig = (this.currentEquipmentConfigurations.lans || []).find(
                        lanEquipmentConfig => lanEquipmentConfig.lan_uuid === lan.uuid,
                    );
                    lan.equipment = lanConfig;

                    if (lanConfig) {
                        items.push(lan);
                    }
                });
            }

            return items;
        },
        dmzs() {
            const items = [];

            if ((this.linkequipmentconf || {}).dmz) {
                this.linkequipmentconf.dmz.forEach((dmz) => {
                    const dmzConfig = (this.currentEquipmentConfigurations.dmzs || []).find(
                        dmzEquipmentConfig => dmzEquipmentConfig.dmz_uuid === dmz.uuid,
                    );
                    dmz.equipment = dmzConfig;

                    if (dmzConfig) {
                        items.push(dmz);
                    }
                });
            }

            return items;
        },
        wans() {
            return this.currentEquipmentConfigurations.wans || [];
        },
        items() {
            return [
                ...this.wans.map(wan => ({
                    type: 'wan',
                    label: this.$gettextInterpolate(
                        this.$gettext('WAN (%{n})'),
                        { n: this.wans.length },
                    ),
                    name: wan.backup_of ? 'Backup' : wan.name,
                    indent: wan.backup_of ? 2 : 0,
                    interfaces: [
                        wan.modulate_by_equipment
                            ? this.$gettext('Integrated modem')
                            : wan.interface,
                    ],
                    addressingList: this.validIPVersions.map(
                        (ipVersion) => {
                            if (wan.backup_of) {
                                return {
                                    ipVersion,
                                    ipAddress: this.$gettext('Same IP address'),
                                };
                            }

                            let access = this.currentLinkEquipment._access || {};
                            if (this.isMultiwan && this.virtual_iplink_members.length) {
                                access = this.virtual_iplink_members.reduce((list, item) => {
                                    list.push(...item._iplink.accesses.filter(
                                        localAccess => !localAccess.is_terminated,
                                    ));
                                    return list;
                                }, []).find(member => member.link_id === wan.name);
                            }

                            let ipAddress = access.connectivity_ipv4_address;
                            if (ipVersion === 6) {
                                ipAddress = access.router_ipv6_address;
                            }

                            return { ipVersion, ipAddress };
                        },
                    ),
                    vlan_id: null,
                })),
                ...this.lans.map(lan => ({
                    type: 'lan',
                    label: this.$gettextInterpolate(
                        this.$gettext('LAN (%{n})'),
                        { n: this.lans.length },
                    ),
                    name: lan.name,
                    indent: 0,
                    vlanId: lan.vlan_id,
                    interfaces: lan.equipment.interfaces,
                    addressingList: this.validIPVersions.map(
                        (ipVersion) => {
                            const addressing = (lan.addressing || []).find(
                                item => item.ip_version === ipVersion,
                            );
                            if (!addressing) return { ipVersion };

                            let ipAddress = addressing.ip;
                            if (this.isVrrp) {
                                ipAddress = (
                                    (lan.equipment.management || {})[`ipv${ipVersion}`] || {}
                                ).ip_address;
                            }
                            let secondaryIpAddress = '';
                            if (addressing.secondary_ip) {
                                secondaryIpAddress = (
                                    `${addressing.secondary_ip}/${addressing.secondary_netmask}`
                                );
                            }

                            return {
                                ipVersion,
                                ipAddress: `${ipAddress}/${addressing.netmask}`,
                                secondaryIpAddress,
                                dhcp: addressing.dhcp,
                                slaac: addressing.slaac,
                            };
                        },
                    ),
                    vlan_id: lan.vlan_id,
                })),
                ...this.dmzs.map((dmz, index) => ({
                    type: 'dmz',
                    label: this.$gettextInterpolate(
                        this.$gettext('DMZ (%{n})'),
                        { n: this.dmzs.length },
                    ),
                    name: dmz.name,
                    indent: 0,
                    vlanId: 3526 + index,
                    interfaces: dmz.equipment.interfaces,
                    addressingList: this.validIPVersions.map(
                        (ipVersion) => {
                            if (ipVersion !== 4) return { ipVersion };

                            let ipAddress = dmz.ip;
                            if (this.isVrrp) {
                                ipAddress = (
                                    (dmz.equipment.management || {})[`ipv${ipVersion}`] || {}
                                ).ip_address;
                            }

                            return {
                                ipVersion,
                                ipAddress: `${ipAddress}/${dmz.subnet.split('/')[1]}`,
                            };
                        },
                    ),
                    vlan_id: 0,
                })),
            ];
        },
        filteredItems() {
            if (this.filterValue === 'all') return this.items;
            return this.items.filter(item => item.type === this.filterValue);
        },
        equipmentsList() {
            const choices = [];

            if (this.virtual_linkequipment_members) {
                this.virtual_linkequipment_members.forEach((item, index) => {
                    choices.push({
                        id: item.access,
                        text: this.$gettextInterpolate(
                            this.$gettext('Equipment %{n}'),
                            { n: index + 1 },
                        ),
                        selected: index === 0,
                    });
                });
            }

            return choices;
        },
        currentInterfacesList() {
            let equipmentPortsData = {};
            if (this.interfaces) {
                equipmentPortsData = this.interfaces.find(
                    equipment => equipment.link_id === this.equipmentLinkId,
                ) || {};
            }

            return (this.currentEquipmentConfigurations.interfaces || []).map(
                itm => ({
                    ...(equipmentPortsData.interfaces || []).find(
                        item => (item.name === itm.name) && item,
                    ),
                    ...itm,
                }),
            );
        },
        currentEquipmentConfigurations() {
            let equipmentConfigurations = {};

            if (this.linkequipmentconf && this.linkequipmentconf.equipments) {
                equipmentConfigurations = this.linkequipmentconf.equipments.find(
                    equipment => equipment.link_id === this.equipmentLinkId,
                ) || {};
            }

            return equipmentConfigurations;
        },
        equipmentData() {
            if (!this.linkequipmentconfoptions) {
                return {};
            }
            return this.linkequipmentconfoptions.equipments_informations.find(
                equipment => equipment.link_id === this.equipmentLinkId,
            ) || {};
        },
        currentLinkEquipment() {
            if (!this.isVrrp) return this.linkequipment;
            return this.virtual_linkequipment_members.find(
                linkequipment => linkequipment.access === this.equipmentLinkId,
            ) || {};
        },
    },
    watch: {
        linkequipment: {
            handler() {
                if (!this.linkequipment || (this.equipmentLinkId && this.isVrrp)) {
                    return;
                }
                this.equipmentLinkId = this.linkequipment.access;
            },
            deep: true,
            immediate: true,
        },
        virtual_linkequipment_members: {
            handler() {
                if (!this.virtual_linkequipment_members.length || !this.isVrrp) {
                    this.equipmentLinkId = this.linkequipment.access;
                    return;
                }
                this.equipmentLinkId = this.virtual_linkequipment_members[0].access;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_interfaces',
        ]),
        refreshInterfaces() {
            this.fetch_interfaces().catch(showSwalError);
        },
    },
};
</script>

<style lang="scss" module>
.generalInterfacesHeader {
    display: flex;
    gap: 12px;
    place-content: flex-end;
    justify-content: space-between;
    margin-left: auto;

    .equipmentsSelect {
        max-width: 220px;
        font-size: 12px;
    }
}
</style>
