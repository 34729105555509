var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.eventsSection },
    [
      _c("Advice", {
        attrs: { title: _vm.sectionTitle },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                You will find in this section the deployment and restart events of the routers performed from the tool.\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    A partial deployment refers to the deployment of the latest changes not yet existing on the router. No reboot is required.\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    A full deployment is a deployment of new configuration file on the equipment. This deployment needs a reboot.\n                "
                      )
                    ]
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.borderBox },
        [
          _c(
            "div",
            { class: ["mt-3", "mb-4", _vm.$style.titleAndSelect] },
            [
              _c("Title", {
                attrs: { "add-col-class": false, title: _vm.sectionTitle }
              }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.eventsHeader },
                [
                  _vm.isVrrp
                    ? _c("Select", {
                        class: _vm.$style.equipmentsSelect,
                        attrs: {
                          "data-list": _vm.equipmentsList,
                          role: "small",
                          name: "equipmentsList"
                        },
                        model: {
                          value: _vm.equipmentLinkId,
                          callback: function($$v) {
                            _vm.equipmentLinkId = $$v
                          },
                          expression: "equipmentLinkId"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ComponentSimpleFilters", {
                    attrs: { "filter-choices": _vm.statusList },
                    model: {
                      value: _vm.selectedStatus,
                      callback: function($$v) {
                        _vm.selectedStatus = $$v
                      },
                      expression: "selectedStatus"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("EventsList", {
            attrs: {
              events: _vm.events,
              "events-count": _vm.eventsCount,
              "events-per-page": _vm.eventsPerPage,
              loading: _vm.loading
            },
            on: { "filters-update": _vm.handlePaginationUpdate }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }