var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container", class: _vm.$style.connect }, [
    _c("div", { staticClass: "row justify-content-md-center" }, [
      _c("div", { staticClass: "col col-lg-6" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "input-group mb-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.$gettext("Username"),
                  "aria-label": "Username",
                  "aria-describedby": "basic-addon1"
                },
                domProps: { value: _vm.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.username = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "password",
                  placeholder: _vm.$gettext("Password"),
                  "aria-label": "Password",
                  "aria-describedby": "basic-addon1"
                },
                domProps: { value: _vm.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.error) +
                        "\n                "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                staticClass: "btn btn-outline-dark my-2 my-sm-0",
                attrs: { type: "submit" }
              },
              [_vm._v("\n                    Connect\n                ")]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "div",
        { staticClass: "input-group-text", attrs: { id: "basic-addon1" } },
        [_vm._v("\n                            @\n                        ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "div",
        { staticClass: "input-group-text", attrs: { id: "basic-addon1" } },
        [_vm._v("\n                            •\n                        ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }