<template>
    <div
        id="app"
        :class="$style.main"
    >
        <Site />
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import Site from '@/components/site.vue';
import store from '@/stores/store.js';

export default {
    name: 'App',
    store,
    components: {
        Site,
    },
    created() {
        axios.defaults.headers.common['Accept-Language'] = this.$language.currentKebabCase;
        this.$setMainStyle(this.$style);
        this.$setModalClasses([
            'ang_theme-atlas_ng',
            this.$style.main,
        ]);
        document.body.classList.add('ang_theme-atlas_ng');
    },
    methods: {
    },
};
</script>

<style lang="scss">
@import '~sweetalert2/src/variables';

$swal2-success: #2CAC76;
$swal2-error: #DE1C22;
$swal2-warning: #E86825;

$swal2-confirm-button-background-color: #2D77AF;
$swal2-cancel-button-background-color: $swal2-white;
$swal2-cancel-button-color: #4091CE;
$swal2-cancel-button-border: 1px solid $swal2-cancel-button-color;

@import '~sweetalert2/src/sweetalert2';
</style>

<style lang="scss" module>
@import "@/assets/fonts/_fonts.scss";
@import '@/assets/css/main.scss';
</style>
