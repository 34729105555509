var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.$style.box, _vm.$style.access_member] }, [
    _c(
      "div",
      { staticClass: "row mb-3", class: _vm.$style.info },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "translate",
                rawName: "v-translate",
                value: { n: _vm.iplinkMember.priority + 1 },
                expression: "{n: iplinkMember.priority + 1}"
              }
            ],
            staticClass: "col mb-2 mb-lg-0",
            class: _vm.$style.body6
          },
          [_vm._v("\n            IP link %{n}\n        ")]
        ),
        _vm._v(" "),
        !_vm.is_current_link(_vm.iplinkMember._iplink)
          ? _c(
              "DropdownMenu",
              { attrs: { color: "black", position: "right", size: "large" } },
              [
                _c("DropdownMenuItem", {
                  attrs: {
                    icon: "shape",
                    color: "red",
                    name: _vm.$gettext("Remove link from this equipment")
                  },
                  on: {
                    click: function($event) {
                      return _vm.removeLinkFromVirtualLink()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: _vm.$style.info }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6 col-md-12",
          class: _vm.$style.body3
        },
        [_vm._v("\n            IP link name\n        ")]
      ),
      _vm._v(" "),
      _c("div", { class: [_vm.$style.value, "col-8 col-md-12"] }, [
        _c("h3", [_vm._v(_vm._s(_vm.iplinkMember._iplink.link_id))]),
        _vm._v(" "),
        _vm.is_current_link(_vm.iplinkMember._iplink)
          ? _c(
              "span",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                class: _vm.$style.badge
              },
              [_vm._v("\n                This link\n            ")]
            )
          : _c(
              "span",
              {
                class: _vm.$style.external_link,
                on: {
                  click: function($event) {
                    return _vm.go_to_iplink(_vm.iplinkMember._iplink)
                  }
                }
              },
              [
                _c("MasterSvg", {
                  attrs: {
                    use: "ic-external-link",
                    width: "14px",
                    height: "14px"
                  }
                })
              ],
              1
            )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row", class: _vm.$style.info }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          staticClass: "col-6 col-md-12",
          class: _vm.$style.body3
        },
        [_vm._v("\n            Link type\n        ")]
      ),
      _vm._v(" "),
      _c("div", { class: [_vm.$style.value, "col-8 col-md-12"] }, [
        _c("h3", [_vm._v(_vm._s(_vm.iplinkMember._iplink.link_type_display))])
      ])
    ]),
    _vm._v(" "),
    _vm.canIPv4(_vm.iplinkMember._iplink)
      ? _c("div", { staticClass: "row", class: _vm.$style.info }, [
          _vm.iplinkMember._iplink.mpls
            ? _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-6 col-md-12",
                  class: _vm.$style.body3
                },
                [_vm._v("\n            Private IPv4\n        ")]
              )
            : _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-6 col-md-12",
                  class: _vm.$style.body3
                },
                [_vm._v("\n            Public IPv4\n        ")]
              ),
          _vm._v(" "),
          _c("div", { class: [_vm.$style.value, "col-8 col-md-12"] }, [
            _c("h3", [_vm._v(_vm._s(_vm.mainAccess.connectivity_ipv4_address))])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.canIPv6(_vm.iplinkMember._iplink)
      ? _c("div", { staticClass: "row", class: _vm.$style.info }, [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "col-6 col-md-12",
              class: _vm.$style.body3
            },
            [_vm._v("\n            Public IPv6\n        ")]
          ),
          _vm._v(" "),
          _c("div", { class: [_vm.$style.value, "col-8 col-md-12"] }, [
            _c("h3", [_vm._v(_vm._s(_vm.mainAccess.router_ipv6_address))])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.canIPv6(_vm.iplinkMember._iplink)
      ? _c("div", { staticClass: "row", class: _vm.$style.info }, [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "col-6 col-md-12",
              class: _vm.$style.body3
            },
            [_vm._v("\n            LAN IPv6\n        ")]
          ),
          _vm._v(" "),
          _c("div", { class: [_vm.$style.value, "col-8 col-md-12"] }, [
            _c("h3", [_vm._v(_vm._s(_vm.mainAccess.ipv6_lan_subnet))])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }