<template>
    <div>
        <div
            v-if="$props.initMethodName"
            class="form-container py-4"
            :class="$style.widget"
        >
            <div id="params-form">
                <h3
                    v-if="$props.params"
                    v-translate
                    class="my-3 offset-0 col-12 offset-md-2 col-md-8"
                >
                    Params Form
                </h3>
                <div
                    v-for="(item, key) in $props.params"
                    :key="key"
                    class="form-group bg-white"
                >
                    <Field
                        v-model="params[key]"
                        :required="true"
                        class="offset-0 col-12 offset-md-2 col-md-8"
                        :label="key"
                        :name="key"
                    />
                </div>
            </div>
            <Button
                role="primary"
                :text="$gettext('Validate')"
                size="large"
                class="offset-2 col-8 offset-md-3 col-md-6"
                @click="loadWidget"
            />
        </div>
        <div
            id="widget-container"
            class="pb-5"
        >
            <div id="widget" />
        </div>
    </div>
</template>

<script>
import Field from '@/components/common/field.vue';
import Button from '@/components/common/button.vue';

export default {
    components: {
        Field,
        Button,
    },
    props: {
        widgetName: {
            type: String,
            default: '',
        },
        initMethodName: {
            type: String,
            default: null,
        },
        params: {
            type: Object,
            default: null,
        },
    },
    methods: {
        loadJSScript() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = `/static/bundles/${this.widgetName}.js`;
                script.async = false;
                script.type = 'text/javascript';
                script.addEventListener('load', () => resolve(script), false);
                script.addEventListener('error', () => reject(script), false);
                document.head.appendChild(script);
            });
        },

        initWidget() {
            const arrayParams = [];
            const inputs = document.getElementById('params-form').getElementsByTagName('input');
            const inputsList = Array.prototype.slice.call(inputs);
            inputsList.forEach((input) => {
                const { value } = input;
                if (!value.includes('{') && !value.includes('[')) {
                    arrayParams.push(`'${input.value}'`);
                } else {
                    arrayParams.push(`${input.value}`);
                }
            });
            const stringParams = arrayParams.length ? `${arrayParams.join(', ')}, ` : '';
            const evaluatedFunction = `${this.widgetName}.${this.initMethodName}(${stringParams}document.getElementById('widget'), 'fr_FR')`;
            // eslint-disable-next-line no-eval
            eval(evaluatedFunction);
        },

        loadWidget() {
            const widgetContainer = document.getElementById('widget-container');
            const widget = document.createElement('div');
            widget.id = 'widget';
            widgetContainer.innerHTML = '';
            widgetContainer.appendChild(widget);
            this.loadJSScript()
                .then(() => {
                    this.initWidget();
                });
        },
    },
};
</script>

<style lang="scss" module>

</style>
