const listStepsPortability = [
    'first_step',
    'number_to_port',
    'port_mandate',
    'number_recap',
];

const listStepsIPLinkDetails = [
    'first_step',
    'iplink_recap',
];

const state = {
    annexes: [],
    nb_total_annexes: 0,
    current_annex: null,
    current_annex_editable: true,
    current_annex_type: null,
    current_step: 0,
    has_timeline: false,
    list_numbers_to_port: [],
    order_sections: {},
    create_numbers_count: 0,
    list_portability_mandate: [],
    loading: false,
};

function getCurrentListSteps(state) {
    let returnValue = [];

    if (state.current_annex_type === 'portability') {
        returnValue = listStepsPortability;
    } else if (state.current_annex_type === 'iplink') {
        returnValue = listStepsIPLinkDetails;
    }

    return returnValue;
}

const getters = {
    loading: state => state.loading,
    all_steps: () => {
        const arr = [];
        let isStepPassed = false;

        getCurrentListSteps(state).forEach((item, index) => {
            let type;

            if (isStepPassed) {
                type = 'Future';
            } else {
                type = 'Passed';
            }

            if (index === state.current_step) {
                type = 'Current';
                isStepPassed = true;
            }
            if (index !== 0) {
                arr.push([
                    item,
                    type,
                ]);
            }
        });
        return arr;
    },
    annexes: state => state.annexes,
    nb_total_annexes: state => state.nb_total_annexes,
    current_annex: state => state.current_annex,
    current_annex_editable: state => state.current_annex_editable,
    current_annex_type: state => state.current_annex_type,
    current_step: state => state.current_step,
    current_step_name: () => {
        if (getCurrentListSteps(state).length > state.current_step) {
            return getCurrentListSteps(state)[state.current_step];
        }

        return 'first_step';
    },
    has_timeline: state => state.has_timeline,
    list_numbers_to_port: state => state.list_numbers_to_port,
    list_numbers_to_port_count: () => {
        let numberscount = 0;
        state.list_numbers_to_port.forEach((item) => {
            numberscount += item.count;
        });

        return numberscount;
    },
    max_step: () => {
        if (getCurrentListSteps(state).length > 0) {
            return getCurrentListSteps(state).length - 1;
        }

        return 0;
    },
    order_sections: state => state.order_sections,
    requested_numbers_count: () => {
        if (state.current_annex.requested_numbers) {
            return state.current_annex.requested_numbers.numbers_total;
        }

        return null;
    },
    requested_numbers_id: () => {
        if (state.current_annex.requested_numbers) {
            return state.current_annex.requested_numbers.id;
        }

        return null;
    },
    list_portability_mandate: state => state.list_portability_mandate,
    create_numbers_count: state => state.create_numbers_count,
    portability_mandate_status: (state, getter) => {
        let status = 'done';
        let countCurrentNumbers = 0;
        if (state.list_portability_mandate.length === 0) {
            return 'in_progress';
        }
        state.list_portability_mandate.forEach((item) => {
            if (item.status === 'failed') {
                status = 'failed';
            } else if (item.status !== 'completed') {
                status = 'in_progress';
            } else {
                item.requested_numbers.forEach((itemRequestedNumber) => {
                    countCurrentNumbers += itemRequestedNumber.count;
                    if (itemRequestedNumber.count === 0) {
                        countCurrentNumbers += 1;
                    }
                });
            }
        });
        if (status !== 'failed'
            && getter.list_numbers_to_port_count > countCurrentNumbers) {
            status = 'in_progress';
        }
        return status;
    },
};

const mutations = {
    add_range(state, range) {
        state.list_numbers_to_port.push(
            range,
        );
    },
    delete_range(state, id) {
        const index = state.list_numbers_to_port.findIndex(element => element.id === id);
        state.list_numbers_to_port.splice(index, 1);
    },
    first_step(state) {
        state.current_annex_editable = true;
        state.current_annex_type = null;
        state.current_step = 0;
        state.has_timeline = false;
    },
    last_step(state) {
        if (getCurrentListSteps(state).length > 0) {
            state.current_step = getCurrentListSteps(state).length - 1;
            state.has_timeline = false;
        }
    },
    next_step(state) {
        if (state.current_step < (getCurrentListSteps(state).length - 1)) {
            state.current_step += 1;
            state.has_timeline = true;
        } else {
            state.current_annex_editable = true;
            state.current_annex_type = null;
            state.current_step = 0;
            state.has_timeline = false;
        }
    },
    previous_step(state) {
        if (state.current_step > 0) {
            state.current_step -= 1;

            if (state.current_step === 0) {
                state.current_annex_editable = true;
                state.current_annex_type = null;
                state.has_timeline = false;
            }
        }
    },
    set_annexes(state, annexes) {
        state.annexes = annexes;
    },
    set_nb_total_annexes(state, nbTotalAnnexes) {
        state.nb_total_annexes = nbTotalAnnexes;
    },
    set_current_annex(state, annex) {
        state.current_annex = annex;

        if (annex.type === 'requested_numbers') {
            state.list_numbers_to_port = state.current_annex.requested_numbers.numbers_to_port;
        }
    },
    set_current_annex_editable(state, isEditable) {
        state.current_annex_editable = isEditable;
    },
    set_current_annex_type(state, type) {
        state.current_annex_type = type;
    },
    set_order_sections(state, orderSections) {
        state.order_sections = orderSections;
    },
    set_parent_row_id(state, parentRow) {
        state.parent_row = parentRow;
    },
    set_order_id(state, orderId) {
        state.order_id = orderId;
    },
    set_requested_numbers_count(state, requestedNumbersCount) {
        state.requested_numbers_count = requestedNumbersCount;
    },
    set_list_portability_mandate(state, listPortabilityMandate) {
        state.list_portability_mandate = listPortabilityMandate;
    },
    set_loading(state, loadingState) {
        state.loading = loadingState;
    },
};

function setErrorServer(error) {
    return {
        type: 'error_server',
        errors: error.response.data,
        status: error.request.status,
    };
}

const actions = {
    delete_annex_requested_number_range({ rootState, commit }, numberRangeId) {
        return new Promise((resolve, reject) => {
            const url = `/api/order/annexrequestednumberranges/${numberRangeId}/`;

            rootState.axios_api.delete(url).then(() => {
                commit('delete_range', numberRangeId);
                resolve();
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
    fetch_annexes({ rootState, commit }, orderId) {
        return new Promise((resolve, reject) => {
            const url = '/api/order/annexes/';
            rootState.axios_api.get(url, {
                params: {
                    order: orderId,
                },
            }).then((response) => {
                commit('set_annexes', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
    update_annex_status({ rootState, commit }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/order/annexes/${params.id}/`;

            rootState.axios_api.patch(url, {
                status: params.status,
            }).then((response) => {
                commit('set_annexes', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
    fetch_annex_requested_number_ranges(
        {
            rootState,
            commit,
        },
        requestedNumbersId,
    ) {
        return new Promise((resolve, reject) => {
            const url = '/api/order/annexrequestednumberranges/';

            rootState.axios_api
                .get(url, requestedNumbersId)
                .then((response) => {
                    commit('set_list_numbers_to_port', response.data);
                    resolve(response.data);
                }).catch((error) => {
                    reject(setErrorServer(error));
                });
        });
    },
    set_annex_requested_number_ranges({ rootState, commit }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/order/annexrequestednumberranges/';
            rootState.axios_api.post(url, params).then((response) => {
                commit('add_range', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
    set_annexes_lines_info(
        {
            rootState,
        },
        requestedNumbersId,
    ) {
        return new Promise((resolve, reject) => {
            const url = `/api/order/annexes/${requestedNumbersId}/launch/`;
            rootState.axios_api.post(url).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
    fetch_annexes_lines_info(
        {
            rootState,
            commit,
        },
        requestedNumbersId,
    ) {
        commit('set_list_portability_mandate', []);
        return new Promise((resolve, reject) => {
            const url = '/api/portability/portabilities/';

            rootState.axios_api.get(url, {
                params: {
                    related_annex: requestedNumbersId,
                },
            }).then((response) => {
                commit('set_list_portability_mandate', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
    update_mandate({ rootState }, params) {
        return new Promise((resolve, reject) => {
            if (!params.mandate) {
                resolve();
                return;
            }

            const url = `/api/portability/portabilities/${params.mandate.id}/`;
            const apiCall = rootState.axios_api.put;

            apiCall(url, params.mandate).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
    calculate_order_section(
        {
            commit,
            dispatch,
        },
        order,
    ) {
        dispatch('fetch_annexes', order.id).then((annexes) => {
            const orderSections = {};
            let nbTotalAnnexes = 0;
            annexes.forEach((annex) => {
                if (!annex.required) {
                    return;
                }

                nbTotalAnnexes += 1;

                const directParentRef = annex.parent_object_ref;

                let parentRef = directParentRef;
                let parentRow = null;

                while (parentRef !== null) {
                    for (let i = 0; i < order._rows.length; i += 1) {
                        if (order._rows[i].id === parentRef) {
                            parentRow = order._rows[i];
                            parentRef = parentRow.parent_id;
                            break;
                        }
                    }
                }

                const topParentRef = parentRow.id;
                const topParentName = parentRow.catalog_line.name;

                if (!(topParentRef in orderSections)) {
                    orderSections[topParentRef] = {};
                    orderSections[topParentRef].annexes = [];
                    orderSections[topParentRef].name = topParentName;
                }

                orderSections[topParentRef].annexes.push(annex);
            });
            commit('set_nb_total_annexes', nbTotalAnnexes);
            commit('set_order_sections', orderSections);
        });
    },
};
export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
