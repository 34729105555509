<template>
    <ListItem
        :class="$style.configurationsTableItem"
        breakpoint="lg"
    >
        <span :class="$style.synchronizedBy">
            {{ synchronizedBy || 'unyc' }}
        </span>
        <span>{{ formattedCreatedAt }}</span>
        <span
            v-if="comment"
            :class="$style.comment"
        >
            {{ comment }}
        </span>
        <span
            v-else
            v-translate
            :class="$style.noComment"
        >
            No associated comment
        </span>

        <template #after>
            <DropdownMenu
                position="right"
                size="large"
            >
                <DropdownMenuItem
                    icon="eye"
                    :name="$gettext('View configuration')"
                    :disabled="disabledActions"
                    @click="$emit('view-configuration')"
                />
                <DropdownMenuItem
                    icon="arrow-time"
                    :name="$gettext('Restore configuration')"
                    :disabled="disabledActions"
                    @click="$emit('restore-configuration')"
                />
            </DropdownMenu>
        </template>
    </ListItem>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import ListItem from '@/components/common/list-item.vue';

import DropdownMenu from '../dropdown-menu.vue';
import DropdownMenuItem from '../dropdown-menu-item.vue';

export default {
    components: {
        DropdownMenu,
        DropdownMenuItem,
        ListItem,
    },
    props: {
        comment: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        createdAt: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        disabledActions: {
            type: Boolean,
            default: false,
        },
        synchronizedBy: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
    },
    computed: {
        formattedCreatedAt() {
            const { createdAt } = this;
            if (!createdAt) return null;

            moment.locale(Vue.config.language);
            const createdAtMoment = moment(createdAt);
            return this.$gettextInterpolate(
                this.$gettext('%{date} at %{time}'),
                {
                    date: createdAtMoment.format('LL'),
                    time: createdAtMoment.format('LT'),
                },
            );
        },
    },
};
</script>

<style lang="scss" module>
.configurationsTableItem {
    .synchronizedBy {
        font-weight: 500;
    }

    .comment {
        flex: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .noComment {
        @extend .comment;

        font-style: italic;

        @include themify($themes) {
            color: themed("grayscale_medium");
        }
    }
}
</style>
