var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.containers_ipsubnets },
    [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          class: _vm.$style.ipsubnets_header
        },
        [_vm._v("\n        Declared IP blocks\n    ")]
      ),
      _vm._v(" "),
      _c("Table", {
        class: _vm.$style.ipsubnets,
        attrs: {
          rows: _vm.rows,
          columns: _vm.columns,
          "total-items": _vm.ipsubnets_count,
          "is-loading": _vm.ipsubnets_loading,
          "sort-options": {
            initialSortBy: { field: "cidr_address", type: "asc" }
          },
          "pagination-options": {
            position: "top",
            perPageDropdown: [10, 20, 30, 40, 50]
          },
          "search-options": {
            enabled: true
          }
        },
        on: { load_data: _vm.change_api_params },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function(props) {
              var _obj, _obj$1
              return [
                props.column.type == "link"
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          tag: "a",
                          to: {
                            name: "ipsubnet",
                            params: { subnet_id: props.row.id }
                          },
                          exact: ""
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(props.formattedRow[props.column.field]) +
                            "\n            "
                        )
                      ]
                    )
                  : props.column.type == "bullet"
                  ? _c("span", {
                      class: [
                        _vm.$style.statusbullet,
                        ((_obj = {}),
                        (_obj[_vm.$style.active] = props.row.active),
                        _obj),
                        ((_obj$1 = {}),
                        (_obj$1[_vm.$style.inactive] = !props.row.active),
                        _obj$1)
                      ]
                    })
                  : _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n            "
                      )
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.action },
        [
          _c("Button", {
            attrs: {
              role: "primary",
              text: _vm.$gettext("Add a new subnet"),
              size: "large"
            },
            on: { click: _vm.addSubnet }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }