var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: [
        _vm.$style.menu_item,
        ((_obj = {}), (_obj[_vm.$style.active] = _vm.active), _obj)
      ],
      attrs: { section: _vm.section },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.updatedSection
        ? _c(
            "div",
            {
              class: _vm.$style.flyinginfo,
              attrs: {
                "data-content": _vm.$gettext(
                  "Changes have been made but are not yet deployed"
                )
              }
            },
            [
              _c("MasterSvg", {
                attrs: { use: "info", width: "14px", height: "14px" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.name))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }