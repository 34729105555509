<template>
    <div :class="$style.wansection">
        <div :class="$style.wanitem_wrapper">
            <!-- panel header -->
            <div :class="$style.header">
                <span
                    class="mb-2 mb-lg-0"
                    :class="$style.body6"
                >
                    WAN {{ indexWan + 1 }}
                </span>
                <span
                    class="mb-2 ml-0 mr-3 mr-lg-auto ml-3 mb-lg-0"
                    :class="$style.wan_name"
                >
                    {{ mainWan.name }}
                </span>

                <Button
                    v-show="showDeleteButton"
                    role="link"
                    :text="$gettext('Delete this WAN')"
                    icon="error"
                    size="large"
                    :class="$style.b1"
                    :disabled="readonlyMode"
                    @click="remove"
                />
                <span :class="$style.separate" />
                <div
                    :class="[$style.hidearrow, {[$style.bottom]: collapsed}]"
                    @click="toggleCollapse()"
                >
                    <MasterSvg use="arrow" />
                </div>
            </div>

            <!-- collapsable panel content -->
            <div
                v-show="!collapsed"
                :class="$style.wanform"
            >
                <template v-if="showPlaceholderBackup">
                    <AccessWanItem
                        :interfaces-choices="interfaces_choices"
                        :readonly-mode="readonlyMode"
                        @add-modem="$emit('add-modem', currentRouterModel)"
                    />
                </template>
                <template v-if="modemLinkequipments.length">
                    <AccessWanItem
                        v-for="modemLinkequipment in modemLinkequipments"
                        :key="modemLinkequipment.access"
                        :linkequipment="modemLinkequipment"
                        :interfaces-choices="interfaces_choices"
                        :wan="currentWan(modemLinkequipment.access)"
                        :router="currentRouterModel"
                        :errors="current_errors"
                        :readonly-mode="readonlyMode"
                        @reload="fetchModemLinkequipments"
                    />
                </template>
                <template v-if="!modemLinkequipments.find(modem => modem.access === mainWan.name)">
                    <AccessWanItem
                        :interfaces-choices="interfaces_choices"
                        :wan="mainWan"
                        :router="currentRouterModel"
                        :errors="current_errors"
                        :readonly-mode="readonlyMode"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '@/components/common/button.vue';
import MasterSvg from '@/components/common/master-svg.vue';

import {
    showSwal,
    showSwalError,
} from '@/utils/utils';
import { actionsControl } from './utils/utils';
import AccessWanItem from './wans-listitem-access.vue';

export default {
    components: {
        Button,
        MasterSvg,
        AccessWanItem,
    },
    props: {
        mainWan: {
            type: Object,
            default: null,
        },
        backupWans: {
            type: Array,
            default: null,
        },
        equipment: {
            type: Object,
            default: null,
        },
        errors: {
            type: Array,
            default: null,
        },
        indexWan: {
            type: Number,
            default: 0,
        },
        interfaces: {
            type: Array,
            default: (() => []),
        },
        showDeleteButton: {
            type: Boolean,
            default: true,
        },
        showPlaceholderBackup: {
            type: Boolean,
            default: false,
        },
        readonlyMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            collapsed: false,
            interfaces_choices: [],
            modemLinkequipments: [],
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconfoptions',
            'virtual_iplink_members',
            'virtual_linkequipment_members',
        ]),
        current_errors() {
            if (this.indexWan >= this.errors.length) {
                return {};
            }
            return this.errors[this.indexWan] || {};
        },
        current_iplink() {
            if (this.virtual_iplink_members.length) {
                return this.virtual_iplink_members.find(
                    item => item.iplink === this.mainWan.name,
                )._iplink;
            }
            return this.currentLinkequipment._access._iplink;
        },
        currentLinkequipment() {
            if (this.virtual_linkequipment_members.length) {
                return this.virtual_linkequipment_members.find(
                    item => item._access.iplink === this.mainWan.name,
                );
            }
            return this.linkequipment;
        },
        currentRouterModel() {
            return ((this.currentLinkequipment || {})._equipment || {})._model;
        },
        wans() {
            return [this.mainWan, ...this.backupWans];
        },
    },
    watch: {
        current_iplink: {
            handler(iplink) {
                if (!iplink) {
                    return;
                }
                this.fetchModemLinkequipments();
            },
            depp: true,
            immediate: true,
        },
        interfaces: {
            handler() {
                let firstInterfaceNumber = 0;
                if (this.equipment && this.equipment.first_interface_number) {
                    firstInterfaceNumber = this.equipment.first_interface_number;
                }

                this.interfaces_choices = [];
                if (this.wans == null) {
                    return;
                }

                this.interfaces.forEach((iface, index) => {
                    let checked = false;
                    this.wans.forEach((wan) => {
                        if (iface === wan.interface) {
                            checked = true;
                        }
                    });
                    const portNumber = iface.port_number + firstInterfaceNumber;
                    this.interfaces_choices.push({
                        value: index,
                        id: `iface${index}`,
                        display_name: `${this.$gettext('Port')} ${portNumber}`,
                        port_number: iface.port_number,
                        checked,
                        type: iface.type || '',
                        disabled: this.disabledChoice(index),
                    });
                });
            },
            deep: true,
            immediate: true,
        },
        equipment: {
            handler() {
                let firstInterfaceNumber = 0;
                if (this.equipment && this.equipment.first_interface_number) {
                    firstInterfaceNumber = this.equipment.first_interface_number;
                }

                this.interfaces_choices.forEach((iface, index) => {
                    const portNumber = iface.port_number + firstInterfaceNumber;
                    iface.display_name = `${this.$gettext('Port')} ${portNumber}`;
                    iface.disabled = this.disabledChoice(index);
                });
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions('equipment', [
            'remove_link_from_virtual_link',
            'fetch_linkequipments',
        ]),
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        disabledChoice(index) {
            return !(this.equipment.wan_interfaces || []).includes(index) || this.readonlyMode;
        },
        fetchModemLinkequipments() {
            this.fetch_linkequipments({
                filters: {
                    equipment_type: 'modem',
                    iplink: this.current_iplink.link_id,
                },
            }).then((linkequipments) => {
                this.modemLinkequipments = linkequipments;
            }).catch((error) => {
                this.modemLinkequipments = [];
                showSwalError(error);
            });
        },
        remove() {
            actionsControl().then(() => {
                this.remove_link_from_virtual_link({
                    iplink: this.mainWan.name,
                }).then(() => {
                    showSwal({
                        title: this.$gettext('IP Link detached'),
                        text: this.$gettext('IP Link has been detached from the equipment'),
                        type: 'success',
                    });
                    this.$eventHub.emit('multiwan-off', {
                        iplink: this.current_iplink,
                    });
                }).catch(showSwalError);
            }).catch(showSwalError);
        },
        currentWan(name) {
            return this.wans.find(wan => wan.name === name);
        },
    },
};
</script>

<style lang="scss" module>
.wansection {
    .wanitem_wrapper {
        @include themify($themes) {
            box-shadow: 0 0 10px 0 rgba(themed('primary_color'), 0.1);
        }
    }

    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 63px;
        padding: 0 24px;

        @include themify($themes) {
            border-bottom: 1px solid rgba(themed('primary_color'), 0.1);
        }

        @include under-to(lg) {
            flex-direction: row;
            align-items: center;
            padding: 10px 20px;
        }

        .hidearrow {
            @include under-to(lg) {
                position: absolute;
                right: 24px;
            }

            :global(svg) {
                width: 15px;
                height: 16px;
                margin-left: 42px;
                cursor: pointer;
                transition: 0.3s all;
            }

            &.bottom {
                :global(svg) {
                    transform: rotate(180deg);
                }
            }
        }

        &:after {
            position: absolute;
            right: 62px;
            width: 1px;
            height: 100%;
            content: '';

            @include themify($themes) {
                background-color: rgba(themed('gray_scale_dark'), 0.1);
            }
        }

        .wan_name {
            @include themify($themes) {
                color: themed('gray_scale_dark');
            }
        }
    }

    .wanform {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 24px;
        background-color: white;
        border-radius: 8px;

        & > div {
            background-color: inherit;
        }
    }
}
</style>
