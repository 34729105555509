var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row", class: _vm.$style.natlist }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c(
          "div",
          {
            staticClass: "d-none px-4",
            class: [
              _vm.$style.header,
              { "d-xl-block": _vm.visible_rules.length }
            ]
          },
          [
            _c("div", { staticClass: "row mx-xl-0" }, [
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "px-xl-0 col-1"
                },
                [_vm._v("\n                    Name\n                ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-1"
                },
                [_vm._v("\n                    Protocol\n                ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-2"
                },
                [_vm._v("\n                    Public IP\n                ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-2"
                },
                [_vm._v("\n                    Public Port\n                ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-2"
                },
                [_vm._v("\n                    Private IP\n                ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-2"
                },
                [_vm._v("\n                    Private Port\n                ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "col-1"
                },
                [_vm._v("\n                    State\n                ")]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-3" },
          _vm._l(_vm.visible_rules, function(natrule, index) {
            return _c("nat-item", {
              key: index,
              attrs: { natrule: natrule },
              on: {
                delete_rule: function($event) {
                  return _vm.deleteRule(natrule.index)
                },
                edit_rule: function($event) {
                  return _vm.editRule(natrule)
                },
                toggle_activation: function($event) {
                  return _vm.toggleActivation(natrule.index)
                }
              }
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.$style.bottom },
          [
            _vm.nb_pages > 1
              ? _c("PaginateVue", {
                  attrs: {
                    "items-count": _vm.filtered_rules.length,
                    "items-per-page": _vm.page_size
                  },
                  model: {
                    value: _vm.current_page,
                    callback: function($$v) {
                      _vm.current_page = $$v
                    },
                    expression: "current_page"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("ComponentEmptySearch", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.visible_rules.length,
              expression: "!visible_rules.length"
            }
          ],
          attrs: { size: "small" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    No result found\n                "
                      )
                    ]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "text",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    Please try again with an other search\n                "
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }