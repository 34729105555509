var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.error_section }, [
    _c("div", { class: _vm.$style.error }, [
      _c("div", { class: _vm.$style.message }, [
        _vm._v("\n            " + _vm._s(_vm.error.message) + "\n        ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.code },
        _vm._l(_vm.splitedCode, function(code, index) {
          var _obj
          return _c(
            "span",
            {
              key: "code-" + index,
              class: ((_obj = {}), (_obj[_vm.$style.middle] = index % 2), _obj)
            },
            [_vm._v("\n                " + _vm._s(code) + "\n            ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.description }, [
        _vm._v("\n            " + _vm._s(_vm.error.description) + "\n        ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }