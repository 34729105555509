<template>
    <div :class="$style.lansection">
        <div :class="$style.lanitem_wrapper">
            <div :class="$style.header">
                <span :class="$style.body6">
                    LAN {{ indexLan+1 }}
                </span>
                <span
                    class="ml-2 mr-auto ml-lg-3"
                    :class="$style.placeholder1"
                >
                    {{ lan.name }}
                </span>
                <Button
                    role="link"
                    :text="$gettext('Delete this LAN')"
                    icon="error"
                    size="large"
                    :class="$style.b1"
                    @click="$emit('remove')"
                />
                <span :class="$style.separate" />
                <div
                    :class="[$style.hidearrow, {[$style.bottom]: collapsed}]"
                    @click="toggleCollapse()"
                >
                    <MasterSvg
                        use="arrow"
                        width="20px"
                    />
                </div>
            </div>

            <ul
                v-show="!collapsed"
                :class="$style.categories"
            >
                <li
                    class="nav-item"
                    :class="[
                        $style.category,
                        {[$style.active]: active_category === 'network'},
                        {[$style.error]: not_empty_object(network_errors)}
                    ]"
                    section="network"
                    @click="change_category('network')"
                >
                    <div :class="$style.category_title">
                        <translate>Network</translate>
                        <div
                            v-if="not_empty_object(network_errors)"
                            :class="$style.warning"
                        >
                            <MasterSvg use="warningicon" />
                        </div>
                    </div>
                </li>
                <li
                    v-for="(equipment_configurations, index) in equipmentsConfigurations || []"
                    :key="index"
                    class="nav-item"
                    :class="[
                        $style.category,
                        {
                            [$style.active]: (
                                active_category === 'port' ,
                                equipment_configurations.link_id === equipment_link_id)
                        },
                        {[$style.error]: not_empty_object(
                            lan_equipment_errors[index])
                        }
                    ]"
                    :section="`port${index}`"
                    @click="change_category(
                        'port',
                        equipment_configurations.link_id,
                        index)
                    "
                >
                    <div :class="$style.category_title">
                        <span>{{ get_port_section_label(index) }}</span>
                        <div
                            v-if="not_empty_object(lan_equipment_errors[index])"
                            :class="$style.warning"
                        >
                            <MasterSvg use="warningicon" />
                        </div>
                    </div>
                </li>
                <li
                    v-for="(addressing_block, index) in lan.addressing"
                    :key="`${indexLan}-${addressing_block.ip_version}`"
                    class="nav-item"
                    :class="[
                        $style.category,
                        {[$style.active]: selectedIpVersion === addressing_block.ip_version},
                        {[$style.error]: not_empty_object(optionsErrors[index])}
                    ]"
                    section="options"
                    @click="change_category('options', null, 0, addressing_block.ip_version)"
                >
                    <div :class="$style.category_title">
                        {{ get_options_section_label(addressing_block.ip_version) }}
                        <div
                            v-if="not_empty_object(optionsErrors[index])"
                            :class="$style.warning"
                        >
                            <MasterSvg use="warningicon" />
                        </div>
                    </div>
                </li>
            </ul>
            <div
                v-show="!collapsed"
                :class="$style.lanform"
            >
                <component
                    :is="currentTabComponent"
                    v-if="render_component"
                    :lan="lan"
                    :addressing="computedAddressing"
                    :ip-version="selectedIpVersion"
                    :errors="current_errors"
                    :index-lan="indexLan"
                    :lan-equipment-configurations="lan_equipment_configurations"
                    :selected-interfaces="selected_interfaces_equipment"
                    :equipment-link-id="equipment_link_id"
                    :wan-choices="wanChoices"
                    @update-main-exit-wan="handleUpdateMainExitWan"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Button from '@/components/common/button.vue';
import SpecificationsMixin from './mixins/specifications.js';
import MasterSvg from './master-svg.vue';
import LanSectionNetwork from './lan-section-general.vue';
import LanSectionPort from './lan-section-port.vue';
import LanSectionOptions from './lan-section-options.vue';

export default {
    components: {
        Button,
        MasterSvg,
        LanSectionNetwork,
        LanSectionPort,
        LanSectionOptions,
    },
    mixins: [
        SpecificationsMixin,
    ],
    props: {
        lan: {
            type: Object,
            default: null,
        },
        equipmentsConfigurations: {
            type: Array,
            default: null,
        },
        lanErrors: {
            type: Object,
            default: null,
        },
        equipmentsErrors: {
            type: Array,
            default: null,
        },
        indexLan: {
            type: Number,
            default: 0,
        },
        selectedInterfaces: {
            type: Array,
            default: (() => []),
        },
        wanChoices: {
            type: Array,
            default: (() => []),
        },
    },
    data() {
        return {
            collapsed: false,
            update: false,
            active_category: 'network',
            render_component: true,
            equipment_link_id: null,
            lan_equipment_configurations: null,
            selected_interfaces_equipment: null,
            selectedIpVersion: null,
            equipment_error_index: null,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconfoptions',
        ]),
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        currentTabComponent() {
            return `LanSection${this.active_category.charAt(0).toUpperCase()}${this.active_category.substring(1)}`;
        },
        network_errors() {
            const errors = JSON.parse(JSON.stringify(this.lanErrors)) || {};

            // remove options errors (unrelated to network tab)
            if (errors && errors.addressing) {
                errors.addressing.forEach(addressing => delete addressing.dhcp);
                errors.addressing.forEach(addressing => delete addressing.slaac);
                if (!errors.addressing.find(value => Object.keys(value).length !== 0)) {
                    delete errors.addressing;
                }
            }

            return errors || {};
        },
        optionsErrors() {
            if (this.lanErrors && this.lanErrors.addressing) {
                const errors = this.lanErrors.addressing.map((addressing) => {
                    const buildErrors = {};
                    if (addressing.dhcp) buildErrors.dhcp = addressing.dhcp;
                    if (addressing.slaac) buildErrors.slaac = addressing.slaac;
                    return buildErrors;
                });
                return errors;
            }
            return [];
        },
        lan_equipment_errors() {
            const errors = [];

            this.equipmentsErrors.forEach((equipmentErrors) => {
                const lanEquipmentErrors = (equipmentErrors.lans || [])[this.indexLan] || {};
                errors.push(lanEquipmentErrors);
            });

            return errors;
        },
        current_errors() {
            if (this.active_category === 'options') {
                return this.computedOptionsErrors;
            }

            if (this.active_category === 'port') {
                return this.lan_equipment_errors[this.equipment_error_index];
            }
            return this.network_errors;
        },
        computedAddressing() {
            return ((this.lan || {}).addressing || {}).find(
                set => set.ip_version === this.selectedIpVersion,
            ) || {};
        },
        computedOptionsErrors() {
            if (this.lan.addressing && this.selectedIpVersion) {
                const index = this.lan.addressing.findIndex(
                    set => set.ip_version === this.selectedIpVersion,
                );
                return this.optionsErrors[index];
            }
            return {};
        },
    },
    watch: {
        lan: {
            handler() {
                this.lan.addressing.forEach((addressing, index) => {
                    if (!addressing.dhcp) {
                        this.lan.addressing[index].dhcp = {
                            enabled: false,
                            type: 'server',
                            lease_duration: 86400,
                            address_ranges: [],
                            static_addresses: [],
                        };
                    }
                    if (!addressing.slaac && addressing.ip_version === 6) {
                        this.lan.addressing[index].slaac = { enabled: true };
                    }
                });
            },
            deep: true,
            immediate: true,
        },
        equipmentsConfigurations: {
            handler() {
                if (!this.equipmentsConfigurations) {
                    return;
                }

                if (this.equipment_link_id) {
                    const equipmentConfigurations = this.equipmentsConfigurations.find(
                        equipment => equipment.link_id === this.equipment_link_id,
                    ) || {};

                    this.lan_equipment_configurations = equipmentConfigurations.lans.find(
                        equipment => (
                            this.lan.uuid && equipment.lan_uuid === this.lan.uuid
                        ) || (
                            this.lan.id && equipment.lan_id === this.lan.id
                        ),
                    );

                    this.selected_interfaces_equipment = (this.selectedInterfaces.find(
                        equipment => equipment.link_id === this.equipment_link_id,
                    ) || { interfaces: [] }).interfaces;
                }
            },
            deep: true,
        },
    },
    methods: {
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        change_category(category, equipmentLinkId = null, errorIndex = 0, ipVersion = null) {
            this.render_component = false;
            this.active_category = category;
            this.equipment_link_id = equipmentLinkId;
            this.equipment_error_index = errorIndex;
            this.selectedIpVersion = ipVersion;

            if (this.equipment_link_id) {
                const equipmentConfigurations = this.equipmentsConfigurations.find(
                    equipment => equipment.link_id === this.equipment_link_id,
                ) || {};

                this.lan_equipment_configurations = equipmentConfigurations.lans.find(
                    equipment => (
                        this.lan.uuid && equipment.lan_uuid === this.lan.uuid
                    ) || (
                        this.lan.id && equipment.lan_id === this.lan.id
                    ),
                );
                this.lan_equipment_configurations.index = errorIndex;

                this.selected_interfaces_equipment = (this.selectedInterfaces.find(
                    equipment => equipment.link_id === this.equipment_link_id,
                ) || { interfaces: [] }).interfaces;
            }

            if (category !== 'options') {
                this.selectedIpVersion = null;
            }

            this.$nextTick(() => {
                this.render_component = true;
            });
        },
        not_empty_object(someObject) {
            if (!someObject) {
                return false;
            }
            return Object.keys(someObject).length;
        },
        get_port_section_label(index) {
            if (this.isVrrp) {
                return Vue.prototype.$gettextInterpolate(
                    Vue.prototype.$gettext('Equipment ports %{ n }'),
                    { n: index + 1 },
                );
            }
            return Vue.prototype.$gettext('Ports');
        },
        get_options_section_label(ipVersion) {
            if (ipVersion === 6) {
                return this.$gettext('IPv6 options');
            }
            return this.$gettext('IPv4 options');
        },
        handleUpdateMainExitWan(mainExitWan) {
            this.$emit('update-main-exit-wan', mainExitWan);
        },
    },
};
</script>

<style lang="scss" module>
.lansection {
    .categories {
        display: flex;
        flex-direction: row;
        padding: 10px 10px 0 10px;
        margin: 10px;

        @include under-to(lg) {
            margin: 10px 0;
            overflow: scroll;
            overflow-y: hidden;
        }

        @include themify($themes) {
            border-bottom: solid 1px rgba(themed('gray_scale_dark'), 0.1);
        }

        .category {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 100px;
            padding: 10px 10px 0 10px;
            margin: 10px 10px 8px 10px;
            cursor: pointer;

            @include themify($themes) {
                color: themed('grayscale_semi');
            }

            @include under-to(lg) {
                min-width: 75px;
                margin: 10px 0;
            }

            &.active,
            &:hover {
                font-weight: 500;
                color: black;
            }

            &.active {
                & .category_title:after {
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    width: calc(100% + 2px);
                    height: 2px;
                    content: '';
                    border-radius: 4px;

                    @include themify($themes) {
                        background-color: rgba(themed('primary_color'), 1);
                    }

                    transition: all 225ms ease-in-out;
                }
            }

            &.error {
                & .category_title:after {
                    @include themify($themes) {
                        background-color: rgba(themed('error_color'), 1);
                    }
                }
            }

            & .category_title {
                position: relative;
                display: flex;
            }

            .squared_badge {
                display: inline-block;
                align-self: center;
                min-width: 10px;
                padding: 2px 8px;
                margin-left: 10px;
                font-size: 9px;
                font-weight: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.6px;
                white-space: nowrap;
                border-radius: 4px;

                &.enabled {
                    color: white;

                    @include themify($themes) {
                        background-color: rgba(themed('success_color'), 1);
                    }
                }

                &.disabled {
                    @include themify($themes) {
                        background-color: rgba(themed('gray_scale_dark'), 0.1);
                    }
                }
            }
        }

        .warning {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 4px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }

    .lanitem_wrapper {
        @include themify($themes) {
            box-shadow: 0 0 10px 0 rgba(themed('primary_color'), 0.1);
        }
    }

    :global(h4) + :global(h4) {
        margin-left: 4px;
    }

    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 63px;
        padding: 0 24px;
        margin-bottom: 10px;

        @include themify($themes) {
            border-bottom: 1px solid rgba(themed('primary_color'), 0.1);
        }

        @include under-to(lg) {
            flex-direction: row;
            align-items: center;
            padding: 10px 20px;
        }

        .hidearrow {
            display: flex;
            width: 20px;
            height: 13px;
            margin-left: 42px;

            :global(svg) {
                width: 15px;
                height: 13px;
                cursor: pointer;
                transition: 0.3s all;
            }

            &.bottom {
                :global(svg) {
                    transform: rotate(180deg);
                }
            }
        }

        &:after {
            position: absolute;
            right: 62px;
            width: 1px;
            height: 100%;
            content: '';

            @include themify($themes) {
                background-color: rgba(themed('gray_scale_dark'), 0.1);
            }
        }
    }

    .lanform {
        padding: 25px 40px;
        background-color: white;

        & > * + * {
            margin-top: 40px;
        }

        @include under-to(lg) {
            padding: 10px 20px;
        }
    }
}
</style>
