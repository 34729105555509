
<template>
    <div :class="$style.submitButtons">
        <Button
            role="secondary"
            :text="$gettext('Cancel')"
            :disabled="disabled"
            size="large"
            :class="$style.cancelButton"
            @click="$emit('cancel')"
        />
        <Button
            role="primary"
            :text="$gettext('Validate configuration')"
            :disabled="disabled"
            size="large"
            :class="$style.validateButton"
            @click="$emit('submit')"
        />
    </div>
</template>

<script>
import Button from '@/components/common/button.vue';

export default {
    name: 'SubmitButtons',
    components: {
        Button,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
.submitButtons {
    display: flex;
    gap: 30px;
    align-self: flex-end;

    @include under-to(lg) {
        flex-direction: column;
        align-self: center;
    }

    .cancelButton,
    .validateButton {
        min-width: 200px;
    }
}
</style>
