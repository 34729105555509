var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.dmzsection }, [
    !_vm.canIPv4
      ? _c(
          "div",
          [
            _c("ManagedSection", {
              attrs: { title: _vm.$gettext("Listing DMZ"), section: "DMZs" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "subtitle",
                    fn: function() {
                      return [
                        _c(
                          "h3",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                    This access is configured on IPv6 stack.\n                "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1626986590
              )
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("Advice", {
              attrs: {
                title: _vm.$gettext("Listing DMZ"),
                "link-url":
                  "https://kiosque.unyc.io/document/administration-des-routeurs/dmz-adr/"
              },
              scopedSlots: _vm._u([
                {
                  key: "text",
                  fn: function() {
                    return [
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                    A DMZ (or demilitarized zone) is an isolated subnetwork, separated from the local network (LAN) and the Internet by a firewall.\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                    The DMZ hosts internal network machines that need to be accessible from the outside without compromising the security of the company's local network.\n                "
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _vm._v(" "),
            _c("UpdatedSection", { attrs: { section: "dmz" } }),
            _vm._v(" "),
            _vm.dmzs.length
              ? _c(
                  "div",
                  { staticClass: "row mt-3 mb-4" },
                  [
                    _c("Title", {
                      attrs: { title: _vm.$gettext("Listing DMZ") }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dmzs.length,
                    expression: "dmzs.length"
                  }
                ],
                staticClass: "row-12"
              },
              [
                _c(
                  "ul",
                  { class: _vm.$style.dmz },
                  _vm._l(_vm.dmzs, function(dmz, index) {
                    return _c(
                      "li",
                      { key: index },
                      [
                        _c("DmzItem", {
                          class: _vm.$style.dmz_item,
                          attrs: {
                            "index-dmz": index,
                            dmz: dmz,
                            "equipments-configurations":
                              _vm.equipments_configurations,
                            "selected-interfaces": _vm.selected_interfaces,
                            "dmz-errors": _vm.dmzs_errors[index],
                            "equipments-errors": _vm.equipments_errors
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.dmzs.length,
                    expression: "!dmzs.length"
                  }
                ]
              },
              [
                _c("DMZEmpty", {
                  attrs: {
                    steps: _vm.emptysteps,
                    subtitle: _vm.subtitle,
                    "numbers-displayed": false,
                    image: "dmz"
                  },
                  on: {
                    link_clicked: function($event) {
                      return _vm.goToPartnerPortal()
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.is_modified,
                    expression: "is_modified"
                  }
                ],
                staticClass: "row offset-0 col-12 offset-lg-6 col-lg-6",
                class: _vm.$style.validatebuttons
              },
              [
                _c("Button", {
                  staticClass: "p-0 col-12 col-lg-5",
                  class: [_vm.$style.rightbutton, _vm.$style.validatebutton],
                  attrs: {
                    role: "secondary",
                    text: _vm.$gettext("Cancel"),
                    size: "large"
                  },
                  on: {
                    click: function($event) {
                      return _vm.initLinkEquipmentConfDmz()
                    }
                  }
                }),
                _vm._v(" "),
                _c("Button", {
                  staticClass: "p-0 offset-0 col-12 offset-lg-1 col-lg-6",
                  class: [_vm.$style.rightbutton, _vm.$style.validatebutton],
                  attrs: {
                    role: "primary",
                    text: _vm.$gettext("Validate configuration"),
                    size: "large"
                  },
                  on: {
                    click: function($event) {
                      return _vm.validate()
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }