import utils from '@/stores/utils';

const state = {
    ipsubnet: null,
    ipsubnets: [],
    ipsubnets_loading: false,
    ipsubnets_count: 0,
    ipsubnets_options: null,
};

const getters = {
    ipsubnet: state => state.ipsubnet,
    ipsubnets: state => state.ipsubnets,
    ipsubnets_loading: state => state.ipsubnets_loading,
    ipsubnets_count: state => state.ipsubnets_count,
    ipsubnets_options: state => state.ipsubnets_options,
};

const mutations = {
    change_ipsubnet(state, ipsubnet) {
        state.ipsubnet = ipsubnet;
    },
    change_ipsubnets(state, ipsubnets) {
        state.ipsubnets = ipsubnets;
    },
    change_ipsubnets_loading(state, ipsubnetsLoading) {
        state.ipsubnets_loading = ipsubnetsLoading;
    },
    change_ipsubnets_count(state, ipsubnetsCount) {
        state.ipsubnets_count = ipsubnetsCount;
    },
    change_ipsubnets_options(state, ipsubnetsOptions) {
        state.ipsubnets_options = ipsubnetsOptions;
    },
};

const actions = {
    fetch_ipsubnet({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/ipmanager/ipblocks/${params.id}/`;

            rootState.axios_api.get(url).then((response) => {
                commit('change_ipsubnet', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(utils.buildServerError(error));
            });
        });
    },

    fetch_ipsubnets({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/ipmanager/ipblocks/';

            commit('change_ipsubnets_loading', true);
            commit('change_ipsubnets_count', 0);

            rootState.axios_api.get(url, params).then((response) => {
                commit('change_ipsubnets_count', response.data.count);
                commit('change_ipsubnets', response.data.results);
                resolve(response.data.results);
            }).catch((error) => {
                reject(utils.buildServerError(error));
            }).finally(() => {
                commit('change_ipsubnets_loading', false);
            });
        });
    },

    fetch_ipsubnets_options({ rootState, commit }) {
        return new Promise((resolve, reject) => {
            const url = '/api/ipmanager/ipblocks/';

            rootState.axios_api.options(url).then((response) => {
                commit('change_ipsubnets_options', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(utils.buildServerError(error));
            });
        });
    },

    add_ipsubnet({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/ipmanager/ipblocks/';

            rootState.axios_api.post(url, params).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(utils.buildServerError(error));
            });
        });
    },

    remove_subnet() {
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
