<template>
    <div
        v-click-outside="focusOut"
        :class="[$style.dropdownMenu, $style[color], $style[mode]]"
        @click.prevent="toggleShow"
    >
        <slot name="content">
            <MasterSvg
                :class-css="$style.dropdownMenuIcon"
                module="equipment"
                use="menu"
            />
        </slot>
        <div :class="cssWrapperClasses">
            <div :class="$style.dropdownMenuItemsContent">
                <slot name="default" />
            </div>
        </div>
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';

export default {
    name: 'DropdownMenu',
    directives: {
        'click-outside': {
            bind(el, binding) {
                const { bubble } = binding.modifiers;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;

                document.addEventListener('click', handler);
            },
            unbind(el) {
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    },
    components: {
        MasterSvg,
    },
    props: {
        color: {
            type: String,
            default: 'primary',
            validator(value) {
                return [
                    'white',
                    'black',
                    'primary',
                ].indexOf(value) !== -1;
            },
        },
        size: {
            type: String,
            default: 'medium',
            validator(value) {
                return [
                    'small',
                    'medium',
                    'large',
                ].indexOf(value) !== -1;
            },
        },
        position: {
            type: String,
            default: 'center',
            validator(value) {
                return [
                    'left',
                    'center',
                    'right',
                ].indexOf(value) !== -1;
            },
        },
        mode: {
            type: String,
            default: 'hover',
            validator(value) {
                return [
                    'hover',
                    'click',
                ].indexOf(value) !== -1;
            },
        },
    },
    data() {
        return {
            showMenu: false,
        };
    },
    computed: {
        cssWrapperClasses() {
            const {
                position,
                size,
                showMenu,
                $style,
            } = this;
            return [
                $style.dropdownMenuItems,
                $style[position],
                $style[size],
                { [$style.show]: showMenu },
            ];
        },
    },
    methods: {
        toggleShow() {
            this.showMenu = !this.showMenu;
        },
        focusOut() {
            this.showMenu = false;
        },
    },
};
</script>

<style lang="scss" module>
.dropdownMenu {
    position: relative;
    display: block;
    align-items: center;
    align-self: center;
    width: 4px;
    height: 18px;
    cursor: pointer;

    @include under-to(lg) {
        margin-left: auto;
    }

    &.primary .dropdownMenuIcon {
        @include themify($themes) {
            fill: themed('primary_color');
        }
    }

    &.white .dropdownMenuIcon {
        fill: white;
    }

    &.black .dropdownMenuIcon {
        fill: black;
    }

    .dropdownMenuItems {
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        z-index: 1030;
        display: none;
        padding: 10px 0;
        visibility: hidden;
        background: white;
        border-radius: 4px;
        opacity: 0;
        transition: 0.3s ease all;

        @include themify($themes) {
            box-shadow: 0 0 12px 0 rgba(themed('primary_color'), 0.3);
        }

        &.small {
            width: 200px;
        }

        &.medium {
            width: 230px;
        }

        &.large {
            width: 285px;
        }

        &.left {
            transform: translateX(-10%);

            &:before {
                left: 50%;
                left: calc(10% - 7px);
            }
        }

        &.center {
            transform: translateX(-50%);

            &:before {
                left: calc(50% - 7px);
            }
        }

        &.right {
            transform: translateX(-90%);

            &:before {
                left: calc(90% - 7px);
            }
        }

        &:before {
            position: absolute;
            top: -7px;
            width: 14px;
            height: 14px;
            content: "";
            background: white;
            transform: rotate(45deg);
        }

        .dropdownMenuItemsContent {
            width: 100%;
            color: black;

            @include under-to(md) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &.hover:hover .dropdownMenuItems {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: 0.3s ease all;
    }

    & .dropdownMenuItems.show {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: 0.3s ease all;
    }
}

</style>
