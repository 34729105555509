<template>
    <div :class="$style.titleWithAdviceState">
        <slot name="before" />
        <div :class="$style.title">
            <slot />
        </div>
        <Tooltip
            :content="contentTooltip"
            :class="$style.iconAdviceState"
            @click="toggleAdivceState"
        >
            <MasterSvg
                v-if="iconTooltip"
                :use="iconTooltip"
                width="18px"
                height="18px"
            />
        </Tooltip>
        <slot name="after" />
    </div>
</template>

<script>
import MasterSvg from '@/components/common/master-svg.vue';
import Tooltip from '@/components/common/tooltip.vue';

export default {
    name: 'TitleWithAdviceState',
    components: {
        MasterSvg,
        Tooltip,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        iconTooltip: 'ic-interrogation-flat',
    }),
    computed: {
        adviceDisplay: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        contentTooltip() {
            if (this.adviceDisplay) return this.$gettext('Hide advice');
            return this.$gettext('Show advice');
        },
    },
    watch: {
        adviceDisplay: {
            handler() {
                this.iconTooltip = null;
                this.$nextTick(() => {
                    this.iconTooltip = this.adviceDisplay ? 'ic-interrogation-flat' : 'ic-interrogation';
                });
            },
            immediate: true,
        },
    },
    methods: {
        toggleAdivceState() {
            this.adviceDisplay = !this.adviceDisplay;
        },
    },
};
</script>

<style lang="scss" module>
.titleWithAdviceState {
    display: flex;
    gap: 10px;

    .title {
        align-self: center;
        font-size: 22px;
        font-weight: 500;
        line-height: 22px;
    }

    .iconAdviceState {
        align-self: center;
        width: 18px;
        height: 18px;
        cursor: pointer;

        @include themify($themes) {
            color: themed('primary_color');
        }
    }
}
</style>
