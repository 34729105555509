var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.lanSectionSkeleton }, [
    _c("div", { class: _vm.$style.lanItemWrapper }, [
      _c("div", { class: _vm.$style.header }, [
        _c("span", { class: _vm.$style.lanTitle }),
        _vm._v(" "),
        _c("span", {
          staticClass: "ml-2 mr-auto ml-lg-3",
          class: _vm.$style.lanName
        }),
        _vm._v(" "),
        _c("div", { class: _vm.$style.removeButton }),
        _vm._v(" "),
        _c("span", { class: _vm.$style.separate }),
        _vm._v(" "),
        _c("div", { class: _vm.$style.hideArrow })
      ]),
      _vm._v(" "),
      _c("ul", { class: _vm.$style.categories }, [
        _c("li", { class: _vm.$style.category }),
        _vm._v(" "),
        _c("li", { class: _vm.$style.category }),
        _vm._v(" "),
        _c("li", { class: _vm.$style.category })
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.lanForm }, [
        _c("div", { class: _vm.$style.content })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }