/* eslint-disable no-underscore-dangle */
import qs from 'qs';
import { cleanDict } from '@/utils/utils';

import modules from './equipment-submodules';

const defaultSection = 'general';
const alowedSectionsInAdminMode = [
    'general',
    'wan',
    'advanced',
];
const listSections = [
    'general',
    'dmz',
    'wan',
    'lan',
    'nat',
    'connectedDevices',
    'routes',
    'firewall',
    'confs',
    'advanced',
    'events',
];

const initialState = () => ({
    menu_visible: false,
    iplink: null,
    confs: [],
    connectedDevicesTable: null,
    connectedDevicesTableLoading: false,
    connectedDevicesTableEntries: [],
    connectedDevicesTableEntriesLoading: true,
    interfaces: null,
    interfacesLoading: false,
    statusLoading: false,
    mplsrules: null,
    linkequipment: null,
    linkequipmentoptions: null,
    linkequipment_loaded: null,
    linkequipmentconf: null,
    linkequipmentconfoptions: null,
    linkequipmentconf_loaded: null,
    equipment_attached: null,
    adminaccount_activated: null,
    sections: [],
    sections_advice_visible: [],
    vendors: [],
    linkSetupEquipmentModels: [],
    equipments: [],
    equipmentsloading: false,
    equipmentconfsloading: true,
    updated_sections: [],
    administrator_credentials: null,
    capabilities: [],
    virtual_iplink_members: [],
    virtual_linkequipment_members: [],
    iplink_in_deployment: null,
    configuration_templates: [],
    security_iplinks: [],
    securityIPLinksFounded: false,
    firmwareVersionByLinkEquipment: [],
    isRecommendedEquipment: false,
    notRecommendedEquipmentReason: null,
});

const state = {
    hash_prefix: '',
    ...initialState(),
};

const getters = {
    iplink: state => state.iplink,
    confs: state => state.confs,
    connectedDevicesTable: state => state.connectedDevicesTable,
    connectedDevicesTableLoading: state => state.connectedDevicesTableLoading,
    connectedDevicesTableEntries: state => state.connectedDevicesTableEntries,
    connectedDevicesTableEntriesLoading: state => state.connectedDevicesTableEntriesLoading,
    interfaces: state => state.interfaces,
    mplsrules: state => state.mplsrules,
    linkequipment: state => state.linkequipment,
    linkequipmentoptions: state => state.linkequipmentoptions,
    linkequipment_loaded: state => state.linkequipment_loaded,
    linkequipmentconf: state => state.linkequipmentconf,
    linkequipmentconfoptions: state => state.linkequipmentconfoptions,
    linkequipmentconf_loaded: state => state.linkequipmentconf_loaded,
    adminaccount_activated: state => state.adminaccount_activated,
    equipment_attached: state => state.equipment_attached,
    sections: state => state.sections,
    section: state => (state.sections.length === 0 ? '' : state.sections.filter(
        section => section.link_id === state.iplink.link_id,
    )[0].section),
    sections_advice_visible: state => state.sections_advice_visible,
    current_section_advice_visible: state => (
        state.sections_advice_visible.length === 0 ? true : (
            state.sections_advice_visible.filter(
                adviceVisible => adviceVisible.section === state.sections.filter(
                    section => section.link_id === state.iplink.link_id,
                )[0].section,
            )[0] || { advice_visible: true }
        ).advice_visible
    ),
    menu_visible: state => state.menu_visible,
    vendors: state => state.vendors,
    linkSetupEquipmentModels: state => state.linkSetupEquipmentModels,
    equipments: state => state.equipments,
    equipmentsloading: state => state.equipmentsloading,
    hash_prefix: state => state.hash_prefix,
    equipmentconfsloading: state => state.equipmentconfsloading,
    updated_sections: state => state.updated_sections,
    administrator_credentials: state => state.administrator_credentials,
    capabilities: state => state.capabilities,
    full_capabilities: (state) => {
        if (!state.capabilities) {
            return [];
        }

        const fullCapabilities = {};
        state.capabilities.forEach((item) => {
            item.capabilities.forEach((capability) => {
                fullCapabilities[capability] = 1 + (fullCapabilities[capability] || 0);
            });
        });

        return Object.keys(fullCapabilities).filter(
            capability => fullCapabilities[capability] === state.capabilities.length,
        );
    },
    virtual_iplink_members: state => state.virtual_iplink_members.sort(
        (a, b) => a.priority - b.priority,
    ),
    virtual_linkequipment_members: state => state.virtual_linkequipment_members,
    iplink_in_deployment: state => state.iplink_in_deployment,
    configuration_templates: state => state.configuration_templates,
    security_iplinks: state => state.security_iplinks,
    securityIPLinksFounded: state => state.securityIPLinksFounded,
    interfacesLoading: state => state.interfacesLoading,
    statusLoading: state => state.statusLoading,
    firmwareVersionByLinkEquipment: state => state.firmwareVersionByLinkEquipment,
    activatedAccesses: state => (
        (((state.linkequipment || {})._access || {})._iplink || {}).accesses || []
    ).filter(access => !access.is_terminated),
    isRecommendedEquipment: state => state.isRecommendedEquipment,
    notRecommendedEquipmentReason: state => state.notRecommendedEquipmentReason,
};

const mutations = {
    resetState(state) {
        Object.assign(state, initialState());
    },

    showMenu(state) {
        state.menu_visible = true;
    },
    hideMenu(state) {
        state.menu_visible = false;
    },

    navigate(state, section) {
        let isDischarged = true;

        if (state.linkequipment) {
            isDischarged = state.linkequipment.discharged;
        }

        if (
            !listSections.includes(section) || (
                isDischarged && !alowedSectionsInAdminMode.includes(section)
            )
        ) {
            section = defaultSection;
        }

        state.sections.filter(
            iplink => iplink.link_id === state.iplink.link_id,
        )[0].section = section;
        state.menu_visible = false;
    },
    hideCurrentSectionAdvice(state) {
        const currentSection = state.sections.filter(
            iplink => iplink.link_id === state.iplink.link_id,
        )[0].section;

        let advice = state.sections_advice_visible.filter(
            adviceVisible => adviceVisible.section === currentSection,
        )[0];

        if (!advice) {
            advice = {
                section: currentSection,
                advice_visible: false,
            };
            state.sections_advice_visible.push(advice);
        }
        advice.advice_visible = false;

        localStorage.sections_advice_visible = JSON.stringify(state.sections_advice_visible);
    },
    showCurrentSectionAdvice(state) {
        const currentSection = state.sections.filter(
            iplink => iplink.link_id === state.iplink.link_id,
        )[0].section;

        state.sections_advice_visible.filter(
            adviceVisible => adviceVisible.section === currentSection,
        )[0].advice_visible = true;

        localStorage.sections_advice_visible = JSON.stringify(state.sections_advice_visible);
    },
    initSectionsAdviceVisible(state) {
        if (localStorage.sections_advice_visible) {
            state.sections_advice_visible = JSON.parse(localStorage.sections_advice_visible);
        }
    },
    changeiplink(state, iplink) {
        Object.assign(state, initialState());

        state.iplink = iplink;
        let section = state.sections.filter(
            iplinkSection => iplinkSection.link_id === iplink.link_id,
        )[0];

        if (section == null) {
            state.sections_advice_visible = [];

            listSections.forEach((sectionTitle) => {
                state.sections_advice_visible.push({
                    section: sectionTitle,
                    advice_visible: true,
                });
            });

            section = {
                link_id: iplink.link_id,
                section: defaultSection,
            };

            state.sections.push(section);
        }
    },

    changeConnectedDevicesTable(state, value) {
        state.connectedDevicesTable = value;
    },
    changeConnectedDevicesTableLoading(state, value) {
        state.connectedDevicesTableLoading = value;
    },
    changeConnectedDevicesTableEntries(state, value) {
        state.connectedDevicesTableEntries = value;
    },
    changeConnectedDevicesTableEntriesLoading(state, value) {
        state.connectedDevicesTableEntriesLoading = value;
    },
    changeinterfaces(state, interfaces) {
        state.interfaces = interfaces;
    },
    changemplsrules(state, mplsrules) {
        state.mplsrules = mplsrules;
    },

    changeconfs(state, confs) {
        state.confs = confs;
    },

    changelinkequipment(state, linkequipment) {
        state.linkequipment = linkequipment;
    },
    changelinkequipmentoptions(state, linkequipmentoptions) {
        state.linkequipmentoptions = linkequipmentoptions;
    },
    changelinkequipmentloaded(state, loaded) {
        state.linkequipment_loaded = loaded;
    },
    adminaccount_activated(state, adminaccountActivated) {
        state.adminaccount_activated = adminaccountActivated;
    },
    equipment_attached(state, equipmentAttached) {
        state.equipment_attached = equipmentAttached;
    },

    changelinkequipmentconf(state, linkequipmentconf) {
        state.linkequipmentconf = linkequipmentconf;
    },

    changelinkequipmentconfoptions(state, linkequipmentconfoptions) {
        state.linkequipmentconfoptions = linkequipmentconfoptions;
    },
    changelinkequipmentconfloaded(state, loaded) {
        state.linkequipmentconf_loaded = loaded;
    },

    updatevendors(state, vendors) {
        state.vendors = vendors;
    },
    updateLinkSetupEquipmentModels(state, { results, linkId }) {
        state.linkSetupEquipmentModels.push(
            {
                linkId,
                setups: results,
            },
        );
    },
    resetLinkSetupEquipmentModels(state) {
        state.linkSetupEquipmentModels = [];
    },
    updateequipments(state, equipments) {
        state.equipments = equipments;
    },
    updateequipmentsloading(state, equipmentsloading) {
        state.equipmentsloading = equipmentsloading;
    },
    update_hash_prefix(state, hashPrefix) {
        state.hash_prefix = hashPrefix;
    },
    updateequipmentconfsloading(state, equipmentconfsloading) {
        state.equipmentconfsloading = equipmentconfsloading;
    },
    update_updated_sections(state, updatedSections) {
        state.updated_sections = updatedSections;
    },
    update_administrator_credentials(state, administratorCredentials) {
        state.administrator_credentials = administratorCredentials;
    },
    update_capabilities(state, capabilities) {
        state.capabilities = capabilities;
    },
    update_virtual_iplink_members(state, virtualIplinkMembers) {
        state.virtual_iplink_members = virtualIplinkMembers;
    },
    update_virtual_linkequipment_members(state, virtualLinkequipmentMembers) {
        state.virtual_linkequipment_members = virtualLinkequipmentMembers;
    },
    update_iplink_in_deployment(state, iplinkInDeployment) {
        state.iplink_in_deployment = iplinkInDeployment;
    },
    update_configuration_templates(state, configurationTemplates) {
        state.configuration_templates = configurationTemplates;
    },
    update_security_iplinks(state, securityIPLinks) {
        state.security_iplinks = securityIPLinks;
    },
    changeSecurityIPLinksFounded(state, value) {
        state.securityIPLinksFounded = value;
    },
    changeInterfacesLoading(state, value) {
        state.interfacesLoading = value;
    },
    changeStatusLoading(state, value) {
        state.statusLoading = value;
    },
    updateFirmwareVersionByLinkEquipment(state, value) {
        state.firmwareVersionByLinkEquipment = value;
    },
    updateRecommendedEquipment(state, { isRecommended, reason }) {
        state.isRecommendedEquipment = isRecommended;
        state.notRecommendedEquipmentReason = reason;
    },
};

function setErrorServer(error) {
    return {
        type: 'error_server',
        status: error.request.status,
        errors: error.response.data,
    };
}

const actions = {
    init_linkequipment(
        {
            commit,
            dispatch,
            state,
            rootState,
        },
        params,
    ) {
        return new Promise((resolve, reject) => {
            const oldLinkequipment = state.linkequipment;
            const iplink = params.iplink || state.iplink;
            let url = `/api/equipments/linkequipments/${iplink.link_id}/`;

            if (params && params.filters) {
                const queryparams = qs.stringify(params.filters, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }

            commit('changelinkequipmentloaded', false);
            rootState.axios_api.get(url).then((response) => {
                commit('changelinkequipment', response.data);
                dispatch('fetch_linkequipmentoptions').catch(reject);

                if (response.data.deployed_conf && !response.data.discharged) {
                    dispatch('fetch_diff_conf', {
                        conf_to_compare: response.data.deployed_conf,
                        verbose: 0,
                    }).then((data) => {
                        commit('update_updated_sections', data);
                    }).catch(() => {
                        commit('update_updated_sections', []);
                    });
                }

                if (!oldLinkequipment || oldLinkequipment.access !== response.data.access) {
                    dispatch('fetch_linkequipment_capabilities').catch(reject);
                    dispatch('fetch_linkequipmentconf').catch(reject);
                    dispatch('fetch_linkequipmentconfs').catch(reject);
                    dispatch('fetch_virtual_iplink_members').catch(reject);
                } else if (response.data._access.access_type === 'virtual') {
                    dispatch('fetch_virtual_iplink_members').catch(reject);
                }

                resolve(response.data);
            }).catch((error) => {
                commit('changelinkequipmentconf', null);
                commit('changeConnectedDevicesTable', null);
                reject(setErrorServer(error));
            }).finally(() => {
                commit('changelinkequipmentloaded', true);
            });
        });
    },

    fetchEquipmentInfos({ state, dispatch }) {
        if (!state.linkequipment.discharged) {
            dispatch('fetchEquipmentFirmwareVersion');
            dispatch('fetchConnectedDevicesTable');
            dispatch('fetch_interfaces');
            dispatch('fetch_mplsrules');
            dispatch('fetch_equipment_status');
        }
    },

    fetch_linkequipment(
        {
            state,
            rootState,
        },
        params,
    ) {
        return new Promise((resolve, reject) => {
            const iplink = params.iplink || state.iplink;
            let url = `/api/equipments/linkequipments/${iplink.link_id}/`;

            if (params && params.filters) {
                const queryparams = qs.stringify(params.filters, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }

            rootState.axios_api.get(url).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_linkequipments({ rootState }, params) {
        return new Promise((resolve, reject) => {
            let url = '/api/equipments/linkequipments/';

            if (params && params.filters) {
                const queryparams = qs.stringify(params.filters, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }

            rootState.axios_api.get(url).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_linkequipmentoptions({ commit, state, rootState }, params = {}) {
        return new Promise((resolve, reject) => {
            let iplink = state.iplink.link_id;
            let endUrl = '?equipment_type=router';

            if (Object.prototype.hasOwnProperty.call(params, 'force_iplink')) {
                if (params.force_iplink) {
                    iplink = params.force_iplink;
                }
                endUrl = '?force_iplink=true&equipment_type=router';
            }
            const url = `/api/equipments/linkequipments/${iplink}/${endUrl}`;

            rootState.axios_api.options(url).then((response) => {
                if (!Object.prototype.hasOwnProperty.call(params, 'force_iplink')) {
                    commit('changelinkequipmentoptions', response.data);
                }
                resolve(response.data);
            }).catch((error) => {
                if (!Object.prototype.hasOwnProperty.call(params, 'force_iplink')) {
                    commit('changelinkequipmentoptions', null);
                }
                reject(setErrorServer(error));
            });
        });
    },

    set_conf_section({ dispatch, state, rootState }, params) {
        return new Promise((resolve, reject) => {
            let patch = {};
            let url = `/api/equipments/linkequipments/${state.iplink.link_id}/conf/?equipment_type=router`;
            let checkOnly = false;

            if (params && params.check_only) {
                url += '&check_only=true';
                checkOnly = true;
            }
            if (params && params.freeze_datas) {
                checkOnly = true;
            }

            if (!params.conf) {
                resolve();
                return;
            }
            cleanDict(params.conf);

            let apiCall = rootState.axios_api.patch;
            if (params.section) {
                patch[params.section] = params.conf;
            } else {
                patch = params.conf;
                apiCall = rootState.axios_api.put;
            }

            apiCall(url, patch).then((response) => {
                if (!checkOnly) {
                    dispatch('init_linkequipment', {
                        filters: {
                            equipment_type: 'router',
                        },
                    }).catch(error => reject(error));
                    dispatch('fetch_linkequipmentconf').catch(error => reject(error));
                    dispatch('fetch_linkequipmentconfs').catch(error => reject(error));
                }

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetchConnectedDevicesTable({ commit, rootState, state }) {
        return new Promise((resolve, reject) => {
            if (state.connectedDevicesTableLoading) {
                resolve(state.connectedDevicesTable);
                return;
            }

            const url = `/api/equipments/linkequipments/${state.linkequipment.access}/connected_devices/?equipment_type=router`;
            commit('changeConnectedDevicesTableLoading', true);
            let interval = null;
            rootState.axios_api.post(url).then((postResponse) => {
                interval = setInterval(
                    () => {
                        rootState.axios_api.get(url).then((getResponse) => {
                            if (getResponse.data[0].status !== 'synchronizing') {
                                clearInterval(interval);
                                commit('changeConnectedDevicesTableLoading', false);
                            }
                        }).catch(() => {
                            clearInterval(interval);
                            commit('changeConnectedDevicesTable', null);
                            commit('changeConnectedDevicesTableLoading', false);
                        });
                    },
                    3000,
                );
                commit('changeConnectedDevicesTable', postResponse.data.tables);

                resolve(postResponse.data.tables);
            }).catch((error) => {
                commit('changeConnectedDevicesTable', null);
                commit('changeConnectedDevicesTableLoading', false);

                reject(setErrorServer(error));
            });
        });
    },

    fetchConnectedDevicesTableEntries({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            let url = `/api/equipments/linkequipment_connected_devices_entries/?link_id=${state.linkequipment.access}`;

            if (params && params.search) {
                url += `&search=${params.search}`;
            }

            commit('changeConnectedDevicesTableEntriesLoading', true);

            rootState.axios_api.get(url).then((response) => {
                commit('changeConnectedDevicesTableEntries', response.data);
                commit('changeConnectedDevicesTableEntriesLoading', false);

                resolve(response.data);
            }).catch((error) => {
                commit('changeConnectedDevicesTableEntries', null);
                commit('changeConnectedDevicesTableEntriesLoading', false);

                reject(setErrorServer(error));
            });
        });
    },

    fetch_mplsrules({ commit, state, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/mplsrules/?equipment_type=router`;

            rootState.axios_api.get(url).then((response) => {
                commit('changemplsrules', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_interfaces({ commit, state, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/interfaces/?equipment_type=router`;

            commit('changeInterfacesLoading', true);
            rootState.axios_api.get(url).then((response) => {
                commit('changeinterfaces', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            }).finally(() => {
                commit('changeInterfacesLoading', false);
            });
        });
    },

    fetch_linkequipmentconf(
        {
            commit,
            dispatch,
            state,
            rootState,
        },
    ) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/conf/?equipment_type=router`;

            rootState.axios_api.get(url).then((response) => {
                commit('changelinkequipmentconf', response.data);
                dispatch('fetch_linkequipmentconfoptions').catch(error => reject(error));
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_linkequipmentconfoptions({ commit, state, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/conf/?equipment_type=router`;

            rootState.axios_api.options(url).then((response) => {
                commit('changelinkequipmentconfoptions', response.data);

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    attach_equipment(
        {
            commit,
            dispatch,
            rootState,
        },
        params,
    ) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/linkequipments/attach/';

            rootState.axios_api.post(url, params).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).then(() => {
                    dispatch('fetchEquipmentInfos');
                }).catch(error => reject(error));
                commit('equipment_attached', response.data);

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    change_attached_equipment(
        {
            commit,
            dispatch,
            rootState,
        },
        params,
    ) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/linkequipments/attach/';

            rootState.axios_api.post(url, params).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).then(() => {
                    dispatch('fetchEquipmentInfos');
                }).catch(error => reject(error));
                dispatch('fetch_linkequipment_capabilities').catch(reject);
                dispatch('fetch_linkequipmentconf').catch(reject);
                dispatch('fetchConnectedDevicesTable').catch(reject);

                commit('equipment_attached', response.data);

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    attach_equipment_multiwan({ dispatch, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/linkequipments/attach/';

            rootState.axios_api.post(url, params).then(() => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).then((response) => {
                    dispatch('fetchEquipmentInfos');
                    resolve(response);
                }).catch(
                    reject,
                );
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    attach_equipment_vrrp({ dispatch, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/linkequipments/attachvrrp/';

            rootState.axios_api.post(url, params).then(() => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).then((response) => {
                    dispatch('fetchEquipmentInfos');
                    resolve(response);
                }).catch(reject);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    detach_vrrp({ dispatch, state, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/network/accesses/${state.linkequipment.access}/`;

            rootState.axios_api.delete(url).then(() => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).then((response) => {
                    dispatch('fetchEquipmentInfos');
                    resolve(response);
                }).catch(
                    reject,
                );
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_linkequipmentconfs({ commit, state, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipmentconfs/?link_id=${state.linkequipment.access}&limit=11`;

            commit('updateequipmentconfsloading', true);

            rootState.axios_api.get(url).then((response) => {
                commit('changeconfs', response.data.results);
                commit('updateequipmentconfsloading', false);

                resolve(response.data);
            }).catch((error) => {
                commit('changeconfs', []);
                commit('updateequipmentconfsloading', false);

                reject(setErrorServer(error));
            });
        });
    },

    restore_conf({ dispatch, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipmentconfs/${params.conf}/restore/?equipment_type=router`;

            rootState.axios_api.post(url).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));
                dispatch('fetch_linkequipmentconf').catch(error => reject(error));
                dispatch('fetch_linkequipmentconfs').catch(error => reject(error));

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    set_comment({ dispatch, state, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/?equipment_type=router`;
            const data = {
                comment: params.comment,
            };

            rootState.axios_api.patch(url, data).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    activate_administrator_account(
        {
            commit,
            dispatch,
            state,
            rootState,
        },
        params,
    ) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/conf/?equipment_type=router`;
            const data = {
                admin_credentials: {
                    username: params.username,
                    password: params.password,
                    authorized_subnets: params.authorized_subnets,
                    enabled: true,
                },
            };

            rootState.axios_api.patch(url, data).then((response) => {
                commit('adminaccount_activated', true);
                dispatch('fetch_linkequipmentconf').catch(error => reject(error));
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    deactivate_administrator_account(
        {
            commit,
            dispatch,
            state,
            rootState,
        },
    ) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/conf/?equipment_type=router`;
            const data = {
                admin_credentials: {
                    enabled: false,
                    username: null,
                    password: null,
                    authorized_subnets: null,
                },
            };

            rootState.axios_api.patch(url, data).then((response) => {
                commit('adminaccount_activated', false);
                commit('update_administrator_credentials', null);
                dispatch('fetch_linkequipmentconf').catch(error => reject(error));
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    download_conf({ state, rootState }, params) {
        return new Promise((resolve, reject) => {
            const iplink = params.iplink || state.iplink;
            let url = `/api/equipments/linkequipments/${iplink.link_id}/download_configuration/`;

            if (params && params.filters) {
                const queryparams = qs.stringify(params.filters, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }

            rootState.axios_api.get(
                url,
                { responseType: 'blob' },
            ).then((response) => {
                resolve(response);
            }).catch((error) => {
                error.response.data.text().then((text) => {
                    if (error.response.status !== 500) {
                        error.response.data = JSON.parse(text);
                    }
                    reject(setErrorServer(error));
                });
            });
        });
    },

    detach_equipment({ commit, state, rootState }, params) {
        return new Promise((resolve, reject) => {
            const iplink = params.iplink || state.iplink;
            let url = `/api/equipments/linkequipments/${iplink.link_id}/`;

            if (params && params.filters) {
                const queryparams = qs.stringify(params.filters, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }

            rootState.axios_api.delete(url).then((response) => {
                commit('changeConnectedDevicesTable', null);
                commit('changelinkequipment', null);
                commit('equipment_attached', null);
                commit('update_updated_sections', []);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    reboot({ dispatch, state, rootState }, params) {
        return new Promise((resolve, reject) => {
            let endUrl = '?equipment_type=router';
            let iplink = state.iplink.link_id;
            if (params && Object.prototype.hasOwnProperty.call(params, 'force_iplink')) {
                if (params.force_iplink) {
                    iplink = params.force_iplink;
                }
                endUrl = '?force_iplink=true&equipment_type=router';
            }
            const url = `/api/equipments/linkequipments/${iplink}/reboot/${endUrl}`;

            rootState.axios_api.post(url).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    deploy(
        {
            dispatch,
            commit,
            state,
            rootState,
        },
        params,
    ) {
        return new Promise((resolve, reject) => {
            const data = {
                hotpatch: params.hotpatch || false,
                comment: params.comment,
                execution_date: params.executionDate,
            };

            let endUrl = '?equipment_type=router';
            let iplink = state.iplink.link_id;
            let iplinkInDeployment = state.linkequipment.access;
            if (params && Object.prototype.hasOwnProperty.call(params, 'force_iplink')) {
                if (params.force_iplink) {
                    iplink = params.force_iplink;
                    iplinkInDeployment = iplink;
                }
                endUrl = '?force_iplink=true&equipment_type=router';
            }
            const url = `/api/equipments/linkequipments/${iplink}/deployconf/${endUrl}`;

            commit('update_iplink_in_deployment', iplinkInDeployment);
            rootState.axios_api.post(url, data).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));
                commit('update_updated_sections', []);
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));
                dispatch('fetch_linkequipmentconfs').catch(error => reject(error));
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_vendors({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/vendors/';

            rootState.axios_api.get(url).then((response) => {
                commit('updatevendors', response.data);

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_linksetupequipmentmodels({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            let url = '/api/equipments/linksetupequipmentmodels/';

            if (params && params.filters) {
                const queryparams = qs.stringify(params.filters, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }

            rootState.axios_api.get(url).then((response) => {
                let results = response.data;
                if ('limit' in params.filters) {
                    // eslint-disable-next-line prefer-destructuring
                    results = response.data.results;
                }
                if (results[0]) {
                    commit('updateRecommendedEquipment', {
                        isRecommended: null,
                        reason: results[0].reason,
                    });
                }
                if (results[0] && params.updateRecommendedValue) {
                    commit('updateRecommendedEquipment', {
                        isRecommended: results[0].recommended,
                        reason: null,
                    });
                }

                commit('updateLinkSetupEquipmentModels', {
                    results,
                    linkId: params.filters.access,
                });
                resolve(response.data);
            }).catch((error) => {
                commit('updateLinkSetupEquipmentModels', {
                    results: [],
                    linkId: null,
                });
                reject(setErrorServer(error));
            });
        });
    },

    edit_equipment({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/equipments/${params.equipment}/?equipment_type=router`;

            rootState.axios_api.patch(url, params.data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_equipments({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/equipments/';
            const parameters = {
                limit: params.limit,
                offset: params.offset,
                vendor_id: params.vendor,
                model_id: params.model,
                search: params.search,
                foriplink: params.iplink.link_id,
                capabilities: params.capabilities,
            };

            commit('updateequipmentsloading', true);

            rootState.axios_api.get(url, { params: parameters }).then((response) => {
                commit('updateequipments', response.data.results);
                commit('updateequipmentsloading', false);

                resolve(response.data);
            }).catch((error) => {
                commit('updateequipments', []);
                commit('updateequipmentsloading', false);

                reject(setErrorServer(error));
            });
        });
    },

    fetch_diff_conf({ state, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/compareconf/?equipment_type=router`;

            rootState.axios_api.get(url, { params }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_administrator_credentials({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${params.iplink}/credentials/?force_iplink=true&equipment_type=router`;

            rootState.axios_api.get(url).then((response) => {
                commit('update_administrator_credentials', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    manage_by_operator({ commit, dispatch, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/managebyoperator/?equipment_type=router`;

            rootState.axios_api.post(url).then((response) => {
                commit('adminaccount_activated', true);
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_equipment_status({ dispatch, rootState, commit }, params) {
        return new Promise((resolve, reject) => {
            let endUrl = '?equipment_type=router';
            let iplink = state.iplink.link_id;

            if (params && Object.prototype.hasOwnProperty.call(params, 'force_iplink')) {
                if (params.force_iplink) {
                    iplink = params.force_iplink;
                    params.force_iplink = true;
                }
                const queryparams = qs.stringify(params, { arrayFormat: 'repeat' });
                endUrl = `?${queryparams}&equipment_type=router`;
            }
            const url = `/api/equipments/linkequipments/${iplink}/status/${endUrl}`;

            commit('changeStatusLoading', true);
            rootState.axios_api.get(url).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).catch(error => reject(error));
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            }).finally(() => {
                commit('changeStatusLoading', false);
            });
        });
    },

    fetchEquipmentFirmwareVersion({ state, commit, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/firmware_version/?equipment_type=router`;

            rootState.axios_api.get(url).then((response) => {
                commit('updateFirmwareVersionByLinkEquipment', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_linkequipment_capabilities({ commit, state, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/capabilities/?equipment_type=router`;

            rootState.axios_api.get(url).then((response) => {
                commit('update_capabilities', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_virtual_iplink_members({
        dispatch,
        commit,
        state,
        rootState,
    }) {
        return new Promise((resolve, reject) => {
            const url = `/api/network/virtualipLinkmembers/?virtual_iplink=${state.linkequipment.access}`;

            rootState.axios_api.get(url).then((response) => {
                commit('update_virtual_iplink_members', response.data);

                if (!state.linkequipment.equipment) {
                    dispatch('fetch_virtual_linkequipment_members');
                }

                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_virtual_linkequipment_members({ state, commit, rootState }) {
        return new Promise((resolve, reject) => {
            Promise.all(
                state.virtual_iplink_members.map(
                    (member) => {
                        const url = `/api/equipments/linkequipments/${member._iplink.link_id}/?force_iplink=true&equipment_type=router`;
                        return rootState.axios_api.get(
                            url,
                            {
                                validateStatus: status => status < 500,
                            },
                        );
                    },
                ),
            ).then((responses) => {
                const linkequipmentMembers = responses.filter(
                    element => element.status === 200,
                ).map(element => element.data);
                commit(
                    'update_virtual_linkequipment_members',
                    linkequipmentMembers,
                );
                resolve(linkequipmentMembers);
            }).catch((error) => {
                commit('update_virtual_linkequipment_members', []);
                reject(setErrorServer(error));
            });
        });
    },


    remove_link_from_virtual_link({ dispatch, rootState }, param) {
        return new Promise((resolve, reject) => {
            const url = `/api/network/virtualipLinkmembers/${param.iplink}/`;

            rootState.axios_api.delete(url).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                }).then(() => {
                    resolve(response.data);
                    dispatch('fetchEquipmentInfos');
                    dispatch('fetch_linkequipmentconf').catch(error => reject(error));
                }).catch((error) => {
                    state.linkequipment = null;
                    if (error.status !== 404) {
                        reject(error);
                    }
                });
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    first_provisioning({ dispatch, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${params.iplink}/firstprovisioning/?force_iplink=true&equipment_type=router`;

            rootState.axios_api.post(url).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                });
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    manual_deploy({ dispatch, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${params.iplink}/manualdeploy/?force_iplink=true&equipment_type=router`;

            const data = {
                comment: params.comment,
            };

            rootState.axios_api.post(url, data).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                });
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_linkequipment_vrrp({ state, rootState }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/listlinkequipmentsvrrp/?equipment_type=router`;

            rootState.axios_api.get(url).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    post_linkequipment_testreachability({ rootState, dispatch }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${params.link_id}/testreachability/`;

            rootState.axios_api.post(
                url,
                {
                    params: {
                        equipment_type: 'router',
                        force_iplink: params.forceIPLink || false,
                    },
                },
            ).then((response) => {
                dispatch('init_linkequipment', {
                    filters: {
                        equipment_type: 'router',
                    },
                });
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_configuration_templates({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            let url = '/api/equipments/configurationtemplates/';

            if (params && params.filters) {
                const queryparams = qs.stringify(params.filters, { arrayFormat: 'repeat' });
                if (queryparams) {
                    url += `?${queryparams}`;
                }
            }

            rootState.axios_api.get(url).then((response) => {
                commit('update_configuration_templates', response.data);
                resolve(response.data);
            }).catch((error) => {
                commit('update_configuration_templates', []);
                reject(setErrorServer(error));
            });
        });
    },

    add_configuration_template({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/configurationtemplates/';

            rootState.axios_api.post(url, params).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    delete_configuration_template({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/configurationtemplates/${params.template.id}/`;

            rootState.axios_api.delete(url).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    update_configuration_template({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/configurationtemplates/${params.template.id}/`;

            rootState.axios_api.patch(url, params.data).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    apply_configuration_template({ rootState, state, dispatch }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipments/${state.iplink.link_id}/apply_template/?equipment_type=router`;
            rootState.axios_api.post(url, params).then((response) => {
                dispatch('fetch_linkequipmentconf').catch(reject);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_security_iplinks({ state, commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/network/iplinks/';

            params.params = {
                ...params.params,
                endcustomer: state.linkequipment._access._iplink._endcustomersite.endcustomer,
                exclude_iplink: state.linkequipment.access,
                type: 'simple',
                already_redundancy: false,
                is_terminated: false,
                has_ip_address: true,
            };

            rootState.axios_api.get(url, params).then((response) => {
                commit('update_security_iplinks', response.data);
                commit('changeSecurityIPLinksFounded', true);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_equipment_modules({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/equipmentmodules/';

            rootState.axios_api.get(url, {
                params: {
                    equipment: params.equipment.id,
                },
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetchAccessCredentialsHistory({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/accesscredentials/';

            rootState.axios_api.get(url, { params }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules,
};
