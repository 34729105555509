<template>
    <simple-svg
        :src="svg"
        :width="width"
        :height="height"
        :custom-class-name="classCss"
    />
</template>

<script>
import LoaderSvg from '@/assets/large-loader.svg';
import WarningIconFlatSvg from '@/assets/ic-warning-flat.svg';
import ValidSvg from '@/assets/ic-valid.svg';
import DownloadSvg from './assets/download.svg';
import CheckSvg from './assets/check.svg';
import DeploySvg from './assets/deploy.svg';
import RestartSvg from './assets/restart.svg';
import UserSvg from './assets/user.svg';
import ArrowSvg from './assets/arrow.svg';
import ArrowDownSvg from './assets/arrow-down.svg';
import EyeSvg from './assets/eye.svg';
import ShapeSvg from './assets/shape.svg';
import MenuSvg from './assets/menu.svg';
import ArrowTimeSvg from './assets/arrow-time.svg';
import BurgerSvg from './assets/burger.svg';
import CrossSvg from './assets/cross.svg';
import AddSvg from './assets/add.svg';
import ZoomSvg from './assets/zoom.svg';
import TrashBin from './assets/trashbin.svg';
import ImportSvg from './assets/import.svg';
import ExportSvg from './assets/export.svg';
import RepeatSvg from './assets/repeat.svg';
import PencilSvg from './assets/pencil.svg';
import ProvisionningSvg from './assets/provisionning.svg';
import RouteSvg from './assets/route.svg';
import EditSvg from './assets/edit.svg';
import LanSvg from './assets/lan.svg';
import ErrorSvg from './assets/error.svg';
import ConnectedDevicesSvg from './assets/connected-devices.svg';
import NATSvg from './assets/nat.svg';
import ConfsSvg from './assets/confs.svg';
import FirewallSvg from './assets/firewall.svg';
import InfoSvg from './assets/info.svg';
import DeploymentSvg from './assets/deployment.svg';
import DeploymentFinishedSvg from './assets/deployment-finished.svg';
import DeploymentErrorSvg from './assets/deployment-error.svg';
import UnderlinePenSvg from './assets/underline-pen.svg';
import RefreshSvg from './assets/refresh.svg';
import SearchSVG from './assets/search.svg';
import EquipmentSvg from './assets/equipment_result.svg';
import PortSVG from './assets/port.svg';
import DmzSvg from './assets/dmz.svg';
import ArrowRightSvg from './assets/arrow-right.svg';
import FirstProvisioningStartSvg from './assets/firstprovisioning-start.svg';
import FirstProvisioningStepsSvg from './assets/firstprovisioning-steps.svg';
import UploadSvg from './assets/upload.svg';
import KeySvg from './assets/ic-key.svg';
import LockSvg from './assets/ic-lock.svg';
import InterrogationSvg from './assets/ic-interrogation.svg';
import LinkSvg from './assets/link.svg';
import AddIconSvg from './assets/ic-add.svg';
import CrownSvg from './assets/ic-crown.svg';
import GearSvg from './assets/ic-gear.svg';
import UnlinkSvg from './assets/unlink.svg';
import WarningIconSvg from './assets/ic-warning.svg';
import SnmpSvg from './assets/snmp.svg';
import Wan2DmzSvg from './assets/ic-wan2dmz.svg';
import Wan2LanSvg from './assets/ic-wan2lan.svg';
import Lan2WanSvg from './assets/ic-lan2wan.svg';
import Lan2LanSvg from './assets/ic-lan2lan.svg';
import PadlockSvg from './assets/ic-padlock.svg';
import SwitchEquipmentSvg from './assets/ic-switch.svg';
import Router4gSvg from './assets/router-4g.svg';

export default {
    props: {
        use: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: null,
        },
        height: {
            type: String,
            default: null,
        },
        classCss: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            svg: '',
        };
    },
    watch: {
        use() {
            this.init_svg();
        },
    },
    created() {
        this.init_svg();
    },
    methods: {
        init_svg() {
            if (this.use === 'download') {
                this.svg = DownloadSvg;
            } else if (this.use === 'info') {
                this.svg = InfoSvg;
            } else if (this.use === 'check') {
                this.svg = CheckSvg;
            } else if (this.use === 'deploy') {
                this.svg = DeploySvg;
            } else if (this.use === 'restart') {
                this.svg = RestartSvg;
            } else if (this.use === 'user') {
                this.svg = UserSvg;
            } else if (this.use === 'arrow') {
                this.svg = ArrowSvg;
            } else if (this.use === 'arrow-down') {
                this.svg = ArrowDownSvg;
            } else if (this.use === 'eye') {
                this.svg = EyeSvg;
            } else if (this.use === 'shape') {
                this.svg = ShapeSvg;
            } else if (this.use === 'menu') {
                this.svg = MenuSvg;
            } else if (this.use === 'arrow-time') {
                this.svg = ArrowTimeSvg;
            } else if (this.use === 'burger') {
                this.svg = BurgerSvg;
            } else if (this.use === 'cross') {
                this.svg = CrossSvg;
            } else if (this.use === 'add') {
                this.svg = AddSvg;
            } else if (this.use === 'zoom') {
                this.svg = ZoomSvg;
            } else if (this.use === 'trash-bin') {
                this.svg = TrashBin;
            } else if (this.use === 'import') {
                this.svg = ImportSvg;
            } else if (this.use === 'export') {
                this.svg = ExportSvg;
            } else if (this.use === 'repeat') {
                this.svg = RepeatSvg;
            } else if (this.use === 'pencil') {
                this.svg = PencilSvg;
            } else if (this.use === 'provisionning') {
                this.svg = ProvisionningSvg;
            } else if (this.use === 'route') {
                this.svg = RouteSvg;
            } else if (this.use === 'edit') {
                this.svg = EditSvg;
            } else if (this.use === 'lan') {
                this.svg = LanSvg;
            } else if (this.use === 'error') {
                this.svg = ErrorSvg;
            } else if (this.use === 'connectedDevices') {
                this.svg = ConnectedDevicesSvg;
            } else if (this.use === 'nat') {
                this.svg = NATSvg;
            } else if (this.use === 'confs') {
                this.svg = ConfsSvg;
            } else if (this.use === 'firewall') {
                this.svg = FirewallSvg;
            } else if (this.use === 'deployment') {
                this.svg = DeploymentSvg;
            } else if (this.use === 'deployment-finished') {
                this.svg = DeploymentFinishedSvg;
            } else if (this.use === 'deployment-error') {
                this.svg = DeploymentErrorSvg;
            } else if (this.use === 'underline-pen') {
                this.svg = UnderlinePenSvg;
            } else if (this.use === 'refresh') {
                this.svg = RefreshSvg;
            } else if (this.use === 'search') {
                this.svg = SearchSVG;
            } else if (this.use === 'loader') {
                this.svg = LoaderSvg;
            } else if (this.use === 'equipment') {
                this.svg = EquipmentSvg;
            } else if (this.use === 'port') {
                this.svg = PortSVG;
            } else if (this.use === 'dmz') {
                this.svg = DmzSvg;
            } else if (this.use === 'arrow-right') {
                this.svg = ArrowRightSvg;
            } else if (this.use === 'first-deployment-start') {
                this.svg = FirstProvisioningStartSvg;
            } else if (this.use === 'first-deployment-steps') {
                this.svg = FirstProvisioningStepsSvg;
            } else if (this.use === 'upload') {
                this.svg = UploadSvg;
            } else if (this.use === 'key') {
                this.svg = KeySvg;
            } else if (this.use === 'lock') {
                this.svg = LockSvg;
            } else if (this.use === 'interrogation') {
                this.svg = InterrogationSvg;
            } else if (this.use === 'link') {
                this.svg = LinkSvg;
            } else if (this.use === 'addicon') {
                this.svg = AddIconSvg;
            } else if (this.use === 'crown') {
                this.svg = CrownSvg;
            } else if (this.use === 'gear') {
                this.svg = GearSvg;
            } else if (this.use === 'unlink') {
                this.svg = UnlinkSvg;
            } else if (this.use === 'warningicon') {
                this.svg = WarningIconSvg;
            } else if (this.use === 'snmp') {
                this.svg = SnmpSvg;
            } else if (this.use === 'warningflaticon') {
                this.svg = WarningIconFlatSvg;
            } else if (this.use === 'valid') {
                this.svg = ValidSvg;
            } else if (this.use === 'wan2dmz') {
                this.svg = Wan2DmzSvg;
            } else if (this.use === 'wan2lan') {
                this.svg = Wan2LanSvg;
            } else if (this.use === 'lan2wan') {
                this.svg = Lan2WanSvg;
            } else if (this.use === 'lan2lan') {
                this.svg = Lan2LanSvg;
            } else if (this.use === 'padlock') {
                this.svg = PadlockSvg;
            } else if (this.use === 'switch-equipment') {
                this.svg = SwitchEquipmentSvg;
            } else if (this.use === 'router-4g') {
                this.svg = Router4gSvg;
            }
        },
    },
};
</script>
