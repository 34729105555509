var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.lanItemOption }, [
    _c("div", { class: _vm.$style.body8 }, [_vm._t("title")], 2),
    _vm._v(" "),
    _c("div", { class: _vm.$style.form }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }