import { naturalCompare } from '@/utils/utils';

const state = {
    address: {
        city: null,
        rivoli_code: null,
        insee_code: null,
        postal_code: null,
        street_label: null,
        street_number: null,
    },
    cities: [],
    streets: [],
    street_numbers: [],
};

const getters = {
    address: state => state.address,
    cities: state => state.cities,
    streets: state => state.streets,
    street_numbers: state => state.street_numbers.sort(naturalCompare),
};

const mutations = {
    set_attribute_address(state, { key, value }) {
        state.address[key] = value;
    },
    set_address(state, address) {
        state.address = address;
    },
    set_cities(state, cities) {
        state.cities = cities;
    },
    set_streets(state, streets) {
        state.streets = streets;
    },
    set_street_numbers(state, streetNumbers) {
        state.street_numbers = streetNumbers;
    },
};

const actions = {
    select_city({ state }, inseeCode) {
        state.address.city = '';
        state.address.insee_code = inseeCode;

        state.cities.forEach((item) => {
            if (item.insee_code === inseeCode) {
                state.address.city = item.label;
            }
        });
    },
    select_street({ commit, state }, rivoliCode) {
        state.address.rivoli_code = rivoliCode;
        state.address.street_label = null;
        commit('set_street_numbers', []);

        state.streets.forEach((item) => {
            if (item.rivoli_code === rivoliCode) {
                state.address.street_label = item.label;
                commit('set_street_numbers', item.street_numbers);
            }
        });
    },
    fetch_cities({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            rootState.axios_api.get('/api/core/getcities/', {
                params: {
                    postal_code: state.address.postal_code,
                },
            }).then((response) => {
                commit('set_cities', response.data);
                resolve();
            }).catch(reject);
        });
    },
    fetch_lines_numbers({ rootState }) {
        return new Promise((resolve, reject) => {
            rootState.axios_api.get('/api/core/getnumbers/', {
                params: {
                    insee_code: state.address.insee_code,
                    rivoli_code: state.address.rivoli_code,
                    street_label: state.address.street_label,
                    street_number: state.address.street_number,
                },
            }).then((response) => {
                resolve(response);
            }).catch(reject);
        });
    },
    fetch_streets({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            rootState.axios_api.get('/api/core/getstreets/', {
                params: {
                    insee_code: state.address.insee_code,
                    label: state.address.street_label,
                },
            }).then((response) => {
                commit('set_streets', response.data);
                resolve();
            }).catch(reject);
        });
    },
    reset_address({ commit }) {
        const emptyAddress = {
            city: null,
            rivoli_code: null,
            insee_code: null,
            postal_code: null,
            street_label: null,
            street_number: null,
        };
        commit('set_address', emptyAddress);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
