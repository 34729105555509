<template>
    <div :class="$style.specification_item">
        <div :class="$style.label">
            <slot name="label">
                {{ label }}
            </slot>
        </div>
        <div :class="$style.value">
            <slot name="value">
                {{ formattedValue }}
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: [String, Number, Array],
            required: false,
            default: null,
        },
    },
    computed: {
        formattedValue() {
            const { value } = this;
            if (Array.isArray(value)) return value.join(',');
            return value;
        },
    },
};
</script>

<style lang="scss" module>
.specification_item {
    display: flex;
    flex-direction: column;
    width: calc(50% - 25px);
    height: fit-content;
    margin: 15px 10px 0 15px;

    &:first-child,
    &:first-child + .specification_item {
        margin: 0 10px 0 15px;
    }

    @include themify($themes) {
        border-bottom: 1px solid themed('grayscale_extralight');

        @include under-to(lg) {
            border-bottom: 0;
        }
    }

    @include under-to(lg) {
        flex-direction: row;
        width: 100%;
        margin: 0 10px 0 15px;
    }

    &:last-child,
    &:nth-last-child(2):nth-child(odd) {
        border-bottom: none;

        @include respond-from(lg) {
            .info_value {
                margin-bottom: 0;
            }
        }
    }

    .label {
        margin-bottom: 15px 0 3px 0;
        font-size: 12px;
        line-height: 1.33;

        @include under-to(lg) {
            width: 50%;
        }

        * {
            justify-content: start;
        }
    }

    .value {
        display: block;
        margin-top: 3px;
        margin-bottom: 15px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
        color: #545454;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include under-to(lg) {
            width: 50%;
            margin-top: 0;
        }

        * {
            justify-content: start;
        }
    }
}
</style>
