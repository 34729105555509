var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Table", {
        attrs: {
          rows: _vm.rows,
          columns: _vm.columns,
          "total-items": _vm.iplinks_count,
          "is-loading": _vm.iplinksloading,
          "sort-options": {
            initialSortBy: { field: "link_id", type: "asc" }
          },
          "pagination-options": {
            position: "top",
            perPageDropdown: [10, 20, 30, 40, 50]
          },
          "search-options": {
            enabled: true
          }
        },
        on: {
          load_data: function($event) {
            return _vm.loadData($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "table-row",
            fn: function(props) {
              return [
                props.column.type == "link"
                  ? _c(
                      "span",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              tag: "a",
                              to: {
                                name: "iplink",
                                params: {
                                  link_id: props.row[props.column.field]
                                }
                              },
                              exact: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row[props.column.field]) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.formattedRow[props.column.field]) +
                          "\n            "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }