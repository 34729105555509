<template>
    <div :class="$style.wifilistitem">
        <div :class="$style.header">
            <span :class="['mr-auto', $style.body6]">
                {{ name }}
            </span>
            <Button
                v-if="wifiIndex > 0"
                role="link"
                :text="$gettext('Delete this SSID')"
                icon="error"
                size="large"
                :class="$style.b1"
                :disabled="readonlyMode"
                @click="$emit('remove')"
            />
            <span :class="$style.separate" />
            <div
                :class="[$style.hidearrow, {[$style.bottom]: collapsed}]"
                @click="toggleCollapse()"
            >
                <MasterSvg
                    use="arrow"
                    width="20px"
                />
            </div>
        </div>
        <div
            v-show="!collapsed"
            :class="$style.wifiItemContent"
        >
            <span
                v-translate
                :class="$style.body8"
            >
                State
            </span>
            <Choice
                v-model="enabled"
                :disabled="readonlyMode"
                :activate-label="$gettext('SSID enabled')"
                :deactivate-label="$gettext('SSID enabled')"
                name="enableWifi"
                :class="[
                    $style.enablewifi,
                    {[$style.newLine]: !canManageWps}
                ]"
                type="switch"
            />
            <div
                v-if="canManageWps"
                :class="[$style.wpsTitle, $style.body8]"
            >
                <span v-translate>WPS</span>
                <Tooltip
                    :content="$gettext('WPS can be enabled only for the primary SSID.')"
                    :class="$style.icon"
                >
                    <MasterSvg
                        use="ic-interrogation"
                        width="18px"
                        height="18px"
                    />
                </Tooltip>
            </div>
            <Choice
                v-if="canManageWps"
                v-model="enabledWps"
                :disabled="readonlyMode"
                :activate-label="$gettext('WPS enabled')"
                :deactivate-label="$gettext('WPS enabled')"
                name="enableWPS"
                :class="$style.enablewifi"
                type="switch"
            />
            <hr :class="$style.line">
            <span
                v-translate
                :class="$style.body8"
            >
                Credentials
            </span>
            <Choice
                v-model="usePersonnalCredentials"
                :activate-label="$gettext('Use custom values')"
                :deactivate-label="$gettext('Use custom values')"
                :disabled="!canHaveDefaultCredentials || readonlyMode"
                name="usePersonnalCredentials"
                :class="[$style.enablewifi, $style.newLine]"
                type="switch"
            />
            <Field
                v-model="ssid"
                :disabled="!usePersonnalCredentials || readonlyMode"
                :errors="errors.ssid"
                :label="$gettext('SSID')"
                :class="$style.newLine"
                :placeholder="$gettext('Default SSID')"
                :info-tooltips="$gettext('You can find the default SSID behind your equipment')"
                name="ssid"
            />
            <Select
                v-model="encryptionType"
                :disabled="!usePersonnalCredentials || readonlyMode"
                :data-list="encryptionTypes"
                :errors="errors.encryption_type"
                :required="usePersonnalCredentials"
                :label="$gettext('Encryption type')"
                name="encryptionType"
            />
            <Field
                v-model="key"
                :disabled="!usePersonnalCredentials || readonlyMode"
                :errors="errors.key"
                :label="$gettext('Key')"
                :required="usePersonnalCredentials"
                :info-tooltips="infoDefaultKey"
                :placeholder="keyPlaceholder"
                name="key"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Field from '@/components/common/field.vue';
import Select from '@/components/common/select.vue';
import Choice from '@/components/common/choice.vue';
import Button from '@/components/common/button.vue';
import Tooltip from '@/components/common/tooltip.vue';
import MasterSvg from '@/components/common/master-svg.vue';

export default {
    components: {
        Field,
        Select,
        Choice,
        Button,
        Tooltip,
        MasterSvg,
    },
    props: {
        wifi: {
            type: Object,
            required: true,
        },
        wifiIndex: {
            type: Number,
            required: true,
        },
        canHaveDefaultCredentials: {
            type: Boolean,
            default: true,
        },
        canManageWps: {
            type: Boolean,
            default: true,
        },
        readonlyMode: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        collapsed: false,
    }),
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconfoptions',
        ]),
        ssid: {
            get() {
                if (!this.usePersonnalCredentials) return null;
                return this.wifi.ssid;
            },
            set(value) {
                if (!this.usePersonnalCredentials) return;
                this.$set(this.wifi, 'ssid', value);
            },
        },
        enabled: {
            get() {
                return this.wifi.enabled;
            },
            set(value) {
                this.$set(this.wifi, 'enabled', value);
            },
        },
        enabledWps: {
            get() {
                return this.wifi.enabled_wps;
            },
            set(value) {
                this.$set(this.wifi, 'enabled_wps', value);
            },
        },
        usePersonnalCredentials: {
            get() {
                return !this.wifi.use_default_credentials;
            },
            set(value) {
                this.$set(this.wifi, 'use_default_credentials', !value);
            },
        },
        encryptionType: {
            get() {
                return this.wifi.encryption_type;
            },
            set(value) {
                this.$set(this.wifi, 'encryption_type', value);
            },
        },
        key: {
            get() {
                if (!this.usePersonnalCredentials) return null;
                return this.wifi.key;
            },
            set(value) {
                if (!this.usePersonnalCredentials) return;
                this.$set(this.wifi, 'key', value);
            },
        },
        infoDefaultKey() {
            if (this.usePersonnalCredentials) return null;
            return this.$gettext('You can find the default key behind your equipment');
        },
        keyPlaceholder() {
            if (this.usePersonnalCredentials) return null;
            return this.$gettext('Default key');
        },
        name() {
            if (this.wifiIndex === 0) return this.$gettext('Main SSID');
            return this.$gettextInterpolate(
                this.$gettext('Guest SSID %{n}'),
                { n: this.wifiIndex },
            );
        },
        encryptionTypes() {
            const encryptionTypes = [];

            if (this.linkequipmentconfoptions && this.linkequipmentconfoptions.actions) {
                this.linkequipmentconfoptions.actions.PUT.wifis.child.children.encryption_type
                    .choices.forEach((item) => {
                        encryptionTypes.push({
                            text: item.display_name,
                            id: item.value,
                        });
                    });
            }

            return encryptionTypes;
        },
    },
    watch: {
        wifi: {
            handler() {
                if (this.encryptionTypes.length === 1) {
                    this.encryptionType = this.encryptionTypes[0].id;
                }
                if (!this.canHaveDefaultCredentials) {
                    this.usePersonnalCredentials = true;
                }
                if (!this.canManageWps) {
                    this.enabledWps = false;
                }
            },
            deep: true,
            immediate: true,
        },
        canHaveDefaultCredentials(value) {
            if (!value) this.usePersonnalCredentials = true;
        },
        canManageWps(value) {
            if (!value) this.enabledWps = false;
        },
    },
    methods: {
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
    },
};
</script>

<style lang="scss" module>
.wifilistitem {
    box-sizing: initial;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s ease all;
    animation: fade-up 0.3s ease-out;

    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        min-height: 63px;
        padding: 0 24px;

        @include themify($themes) {
            border-bottom: 1px solid rgba(themed('primary_color'), 0.1);
        }

        @include under-to(lg) {
            flex-direction: row;
            align-items: center;
            padding: 10px 20px;
        }

        .hidearrow {
            display: flex;
            width: 20px;
            height: 13px;
            margin-left: 42px;

            :global(svg) {
                width: 15px;
                height: 13px;
                cursor: pointer;
                transition: 0.3s all;
            }

            &.bottom {
                :global(svg) {
                    transform: rotate(180deg);
                }
            }
        }

        &:after {
            position: absolute;
            right: 62px;
            width: 1px;
            height: 100%;
            content: '';

            @include themify($themes) {
                background-color: rgba(themed('gray_scale_dark'), 0.1);
            }
        }
    }

    .wifiItemContent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
        width: auto;
        padding: 25px;
        margin: 10px 0 10px 0;
        text-align: left;
        background-color: white;

        @include under-to(lg) {
            grid-template-columns: repeat(1, 1fr);
        }

        .wpsTitle {
            display: flex;
            grid-row: 1;
            grid-column: 2;

            @include under-to(lg) {
                grid-row: 3;
                grid-column: 1;
            }

            .icon {
                width: 18px;
                height: 18px;
                margin-left: 10px;

                @include themify($themes) {
                    color: themed('grayscale_medium');
                }
            }
        }

        .line {
            grid-column: span 2;
            width: 100%;
            margin: 0;

            @include under-to(lg) {
                grid-column: 1;
            }
        }

        .newLine {
            grid-column: 1;
        }
    }
}
</style>
