const IPCIDR = require('ip-cidr');
const { BigInteger } = require('jsbn');

/**
 * @param {bigint} x
 * @param {bigint} n
 * Same as 'Math.pow' for the bigint type.
 * @returns {bigint}
 */
function powBigInt(x, n) {
    let result = x;
    for (let i = 1; i < n; i += 1) {
        result *= x;
    }
    return result;
}

/**
 * @param {number} ipVersion
 * Get max prefix size depending on IP version.
 * ipVersion can be 6 or 4.
 * @returns {number}
 */
export function getMaxPrefixSize(ipVersion) {
    if (ipVersion === 6) return 128;
    return 32;
}

/**
 * @param {string} ipAddress
 * @param {string} prefixSize
 * @param {number} index
 * Get previous IP address of IP address given in paramters.
 * This IP need to be include in builded network with a prefix size.
 * Decrementation can be manage by index param.
 * @returns {string}
 */
export function getPreviousIPAddress(ipAddress, prefixSize, index = 1) {
    const network = new IPCIDR(`${ipAddress}/${prefixSize}`);
    const oldIPAddress = IPCIDR.createAddress(ipAddress);
    const newIPAddressBigInt = oldIPAddress.bigInteger().subtract(new BigInteger(`${index}`));
    const newIPAddress = network.ipAddressType.fromBigInteger(newIPAddressBigInt);

    return newIPAddress.correctForm();
}

/**
 * @param {string} ipAddress
 * @param {string} prefixSize
 * @param {number} index
 * Get next IP address of IP address given in paramters.
 * This IP need to be include in builded network with a prefix size.
 * Incrementation can be manage by index param.
 * @returns {string}
 */
export function getNextIPAddress(ipAddress, prefixSize, index = 1) {
    const network = new IPCIDR(`${ipAddress}/${prefixSize}`);
    const oldIPAddress = IPCIDR.createAddress(ipAddress);
    const newIPAddressBigInt = oldIPAddress.bigInteger().add(new BigInteger(`${index}`));
    const newIPAddress = network.ipAddressType.fromBigInteger(newIPAddressBigInt);

    return newIPAddress.correctForm();
}

/**
 * @param {IPCIDR} network
 * @param {boolean} exclusive
 * Get first IP address from network given in paramters.
 * If exclusive is true the network address is exclude.
 * @returns {string}
 */
export function getFirstIPFromNetwork(network, exclusive = true) {
    let firstAddress = network.addressStart;
    if (exclusive) {
        firstAddress = firstAddress.startAddressExclusive();
    }
    return firstAddress.correctForm();
}

/**
 * @param {IPCIDR} network
 * @param {boolean} exclusive
 * Get last IP address from network given in paramters.
 * If exclusive is true the brod address is exclude.
 * @returns {string}
 */
export function getLastIPFromNetwork(network, exclusive = true) {
    let lastAddress = network.addressEnd;
    if (exclusive) {
        lastAddress = lastAddress.endAddressExclusive();
    }
    return lastAddress.correctForm();
}

/**
 * @param {string} cidrMasterNetwork
 * @param {number} ipVersion
 * @param {number} prefixSize
 * @param {Array} usedNetworks
 * @param {bigint} startIndex
 * Generate network depending on prefix size.
 * This network is included in master network.
 * If usedNetworks is given the generated network must not be already include in this array.
 * Incrementation can be manage by startIndex param.
 * @returns {IPCIDR}
 */
export function generateNetworkFromMasterNetwork(
    masterNetwork, ipVersion, prefixSize, usedNetworks = [], startIndex = 0n,
) {
    const cidrMasterNetwork = new IPCIDR(masterNetwork);

    const maxPrefixSize = getMaxPrefixSize(ipVersion);
    const networkSize = maxPrefixSize - prefixSize;

    let networkAddress = null;
    let index = startIndex;
    do {
        const from = (powBigInt(2n, networkSize) * index);
        [networkAddress] = cidrMasterNetwork.toArray({ from, limit: 1 });
        if (!usedNetworks.includes(`${networkAddress}/${prefixSize}`)) {
            break;
        }
        index += 1n;
    } while (index <= maxPrefixSize - networkSize);

    return new IPCIDR(`${networkAddress}/${prefixSize}`);
}
