<template>
    <div>
        <vue-good-table
            mode="remote"
            style-class="vgt-table"
            :total-rows="totalItems"
            :rows="rows"
            :columns="columns"
            :sort-options="sortOptions"
            :global-search="true"
            :search-options="searchOptions"
            :pagination-options="pagination"
            :is-loading="isLoading"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-search="onSearch"
            @on-per-page-change="onPerPageChange"
            @on-cell-click="onCellClick"
        >
            <template v-slot:table-row="props">
                <slot
                    name="table-row"
                    v-bind="props"
                />
            </template>
            <template v-slot:loadingContent>
                <Loader
                    :show="isLoading"
                    :class="$style.table_loader"
                    width="150px"
                >
                    <template v-slot:after>
                        <h3 v-translate>
                            Loading results
                        </h3>
                    </template>
                </Loader>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import Vue from 'vue';
import VueGoodTable from 'vue-good-table';
import Loader from './loader.vue';

Vue.use(VueGoodTable);

const DEFAULT_PAGINATION = {
    enabled: true,
    position: 'bottom',
    mode: 'pages',
    perPageDropdown: [2, 4, 6, 8, 10],
    dropdownAllowAll: false,
};

const DEFAULT_ORDERING = {
    enabled: true,
};

const DEFAULT_SEARCH = {
    enabled: true,
    skipDiacritics: true,
};

export default {
    components: {
        Loader,
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
        rows: {
            type: Array,
            required: true,
        },
        paginationOptions: {
            type: Object,
            default: () => {},
        },
        cellClick: {
            type: Object,
            default: () => null,
        },
        totalItems: {
            type: Number,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        sortOptions: {
            type: Object,
            default: () => {},
        },
        searchOptions: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            pagination: {},
            ordering: {},
            search: {},
            serverParams: {
                columnFilters: {
                },
                sort: {
                    field: '',
                    type: '',
                },
                sortList: '',
                search: '',
                page: 0,
                limit: 0,
                offset: 0,
            },
        };
    },
    watch: {
        serverParams: {
            handler(oldValue, newValue) {
                if (oldValue !== newValue) {
                    this.$emit('load_data', Object.assign(this.serverParams));
                }
            },
            deep: true,
        },
    },
    created() {
        this.pagination = Object.assign(DEFAULT_PAGINATION, this.paginationOptions);
        this.ordering = Object.assign(DEFAULT_ORDERING, this.sortOptions);
        this.search = Object.assign(DEFAULT_SEARCH, this.searchOptions);

        [this.serverParams.limit] = this.pagination.perPageDropdown;
    },
    mounted() {
        this.$emit('load_data', this.serverParams);
    },
    methods: {
        updateParams(partialParams) {
            this.serverParams = Object.assign({}, this.serverParams, partialParams);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage,
                offset: (params.currentPage - 1) * params.currentPerPage,
            });
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                offset: (params.currentPage - 1) * params.currentPerPage,
            });
        },

        onSortChange(params) {
            const result = [];
            const orderingList = [];

            params.forEach((element) => {
                let orderingType = '';
                let sortField = element.field;

                if (element.type === 'desc') {
                    orderingType = '-';
                }

                this.columns.forEach((column) => {
                    if (column.field === sortField && column.sort_field) {
                        sortField = column.sort_field;
                    }
                });

                orderingList.push(`${orderingType}${sortField}`);

                result.push({
                    type: element.type,
                    field: sortField,
                });
            });

            this.updateParams({
                sort: result,
                sortList: orderingList.toString(),
            });
        },

        onColumnFilter(params) {
            params.offset = 0;
            this.updateParams(params);
        },

        onSearch(params) {
            this.updateParams({
                search: params.searchTerm,
                offset: 0,
            });
        },

        onCellClick(params) {
            if (this.cellClick) {
                this.cellClick(params);
            }
        },
    },
};
</script>

<style lang="scss" module>
.table_loader {
    margin: 50px auto 50px auto;
    transition: 0.6s ease all;
}
</style>
