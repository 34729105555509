<template>
    <li
        :class="[
            $style.menu_item,
            {[$style.active]: active}
        ]"
        :section="section"
        @click="$emit('click')"
    >
        <div
            v-if="updatedSection"
            :class="$style.flyinginfo"
            :data-content="$gettext('Changes have been made but are not yet deployed')"
        >
            <MasterSvg
                use="info"
                width="14px"
                height="14px"
            />
        </div>
        <span>{{ name }}</span>
    </li>
</template>

<script>
import MasterSvg from './master-svg.vue';

export default {
    components: {
        MasterSvg,
    },
    props: {
        section: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
        updatedSection: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
    },
    methods: {
    },
};
</script>

<style lang="scss" module>
.menu_item {
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 10px;
    margin-bottom: 15px;
    font-weight: 100;
    list-style: none;
    cursor: pointer;
    border-radius: 0 20px 20px 0;
    transition: 0.3s ease all;

    @include under-to(md) {
        height: 53px;
        padding-left: 56px;
        margin-right: 40px;
        margin-bottom: 0;
    }

    &:hover {
        font-weight: bold;
        transition: 0.3s ease all;

        @include themify($themes) {
            color: themed('primary_color');
        }
    }

    &.active {
        font-weight: 700;

        @include themify($themes) {
            color: themed('primary_color');
        }
    }

    span:first-child {
        margin-left: 30px;
    }

    .flyinginfo {
        position: relative;
        display: flex;
        align-items: center;
        width: 14px;
        height: 14px;
        margin-right: 11px;
        margin-left: 5px;

        svg {
            @include themify($themes) {
                fill: themed('primary_color');
            }
        }

        &:hover {
            &:after,
            &:before {
                display: block;
                margin-bottom: -2px;
                transition: 0.3s ease all;
            }
        }

        &:before {
            position: absolute;
            top: calc(100% + 5px);
            left: calc(50%);
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        &:after {
            position: absolute;
            top: calc(100% + 10px);
            left: -13px;
            z-index: 4;
            display: none;
            width: max-content;
            max-width: 270px;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 300;
            color: black;
            text-align: center;
            white-space: pre-wrap;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            transition: 0.3s ease all;

            @include themify($themes) {
                box-shadow: 0 0 10px 0 rgba(themed('primary_color'), 0.2);
            }
        }
    }
}
</style>
