var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.general_equipment_specifications },
    [
      _c("ComponentSpecifications", {
        attrs: { "image-src": _vm.routerPicture },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.linkequipment._equipment._model._vendor.name) +
                      "\n                " +
                      _vm._s(_vm.linkequipment._equipment._model.name) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _vm.linkequipment._equipment.serial_number
                  ? _c("ProviderBadge", {
                      attrs: { equipment: _vm.linkequipment._equipment }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "specifications",
            fn: function() {
              return [
                _vm._l(_vm.specifications.slice(0, 2), function(item) {
                  return _c("ComponentSpecificationsItem", {
                    key: item.label,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                _vm._v(" "),
                _vm.firmwareVersion && _vm.firmwareVersion.current_version
                  ? _c("ComponentSpecificationsItem", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("translate", [_vm._v("Firmware version")])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "value",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    class: _vm.$style.version,
                                    on: { click: _vm.showFirmwareModal }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.firmwareVersion.current_version
                                          ) +
                                          "\n                        "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm.linkequipmentconfoptions
                                      ? _c(
                                          "span",
                                          { class: _vm.firmwareIcon.class },
                                          [
                                            _c("MasterSvg", {
                                              attrs: {
                                                use: _vm.firmwareIcon.name,
                                                width: "12px",
                                                height: "12px"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2790041989
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(
                  _vm.specifications.slice(2, _vm.specifications.length),
                  function(item) {
                    return _c("ComponentSpecificationsItem", {
                      key: item.label,
                      attrs: { label: item.label, value: item.value }
                    })
                  }
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }