import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/ng/home.vue';
import IPLinks from '@/views/common/iplinks.vue';
import IPLink from '@/views/common/iplink.vue';
import Gps from '@/views/ng/gps.vue';
import Widgets from '@/views/ng/widgets.vue';
import PageNotFound from '@/views/common/page-not-found.vue';
import IPManager from '@/views/common/ipmanager.vue';
import IPSubnet from '@/views/common/ipsubnet.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            label: Vue.prototype.$gettext('Page not found'),
            name: 'page-not-found',
            path: '/404',
            component: PageNotFound,
        }, {
            path: '*',
            redirect: '/404',
        }, {
            label: Vue.prototype.$gettext('Home'),
            name: 'home',
            path: '/',
            component: Home,
            header: true,
        }, {
            label: Vue.prototype.$gettext('IP links'),
            name: 'iplinks',
            path: '/iplinks/',
            component: IPLinks,
            header: true,
        }, {
            label: Vue.prototype.$gettext('IP links'),
            name: 'iplink',
            path: '/iplinks/:link_id/',
            component: IPLink,
        }, {
            label: Vue.prototype.$gettext('GPS'),
            props: { displayCoordinates: true },
            name: 'gps',
            path: '/gps/',
            component: Gps,
            header: true,
        }, {
            label: Vue.prototype.$gettext('Widgets'),
            name: 'widgets',
            path: '/widgets/',
            component: Widgets,
            header: true,
        },
        {
            name: 'eligibility',
            path: process.env.NODE_ENV === 'production'
                ? 'https://atlas.unyc.io/lien/eligibilite/'
                : 'http://atlas-preprod.serveurcom.com/lien/eligibilite/',
        },
        {
            label: Vue.prototype.$gettext('IP Manager'),
            name: 'ipmanager',
            path: '/ipmanager/',
            component: IPManager,
            header: true,
        },
        {
            label: Vue.prototype.$gettext('IP Subnet'),
            name: 'ipsubnet',
            path: '/ipmanager/subnets/:subnet_id/',
            component: IPSubnet,
        },
    ],
});
