var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.provisionning }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row", class: _vm.$style.provisionningrow }, [
        _c("div", { staticClass: "col left" }, [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.title
            },
            [
              _vm._v(
                "\n                    No equipment is attached to this link yet\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.actions }, [
            _c(
              "div",
              {
                class: _vm.$style.complex_button,
                on: { click: _vm.showAttachModal }
              },
              [
                _c(
                  "div",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    class: _vm.$style.title
                  },
                  [
                    _vm._v(
                      "\n                            Provision a new equipment\n                        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    class: _vm.$style.description
                  },
                  [
                    _vm._v(
                      "\n                            Use equipment available in your stock\n                        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("MasterSvg", { attrs: { use: "arrow-right" } })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [_vm.$style.complex_button, _vm.$style.secondary],
                on: { click: _vm.showInfoModal }
              },
              [
                _c(
                  "div",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    class: _vm.$style.title
                  },
                  [
                    _vm._v(
                      "\n                            My equipment is already installed\n                        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    class: _vm.$style.description
                  },
                  [
                    _vm._v(
                      "\n                            Declare an equipment functional and already installed on site\n                        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("MasterSvg", { attrs: { use: "arrow-right" } })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col right" },
          [_c("MasterSvg", { attrs: { use: "provisionning" } })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }