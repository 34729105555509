var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { class: [_vm.$style.lanIpAddressTitle, "col-12", "mb-4", "mt-4"] },
        [
          _c("span", { class: _vm.$style.body8 }, [
            _vm._v("\n            IPv" + _vm._s(_vm.ipVersion) + "\n        ")
          ]),
          _vm._v(" "),
          _vm.ipVersion === 6
            ? _c(
                "Badge",
                { attrs: { type: _vm.slaac.enabled ? "success" : "grey" } },
                [_vm._v("\n            SLAAC\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Badge",
            { attrs: { type: _vm.dhcp.enabled ? "success" : "grey" } },
            [_vm._v("\n            DHCP\n        ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Field", {
        staticClass: "col-12 col-lg-6",
        class: _vm.$style.margin_field,
        attrs: {
          errors: _vm.errors.ip,
          label: _vm.$gettext("IP address"),
          required: true,
          name: "gateway"
        },
        model: {
          value: _vm.addressing.ip,
          callback: function($$v) {
            _vm.$set(_vm.addressing, "ip", $$v)
          },
          expression: "addressing.ip"
        }
      }),
      _vm._v(" "),
      _c("Select", {
        staticClass: "col-12 col-lg-6",
        class: _vm.$style.margin_field,
        attrs: {
          disabled: !_vm.canUpdateNetmask,
          errors: _vm.errors.netmask,
          "data-list": _vm.netmasks,
          label: _vm.$gettext("Netmask"),
          required: true,
          name: "netmask"
        },
        model: {
          value: _vm.addressing.netmask,
          callback: function($$v) {
            _vm.$set(_vm.addressing, "netmask", $$v)
          },
          expression: "addressing.netmask"
        }
      }),
      _vm._v(" "),
      !_vm.isVrrp
        ? _c("Choice", {
            staticClass: "col-12 col-lg-5 mb-3",
            attrs: {
              name: "useSecondaryLan",
              label: _vm.$gettext("Use secondary subnet"),
              type: "checkbox"
            },
            model: {
              value: _vm.useSecondaryLan,
              callback: function($$v) {
                _vm.useSecondaryLan = $$v
              },
              expression: "useSecondaryLan"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "w-100  mb-3" }),
      _vm._v(" "),
      _vm.useSecondaryLan && !_vm.isVrrp
        ? [
            _c("Field", {
              staticClass: "col-12 col-lg-6",
              class: _vm.$style.margin_field,
              attrs: {
                errors: _vm.errors.secondary_ip,
                label: _vm.$gettext("Secondary IP Address"),
                name: "secondaryIP"
              },
              model: {
                value: _vm.addressing.secondary_ip,
                callback: function($$v) {
                  _vm.$set(_vm.addressing, "secondary_ip", $$v)
                },
                expression: "addressing.secondary_ip"
              }
            }),
            _vm._v(" "),
            _c("Select", {
              staticClass: "col-12 col-lg-6",
              class: _vm.$style.margin_field,
              attrs: {
                disabled: !_vm.canUpdateNetmask,
                errors: _vm.errors.secondary_netmask,
                "data-list": _vm.netmasks,
                label: _vm.$gettext("Secondary Netmask"),
                name: "secondaryNetmask"
              },
              model: {
                value: _vm.addressing.secondary_netmask,
                callback: function($$v) {
                  _vm.$set(_vm.addressing, "secondary_netmask", $$v)
                },
                expression: "addressing.secondary_netmask"
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }