
import Vue from 'vue';
import VueMq from 'vue-mq';
import EventHub from 'vue-event-hub';
import VueGetText from 'vue-gettext';
import Loading from 'vue-loading-overlay';
import LoadingCss from 'vue-loading-overlay/dist/vue-loading.css';
import { SimpleSVG } from 'vue-simple-svg';
import translations from '@/locales/translations.json';
import InitCssComponent from '@/plugins/plugin-init-css-component';
import Modal from '@/plugins/modal';
import VueSweetalert2 from 'vue-sweetalert2';

const AVAILABLE_LANGUAGES = {
    en_US: 'English',
    fr_FR: 'Français',
};

Vue.use(VueGetText, {
    autoAddKeyAttributes: true,
    availableLanguages: AVAILABLE_LANGUAGES,
    defaultLanguage: Object.prototype.hasOwnProperty.call(AVAILABLE_LANGUAGES, navigator.language.replace('-', '_')) ? navigator.language.replace('-', '_') : 'en_US',
    languageVmMixin: {
        computed: {
            currentKebabCase() {
                return this.current.toLowerCase().replace('_', '-');
            },
        },
    },
    translations,
});

Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: Infinity,
    },
});

Vue.use(InitCssComponent);
Vue.use(Loading);
Vue.use(LoadingCss);
Vue.use(Modal);
Vue.use(EventHub);

Vue.use(VueSweetalert2);
Vue.component('loading', Loading);
Vue.component('simple-svg', SimpleSVG);
Vue.use(require('vue-moment'));
