<template>
    <div :class="$style.iplink">
        <Equipment
            v-if="showEquipment"
            :iplink="iplink"
            :can-activate-admin-account="canActivateAdminAccount"
        />
        <ManagedSection v-else>
            <template #title>
                <translate>
                    Router administration is disabled for this link.
                </translate>
            </template>
            <template #body>
                {{ bodyManagedSection }}
            </template>
        </ManagedSection>
    </div>
</template>

<script>
import Equipment from '@/components/equipment/equipment.vue';
import ManagedSection from '@/components/equipment/component-managed-section.vue';

export default {
    components: {
        Equipment,
        ManagedSection,
    },
    props: {
        iplink: {
            type: Object,
            default: null,
        },
    },
    computed: {
        canActivateAdminAccount() {
            if (!this.iplink) return false;
            return (this.iplink.accesses || []).filter(
                access => !access.is_terminated,
            ).length < 2;
        },
        iplinkWithIpAddress() {
            return this.iplink.router_ipv4_address || this.iplink.router_ipv6_address;
        },
        showEquipment() {
            return this.iplink && this.iplinkWithIpAddress && !this.iplink.is_terminated;
        },
        bodyManagedSection() {
            if (this.iplink) {
                if (this.iplink.is_terminated) {
                    return this.$gettext('Your IP link is terminated.');
                }
                if (!this.ip_link_with_ip_address) {
                    return this.$gettext('Your link does not have an IP address.');
                }
            }
            return null;
        },
    },
};

</script>

<style lang="scss" module>
    .iplink {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 20px;

        @include under-to(sm) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
</style>
