var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.titleWithAdviceState },
    [
      _vm._t("before"),
      _vm._v(" "),
      _c("div", { class: _vm.$style.title }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "Tooltip",
        {
          class: _vm.$style.iconAdviceState,
          attrs: { content: _vm.contentTooltip },
          on: { click: _vm.toggleAdivceState }
        },
        [
          _vm.iconTooltip
            ? _c("MasterSvg", {
                attrs: { use: _vm.iconTooltip, width: "18px", height: "18px" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("after")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }