var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.specification_item }, [
    _c(
      "div",
      { class: _vm.$style.label },
      [
        _vm._t("label", function() {
          return [_vm._v("\n            " + _vm._s(_vm.label) + "\n        ")]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.value },
      [
        _vm._t("value", function() {
          return [
            _vm._v("\n            " + _vm._s(_vm.formattedValue) + "\n        ")
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }