var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      [
        _c(
          "b-navbar",
          { attrs: { toggleable: "lg", type: "dark", variant: "dark" } },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-item pointer",
                attrs: {
                  tag: "b-navbar-brand",
                  href: "/",
                  to: { name: "home" },
                  exact: ""
                }
              },
              [_vm._v("\n                unyc\n            ")]
            ),
            _vm._v(" "),
            _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
            _vm._v(" "),
            _c(
              "b-collapse",
              { attrs: { id: "nav-collapse", "is-nav": "" } },
              [
                _c(
                  "b-navbar-nav",
                  [
                    _vm._l(_vm.routes, function(item) {
                      return [
                        _c(
                          "router-link",
                          {
                            key: item.name,
                            staticClass: "nav-item",
                            attrs: {
                              tag: "b-nav-item",
                              href: item.path,
                              "active-class": "active",
                              to: { name: item.name },
                              exact: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.label) +
                                "\n                        "
                            )
                          ]
                        )
                      ]
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "b-navbar-nav",
                  { staticClass: "ml-auto" },
                  [
                    _c(
                      "b-nav-item-dropdown",
                      { attrs: { right: "" } },
                      [
                        _c("template", { slot: "button-content" }, [
                          _c("em", [_c("translate", [_vm._v("More")])], 1)
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          { attrs: { href: "/admin/" } },
                          [_c("translate", [_vm._v("Admin")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "/logout" },
                            on: { click: _vm.onSignout }
                          },
                          [_c("translate", [_vm._v("Sign Out")])],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }