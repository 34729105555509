<template>
    <div :class="$style.sectionGeneral">
        <!-- general form group -->
        <div class="row">
            <div class="col-12 mb-4">
                <span
                    v-translate
                    :class="$style.body8"
                >
                    General
                </span>
            </div>
            <Field
                v-model="lan.name"
                :errors="formErrors.name"
                :label="$gettext('Name')"
                :required="true"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="name"
            />
            <Field
                v-model="lan.vlan_id"
                :errors="formErrors.vlan_id"
                :label="$gettext('VLAN ID')"
                :required="true"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="vlan_id"
            />
            <Select
                v-if="wanChoices.length > 1"
                v-model="internalMainExitWan"
                :errors="formErrors.main_exit_wan"
                :data-list="wanChoices"
                :label="$gettext('Priority WAN exit')"
                :info-tooltips="tooltipWanContent"
                :required="true"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="main_exit_wan"
            />
        </div>

        <hr :class="$style.separator">

        <!-- ipv4 form group -->
        <IpAddressBlock
            v-if="canIPv4"
            :ip-version="4"
            :addressing="lan.addressing[ipv4Index]"
            :errors="formErrors.addressing[ipv4Index]"
            :netmasks="ipv4Netmasks"
            :default-netmask="24"
            can-update-netmask
            :is-vrrp="isVrrp"
        />
        <hr
            v-if="canIPv6"
            :class="$style.separator"
        >

        <!-- ipv6 form group -->
        <IpAddressBlock
            v-if="canIPv6"
            :ip-version="6"
            :addressing="lan.addressing[ipv6Index]"
            :errors="formErrors.addressing[ipv6Index]"
            :netmasks="ipv6Netmasks"
            :default-netmask="64"
            :is-vrrp="isVrrp"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Field from '@/components/common/field.vue';
import Select from '@/components/common/select.vue';
import SpecificationsMixin from './mixins/specifications.js';

import IpAddressBlock from './lan-section-general-ip-address.vue';

export default {
    components: {
        Field,
        Select,
        IpAddressBlock,
    },
    mixins: [
        SpecificationsMixin,
    ],
    props: {
        lan: {
            type: Object,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
        wanChoices: {
            type: Array,
            default: (() => []),
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconfoptions',
        ]),
        formErrors() {
            // circumvent empty nested properties on errors prop object
            return {
                addressing: [
                    {
                        ip: null,
                        netmask: null,
                        secondary_ip: null,
                        secondary_netmask: null,
                    },
                    {
                        ip: null,
                        netmask: null,
                        secondary_ip: null,
                        secondary_netmask: null,
                    },
                ],
                ...this.errors,
            };
        },
        ipv4Netmasks() {
            const choices = [];

            if (this.linkequipmentconfoptions) {
                (this.linkequipmentconfoptions.ipv4.netmask || []).forEach(
                    (netmask) => {
                        choices.push({
                            id: netmask,
                            text: `/${netmask}`,
                        });
                    },
                );
            }

            return choices;
        },
        ipv6Netmasks() {
            const choices = [];

            if (this.linkequipmentconfoptions) {
                (this.linkequipmentconfoptions.ipv6.netmask || []).forEach(
                    (netmask) => {
                        choices.push({
                            id: netmask,
                            text: `/${netmask}`,
                        });
                    },
                );
            }

            return choices;
        },
        ipv4Index() {
            const index = (this.lan.addressing || []).findIndex(
                x => x.ip_version === 4,
            );
            if (index === -1) {
                return null;
            }
            return index;
        },
        ipv6Index() {
            const index = (this.lan.addressing || []).findIndex(
                x => x.ip_version === 6,
            );
            if (index === -1) {
                return null;
            }
            return index;
        },
        internalMainExitWan: {
            get() {
                return this.lan.main_exit_wan;
            },
            set(value) {
                if (this.lan.main_exit_wan && this.lan.main_exit_wan !== value) {
                    this.$emit('update-main-exit-wan', value);
                }
                this.$set(this.lan, 'main_exit_wan', value);
            },
        },
        tooltipWanContent() {
            let info = this.$gettext('Other IP links will be used as backup.');
            if (this.isMpls && this.isMultiwan) {
                info += '\u000A';
                info += this.$gettext('In the MPLS Multiwan case, this choice applies to all LANs.');
            }
            return info;
        },
    },
    watch: {
        lan: {
            handler() {
                if (!this.lan) {
                    return;
                }

                if (!this.internalMainExitWan) {
                    this.internalMainExitWan = this.wanChoices[0].id;
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" module>
.sectionGeneral {
    background-color: inherit;

    .margin_field {
        margin-bottom: 32px;
    }

    .separator {
        @include themify($themes) {
            margin: 0;
            border-top: 1px solid themed('grayscale_extralight');
        }
    }
}
</style>
