<template>
    <div :class="[$style.disabled_section, 'row']">
        <Title
            v-if="title"
            class="mt-3 mb-4"
            :title="title"
        />
        <div :class="$style.placeholder">
            <MasterSvg
                :use="svgName"
                :width="svgWidth"
                :height="svgHeight"
                :class-css="$style.img"
            />
            <h3 :class="$style.placeholder_title">
                <slot name="title">
                    <translate>
                        An MPLS is deployed and manages this IP link.
                    </translate>
                </slot>
            </h3>
            <div :class="$style.placeholder_body">
                <slot name="body">
                    <span v-translate="{network_zone: section}">
                        You cannot create and configure %{ network_zone } on this access.
                    </span>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';
import Title from './component-title.vue';

export default {
    components: {
        MasterSvg,
        Title,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        section: {
            type: String,
            default: null,
        },
        svgName: {
            type: String,
            default: 'padlock',
        },
        svgWidth: {
            type: String,
            default: null,
        },
        svgHeight: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" module>
.disabled_section {
    .img {
        width: 100%;
        max-height: 150px;
        margin-bottom: 30px;
        animation: fade-up 300ms ease-in-out;

        @include under-to(lg) {
            margin-top: 15px;
        }

        svg {
            width: 100%;
            max-height: 150px;
        }
    }

    .placeholder {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        width: 100%;
        padding: 40px 80px;
        text-align: center;
        border-radius: 4px;

        @include under-to(lg) {
            padding: 30px 10%;
        }

        @include themify($themes) {
            background-color: lighten(themed("tertiary_color"), 2.5%);
        }

        .placeholder_title {
            font-size: 16px;
            font-weight: 500;
        }

        .placeholder_body {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.63;
        }
    }
}
</style>
