<template>
    <Field
        v-model="internalValue"
        :errors="formattedErrors"
        :required="required"
        :readonly="readonly"
        :label="label"
        :disabled="disabled"
        name="name"
        :placeholder="placeholder"
        :info-tooltips="infoTooltips"
    />
</template>

<script>
import Field from '@/components/common/field.vue';

export default {
    components: {
        Field,
    },
    props: {
        errors: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        multipleValue: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            labelAllIPs: `0.0.0.0/0 ${this.$gettext('(ALL)')}`,
            labelAllIPv6s: `::/0 ${this.$gettext('(ALL)')}`,
            internalValue: '',
        };
    },
    computed: {
        infoTooltips() {
            if (!this.multipleValue) return null;
            return this.$gettext(
                'You can specify an IP address list or subnet list by separating them by ";"',
            );
        },
        formattedErrors() {
            if (!this.errors) return null;
            return Object.values(this.errors);
        },
    },
    watch: {
        internalValue: {
            handler(newValue, previousValue) {
                if (newValue === '0.0.0.0' && !previousValue.endsWith('/')) {
                    this.internalValue = '0.0.0.0/0';
                    return;
                }
                if (newValue === '::' && !previousValue.endsWith('/')) {
                    this.internalValue = '::/0';
                    return;
                }
                if (newValue === '0.0.0.0/0' && !previousValue.endsWith(' ')) {
                    this.internalValue = this.labelAllIPs;
                    return;
                }
                if (newValue === '::/0' && !previousValue.endsWith(' ')) {
                    this.internalValue = this.labelAllIPv6s;
                    return;
                }
                let formattedValue = this.internalValue.replace(
                    this.labelAllIPs,
                    '0.0.0.0/0',
                );
                formattedValue = formattedValue.replace(
                    this.labelAllIPv6s,
                    '::/0',
                );

                this.$emit('input', formattedValue.split(/(?:,| |\+|;)+/).filter(t => t));
            },
        },
        immediate: true,
    },
    created() {
        this.internalValue = this.value.join(';');
    },
};
</script>
