var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.collapsableBlockLayout,
        ((_obj = {}), (_obj[_vm.$style[_vm.breakpoint]] = _vm.breakpoint), _obj)
      ]
    },
    [
      _c(
        "div",
        { class: _vm.$style.header },
        [
          _c("h3", [_vm._t("title")], 2),
          _vm._v(" "),
          _vm.showContent ? [_vm._t("header")] : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.$style.arrow,
                ((_obj$1 = {}),
                (_obj$1[_vm.$style.active] = _vm.showContent),
                _obj$1)
              ],
              on: {
                click: function($event) {
                  return _vm.toggleShowContent()
                }
              }
            },
            [_c("span"), _vm._v(" "), _c("span")]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "SlideUpDownTransition",
        { attrs: { active: _vm.showContent } },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }