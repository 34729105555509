<template>
    <!-- eslint-disable max-len -->
    <HeaderAdviceLayout
        :advice-display.sync="adviceDisplay"
        advice-link-url="https://kiosque.unyc.io/document/administration-des-routeurs/route-adr/"
    >
        <!-- Advice -->
        <template v-slot:advice-title>
            <translate>Routes</translate>
        </template>
        <template v-slot:advice-content>
            <translate>
                Entering a static route manually determines the path that a packet must take to reach a network B from a network A.
            </translate>
        </template>
        <template v-slot:advice-example>
            <p v-translate>
                Exemple: You want packets destined for the 32.15.26.0/24 subnet to pass through the 192.168.0.62 IP address. You will need to set the following rule:
            </p>
            <ul>
                <li v-translate>
                    Destination IP: 32.15.26.0
                </li>
                <li v-translate>
                    Netmask: /24
                </li>
                <li v-translate>
                    Gateway: 192.168.0.62
                </li>
                <li v-translate>
                    Metric: 1
                </li>
            </ul>
        </template>

        <!-- Updated section block -->
        <BannerUpdatedSection v-if="isUpdated" />

        <!-- Content -->
        <TitleWithAdviceState v-model="adviceDisplay">
            <translate>Routes</translate>
            <template #after>
                <DesktopAndMobileButton
                    role="primary"
                    icon="add"
                    :text="$gettext('Add route')"
                    @click="addRoute"
                />
            </template>
        </TitleWithAdviceState>

        <!-- Steps -->
        <StepsList
            v-if="!routes.length"
            image="route"
        >
            <template #step-1>
                <translate>
                    Click on "Add route"
                </translate>
            </template>
            <template #step-2>
                <translate>
                    Fill in all the fields correctly and validate by clicking on the button
                </translate>
            </template>
            <template #step-3>
                <translate>
                    Do not forget to deploy the configuration for equipment to take new changes
                </translate>
            </template>
        </StepsList>

        <!-- Table -->
        <RoutesTable
            v-else
            :routes="routes"
            :selected-ip-version.sync="selectedIpVersion"
            @edit-route="editRoute"
            @delete-route="deleteRoute"
        />
    </HeaderAdviceLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { showSwalError, showToast } from '@/utils/utils';

import { actionsControl } from '../utils/utils';

import BannerUpdatedSection from '../common/banner-updated-section.vue';
import DesktopAndMobileButton from '../common/desktop-and-mobile-button.vue';
import HeaderAdviceLayout from '../layout/header-advice-layout.vue';
import StepsList from '../common/steps-list.vue';
import TitleWithAdviceState from '../common/title-with-advice-state.vue';

import RoutesTable from './routes-table.vue';

import AdviceMixin from '../mixins/advice.js';

const AddRouteRuleModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-addrouterule" */
    '../modals/addrouterule.vue'
);

export default {
    components: {
        BannerUpdatedSection,
        DesktopAndMobileButton,
        HeaderAdviceLayout,
        RoutesTable,
        StepsList,
        TitleWithAdviceState,
    },
    mixins: [
        AdviceMixin,
    ],
    data: () => ({
        adviceId: 'routes',
        selectedIpVersion: 'all',
    }),
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconf',
            'updated_sections',
        ]),
        routes() {
            return (this.linkequipmentconf || {}).routes || [];
        },
        isUpdated() {
            return (this.updated_sections || []).includes('routes');
        },
    },
    mounted() {
        this.fetch_linkequipmentconf().catch(showSwalError);
    },
    methods: {
        ...mapActions('equipment', [
            'set_conf_section',
            'fetch_linkequipmentconf',
        ]),
        addRoute() {
            this.showRouteModal(
                {
                    netmask: this.selectedIpVersion === 6 ? 128 : 32,
                    ip_version: this.selectedIpVersion !== 'all' ? this.selectedIpVersion : 4,
                },
                true,
            );
        },
        deleteRoute(route) {
            const index = this.routes.findIndex(savedRoute => savedRoute.uuid === route.uuid);
            actionsControl(this.linkequipment).then(() => {
                this.routes.splice(index, 1);
                this.set_conf_section({
                    section: 'routes',
                    conf: this.routes,
                }).then(() => {
                    showToast(this.$gettext('Route deleted'));
                }).catch(showSwalError);
            }).catch(showSwalError);
        },
        editRoute(route) {
            this.showRouteModal(route, false);
        },
        showRouteModal(defaultRule, isNewRule) {
            this.show_modal(
                AddRouteRuleModal,
                {
                    defaultRule,
                    isNewRule,
                },
                {
                    maxWidth: 650,
                    height: 'auto',
                },
            );
        },
    },
};
</script>
