var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.accessForm },
    [
      _vm.isBackup
        ? _c(
            "span",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.labelForm
            },
            [_vm._v("\n        Backup connexion\n    ")]
          )
        : _c(
            "span",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.labelForm
            },
            [_vm._v("\n        Main connexion\n    ")]
          ),
      _vm._v(" "),
      _vm.isBackup && _vm.wan
        ? _c(
            "div",
            { class: _vm.$style.flexColunm },
            [
              _c(
                "span",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  class: _vm.$style.accessTitle
                },
                [_vm._v("\n            Modem choice\n        ")]
              ),
              _vm._v(" "),
              _c("Select", {
                staticClass: "mt-3",
                class: _vm.$style.backupModem,
                attrs: {
                  "data-list": _vm.modemChoices,
                  disabled: _vm.readonlyMode,
                  required: true,
                  name: "backupModem"
                },
                model: {
                  value: _vm.currentModem,
                  callback: function($$v) {
                    _vm.currentModem = $$v
                  },
                  expression: "currentModem"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.linkequipment
        ? _c(
            "div",
            { class: _vm.$style.modem },
            [
              _c("ComponentSpecifications", {
                class:
                  ((_obj = {}),
                  (_obj[_vm.$style.customerOwnedModem] =
                    _vm.customerOwnedModem),
                  _obj),
                attrs: { "image-src": _vm.modemPicture },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            {
                              directives: [
                                { name: "translate", rawName: "v-translate" }
                              ],
                              class: _vm.$style.equipmentTitle
                            },
                            [
                              _vm._v(
                                "\n                    Modem\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.modemModel
                            ? _c("div", { class: _vm.$style.modemName }, [
                                _c("span", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.modemModel._vendor.name) +
                                      "\n                        " +
                                      _vm._s(_vm.modemModel.name) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.wan.modulate_by_equipment
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "translate",
                                            rawName: "v-translate"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                        (Integrated modem)\n                    "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm.usedInfrastructureOperatorModem
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "translate",
                                      rawName: "v-translate"
                                    }
                                  ],
                                  class: _vm.$style.modemName
                                },
                                [
                                  _vm._v(
                                    "\n                    Infrastructure operator modem\n                "
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "translate",
                                      rawName: "v-translate"
                                    }
                                  ],
                                  class: _vm.$style.modemName
                                },
                                [
                                  _vm._v(
                                    "\n                    You are using your own modem device\n                "
                                  )
                                ]
                              )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "specifications",
                      fn: function() {
                        return [
                          _c("ComponentSpecificationsItem", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c("translate", [_vm._v("Manufacturer")])
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "value",
                                  fn: function() {
                                    return [
                                      _vm.modemModel
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.modemModel._vendor.name
                                                ) +
                                                "\n                        "
                                            )
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "translate",
                                                  rawName: "v-translate"
                                                }
                                              ],
                                              class:
                                                _vm.$style.unavailableInfoValue
                                            },
                                            [
                                              _vm._v(
                                                "\n                            Unavailable information\n                        "
                                              )
                                            ]
                                          )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2064549076
                            )
                          }),
                          _vm._v(" "),
                          _c("ComponentSpecificationsItem", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c("translate", [_vm._v("Serial number")])
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "value",
                                  fn: function() {
                                    return [
                                      !_vm.modemModel ||
                                      _vm.usedInfrastructureOperatorModem
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "translate",
                                                  rawName: "v-translate"
                                                }
                                              ],
                                              class:
                                                _vm.$style.unavailableInfoValue
                                            },
                                            [
                                              _vm._v(
                                                "\n                            Unavailable information\n                        "
                                              )
                                            ]
                                          )
                                        : _vm.linkequipment._equipment
                                            .serial_number
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.linkequipment._equipment
                                                    .serial_number
                                                ) +
                                                "\n                        "
                                            )
                                          ])
                                        : _vm.wan.modulate_by_equipment
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "translate",
                                                  rawName: "v-translate"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                            Not yet connected on site\n                        "
                                              )
                                            ]
                                          )
                                        : _c("Button", {
                                            class: _vm.$style.b1,
                                            attrs: {
                                              role: "link",
                                              text: _vm.$gettext(
                                                "Type Serial number"
                                              ),
                                              size: "large"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addSerialNumber(
                                                  _vm.linkequipment
                                                )
                                              }
                                            }
                                          })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              3721737455
                            )
                          }),
                          _vm._v(" "),
                          _c("ComponentSpecificationsItem", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [_c("translate", [_vm._v("Model")])]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "value",
                                  fn: function() {
                                    return [
                                      _vm.modemModel
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(_vm.modemModel.name) +
                                                "\n                        "
                                            )
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "translate",
                                                  rawName: "v-translate"
                                                }
                                              ],
                                              class:
                                                _vm.$style.unavailableInfoValue
                                            },
                                            [
                                              _vm._v(
                                                "\n                            Unavailable information\n                        "
                                              )
                                            ]
                                          )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              672601401
                            )
                          }),
                          _vm._v(" "),
                          !_vm.wan.modulate_by_equipment
                            ? _c("ComponentSpecificationsItem", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("translate", [
                                            _vm._v("Configuration")
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "value",
                                      fn: function() {
                                        return [
                                          _vm.modemModel
                                            ? _c("Button", {
                                                class: _vm.$style.b1,
                                                attrs: {
                                                  role: "link",
                                                  text: _vm.$gettext(
                                                    "Download configuration"
                                                  ),
                                                  size: "large"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.download_configuration(
                                                      _vm.linkequipment._access
                                                    )
                                                  }
                                                }
                                              })
                                            : _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "translate",
                                                      rawName: "v-translate"
                                                    }
                                                  ],
                                                  class:
                                                    _vm.$style
                                                      .unavailableInfoValue
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            Unavailable information\n                        "
                                                  )
                                                ]
                                              )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  148724842
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("ComponentSpecificationsItem", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [_c("translate", [_vm._v("Mode")])]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "value",
                                  fn: function() {
                                    return [
                                      _vm.modemModel
                                        ? _c(
                                            "div",
                                            [
                                              _vm.wan.modulate_by_equipment
                                                ? _c("translate", [
                                                    _vm._v(
                                                      "\n                                Integrated modem\n                            "
                                                    )
                                                  ])
                                                : _c("translate", [
                                                    _vm._v(
                                                      "\n                                Bridge\n                            "
                                                    )
                                                  ])
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "translate",
                                                  rawName: "v-translate"
                                                }
                                              ],
                                              class:
                                                _vm.$style.unavailableInfoValue
                                            },
                                            [
                                              _vm._v(
                                                "\n                            Unavailable information\n                        "
                                              )
                                            ]
                                          )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              684499023
                            )
                          }),
                          _vm._v(" "),
                          !_vm.wan.modulate_by_equipment
                            ? _c("ComponentSpecificationsItem", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c("translate", [_vm._v("Port")])
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "value",
                                      fn: function() {
                                        return [
                                          _vm.modemModel
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                            Port 2\n                        "
                                                )
                                              ])
                                            : _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "translate",
                                                      rawName: "v-translate"
                                                    }
                                                  ],
                                                  class:
                                                    _vm.$style
                                                      .unavailableInfoValue
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            Unavailable information\n                        "
                                                  )
                                                ]
                                              )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  167381889
                                )
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2922424919
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.wan
        ? _c(
            "div",
            { class: _vm.$style.flexColunm },
            [
              _c(
                "span",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass: "mb-2",
                  class: _vm.$style.portTitle
                },
                [
                  _vm._v(
                    "\n            Connection port on the router\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("RadioButton", {
                class: _vm.$style.interfaces,
                attrs: {
                  choices: _vm.internalInterfacesChoices,
                  "custom-class": _vm.$style.port,
                  errors: (_vm.errors || {}).interface,
                  name: "iface-" + _vm.wan.uuid + "-" + _vm.wan.indexWan
                },
                model: {
                  value: _vm.wan.interface,
                  callback: function($$v) {
                    _vm.$set(_vm.wan, "interface", $$v)
                  },
                  expression: "wan.interface"
                }
              })
            ],
            1
          )
        : _c("ManagedSection", {
            class: _vm.$style.flexColunm,
            attrs: {
              "svg-name": "router-4g",
              "svg-heigth": "40px",
              "svg-width": "40px"
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [_c("translate", [_vm._v("No 4G modem attached")])]
                },
                proxy: true
              },
              {
                key: "body",
                fn: function() {
                  return [
                    _c("translate", [
                      _vm._v(
                        '\n                To attach a modem, click on "Add a 4G modem" and choose a compatible model, or detach and re-provision a device from the menu in the header.\n            '
                      )
                    ]),
                    _vm._v(" "),
                    _c("Button", {
                      class: _vm.$style.placeholderButton,
                      attrs: {
                        role: "secondary",
                        text: _vm.$gettext("Add a 4g modem"),
                        size: "large"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("add-modem", _vm.router)
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }