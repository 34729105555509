var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.advancedSection }, [
    _c(
      "h3",
      {
        directives: [{ name: "translate", rawName: "v-translate" }],
        staticClass: "my-3"
      },
      [_vm._v("\n        Advanced configurations\n    ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.advancedList },
      [
        _vm._l(_vm.advancedConfigurations, function(configuration, index) {
          return [
            configuration.enabled
              ? _c(
                  "div",
                  {
                    key: index,
                    class: _vm.$style.advancedBlock,
                    on: {
                      click: function($event) {
                        return _vm.$emit(
                          "click-component",
                          configuration.component,
                          configuration.sectionSuffix
                        )
                      }
                    }
                  },
                  [
                    _c("div", { class: _vm.$style.nameBlock }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(configuration.name) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.descriptionBlock }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(configuration.description) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("MasterSvg", { attrs: { use: "arrow-right" } })
                  ],
                  1
                )
              : _vm._e()
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }