<template>
    <div :class="$style.readonlyBanner">
        <div :class="$style.eyeIcon">
            <MasterSvg
                use="big-eye"
                width="76px"
                height="90px"
            />
        </div>
        <div :class="$style.description">
            <div
                v-translate
                :class="$style.title"
            >
                Read only
            </div>
            <div
                v-translate
                :class="$style.content"
            >
                You have scheduled a deployment, to make changes please cancel the deployment
            </div>
        </div>
        <div :class="$style.background">
            <MasterSvg
                use="sparkles"
                width="90%"
                height="auto"
            />
            <MasterSvg
                use="pattern"
                width="auto"
                height="100%"
            />
        </div>
    </div>
</template>

<script>
import MasterSvg from '@/components/common/master-svg.vue';

export default {
    name: 'ReadonlyBanner',
    components: {
        MasterSvg,
    },
};
</script>

<style lang="scss" module>
.readonlyBanner {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    width: 100%;
    padding: 40px 80px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        content: '';
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    @include under-to(lg) {
        flex-direction: column;
        gap: 28px;
        padding: 40px;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include under-to(lg) {
            display: none;
        }
    }

    .eyeIcon {
        z-index: 1;
        width: 76px;
        height: 90px;
    }

    .description {
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
        }

        .content {
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
        }
    }
}
</style>
