
import Vue from 'vue';
import {
    showSwal,
    showSwalError,
    setError,
} from '@/utils/utils';

export const MAP_CONF_ITEMS = {
    general: Vue.prototype.$gettext('General'),
    dmz: Vue.prototype.$gettext('DMZ'),
    dmzs: Vue.prototype.$gettext('DMZ'),
    lans: Vue.prototype.$gettext('LAN'),
    nat: Vue.prototype.$gettext('NAT'),
    routes: Vue.prototype.$gettext('Routes'),
    firewall: Vue.prototype.$gettext('Firewall'),
    interfaces: Vue.prototype.$gettext('Interfaces'),
};

function buildErrorsMessage(error, key, style, firstTitle = null) {
    let content = `<div class=${style}>`;
    let indexItem = 0;

    if (firstTitle) {
        content += `<b>${firstTitle}</b><br/>`;
    }

    if (error.errors.non_field_errors && error.errors.non_field_errors.length) {
        content += `<b>${Vue.prototype.$gettext('General errors:')}</b><ul>`;
        (error.errors.non_field_errors || []).forEach((errorConf) => {
            content += `<li>${errorConf}</li>`;
        });
        content += '</ul>';
    }

    (error.errors[key] || []).forEach((errorConf) => {
        if (Object.keys(errorConf).length) {
            const itemTitle = Vue.prototype.$gettextInterpolate(
                Vue.prototype.$gettext('Errors in item %{ n }:'),
                { n: indexItem + 1 },
            );

            content += `<b>${itemTitle}</b>`;
            content += '<ul>';
            Object.keys(errorConf).forEach((field) => {
                if (Object.keys(errorConf[field]).length === 0) {
                    content += `<li>${field}: ${errorConf[field]}</li>`;
                } else {
                    Object.keys(errorConf[field]).forEach((child) => {
                        content += `<li>${field}: ${errorConf[field][child]}</li>`;
                    });
                }
            });
            content += '</ul>';
        }
        indexItem += 1;
    });
    content += '</div>';

    let nameSection = '';
    if (Object.prototype.hasOwnProperty.call(MAP_CONF_ITEMS, key)) {
        nameSection = MAP_CONF_ITEMS[key];
    }
    const title = Vue.prototype.$gettextInterpolate(
        Vue.prototype.$gettext('Errors list from %{ s } section'),
        { s: nameSection },
    );

    showSwal({
        title,
        html: content,
        type: 'error',
    });
}

export function showErrorConf(store, error, style = null) {
    let paramsSwal = {};
    if (error.errors) {
        error.errors.non_field_errors = [];
        error.errors.detail = Vue.prototype.$gettext(
            'Some errors are present on your configuration. Please correct them.',
        );
        paramsSwal = {
            confirmButtonText: Vue.prototype.$gettext('Show detail'),
            cancelButtonText: Vue.prototype.$gettext('Ok'),
            showCloseButton: true,
            showCancelButton: true,
        };
    }
    showSwalError(
        error,
        paramsSwal,
    ).then((resultSwal) => {
        if (!resultSwal.value) {
            return;
        }
        const linkequipmentconf = store.getters['equipment/linkequipmentconf'];
        Object.keys(linkequipmentconf).forEach((key) => {
            store.dispatch(
                'equipment/set_conf_section',
                {
                    section: key,
                    conf: linkequipmentconf[key],
                    check_only: true,
                },
            ).catch((result) => {
                if (key === 'equipments') {
                    result.errors.equipments.forEach((errorEquipment, indexEquipment) => {
                        Object.keys(errorEquipment).forEach((keyEquipment) => {
                            const errorRebuild = {
                                errors: {},
                            };
                            errorRebuild.errors[keyEquipment] = errorEquipment[keyEquipment];
                            buildErrorsMessage(
                                errorRebuild,
                                keyEquipment,
                                style,
                                Vue.prototype.$gettextInterpolate(
                                    Vue.prototype.$gettext('Equipment %{n}'),
                                    { n: indexEquipment + 1 },
                                ),
                            );
                        });
                    });
                } else {
                    buildErrorsMessage(result, key, style);
                }
            });
        });
    });
}

export function actionsControl(linkequipment) {
    return new Promise((resolve, reject) => {
        if (linkequipment) {
            if (linkequipment.status === 'synchronizing') {
                reject(setError(
                    'warning',
                    Vue.prototype.$gettext('This action cannot be done because a synchronization is in progress.'),
                ));
            }
        }

        resolve();
    });
}
