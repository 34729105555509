var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.staticaddresslist }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible_addresses.length,
            expression: "visible_addresses.length"
          }
        ],
        class: _vm.$style.addresses
      },
      _vm._l(_vm.visible_addresses, function(address, index) {
        return _c("StaticAddressItem", {
          key: index,
          attrs: {
            address: address,
            disabled: _vm.disabled,
            errors: (_vm.errors && _vm.errors[index]) || {}
          },
          on: {
            remove: function($event) {
              return _vm.$emit("removeStaticAddress", index)
            }
          }
        })
      }),
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.bottom },
      [
        _vm.nb_pages > 1
          ? _c("PaginateVue", {
              attrs: {
                "items-count": _vm.addresses.length,
                "items-per-page": _vm.page_size
              },
              model: {
                value: _vm.current_page,
                callback: function($$v) {
                  _vm.current_page = $$v
                },
                expression: "current_page"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.visible_addresses.length,
            expression: "!visible_addresses.length"
          }
        ],
        class: [_vm.$style.empty, _vm.$style.body1]
      },
      [
        _c("div", [
          _c(
            "span",
            { directives: [{ name: "translate", rawName: "v-translate" }] },
            [
              _vm._v(
                "\n                There is no static address,\n            "
              )
            ]
          ),
          _vm._v("\n             \n            "),
          _c(
            "span",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: [
                _vm.$style.textButton,
                ((_obj = {}), (_obj[_vm.$style.disabled] = _vm.disabled), _obj)
              ],
              on: { click: _vm.handleAddButton }
            },
            [
              _vm._v(
                "\n                Add a first static address\n            "
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }