<template>
    <div
        :class="{[$style.tooltip]: content, [$style.greyBorder]: greyBorder}"
        :data-content="content"
        @click="$emit('click')"
    >
        <slot name="default" />
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            required: true,
        },
        greyBorder: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
.tooltip {
    position: relative;
    display: flex;

    &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: 5;
        display: none;
        width: 10px;
        height: 10px;
        margin-top: 2px;
        content: " ";
        background: white;
        transform: rotate(45deg) translate(-25%, 25%);
    }

    &:after {
        position: absolute;
        top: calc(100% + 7px);
        left: 50%;
        z-index: 4;
        display: none;
        width: max-content;
        width: intrinsic;
        padding: 5px 20px;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 1.57;
        color: black;
        text-align: center;
        white-space: pre;
        content: attr(data-content);
        background-color: white;
        border-radius: 4px;
        transition: 0.3s ease all;
        transform: translateX(-50%);

        @include themify($themes) {
            box-shadow: 0 0 12px 0 rgba(themed('primary_color'), 0.4);
        }
    }

    &:hover {
        &:after,
        &:before {
            display: block;
            transition: 0.3s ease all;
        }
    }

    &.greyBorder {
        &:after {
            @include themify($themes) {
                box-shadow: 0 0 12px 0 rgba(themed('grayscale_bold'), 0.4);
            }
        }
    }
}
</style>
