var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.input_number }, [
    _c("div", { class: _vm.$style.number_arrows }, [
      _c(
        "a",
        {
          class: _vm.$style.btn_up,
          on: {
            click: function($event) {
              return _vm.increment()
            }
          }
        },
        [_vm._v("\n            +\n        ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          class: _vm.$style.btn_down,
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.decrement()
            }
          }
        },
        [_vm._v("\n            -\n        ")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.formatted_quantity,
            expression: "formatted_quantity",
            modifiers: { number: true }
          }
        ],
        class: _vm.$style.inputclass,
        attrs: {
          type: "text",
          maxlength: _vm.maxlength,
          autocomplete: "off",
          placeholder: _vm.placeholder
        },
        domProps: { value: _vm.formatted_quantity },
        on: {
          keyup: function($event) {
            return _vm.onKeyup($event)
          },
          keydown: function($event) {
            return _vm.onKeydown($event)
          },
          blur: [
            _vm.onBlur,
            function($event) {
              return _vm.$forceUpdate()
            }
          ],
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.formatted_quantity = _vm._n($event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.symbol
        ? _c("span", { class: _vm.$style.number_symbol }, [
            _vm._v("\n            " + _vm._s(_vm.symbol) + "\n        ")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }