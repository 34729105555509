var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.titlesection, { col: _vm.addColClass }] },
    [
      _vm.showPreviousButton
        ? _c(
            "div",
            {
              class: _vm.$style.previous_button,
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("previous_action")
                }
              }
            },
            [
              _c("MasterSvg", {
                attrs: { use: "arrow-right", width: "17.5px", height: "15px" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")]),
      _vm._v(" "),
      !_vm.current_section_advice_visible
        ? _c(
            "div",
            {
              class: [_vm.$style.show_advice, _vm.$style.flyinginfo],
              attrs: { "data-content": _vm.$gettext("Show advice") },
              on: { click: _vm.showCurrentSectionAdvice }
            },
            [
              _c("MasterSvg", {
                attrs: { use: "interrogation", width: "18px", height: "18px" }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }