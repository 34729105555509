<template>
    <div class="container">
        <Gps
            :class="$style.gps_section"
            :display-coordinates="displayCoordinates"
        />
    </div>
</template>

<script>

import { mapMutations } from 'vuex';
import Gps from '@/components/gps/gps.vue';

export default {
    components: {
        Gps,
    },
    props: { displayCoordinates: Boolean },
    created() {
        this.initPosition({
            lat: 47,
            lng: 1,
            zoom: 10,
        });
    },
    methods: {
        ...mapMutations('gps', [
            'initPosition',
        ]),
    },
};

</script>

<style lang="scss" module>
    .gps_section {
        padding-top: 30px;
        padding-bottom: 30px;
    }
</style>
