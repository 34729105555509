<template>
    <div
        :class="[
            {[$style.disabled_iface]: iface.type === 'wan' && !canManageWan},
            $style.generalPortsItem
        ]"
    >
        <div :class="$style.ifacename">
            {{ iface.name }}
        </div>
        <div
            :class="[$style.interface_description, $style.body1]"
        >
            <input
                :id="`description_${iface.port_number}`"
                v-model="iface.description"
                :class="$style.description_input"
                type="text"
                name="description"
            >
            <label
                :class="$style.description_svg"
                :for="`description_${iface.port_number}`"
            >
                <MasterSvg use="underline-pen" />
            </label>
        </div>
        <div
            :class="[$style.speed, $style.noselect, $style.body1]"
            :style="{ flex: 2 }"
            @click="changeSpeed()"
        >
            {{ speedName }}
            <Loader
                v-if="loading"
                :show="loading"
                width="16px"
            />
            <Tooltip
                v-else-if="iface.link_active"
                :content="displayNegociatedInterface"
                :class="{[$style.halfDuplex]: iface.negotiated_duplex === 'half'}"
            >
                <MasterSvg use="info" />
            </Tooltip>
        </div>
        <div
            :class="[
                $style.body1,
                $style.lan_list,
                {
                    [$style.wan]: iface.type === 'wan',
                    [$style.lan_list_infos]: lanAndDmzList.length > 0,
                }
            ]"
            @click="showLans()"
        >
            <span v-if="iface.type !== 'wan'">
                {{ accessType }}
            </span>
            <span v-else>
                <b>{{ wanName }}</b>
            </span>
            <Tooltip
                v-if="lanAndDmzList.length > 0"
                :content="displayLanslist"
                :class="$style.icon"
            >
                <MasterSvg use="info" />
            </Tooltip>
        </div>
        <div :class="$style.containerSwitch">
            <Tooltip
                :content="statusInterface"
                :class="$style.container_button"
            >
                <Choice
                    v-model="iface.enabled"
                    :disabled="iface.type === 'wan'"
                    :class="$style.port_button"
                    name="enable_port"
                    type="switchButton"
                />
            </Tooltip>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Choice from '@/components/common/choice.vue';
import Loader from '@/components/common/loader.vue';
import Tooltip from '@/components/common/tooltip.vue';

import MasterSvg from '../../master-svg.vue';

const ShowLansInterfaceModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-showlansinterface" */
    '../../modals/showlansinterface.vue'
);

const NB_LAN_VISIBLE = 5;

const DUPLEXES = {
    half: 'Half duplex',
    full: 'Full duplex',
};

const SPEEDS = {
    auto: 'Auto',
    10: '10 Mbit/s',
    100: '100 Mbit/s',
    1000: '1 Gbit/s',
    2500: '2,5 Gbit/s',
    5000: '5 Gbit/s',
    10000: '10 Gbit/s',
};

export default {
    name: 'GeneralPortsListItem',
    components: {
        Choice,
        Loader,
        MasterSvg,
        Tooltip,
    },
    props: {
        iface: {
            required: true,
            type: Object,
        },
        configuration: {
            required: true,
            type: Object,
        },
        options: {
            required: true,
            type: Object,
        },
        loading: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconf',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        accessType() {
            if (this.lanAndDmzList.length > 1) {
                return `Trunk (${this.lanAndDmzList.length})`;
            }
            if (this.lanAndDmzList.length === 1) {
                return Vue.prototype.$gettext('Access');
            }

            return Vue.prototype.$gettext('No LAN attached');
        },
        lanAndDmzList() {
            const list = [];

            if (this.linkequipmentconf && this.linkequipmentconf.lans) {
                this.linkequipmentconf.lans.forEach((lan, index) => {
                    const lanConfig = (this.configuration.lans || []).find(
                        lanEquipmentConfig => lanEquipmentConfig.lan_uuid === lan.uuid,
                    ) || {};

                    if ((lanConfig.interfaces || []).includes(this.iface.port_number)) {
                        list.push(`LAN ${index + 1} - ${lan.name}`);
                    }
                });
            }

            if (this.linkequipmentconf && this.linkequipmentconf.dmz) {
                this.linkequipmentconf.dmz.forEach((dmz, index) => {
                    const dmzConfig = (this.configuration.dmzs || []).find(
                        dmzEquipmentConfig => dmzEquipmentConfig.dmz_uuid === dmz.uuid,
                    ) || {};

                    if ((dmzConfig.interfaces || []).includes(this.iface.port_number)) {
                        list.push(`DMZ ${index + 1} - ${dmz.name}`);
                    }
                });
            }

            return list;
        },
        displayLanslist() {
            let textReturn = '';
            const others = this.lanAndDmzList.length - NB_LAN_VISIBLE;

            if (this.lanAndDmzList.length > 0) {
                this.lanAndDmzList.forEach((lan, index) => {
                    if (index >= NB_LAN_VISIBLE) {
                        if (index === NB_LAN_VISIBLE) {
                            const translated = Vue.prototype.$ngettext(
                                '+ %{ n } other', '+ %{ n } others', others,
                            );
                            const interpolated = Vue.prototype.$gettextInterpolate(
                                translated, { n: others },
                            );
                            textReturn += interpolated;
                        }
                    } else {
                        textReturn += `${lan}\u000A`;
                    }
                });
            }

            return textReturn;
        },
        displayNegociatedInterface() {
            const speed = SPEEDS[this.iface.negotiated_speed];
            const duplex = DUPLEXES[this.iface.negotiated_duplex];
            return `${speed} (${duplex})`;
        },
        duplexes() {
            return [
                {
                    id: 'full',
                    text: DUPLEXES.full,
                },
            ];
        },
        speeds() {
            const speeds = [];
            if (this.options && this.options.physical_port_speeds) {
                const physicalPortSpeeds = this.options.physical_port_speeds[this.iface.name] || [];
                physicalPortSpeeds.forEach((value) => {
                    speeds.push({
                        id: value,
                        text: SPEEDS[value],
                    });
                });
            }

            return speeds;
        },
        speedName() {
            const indexDuplex = this.duplexes.findIndex(item => item.id === this.iface.duplex);
            const indexSpeed = this.speeds.findIndex(item => item.id === this.iface.speed);

            if (
                this.speeds.length === 0
                || this.duplexes.length === 0
                || indexSpeed === -1
                || indexDuplex === -1
            ) {
                return this.$gettext('Unknown');
            }

            let name = this.speeds[indexSpeed].text;
            if (this.iface.speed !== 'auto') {
                const duplex = this.duplexes[indexDuplex];

                if (duplex) {
                    name = `${name} ( ${duplex.text} )`;
                }
            }

            return name;
        },
        canManageWan() {
            return this.permissions.find(
                element => element.codename === 'can_manage_wan_interfaces' && element.app_label === 'equipments',
            ) !== undefined;
        },
        interfaceFiltersValues() {
            return [
                { label: this.$gettext('Active'), value: 'active' },
                { label: this.$gettext('Inactive'), value: 'inactive' },
            ];
        },
        wanName() {
            let wanName = '';

            if (this.configuration) {
                const wan = this.configuration.wans.find(
                    wanConf => wanConf.interface === this.iface.port_number,
                );
                if (wan) {
                    wanName = wan.type === 'main' ? wan.name : this.$gettext('Backup');
                }
            }

            return wanName;
        },
        statusInterface() {
            let status = Vue.prototype.$gettext('Enabled');

            if (!this.iface.enabled) {
                status = Vue.prototype.$gettext('Disabled');
            }
            if (this.iface.type === 'wan') {
                status = Vue.prototype.$gettext('WAN ports can not be changed');
            }

            return status;
        },
    },
    methods: {
        changeSpeed() {
            if (this.iface.type === 'wan' && !this.canManageWan) {
                return;
            }

            let indexDuplex = this.duplexes.findIndex(item => item.id === this.iface.duplex) + 1;

            if (indexDuplex >= this.duplexes.length || this.iface.speed === 'auto') {
                let indexSpeed = this.speeds.findIndex(item => item.id === this.iface.speed) + 1;
                indexDuplex = 0;

                if (indexSpeed >= this.speeds.length) {
                    indexSpeed = 0;
                }

                this.iface.speed = this.speeds[indexSpeed].id;
            }

            this.iface.duplex = this.duplexes[indexDuplex].id;
        },
        showLans() {
            if (this.$mq === 'xs' || this.$mq === 'sm') {
                this.show_modal(
                    ShowLansInterfaceModal,
                    {
                        port: this.iface,
                        interfaces: this.lanAndDmzList,
                    },
                );
            }
        },
    },
};
</script>

<style lang="scss" module>
.generalPortsItem {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 4px;

    @include themify($themes) {
        border: 1px solid themed('grayscale_extralight');
    }

    @include under-to(lg) {
        flex-direction: column;
        padding-bottom: 20px;

        &:first-child {
            margin-top: 15px;
        }
    }

    & > * {
        flex: 1;
    }

    .ifacename {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;

        @include under-to(lg) {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 20px;
            font-style: normal;
            font-weight: bold;
            text-align: center;
        }
    }

    .interface_description {
        position: relative;

        @include under-to(lg) {
            display: none;
            margin-top: 20px;
        }

        svg {
            width: 14px;
            height: 14px;
            color: black;
            opacity: 0;
            transition: all 0s ease 0.3s, opacity 0.3s;
        }

        label {
            position: absolute;
            top: 0;
            right: 0;
        }

        .description_input {
            width: calc(100% - 22px);
            height: auto;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: transparent;
            border: 0;
            border-color: transparent;
        }

        .description_svg {
            position: absolute;
            top: 0;
            right: 14px;
            transform: translateX(50%);
        }

        &:hover {
            svg {
                opacity: 1;
                transition: all 0s ease 0.3s, opacity 0.3s;
            }
        }
    }

    .speed {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: flex-start;
        text-decoration: underline;

        @include under-to(lg) {
            justify-content: center;
            margin-top: 5px;
            font-size: 14px;
            font-style: normal;
            color: #B3B3B3;
            text-align: center;
        }

        svg {
            width: 16px;
            height: 16px;
            margin-top: 2px;

            @include themify($themes) {
                fill: themed('primary_color');
            }
        }

        .halfDuplex {
            svg {
                @include themify($themes) {
                    fill: themed('warning_color');
                }
            }
        }
    }

    .icon {
        margin-left: 4px;

        @include themify($themes) {
            fill: themed('primary_color');
        }
    }

    .containerSwitch {
        display: flex;
        justify-content: flex-end;

        @include under-to(lg) {
            justify-content: center;
        }

        .container_button {
            .port_button {
                display: flex;
                justify-content: center;

                @include under-to(lg) {
                    margin-top: 10px;
                    font-size: 20px;
                }
            }
        }
    }

    .lan_list {
        display: flex;
        flex-direction: row;

        @include under-to(lg) {
            justify-content: center;
        }

        &.wan {
            @include themify($themes) {
                color: themed('main_color');
            }
        }

        &.lan_list_infos {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        svg {
            width: 16px;
            height: 16px;
            color: black;
        }

        @include under-to(lg) {
            margin-top: 10px;
            font-size: 14px;
            font-style: normal;
            text-align: center;
        }
    }

    .speed:after {
        @include under-to(lg) {
            display: inline-flex;
            align-self: center;
            width: 8px;
            height: 8px;
            margin-left: 10px;
            vertical-align: middle;
            content: '';
            border-radius: 50%;

            @include themify($themes) {
                border: 1px solid themed('success_color');
            }
        }
    }

    .speed:hover {
        cursor: pointer;

        @include themify($themes) {
            color: themed('primary_color');
        }
    }

    .disabled_iface .speed {
        color: #B3B3B3;
        text-decoration: none;

        @include under-to(lg) {
            margin-top: 5px;
        }

        &:hover {
            color: #B3B3B3;
        }
    }

    .disabled_iface > * {
        &:hover {
            text-decoration: none;
            cursor: default;
            box-shadow: none;
            transition: none;
        }
    }
}
</style>
