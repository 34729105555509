<template>
    <div :class="$style.provisionning">
        <div class="container">
            <div
                class="row"
                :class="$style.provisionningrow"
            >
                <div class="col left">
                    <div
                        v-translate
                        :class="$style.title"
                    >
                        No equipment is attached to this link yet
                    </div>
                    <div
                        :class="$style.actions"
                    >
                        <div
                            :class="$style.complex_button"
                            @click="showAttachModal"
                        >
                            <div
                                v-translate
                                :class="$style.title"
                            >
                                Provision a new equipment
                            </div>
                            <div
                                v-translate
                                :class="$style.description"
                            >
                                Use equipment available in your stock
                            </div>
                            <MasterSvg
                                use="arrow-right"
                            />
                        </div>
                        <div
                            :class="[$style.complex_button, $style.secondary]"
                            @click="showInfoModal"
                        >
                            <div
                                v-translate
                                :class="$style.title"
                            >
                                My equipment is already installed
                            </div>
                            <div
                                v-translate
                                :class="$style.description"
                            >
                                Declare an equipment functional and already installed on site
                            </div>
                            <MasterSvg
                                use="arrow-right"
                            />
                        </div>
                    </div>
                </div>
                <div class="col right">
                    <MasterSvg
                        use="provisionning"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';

const AttachModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-attach" */
    './modals/attach.vue'
);
const InfoModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-provisioninginfo" */
    './modals/provisioning-info.vue'
);

export default {
    components: {
        MasterSvg,
    },
    props: {
        iplink: {
            type: Object,
            default: null,
        },
    },
    methods: {
        showAttachModal() {
            const accessesNumber = (
                this.iplink.accesses || []
            ).filter(access => !access.is_terminated).length;
            const capabilities = [
                `can_manage_${this.iplink.ipstack}`,
            ];
            if (accessesNumber > 1) {
                capabilities.push(...['can_multiwan', 'backup']);
            }

            this.show_modal(
                AttachModal,
                {
                    iplink: this.iplink,
                    action: 'attach',
                    capabilities,
                },
                {
                    maxWidth: Infinity,
                    maxHeight: Infinity,
                },
            );
        },
        showInfoModal() {
            this.show_modal(InfoModal);
        },
    },
};
</script>

<style lang="scss" module>
.provisionning {
    .title {
        max-width: 420px;
        font-size: 26px;
        font-weight: 500;

        @include under-to(md) {
            max-width: 100%;
            font-size: 22px;
            text-align: center;
        }
    }

    :global(.left),
    :global(.right) {
        display: flex;
        flex-direction: column;
        justify-content: left;
        padding: 20px;

        @include under-to(md) {
            flex-direction: row;
            text-align: center;
        }
    }

    .tip {
        margin-top: 25px;

        @include under-to(md) {
            text-align: center;
        }
    }

    :global(.left) {
        margin-top: 40px;
        text-align: left;

        @include under-to(md) {
            flex-direction: column;
        }

        .actions {
            margin-top: 30px;

            @include under-to(md) {
                align-self: center;
            }

            & > * {
                margin-top: 20px;
            }

            .complex_button {
                position: relative;
                max-width: 440px;
                padding: 20px 20px 19px 20px;
                text-align: left;
                cursor: pointer;
                border-radius: 4px;

                @include themify($themes) {
                    border: 1px solid themed('primary_color');
                }

                &:hover {
                    @include themify($themes) {
                        background-color: rgba(themed('tertiary_color'), 0.4);
                    }

                    svg {
                        right: 21px;
                    }
                }

                &.secondary {
                    @include themify($themes) {
                        border: 1px solid themed('grayscale_light');
                    }

                    &:hover {
                        @include themify($themes) {
                            border: 1px solid themed('primary_color');
                        }
                    }
                }

                .title {
                    font-size: 14px;
                    font-weight: 500;

                    @include themify($themes) {
                        color: themed('primary_color');
                    }
                }

                .description {
                    margin-top: 7px;
                    font-size: 12px;
                    font-weight: 300;

                    @include themify($themes) {
                        color: themed('grayscale_medium');
                    }
                }

                svg {
                    position: absolute;
                    top: 21px;
                    right: 28px;
                    width: 13.7px;
                    height: 11.3px;
                    transition: 0.3s ease right;

                    @include themify($themes) {
                        fill: themed('primary_color');
                    }
                }
            }
        }
    }

    :global(.right) {
        @include respond-to(md) {
            margin-top: 40px;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    .provisionningrow {
        height: 643px;

        @include under-to(md) {
            flex-direction: column-reverse;
            height: 100%;
        }
    }
}
</style>
