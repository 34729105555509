import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import equipment from './modules/module-equipment';
import iplinks from './modules/module-iplinks';
import gps from './modules/module-gps';
import iplinksells from './modules/module-iplinksells';
import telephonystats from './modules/module-telephony-stats';
import customerimport from './modules/module-customerimport';
import cdrmonitoring from './modules/module-cdrmonitoring';
import vgtimport from './modules/module-vgtimport';
import nraimport from './modules/module-nraimport';
import gridrateimport from './modules/module-gridrateimport';
import shortcuts from './modules/module-shortcuts.js';
import modal from './modules/module-modal.js';
import order from './modules/module-order.js';
import address from './modules/module-address.js';
import eligibility from './modules/module-eligibility.js';
import technicalannex from './modules/module-technicalannex.js';
import ipmanager from './modules/module-ipmanager.js';

Vue.use(Vuex);

const modules = {
    equipment,
    iplinks,
    gps,
    iplinksells,
    telephonystats,
    customerimport,
    vgtimport,
    nraimport,
    gridrateimport,
    cdrmonitoring,
    shortcuts,
    modal,
    order,
    address,
    eligibility,
    technicalannex,
    ipmanager,
};

const state = {
    permissions: [],
    axios_api: null,
};

const getters = {
    permissions: state => state.permissions,
    axios_api: state => state.axios_api,
};

const mutations = {
    update_permissions(state, permissions) {
        state.permissions = permissions;
    },
    update_axios_api(state, axiosApi) {
        state.axios_api = axiosApi;
    },
};

const actions = {
    init_axios({ commit }) {
        commit(
            'update_axios_api',
            axios.create(),
        );
    },

    fetch_permissions({ commit, state }) {
        return new Promise((resolve, reject) => {
            const url = '/api/core/permissions/';

            state.axios_api.get(url)
                .then((response) => {
                    commit('update_permissions', response.data);
                    resolve(response.data);
                })
                .catch((response) => {
                    commit('update_permissions', []);
                    reject(response.data);
                });
        });
    },
};

export default new Vuex.Store({
    modules,
    state,
    getters,
    mutations,
    actions,
});
