var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show_component
    ? _c("div", { staticClass: "row", class: _vm.$style.updated_section }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            { class: _vm.$style.text_updated_sections },
            [
              _c(
                "h4",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [
                  _vm._v(
                    "\n                Some changes in this section are not yet deployed on the equipment.\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c("Button", {
                class: _vm.$style.b1,
                attrs: {
                  role: "link",
                  knowmore: true,
                  disabled: _vm.disabled,
                  text: _vm.$gettext("Restore previous conf")
                },
                on: { click: _vm.restore_configuration }
              })
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }