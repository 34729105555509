var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: [
        _vm.$style.interfacesRouterPort,
        ((_obj = {}),
        (_obj[_vm.$style.used] = _vm.used),
        (_obj[_vm.$style.selected] = _vm.selected),
        _obj),
        _vm.ledState
      ]
    },
    [
      _c(
        "span",
        { on: { click: _vm.handleCick } },
        [_c("MasterSvg", { attrs: { use: "port" } })],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "text-center", class: _vm.$style.number }, [
        _vm._v("\n        " + _vm._s(_vm.displayPortNumber) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }