<template>
    <div :class="$style.eventsList">
        <template v-if="loading">
            <EventsListLoader
                v-for="index in 2"
                :key="index"
            />
        </template>
        <div
            v-else-if="events.length"
            :class="$style.results"
        >
            <EventsListItem
                v-for="event in events"
                :key="event.created_at"
                v-bind="{ ...event }"
                :created-by="event.created_by"
                :created-at="event.created_at"
                :updated-at="event.updated_at"
                :error-code="event.error_code"
                :error-message="event.error_message"
            />
        </div>
        <ComponentEmpty
            v-else
            size="large"
        >
            <template v-slot:title>
                <div v-translate>
                    No event has been realized on this equipment
                </div>
            </template>
        </ComponentEmpty>
        <Paginate
            v-if="needPaginate"
            v-model="currentPage"
            :items-count="eventsCount"
            :items-per-page="eventsPerPage"
            @pagination-update="handlePaginationUpdate"
        />
    </div>
</template>

<script>
import Paginate from '@/components/common/paginate.vue';

import ComponentEmpty from '../component-empty-search.vue';

import EventsListItem from './events-list-item.vue';
import EventsListLoader from './events-list-loader.vue';

export default {
    components: {
        ComponentEmpty,
        EventsListItem,
        EventsListLoader,
        Paginate,
    },
    props: {
        events: {
            type: Array,
            required: true,
        },
        eventsCount: {
            type: Number,
            required: true,
        },
        eventsPerPage: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            currentPage: 0,
        };
    },
    computed: {
        needPaginate() {
            return this.eventsCount > this.eventsPerPage;
        },
    },
    methods: {
        handlePaginationUpdate(page) {
            this.$emit('filters-update', page);
        },
    },
};
</script>

<style lang="scss" module>
.eventsList {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .results {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
        min-height: 195px;
    }
}
</style>
