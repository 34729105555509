var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.generalInterfacesListItem,
        ((_obj = {}),
        (_obj[_vm.$style.selected] = _vm.interfaces.includes(_vm.selectedPort)),
        (_obj[_vm.$style.addIndent] = _vm.indent != 0),
        _obj)
      ],
      style: "--indentValue: " + _vm.indent + "0px"
    },
    [
      _c(
        "div",
        { class: _vm.$style.informationBlock },
        [
          _c("div", { class: _vm.$style.name }, [
            _vm._v("\n            " + _vm._s(_vm.name) + "\n        ")
          ]),
          _vm._v(" "),
          _c("OneOrMore", {
            attrs: { values: _vm.formattedInterfaces },
            scopedSlots: _vm._u([
              {
                key: "multiple-value",
                fn: function(ref) {
                  var count = ref.count
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "translate",
                            rawName: "v-translate",
                            value: { count: count },
                            expression: "{count}"
                          }
                        ]
                      },
                      [_vm._v("Switch %{count}")]
                    )
                  ]
                }
              },
              {
                key: "empty-value",
                fn: function() {
                  return [_c("translate", [_vm._v("No port attached")])]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.addressingList, function(addressing) {
        return _c(
          "div",
          {
            key: _vm.name + "_" + addressing.ipVersion,
            class: _vm.$style.informationBlock
          },
          [
            _c(
              "div",
              { class: _vm.$style.subnet },
              [
                _c("span", [_vm._v(_vm._s(addressing.ipAddress))]),
                _vm._v(" "),
                addressing.secondaryIpAddress
                  ? _c(
                      "Tooltip",
                      {
                        attrs: {
                          content: _vm.getSecondaryIpAddress(addressing)
                        }
                      },
                      [_c("MasterSvg", { attrs: { use: "info" } })],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$style.tagsList },
              [
                addressing.dhcp && addressing.ipVersion === 6
                  ? _c(
                      "TagStatus",
                      { attrs: { active: _vm.getSlaacStatus(addressing) } },
                      [_vm._v("\n                SLAAC\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                addressing.dhcp
                  ? _c(
                      "TagStatus",
                      { attrs: { active: _vm.getDhcpStatus(addressing) } },
                      [_vm._v("\n                DHCP\n            ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { class: _vm.$style.informationBlock }, [
        _vm.vlanId
          ? _c("span", [_vm._v("VLAN " + _vm._s(_vm.vlanId))])
          : _c(
              "span",
              { directives: [{ name: "translate", rawName: "v-translate" }] },
              [_vm._v("\n            Unavailable information\n        ")]
            )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }