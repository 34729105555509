var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.equipmentheader }, [
    _c(
      "div",
      { class: _vm.$style.equipmentinfo },
      [
        _c("div", { class: _vm.$style.picto }, [
          _vm.isVrrp
            ? _c("img", { attrs: { src: require("./assets/vrrp.svg?inline") } })
            : _c("img", {
                attrs: { src: require("./assets/router.svg?inline") }
              })
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.nameandstatus }, [
          !_vm.isVrrp
            ? _c("div", { class: _vm.$style.body6 }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.linkequipment._equipment._model._vendor.name) +
                    "\n                " +
                    _vm._s(_vm.linkequipment._equipment._model.name) +
                    "\n            "
                )
              ])
            : _c("div", { class: _vm.$style.body6 }, [
                _vm._v("\n                VRRP\n            ")
              ]),
          _vm._v(" "),
          !_vm.linkequipment.discharged
            ? _c("div", { staticClass: "d-flex", class: _vm.$style.body1 }, [
                _vm.linkequipment.is_plugged
                  ? _c(
                      "div",
                      {
                        class: [
                          _vm.$style.refresh_status,
                          ((_obj = {}),
                          (_obj[_vm.$style.active] = _vm.statusLoading),
                          _obj)
                        ],
                        on: {
                          click: function($event) {
                            return _vm.fetchEquipmentStatus()
                          }
                        }
                      },
                      [
                        _c("MasterSvg", {
                          attrs: {
                            use: "refresh",
                            width: "15px",
                            height: "15px"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.statusLocale))]),
                _vm._v(" "),
                _c("div", {
                  class: [_vm.$style.statusbullet, _vm.status_class]
                })
              ])
            : _c("div", { class: _vm.$style.body1 }, [
                _c("span", [_vm._v(_vm._s(_vm.discharge_text))]),
                _vm._v(" "),
                _c("div", { class: _vm.$style.statusbullet })
              ])
        ]),
        _vm._v(" "),
        _c(
          "DropdownMenu",
          {
            style: { "margin-right": "20px", "margin-left": "50px" },
            attrs: { color: "white", size: "large" }
          },
          [
            !_vm.isVrrp && _vm.can_manage_router
              ? _c("DropdownMenuItem", {
                  attrs: { icon: "key", name: _vm.$gettext("Credentials") },
                  on: {
                    click: function($event) {
                      return _vm.showCredentialsModal()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.linkequipment.conf_is_private &&
            (_vm.linkequipment.is_plugged || _vm.linkequipment.discharged) &&
            !_vm.isVrrp
              ? _c("DropdownMenuItem", {
                  attrs: {
                    icon: "download",
                    name: _vm.$gettext("Download configuration")
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadConf()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("DropdownMenuItem", {
              attrs: {
                icon: "upload",
                name: _vm.$gettext("Save configuration as template")
              },
              on: {
                click: function($event) {
                  return _vm.saveConfiguration()
                }
              }
            }),
            _vm._v(" "),
            _c("DropdownMenuItem", {
              attrs: {
                icon: "repeat",
                name: _vm.$gettext("Restore a configuration")
              },
              on: {
                click: function($event) {
                  return _vm.restoreConfiguration()
                }
              }
            }),
            _vm._v(" "),
            !_vm.linkequipment.discharged &&
            _vm.linkequipment.is_plugged &&
            _vm.has_capability("can_reboot_equipment")
              ? _c("DropdownMenuItem", {
                  attrs: {
                    icon: "restart",
                    name: _vm.$gettext("Restart equipment")
                  },
                  on: {
                    click: function($event) {
                      return _vm.rebootEquipment()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.linkequipment.discharged && _vm.linkequipment.is_plugged
              ? _c("DropdownMenuItem", {
                  attrs: {
                    icon: "refresh",
                    name: _vm.$gettext("Update equipment status")
                  },
                  on: {
                    click: function($event) {
                      return _vm.fetchEquipmentStatus()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isVrrp
              ? _c("DropdownMenuItem", {
                  attrs: {
                    icon: "switch-equipment",
                    name: _vm.$gettext("Change equipment")
                  },
                  on: {
                    click: function($event) {
                      return _vm.showAttachModal()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.linkequipment.discharged && _vm.can_secure
              ? _c("DropdownMenuItem", {
                  attrs: {
                    icon: "lock",
                    color: "yellow",
                    name: _vm.$gettext("Secure the installation")
                  },
                  on: {
                    click: function($event) {
                      return _vm.showSecurityModal()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isVrrp
              ? _c("DropdownMenuItem", {
                  attrs: {
                    icon: "unlink",
                    color: "red",
                    name: _vm.$gettext("Remove VRRP")
                  },
                  on: {
                    click: function($event) {
                      return _vm.detachVrrp()
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isVrrp
              ? _c("DropdownMenuItem", {
                  attrs: {
                    icon: "shape",
                    color: "red",
                    name: _vm.$gettext("Detach equipment from this link")
                  },
                  on: {
                    click: function($event) {
                      return _vm.detachEquipment()
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.deployinfo },
      [
        _vm.scheduledAction
          ? _c("div", { class: _vm.$style.text }, [
              _c("div", { class: _vm.$style.body1 }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.scheduledActionTitle) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.$style.deploydate }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.scheduledActionDate ||
                        _vm.$gettext("As soon as possible")
                    )
                  )
                ])
              ])
            ])
          : _vm.linkequipment.is_plugged
          ? _c("div", { class: _vm.$style.text }, [
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  class: _vm.$style.body1
                },
                [_vm._v("\n                Last deploy\n            ")]
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.deploydate }, [
                _c("h2", [_vm._v(_vm._s(_vm.lastSynchronizationDate))])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("DeployButton", {
          attrs: {
            "action-is-scheduled": !!_vm.scheduledAction,
            "missing-wan-configuration": _vm.missingWanConfiguration
          },
          on: {
            "cancel-scheduled-action": _vm.handleCancelScheduledAction,
            "deploy-configuration": _vm.handleDeployConfiguration,
            "show-deploy-configuration-modal":
              _vm.handleShowDeployConfigurationModal,
            "show-incomplete-wan-configuration-modal":
              _vm.handleShowIncompleteWanConfigurationModal
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }