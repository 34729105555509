var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Field", {
    attrs: {
      errors: _vm.formattedErrors,
      required: _vm.required,
      readonly: _vm.readonly,
      label: _vm.label,
      disabled: _vm.disabled,
      name: "name",
      placeholder: _vm.placeholder,
      "info-tooltips": _vm.infoTooltips
    },
    model: {
      value: _vm.internalValue,
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }