<script>
export default {
    functional: true,
    render: (createElement, context) => {
        const data = {
            props: {
                name: 'fade',
                mode: 'out-in',
            },
        };
        return createElement('transition', data, context.children);
    },
};
</script>

<style lang="scss">
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active {
        transition: all 0.2s ease-out;
    }

    .fade-leave-active {
        transition: all 0.2s ease-in;
    }
</style>
