var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.iplink },
    [
      _vm.showEquipment
        ? _c("Equipment", {
            attrs: {
              iplink: _vm.iplink,
              "can-activate-admin-account": _vm.canActivateAdminAccount
            }
          })
        : _c("ManagedSection", {
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c("translate", [
                      _vm._v(
                        "\n                Router administration is disabled for this link.\n            "
                      )
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "body",
                fn: function() {
                  return [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.bodyManagedSection) +
                        "\n        "
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }