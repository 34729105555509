<template>
    <!-- eslint-disable max-len -->
    <GeneralAdminBox
        v-if="!linkequipment.discharged && !canManageRouter"
        :button-text="$gettext('I activate my administrator account')"
        @click="showEnableAdminAccountModal"
    >
        <p v-translate>
            You can activate an administrator account to apply specific configurations to your equipment via SSH or Telnet.
        </p>
        <p v-translate>
            In case of customer service intervention, the administrator account must be disabled. This operation will reinitialize the router in its latest known configuration and any manual interventions on the equipment (not through Atlas) will be erased.
        </p>
    </GeneralAdminBox>
    <GeneralAdminBox
        v-else-if="canManageRouter"
        :button-text="buttonAdminCredentialsText"
        @click="showAdminCredentials"
    >
        <template v-if="linkequipment.discharged">
            <p v-translate>
                The administrator account is activated you can get administrator credential.
            </p>
            <p v-translate>
                To use the equipments management tool again you need to deactivate the administrator account.
            </p>
            <p v-translate>
                The specific modifications made to the equipment will be reset by the last known configuration of unyc.
            </p>
        </template>
        <template v-else>
            <p v-translate>
                You can activate an administrator account to apply specific configurations to the equipment via SSH or Telnet.
            </p>
            <p v-translate>
                Once activated, the equipment management tool will no longer be used.
            </p>
            <p v-translate>
                It will be necessary to deactivate the administrator account, the equipment will be reset with the last known configuration of unyc.
            </p>
        </template>
    </GeneralAdminBox>
    <GeneralAdminBox
        v-else-if="linkequipment.discharged && !canManageRouter"
        :button-text="$gettext('Show administrator credentials')"
        :button-disable="!canDeactivateAdminAccount"
        @click="showDisableAdminAccountModal"
    >
        <template v-if="linkequipment.discharged">
            <p
                v-if="dischargeByOperator"
                v-translate
            >
                unyc activated your administrator account. You must contact support to make changes on the equipment or to recover this tool.
            </p>
            <p
                v-else
                v-translate
            >
                You activated your administrator account.
            </p>
            <p v-translate>
                In case of customer service intervention, the administrator account must be disabled. This operation will reinitialize the router in its latest known configuration and any manual interventions on the equipment (not through Atlas) will be erased.
            </p>
        </template>
    </GeneralAdminBox>
</template>

<script>
import { mapGetters } from 'vuex';
import { showSwal } from '@/utils/utils';
import GeneralAdminBox from './general/admin/general-admin-box.vue';

const EnableAdminAccountModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-admin-account-enable-admin-account" */
    './modals/admin-account/enable-admin-account.vue'
);
const DisableAdminAccountModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-admin-account-disable-admin-account" */
    './modals/admin-account/disable-admin-account.vue'
);
const OperatorAdminCredentialsModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-admincredentials" */
    './modals/admin-account/operator-admin-credentials.vue'
);
const ArchivedCredentialsModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-archived-credentials" */
    './modals/archived-credentials.vue'
);

export default {
    components: {
        GeneralAdminBox,
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'linkequipment',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        buttonAdminCredentialsText() {
            if (!this.linkequipment.discharged) {
                return this.$gettext('I activate administrator account');
            }
            return this.$gettext('Show administrator credentials');
        },
        canDeactivateAdminAccount() {
            if (!this.dischargeByOperator) {
                return true;
            }
            return this.canManageRouter;
        },
        canManageRouter() {
            return this.permissions.find(
                element => element.codename === 'can_manage_router' && element.app_label === 'equipments',
            ) !== undefined;
        },
        dischargeByOperator() {
            return this.linkequipment.discharge_type === 'managed_by_operator';
        },
    },
    methods: {
        showAdminCredentials() {
            this.show_modal(OperatorAdminCredentialsModal).then((data) => {
                if (data === 'archivedCredentials') {
                    this.show_modal(
                        ArchivedCredentialsModal,
                        {
                            access: this.linkequipment._access,
                        },
                        {
                            height: 'auto',
                            maxWidth: 820,
                        },
                    );
                }
            });
        },
        showEnableAdminAccountModal() {
            this.showAdminAccountModal(EnableAdminAccountModal);
        },
        showDisableAdminAccountModal() {
            this.showAdminAccountModal(DisableAdminAccountModal);
        },
        showAdminAccountModal(modal) {
            if (this.linkequipment.is_plugged && this.linkequipment.status !== 'up') {
                showSwal({
                    title: this.$gettext('Operation not permited'),
                    text: this.$gettext('The equipment is not in a stable condition. You cannot perform this action.'),
                    type: 'error',
                    confirmButtonText: this.$gettext('Ok'),
                    showCloseButton: true,
                });
            } else {
                this.show_modal(modal);
            }
        },
    },
};
</script>

<style lang="scss" module>
.admin_container {
    display: flex;
    padding: 20px 30px;
    border-radius: 4px;

    @include themify($themes) {
        border: solid 1px themed('primary_color');
    }

    .admin_box {
        display: flex;
        align-items: center;

        @include under-to(lg) {
            flex-direction: column;
            margin: 0;

            :global(.col) {
                padding-right: 0;
                padding-left: 0;
            }
        }

        p {
            margin-bottom: 10px;
        }
    }
}
</style>
