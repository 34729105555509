var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.lansection }, [
    _c(
      "div",
      [
        _c("Advice", {
          attrs: {
            title: _vm.$gettext("LANs configuration"),
            "link-url":
              "https://kiosque.unyc.io/document/administration-des-routeurs/lan-adr/"
          },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function() {
                return [
                  _c("translate", { attrs: { tag: "p" } }, [
                    _vm._v(
                      "\n                    A LAN (Local Area Network) is a network that allows you to communicate with one another without using internet access.\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("translate", { attrs: { tag: "p" } }, [
                    _vm._v(
                      "\n                    Our router management interface allows you to configure and interconnect different LANs (depending on model and ports available).\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("translate", { attrs: { tag: "p" } }, [
                    _vm._v(
                      "\n                    IPv4 and IPv6 options allow the automatic IP configuration of the equipment connected to the router.\n                "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.validIPVersions.includes(4)
                    ? _c(
                        "p",
                        [
                          _c("translate", [
                            _vm._v(
                              "\n                        In IPv4, the automatic configuration of IP addresses of a device within a LAN is done through DHCP (Dynamic Host Configuration Protocol).\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("translate", [
                            _vm._v(
                              "\n                        Assign a static IP to an equipment by specifying its MAC address.\n                    "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.validIPVersions.includes(6)
                    ? _c(
                        "p",
                        [
                          _c("translate", [
                            _vm._v(
                              "\n                        In IPv6, the automatic configuration of IP addresses of a device within a LAN is done through the SLAAC protocol (Stateless Automatic Auto Configuration).\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("translate", [
                            _vm._v(
                              "\n                        In some cases, DHCPv6 can be activated in addition to SLAAC in order to assign IPv6 addresses of a specific range to a device.\n                    "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "example",
              fn: function() {
                return [
                  _c("translate", [
                    _vm._v(
                      "\n                    Exemple: On port 1, you can configure a LAN to 192.168.X.X and on port 2 a LAN to 10.0.X.X\n                "
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        }),
        _vm._v(" "),
        _c("UpdatedSection", { attrs: { section: "lans" } }),
        _vm._v(" "),
        _vm.lans.length
          ? _c(
              "div",
              { staticClass: "row mt-3 mb-4" },
              [
                _c("Title", {
                  attrs: { title: _vm.$gettext("LANs configuration") }
                }),
                _vm._v(" "),
                _c("Button", {
                  ref: "top_add_button",
                  staticClass: "col-4 ml-auto d-none d-lg-flex",
                  class: [_vm.$style.rightbutton],
                  attrs: {
                    role: "primary",
                    text: _vm.$gettext("Add LAN"),
                    icon: "add",
                    size: "large",
                    disabled: _vm.disabledAddButton
                  },
                  on: {
                    click: function($event) {
                      return _vm.addLan()
                    }
                  }
                }),
                _vm._v(" "),
                _c("Button", {
                  staticClass: "d-lg-none",
                  class: [_vm.$style.bottombutton],
                  attrs: {
                    role: "icon_primary",
                    icon: "add",
                    disabled: _vm.disabledAddButton
                  },
                  on: {
                    click: function($event) {
                      return _vm.addLan()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.lans.length,
                expression: "lans.length"
              }
            ],
            staticClass: "row-12"
          },
          [
            _vm.linkequipmentconfoptions
              ? _c(
                  "ul",
                  { class: _vm.$style.lans },
                  _vm._l(_vm.lans, function(lan, index) {
                    return _c(
                      "li",
                      { key: index, ref: "lan-" + (index + 1), refInFor: true },
                      [
                        _c("LanItem", {
                          attrs: {
                            "index-lan": index,
                            lan: lan,
                            "equipments-configurations":
                              _vm.equipments_configurations,
                            "selected-interfaces": _vm.selected_interfaces,
                            "lan-errors": _vm.lans_errors[index],
                            "equipments-errors": _vm.equipments_errors,
                            "wan-choices": _vm.wanChoices
                          },
                          on: {
                            "update-main-exit-wan": _vm.handleUpdateMainExitWan,
                            remove: function($event) {
                              return _vm.removeLan(index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("ul", { class: _vm.$style.lans }, [
                  _c("li", [_c("LanItemSkeleton")], 1)
                ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.lans.length,
                expression: "!lans.length"
              }
            ]
          },
          [
            _c("LansEmpty", {
              attrs: {
                steps: _vm.emptysteps,
                subtitle: _vm.subtitle,
                image: "lan"
              }
            }),
            _vm._v(" "),
            _c("Button", {
              staticClass: "mt-4 col-4 d-none d-lg-flex",
              attrs: {
                role: "primary",
                text: _vm.$gettext("Add LAN"),
                icon: "add",
                size: "large"
              },
              on: {
                click: function($event) {
                  return _vm.addLan()
                }
              }
            }),
            _vm._v(" "),
            _c("Button", {
              staticClass: "d-lg-none",
              class: [_vm.$style.bottombutton],
              attrs: { role: "icon_primary", icon: "add" },
              on: {
                click: function($event) {
                  return _vm.addLan()
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { class: _vm.$style.validatebuttons }, [
          _c(
            "div",
            [
              _vm.show_bottom_button
                ? _c("Button", {
                    staticClass: "p-0 col-12 col-lg-6",
                    class: [_vm.$style.rightbutton, _vm.$style.validatebutton],
                    attrs: {
                      role: "primary",
                      text: _vm.$gettext("Add LAN"),
                      icon: "add",
                      size: "large",
                      disabled: _vm.disabledAddButton
                    },
                    on: {
                      click: function($event) {
                        return _vm.addLan()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.is_modified
                ? _c("Button", {
                    staticClass: "p-0 col-12 col-lg-5",
                    class: [_vm.$style.rightbutton, _vm.$style.validatebutton],
                    attrs: {
                      role: "secondary",
                      text: _vm.$gettext("Cancel"),
                      size: "large"
                    },
                    on: {
                      click: function($event) {
                        return _vm.initLinkEquipmentConfLan()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.is_modified
                ? _c("Button", {
                    staticClass: "p-0 offset-0 col-12 offset-lg-1 col-lg-6",
                    class: [_vm.$style.rightbutton, _vm.$style.validatebutton],
                    attrs: {
                      role: "primary",
                      text: _vm.$gettext("Validate configuration"),
                      size: "large"
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleFormSubmit()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }