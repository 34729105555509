var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { attrs: { id: "site" } },
        [
          _vm.connected
            ? [
                !_vm.$route.query.hide_header ? _c("Header") : _vm._e(),
                _vm._v(" "),
                _c("router-view")
              ]
            : [_c("Connect")]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }