var render = function() {
  var _obj, _obj$1, _obj$2, _obj$3
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.connectedDevicesSection },
    [
      _c("Advice", {
        attrs: { title: _vm.$gettext("Connected devices") },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      '\n                You will find here the MAC addresses and corresponding IPv4 or IPv6 addresses of the devices currently connected to the router. The list is fetched when loading this page or clicking on "refresh".\n            '
                    )
                  ]
                ),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "b",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    Important:\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        '\n                    the list below only shows connected devices to the local network at the time you connect to this page (or click on "refresh").\n                '
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "b",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    Did you know:\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    from this tool, you can create NAT rules (for an IPv4 device) or firewall rules (for an IPv6 device).\n                "
                      )
                    ]
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _vm.is_synchronized && _vm.linkequipment.is_plugged
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "row mt-3 mb-4" },
                [_c("Title", { attrs: { title: _vm.section_title } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.isVrrp
                    ? _c("Select", {
                        staticClass: "col-lg-3 align-self-center",
                        class: _vm.$style.equipements_select,
                        attrs: {
                          "data-list": _vm.equipments_list,
                          role: "small",
                          name: "equipments_list"
                        },
                        model: {
                          value: _vm.equipment_link_id,
                          callback: function($$v) {
                            _vm.equipment_link_id = $$v
                          },
                          expression: "equipment_link_id"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("Field", {
                    staticClass: "col-lg-4 align-self-center",
                    class: [
                      _vm.$style.searchField,
                      {
                        "mt-3 mt-lg-0": _vm.isVrrp,
                        "offset-lg-3": !_vm.isVrrp
                      }
                    ],
                    attrs: {
                      placeholder: _vm.$gettext(
                        "Search for an connected device"
                      ),
                      name: "search",
                      size: "small",
                      "icon-left": "search"
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-lg-row-reverse mt-2 col-lg-5" },
                    [
                      _c("Button", {
                        staticClass: "d-none p-2 d-lg-block",
                        class: [
                          _vm.$style.refresh_action,
                          ((_obj = {}),
                          (_obj[
                            _vm.$style.active
                          ] = !_vm.connectedDevicesTableLoading),
                          _obj)
                        ],
                        attrs: {
                          role: "inverted",
                          icon: "refresh",
                          text: _vm.refresh_text,
                          disabled: _vm.connectedDevicesTableLoading
                        },
                        on: {
                          click: function($event) {
                            return _vm.fetchConnectedDevicesTableFromEquipment()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("Button", {
                        staticClass: "d-lg-none",
                        class: [
                          _vm.$style.bottombutton,
                          _vm.$style.refresh_action,
                          ((_obj$1 = {}),
                          (_obj$1[
                            _vm.$style.active
                          ] = !_vm.connectedDevicesTableLoading),
                          _obj$1)
                        ],
                        attrs: {
                          role: "icon_primary",
                          icon: "refresh",
                          disabled: _vm.connectedDevicesTableLoading
                        },
                        on: {
                          click: function($event) {
                            return _vm.fetchConnectedDevicesTableFromEquipment()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "p-2 align-self-center",
                          class: [_vm.$style.refresh_text, _vm.$style.body1]
                        },
                        [
                          _c("translate", { staticClass: "d-lg-none" }, [
                            _vm._v(
                              "\n                        Last synchro:\n                    "
                            )
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.lastSynchronizationDate) +
                                "\n                    "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("ConnectedDevicesList", {
                attrs: { "equipment-link-id": _vm.equipment_link_id }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("Empty", {
                attrs: {
                  steps: _vm.emptysteps,
                  image: "connectedDevices",
                  subtitle: _vm.$gettext("Connected devices update")
                }
              }),
              _vm._v(" "),
              _c("Button", {
                staticClass: "mt-4 col-4 d-none d-lg-flex",
                class: [
                  _vm.$style.refresh_action,
                  ((_obj$2 = {}),
                  (_obj$2[
                    _vm.$style.active
                  ] = !_vm.connectedDevicesTableLoading),
                  _obj$2)
                ],
                attrs: {
                  role: "primary",
                  text: _vm.refresh_text,
                  icon: "refresh",
                  size: "large",
                  disabled: _vm.connectedDevicesTableLoading
                },
                on: {
                  click: function($event) {
                    return _vm.fetchConnectedDevicesTableFromEquipment()
                  }
                }
              }),
              _vm._v(" "),
              _c("Button", {
                staticClass: "d-lg-none",
                class: [
                  _vm.$style.bottombutton,
                  _vm.$style.refresh_action,
                  ((_obj$3 = {}),
                  (_obj$3[
                    _vm.$style.active
                  ] = !_vm.connectedDevicesTableLoading),
                  _obj$3)
                ],
                attrs: {
                  role: "icon_primary",
                  icon: "refresh",
                  disabled: _vm.connectedDevicesTableLoading
                },
                on: {
                  click: function($event) {
                    return _vm.fetchConnectedDevicesTableFromEquipment()
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }