<template>
    <div :class="$style.general_equipment_specifications">
        <ComponentSpecifications :image-src="routerPicture">
            <template #title>
                <span>
                    {{ linkequipment._equipment._model._vendor.name }}
                    {{ linkequipment._equipment._model.name }}
                </span>
                <ProviderBadge
                    v-if="linkequipment._equipment.serial_number"
                    :equipment="linkequipment._equipment"
                />
            </template>
            <template #specifications>
                <ComponentSpecificationsItem
                    v-for="item in specifications.slice(0, 2)"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                />
                <!-- specific case with html instead of text -->
                <ComponentSpecificationsItem
                    v-if="firmwareVersion && firmwareVersion.current_version"
                >
                    <template #label>
                        <translate>Firmware version</translate>
                    </template>

                    <template #value>
                        <div
                            :class="$style.version"
                            @click="showFirmwareModal"
                        >
                            <span>
                                {{ firmwareVersion.current_version }}
                            </span>
                            <span
                                v-if="linkequipmentconfoptions"
                                :class="firmwareIcon.class"
                            >
                                <MasterSvg
                                    :use="firmwareIcon.name"
                                    width="12px"
                                    height="12px"
                                />
                            </span>
                        </div>
                    </template>
                </ComponentSpecificationsItem>

                <ComponentSpecificationsItem
                    v-for="item in specifications.slice(2, specifications.length)"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                />
            </template>
        </ComponentSpecifications>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MasterSvg from '@/components/common/master-svg.vue';

import ComponentSpecifications from './component-specifications.vue';
import ComponentSpecificationsItem from './component-specifications-item.vue';
import ProviderBadge from './provider-badge.vue';

const FirmwareModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-firmware" */
    './modals/firmware.vue'
);

export default {
    components: {
        ComponentSpecifications,
        ComponentSpecificationsItem,
        ProviderBadge,
        MasterSvg,
    },
    data: () => ({
        routerPicture: null,
    }),
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'access',
            'linkequipment',
            'linkequipmentconfoptions',
            'virtual_iplink_members',
            'firmwareVersionByLinkEquipment',
        ]),
        firmwareVersion() {
            if (!this.linkequipment._equipment) {
                return null;
            }

            const firmwareVersion = (this.firmwareVersionByLinkEquipment || []).find(
                version => version.link_id === this.linkequipment.access,
            );
            return firmwareVersion;
        },
        firmwareIcon() {
            const { firmwareVersion } = this;
            if (firmwareVersion && firmwareVersion.is_allowed) {
                return { name: 'ic-valid', class: this.$style.valid };
            }
            return { name: 'ic-warning-flat', class: this.$style.warning };
        },
        specifications() {
            const specs = [
                {
                    label: this.$gettext('Vendor'),
                    value: this.linkequipment._equipment._model._vendor.name,
                    display_condition: this.linkequipment._equipment,
                }, {
                    label: this.$gettext('Serial Number'),
                    value: this.linkequipment._equipment.serial_number || this.$gettext('Not yet connected on site'),
                    display_condition: this.linkequipment._equipment,
                }, {
                    label: this.$gettext('Link type'),
                    value: this.linkequipment._access.link_type_display,
                    display_condition: !this.virtual_iplink_members.length,
                }, {
                    label: this.iplink.mpls ? this.$gettext('Private IPv4') : this.$gettext('Public IPv4'),
                    value: this.linkequipment._access.connectivity_ipv4_address,
                    display_condition:
                    ['dualstack', 'ipv4'].some(type => type === this.linkequipment._access.ipstack && !this.virtual_iplink_members.length),
                }, {
                    label: this.$gettext('LAN IPv6'),
                    value: this.linkequipment._access.ipv6_lan_subnet,
                    display_condition:
                    ['dualstack', 'ipv6'].some(type => type === this.linkequipment._access.ipstack && !this.virtual_iplink_members.length),
                }, {
                    label: this.$gettext('Public IPv6'),
                    value: this.linkequipment._access.router_ipv6_address,
                    display_condition:
                    ['dualstack', 'ipv6'].some(type => type === this.linkequipment._access.ipstack && !this.virtual_iplink_members.length),
                },
            ];
            return specs.filter(item => item.display_condition);
        },
    },
    watch: {
        linkequipment: {
            handler() {
                if (
                    this.linkequipment
                    && this.linkequipment._equipment._model.image
                    && !this.routerPicture
                ) {
                    this.routerPicture = this.linkequipment._equipment._model.image;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        showFirmwareModal() {
            const { firmwareVersion } = this;
            if (!firmwareVersion) {
                return;
            }

            const maxHeight = firmwareVersion.is_allowed ? 350 : 400;

            this.show_modal(
                FirmwareModal,
                {
                    'link-id': this.linkequipment.access,
                    'current-firmware': {
                        ...firmwareVersion,
                        recommended_firmware: undefined,
                    },
                    'recommended-firmware': firmwareVersion.recommended_firmware,
                },
                {
                    maxWidth: 610,
                    maxHeight,
                },
            );
        },
    },
};
</script>

<style lang="scss" module>
.general_equipment_specifications {
    .version {
        display: flex;
        align-items: center;
        cursor: pointer;

        @include themify($themes) {
            color: themed('primary_color');
        }

        &:hover {
            text-decoration: underline;
        }

        svg {
            margin: auto 0 auto 7px;
        }

        .warning {
            @include themify($themes) {
                fill: themed('warning_color');
            }
        }

        .valid {
            @include themify($themes) {
                fill: themed('success_color');
            }
        }
    }
}
</style>
