var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.snmpCommunitiesList },
    _vm._l(_vm.communities, function(community, index) {
      return _c("SnmpCommunitiesListItem", {
        key: "community-" + (index + 1),
        attrs: {
          community: community,
          errors: _vm.formErrors[index],
          "readonly-mode": _vm.readonlyMode
        },
        on: {
          remove: function($event) {
            return _vm.$emit("remove", index)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }