<template>
    <div
        v-if="dmz"
        :class="$style.dmzsection"
    >
        <div :class="$style.dmzitem_wrapper">
            <div :class="$style.header">
                <span :class="$style.body6">
                    DMZ {{ indexDmz + 1 }}
                </span>
                <span
                    class="ml-2 mr-auto ml-lg-3"
                    :class="$style.placeholder1"
                >
                    {{ dmz.name }}
                </span>
                <div
                    :class="[$style.hidearrow, {[$style.bottom]: collapsed}]"
                    @click="toggleCollapse()"
                >
                    <MasterSvg
                        use="arrow"
                        width="20px"
                    />
                </div>
            </div>

            <ul
                v-show="!collapsed"
                :class="$style.categories"
            >
                <li
                    class="nav-item"
                    :class="[
                        $style.category,
                        {[$style.active]: active_category === 'network'},
                        {[$style.error]: not_empty_object(network_errors)}
                    ]"
                    section="network"
                    @click="change_category('network')"
                >
                    <div :class="$style.category_title">
                        <translate>Network</translate>
                        <div
                            v-if="not_empty_object(network_errors)"
                            :class="$style.warning"
                        >
                            <MasterSvg use="warningicon" />
                        </div>
                    </div>
                </li>
                <li
                    v-for="(equipment_configurations, index) in equipmentsConfigurations || []"
                    :key="index"
                    class="nav-item"
                    :class="[
                        $style.category,
                        {
                            [$style.active]: (
                                active_category === 'port' ,
                                equipment_configurations.link_id === equipment_link_id)
                        },
                        {[$style.error]: not_empty_object(
                            dmz_equipment_errors[index])
                        }
                    ]"
                    :section="`port${index}`"
                    @click="change_category(
                        'port',
                        equipment_configurations.link_id,
                        index)
                    "
                >
                    <div :class="$style.category_title">
                        <span>{{ get_port_section_label(index) }}</span>
                        <div
                            v-if="not_empty_object(
                                dmz_equipment_errors[index])"
                            :class="$style.warning"
                        >
                            <MasterSvg use="warningicon" />
                        </div>
                    </div>
                </li>
            </ul>

            <div
                v-show="!collapsed"
                :class="$style.dmzform"
            >
                <component
                    :is="currentTabComponent"
                    v-if="render_component"
                    class="tab"
                    :dmz="dmz"
                    :errors="get_current_errors()"
                    :index-dmz="indexDmz"
                    :dmz-equipment-configurations="dmz_equipment_configurations"
                    :selected-interfaces="selected_interfaces_equipment"
                    :equipment-link-id="equipment_link_id"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Field from '@/components/common/field.vue';
import Select from '@/components/common/select.vue';
import DmzItemInterfaces from './dmzitem-interfaces.vue';
import MasterSvg from './master-svg.vue';
import DmzSectionNetwork from './dmz-section-general.vue';
import DmzSectionPort from './dmz-section-port.vue';

export default {
    components: {
        DmzItemInterfaces,
        MasterSvg,
        Field,
        Select,
        DmzSectionNetwork,
        DmzSectionPort,
    },
    props: {
        dmz: {
            type: Object,
            default: null,
        },
        equipmentsConfigurations: {
            type: Array,
            default: null,
        },
        dmzErrors: {
            type: Object,
            default: null,
        },
        equipmentsErrors: {
            type: Array,
            default: null,
        },
        indexDmz: {
            type: Number,
            default: 0,
        },
        selectedInterfaces: {
            type: Array,
            default: (() => []),
        },
    },
    data() {
        return {
            collapsed: false,
            update: false,
            active_category: 'network',
            render_component: true,
            equipment_link_id: null,
            dmz_equipment_configurations: null,
            selected_interfaces_equipment: null,
            index_error: 0,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'linkequipment',
            'linkequipmentconfoptions',
            'virtual_iplink_members',
        ]),
        network_errors() {
            return this.dmzErrors || {};
        },
        dmz_equipment_errors() {
            const errors = [];

            this.equipmentsErrors.forEach((equipmentErrors) => {
                const dmzEquipmentErrors = (equipmentErrors.dmzs || [])[this.indexDmz] || {};
                errors.push(dmzEquipmentErrors);
            });

            return errors;
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        currentTabComponent() {
            return `DmzSection${this.active_category.charAt(0).toUpperCase()}${this.active_category.substring(1).toLowerCase().toLowerCase()}`;
        },
    },
    watch: {
        equipmentsConfigurations: {
            handler() {
                if (!this.equipmentsConfigurations) {
                    return;
                }

                if (this.equipment_link_id) {
                    const equipmentConfigurations = this.equipmentsConfigurations.find(
                        equipment => equipment.link_id === this.equipment_link_id,
                    ) || {};

                    this.dmz_equipment_configurations = equipmentConfigurations.dmzs.find(
                        equipment => (
                            this.dmz.uuid && equipment.dmz_uuid === this.dmz.uuid
                        ),
                    );

                    this.selected_interfaces_equipment = (this.selectedInterfaces.find(
                        equipment => equipment.link_id === this.equipment_link_id,
                    ) || { interfaces: [] }).interfaces;
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions('equipment', [
            'set_conf_section',
        ]),
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        change_category(category, equipmentLinkId = null, errorIndex = 0) {
            this.render_component = false;
            this.active_category = category;
            this.equipment_link_id = equipmentLinkId;
            this.error_index = errorIndex;

            if (this.equipment_link_id) {
                const equipmentConfigurations = this.equipmentsConfigurations.find(
                    equipment => equipment.link_id === this.equipment_link_id,
                ) || {};

                this.dmz_equipment_configurations = equipmentConfigurations.dmzs.find(
                    equipment => (
                        this.dmz.uuid && equipment.dmz_uuid === this.dmz.uuid
                    ),
                );

                this.selected_interfaces_equipment = (this.selectedInterfaces.find(
                    equipment => equipment.link_id === this.equipment_link_id,
                ) || { interfaces: [] }).interfaces;
            }

            this.$nextTick(() => {
                this.render_component = true;
            });
        },
        get_current_errors() {
            if (this.active_category === 'port') {
                return this.dmz_equipment_errors[this.error_index];
            }
            return this.network_errors;
        },
        not_empty_object(someObject) {
            if (!someObject) {
                return false;
            }
            return Object.keys(someObject).length;
        },
        get_port_section_label(index) {
            if (this.isVrrp) {
                return Vue.prototype.$gettextInterpolate(
                    Vue.prototype.$gettext('Equipment ports %{ n }'),
                    { n: index + 1 },
                );
            }
            return Vue.prototype.$gettext('Ports');
        },
    },
};
</script>

<style lang="scss" module>
.dmzsection {
    .categories {
        display: flex;
        flex-direction: row;
        padding: 10px 10px 0 10px;
        margin: 10px;

        @include under-to(lg) {
            margin: 10px 0;
            overflow: scroll;
            overflow-y: hidden;
        }

        @include themify($themes) {
            border-bottom: solid 1px rgba(themed('gray_scale_dark'), 0.1);
        }

        .category {
            display: flex;
            justify-content: center;
            min-width: 100px;
            padding: 10px 10px 0 10px;
            margin: 10px 10px 8px 10px;
            cursor: pointer;

            @include themify($themes) {
                color: themed('grayscale_semi');
            }

            @include under-to(lg) {
                min-width: 75px;
                margin: 10px 0;
            }

            &.active,
            &:hover {
                font-weight: 500;
                color: black;
            }

            &.active {
                & .category_title:after {
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                    width: calc(100% + 2px);
                    height: 2px;
                    content: '';
                    border-radius: 4px;

                    @include themify($themes) {
                        background-color: rgba(themed('primary_color'), 1);
                    }

                    transition: all 225ms ease-in-out;
                }
            }

            &.error {
                & .category_title:after {
                    @include themify($themes) {
                        background-color: rgba(themed('error_color'), 1);
                    }
                }
            }

            & .category_title {
                position: relative;
                display: flex;
            }
        }

        .warning {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 4px;

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }

    .dmzitem_wrapper {
        @include themify($themes) {
            box-shadow: 0 0 10px 0 rgba(themed('primary_color'), 0.1);
        }
    }

    .margin_field {
        margin-bottom: 32px;
    }

    .subtitle {
        display: flex;
        padding-top: 8px;

        @include under-to(md) {
            display: block;
        }
    }

    :global(h4) + :global(h4) {
        margin-left: 4px;
    }

    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 63px;
        padding: 0 24px;
        margin-bottom: 10px;

        @include themify($themes) {
            border-bottom: 1px solid rgba(themed('primary_color'), 0.1);
        }

        @include under-to(lg) {
            flex-direction: row;
            align-items: center;
            padding: 10px 20px;
        }

        .hidearrow {
            display: flex;
            width: 20px;
            height: 13px;
            margin-left: 42px;

            :global(svg) {
                width: 20px;
                height: 13px;
                cursor: pointer;
                transition: 0.3s all;
            }

            &.bottom {
                :global(svg) {
                    transform: rotate(180deg);
                }
            }
        }

        &:after {
            position: absolute;
            right: 62px;
            width: 1px;
            height: 100%;
            content: '';

            @include themify($themes) {
                background-color: rgba(themed('gray_scale_dark'), 0.1);
            }
        }
    }

    .enabledhcp {
        margin-left: 30px;
        font-size: 17px;
    }

    .dmzform {
        padding: 25px 40px;
        background-color: white;

        & > * + * {
            margin-top: 40px;
        }

        @include under-to(lg) {
            padding: 10px 20px;
        }
    }
}
</style>
