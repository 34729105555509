<template>
    <div
        :class="[
            $style.collapsableBlockLayout,
            {[$style[breakpoint]]: breakpoint},
        ]"
    >
        <div :class="$style.header">
            <h3><slot name="title" /></h3>
            <template v-if="showContent">
                <slot name="header" />
            </template>
            <div
                :class="[$style.arrow, {[$style.active]: showContent}]"
                @click="toggleShowContent()"
            >
                <span />
                <span />
            </div>
        </div>
        <SlideUpDownTransition :active="showContent">
            <slot name="default" />
        </SlideUpDownTransition>
    </div>
</template>

<script>
import SlideUpDownTransition from '@/components/common/transitions/slide-up-down.vue';

export default {
    name: 'CollapsableBlock',
    components: {
        SlideUpDownTransition,
    },
    props: {
        breakpoint: {
            type: String,
            default: 'sm',
            validator(value) {
                return ['xs', 'sm', 'md', 'lg', 'xl'].includes(value);
            },
        },
    },
    data() {
        return {
            showContent: true,
        };
    },
    methods: {
        toggleShowContent() {
            this.showContent = !this.showContent;
        },
    },
};
</script>

<style lang="scss" module>
.collapsableBlockLayout {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;

    .header {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .arrow {
            position: absolute;
            top: 9px;
            right: 0;
            display: none;
            width: 23px;
            height: 10px;

            span {
                position: absolute;
                top: 4px;
                display: inline-block;
                width: 15px;
                height: 3px;
                background-color: black;
                border-radius: 4px;
                transition: all 0.2s ease;

                &:first-of-type {
                    left: 0;
                    transform: scale(0.9) rotate(45deg);
                }

                &:last-of-type {
                    right: 0;
                    transform: scale(0.9) rotate(-45deg);
                }
            }

            &.active {
                span {
                    &:first-of-type {
                        transform: scale(0.9) rotate(-45deg);
                    }

                    &:last-of-type {
                        transform: scale(0.9) rotate(45deg);
                    }
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    @mixin show-arrow {
        .header {
            flex-direction: column;

            .arrow {
                display: flex;
            }
        }
    }

    &.xs {
        @include under-to(xs) {
            @include show-arrow;
        }
    }

    &.sm {
        @include under-to(sm) {
            @include show-arrow;
        }
    }

    &.md {
        @include under-to(md) {
            @include show-arrow;
        }
    }

    &.lg {
        @include under-to(lg) {
            @include show-arrow;
        }
    }

    &.xl {
        @include under-to(xl) {
            @include show-arrow;
        }
    }
}
</style>
