<template>
    <div :class="$style.tableComponent">
        <slot name="filters" />
        <ListHeader :breakpoint="breakpoint">
            <slot name="header" />

            <!-- Actions -->
            <template
                v-if="headerWithAction"
                #after
            >
                <span :style="{ width: '14px' }" />
            </template>
        </ListHeader>
        <template v-if="loading">
            <ListItem
                v-for="index in itemsInSkeleton"
                :key="`skeleton-${index}`"
                disabled
                :breakpoint="breakpoint"
            >
                <slot name="skeleton-loader" />
            </ListItem>
        </template>
        <template v-else>
            <div
                v-if="!itemsCount && emptyResultsIcon"
                :class="$style.emptyResults"
            >
                <div :class="$style.emptyResultsPicture">
                    <MasterSvg
                        :use="emptyResultsIcon"
                        :module="emptyResultsIconModule"
                    />
                </div>
                <div :class="$style.emptyResultsContent">
                    <slot name="empty-title">
                        <translate :class="$style.title">
                            No result found
                        </translate>
                    </slot>
                    <slot name="empty-text">
                        <translate>
                            Please try again with an other search
                        </translate>
                    </slot>
                </div>
            </div>
            <slot name="default" />
            <PaginateVue
                v-if="paginate"
                v-model="internalPageNumber"
                :items-count="itemsCount"
                :items-per-page="itemsPerPage"
            />
        </template>
    </div>
</template>

<script>
import ListHeader from '@/components/common/list-header.vue';
import ListItem from '@/components/common/list-item.vue';
import PaginateVue from '@/components/common/paginate.vue';
import MasterSvg from '@/components/common/master-svg.vue';

export default {
    components: {
        ListHeader,
        ListItem,
        PaginateVue,
        MasterSvg,
    },
    props: {
        breakpoint: {
            type: String,
            default: 'sm',
            validator(value) {
                return ['xs', 'sm', 'md', 'lg', 'xl'].includes(value);
            },
        },
        headerWithAction: {
            type: Boolean,
            default: true,
        },
        itemsInSkeleton: {
            type: Number,
            default: 3,
        },
        itemsCount: {
            type: Number,
            default: 0,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        paginate: {
            type: Boolean,
            default: false,
        },
        pageNumber: {
            type: Number,
            default: 0,
        },
        emptyResultsIcon: {
            type: String,
            default: '',
        },
        emptyResultsIconModule: {
            type: String,
            default: 'common',
        },
    },
    computed: {
        internalPageNumber: {
            get() {
                return this.pageNumber;
            },
            set(value) {
                this.$emit('update:pageNumber', value);
            },
        },
    },
};
</script>

<style lang="scss" module>
.tableComponent {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .emptyResults {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-self: center;

        .emptyResultsPicture {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-height: 250px;
        }

        .emptyResultsContent {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;
            font-size: 14px;

            .title {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}
</style>
