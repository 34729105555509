<template>
    <div :class="$style.gps_map_section">
        <div :class="$style.gps_map">
            <GmapMap
                map-type-id="roadmap"
                :center="center"
                :zoom="zoom"
                :options="options"
                :class="$style.gmap_container"
            >
                <GmapMarker
                    :position="center"
                    :clickable="true"
                    :draggable="true"
                    @dragend="handlePositionUpdate($event)"
                />
            </GmapMap>
        </div>
        <div
            v-if="displayCoordinates"
            :class="$style.coordinates"
        >
            <span>X: {{ latLng.lng }}</span>
            <span>Y: {{ latLng.lat }}</span>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
    props: { displayCoordinates: Boolean },
    data() {
        return {
            options: {
                streetViewControl: false,
                fullscreenControl: false,
                controlSize: 23,
            },
        };
    },
    computed: {
        ...mapGetters('gps', [
            'center',
            'latLng',
            'zoom',
        ]),
    },
    methods: {
        ...mapActions('gps', [
            'updatePosition',
        ]),
        handlePositionUpdate($event) {
            const position = {
                lat: $event.latLng.lat(),
                lng: $event.latLng.lng(),
            };

            this.updatePosition(position);
        },
    },
};

</script>

<style lang="scss" module>
.gps_map_section {
    width: 100%;
    height: 100%;

    .gps_map {
        .gmap_container {
            width: 100%;
            height: 300px;
        }
    }

    .coordinates {
        margin-top: 10px;
        font-size: 12px;
        color: #666;

        & > *:not(:first-child) {
            margin-left: 20%;
        }
    }
}
</style>
