var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visiblePorts.length
    ? _c("div", { class: _vm.$style.generalPortsList }, [
        _c(
          "div",
          { class: _vm.$style.portsList },
          [
            _vm._l(_vm.visiblePorts, function(iface, index) {
              return [
                _c("Port", {
                  key: index,
                  attrs: {
                    configuration: _vm.equipmentConfiguration,
                    iface: iface,
                    loading: _vm.interfacesLoading,
                    options: _vm.equipmentOptions
                  }
                })
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.needPaginate || _vm.isModified
          ? _c(
              "div",
              { class: _vm.$style.bottom },
              [
                _vm.needPaginate
                  ? _c("Paginate", {
                      attrs: {
                        "items-count": _vm.itemsCount,
                        "items-per-page": _vm.itemsPerPage
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isModified
                  ? _c(
                      "div",
                      { class: _vm.$style.controls },
                      [
                        _c("Button", {
                          staticClass: "mr-3",
                          attrs: {
                            role: "primary",
                            text: _vm.$gettext("Apply"),
                            size: "small"
                          },
                          on: { click: _vm.apply }
                        }),
                        _vm._v(" "),
                        _c("Button", {
                          attrs: {
                            role: "secondary",
                            text: _vm.$gettext("Cancel"),
                            size: "small"
                          },
                          on: { click: _vm.cancel }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    : _c("ComponentEmptySearch", {
        attrs: { size: "small" },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm.filterValue == "active"
                  ? _c(
                      "div",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [
                        _vm._v(
                          "\n            No active interface to show\n        "
                        )
                      ]
                    )
                  : _vm.filterValue == "inactive"
                  ? _c(
                      "div",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [
                        _vm._v(
                          "\n            No inactive interface to show\n        "
                        )
                      ]
                    )
                  : _c(
                      "div",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [_vm._v("\n            No interface to show\n        ")]
                    )
              ]
            },
            proxy: true
          },
          {
            key: "text",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n            Please try with an other filter\n        "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }