var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.gps_map_section }, [
    _c(
      "div",
      { class: _vm.$style.gps_map },
      [
        _c(
          "GmapMap",
          {
            class: _vm.$style.gmap_container,
            attrs: {
              "map-type-id": "roadmap",
              center: _vm.center,
              zoom: _vm.zoom,
              options: _vm.options
            }
          },
          [
            _c("GmapMarker", {
              attrs: { position: _vm.center, clickable: true, draggable: true },
              on: {
                dragend: function($event) {
                  return _vm.handlePositionUpdate($event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.displayCoordinates
      ? _c("div", { class: _vm.$style.coordinates }, [
          _c("span", [_vm._v("X: " + _vm._s(_vm.latLng.lng))]),
          _vm._v(" "),
          _c("span", [_vm._v("Y: " + _vm._s(_vm.latLng.lat))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }