<template>
    <div :class="$style.prettyfield">
        <div
            v-if="type=='datetime'"
            :class="[$style.datetime, {[$style.disabled]: disabled}]"
        >
            <label
                v-if="label"
                :class="[
                    $style.prettyinput,
                    $style.input,
                    {[$style.error]: !!errors}
                ]"
            >
                {{ label }}
                <span v-if="required">
                    *
                </span>
            </label>
            <VueCtkDateTimePicker
                v-model="inputVal"
                :min-date="minDate"
                :max-date="maxDate"
                :no-weekends-days="noWeekendsDays"
                :first-day-of-week="1"
                :minute-interval="30"
                :no-header="true"
                :no-button-now="true"
                :disabled-hours="disabledHours"
                locale="fr"
                format="YYYY-MM-DDTHH:mm"
            />
            <div
                v-if="errors"
                :class="$style.errors"
            >
                <div
                    v-for="(error, index) in errors"
                    :key="index"
                    :class="$style.error"
                >
                    <template v-if="typeof error === 'string'">
                        <span>{{ error }}</span>
                    </template>
                    <template v-else>
                        <span
                            v-for="(suberror, subindex) in error"
                            :key="subindex"
                        >
                            {{ parseInt(index) + 1 }}: {{ suberror }}
                        </span>
                    </template>
                </div>
            </div>
        </div>
        <label
            v-if="type=='number'"
            :class="$style.prettylabelnumber"
        >
            {{ label }}
            <InputSpin
                v-model="inputVal"
                :name="name"
                :label="label"
                :min="min"
                :max="max"
                :step="step"
                :is-integer-value="isIntegerValue"
                :mousedown="mousedown"
                :keydown="keydown"
                :maxlength="maxlength"
                :placeholder="placeholder"
                :nb-decimals="nbDecimals"
                :symbol="symbol"
            />
            <label
                v-if="secondaryLabel"
                :class="$style.secondary_label"
            >
                {{ secondaryLabel }}
            </label>
        </label>
        <label
            v-else
            :class="[
                $style.prettyinputfield,
                $style.input,
                {[$style.error]: errors},
                {[$style.disabled]: disabled},
                {[$style.select_large]: type=='select_large'},
                {[$style.select_small]: type=='select_small'},
                {[$style.small]: size=='small'}
            ]"
        >
            <MasterSvg
                v-if="iconLeft"
                :use="iconLeft"
                :custom-class="$style.icon_left"
            />
            <textarea
                v-if="type=='textarea'"
                v-model="inputVal"
                :name="name"
                autocomplete="off"
                :disabled="disabled"
                :required="required"
                :placeholder="computed_placeholder"
                :rows="textareaRows"
                :maxlength="textareaMaxLength"
            />
            <input
                v-else
                v-model="inputVal"
                autocomplete="off"
                :class="[
                    {[$style.input_with_icon_left]: iconLeft},
                    {[$style.input_with_icon_right]: computed_iconright||dynamicIconRight},
                ]"
                :type="realType"
                :name="name"
                :disabled="disabled"
                :readonly="readonly"
                :required="required"
                :placeholder="computed_placeholder"
                :min="min"
                :max="max"
                @focusIn="focusInInterceptor"
                @blur="blurInterceptor"
                @keydown.up="upInterceptor"
                @keydown.down="downInterceptor"
                @keydown.enter="enterInterceptor"
                @keydown.tab="tabInterceptor"
            >
            <div
                v-if="computed_iconright"
                :class="[
                    $style.icon_right,
                    {[$style.pointer_cursor]: computed_iconright === 'eye'},
                ]"
                @click="clickIconRightInterceptor"
            >
                <MasterSvg :use="computed_iconright" />
            </div>
            <span
                v-if="label && size !== 'small'"
                :class="[
                    $style.label,
                    {[$style.disabledLabel]: disabled},
                ]"
            >
                {{ label }}
                <span v-if="required">
                    *
                </span>
                <div
                    v-if="infoTooltips"
                    :class="$style.info_tooltips"
                    :data-content="infoTooltips"
                >
                    <MasterSvg use="info" />
                </div>
            </span>
            <div
                v-if="info && !errors"
                :class="$style.info"
            >
                {{ info }}
            </div>
            <div
                v-if="errors"
                :class="$style.errors"
            >
                <div
                    v-for="(error, index) in errors"
                    :key="index"
                    :class="$style.error"
                >
                    <template v-if="typeof error === 'string'">
                        <span>{{ error }}</span>
                    </template>
                    <template v-else>
                        <span
                            v-for="(suberror, subindex) in error"
                            :key="subindex"
                        >
                            {{ parseInt(index) + 1 }}: {{ suberror }}
                        </span>
                    </template>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
import moment from 'moment';
import MasterSvg from './master-svg.vue';
import InputSpin from './input_spin.vue';

export default {
    components: {
        MasterSvg,
        InputSpin,
    },
    props: {
        type: {
            type: String,
            default: 'text',
        },
        minDate: {
            type: String,
            default: (() => moment().toISOString()),
        },
        maxDate: {
            type: String,
            default: null,
        },
        disabledHours: {
            type: Array,
            default: (() => ['00', '01', '02', '03', '04', '05', '06', '07', '08', '19', '20', '21', '22', '23', '24']),
        },
        noWeekendsDays: {
            type: Boolean,
            default: true,
        },
        inline: {
            type: Boolean,
            default: true,
        },
        value: {
            default: null,
            required: false,
        },
        errors: {
            type: Array,
            default: (() => null),
            required: false,
        },
        label: {
            type: String,
            default: null,
        },
        secondaryLabel: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: 'large',
        },
        iconLeft: {
            type: String,
            default: null,
        },
        iconRight: {
            type: String,
            default: null,
        },
        dynamicIconRight: {
            type: Boolean,
            default: false,
        },
        infoTooltips: {
            type: String,
            default: null,
        },
        info: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        min: {
            type: [Number, String],
            default: 0,
        },
        max: {
            type: [Number, String],
            default: 100,
        },
        step: {
            type: Number,
            default: 1,
        },
        isIntegerValue: {
            type: Boolean,
            default: false,
        },
        mousedown: {
            type: Boolean,
            default: false,
        },
        keydown: {
            type: Boolean,
            default: true,
        },
        maxlength: {
            type: Number,
            default: null,
        },
        nbDecimals: {
            type: Number,
            default: null,
        },
        symbol: {
            type: String,
            default: null,
        },
        textareaRows: {
            type: String,
            default: '5',
        },
        textareaMaxLength: {
            type: String,
            default: '500',
        },
    },
    data() {
        return {
            inputVal: this.value,
            realType: this.type,
        };
    },
    computed: {
        computed_iconright() {
            return this.type === 'password' && !this.iconRight ? 'eye' : this.iconRight;
        },
        computed_placeholder() {
            if (!this.placeholder && this.label && this.size === 'small') {
                return this.label;
            }

            return this.placeholder;
        },
    },
    watch: {
        value: {
            handler() {
                this.inputVal = this.value;
            },
            immediate: true,
            deep: true,
        },
        inputVal: {
            handler(val) {
                this.$emit('input', val);
            },
        },
    },
    created() {
        if (!['text', 'password', 'date', 'time'].includes(this.type)) {
            this.realType = 'text';
        }
    },
    methods: {
        focusInInterceptor() {
            this.$emit('focusIn');
        },
        blurInterceptor() {
            this.$emit('focusOut');
        },
        upInterceptor() {
            this.$emit('navigationUp');
        },
        downInterceptor() {
            this.$emit('navigationDown');
        },
        enterInterceptor() {
            this.$emit('navigationEnter');
        },
        tabInterceptor(event) {
            this.$emit('navigationTab', event);
        },
        clickIconRightInterceptor() {
            if (this.computed_iconright === 'eye') {
                this.realType = this.realType === 'text' ? 'password' : 'text';
            } else {
                this.$emit('click-icon-right');
            }
        },
    },

};
</script>

<style lang="scss" module>
.prettyfield {
    background-color: inherit;

    .prettyinputfield {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        background-color: inherit;

        .label {
            position: absolute;
            top: -20px;
            left: 12px;
            display: flex;
            padding: 2px 10px 2px 8px;
            font-family: inherit;
            font-size: 11px;
            font-weight: bold;
            white-space: nowrap;
            cursor: text;
            background-color: inherit;
            transform: translateY(50%);

            @include themify($themes) {
                color: themed('primary_color');
            }

            &:before {
                position: absolute;
                top: 50%;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                display: block;
                content: '';
                background-color: var(--grey-50-hex);

                @include themify($themes) {
                    background-color: white;
                }
            }

            span {
                margin-left: 3px;
                color: red;
            }

            .info_tooltips {
                position: relative;
                display: flex;
                align-items: center;
                width: 15px;
                height: 15px;
                margin-left: 4px;

                svg {
                    width: 15px;
                    height: 15px;

                    @include themify($themes) {
                        fill: themed('grayscale_medium');
                    }
                }

                &:hover {
                    &:after,
                    &:before {
                        display: block;
                        transition: 0.3s ease all;
                    }
                }

                &:before {
                    position: absolute;
                    bottom: calc(100% + 5px);
                    left: calc(50%);
                    z-index: 5;
                    display: none;
                    width: 10px;
                    height: 10px;
                    content: " ";
                    background: white;
                    transform: rotate(45deg) translate(-25%, 25%);
                }

                &:after {
                    position: absolute;
                    bottom: calc(100% + 10px);
                    left: calc(50%);
                    z-index: 4;
                    display: none;
                    width: max-content;
                    max-width: 300px;
                    padding: 10px 20px;
                    font-family: 'Orkney', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.57;
                    color: black;
                    text-align: center;
                    white-space: pre-wrap;
                    content: attr(data-content);
                    background-color: white;
                    border-radius: 4px;
                    transition: 0.3s ease all;
                    transform: translateX(-50%);

                    @include themify($themes) {
                        box-shadow: 0 0 8px 0 rgba(themed('primary_color'), 0.4);
                    }
                }
            }
        }

        &.small {
            input {
                height: 30px;
                padding: 0 35px;
                font-size: 13px;
            }

            .icon {
                top: 15px;
            }

            .icon_left {
                left: 14px;
            }

            .icon_right {
                right: 14px;
            }
        }

        .icon {
            position: absolute;
            top: 23px;
            width: 16px;
            height: auto;
            transform: translateY(-50%);

            @include themify($themes) {
                fill: themed('grayscale_regular');
            }

            svg {
                width: 16px;
                height: auto;
            }
        }

        .icon_left {
            @extend .icon;

            left: 20px;
        }

        .icon_right {
            @extend .icon;

            right: 20px;
            display: flex;
            align-items: center;
        }

        input {
            width: 100%;
            height: 46px;
            padding: 0 20px;
            font-family: inherit;
            font-size: 13px;
            font-style: normal;
            font-weight: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            background-color: white;
            border-radius: 4px;
            outline: none;
            appearance: none;
            transition: all ease-in-out 0.15s;

            @include themify($themes) {
                color: black;
                border: 1px solid themed('grayscale_regular');
            }

            &:focus {
                @include themify($themes) {
                    border-color: themed('primary_color');
                    box-shadow: 0 0 4px themed('secondary_color');
                }
            }

            &:placeholder {
                @include themify($themes) {
                    color: themed('grayscale_semi');
                }
            }
        }

        textarea {
            @extend input;

            height: auto;
            padding: 10px 20px;
            resize: none;
        }

        .input_with_icon_left {
            @extend input;

            padding-left: 45px;
        }

        .input_with_icon_right {
            @extend input;

            padding-right: 45px;
        }

        .pointer_cursor {
            cursor: pointer;
        }

        &.select_large {
            .label,
            input {
                cursor: pointer;
            }

            &:after {
                position: absolute;
                top: 25px;
                right: 20px;
                z-index: 9;
                width: 0;
                height: 0;
                pointer-events: none;
                content: "";
                border-right: 3px solid transparent;
                border-left: 3px solid transparent;
                transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

                @include themify($themes) {
                    border-top: 6px solid themed('primary_color');
                }
            }

            &:before {
                position: absolute;
                top: 17px;
                right: 20px;
                z-index: 9;
                width: 0;
                height: 0;
                pointer-events: none;
                content: "";
                border-right: 3px solid transparent;
                border-left: 3px solid transparent;
                transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

                @include themify($themes) {
                    border-bottom: 6px solid themed('primary_color');
                }
            }
        }

        &.select_small {
            .label {
                top: 10px;

                @include under-to(xl) {
                    text-align: left;
                }
            }

            input {
                top: 10px;
                height: 30px;
                padding: 9px 13px 7px 20px;
                cursor: pointer;
            }

            &:after {
                position: absolute;
                right: 20px;
                bottom: calc(50% - 7px);
                z-index: 9;
                width: 0;
                height: 0;
                pointer-events: none;
                content: "";
                border-right: 3px solid transparent;
                border-left: 3px solid transparent;
                transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

                @include themify($themes) {
                    border-top: 6px solid themed('primary_color');
                }
            }

            &:before {
                position: absolute;
                top: calc(50% - 7px);
                right: 20px;
                z-index: 9;
                width: 0;
                height: 0;
                pointer-events: none;
                content: "";
                border-right: 3px solid transparent;
                border-left: 3px solid transparent;
                transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

                @include themify($themes) {
                    border-bottom: 6px solid themed('primary_color');
                }
            }
        }

        &.error span {
            @include themify($themes) {
                color: themed('error_color');
            }
        }

        &.error input {
            @include themify($themes) {
                border: 1px solid themed('error_color');
            }
        }

        &.error input:focus,
        &.error input:not(:placeholder-shown) {
            & + span {
                @include themify($themes) {
                    color: themed('error_color');
                }
            }
        }

        .error,
        .info {
            padding-top: 5px;
            padding-left: 21px;
            font-family: inherit;
            font-size: 12px;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
        }

        .info {
            color: black;
        }

        .error {
            @include themify($themes) {
                color: themed('error_color');
            }
        }

        &.disabled {
            .label {
                @include themify($themes) {
                    color: themed('grayscale_bold');
                }

                &:before {
                    @include themify($themes) {
                        background-color: themed('gray_scale_light');
                    }
                }
            }

            input {
                cursor: not-allowed;

                @include themify($themes) {
                    color: themed('grayscale_bold');
                    background-color: themed('gray_scale_light');
                }
            }
        }
    }

    .prettylabelnumber {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        font-family: inherit;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        background-color: inherit;

        .secondary_label {
            font-size: 11px;
            font-weight: 300;

            @include themify($themes) {
                color: themed('gray_scale_dark');
            }
        }
    }

    .datetime .errors {
        @include themify($themes) {
            color: themed('error_color');
        }
    }
}
</style>
