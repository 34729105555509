<!-- eslint-disable max-len -->
<template>
    <div :class="$style.eventsSection">
        <Advice :title="sectionTitle">
            <template v-slot:text>
                <p v-translate>
                    You will find in this section the deployment and restart events of the routers performed from the tool.
                </p>
                <p>
                    <span v-translate>
                        A partial deployment refers to the deployment of the latest changes not yet existing on the router. No reboot is required.
                    </span>
                    <br>
                    <span v-translate>
                        A full deployment is a deployment of new configuration file on the equipment. This deployment needs a reboot.
                    </span>
                </p>
            </template>
        </Advice>

        <div :class="$style.borderBox">
            <div :class="['mt-3', 'mb-4', $style.titleAndSelect]">
                <Title
                    :add-col-class="false"
                    :title="sectionTitle"
                />
                <div :class="$style.eventsHeader">
                    <Select
                        v-if="isVrrp"
                        v-model="equipmentLinkId"
                        :data-list="equipmentsList"
                        role="small"
                        :class="$style.equipmentsSelect"
                        name="equipmentsList"
                    />
                    <!-- table filters -->
                    <ComponentSimpleFilters
                        v-model="selectedStatus"
                        :filter-choices="statusList"
                    />
                </div>
            </div>
            <EventsList
                :events="events"
                :events-count="eventsCount"
                :events-per-page="eventsPerPage"
                :loading="loading"
                @filters-update="handlePaginationUpdate"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { showSwalError } from '@/utils/utils';

import Select from '@/components/common/select.vue';

import EventsList from './events-list.vue';

import Advice from '../advice.vue';
import Title from '../component-title.vue';
import ComponentSimpleFilters from '../component-simple-filters.vue';

export default {
    components: {
        Advice,
        ComponentSimpleFilters,
        EventsList,
        Select,
        Title,
    },
    props: {
        scheduledActions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            equipmentLinkId: null,
            equipments: null,
            showLoader: true,
            loading: true,
            events: [],
            eventsCount: 0,
            eventsPerPage: 5,
            selectedStatus: 'all',
            filters: {
                offset: 0,
            },
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'virtual_iplink_members',
        ]),
        statusList() {
            return [
                { label: this.$gettext('Success'), value: 'successful' },
                { label: this.$gettext('Failure'), value: 'failure' },
            ];
        },
        sectionTitle() {
            return this.$gettext('Events');
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        equipmentsList() {
            const choices = [];

            if (this.virtual_iplink_members) {
                this.virtual_iplink_members.forEach((item, index) => {
                    choices.push({
                        id: item.iplink,
                        text: this.$gettextInterpolate(
                            this.$gettext('Equipment %{n}'),
                            { n: index + 1 },
                        ),
                        selected: index === 0,
                    });
                });
            }

            return choices;
        },
    },
    watch: {
        scheduledActions() {
            this.showLoader = false;
            this.fetchEvents();
        },
        linkequipment: {
            handler() {
                if (!this.linkequipment) return;
                if (this.equipmentLinkId) {
                    this.showLoader = false;
                    this.fetchEvents();
                    return;
                }
                this.equipmentLinkId = this.linkequipment.access;
            },
            deep: true,
            immediate: true,
        },
        virtual_iplink_members: {
            handler() {
                if (!this.virtual_iplink_members.length || !this.isVrrp) {
                    this.equipmentLinkId = this.linkequipment.access;
                    return;
                }
                this.equipmentLinkId = this.virtual_iplink_members[0].iplink;
            },
            deep: true,
            immediate: true,
        },
        filters: {
            handler() {
                this.fetchEvents();
            },
            deep: true,
        },
        equipmentLinkId() {
            this.fetchEvents();
        },
        selectedStatus(value) {
            if (value === 'all') {
                Vue.delete(this.filters, 'status');
            } else {
                Vue.set(this.filters, 'status', value);
            }
        },
    },
    mounted() {
        this.fetchEvents();
    },
    methods: {
        ...mapActions('equipment/actions', [
            'getLinkEquipmentActions',
        ]),
        fetchEvents() {
            if (this.showLoader) this.loading = true;
            this.getLinkEquipmentActions({
                access: this.equipmentLinkId,
                limit: this.eventsPerPage,
                method: [
                    'provisioning',
                    'deploy_linkequipment_conf',
                    'reboot_linkequipment',
                    'upgrade_firmware',
                ],
                status: ['scheduled', 'failure', 'successful'],
                ...this.filters,
            }).then((response) => {
                this.events = response.results;
                this.eventsCount = response.count;
            }).catch(
                showSwalError,
            ).finally(() => {
                this.loading = false;
                this.showLoader = true;
            });
        },
        handlePaginationUpdate(page) {
            this.showLoader = false;
            this.filters.offset = this.eventsPerPage * page;
        },
    },
};
</script>

<style lang="scss" module>
.eventsSection {
    min-height: 800px;

    .titleAndSelect {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .eventsHeader {
            display: flex;
            gap: 12px;
            place-content: flex-end;
            justify-content: space-between;
            margin-left: auto;

            .equipmentsSelect {
                max-width: 220px;
                font-size: 12px;
            }
        }
    }

    .borderBox {
        padding: 30px;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        @include under-to(md) {
            padding: 20px;
        }
    }
}
</style>
