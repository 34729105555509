var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.headerAdviceLayout },
    [
      _vm._t("before"),
      _vm._v(" "),
      _c(
        "Advice",
        {
          attrs: {
            "can-be-closed": _vm.adviceCanBeClosed,
            "link-url": _vm.adviceLinkUrl
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [_vm._t("advice-title")]
                },
                proxy: true
              },
              {
                key: "subtitle",
                fn: function() {
                  return [_vm._t("advice-subtitle")]
                },
                proxy: true
              },
              {
                key: "example",
                fn: function() {
                  return [_vm._t("advice-example")]
                },
                proxy: true
              }
            ],
            null,
            true
          ),
          model: {
            value: _vm.internalAdviceDisplay,
            callback: function($$v) {
              _vm.internalAdviceDisplay = $$v
            },
            expression: "internalAdviceDisplay"
          }
        },
        [_vm._v(" "), _vm._v(" "), [_vm._t("advice-content")]],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }