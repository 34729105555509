var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("simple-svg", {
    attrs: {
      src: _vm.svg,
      width: _vm.width,
      height: _vm.height,
      "custom-class-name": _vm.customClass
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }