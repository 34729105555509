import { mapActions } from 'vuex';
import QuestionSvg from '@/assets/ic-question.svg';
import { showSwal, setError } from '@/utils/utils';
import { actionsControl } from '../utils/utils';

const FirstProvisioningModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-firstprovisioning" */
    '../modals/firstprovisioning.vue'
);
const ManualDeploymentModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-manualdeployment" */
    '../modals/manualdeployment.vue'
);
const ConfTemplateSaveAskModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-conftemplatesaveask" */
    '../modals/conf-template-save-ask.vue'
);
const ScheduleDeploymentModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-scheduledeployment" */
    '../modals/schedule-deployment/modal.vue'
);

export default {
    methods: {
        ...mapActions('equipment', [
            'deploy',
            'fetch_linkequipmentoptions',
        ]),
        show_swal_deploy_conf(
            linkequipment,
            linkequipmentoptions,
            forceIplink = false,
            schedule = false,
        ) {
            return new Promise((resolve, reject) => {
                if (!linkequipment.is_plugged) {
                    const data = {
                        type: 'first_deployment',
                        params: {
                            component: FirstProvisioningModal,
                            props_component: {
                                linkequipment,
                                'init-scripts': linkequipmentoptions.init_scripts,
                            },
                            props_modal: {
                                maxWidth: 580,
                                maxHeight: 630,
                            },
                        },
                    };
                    resolve(data);
                    return;
                }

                if (linkequipment.discharged) {
                    resolve({});
                    return;
                }

                if (linkequipment.status === 'rebooting') {
                    reject(setError(
                        'warning',
                        this.$gettext(
                            'This action cannot be done because equipment is rebooting.',
                        ),
                    ));
                    return;
                }

                if (linkequipment.synchronized) {
                    resolve({});
                    return;
                }

                actionsControl(linkequipment).then(() => {
                    const hotpatch = linkequipmentoptions.can_hotpatch;
                    const deployConf = linkequipmentoptions.can_deploy_conf;
                    let footerText = this.$gettext(
                        'No reboot will be required to deploy this configuration',
                    );

                    if (!hotpatch && !deployConf) {
                        const data = {
                            type: 'manual_deployment',
                            params: {
                                component: ManualDeploymentModal,
                                props_component: {
                                    linkequipment,
                                },
                                props_modal: {
                                    maxWidth: 580,
                                    maxHeight: 630,
                                },
                            },
                        };
                        resolve(data);
                        return;
                    }

                    if (schedule) {
                        this.show_modal(
                            ScheduleDeploymentModal,
                            {},
                            {
                                maxHeight: 515,
                                maxWidth: 600,
                            },
                        ).then((scheduleParams) => {
                            const data = {
                                type: 'auto_deployment',
                                params: {
                                    comment: scheduleParams.comment,
                                    executionDate: scheduleParams.executionDate,
                                },
                            };

                            if (forceIplink) {
                                data.params.force_iplink = linkequipment.access;
                            }
                            resolve(data);
                        });
                        return;
                    }

                    if (!hotpatch) {
                        footerText = this.$gettext(
                            'Reboot will be required to deploy this configuration on equipment',
                        );
                    }

                    this.save_configuration().then(() => {
                        showSwal({
                            title: this.$gettext('Configuration deployment'),
                            text: this.$gettext('You are about to deploy configuration on the equipment. Previous configuration will be saved and you will be able to restore it from the "Saved configurations" panel.'),
                            type: 'question',
                            input: 'textarea',
                            footer: footerText,
                            inputPlaceholder: this.$gettext('Add a comment to indicate the reasons to apply these modifications'),
                            inputAttributes: {
                                'aria-label': this.$gettext('Add a comment to indicate the reasons to apply these modifications'),
                            },
                            showCancelButton: true,
                            confirmButtonText: this.$gettext('Deploy configuration'),
                            cancelButtonText: this.$gettext('Cancel'),
                            showCloseButton: true,
                            showLoaderOnConfirm: false,
                            preConfirm: (result) => {
                                if (!hotpatch) {
                                    this.$swal.insertQueueStep({
                                        title: this.$gettext('Reboot required'),
                                        text: this.$gettext('Equipment needs to be rebooted once configuration is deployed. Do you really want to deploy now ?'),
                                        imageUrl: QuestionSvg,
                                        imageWidth: 75,
                                        imageHeight: 75,
                                        showCancelButton: true,
                                        confirmButtonText: this.$gettext('Yes'),
                                        cancelButtonText: this.$gettext('No'),
                                        showCloseButton: true,
                                        showLoaderOnConfirm: true,
                                    });
                                }

                                return result.value;
                            },
                        }).then((result) => {
                            if (result.dismiss) {
                                resolve({});
                                return;
                            }

                            const data = {
                                type: 'auto_deployment',
                                params: {
                                    hotpatch,
                                    comment: result.value[0],
                                },
                            };

                            if (forceIplink) {
                                data.params.force_iplink = linkequipment.access;
                            }

                            resolve(data);
                        });
                    });
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        save_configuration() {
            let templatesSettings = null;
            if (localStorage.templates_settings) {
                templatesSettings = JSON.parse(localStorage.templates_settings);
            }
            if (templatesSettings && templatesSettings.hide_modal) {
                return new Promise((resolve) => {
                    resolve();
                });
            }

            return this.show_modal(
                ConfTemplateSaveAskModal,
                {},
                {
                    maxHeight: 340,
                    maxWidth: 600,
                },
            );
        },
        deploy_conf(linkequipment, forceIplink = false, schedule = false) {
            return new Promise((resolve, reject) => {
                const paramsFetchOptions = {};
                if (forceIplink) {
                    paramsFetchOptions.force_iplink = linkequipment._access.link_id;
                }

                this.fetch_linkequipmentoptions(paramsFetchOptions).then((linkequipmentoptions) => {
                    this.show_swal_deploy_conf(
                        linkequipment,
                        linkequipmentoptions,
                        forceIplink,
                        schedule,
                    ).then((data) => {
                        if (data.type === 'first_deployment') {
                            this.save_configuration().then(() => {
                                this.show_modal(
                                    data.params.component,
                                    data.params.props_component,
                                    data.params.props_modal,
                                );
                            });
                            resolve(data);
                        } else if (data.type === 'manual_deployment') {
                            this.save_configuration().then(() => {
                                this.show_modal(
                                    data.params.component,
                                    data.params.props_component,
                                    data.params.props_modal,
                                );
                            });
                            resolve(data);
                        } else if (data.type === 'auto_deployment') {
                            this.deploy(data.params).then((result) => {
                                resolve({ ...data, result });
                            }).catch(reject);
                        } else {
                            resolve(data);
                        }
                    }).catch(reject);
                }).catch(reject);
            });
        },
    },
};
