
<template>
    <CollapsableBlockLayout breakpoint="md">
        <template #title>
            <translate>List of ports</translate>
        </template>
        <template #header>
            <div :class="$style.generalPortsHeader">
                <Select
                    v-if="isVrrp"
                    v-model="equipmentLinkId"
                    :data-list="equipmentsList"
                    role="small"
                    :class="$style.equipmentsSelect"
                    name="equipmentsList"
                />
                <ComponentSimpleFilters
                    v-model="filterValue"
                    :filter-choices="interfaceFiltersValues"
                />
            </div>
        </template>
        <div :class="$style.generalPortsList">
            <UpdatedSection section="interfaces" />
            <GeneralPortsList
                :equipment-link-id="equipmentLinkId"
                :filter-value="filterValue"
            />
        </div>
    </CollapsableBlockLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import Select from '@/components/common/select.vue';

import GeneralPortsList from './general-ports-list.vue';

import UpdatedSection from '../../component-updated-section.vue';
import ComponentSimpleFilters from '../../component-simple-filters.vue';

import CollapsableBlockLayout from '../../layout/collapsable-block.vue';

export default {
    name: 'GeneralPorts',
    components: {
        ComponentSimpleFilters,
        CollapsableBlockLayout,
        GeneralPortsList,
        Select,
        UpdatedSection,
    },
    data() {
        return {
            equipmentLinkId: null,
            filterValue: 'all',
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'virtual_iplink_members',
        ]),
        equipmentsList() {
            const choices = [];

            if (this.virtual_iplink_members) {
                this.virtual_iplink_members.forEach((item, index) => {
                    choices.push({
                        id: item.iplink,
                        text: this.$gettextInterpolate(
                            this.$gettext('Equipment %{n}'),
                            { n: index + 1 },
                        ),
                        selected: index === 0,
                    });
                });
            }

            return choices;
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        interfaceFiltersValues() {
            return [
                { label: this.$gettext('Active'), value: 'active' },
                { label: this.$gettext('Inactive'), value: 'inactive' },
            ];
        },
    },
    watch: {
        linkequipment: {
            handler() {
                if (!this.linkequipment || this.equipmentLinkId) {
                    return;
                }
                this.equipmentLinkId = this.linkequipment.access;
            },
            deep: true,
            immediate: true,
        },
        virtual_iplink_members: {
            handler() {
                if (!this.virtual_iplink_members.length || !this.isVrrp) {
                    this.equipmentLinkId = this.linkequipment.access;
                    return;
                }
                this.equipmentLinkId = this.virtual_iplink_members[0].iplink;
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" module>
.generalPortsHeader {
    display: flex;
    gap: 12px;
    place-content: flex-end;
    justify-content: space-between;
    margin-left: auto;

    .equipmentsSelect {
        max-width: 220px;
        font-size: 12px;
    }
}

.generalPortsList {
    display: flex;
    flex-direction: column;
    height: auto;
}
</style>
