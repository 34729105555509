var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.deployButtonDisplay
    ? _c("Button", {
        class: [_vm.$style.deployButton, _vm.deployButtonClass],
        attrs: {
          text: _vm.deployButtonText,
          size: "large",
          icon: _vm.deployButtonIcon,
          disabled: _vm.linkequipment.is_plugged && _vm.linkequipment.discharged
        },
        on: { click: _vm.handleButtonClick }
      })
    : _c(
        "DropdownMenu",
        {
          class: [_vm.$style.deployButton, _vm.$style.dropdownMenuDeploy],
          attrs: { mode: "click", size: "large" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function() {
                return [
                  _c("Button", {
                    attrs: {
                      text: _vm.$gettext("Deploy"),
                      icon: "arrow-down",
                      size: "large"
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("DropdownMenuItem", {
            attrs: { icon: "deploy", name: _vm.$gettext("Deploy now") },
            on: {
              click: function($event) {
                return _vm.showDeployConfModal(false)
              }
            }
          }),
          _vm._v(" "),
          _c("DropdownMenuItem", {
            attrs: {
              icon: "arrow-time",
              name: _vm.$gettext("Schedule a deployment")
            },
            on: {
              click: function($event) {
                return _vm.showDeployConfModal(true)
              }
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }