import VModal from 'vue-js-modal';

const DefaultModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "common-modal" */
    '../components/common/modal.vue'
);

export default {
    install(Vue) {
        Vue.$modal_classes = [];

        Vue.use(
            VModal,
            {
                dynamicDefaults: {
                    transition: 'nice-modal-fade',
                    draggable: false,
                    resizable: false,
                    adaptive: true,
                    reset: true,
                    scrollable: true,
                    width: '80%',
                    height: '80%',
                    maxWidth: 1210,
                    maxHeight: 560,
                    styles: {
                        overflow: 'auto',
                    },
                },
            },
        );

        Vue.mixin({
            methods: {
                show_modal(component, propsComponent = {}, propsModal = {}) {
                    return new Promise((resolve, reject) => {
                        propsModal.component = component;
                        propsModal.propsComponent = propsComponent;
                        propsModal.classes = Vue.$modal_classes;

                        let alwaysResolved = true;
                        if (Object.prototype.hasOwnProperty.call(propsModal, 'alwaysResolved')) {
                            // eslint-disable-next-line prefer-destructuring
                            alwaysResolved = propsModal.alwaysResolved;
                        }

                        this.$modal.show(
                            DefaultModal,
                            propsModal,
                            propsModal,
                            {
                                'before-close': (event) => {
                                    if (alwaysResolved) {
                                        resolve((event.params || {}).data);
                                        return;
                                    }
                                    if (!event.params) {
                                        reject();
                                        return;
                                    }
                                    if (event.params.action === 'close') {
                                        resolve(event.params.data);
                                        return;
                                    }
                                    reject(event.params.data);
                                },
                            },
                        );
                    });
                },
                hide_all_modal() {
                    this.$modal.hideAll();
                },
            },
        });

        Vue.prototype.$setModalClasses = function setModalClasses(classes) {
            Vue.$modal_classes = classes;
        };
    },
};
