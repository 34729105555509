var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.dnsConfiguration },
    [
      _c(
        "div",
        { class: _vm.$style.body4 },
        [_c("translate", [_vm._v("DNS")])],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.fieldsForm },
        [
          _c("Field", {
            attrs: {
              errors: _vm.formErrors.primary_dns,
              label: _vm.$gettext("Primary DNS"),
              required: "",
              disabled: _vm.useDefaultDns || _vm.disabled,
              name: "primary_dns"
            },
            model: {
              value: _vm.internalPrimaryDns,
              callback: function($$v) {
                _vm.internalPrimaryDns = $$v
              },
              expression: "internalPrimaryDns"
            }
          }),
          _vm._v(" "),
          _c("Field", {
            attrs: {
              errors: _vm.formErrors.secondary_dns,
              label: _vm.$gettext("Secondary DNS"),
              disabled: _vm.useDefaultDns || _vm.disabled,
              name: "secondary_dns"
            },
            model: {
              value: _vm.internalSecondaryDns,
              callback: function($$v) {
                _vm.internalSecondaryDns = $$v
              },
              expression: "internalSecondaryDns"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("Choice", {
        class: _vm.$style.defaultDns,
        attrs: {
          disabled: _vm.disabled,
          name: "useDefaultDns",
          label: _vm.$gettext("Use default DNS servers"),
          type: "checkbox"
        },
        model: {
          value: _vm.useDefaultDns,
          callback: function($$v) {
            _vm.useDefaultDns = $$v
          },
          expression: "useDefaultDns"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }