<template>
    <div>
        <div
            :class="[$style.box, $style.vrrp_member]"
        >
            <div :class="$style.header_member">
                <div :class="$style.name">
                    <div v-translate="{n: index}">
                        Equipment %{n}
                    </div>
                    <div
                        class="d-flex"
                        :class="$style.status"
                    >
                        <div
                            v-if="linkequipment.is_plugged"
                            :class="[
                                $style.refresh_status,
                                {[$style.active]: fetching_status}
                            ]"
                            @click="fetchEquipmentStatus()"
                        >
                            <MasterSvg
                                use="refresh"
                                width="15px"
                                height="15px"
                            />
                        </div>
                        <span :class="[$style.statusbullet, status_class]">
                            {{ status_text }}
                        </span>
                        <span :class="$style.synchronize_text">
                            {{ synchronize_text }}
                        </span>
                    </div>
                </div>
                <DropdownMenu
                    color="white"
                    position="right"
                    size="large"
                >
                    <DropdownMenuItem
                        v-if="can_manage_router"
                        icon="key"
                        :name="$gettext('Credentials')"
                        @click="showCredentialsModal()"
                    />
                    <DropdownMenuItem
                        v-if="!linkequipment.discharged
                            && linkequipment.is_plugged
                            && has_capability('can_reboot_equipment')
                        "
                        icon="restart"
                        :name="$gettext('Restart equipment')"
                        @click="rebootEquipment()"
                    />
                    <DropdownMenuItem
                        v-if="!linkequipment.discharged"
                        icon="deploy"
                        :name="$gettext('Deploy configuration')"
                        @click="showDeployConfModal()"
                    />
                    <DropdownMenuItem
                        v-if="!linkequipment.conf_is_private
                            && linkequipment.is_plugged || linkequipment.discharged
                        "
                        icon="download"
                        :name="$gettext('Download configuration')"
                        @click="downloadConf()"
                    />
                    <DropdownMenuItem
                        v-if="!linkequipment.discharged"
                        icon="switch-equipment"
                        :name="$gettext('Change equipment')"
                        @click="showAttachModal()"
                    />
                </DropdownMenu>
            </div>
            <div :class="$style.infos">
                <div :class="[$style.box_info, $style.main_box]">
                    <div :class="$style.equipment_picture_background">
                        <div
                            :class="$style.equipment_picture"
                            :style="`background-image: url('${routerPicture}')`"
                        />
                    </div>
                </div>
                <div :class="[$style.box_info, $style.main_box]">
                    <div :class="$style.info_bigname">
                        {{ linkequipment._equipment._model._vendor.name }}
                        {{ linkequipment._equipment._model.name }}
                    </div>
                    <div :class="$style.info_bigvalue">
                        <template v-if="!linkequipment._access._iplink.members.length > 1">
                            <span
                                v-translate
                                class="d-inline-flex"
                            >
                                Multi ip links
                            </span>
                            <div :class="$style.members_list">
                                <MasterSvg use="info" />
                                <div
                                    :class="$style.flyinginfo"
                                    :data-content="get_members_list(linkequipment._access._iplink)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            {{ linkequipment._access._iplink.link_id }}
                        </template>
                    </div>
                    <ProviderBadge
                        v-if="linkequipment._equipment.serial_number"
                        :class="$style.provider_badge"
                        :equipment="linkequipment._equipment"
                    />
                </div>
                <div :class="$style.box_info">
                    <div
                        v-translate
                        :class="$style.info_name"
                    >
                        Model
                    </div>
                    <div :class="$style.info_value">
                        {{ linkequipment._equipment._model._vendor.name }}
                    </div>
                </div>
                <div :class="$style.box_info">
                    <div
                        v-translate
                        :class="$style.info_name"
                    >
                        Serial number
                    </div>
                    <div :class="$style.info_value">
                        {{ serial_number }}
                    </div>
                </div>
                <div
                    v-if="firmwareVersion && firmwareVersion.current_version"
                    :class="$style.box_info"
                >
                    <div
                        v-translate
                        :class="$style.info_name"
                    >
                        Firmware version
                    </div>
                    <div
                        class="d-inline-flex"
                        :class="[$style.info_value, $style.version]"
                        @click="showFirmwareModal"
                    >
                        <span>
                            {{ firmwareVersion.current_version }}
                        </span>
                        <MasterSvg
                            :class-css="firmwareIconStyle.class"
                            :use="firmwareIconStyle.name"
                            width="12px"
                            height="12px"
                        />
                    </div>
                </div>
                <div
                    v-if="canIPv4"
                    :class="$style.box_info"
                >
                    <div :class="$style.info_name">
                        <translate v-if="linkequipment._access._iplink.mpls">
                            Private IPv4
                        </translate>
                        <translate v-else>
                            Public IPv4
                        </translate>
                    </div>
                    <div :class="$style.info_value">
                        {{ linkequipment._access.connectivity_ipv4_address }}
                    </div>
                </div>
                <div
                    v-if="canIPv6"
                    :class="$style.box_info"
                >
                    <div
                        v-translate
                        :class="$style.info_name"
                    >
                        Public IPv6
                    </div>
                    <div :class="$style.info_value">
                        {{ linkequipment._access.router_ipv6_address }}
                    </div>
                </div>
                <div
                    v-if="canIPv6"
                    :class="$style.box_info"
                >
                    <div
                        v-translate
                        :class="$style.info_name"
                    >
                        LAN IPv6
                    </div>
                    <div :class="$style.info_value">
                        {{ linkequipment._access.ipv6_lan_subnet }}
                    </div>
                </div>
                <div
                    v-if="!linkequipment._access._iplink.members.length"
                    :class="$style.box_info"
                >
                    <div
                        v-translate
                        :class="$style.info_name"
                    >
                        Link type
                    </div>
                    <div :class="$style.info_value">
                        {{ linkequipment._access.link_type_display }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    showSwal,
    showSwalError,
    fetchValueInDictInsensitiveCase,
} from '@/utils/utils';
import {
    showErrorConf,
    actionsControl,
} from './utils/utils';
import MasterSvg from './master-svg.vue';
import DropdownMenu from './dropdown-menu.vue';
import DropdownMenuItem from './dropdown-menu-item.vue';
import ProviderBadge from './provider-badge.vue';
import defaultImgRoute from './assets/defaultrouter.png';

const AttachModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-attach" */
    './modals/attach.vue'
);
const CredentialsModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-credentials" */
    './modals/credentials.vue'
);
const FirmwareModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-firmware" */
    './modals/firmware.vue'
);
const ArchivedCredentialsModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-archived-credentials" */
    './modals/archived-credentials.vue'
);

export default {
    components: {
        MasterSvg,
        DropdownMenu,
        DropdownMenuItem,
        ProviderBadge,
    },
    props: {
        linkequipment: {
            type: Object,
            default: null,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            fetching_status: false,
            routerPicture: defaultImgRoute,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconf',
            'linkequipmentconfoptions',
            'capabilities',
            'firmwareVersionByLinkEquipment',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        synchronize_text() {
            if (!this.linkequipment.is_plugged) {
                return '';
            }
            if (this.linkequipment.synchronized) {
                return this.$gettext('Synchronized');
            }
            return this.$gettext('No synchronized');
        },
        status_text() {
            if (!this.linkequipment.is_plugged) {
                if (this.linkequipment.is_provisioned) {
                    return this.$gettext('Waiting for connection on site');
                }
                return this.$gettext('Never deployed');
            }
            return {
                unknown: this.$gettext('Unknown state'),
                rebooting: this.$gettext('Rebooting'),
                synchronizing: this.$gettext('Synchronizing'),
                unreachable: this.$gettext('Unreachable'),
                up: this.$gettext('Up'),
            }[this.linkequipment.status];
        },
        status_class() {
            return {
                unknown: this.$style.unknown,
                rebooting: this.$style.rebooting,
                synchronizing: this.$style.synchronizing,
                unreachable: this.$style.unreachable,
                up: this.$style.up,
            }[this.linkequipment.status];
        },
        can_manage_router() {
            return this.permissions.find(
                element => element.codename === 'can_manage_router' && element.app_label === 'equipments',
            ) !== undefined;
        },
        firmwareVersion() {
            if (!this.linkequipment._equipment) {
                return null;
            }

            const firmwareVersion = (this.firmwareVersionByLinkEquipment || []).find(
                version => version.link_id === this.linkequipment.access,
            );
            return firmwareVersion;
        },
        firmwareIconStyle() {
            const { firmwareVersion } = this;
            if (firmwareVersion && firmwareVersion.is_allowed) {
                return { name: 'valid', class: this.$style.valid };
            }
            return { name: 'warningflaticon', class: this.$style.warning };
        },
        current_capabilities() {
            return (
                this.capabilities.find(item => item.link_id === this.linkequipment.access) || {}
            ).capabilities || [];
        },
        serial_number() {
            if (!this.linkequipment._equipment.serial_number) {
                return this.$gettext('Not yet connected on site');
            }
            return this.linkequipment._equipment.serial_number;
        },
        canIPv4() {
            return ['dualstack', 'ipv4'].some(
                type => type === this.linkequipment._access.ipstack,
            );
        },
        canIPv6() {
            return ['dualstack', 'ipv6'].some(
                type => type === this.linkequipment._access.ipstack,
            );
        },
    },
    watch: {
        linkequipment: {
            handler() {
                if (
                    this.linkequipment
                    && this.linkequipment._equipment._model.image
                    && this.routerPicture === defaultImgRoute
                ) {
                    this.routerPicture = this.linkequipment._equipment._model.image;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        ...mapActions('equipment', [
            'reboot',
            'download_conf',
            'set_conf_section',
            'fetch_equipment_status',
        ]),
        get_members_list(iplink) {
            let text = `${this.$gettext('List of ip links:')}\u000A\u000A`;
            text += iplink.members.join('\u000A');
            return text;
        },
        downloadConf() {
            this.$eventHub.emit('show-global-loader', true);
            this.set_conf_section({
                check_only: true,
                section: null,
                conf: this.linkequipmentconf,
            }).then(() => {
                this.download_conf({
                    iplink: this.linkequipment._access._iplink,
                    filters: {
                        force_iplink: true,
                        equipment_type: 'router',
                    },
                }).then((response) => {
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const element = document.createElement('a');
                    const filename = fetchValueInDictInsensitiveCase(
                        response.headers,
                        'content-disposition',
                    ).split('filename=')[1];
                    element.setAttribute('href', fileURL);
                    element.setAttribute('download', filename);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }).catch(
                    showSwalError,
                ).finally(() => {
                    this.$nextTick(() => {
                        this.$eventHub.emit('show-global-loader', false);
                    });
                });
            }).catch((error) => {
                showErrorConf(this.$store, error, this.$style.sweeterrors);
                this.$eventHub.emit('show-global-loader', false);
            });
        },
        showDeployConfModal() {
            if (this.linkequipment.discharged) {
                return;
            }

            if (
                !this.linkequipment.is_plugged
                || (
                    !this.linkequipment.synchronized
                    && this.linkequipment.status !== 'synchronizing'
                )
            ) {
                this.set_conf_section({
                    check_only: true,
                    section: null,
                    conf: this.linkequipmentconf,
                }).then(() => {
                    actionsControl(this.linkequipment).then(() => {
                        this.$emit('deploy-configuration', { linkequipment: this.linkequipment });
                    }).catch(showSwalError);
                }).catch((error) => {
                    showErrorConf(this.$store, error, this.$style.sweeterrors);
                });
            } else {
                this.$emit('show-deploy-configuration-modal');
            }
        },
        rebootEquipment() {
            if (!this.has_capability('can_reboot_equipment')) {
                return;
            }

            actionsControl(this.linkequipment).then(() => {
                showSwal({
                    title: this.$gettext('Do you want to reboot the equipment ?'),
                    html: `
                        ${this.$gettext('Rebooting the equipment will cause a service interruption.')}
                        <br>
                        ${this.$gettext('VOICE/DATA access will be temporarily cut off until the equipment is returned to service.')}
                        <br>
                    `,
                    type: 'warning',
                    confirmButtonText: this.$gettext('Yes'),
                    cancelButtonText: this.$gettext('No'),
                    showCancelButton: true,
                    showCloseButton: true,
                }).then((resultSwal) => {
                    if (!resultSwal.value) {
                        return;
                    }
                    this.reboot({
                        force_iplink: this.linkequipment._access.link_id,
                    }).catch(showSwalError);
                });
            }).catch(showSwalError);
        },
        showAttachModal() {
            this.show_modal(
                AttachModal,
                {
                    iplink: this.linkequipment._access._iplink,
                    action: 'update',
                    capabilities: [
                        `can_manage_${this.linkequipment._access.ipstack}`,
                        'can_vrrp',
                    ],
                },
                {
                    maxWidth: Infinity,
                    maxHeight: Infinity,
                },
            );
        },
        showCredentialsModal() {
            this.show_modal(
                CredentialsModal,
                {
                    linkequipment: this.linkequipment,
                },
                {
                    height: 'auto',
                    maxWidth: 480,
                },
            ).then((data) => {
                if (data === 'archivedCredentials') {
                    this.show_modal(
                        ArchivedCredentialsModal,
                        {
                            access: this.linkequipment._access,
                        },
                        {
                            height: 'auto',
                            maxWidth: 820,
                        },
                    );
                }
            });
        },
        showFirmwareModal() {
            const { firmwareVersion } = this;
            if (!firmwareVersion) {
                return;
            }

            let height = 400;
            if (firmwareVersion.is_allowed) {
                height = 350;
            }

            this.show_modal(
                FirmwareModal,
                {
                    'link-id': this.linkequipment.access,
                    'current-firmware': {
                        ...firmwareVersion,
                        recommended_firmware: undefined,
                    },
                    'recommended-firmware': firmwareVersion.recommended_firmware,
                },
                {
                    maxWidth: 610,
                    maxHeight: height,
                },
            );
        },
        has_capability(capability) {
            return this.current_capabilities.includes(capability);
        },
        fetchEquipmentStatus() {
            if (!this.fetching_status) {
                this.fetching_status = true;

                this.fetch_equipment_status({
                    force_iplink: this.linkequipment._access.link_id,
                }).then(() => {
                    this.fetching_status = false;
                }).catch((error) => {
                    this.fetching_status = false;
                    showSwalError(error);
                });
            }
        },
    },
};

</script>
<style lang="scss" module>
.box.vrrp_member {
    min-width: 280px;
    min-height: 140px;
    padding: 20px 30px 40px 30px;

    @include under-to(md) {
        padding: 20px 20px 30px 20px;
    }

    .infos {
        display: flex;
        flex-wrap: wrap;
        height: fit-content;

        .box_info {
            display: flex;
            flex-direction: column;
            width: calc(50% - 25px);
            height: fit-content;
            margin: 15px 10px 0 15px;

            @include themify($themes) {
                border-bottom: 1px solid themed('grayscale_extralight');

                @include under-to(md) {
                    border-bottom: 0;
                }
            }

            &:last-child,
            &:nth-last-child(2):nth-child(odd) {
                border-bottom: none;

                @include respond-from(md) {
                    .info_value {
                        margin-bottom: 0;
                    }
                }
            }

            &.main_box {
                justify-content: center;
                height: 105px;
                margin-bottom: 20px;
                border-bottom: none;

                @include under-to(md) {
                    height: 100%;
                }
            }

            .info_name {
                margin-bottom: 15px 0 3px 0;
                font-size: 12px;
                line-height: 1.33;

                @include under-to(md) {
                    width: 50%;
                }
            }

            .info_bigname {
                font-size: 16px;
                font-weight: 500;
                line-height: 1;

                @include under-to(md) {
                    font-size: 18px;
                }
            }

            .provider_badge {
                margin-top: 16px;
            }

            .info_value {
                margin-top: 3px;
                margin-bottom: 15px;
                font-size: 12px;
                font-weight: 300;
                line-height: 1.33;
                color: #545454;

                @include under-to(md) {
                    width: 50%;
                    margin-top: 0;
                }
            }

            .info_bigvalue {
                margin-top: 3px;
                font-size: 14px;
                font-weight: 300;
                line-height: 1.14;

                @include under-to(md) {
                    margin-top: 0;
                    font-size: 16px;
                }
            }

            .version {
                cursor: pointer;

                @include themify($themes) {
                    color: themed('primary_color');
                }

                &:hover {
                    text-decoration: underline;
                }

                svg {
                    margin-left: 4px;
                }

                .warning {
                    @include themify($themes) {
                        fill: themed('warning_color');
                    }
                }

                .valid {
                    @include themify($themes) {
                        fill: themed('success_color');
                    }
                }
            }
        }
    }

    .equipment_picture_background {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 105px;
        border-radius: 4px;

        @include themify($themes) {
            background: themed('bluescale_extralight');
        }

        .equipment_picture {
            align-items: center;
            justify-content: flex-start;
            width: 80%;
            height: 80%;
            background-repeat: no-repeat;
            background-position: center;
            background-origin: content-box;
            background-size: contain;
        }
    }

    .members_list {
        display: inline-flex;
        margin-left: 5px;

        svg {
            width: 15px;
            height: 15px;
            margin-left: 5px;
        }

        &:hover .flyinginfo:after,
        &:hover .flyinginfo:before {
            display: block;
            margin-bottom: -2px;
            transition: 0.3s ease all;
        }

        .flyinginfo {
            position: relative;
            display: flex;
            align-items: center;
            width: 0;
        }

        .flyinginfo:before {
            position: absolute;
            top: calc(100% + 5px);
            left: calc(50% - 9px);
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        .flyinginfo:after {
            position: absolute;
            top: calc(100% + 10px);
            left: calc(50% - 9px);
            z-index: 4;
            display: none;
            width: max-content;
            width: intrinsic;
            padding: 10px 20px;
            font-family: 'Orkney', sans-serif;
            font-size: 10px;
            line-height: 1.57;
            color: black;
            text-align: center;
            white-space: pre;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            transition: 0.3s ease all;
            transform: translateX(-50%);

            @include themify($themes) {
                box-shadow: 0 0 12px 0 rgba(themed('primary_color'), 0.4);
            }
        }
    }

    .header_member {
        display: flex;
        padding: 20px 30px 15px 30px;
        margin: -20px -30px 0 -30px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        @include themify($themes) {
            background-color: themed('main_secondary_color');
        }

        @include under-to(md) {
            margin: -20px -20px 20px -20px;
        }

        .name {
            flex-grow: 1;
            font-size: 20px;
            font-weight: 500;
            color: white;
            letter-spacing: -0.3px;
        }

        .status {
            font-size: 14px;
            font-weight: normal;
            color: white;
            letter-spacing: -0.44px;

            .synchronize_text {
                margin-left: 30px;
                font-weight: 200;
            }

            .refresh_status {
                display: flex;
                align-items: center;
                cursor: pointer;

                &.active {
                    cursor: wait;

                    svg {
                        animation: rotating 2s linear infinite;
                    }
                }

                svg {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                    fill: white;
                }
            }
        }

        .statusbullet {
            position: relative;
            vertical-align: middle;

            &:after {
                position: absolute;
                top: 50%;
                width: 7px;
                height: 7px;
                margin-left: 8px;
                content: ' ';
                border-radius: 50%;
                transform: translateY(-50%);
            }

            &.unknown,
            &.unreachable {
                &:after {
                    @include themify($themes) {
                        background-color: themed('error_color');
                    }
                }
            }

            &.synchronized,
            &.up,
            &.synchronizing {
                &:after {
                    @include themify($themes) {
                        background-color: themed('success_color');
                    }
                }
            }

            &.rebooting {
                &:after {
                    @include themify($themes) {
                        background-color: themed('warning_color');
                    }
                }
            }

            &.rebooting,
            &.synchronizing {
                &:after {
                    transition: 1s ease all;
                    animation: blinker 2s linear infinite;
                }
            }
        }
    }

    .info {
        align-items: center;
        margin-top: 17px;

        .body3 {
            font-size: 18px;
        }
    }

    .value {
        margin-top: 5px;

        h3 {
            font-weight: 500;
        }
    }
}
</style>
