<template>
    <div
        :class="[
            $style.prettyinputchoice,
            {[$style.disabled]: disabled},
            {[$style.error]: errors},
            {[$style.flyinginfo]: infoTooltips},
        ]"
        :data-content="infoTooltips"
    >
        <div
            :class="[
                $style['pretty'+type],
                {[$style.checked]: checked},
                customClass,
            ]"
            @click="toggleCheck()"
        >
            <div :class="$style.inputwrapper">
                <input
                    :id="name"
                    :type="realType"
                    :disabled="disabled"
                    :checked="checked"
                >
                <label>
                    {{ label_input }}
                </label>
            </div>
        </div>
        <div
            v-if="errors"
            :class="$style.errors"
        >
            <div
                v-for="(error, index) in errors"
                :key="index"
                :class="$style.error"
            >
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Choice',
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        activateLabel: {
            type: String,
            required: false,
            default: null,
        },
        deactivateLabel: {
            type: String,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: true,
            default: null,
            validator(value) {
                return [
                    'checkbox',
                    'switch',
                    'switchText',
                    'switchButton',
                    'selector',
                ].indexOf(value) !== -1;
            },
        },
        name: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
        errors: {
            type: Array,
            default: (() => null),
            required: false,
        },
        infoTooltips: {
            type: String,
            default: '',
        },
        customClass: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            checked: this.value,
        };
    },
    computed: {
        label_input() {
            if (this.type === 'selector') {
                return '';
            }
            if (['switch', 'switchText'].includes(this.type) && this.checked) {
                return this.activateLabel;
            }
            if (['switch', 'switchText'].includes(this.type) && !this.checked) {
                return this.deactivateLabel;
            }

            return this.label;
        },
    },
    watch: {
        value() {
            this.checked = this.value;
        },
        checked(val) {
            this.$emit('input', val);
        },
    },
    created() {
        const checkboxTypes = [
            'selector', 'switch', 'switchText', 'switchButton',
        ];
        if (checkboxTypes.includes(this.type)) {
            this.realType = 'checkbox';
            return;
        }

        this.realType = this.type;
    },
    methods: {
        toggleCheck() {
            if (this.disabled) {
                return;
            }
            this.checked = !this.checked;
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" module>
.prettyinputchoice {
    position: relative;
    display: flex;
    flex-direction: column;

    &:not(.disabled) *:not(.errors):not(.error) {
        cursor: pointer;
    }

    &.disabled *:not(.errors):not(.error) {
        cursor: not-allowed;
    }

    :global(input[type="checkbox"]) {
        width: 15px;
        height: 15px;
        border-radius: 4px;

        @include themify($themes) {
            border: 2px solid themed('grayscale_medium');
        }

        &:global(:checked) {
            @include themify($themes) {
                background-color: themed('primary_color');
            }
        }
    }

    &.error {
        :global(input[type="checkbox"]) {
            @include themify($themes) {
                border: 1px solid themed('error_color');
                box-shadow: 0 0 8px 0 rgba(themed('error_color'), 0.2);
            }
        }

        .errors {
            padding-top: 5px;
            padding-left: 14px;
            font-family: inherit;
            font-size: 0.9em;
            font-style: normal;
            font-weight: 300;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;

            @include themify($themes) {
                color: themed('error_color');
            }
        }
    }

    &:hover {
        &.flyinginfo:after,
        &.flyinginfo:before {
            display: block;
            margin-bottom: -2px;
            transition: 0.3s ease all;
        }
    }

    &.flyinginfo:before {
        position: absolute;
        bottom: calc(100% + 5px);
        left: calc(50% + 6px);
        z-index: 5;
        display: none;
        width: 10px;
        height: 10px;
        content: " ";
        background: white;
        transform: rotate(45deg) translate(-25%, 25%);
    }

    &.flyinginfo:after {
        position: absolute;
        bottom: calc(100% + 10px);
        left: calc(50% + 5px);
        z-index: 4;
        display: none;
        width: max-content;
        max-width: 300px;
        padding: 10px 20px;
        font-family: 'Orkney', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.57;
        color: black;
        text-align: center;
        white-space: pre-wrap;
        content: attr(data-content);
        background-color: white;
        border-radius: 4px;
        transition: 0.3s ease all;
        transform: translateX(-50%);

        @include themify($themes) {
            box-shadow: 0 0 8px 0 rgba(themed('primary_color'), 0.4);
        }
    }
}

.prettycheckbox {
    padding: 10px 14px;
    border-radius: 4px;

    @include themify($themes) {
        background-color: themed('gray_scale_light');
    }

    & + & {
        margin-top: 0;
    }

    .inputwrapper {
        position: relative;
        display: flex;
        margin-top: 0;
    }

    :global(input[type="checkbox"]) + :global(label) {
        flex-shrink: 1;
        margin: 0 0 0 15px;
    }

    :global(label):before,
    :global(label):after {
        position: absolute;
        opacity: 0;
    }

    :global(label):before {
        top: 2px;
        left: 0;
        width: 15px;
        height: 15px;
        content: '';
        border-radius: 4px;

        @include themify($themes) {
            background-color: themed('primary_color');
        }
    }

    :global(label):after {
        top: 0;
        left: 1px;
        width: 15px;
        height: 15px;
        font-weight: bold;
        color: white;
        text-align: center;
        content: "L";
        transform: rotateZ(45deg) rotateY(155deg);
    }

    :global(input[type=checkbox]) {
        flex-grow: 0;
        flex-shrink: 0;
        width: 15px;
        height: 15px;
        appearance: none;
        margin-top: 2px;
        border-radius: 4px;

        @include themify($themes) {
            border: 2px solid themed('grayscale_medium');
        }

        &:after,
        &:before {
            display: none !important;
        }

        &:checked {
            visibility: hidden;

            + :global(label) {
                &:before,
                &:after {
                    opacity: 1;
                }
            }
        }

        &:disabled {
            &,
            & + :global(label):before {
                @include themify($themes) {
                    background-color: themed('gray_scale_dark');
                }
            }

            &:checked + :global(label):after {
                @include themify($themes) {
                    color: themed('gray_scale_normal');
                }
            }
        }
    }

    &.checked {
        @include themify($themes) {
            background-color: themed("tertiary_color");
        }
    }
}

.prettyswitch {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    border-radius: 4px;

    @include themify($themes) {
        border: solid 1px rgba(themed('gray_scale_dark'), 0.1);
    }

    &.checked {
        @include themify($themes) {
            background-color: rgba(themed('success_color'), 0.1);
            border-color: themed('success_color');
        }
    }

    .inputwrapper {
        position: relative;
        display: flex;
        height: 44px;
        margin-top: 0;
    }

    :global(input[type="checkbox"]) {
        display: none;
        width: 0;
        height: 0;
        visibility: hidden;

        &:checked + :global(label) {
            &:before {
                @include themify($themes) {
                    background-color: themed('success_color');
                }
            }

            &:after {
                transform: translateX(12px) translateY(-50%);
            }
        }

        &:after,
        &:before {
            display: none !important;
        }

        &:disabled + label {
            opacity: 0.5;
        }
    }

    :global(label) {
        position: relative;
        align-self: center;
        margin: 0 !important;
        font-size: 14px;
        text-indent: 40px;

        &:after {
            position: absolute;
            top: 50%;
            left: 2px;
            width: 11px;
            height: 11px;
            content: '';
            background-color: white;
            border-radius: 50%;
            transition: 0.3s transform;
            transform: translateY(-50%);
        }

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 27px;
            height: 15px;
            content: '';
            border-radius: 10px;
            transition: 0.3s background-color;
            transform: translateY(-50%);

            @include themify($themes) {
                background-color: themed('gray_scale_dark');
            }
        }
    }
}

.prettyswitchButton {
    width: 100%;
    height: 100%;

    .inputwrapper {
        position: relative;
        display: flex;
        margin-top: 0;
    }

    :global(input[type="checkbox"]) {
        display: none;
        width: 0;
        height: 0;
        visibility: hidden;

        &:checked + :global(label) {
            @include themify($themes) {
                background-color: themed('success_color');
            }

            &:before {
                transform: translateX(12px);
            }
        }

        &:after,
        &:before {
            display: none !important;
        }

        &:disabled + label {
            opacity: 0.5;
        }
    }

    :global(label) {
        position: relative;
        align-self: center;
        width: 27px;
        height: 15px;
        border-radius: 10px;
        transition: 0.3s background-color;

        @include themify($themes) {
            background-color: themed('gray_scale_dark');
        }

        &:before {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 11px;
            height: 11px;
            content: '';
            background-color: white;
            border-radius: 50%;
            transition: 0.3s transform;
        }
    }
}

.prettyswitchText {
    :global(input[type="checkbox"]) {
        display: none;
        width: 0;
        height: 0;
        visibility: hidden;

        &:checked + :global(label) {
            font-weight: bold;
            color: white;

            @include themify($themes) {
                background-color: themed('success_color');
            }
        }

        & + :global(label) {
            margin: 0;
        }

        &:after,
        &:before {
            display: none !important;
        }

        &:disabled + label {
            font-family: inherit;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #A0A0A0;
            text-align: center;
            letter-spacing: normal;
            background-color: #EBEBEB !important;
            box-shadow: none;
        }
    }

    :global(label) {
        position: relative;
        padding: 6px 20px;
        font-family: inherit;
        font-size: 0.6em;
        font-style: normal;
        font-weight: 500;
        font-stretch: normal;
        line-height: normal;
        color: black;
        text-align: center;
        letter-spacing: normal;
        background-color: white;
        border-radius: 4px;
        transition: 0.3s background-color;

        @include themify($themes) {
            box-shadow: 0 0 8px 0 themed('quaternary_color');
        }
    }
}

.prettyselector {
    :global(label) {
        &:before {
            top: 3px;
            left: 2px;
            width: 11px;
            height: 11px;
            color: black;
            background-color: currentColor;
            border: 2px solid white;
            border-radius: 50%;
        }
    }

    :global(input[type="checkbox"]) {
        width: 22px;
        height: 22px;
        color: black;
        border-style: dotted;
        border-radius: 50%;

        @include themify($themes) {
            border-color: themed('primary_color');
        }

        &:after,
        &:before {
            display: none !important;
        }

        &:disabled {
            background-color: white;

            @include themify($themes) {
                border-color: themed('gray_scale_normal');
            }

            & + :global(label):before {
                @include themify($themes) {
                    border-color: themed('gray_scale_normal');
                }
            }
        }

        &:checked {
            opacity: 1;

            + :global(label) {
                &:before {
                    border-width: 0;

                    @include themify($themes) {
                        background-color: themed('primary_color');
                    }
                }

                &:after {
                    top: 2px;
                    left: 6px;
                    width: 15px;
                    height: 15px;
                    font-size: 1.2em;
                    font-weight: bold;
                    color: white;
                    text-align: center;
                    content: "L";
                    opacity: 1;
                    transform: rotateZ(45deg) rotateY(155deg);
                }
            }
        }
    }
}
</style>
