<template>
    <div :class="$style.desktopAndMobileButton">
        <Button
            :role="role"
            :class="$style.desktopButton"
            :disabled="disabled"
            :text="text"
            :icon="icon"
            :size="size"
            @click="$emit('click')"
        />
        <Button
            :role="`icon_${role}`"
            :disabled="disabled"
            :icon="icon"
            :class="$style.mobileButton"
            @click="$emit('click')"
        />
    </div>
</template>

<script>
import Button from '@/components/common/button.vue';

export default {
    name: 'DesktopAndMobileButton',
    components: {
        Button,
    },
    props: {
        role: {
            type: String,
            required: false,
            default: 'primary',
            validator(value) {
                return [
                    'primary',
                    'secondary',
                ].indexOf(value) !== -1;
            },
        },
        text: {
            type: String,
            required: false,
            default: null,
        },
        size: {
            type: String,
            required: false,
            default: null,
            validator(value) {
                return [
                    'small',
                    'large',
                ].indexOf(value) !== -1;
            },
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
.desktopAndMobileButton {
    margin-left: auto;

    .desktopButton {
        min-width: 280px;

        @include under-to(lg) {
            display: none;
        }
    }

    .mobileButton {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1039;
        display: none;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-bottom: 20px;

        @include under-to(lg) {
            display: flex;
        }
    }
}
</style>
