<template>
    <div class="container">
        <Error :error="error" />
    </div>
</template>

<script>
import Error from '@/components/error/error.vue';

export default {
    name: 'PageNotFound',
    components: {
        Error,
    },
    data() {
        return {
            error: {
                code: 404,
                message: this.$gettext('Page not found'),
                description: this.$gettext('We are sorry, but the page you requested was not found.'),
            },
        };
    },
};
</script>

<style lang="scss" module>

</style>
