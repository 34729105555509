<template>
    <div
        :class="[
            $style.prettyinputradio,
            {[$style.error]: errors},
        ]"
    >
        <div>
            <div
                v-for="(choice, index) in choices"
                :key="`${name}-${index}`"
                :class="[
                    {
                        [$style.selected]: choice.value === selected_value,
                        [$style.disabled]: choice.disabled,
                        [customClass]: customClass,
                        [$style.flyinginfo]: choice.info_tooltips,
                    },
                    choice.custom_class,
                    $style.inputwrapper_radio,
                ]"
                :data-content="choice.info_tooltips"
                @click="toggleCheck(choice.id)"
            >
                <label
                    :class="$style.prettyradio_label"
                >
                    <input
                        :id="choice.id"
                        :ref="choice.id"
                        v-model="selected_value"
                        type="radio"
                        :name="name"
                        :value="choice.value"
                        :disabled="choice.disabled"
                    >
                    {{ choice.display_name }}
                </label>
            </div>
        </div>
        <div
            v-if="errors"
            :class="$style.errors"
        >
            <div
                v-for="(error, index) in errors"
                :key="index"
                :class="$style.error"
            >
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Choice',
    props: {
        choices: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: true,
            default: null,
        },
        customClass: {
            type: String,
            required: false,
            default: null,
        },
        value: {
            type: [Number, String],
            required: false,
            default: null,
        },
        errors: {
            type: Array,
            default: (() => null),
            required: false,
        },
    },
    data() {
        return {
            selected_value: this.value,
        };
    },
    watch: {
        value() {
            this.selected_value = this.value;
        },
        selected_value(val) {
            this.$emit('input', val);
        },
    },
    methods: {
        toggleCheck(ref) {
            this.$refs[ref][0].click();
            this.$emit('click', ref);
        },
    },
};
</script>

<style lang="scss" module>
.prettyinputradio {
    display: flex;
    flex-direction: column;

    & .inputwrapper_radio {
        position: relative;
        display: inline-flex;
        width: 110px;
        padding: 10px 14px;
        margin-top: 0;
        margin-right: 15px;
        margin-bottom: 15px;
        border-radius: 4px;

        @include themify($themes) {
            background-color: themed('gray_scale_light');
        }

        &:hover {
            &.flyinginfo:after,
            &.flyinginfo:before {
                display: block;
                margin-bottom: -2px;
                transition: 0.3s ease all;
            }
        }

        &.flyinginfo:before {
            position: absolute;
            bottom: calc(100% + 5px);
            left: calc(50% + 6px);
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        &.flyinginfo:after {
            position: absolute;
            bottom: calc(100% + 10px);
            left: calc(50% + 5px);
            z-index: 4;
            display: none;
            width: max-content;
            max-width: 300px;
            padding: 10px 20px;
            font-family: 'Orkney', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.57;
            color: black;
            text-align: center;
            white-space: pre-wrap;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            transition: 0.3s ease all;
            transform: translateX(-50%);

            @include themify($themes) {
                box-shadow: 0 0 8px 0 rgba(themed('primary_color'), 0.4);
            }
        }

        &.selected {
            @include themify($themes) {
                background-color: themed("tertiary_color");
            }
        }

        &:not(.disabled) {
            cursor: pointer;

            * {
                cursor: pointer;
            }
        }

        .prettyradio_label {
            display: inline-flex;
            flex-shrink: 1;
            align-self: center;
            margin: 0;

            :global(input[type="radio"]) {
                position: relative;
                appearance: none;
                width: 16px;
                height: 16px;
                margin: 1px 15px 0 0;
                border: none;
                outline: none;
                transition: none;

                &:disabled {
                    appearance: none;

                    &:before {
                        @include themify($themes) {
                            background-color: rgba(themed('gray_scale_dark'), 0.6);
                        }
                    }
                }

                &:before,
                &:after {
                    opacity: 0;
                }

                &:before {
                    display: flex;
                    width: 16px;
                    height: 16px;
                    content: '';
                    border-radius: 50%;
                    opacity: 1;

                    @include themify($themes) {
                        border: 2px solid themed('grayscale_medium');
                    }
                }

                &:checked {
                    appearance: none;

                    &:before {
                        background-color: white;

                        @include themify($themes) {
                            border: 2px solid themed('primary_color');
                        }
                    }

                    &:disabled:before {
                        background-color: white;

                        @include themify($themes) {
                            border: 2px solid themed('grayscale_medium');
                        }
                    }

                    &:after {
                        position: absolute;
                        top: 50%;
                        width: 8px;
                        height: 8px;
                        margin-left: 4px;
                        content: '';
                        background-color: white;
                        border: white;
                        border-radius: 50%;
                        opacity: 1;
                        transform: translateY(-50%);

                        @include themify($themes) {
                            background-color: themed('primary_color');
                        }
                    }

                    &:disabled:after {
                        @include themify($themes) {
                            background-color: themed('gray_scale_dark');
                        }
                    }
                }
            }
        }
    }

    &.error {
        :global(input)[type="checkbox"] {
            @include themify($themes) {
                border: 1px solid themed('error_color');
                box-shadow: 0 0 8px 0 rgba(themed('error_color'), 0.2);
            }
        }

        .errors {
            padding-top: 5px;
            padding-left: 14px;
            font-family: inherit;
            font-size: 0.9em;
            font-style: normal;
            font-weight: 300;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;

            @include themify($themes) {
                color: themed('error_color');
            }
        }
    }
}
</style>
