<template>
    <ItemOption :class="$style.slaacConfiguration">
        <template #title>
            <translate>SLAAC Configuration</translate>
        </template>
        <Choice
            v-model="slaac.enabled"
            :activate-label="$gettext('Enabled')"
            :deactivate-label="$gettext('Disabled')"
            name="enableSLAAC"
            :class="$style.enableSlaac"
            type="switch"
        />
        <DnsConfiguration
            :ip-version="ipVersion"
            :primary-dns.sync="slaac.primary_dns"
            :secondary-dns.sync="slaac.secondary_dns"
            :errors="errors"
        />
    </ItemOption>
</template>

<script>
import Choice from '@/components/common/choice.vue';
import ItemOption from './lan-item-option.vue';
import DnsConfiguration from './lan-item-option-dns.vue';

export default {
    components: {
        Choice,
        DnsConfiguration,
        ItemOption,
    },
    props: {
        addressing: {
            type: Object,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ipVersion() {
            return this.addressing.ip_version || 6;
        },
        slaac() {
            return this.addressing.slaac || {};
        },
    },
};
</script>

<style lang="scss" module>
.slaacConfiguration {
    .enableSlaac {
        width: calc(50% - 15px);
    }
}
</style>
