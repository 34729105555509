var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.eventItem }, [
    _c(
      "div",
      {
        class: [
          _vm.$style.header,
          ((_obj = {}),
          (_obj[_vm.$style.scheduledEvent] = _vm.status === "scheduled"),
          (_obj[_vm.$style.failureEvent] = _vm.status === "failure"),
          _obj)
        ]
      },
      [
        _c(
          "div",
          { class: _vm.$style.iconAndTitle },
          [
            _vm.iconStyle
              ? _c("MasterSvg", {
                  attrs: {
                    use: _vm.iconStyle.name,
                    width: _vm.iconStyle.width,
                    height: _vm.iconStyle.height
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { class: _vm.$style.titleAndDatetime }, [
              _c("span", [_vm._v(_vm._s(_vm.actionName))]),
              _vm._v(" "),
              _vm.status === "scheduled" && _vm.formattedEta
                ? _c("span", { class: _vm.$style.datetime }, [
                    _vm._v(
                      "\n                    - " +
                        _vm._s(_vm.formattedEta) +
                        "\n                "
                    )
                  ])
                : _vm._e()
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n            " + _vm._s(_vm.formattedCreatedAt) + "\n        "
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.itemContent },
      [
        _vm.createdBy
          ? _c(
              "span",
              {
                directives: [
                  {
                    name: "translate",
                    rawName: "v-translate",
                    value: { createdBy: _vm.createdBy },
                    expression: "{createdBy}"
                  }
                ]
              },
              [_vm._v("\n            By %{createdBy}\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.actionComment
          ? _c("span", { class: _vm.$style.actionComment }, [
              _vm._v(
                "\n            " + _vm._s(_vm.actionComment) + "\n        "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.errorMessage
          ? _c(
              "Banner",
              {
                attrs: {
                  background: "",
                  icon: "solid-info",
                  type: "error",
                  "can-be-closed": false
                }
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }