var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItemOption",
    {
      class: _vm.$style.slaacConfiguration,
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_c("translate", [_vm._v("SLAAC Configuration")])]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("Choice", {
        class: _vm.$style.enableSlaac,
        attrs: {
          "activate-label": _vm.$gettext("Enabled"),
          "deactivate-label": _vm.$gettext("Disabled"),
          name: "enableSLAAC",
          type: "switch"
        },
        model: {
          value: _vm.slaac.enabled,
          callback: function($$v) {
            _vm.$set(_vm.slaac, "enabled", $$v)
          },
          expression: "slaac.enabled"
        }
      }),
      _vm._v(" "),
      _c("DnsConfiguration", {
        attrs: {
          "ip-version": _vm.ipVersion,
          "primary-dns": _vm.slaac.primary_dns,
          "secondary-dns": _vm.slaac.secondary_dns,
          errors: _vm.errors
        },
        on: {
          "update:primaryDns": function($event) {
            return _vm.$set(_vm.slaac, "primary_dns", $event)
          },
          "update:primary-dns": function($event) {
            return _vm.$set(_vm.slaac, "primary_dns", $event)
          },
          "update:secondaryDns": function($event) {
            return _vm.$set(_vm.slaac, "secondary_dns", $event)
          },
          "update:secondary-dns": function($event) {
            return _vm.$set(_vm.slaac, "secondary_dns", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }