var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.wifiList },
    [
      _vm.wifis.length > 0
        ? _c("WifiListItem", {
            attrs: {
              "can-have-default-credentials": _vm.canHaveDefaultCredentials,
              "can-manage-wps": _vm.canManageWps,
              wifi: _vm.wifis[0],
              "wifi-index": 0,
              errors: _vm.formErrors[0],
              "readonly-mode": _vm.readonlyMode
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.wifis.length > 1
        ? _c("hr", { class: _vm.$style.splitLine })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.wifis.slice(0, -1), function(wifi, index) {
        return _c("WifiListItem", {
          key: "ssid-" + (index + 1),
          attrs: {
            "can-have-default-credentials": _vm.canHaveDefaultCredentials,
            "can-manage-wps": false,
            wifi: _vm.wifis[index + 1],
            "wifi-index": index + 1,
            errors: _vm.formErrors[index + 1],
            "readonly-mode": _vm.readonlyMode
          },
          on: {
            remove: function($event) {
              return _vm.$emit("remove-wifi", index + 1)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }