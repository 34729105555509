<template>
    <div
        v-if="show"
        :class="$style.prettyloader"
    >
        <slot
            name="before"
        />
        <div :class="$style.flex_row">
            <MasterSvg
                :use="svg"
                :width="width"
                :height="width"
                :custom-class="$style.loader"
            />
            <slot
                name="next"
            />
        </div>
        <slot
            name="after"
        />
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';

export default {
    name: 'Loader',
    components: {
        MasterSvg,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        width: {
            type: String,
            required: false,
            default: '100%',
        },
        size: {
            type: String,
            required: false,
            default: 'small',
            validator(value) {
                return [
                    'small',
                    'large',
                ].indexOf(value) !== -1;
            },
        },
    },
    data() {
        return {
            svg: this.size === 'large' ? 'large-loader' : 'small-loader',
        };
    },
};
</script>

<style lang="scss" module>
.prettyloader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    text-align: center;

    .flex_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        text-align: center;
    }

    .loader {
        @include themify($themes) {
            color: themed('primary_color');
        }
    }
}
</style>
