<template>
    <div :class="$style.input_number">
        <div :class="$style.number_arrows">
            <a
                :class="$style.btn_up"
                @click="increment()"
            >
                +
            </a>

            <a
                :class="$style.btn_down"
                @click.prevent="decrement()"
            >
                -
            </a>

            <input
                v-model.number="formatted_quantity"
                type="text"
                :maxlength="maxlength"
                autocomplete="off"
                :placeholder="placeholder"
                :class="$style.inputclass"
                @keyup="onKeyup($event)"
                @keydown="onKeydown($event)"
                @blur="onBlur"
            >

            <span
                v-if="symbol"
                :class="$style.number_symbol"
            >
                {{ symbol }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            default: 1,
        },
        isIntegerValue: {
            type: Boolean,
            default: false,
        },
        mousedown: {
            type: Boolean,
            default: false,
        },
        keydown: {
            type: Boolean,
            default: true,
        },
        maxlength: {
            type: Number,
            required: true,
        },
        placeholder: {
            type: String,
            default: null,
        },
        nbDecimals: {
            type: Number,
            required: true,
        },
        symbol: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            clicked: false,
            interval: 0,
            timeout: 0,
            quantity: this.value,
            oldValue: parseFloat(this.value),
            isKeydown: false,
        };
    },
    computed: {
        formatted_quantity: {
            get() {
                if (this.quantity && !this.isIntegerValue && this.nbDecimals) {
                    return this.quantity.toFixed(this.nbDecimals);
                }

                return this.quantity;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        placeholder() {
            this.quantity = !this.placeholder ? this.min : null;
        },
        quantity: {
            handler(val) {
                this.$emit('input', val);
            },
        },
        min(val) {
            if (this.quantity < val) {
                this.quantity = val;
            }
        },
        max(val) {
            this.quantity = this.quantity > val ? val : this.min;
        },
        value() {
            this.quantity = this.value;
        },
    },
    mounted() {
        this.emitChange(true);
    },
    methods: {
        emitChange(init = false) {
            this.oldValue = this.quantity;
            if (init && !this.placeholder) {
                this.quantity = (this.value < this.min) ? this.min : this.value;
            }
            this.$emit('onInputNumberChange', this.quantity, init);
        },
        increment() {
            if (this.quantity == null || Number.isNaN(parseFloat(this.quantity))) {
                this.quantity = this.min;
            } else if (this.quantity < this.max) {
                this.quantity = parseFloat(this.quantity + this.step);

                if (!this.isIntegerValue && this.nbDecimals) {
                    this.quantity = parseFloat(this.quantity.toFixed(this.nbDecimals));
                }
            } else {
                this.quantity = this.max;
            }
        },
        decrement() {
            if (this.quantity == null || Number.isNaN(parseFloat(this.quantity))) {
                this.quantity = this.min;
            } else if (this.quantity > this.min) {
                this.quantity = parseFloat(this.quantity - this.step);

                if (!this.isIntegerValue && this.nbDecimals) {
                    this.quantity = parseFloat(this.quantity.toFixed(this.nbDecimals));
                }
            } else {
                this.quantity = this.min;
            }
        },
        onBlur() {
            if (this.placeholder && !this.quantity) {
                return;
            }
            if (this.quantity.toString().length === 0) {
                this.quantity = this.oldValue;
                return;
            }
            if (this.quantity < this.min) {
                this.quantity = this.min;
            }
            if (this.quantity > this.max) {
                this.quantity = this.max;
            }
        },
        evaluateQuantity() {
            if (this.isKeydown) return;
            if (
                !this.placeholder
                && this.quantity.toString().length > 0
                && this.quantity !== this.oldValue
            ) {
                this.emitChange();
            }
        },
        onKeyup() {
            this.isKeydown = false;
            this.evaluateQuantity();
        },
        onKeydown(e) {
            this.isKeydown = true;
            if (!this.keydown) {
                e.preventDefault();
                return;
            }

            // Up key: Increase the value
            if (e.keyCode === 38) {
                this.increment();
                e.preventDefault();
                return;
            }

            // Down key: Decrease the value
            if (e.keyCode === 40) {
                this.decrement();
                e.preventDefault();
                return;
            }

            // Allow dot key for decimals:
            if (e.keyCode === 110 || (e.shiftKey && e.keyCode === 190)) {
                if (!this.isIntegerValue) {
                    return;
                }
            }

            // Allow these keys only:
            if (
                // Shift + numbers
                (e.shiftKey && (e.keyCode >= 48 && e.keyCode <= 57))

                // Pad numbers
                || (e.keyCode >= 96 && e.keyCode <= 105)

                // Backspace
                || e.keyCode === 8

                // Delete
                || e.keyCode === 46

                // Ctrl + c
                || (e.ctrlKey && e.keyCode === 67)

                // Cmd + c on mac
                || (e.metaKey && e.keyCode === 67)

                // Ctrl + x
                || (e.ctrlKey && e.keyCode === 88)

                // Cmd + x on mac
                || (e.metaKey && e.keyCode === 88)

                // Ctrl + a
                || (e.ctrlKey && e.keyCode === 65)

                // Cmd + a on mac
                || (e.metaKey && e.keyCode === 65)

                // Arrow left
                || e.keyCode === 37

                // Arrow right
                || e.keyCode === 39
            ) {
                return;
            }

            e.preventDefault();
        },
    },
};
</script>

<style lang="scss" module>
    .input_number {
        position: relative;
        display: flex;

        input {
            padding-left: 15px;
            font-size: 16px;
            line-height: 40px;

            &:focus {
                outline: none;
            }
        }

        .number_arrows {
            position: relative;
            width: 100%;

            .btn_up {
                position: absolute;
                right: 0;
                z-index: 1;
                width: 19px;
                height: 20px;
                padding-top: 2px;
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                user-select: none;
                border: 1px solid #EFEFEF;
                border-bottom: none;
                border-top-right-radius: 4px;

                &:hover {
                    cursor: pointer;
                }
            }

            .btn_down {
                position: absolute;
                right: 0;
                z-index: 1;
                width: 19px;
                height: 20px;
                padding-top: 1px;
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                user-select: none;
                border: 1px solid #EFEFEF;
                border-bottom: none;
                border-bottom-right-radius: 4px;
                transform: translate(0, 100%);

                &:hover {
                    cursor: pointer;
                }
            }

            .inputclass {
                position: relative;
                width: 100%;
                height: 40px;
                border: 1px solid #EFEFEF;
                border-radius: 4px;
                box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);
            }

            .number_symbol {
                position: absolute;
                right: 25px;
                font-size: 16px;
                line-height: 45px;

                @include themify($themes) {
                    color: themed('gray_scale_dark');
                }
            }
        }
    }
</style>
