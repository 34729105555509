var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListItem",
    {
      class: _vm.$style.routesTableItem,
      attrs: { breakpoint: "lg" },
      scopedSlots: _vm._u([
        {
          key: "after",
          fn: function() {
            return [
              _c(
                "DropdownMenu",
                { attrs: { position: "right", size: "large" } },
                [
                  _c("DropdownMenuItem", {
                    attrs: {
                      icon: "underline-pen",
                      name: _vm.$gettext("Edit route"),
                      disabled: _vm.disabledActions
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("edit-route")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("DropdownMenuItem", {
                    attrs: {
                      icon: "trash-bin",
                      name: _vm.$gettext("Delete route"),
                      color: "red",
                      disabled: _vm.disabledActions
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("delete-route")
                      }
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("span", { style: { flex: 2, "font-weight": 500 } }, [
        _vm._v("\n        " + _vm._s(_vm.destination) + "\n    ")
      ]),
      _vm._v(" "),
      _c("span", { style: { flex: 2 } }, [
        _vm._v("\n        /" + _vm._s(_vm.netmask) + "\n    ")
      ]),
      _vm._v(" "),
      _c("span", { style: { flex: 2 } }, [
        _vm._v("\n        " + _vm._s(_vm.gateway) + "\n    ")
      ]),
      _vm._v(" "),
      _c("span", [_vm._v("\n        " + _vm._s(_vm.metric) + "\n    ")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }