var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.componentSimpleFilters }, [
    _c(
      "ul",
      { class: _vm.$style.filterChoices },
      [
        _c(
          "li",
          {
            class: [
              ((_obj = {}),
              (_obj[_vm.$style.active] =
                _vm.value == _vm.defaultValue.value || null),
              _obj)
            ],
            on: {
              click: function($event) {
                return _vm.on_selection(_vm.defaultValue.value)
              }
            }
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.defaultValue.label) + "\n        "
            )
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.filterChoices, function(choice) {
          var _obj
          return _c(
            "li",
            {
              key: choice.value,
              class:
                ((_obj = {}),
                (_obj[_vm.$style.active] = _vm.value == choice.value),
                _obj),
              on: {
                click: function($event) {
                  return _vm.on_selection(choice.value)
                }
              }
            },
            [_vm._v("\n            " + _vm._s(choice.label) + "\n        ")]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }