var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.wansection }, [
    _c("div", { class: _vm.$style.wanitem_wrapper }, [
      _c(
        "div",
        { class: _vm.$style.header },
        [
          _c("span", { staticClass: "mb-2 mb-lg-0", class: _vm.$style.body6 }, [
            _vm._v(
              "\n                WAN " +
                _vm._s(_vm.indexWan + 1) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "mb-2 ml-0 mr-3 mr-lg-auto ml-3 mb-lg-0",
              class: _vm.$style.wan_name
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.mainWan.name) +
                  "\n            "
              )
            ]
          ),
          _vm._v(" "),
          _c("Button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDeleteButton,
                expression: "showDeleteButton"
              }
            ],
            class: _vm.$style.b1,
            attrs: {
              role: "link",
              text: _vm.$gettext("Delete this WAN"),
              icon: "error",
              size: "large",
              disabled: _vm.readonlyMode
            },
            on: { click: _vm.remove }
          }),
          _vm._v(" "),
          _c("span", { class: _vm.$style.separate }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.$style.hidearrow,
                ((_obj = {}), (_obj[_vm.$style.bottom] = _vm.collapsed), _obj)
              ],
              on: {
                click: function($event) {
                  return _vm.toggleCollapse()
                }
              }
            },
            [_c("MasterSvg", { attrs: { use: "arrow" } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapsed,
              expression: "!collapsed"
            }
          ],
          class: _vm.$style.wanform
        },
        [
          _vm.showPlaceholderBackup
            ? [
                _c("AccessWanItem", {
                  attrs: {
                    "interfaces-choices": _vm.interfaces_choices,
                    "readonly-mode": _vm.readonlyMode
                  },
                  on: {
                    "add-modem": function($event) {
                      return _vm.$emit("add-modem", _vm.currentRouterModel)
                    }
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.modemLinkequipments.length
            ? _vm._l(_vm.modemLinkequipments, function(modemLinkequipment) {
                return _c("AccessWanItem", {
                  key: modemLinkequipment.access,
                  attrs: {
                    linkequipment: modemLinkequipment,
                    "interfaces-choices": _vm.interfaces_choices,
                    wan: _vm.currentWan(modemLinkequipment.access),
                    router: _vm.currentRouterModel,
                    errors: _vm.current_errors,
                    "readonly-mode": _vm.readonlyMode
                  },
                  on: { reload: _vm.fetchModemLinkequipments }
                })
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.modemLinkequipments.find(function(modem) {
            return modem.access === _vm.mainWan.name
          })
            ? [
                _c("AccessWanItem", {
                  attrs: {
                    "interfaces-choices": _vm.interfaces_choices,
                    wan: _vm.mainWan,
                    router: _vm.currentRouterModel,
                    errors: _vm.current_errors,
                    "readonly-mode": _vm.readonlyMode
                  }
                })
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }