const state = {
    center: { lat: 0, lng: 0 },
    latLng: { lat: 0, lng: 0 },
    zoom: 10,
};

const getters = {
    center: state => state.center,
    latLng: state => state.latLng,
    zoom: state => state.zoom,
};

const mutations = {
    setCenter(state, { lat, lng }) {
        state.center = { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
    setLatLng(state, { lat, lng }) {
        state.latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
    setZoom(state, zoom) {
        state.zoom = parseInt(zoom, 10);
    },
    initPosition(state, { lat, lng, zoom }) {
        state.zoom = parseInt(zoom, 10);
        state.center = { lat: parseFloat(lat), lng: parseFloat(lng) };
        state.latLng = { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
};

const actions = {
    updatePosition({ commit }, latLng) {
        commit('setCenter', latLng);
        commit('setLatLng', latLng);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
