<template>
    <simple-svg
        :src="svg"
        :width="width"
        :height="height"
        :custom-class-name="customClass"
    />
</template>

<script>
export default {
    props: {
        use: {
            type: String,
            default: '',
        },
        module: {
            type: String,
            default: 'common',
        },
        width: {
            type: String,
            default: null,
        },
        height: {
            type: String,
            default: null,
        },
        customClass: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            svg: null,
        };
    },
    watch: {
        use() {
            this.init_svg();
        },
    },
    created() {
        this.init_svg();
    },
    methods: {
        init_svg() {
            const myContext = require.context('@/', true, /\.svg$/);
            let path = `./components/${this.module}`;
            if (this.module === 'common') {
                path = '.';
            }

            this.svg = myContext(`${path}/assets/${this.use}.svg`);
        },
    },
};
</script>
