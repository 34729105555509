<template>
    <Table
        breakpoint="lg"
        :loading="loading"
    >
        <!-- Header -->
        <template #header>
            <translate>Author</translate>
            <translate>Date</translate>
            <translate :style="{ flex: 3 }">
                Comment
            </translate>
        </template>

        <!-- Loader -->
        <template #skeleton-loader>
            <span /> <span /> <span :style="{ flex: 3 }" />
        </template>

        <!-- Content -->
        <ConfsTableItem
            v-for="configuration in configurations"
            :key="configuration.id"
            :comment="configuration.synchronization_comment"
            :created-at="configuration.creation_date"
            :synchronized-by="configuration.synchronized_by"
            :disabled-actions="disabledActions"
            @view-configuration="$emit('view-configuration', configuration)"
            @restore-configuration="$emit('restore-configuration', configuration)"
        />
    </Table>
</template>

<script>
import Table from '../layout/table.vue';

import ConfsTableItem from './confs-table-item.vue';

export default {
    components: {
        ConfsTableItem,
        Table,
    },
    props: {
        configurations: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabledActions: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
