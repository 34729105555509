var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.tableComponent },
    [
      _vm._t("filters"),
      _vm._v(" "),
      _c(
        "ListHeader",
        {
          attrs: { breakpoint: _vm.breakpoint },
          scopedSlots: _vm._u(
            [
              _vm.headerWithAction
                ? {
                    key: "after",
                    fn: function() {
                      return [_c("span", { style: { width: "14px" } })]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _vm.loading
        ? _vm._l(_vm.itemsInSkeleton, function(index) {
            return _c(
              "ListItem",
              {
                key: "skeleton-" + index,
                attrs: { disabled: "", breakpoint: _vm.breakpoint }
              },
              [_vm._t("skeleton-loader")],
              2
            )
          })
        : [
            !_vm.itemsCount && _vm.emptyResultsIcon
              ? _c("div", { class: _vm.$style.emptyResults }, [
                  _c(
                    "div",
                    { class: _vm.$style.emptyResultsPicture },
                    [
                      _c("MasterSvg", {
                        attrs: {
                          use: _vm.emptyResultsIcon,
                          module: _vm.emptyResultsIconModule
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.$style.emptyResultsContent },
                    [
                      _vm._t("empty-title", function() {
                        return [
                          _c("translate", { class: _vm.$style.title }, [
                            _vm._v(
                              "\n                        No result found\n                    "
                            )
                          ])
                        ]
                      }),
                      _vm._v(" "),
                      _vm._t("empty-text", function() {
                        return [
                          _c("translate", [
                            _vm._v(
                              "\n                        Please try again with an other search\n                    "
                            )
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _vm.paginate
              ? _c("PaginateVue", {
                  attrs: {
                    "items-count": _vm.itemsCount,
                    "items-per-page": _vm.itemsPerPage
                  },
                  model: {
                    value: _vm.internalPageNumber,
                    callback: function($$v) {
                      _vm.internalPageNumber = $$v
                    },
                    expression: "internalPageNumber"
                  }
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }