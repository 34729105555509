<!-- eslint-disable max-len -->
<template>
    <div :class="$style.wans_section">
        <Advice :title="section_title">
            <template v-slot:text>
                <p v-translate>
                    WAN is the connection between customer router and operator network.
                </p>
                <p v-translate>
                    xDSL or 4G links require modem, either integrated in the customer router or in separated device between the customer router and operator network.
                </p>
                <p v-translate>
                    If necessary, WAN port can be modified.
                </p>
            </template>
        </Advice>

        <UpdatedSection
            section="wans"
            :disabled="readonlyMode"
        />

        <div>
            <div class="row mt-3 mb-4">
                <Title
                    :title="section_title"
                />
                <Select
                    v-if="isVrrp"
                    v-model="equipment_link_id"
                    :data-list="equipments_list"
                    role="small"
                    class="col align-self-center"
                    :class="$style.equipements_select"
                    name="equipments_list"
                />
            </div>
            <WansList
                :current-equipment-conf="current_equipment_conf"
                :errors="current_errors"
                :readonly-mode="readonlyMode"
            />
            <div
                v-show="is_modified && !readonlyMode"
                :class="$style.validatebuttons"
                class="row p-0 offset-0 col-12 offset-lg-3 col-lg-9 offset-xl-5 col-xl-7"
            >
                <Button
                    role="secondary"
                    :text="$gettext('Cancel')"
                    size="large"
                    :class="[$style.rightbutton, $style.validatebutton]"
                    class="mt-3 mt-lg-0 p-0 col-12 col-lg-5"
                    @click="initEquipmentsConfigurations()"
                />
                <Button
                    role="primary"
                    :text="$gettext('Validate configuration')"
                    size="large"
                    :class="[$style.rightbutton, $style.validatebutton]"
                    class="mt-3 mt-lg-0 p-0 offset-0 col-12 offset-lg-1 col-lg-6"
                    @click="validate()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '@/components/common/button.vue';
import Select from '@/components/common/select.vue';
import { showSwal, showSwalError } from '@/utils/utils';
import { actionsControl } from './utils/utils';
import Advice from './advice.vue';
import WansList from './wans-list.vue';
import Title from './component-title.vue';
import UpdatedSection from './component-updated-section.vue';

export default {
    components: {
        Advice,
        Select,
        WansList,
        Button,
        Title,
        UpdatedSection,
    },
    props: {
        readonlyMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            equipment_link_id: null,
            errors: [],
            equipments: null,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconf',
            'linkequipment',
            'virtual_iplink_members',
        ]),
        section_title() {
            return this.$gettext('WANs configuration');
        },
        current_equipment_conf() {
            if (!this.equipments) {
                return {};
            }
            return this.equipments.find(
                item => item.link_id === this.equipment_link_id,
            ) || {};
        },
        current_errors() {
            if (!this.equipments) {
                return {};
            }
            const indexError = this.equipments.indexOf(
                this.current_equipment_conf,
            );
            return this.errors[indexError] || {};
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        equipments_list() {
            const choices = [];

            if (this.virtual_iplink_members) {
                this.virtual_iplink_members.forEach((item, index) => {
                    choices.push({
                        id: item.iplink,
                        text: this.$gettextInterpolate(
                            this.$gettext('Equipment %{n}'),
                            { n: index + 1 },
                        ),
                        selected: index === 0,
                    });
                });
            }

            return choices;
        },
        is_modified() {
            if (!this.linkequipmentconf) {
                return false;
            }

            return JSON.stringify(
                this.equipments,
            ) !== JSON.stringify(
                this.linkequipmentconf.equipments || [],
            );
        },
    },
    watch: {
        linkequipment: {
            handler() {
                if (!this.linkequipment || this.equipment_link_id) {
                    return;
                }
                this.equipment_link_id = this.linkequipment.access;
            },
            deep: true,
            immediate: true,
        },
        virtual_iplink_members: {
            handler() {
                if (!this.virtual_iplink_members.length || !this.isVrrp) {
                    this.equipment_link_id = this.linkequipment.access;
                    return;
                }
                this.equipment_link_id = this.virtual_iplink_members[0].iplink;
            },
            deep: true,
            immediate: true,
        },
        linkequipmentconf: {
            handler() {
                this.initEquipmentsConfigurations();
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.fetch_linkequipmentconf().catch(showSwalError);
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_linkequipmentconf',
            'set_conf_section',
        ]),
        handleErrors(result) {
            this.errors = result.errors.equipments || [];

            showSwal({
                title: this.$gettext('WAN configuration save failed'),
                type: 'error',
                confirmButtonText: this.$gettext('Edit configuration'),
                showCloseButton: true,
            });
        },
        validate() {
            showSwal({
                title: this.$gettext('Careful you have reassigned the WAN port of the equipment, are you sure of this change?'),
                type: 'warning',
                confirmButtonText: this.$gettext('I confirm'),
                cancelButtonText: this.$gettext('No I’m being careful'),
                showCloseButton: true,
                showCancelButton: true,
                showLoaderOnConfirm: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) {
                    return;
                }

                actionsControl(this.linkequipment).then(() => {
                    this.set_conf_section({
                        section: 'equipments',
                        conf: this.equipments,
                    }).then(
                        this.handleSuccess,
                    ).catch(
                        this.handleErrors,
                    );
                }).catch(showSwalError);
            });
        },
        handleSuccess() {
            showSwal({
                title: this.$gettext('WAN configuration saved'),
                text: this.$gettext('You must deploy configuration in order to apply modifications on equipment'),
                type: 'success',
                showCancelButton: true,
                confirmButtonText: this.$gettext('Deploy now'),
                cancelButtonText: this.$gettext('Later'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) {
                    return;
                }

                this.$emit('deploy-configuration');
            });
        },
        initEquipmentsConfigurations() {
            this.errors = [];
            if (!this.linkequipmentconf) {
                return;
            }
            this.equipments = JSON.parse(JSON.stringify(
                this.linkequipmentconf.equipments || [],
            ));
            this.equipments.forEach(() => {
                this.errors.push({});
            });
        },
    },
};
</script>

<style lang="scss" module>
.wans_section {
    min-height: 800px;

    .equipements_select {
        max-width: 220px;
        font-size: 12px;
    }

    .validatebuttons {
        margin-top: 20px;

        @include under-to(lg) {
            margin: auto;
        }
    }

    .rightbutton {
        min-width: 200px;
    }

    .bottombutton {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1039;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}
</style>
