var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.eventsList },
    [
      _vm.loading
        ? _vm._l(2, function(index) {
            return _c("EventsListLoader", { key: index })
          })
        : _vm.events.length
        ? _c(
            "div",
            { class: _vm.$style.results },
            _vm._l(_vm.events, function(event) {
              return _c(
                "EventsListItem",
                _vm._b(
                  {
                    key: event.created_at,
                    attrs: {
                      "created-by": event.created_by,
                      "created-at": event.created_at,
                      "updated-at": event.updated_at,
                      "error-code": event.error_code,
                      "error-message": event.error_message
                    }
                  },
                  "EventsListItem",
                  Object.assign({}, event),
                  false
                )
              )
            }),
            1
          )
        : _c("ComponentEmpty", {
            attrs: { size: "large" },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ]
                      },
                      [
                        _vm._v(
                          "\n                No event has been realized on this equipment\n            "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
      _vm._v(" "),
      _vm.needPaginate
        ? _c("Paginate", {
            attrs: {
              "items-count": _vm.eventsCount,
              "items-per-page": _vm.eventsPerPage
            },
            on: { "pagination-update": _vm.handlePaginationUpdate },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }