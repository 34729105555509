<template>
    <div :class="$style.wifiList">
        <WifiListItem
            v-if="wifis.length > 0"
            :can-have-default-credentials="canHaveDefaultCredentials"
            :can-manage-wps="canManageWps"
            :wifi="wifis[0]"
            :wifi-index="0"
            :errors="formErrors[0]"
            :readonly-mode="readonlyMode"
        />
        <hr
            v-if="wifis.length > 1"
            :class="$style.splitLine"
        >
        <WifiListItem
            v-for="(wifi, index) in wifis.slice(0, -1)"
            :key="`ssid-${index + 1}`"
            :can-have-default-credentials="canHaveDefaultCredentials"
            :can-manage-wps="false"
            :wifi="wifis[index + 1]"
            :wifi-index="index + 1"
            :errors="formErrors[index + 1]"
            :readonly-mode="readonlyMode"
            @remove="$emit('remove-wifi', index + 1)"
        />
    </div>
</template>

<script>
import WifiListItem from './wifi-list-item.vue';

export default {
    components: {
        WifiListItem,
    },
    props: {
        canHaveDefaultCredentials: {
            type: Boolean,
            default: true,
        },
        canManageWps: {
            type: Boolean,
            default: true,
        },
        formErrors: {
            type: Array,
            required: true,
        },
        readonlyMode: {
            type: Boolean,
            default: false,
        },
        wifis: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" module>
.wifiList {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .splitLine {
        width: 100%;
        margin: 0;
    }
}
</style>
