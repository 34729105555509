<template>
    <div
        v-if="section != 'menu'"
        :class="$style.menu_burger"
    >
        <div
            :class="$style.burger"
            @click="showMenu()"
        >
            <MasterSvg use="burger" />
        </div>
        <div :class="$style.section">
            {{ get_label_section() }}
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import MasterSvg from './master-svg.vue';

export default {
    components: {
        MasterSvg,
    },
    computed: {
        ...mapGetters('equipment', [
            'section',
            'label_section',
        ]),
    },
    methods: {
        ...mapMutations('equipment', [
            'showMenu',
        ]),
        get_label_section() {
            switch (this.section) {
                case 'general':
                    return this.$gettext('General');
                case 'wan':
                    return this.$gettext('WAN');
                case 'lan':
                    return this.$gettext('LAN');
                case 'dmz':
                    return this.$gettext('DMZ');
                case 'nat':
                    return this.$gettext('NAT');
                case 'connectedDevices':
                    return this.$gettext('Connected devices');
                case 'routes':
                    return this.$gettext('Routes');
                case 'firewall':
                    return this.$gettext('Firewall');
                case 'confs':
                    return this.$gettext('Saved configurations');
                case 'events':
                    return this.$gettext('Events');
                case 'advanced':
                    return this.$gettext('Advanced');
                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss" module>
.menu_burger {
    display: flex;
    align-items: center;
    padding: 15px 20px;

    @include themify($themes) {
        background-color: themed('gray_scale_light');
    }

    .burger {
        width: 22px;
        height: 22px;
        content: '';
        transform: rotate(90deg);

        @include themify($themes) {
            fill: themed('primary_color');
        }
    }

    .section {
        margin: auto;
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        font-stretch: normal;
        line-height: normal;
        color: black;
        text-align: center;
        letter-spacing: normal;
    }
}
</style>
