<template>
    <div
        class="container"
        :class="$style.connect"
    >
        <div class="row justify-content-md-center">
            <div class="col col-lg-6">
                <form @submit.prevent="onSubmit">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <div
                                id="basic-addon1"
                                class="input-group-text"
                            >
                                @
                            </div>
                        </div>
                        <input
                            v-model="username"
                            type="text"
                            class="form-control"
                            :placeholder="$gettext('Username')"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        >
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <div
                                id="basic-addon1"
                                class="input-group-text"
                            >
                                •
                            </div>
                        </div>
                        <input
                            v-model="password"
                            type="password"
                            class="form-control"
                            :placeholder="$gettext('Password')"
                            aria-label="Password"
                            aria-describedby="basic-addon1"
                        >
                    </div>

                    <div
                        v-if="error"
                        class="alert alert-danger"
                        role="alert"
                    >
                        {{ error }}
                    </div>


                    <button
                        v-translate
                        class="btn btn-outline-dark my-2 my-sm-0"
                        type="submit"
                    >
                        Connect
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            username: '',
            password: '',
            error: null,
            onSubmit() {
                axios.post('/api/account/login/', { username: this.username, password: this.password })
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        if (error.response.status === 403) {
                            this.error = error.response.data.error;
                        } else {
                            this.error = this.$gettext('Unknown error, please try again');
                        }
                    });
            },
        };
    },
};
</script>

<style lang="scss" module>
.connect:global(.container) {
    display: flex;
    align-items: center;
    height: 100%;

    & > * {
        width: 100%;
    }
}
</style>
