import Vue from 'vue';
import QuestionSvg from '@/assets/ic-question.svg';
import WarningSvg from '@/assets/ic-warning.svg';

export function showSwal(params) {
    if (params.type === 'question') {
        params.type = '';
        params.imageWidth = 75;
        params.imageHeight = 75;
        params.imageUrl = QuestionSvg;
    }
    if (params.type === 'warning') {
        params.type = '';
        params.imageWidth = 75;
        params.imageHeight = 75;
        params.imageUrl = WarningSvg;
    }

    if (Vue.prototype.$swal.isVisible() && Vue.prototype.$swal.getQueueStep() !== null) {
        return Vue.prototype.$swal.insertQueueStep(params);
    }
    return Vue.prototype.$swal.queue([params]);
}

export function cleanDict(conf) {
    Object.entries(conf).forEach(([key, val]) => {
        if (val && typeof val === 'object') cleanDict(val);
        else if (val === null || val === '') delete conf[key];
    });
}

export function setError(type, message) {
    return {
        type,
        errors: message,
    };
}

export function showToast(title, type = 'success', timer = 2000) {
    showSwal({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer,
        title,
        type,
    });
}

export function showSwalError(error, params_swal = {}) {
    let type = 'warning';
    const title = Vue.prototype.$gettext('An error happened');
    let text = '';

    if (!error) {
        return null;
    }

    if (typeof error === 'string') {
        params_swal.type = 'error';
        params_swal.title = title;
        params_swal.text = error;

        return showSwal(params_swal);
    }

    if (error.type && error.type.startsWith('error')) {
        type = 'error';
    }

    if (error.type === 'error_server') {
        if (error.errors.detail) {
            text = error.errors.detail;
        }
        if (error.errors.non_field_errors) {
            error.errors.non_field_errors.forEach((errorText) => {
                text += errorText;
            });
        }
    } else {
        text = error.errors;
    }

    params_swal.type = type;
    params_swal.title = title;
    params_swal.text = text;
    return showSwal(params_swal);
}

export function formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
}

export function retrieveStartEndMonthDate(yyyymm) {
    const month = yyyymm.substring(4, 6) - 1;
    const year = yyyymm.substring(0, 4);
    const firstDate = formatDate(new Date(year, month, 1));
    const lastDate = formatDate(new Date(year, month + 1, 0));
    return [firstDate, lastDate];
}

export function resultToSwalContent(view, result) {
    let nonFieldErrors = null;
    let details = null;
    if (result.details) {
        details = JSON.parse(JSON.stringify(result.details));
    }
    if (details && details.non_field_errors) {
        nonFieldErrors = details.non_field_errors;
        delete (details.non_field_errors);
    }

    let content = '<div class="sweeterrors"><ul>';
    if ([400, 412].includes(result.code)) {
        if (details && Object.keys(details).length) {
            content += `<li>${view.$gettext('Fields errors, check form errors and try again')}</li>`;
        } else if (nonFieldErrors) {
            (nonFieldErrors || []).forEach((error) => {
                content += `<li>${error}</li>`;
            });
        } else {
            content += `<li>${view.$gettext('Server reported bad form values, please check values and try again')}</li>`;
        }
    } else if (details.detail) {
        content += `<li>${details.detail}</li>`;
    } else {
        content += `<li>${view.$gettext('Oops, an unexpected error happened, please contact us for support')}</li>`;
    }

    content += '</ul></div>';
    return content;
}

export function fetchValueInDictInsensitiveCase(headers, key) {
    const validKey = Object.keys(headers).find(
        name => name.toLowerCase() === key.toLowerCase(),
    );
    return (validKey && headers[validKey]) || '';
}

export function isInViewport(element) {
    const distance = element.getBoundingClientRect();
    return (
        distance.top >= 0
        && distance.left >= 0
        && distance.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function isValidateIPaddress(ipaddress) {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress);
}

export function randomAndExclude(list) {
    const randomElem = list[Math.floor(Math.random() * list.length)];
    const elemIndex = list.findIndex(
        element => element === randomElem,
    );
    list.splice(elemIndex, 1);
    return randomElem;
}

export function naturalCompare(a, b) {
    const ax = [];
    const bx = [];

    a.replace(/(\d+)|(\D+)/g, (_, x, y) => {
        ax.push([x || Infinity, y || '']);
    });
    b.replace(/(\d+)|(\D+)/g, (_, x, y) => {
        bx.push([x || Infinity, y || '']);
    });

    while (ax.length && bx.length) {
        const an = ax.shift();
        const bn = bx.shift();
        const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if (nn) return nn;
    }

    return ax.length - bx.length;
}

export function rioValidationRules(rio, phoneNumber) {
    return new Promise((resolve, reject) => {
        if (!RegExp(/^([0-9A-Za-z+]{12})$/).test(rio)) {
            reject(setError(
                'warning',
                Vue.prototype.$gettext('IOR number field should be composed of 12 elements'),
            ));
        }

        const concat = rio.substr(0, 9) + phoneNumber;

        const encodingChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+';

        let a = 0;
        let b = 0;
        let c = 0;

        for (let i = 0; i < concat.length; i += 1) {
            const pos = encodingChars.indexOf(concat[i]);

            a = (a + pos) % 37;
            b = (b * 2 + pos) % 37;
            c = (c * 4 + pos) % 37;
        }

        const keyValue = encodingChars[a] + encodingChars[b] + encodingChars[c];

        if (keyValue !== rio.substr(9, 12)) {
            reject(setError(
                'warning',
                Vue.prototype.$gettext('IOR number validation algorithm failed'),
            ));
        }
        resolve();
    });
}

export function siretValidationRules(siret) {
    return new Promise((resolve, reject) => {
        const exception = '356000000'; // La Poste group exception

        if (!RegExp(/^([0-9]{14})$/).test(siret)) {
            reject(setError(
                'warning',
                Vue.prototype.$gettext('SIRET number field should be composed of 14 digits'),
            ));
        }

        if (!siret.startsWith(exception)) {
            const len = siret.length;
            const parity = len % 2;

            let sum = 0;
            for (let i = len - 1; i >= 0; i -= 1) {
                let d = parseInt(siret.charAt(i), 10);
                if (i % 2 === parity) { d *= 2; }
                if (d > 9) { d -= 9; }
                sum += d;
            }

            if ((sum % 10) !== 0) {
                reject(setError(
                    'warning',
                    Vue.prototype.$gettext('SIRET number validation algorithm failed'),
                ));
            }
        }

        resolve();
    });
}

export function getImpactedIpLinks(virtualIplinkMembers, linkEquipment = null, ipLink = null) {
    const ipLinks = [];
    if (virtualIplinkMembers.length === 0) {
        ipLinks.push(ipLink || linkEquipment._access);
    } else {
        virtualIplinkMembers.forEach((member) => {
            ipLinks.push(member._iplink);
        });
    }
    return ipLinks;
}
