var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        ((_obj = {}),
        (_obj[_vm.$style.disabled_iface] =
          _vm.iface.type === "wan" && !_vm.canManageWan),
        _obj),
        _vm.$style.generalPortsItem
      ]
    },
    [
      _c("div", { class: _vm.$style.ifacename }, [
        _vm._v("\n        " + _vm._s(_vm.iface.name) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: [_vm.$style.interface_description, _vm.$style.body1] },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.iface.description,
                expression: "iface.description"
              }
            ],
            class: _vm.$style.description_input,
            attrs: {
              id: "description_" + _vm.iface.port_number,
              type: "text",
              name: "description"
            },
            domProps: { value: _vm.iface.description },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.iface, "description", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              class: _vm.$style.description_svg,
              attrs: { for: "description_" + _vm.iface.port_number }
            },
            [_c("MasterSvg", { attrs: { use: "underline-pen" } })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [_vm.$style.speed, _vm.$style.noselect, _vm.$style.body1],
          style: { flex: 2 },
          on: {
            click: function($event) {
              return _vm.changeSpeed()
            }
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.speedName) + "\n        "),
          _vm.loading
            ? _c("Loader", { attrs: { show: _vm.loading, width: "16px" } })
            : _vm.iface.link_active
            ? _c(
                "Tooltip",
                {
                  class:
                    ((_obj$1 = {}),
                    (_obj$1[_vm.$style.halfDuplex] =
                      _vm.iface.negotiated_duplex === "half"),
                    _obj$1),
                  attrs: { content: _vm.displayNegociatedInterface }
                },
                [_c("MasterSvg", { attrs: { use: "info" } })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.$style.body1,
            _vm.$style.lan_list,
            ((_obj$2 = {}),
            (_obj$2[_vm.$style.wan] = _vm.iface.type === "wan"),
            (_obj$2[_vm.$style.lan_list_infos] = _vm.lanAndDmzList.length > 0),
            _obj$2)
          ],
          on: {
            click: function($event) {
              return _vm.showLans()
            }
          }
        },
        [
          _vm.iface.type !== "wan"
            ? _c("span", [
                _vm._v("\n            " + _vm._s(_vm.accessType) + "\n        ")
              ])
            : _c("span", [_c("b", [_vm._v(_vm._s(_vm.wanName))])]),
          _vm._v(" "),
          _vm.lanAndDmzList.length > 0
            ? _c(
                "Tooltip",
                {
                  class: _vm.$style.icon,
                  attrs: { content: _vm.displayLanslist }
                },
                [_c("MasterSvg", { attrs: { use: "info" } })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.containerSwitch },
        [
          _c(
            "Tooltip",
            {
              class: _vm.$style.container_button,
              attrs: { content: _vm.statusInterface }
            },
            [
              _c("Choice", {
                class: _vm.$style.port_button,
                attrs: {
                  disabled: _vm.iface.type === "wan",
                  name: "enable_port",
                  type: "switchButton"
                },
                model: {
                  value: _vm.iface.enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.iface, "enabled", $$v)
                  },
                  expression: "iface.enabled"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }