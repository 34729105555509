<template>
    <ListItem
        :class="$style.routesTableItem"
        breakpoint="lg"
    >
        <span :style="{ flex: 2, 'font-weight': 500 }">
            {{ destination }}
        </span>
        <span :style="{ flex: 2 }">
            /{{ netmask }}
        </span>
        <span :style="{ flex: 2 }">
            {{ gateway }}
        </span>
        <span>
            {{ metric }}
        </span>

        <template #after>
            <DropdownMenu
                position="right"
                size="large"
            >
                <DropdownMenuItem
                    icon="underline-pen"
                    :name="$gettext('Edit route')"
                    :disabled="disabledActions"
                    @click="$emit('edit-route')"
                />
                <DropdownMenuItem
                    icon="trash-bin"
                    :name="$gettext('Delete route')"
                    color="red"
                    :disabled="disabledActions"
                    @click="$emit('delete-route')"
                />
            </DropdownMenu>
        </template>
    </ListItem>
</template>

<script>
import ListItem from '@/components/common/list-item.vue';

import DropdownMenu from '../dropdown-menu.vue';
import DropdownMenuItem from '../dropdown-menu-item.vue';

export default {
    components: {
        DropdownMenu,
        DropdownMenuItem,
        ListItem,
    },
    props: {
        destination: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        gateway: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
        metric: {
            default: null,
            validator: item => typeof item === 'number' || item === null,
        },
        netmask: {
            default: null,
            validator: item => typeof item === 'number' || item === null,
        },
        disabledActions: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
