<template>
    <div
        :class="cssWrapperClasses"
        @click="handleClick"
        @mouseleave="handleMouseLeave"
        @mouseover="handleMouseOver"
    >
        <slot name="before" />

        <div :class="[$style.listItemContent, customContentClass]">
            <slot name="default">
                <span
                    v-for="index in columnNumber"
                    :key="index"
                />
            </slot>
        </div>
        <div :class="$style.after">
            <slot name="after" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListItem',
    props: {
        bordered: {
            type: Boolean,
            default: false,
        },
        highlighted: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        breakpoint: {
            type: String,
            default: 'sm',
            validator(value) {
                return ['xs', 'sm', 'md', 'lg', 'xl'].includes(value);
            },
        },
        customContentClass: {
            type: [String, Array, Object],
            default: null,
        },
        customWrapperClass: {
            type: [String, Array, Object],
            default: null,
        },
        columnNumber: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        cssWrapperClasses() {
            const {
                bordered,
                breakpoint,
                disabled,
                highlighted,
                clickable,
                customWrapperClass,
                $style,
            } = this;
            return [
                $style.listItem,
                {
                    [$style[breakpoint]]: breakpoint,
                    [$style.border]: bordered,
                    [$style.highlight]: highlighted,
                    [$style.clickable]: clickable,
                    [$style.disabled]: disabled,
                },
                customWrapperClass,
            ];
        },
    },
    methods: {
        handleClick(event) {
            if (!this.clickable) return;
            this.$emit('click', event);
        },
        handleMouseLeave(event) {
            this.$emit('mouseleave', event);
        },
        handleMouseOver(event) {
            this.$emit('mouseover', event);
        },
    },
};
</script>

<style lang="scss" module>
@import '@/assets/css/_skeleton.scss';

.listItem {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 60px;
    padding: 10px;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    border: 1px solid transparent;
    border-radius: 4px;

    @include themify($themes) {
        box-shadow: 0 0 8px 0 rgba(themed('secondary_color'), 0.3);
    }

    & .after {
        display: flex;
        margin-right: 10px;
    }

    &.clickable {
        cursor: pointer;
    }

    &.border {
        @include themify($themes) {
            border: 1px solid themed('primary_color');
        }
    }

    &.highlight {
        @include themify($themes) {
            border-left: 4px solid themed('primary_color');
        }
    }

    &.disabled {
        @include themify($themes) {
            box-shadow: 0 0 8px 0 themed("grayscale_light");
        }
    }

    .listItemContent {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;
        min-width: 0;
        margin: 10px;

        & > * {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:empty {
                @extend .skeleton;

                height: 25px;
            }
        }
    }

    @mixin resized {
        &.listItem {
            position: relative;
            flex-direction: column;
            align-items: flex-end;
            max-height: 100%;
            padding-left: 30px;

            .listItemContent {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        & .after {
            position: absolute;
            top: 20px;
            left: calc(100% - 25px);
        }
    }

    &.xs {
        @include under-to(xs) {
            @include resized;
        }
    }

    &.sm {
        @include under-to(sm) {
            @include resized;
        }
    }

    &.md {
        @include under-to(md) {
            @include resized;
        }
    }

    &.lg {
        @include under-to(lg) {
            @include resized;
        }
    }

    &.xl {
        @include under-to(xl) {
            @include resized;
        }
    }
}
</style>
