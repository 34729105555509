<template>
    <ItemOption :class="$style.dhcpConfiguration">
        <template #title>
            <translate>DHCP Configuration</translate>
        </template>
        <div :class="$style.fieldsForm">
            <Choice
                v-model="dhcp.enabled"
                :disabled="disabled"
                :activate-label="$gettext('Enabled')"
                :deactivate-label="$gettext('Disabled')"
                name="enable_dhcp"
                type="switch"
            />
            <Select
                v-model="dhcp.type"
                :disabled="disabled"
                :errors="formErrors.type"
                :data-list="dhcp_type_choices"
                :label="$gettext('Type')"
                :required="true"
                name="type"
            />
        </div>
        <DnsConfiguration
            v-if="show_server_fields && ipVersion === 4"
            :disabled="disabled"
            :ip-version="ipVersion"
            :primary-dns.sync="dhcp.primary_dns"
            :secondary-dns.sync="dhcp.secondary_dns"
            :errors="formErrors"
        />
        <div
            v-if="show_relay_fields"
            :class="$style.body4"
        >
            <translate>
                Relay server
            </translate>
        </div>
        <Field
            v-if="show_relay_fields"
            v-model="dhcp.primary_relay_server_ip"
            :disabled="disabled"
            :errors="formErrors.primary_relay_server_ip"
            :label="$gettext('Primary relay server')"
            :required="true"
            name="primary_relay_server_ip"
        />
        <Field
            v-if="show_relay_fields"
            v-model="dhcp.secondary_relay_server_ip"
            :disabled="disabled"
            :errors="formErrors.secondary_relay_server_ip"
            :label="$gettext('Secondary relay server')"
            name="secondary_relay_server_ip"
        />
        <div
            v-if="show_server_fields"
            :class="$style.body4"
        >
            <translate>
                Lease duration
            </translate>
        </div>
        <Field
            v-if="show_server_fields"
            v-model="dhcp.lease_duration"
            :disabled="disabled"
            :errors="formErrors.lease_duration"
            :label="$gettext('Lease duration')"
            :class="$style.halfField"
            name="lease_duration"
        />
        <div
            v-if="show_server_fields"
            :class="$style.subtitle"
        >
            <div>
                <span :class="$style.body4">
                    <translate>Address ranges</translate>
                    ({{ max_dhcp_ranges }}/{{ nb_max_address_ranges_dhcp }})
                </span>
            </div>
            <Button
                v-show="max_dhcp_ranges"
                role="link"
                :text="$gettext('Add address range')"
                size="large"
                :disabled="max_dhcp_ranges >= nb_max_address_ranges_dhcp || disabled"
                :class="[$style.b1, $style.additembutton]"
                icon="add"
                @click="addAddressRange()"
            />
        </div>
        <AddressRangeList
            v-if="show_server_fields"
            :disabled="disabled"
            :addresses="dhcp.address_ranges || []"
            :errors="formErrors.address_ranges || []"
            @addFirstAddressRange="addAddressRange()"
            @removeAddressRange="removeAddressRange"
        />
        <div
            v-if="formErrors.address_ranges_errors"
            :class="$style.errors"
        >
            <div
                v-for="(error, index) in formErrors.address_ranges_errors"
                :key="index"
                :class="$style.error"
            >
                {{ error }}
            </div>
        </div>
        <div
            v-if="show_server_fields"
            :class="$style.subtitle"
        >
            <div>
                <span :class="$style.body4">
                    <translate>Static addresses</translate>
                    ({{ nb_static_addresses_dhcp }})
                </span>
            </div>
            <Button
                v-show="nb_static_addresses_dhcp"
                role="link"
                :disabled="disabled"
                :text="$gettext('Add static address')"
                size="large"
                :class="[$style.b1, $style.additembutton]"
                icon="add"
                @click="addStaticAddress()"
            />
        </div>
        <StaticAddressList
            v-if="show_server_fields"
            :disabled="disabled"
            :addresses="dhcp.static_addresses || []"
            :errors="formErrors.static_addresses || []"
            @addFirstStaticAddress="addStaticAddress()"
            @removeStaticAddress="removeStaticAddress"
        />
        <div
            v-if="formErrors.static_addresses_errors"
            :class="$style.errors"
        >
            <div
                v-for="(error, index) in formErrors.static_addresses_errors"
                :key="index"
                :class="$style.error"
            >
                {{ error }}
            </div>
        </div>
    </ItemOption>
</template>

<script>
import {
    getFirstIPFromNetwork,
    getLastIPFromNetwork,
    generateNetworkFromMasterNetwork,
} from '@/utils/network';

import { mapGetters } from 'vuex';
import Field from '@/components/common/field.vue';
import Choice from '@/components/common/choice.vue';
import Select from '@/components/common/select.vue';
import Button from '@/components/common/button.vue';
import DnsConfiguration from './lan-item-option-dns.vue';
import AddressRangeList from './lan-item-address-range-list.vue';
import StaticAddressList from './lan-item-static-address-list.vue';
import ItemOption from './lan-item-option.vue';

export default {
    components: {
        Field,
        Choice,
        Select,
        Button,
        DnsConfiguration,
        AddressRangeList,
        StaticAddressList,
        ItemOption,
    },
    props: {
        addressing: {
            type: Object,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            nb_max_address_ranges_dhcp: 0,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'linkequipment',
            'linkequipmentconfoptions',
        ]),
        dhcp() {
            return (this.addressing || {}).dhcp || {};
        },
        ipVersion() {
            return (this.addressing || {}).ip_version;
        },
        isMpls() {
            return this.linkequipment._access._iplink.mpls;
        },
        show_server_fields() {
            return this.dhcp.type === 'server';
        },
        show_relay_fields() {
            return this.dhcp.type === 'relay';
        },
        formErrors() {
            return this.errors || {};
        },
        dhcp_type_choices() {
            const choices = [];

            if (this.linkequipmentconfoptions && this.linkequipmentconfoptions.actions) {
                this.linkequipmentconfoptions.actions.PUT.lans
                    .child.children.addressing.child.children.dhcp.children.type.choices
                    .forEach((item) => {
                        if (!this.isMpls && item.value === 'relay') {
                            return;
                        }
                        choices.push({
                            id: item.value,
                            text: item.display_name,
                        });
                    });
            }

            return choices;
        },
        max_dhcp_ranges() {
            if (this.dhcp && this.dhcp.address_ranges) {
                return this.dhcp.address_ranges.length;
            }
            return 0;
        },
        nb_static_addresses_dhcp() {
            if (this.dhcp && this.dhcp.static_addresses) {
                return this.dhcp.static_addresses.length;
            }
            return 0;
        },
    },
    watch: {
        dhcp: {
            handler() {
                if (!this.dhcp) {
                    return;
                }

                if (!this.dhcp.type) {
                    if (this.dhcp_type_choices.length === 1) {
                        this.dhcp.type = this.dhcp_type_choices[0].id;
                    }
                }
            },
            deep: true,
            immediate: true,
        },
        'dhcp.enabled': {
            handler(value) {
                if (!value || this.show_relay_fields) {
                    return;
                }
                if (value && !(this.dhcp.address_ranges || []).length) {
                    this.dhcp.address_ranges = [
                        this.generateAddressRanges(0),
                    ];
                }
            },
        },
        'dhcp.primary_relay_server_ip': {
            handler(value) {
                if (this.show_server_fields) {
                    return;
                }
                if (value && !this.dhcp.enabled) {
                    this.dhcp.enabled = true;
                } else if (!value && this.dhcp.enabled) {
                    this.dhcp.enabled = false;
                }
            },
        },
        'dhcp.address_ranges': {
            handler(value) {
                if (this.show_relay_fields) {
                    return;
                }
                if (!value) {
                    this.dhcp.enabled = false;
                    return;
                }
                if (value.length && !this.dhcp.enabled) {
                    this.dhcp.enabled = true;
                } else if (!value.length && this.dhcp.enabled) {
                    this.dhcp.enabled = false;
                }
            },
        },
        'dhcp.static_addresses': {
            handler(value) {
                if (this.show_relay_fields || !value) {
                    return;
                }
                if (value.length && !this.dhcp.enabled) {
                    this.dhcp.enabled = true;
                } else if (
                    !value.length
                    && !(this.dhcp.address_ranges || []).length
                    && this.dhcp.enabled
                ) {
                    this.dhcp.enabled = false;
                }
            },
        },
        linkequipmentconfoptions: {
            handler(linkequipmentconfoptions) {
                if (!linkequipmentconfoptions) {
                    return;
                }

                this.nb_max_address_ranges_dhcp = linkequipmentconfoptions.max_dhcp_ranges;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        toggleDhcp() {
            this.dhcp.enabled = !this.dhcp.enabled;
        },
        addStaticAddress() {
            if (!this.dhcp.static_addresses) {
                this.dhcp.static_addresses = [];
            }
            this.dhcp.static_addresses.unshift({});
        },
        removeStaticAddress(index) {
            this.dhcp.static_addresses.splice(index, 1);
        },
        addAddressRange() {
            if (!this.dhcp.address_ranges) {
                this.dhcp.address_ranges = [];
            }
            this.dhcp.address_ranges.unshift(
                this.generateAddressRanges(this.dhcp.address_ranges.length),
            );
        },
        between(val, min, max) {
            return val >= min && val <= max;
        },
        generateAddressRanges(index) {
            if (this.ipVersion === 4) {
                return {};
            }
            const ipData = this.addressing;
            const staticAddressParts = generateNetworkFromMasterNetwork(
                // eslint-disable-next-line no-undef
                `${ipData.ip}/${ipData.netmask}`, this.ipVersion, 112, [], BigInt(index) + 1n,
            );
            return {
                start_ip_address: getFirstIPFromNetwork(staticAddressParts, false),
                end_ip_address: getLastIPFromNetwork(staticAddressParts, false),
            };
        },
        ipToNum(ip) {
            if (!ip) {
                return 0;
            }

            return Number(
                ip.split('.')
                    .map(d => (`000${d}`).substr(-3))
                    .join(''),
            );
        },
        removeAddressRange(index) {
            const addressRange = this.dhcp.address_ranges[index];

            if (this.dhcp.static_addresses && this.dhcp.static_addresses.length) {
                this.dhcp.static_addresses.slice().reverse().forEach(
                    (staticAddress, indexStaticAddress, staticAddresses) => {
                        const inRange = this.between(
                            this.ipToNum(staticAddress.ip),
                            this.ipToNum(addressRange.start_ip_address),
                            this.ipToNum(addressRange.end_ip_address),
                        );

                        if (inRange) {
                            this.removeStaticAddress(
                                staticAddresses.length - 1 - indexStaticAddress,
                            );
                        }
                    },
                );
            }

            this.dhcp.address_ranges.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" module>
.dhcpConfiguration {
    .fieldsForm {
        display: flex;
        gap: 30px;

        & > * {
            flex: 1;
        }
    }

    .halfField {
        width: calc(50% - 15px);
    }

    .subtitle {
        display: flex;
        margin-top: 8px;
    }

    .additembutton {
        align-self: flex-end;
        max-width: fit-content;
        margin-left: auto;
    }
}
</style>
