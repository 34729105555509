var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: [_vm.$style.box, _vm.$style.vrrp_member] }, [
      _c(
        "div",
        { class: _vm.$style.header_member },
        [
          _c("div", { class: _vm.$style.name }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "translate",
                    rawName: "v-translate",
                    value: { n: _vm.index },
                    expression: "{n: index}"
                  }
                ]
              },
              [_vm._v("\n                    Equipment %{n}\n                ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex", class: _vm.$style.status }, [
              _vm.linkequipment.is_plugged
                ? _c(
                    "div",
                    {
                      class: [
                        _vm.$style.refresh_status,
                        ((_obj = {}),
                        (_obj[_vm.$style.active] = _vm.fetching_status),
                        _obj)
                      ],
                      on: {
                        click: function($event) {
                          return _vm.fetchEquipmentStatus()
                        }
                      }
                    },
                    [
                      _c("MasterSvg", {
                        attrs: { use: "refresh", width: "15px", height: "15px" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { class: [_vm.$style.statusbullet, _vm.status_class] },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.status_text) +
                      "\n                    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", { class: _vm.$style.synchronize_text }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.synchronize_text) +
                    "\n                    "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "DropdownMenu",
            { attrs: { color: "white", position: "right", size: "large" } },
            [
              _vm.can_manage_router
                ? _c("DropdownMenuItem", {
                    attrs: { icon: "key", name: _vm.$gettext("Credentials") },
                    on: {
                      click: function($event) {
                        return _vm.showCredentialsModal()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.linkequipment.discharged &&
              _vm.linkequipment.is_plugged &&
              _vm.has_capability("can_reboot_equipment")
                ? _c("DropdownMenuItem", {
                    attrs: {
                      icon: "restart",
                      name: _vm.$gettext("Restart equipment")
                    },
                    on: {
                      click: function($event) {
                        return _vm.rebootEquipment()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.linkequipment.discharged
                ? _c("DropdownMenuItem", {
                    attrs: {
                      icon: "deploy",
                      name: _vm.$gettext("Deploy configuration")
                    },
                    on: {
                      click: function($event) {
                        return _vm.showDeployConfModal()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              (!_vm.linkequipment.conf_is_private &&
                _vm.linkequipment.is_plugged) ||
              _vm.linkequipment.discharged
                ? _c("DropdownMenuItem", {
                    attrs: {
                      icon: "download",
                      name: _vm.$gettext("Download configuration")
                    },
                    on: {
                      click: function($event) {
                        return _vm.downloadConf()
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.linkequipment.discharged
                ? _c("DropdownMenuItem", {
                    attrs: {
                      icon: "switch-equipment",
                      name: _vm.$gettext("Change equipment")
                    },
                    on: {
                      click: function($event) {
                        return _vm.showAttachModal()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { class: _vm.$style.infos }, [
        _c("div", { class: [_vm.$style.box_info, _vm.$style.main_box] }, [
          _c("div", { class: _vm.$style.equipment_picture_background }, [
            _c("div", {
              class: _vm.$style.equipment_picture,
              style: "background-image: url('" + _vm.routerPicture + "')"
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: [_vm.$style.box_info, _vm.$style.main_box] },
          [
            _c("div", { class: _vm.$style.info_bigname }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.linkequipment._equipment._model._vendor.name) +
                  "\n                    " +
                  _vm._s(_vm.linkequipment._equipment._model.name) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.$style.info_bigvalue },
              [
                !_vm.linkequipment._access._iplink.members.length > 1
                  ? [
                      _c(
                        "span",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ],
                          staticClass: "d-inline-flex"
                        },
                        [
                          _vm._v(
                            "\n                            Multi ip links\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { class: _vm.$style.members_list },
                        [
                          _c("MasterSvg", { attrs: { use: "info" } }),
                          _vm._v(" "),
                          _c("div", {
                            class: _vm.$style.flyinginfo,
                            attrs: {
                              "data-content": _vm.get_members_list(
                                _vm.linkequipment._access._iplink
                              )
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.linkequipment._access._iplink.link_id) +
                          "\n                    "
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _vm.linkequipment._equipment.serial_number
              ? _c("ProviderBadge", {
                  class: _vm.$style.provider_badge,
                  attrs: { equipment: _vm.linkequipment._equipment }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { class: _vm.$style.box_info }, [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.info_name
            },
            [_vm._v("\n                    Model\n                ")]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.info_value }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.linkequipment._equipment._model._vendor.name) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.$style.box_info }, [
          _c(
            "div",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              class: _vm.$style.info_name
            },
            [_vm._v("\n                    Serial number\n                ")]
          ),
          _vm._v(" "),
          _c("div", { class: _vm.$style.info_value }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.serial_number) +
                "\n                "
            )
          ])
        ]),
        _vm._v(" "),
        _vm.firmwareVersion && _vm.firmwareVersion.current_version
          ? _c("div", { class: _vm.$style.box_info }, [
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  class: _vm.$style.info_name
                },
                [
                  _vm._v(
                    "\n                    Firmware version\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-inline-flex",
                  class: [_vm.$style.info_value, _vm.$style.version],
                  on: { click: _vm.showFirmwareModal }
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.firmwareVersion.current_version) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("MasterSvg", {
                    attrs: {
                      "class-css": _vm.firmwareIconStyle.class,
                      use: _vm.firmwareIconStyle.name,
                      width: "12px",
                      height: "12px"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.canIPv4
          ? _c("div", { class: _vm.$style.box_info }, [
              _c(
                "div",
                { class: _vm.$style.info_name },
                [
                  _vm.linkequipment._access._iplink.mpls
                    ? _c("translate", [
                        _vm._v(
                          "\n                        Private IPv4\n                    "
                        )
                      ])
                    : _c("translate", [
                        _vm._v(
                          "\n                        Public IPv4\n                    "
                        )
                      ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.info_value }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.linkequipment._access.connectivity_ipv4_address
                    ) +
                    "\n                "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.canIPv6
          ? _c("div", { class: _vm.$style.box_info }, [
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  class: _vm.$style.info_name
                },
                [_vm._v("\n                    Public IPv6\n                ")]
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.info_value }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.linkequipment._access.router_ipv6_address) +
                    "\n                "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.canIPv6
          ? _c("div", { class: _vm.$style.box_info }, [
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  class: _vm.$style.info_name
                },
                [_vm._v("\n                    LAN IPv6\n                ")]
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.info_value }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.linkequipment._access.ipv6_lan_subnet) +
                    "\n                "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.linkequipment._access._iplink.members.length
          ? _c("div", { class: _vm.$style.box_info }, [
              _c(
                "div",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  class: _vm.$style.info_name
                },
                [_vm._v("\n                    Link type\n                ")]
              ),
              _vm._v(" "),
              _c("div", { class: _vm.$style.info_value }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.linkequipment._access.link_type_display) +
                    "\n                "
                )
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }