<template>
    <div :class="$style.equipmentheader">
        <div :class="$style.equipmentinfo">
            <div :class="$style.picto">
                <img
                    v-if="isVrrp"
                    :src="require('./assets/vrrp.svg?inline')"
                >
                <img
                    v-else
                    :src="require('./assets/router.svg?inline')"
                >
            </div>
            <div :class="$style.nameandstatus">
                <div
                    v-if="!isVrrp"
                    :class="$style.body6"
                >
                    {{ linkequipment._equipment._model._vendor.name }}
                    {{ linkequipment._equipment._model.name }}
                </div>
                <div
                    v-else
                    :class="$style.body6"
                >
                    VRRP
                </div>
                <div
                    v-if="!linkequipment.discharged"
                    class="d-flex"
                    :class="$style.body1"
                >
                    <div
                        v-if="linkequipment.is_plugged"
                        :class="[
                            $style.refresh_status,
                            {[$style.active]: statusLoading}
                        ]"
                        @click="fetchEquipmentStatus()"
                    >
                        <MasterSvg
                            use="refresh"
                            width="15px"
                            height="15px"
                        />
                    </div>
                    <span>{{ statusLocale }}</span>
                    <div :class="[$style.statusbullet, status_class]" />
                </div>
                <div
                    v-else
                    :class="$style.body1"
                >
                    <span>{{ discharge_text }}</span>
                    <div :class="$style.statusbullet" />
                </div>
            </div>
            <DropdownMenu
                color="white"
                size="large"
                :style="{'margin-right': '20px', 'margin-left': '50px'}"
            >
                <DropdownMenuItem
                    v-if="!isVrrp && can_manage_router"
                    icon="key"
                    :name="$gettext('Credentials')"
                    @click="showCredentialsModal()"
                />
                <DropdownMenuItem
                    v-if="!linkequipment.conf_is_private
                        && (linkequipment.is_plugged || linkequipment.discharged)
                        && !isVrrp
                    "
                    icon="download"
                    :name="$gettext('Download configuration')"
                    @click="downloadConf()"
                />
                <DropdownMenuItem
                    icon="upload"
                    :name="$gettext('Save configuration as template')"
                    @click="saveConfiguration()"
                />
                <DropdownMenuItem
                    icon="repeat"
                    :name="$gettext('Restore a configuration')"
                    @click="restoreConfiguration()"
                />
                <DropdownMenuItem
                    v-if="!linkequipment.discharged
                        && linkequipment.is_plugged
                        && has_capability('can_reboot_equipment')
                    "
                    icon="restart"
                    :name="$gettext('Restart equipment')"
                    @click="rebootEquipment()"
                />
                <DropdownMenuItem
                    v-if="!linkequipment.discharged
                        && linkequipment.is_plugged
                    "
                    icon="refresh"
                    :name="$gettext('Update equipment status')"
                    @click="fetchEquipmentStatus()"
                />
                <DropdownMenuItem
                    v-if="!isVrrp"
                    icon="switch-equipment"
                    :name="$gettext('Change equipment')"
                    @click="showAttachModal()"
                />
                <DropdownMenuItem
                    v-if="!linkequipment.discharged && can_secure"
                    icon="lock"
                    color="yellow"
                    :name="$gettext('Secure the installation')"
                    @click="showSecurityModal()"
                />
                <DropdownMenuItem
                    v-if="isVrrp"
                    icon="unlink"
                    color="red"
                    :name="$gettext('Remove VRRP')"
                    @click="detachVrrp()"
                />
                <DropdownMenuItem
                    v-if="!isVrrp"
                    icon="shape"
                    color="red"
                    :name="$gettext('Detach equipment from this link')"
                    @click="detachEquipment()"
                />
            </DropdownMenu>
        </div>
        <div :class="$style.deployinfo">
            <div
                v-if="scheduledAction"
                :class="$style.text"
            >
                <div :class="$style.body1">
                    {{ scheduledActionTitle }}
                </div>
                <div :class="$style.deploydate">
                    <h2>{{ scheduledActionDate || $gettext('As soon as possible') }}</h2>
                </div>
            </div>
            <div
                v-else-if="linkequipment.is_plugged"
                :class="$style.text"
            >
                <div
                    v-translate
                    :class="$style.body1"
                >
                    Last deploy
                </div>
                <div :class="$style.deploydate">
                    <h2>{{ lastSynchronizationDate }}</h2>
                </div>
            </div>
            <DeployButton
                :action-is-scheduled="!!scheduledAction"
                :missing-wan-configuration="missingWanConfiguration"
                @cancel-scheduled-action="handleCancelScheduledAction"
                @deploy-configuration="handleDeployConfiguration"
                @show-deploy-configuration-modal="handleShowDeployConfigurationModal"
                @show-incomplete-wan-configuration-modal="handleShowIncompleteWanConfigurationModal"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import {
    showSwalError,
    showSwal,
    fetchValueInDictInsensitiveCase,
    getImpactedIpLinks,
} from '@/utils/utils';
import {
    showErrorConf,
    actionsControl,
} from './utils/utils';
import MasterSvg from './master-svg.vue';
import DropdownMenu from './dropdown-menu.vue';
import DropdownMenuItem from './dropdown-menu-item.vue';

import DeployButton from './header/deploy-button.vue';

const CredentialsModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-credentials" */
    './modals/credentials.vue'
);
const ConfTemplateChoicesModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-conftemplatechoices" */
    './modals/conf-template-choices.vue'
);
const ConfTemplateSaveFormModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-conftemplatesaveform" */
    './modals/conf-template-save-form.vue'
);
const SecurityModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-security" */
    './modals/security.vue'
);
const AttachModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-attach" */
    './modals/attach.vue'
);
const ArchivedCredentialsModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-archived-credentials" */
    './modals/archived-credentials.vue'
);

export default {
    name: 'Header',
    components: {
        MasterSvg,
        DropdownMenu,
        DropdownMenuItem,
        DeployButton,
    },
    props: {
        missingWanConfiguration: {
            type: Boolean,
            default: false,
        },
        scheduledAction: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'activatedAccesses',
            'linkequipment',
            'linkequipmentoptions',
            'linkequipmentconf',
            'iplink',
            'virtual_iplink_members',
            'full_capabilities',
            'securityIPLinksFounded',
            'statusLoading',
            'security_iplinks',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        discharge_text() {
            // eslint-disable-next-line max-len
            let reseller = this.linkequipment._access._iplink._endcustomersite._endcustomer._partner.name;
            if (this.linkequipment.discharge_type === 'managed_by_operator') {
                reseller = 'unyc';
            }

            return Vue.prototype.$gettextInterpolate(
                Vue.prototype.$gettext('Managed by %{ reseller }'),
                { reseller },
            );
        },
        statusLocale() {
            if (!this.linkequipment.is_plugged) {
                if (this.linkequipment.is_provisioned) {
                    return this.$gettext('Waiting for connection on site');
                }
                return this.$gettext('Never deployed');
            }
            return {
                unknown: this.$gettext('Unknown state'),
                rebooting: this.$gettext('Rebooting'),
                synchronizing: this.$gettext('Synchronizing'),
                unreachable: this.$gettext('Unreachable'),
                up: this.$gettext('Up'),
            }[this.linkequipment.status];
        },
        lastSynchronizationDate() {
            if (!this.linkequipment.last_synchronization_date) {
                return this.$gettext('Never deployed');
            }
            moment.locale(Vue.config.language);
            return moment(this.linkequipment.last_synchronization_date).format('LLL');
        },
        status_class() {
            return {
                unknown: this.$style.unknown,
                rebooting: this.$style.rebooting,
                synchronizing: this.$style.synchronizing,
                unreachable: this.$style.unreachable,
                up: this.$style.up,
            }[this.linkequipment.status];
        },
        can_manage_router() {
            return this.permissions.find(
                element => element.codename === 'can_manage_router' && element.app_label === 'equipments',
            ) !== undefined;
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        can_vrrp() {
            return this.has_capability('can_vrrp') && !this.isVrrp;
        },
        can_secure() {
            if (this.linkequipment._access.redundancy || this.activatedAccesses.length > 1) {
                return false;
            }
            return this.securityIPLinksFounded && (this.security_iplinks || []).length;
        },
        scheduledActionTitle() {
            const { scheduledAction } = this;
            if (!scheduledAction) return null;

            let name = this.$gettext('Unknown event');
            if (scheduledAction.method === 'deploy_linkequipment_conf') {
                name = this.$gettext('Deployment');
            }
            if (scheduledAction.method === 'reboot_linkequipment') name = this.$gettext('Reboot');

            return this.$gettextInterpolate(
                this.$gettext('Scheduled %{name}'),
                { name },
            );
        },
        scheduledActionDate() {
            const { scheduledAction } = this;
            if (!scheduledAction) return null;
            if (!scheduledAction.eta) return null;

            moment.locale(Vue.config.language);
            const etaMoment = moment(scheduledAction.eta);
            return this.$gettextInterpolate(
                this.$gettext('%{date} at %{time}'),
                {
                    date: etaMoment.format('LL'),
                    time: etaMoment.format('LT'),
                },
            );
        },
    },
    watch: {
        linkequipment: {
            handler() {
                this.$forceUpdate();
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions('equipment', [
            'reboot',
            'download_conf',
            'detach_equipment',
            'set_conf_section',
            'detach_vrrp',
            'fetch_equipment_status',
        ]),
        downloadConf() {
            this.$eventHub.emit('show-global-loader', true);
            this.set_conf_section({
                check_only: true,
                section: null,
                conf: this.linkequipmentconf,
            }).then(() => {
                this.download_conf({
                    filters: {
                        equipment_type: 'router',
                    },
                }).then((response) => {
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const element = document.createElement('a');
                    const filename = fetchValueInDictInsensitiveCase(
                        response.headers,
                        'content-disposition',
                    ).split('filename=')[1];
                    element.setAttribute('href', fileURL);
                    element.setAttribute('download', filename);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }).catch(
                    showSwalError,
                ).finally(() => {
                    this.$eventHub.emit('show-global-loader', false);
                });
            }).catch((error) => {
                showErrorConf(this.$store, error, this.$style.sweeterrors);
                this.$eventHub.emit('show-global-loader', false);
            });
        },
        showCredentialsModal() {
            this.show_modal(
                CredentialsModal,
                {
                    linkequipment: this.linkequipment,
                },
                {
                    height: 'auto',
                    maxWidth: 480,
                },
            ).then((data) => {
                if (data === 'archivedCredentials') {
                    this.show_modal(
                        ArchivedCredentialsModal,
                        {
                            access: this.linkequipment._access,
                        },
                        {
                            height: 'auto',
                            maxWidth: 820,
                        },
                    );
                }
            });
        },
        detachEquipment() {
            actionsControl(this.linkequipment).then(() => {
                showSwal({
                    title: this.$gettext('Do you want to detach equipment from this link?'),
                    html: `
                        ${this.$gettext('By detaching equipment from this link, you can associate and an other equipment with this IP link.')}
                        <br/>
                        ${this.$gettext('The current configuration is saved and you can deploy it on the next equipment.')}
                        <br/>
                    `,
                    type: 'warning',
                    confirmButtonText: this.$gettext('Yes'),
                    cancelButtonText: this.$gettext('No'),
                    showCancelButton: true,
                    showCloseButton: true,
                }).then((resultSwal) => {
                    if (!resultSwal.value) {
                        return;
                    }
                    this.detach_equipment({
                        filters: {
                            equipment_type: 'router',
                        },
                    }).then(() => {
                        const ipLinks = getImpactedIpLinks(
                            this.virtual_iplink_members,
                            null,
                            this.iplink,
                        );
                        this.$eventHub.emit('detach-equipment', {
                            iplinks: ipLinks,
                        });
                    }).catch(showSwalError);
                });
            }).catch(showSwalError);
        },
        rebootEquipment() {
            if (!this.has_capability('can_reboot_equipment')) {
                return;
            }

            actionsControl(this.linkequipment).then(() => {
                showSwal({
                    title: this.$gettext('Do you want to reboot the equipment ?'),
                    html: `
                        ${this.$gettext('Rebooting the equipment will cause a service interruption.')}
                        <br/>
                        ${this.$gettext('VOICE/DATA access will be temporarily cut off until the equipment is returned to service.')}
                        <br/>
                    `,
                    type: 'warning',
                    confirmButtonText: this.$gettext('Yes'),
                    cancelButtonText: this.$gettext('No'),
                    showCancelButton: true,
                    showCloseButton: true,
                }).then((resultSwal) => {
                    if (!resultSwal.value) {
                        return;
                    }
                    this.reboot().catch(showSwalError);
                });
            }).catch(showSwalError);
        },
        has_capability(capability) {
            return this.full_capabilities.includes(capability);
        },
        showSecurityModal() {
            actionsControl(this.linkequipment).then(() => {
                this.show_modal(
                    SecurityModal,
                    {},
                    {
                        height: 'auto',
                        maxWidth: 820,
                    },
                );
            }).catch(showSwalError);
        },
        detachVrrp() {
            actionsControl(this.linkequipment).then(() => {
                showSwal({
                    title: this.$gettext('Are you sure you want to remove VRRP mode'),
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonText: this.$gettext('Remove connection'),
                    cancelButtonText: this.$gettext('Cancel'),
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                }).then((result) => {
                    if (result.value) {
                        this.detach_vrrp().then(() => {
                            showSwal({
                                title: this.$gettext('VRRP detached'),
                                text: this.$gettext('VRRP has properly been detached from IP link'),
                                type: 'success',
                            });
                        });
                    }
                });
            }).catch(showSwalError);
        },
        fetchEquipmentStatus() {
            if (this.statusLoading) {
                return;
            }
            this.fetch_equipment_status().catch(showSwalError);
        },
        restoreConfiguration() {
            actionsControl(this.linkequipment).then(() => {
                this.show_modal(
                    ConfTemplateChoicesModal,
                    {},
                    {
                        maxHeight: 450,
                        maxWidth: 900,
                    },
                );
            }).catch(showSwalError);
        },
        saveConfiguration() {
            actionsControl(this.linkequipment).then(() => {
                this.show_modal(
                    ConfTemplateSaveFormModal,
                    {},
                    {
                        maxHeight: 340,
                        maxWidth: 600,
                    },
                );
            }).catch(showSwalError);
        },
        showAttachModal() {
            const accessesNumber = this.activatedAccesses.length;
            const capabilities = [
                `can_manage_${this.iplink.ipstack}`,
            ];
            if (this.linkequipment._access.redundancy === 'multiwan' || accessesNumber > 1) {
                capabilities.push('can_multiwan');
            }
            if (accessesNumber > 1) {
                capabilities.push('backup');
            }

            actionsControl(this.linkequipment).then(() => {
                this.show_modal(
                    AttachModal,
                    {
                        iplink: this.linkequipment._access._iplink,
                        action: 'update',
                        capabilities,
                    },
                    {
                        maxWidth: Infinity,
                        maxHeight: Infinity,
                    },
                );
            }).catch(showSwalError);
        },
        handleDeployConfiguration(params) {
            this.$emit('deploy-configuration', params);
        },
        handleCancelScheduledAction() {
            this.$emit('cancel-scheduled-action');
        },
        handleShowDeployConfigurationModal() {
            this.$emit('show-deploy-configuration-modal');
        },
        handleShowIncompleteWanConfigurationModal() {
            this.$emit('show-incomplete-wan-configuration-modal');
        },
    },
};
</script>

<style lang="scss" module>
.equipmentheader {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    padding: 10px 40px;
    color: white;

    @include themify($themes) {
        background-color: themed('main_color');
    }

    @include under-to(md) {
        flex-direction: column;
        align-items: flex-start;
        height: 200px;
        padding: 25px 20px 25px 20px;
    }

    .equipmentinfo {
        display: flex;

        @include under-to(md) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .picto {
        display: flex;
        align-items: center;

        img {
            height: 34px;
        }
    }

    .nameandstatus {
        margin-left: 25px;

        .body6 {
            margin-bottom: 5px;
        }

        .body1,
        .body6 {
            color: white;
        }

        @include under-to(md) {
            .body6 {
                font-size: 22px;
                line-height: normal;
            }

            .body1 {
                font-size: 16px;
                line-height: normal;
            }
        }

        .refresh_status {
            display: flex;
            align-items: center;
            cursor: pointer;

            &.active {
                cursor: wait;

                svg {
                    animation: rotating 2s linear infinite;
                }
            }

            svg {
                width: 15px;
                height: 15px;
                margin-right: 10px;
                fill: white;
            }
        }

        .statusbullet {
            position: relative;
            margin-left: 8px;
            vertical-align: middle;

            &:before {
                position: absolute;
                top: 50%;
                width: 7px;
                height: 7px;
                content: ' ';
                border-radius: 50%;
                transform: translateY(-50%);
            }

            &.unknown,
            &.unreachable {
                &:before {
                    @include themify($themes) {
                        background-color: themed('error_color');
                    }
                }
            }

            &.synchronized,
            &.up,
            &.synchronizing {
                &:before {
                    @include themify($themes) {
                        background-color: themed('success_color');
                    }
                }
            }

            &.rebooting {
                &:before {
                    @include themify($themes) {
                        background-color: themed('warning_color');
                    }
                }
            }

            &.rebooting,
            &.synchronizing {
                transition: 1s ease all;
                animation: blinker 2s linear infinite;
            }
        }
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }

    .deployinfo {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include under-to(md) {
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: auto;
        }

        .text {
            white-space: nowrap;

            .body1 {
                color: white;
            }

            @include under-to(md) {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                margin-bottom: 14px;

                .body1 {
                    font-size: 12px;
                    line-height: 1.17;
                }

                h2 {
                    margin: 0;
                    font-size: 12px;
                    line-height: 1.17;
                }

                .deploydate {
                    margin-left: 10px;
                }
            }
        }

        .deploydate {
            max-width: 260px;
            max-height: 25px;
            overflow: hidden;

            h2 {
                font-size: 18px;

                @include themify($themes) {
                    color: themed('gray_scale_light');
                }
            }

            @include under-to(md) {
                margin-left: auto;
            }
        }
    }
}
</style>
