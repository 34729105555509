<template>
    <div
        :class="$style.emptysection"
        class="row"
    >
        <Title
            class="d-flex mt-3 mb-4 col-12"
            :title="subtitle"
            :show-previous-button="showPreviousButton"
            @previous_action="$emit('previous_action')"
        />
        <div class="col-12 col-lg-6">
            <div
                v-for="(step, index) in steps"
                :key="index"
                class="d-flex"
                :class="$style.info"
            >
                <div
                    v-if="numbersDisplayed"
                    class="col-1 mr-3 p-0"
                    :class="$style.number"
                >
                    <h1>{{ index + 1 }}</h1>
                </div>

                <div :class="[$style.text, $style.body1]">
                    <div>
                        <span class="d-none d-lg-inline">
                            {{ step.text }}
                        </span>
                        <span class="d-inline d-lg-none">
                            {{ step.textmobile }}
                        </span>
                        <Button
                            v-if="step.linktext"
                            class="d-inline-flex"
                            role="link"
                            :text="step.linktext"
                            @click="$emit('link_clicked', index)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <MasterSvg
                :use="image"
                class="img-fluid"
                :class-css="$style.img"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Button from '@/components/common/button.vue';
import MasterSvg from './master-svg.vue';
import Title from './component-title.vue';

export default {
    components: {
        MasterSvg,
        Button,
        Title,
    },
    props: {
        subtitle: {
            type: String,
            required: true,
        },
        steps: {
            type: Array,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        numbersDisplayed: {
            type: Boolean,
            default: true,
        },
        showPreviousButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'current_section_advice_visible',
        ]),
    },
    methods: {
        ...mapMutations('equipment', [
            'showCurrentSectionAdvice',
        ]),
    },
};
</script>

<style lang="scss" module>
.emptysection {
    .img {
        width: 100%;
        max-height: 340px;

        @include under-to(lg) {
            margin-top: 15px;
        }
    }

    @include under-to(lg) {
        div > h3 {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.57;
        }
    }

    .show_advice {
        display: flex;
        align-self: center;
        width: 18px;
        height: 18px;
        margin-left: 10px;
        cursor: pointer;

        @include themify($themes) {
            fill: themed('primary_color');
        }
    }

    .flyinginfo {
        position: relative;

        &:before {
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            margin-top: 2px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        &:after {
            position: absolute;
            top: calc(100% + 7px);
            left: 50%;
            z-index: 4;
            display: none;
            width: max-content;
            width: intrinsic;
            padding: 5px 20px;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 1.57;
            color: black;
            text-align: center;
            white-space: pre;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            transition: 0.3s ease all;
            transform: translateX(-50%);

            @include themify($themes) {
                box-shadow: 0 0 12px 0 rgba(themed('primary_color'), 0.4);
            }
        }

        &:hover {
            &:after,
            &:before {
                display: block;
                transition: 0.3s ease all;
            }
        }
    }

    .info {
        width: 100%;
        padding: 16px 33px;
        margin-top: 15px;
        border-radius: 4px;

        @include themify($themes) {
            background-color: themed("gray_scale_light");
        }

        .text {
            align-self: center;
        }

        .number {
            h1 {
                align-self: center;
                margin-bottom: 0;

                @include themify($themes) {
                    color: themed("primary_color");
                }
            }
        }
    }
}
</style>
