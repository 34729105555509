var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.equipment_section },
    [
      _vm.linkequipment
        ? _c(
            "div",
            { class: _vm.$style.equipment },
            [
              _c("Header", {
                attrs: {
                  "missing-wan-configuration": _vm.missingWanConfiguration,
                  "scheduled-action": _vm.scheduledActions[0]
                },
                on: {
                  "deploy-configuration": _vm.handleDeployConfiguration,
                  "cancel-scheduled-action": _vm.handleCancelScheduledAction,
                  "show-deploy-configuration-modal":
                    _vm.showConfDeploymentModal,
                  "show-incomplete-wan-configuration-modal":
                    _vm.showIncompleteWanConfigurationModal
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.equipment_content_wrapper },
                [
                  (_vm.$mq === "xs" || _vm.$mq === "sm") && !_vm.menu_visible
                    ? _c("MenuBurger", {
                        staticClass: "sticky-top",
                        class: _vm.$style.content_menu
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.$mq !== "xs" && _vm.$mq !== "sm") || _vm.menu_visible
                    ? _c("Menu", { class: _vm.$style.content_menu })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.menu_visible
                    ? _c("Content", {
                        class: _vm.$style.equipment_content,
                        attrs: {
                          "can-activate-admin-account":
                            _vm.canActivateAdminAccount,
                          readonly: _vm.readonly,
                          "missing-wan-configuration":
                            _vm.missingWanConfiguration,
                          "scheduled-actions": _vm.scheduledActions
                        },
                        on: {
                          "deploy-configuration": _vm.handleDeployConfiguration,
                          "show-deploy-configuration-modal":
                            _vm.showConfDeploymentModal,
                          "show-attach-backup-modem-modal":
                            _vm.showAttachBackupModemModal
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment && _vm.linkequipment_loaded
        ? _c("Provisionning", { attrs: { iplink: _vm.iplink } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showGlobalLoaderValue ? _c("CircleLoader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }