var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Banner",
    {
      attrs: { icon: "solid-info", type: "info", "can-be-closed": false },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function() {
            return [
              _c("Button", {
                class: _vm.$style.b1,
                attrs: {
                  role: "link",
                  text: _vm.$gettext("Restore previous conf"),
                  disabled: _vm.disabled,
                  size: "large"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("restore-configuration")
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("translate", [
        _vm._v(
          "\n        Some changes in this section are not yet deployed on the equipment.\n    "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }