import qs from 'qs';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getLinkEquipmentActions({ rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/equipments/linkequipment_actions/';

            params = { status: ['scheduled', 'failure', 'successful'], ...params };
            const paramsSerializer = queryParams => qs.stringify(queryParams, { arrayFormat: 'repeat' });
            rootState.axios_api.get(url, { params, paramsSerializer }).then((response) => {
                resolve(response.data);
            }).catch(reject);
        });
    },

    getLinkEquipmentAction({ rootState }, id) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipment_actions/${id}/`;

            rootState.axios_api.get(url).then((response) => {
                resolve(response.data);
            }).catch(reject);
        });
    },

    patchLinkEquipmentAction({ rootState }, { id, data }) {
        return new Promise((resolve, reject) => {
            const url = `/api/equipments/linkequipment_actions/${id}/`;

            rootState.axios_api.patch(url, data).then((response) => {
                resolve(response.data);
            }).catch(reject);
        });
    },

    cancelLinkEquipmentAction({ dispatch }, id) {
        return dispatch(
            'patchLinkEquipmentAction',
            {
                id,
                data: { status: 'canceled' },
            },
        );
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
