<template>
    <div :class="$style.specifications">
        <div class="d-block d-lg-flex flex-lg-row">
            <!-- left block -->
            <div :class="$style.left_block">
                <div :class="$style.title">
                    <slot name="title" />
                </div>

                <slot name="image">
                    <div :class="$style.picture_background">
                        <div
                            :class="$style.picture"
                            :style="`background-image: url('${image}')`"
                        />
                    </div>
                </slot>
            </div>

            <!-- list block that can use simple props object or complex slot -->
            <div :class="$style.right_block">
                <ComponentSpecificationsItem
                    v-for="(spec, index) in specifications"
                    :key="index"
                    :label="spec.label"
                    :value="spec.value"
                />
                <slot name="specifications" />
            </div>
        </div>
    </div>
</template>

<script>
import defaultImgRoute from './assets/defaultrouter.png';
import ComponentSpecificationsItem from './component-specifications-item.vue';

export default {
    components: {
        ComponentSpecificationsItem,
    },
    props: {
        imageSrc: {
            type: String,
            default: null,
            required: false,
        },
        title: {
            type: String,
            default: null,
            required: false,
        },
        specifications: {
            type: Array,
            default: null,
            required: false,
            validator: (items) => {
                const validItems = items.filter(obj => ['label', 'value'].every(key => key in obj));
                return validItems.length === items.length;
            },
        },
    },
    computed: {
        image() {
            if (this.imageSrc) {
                return this.imageSrc;
            }
            return defaultImgRoute;
        },
    },
};
</script>

<style lang="scss" module>
.specifications {
    .left_block {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-right: 50px;

        .title {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.1;

            @include under-to(lg) {
                margin: 10px 0;
            }
        }

        .picture_background {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 140px;
            border-radius: 4px;

            @include themify($themes) {
                background: themed('bluescale_extralight');
            }

            @include under-to(lg) {
                margin-bottom: 20px;
            }

            .picture {
                align-items: center;
                justify-content: flex-start;
                width: 80%;
                height: 80%;
                background-repeat: no-repeat;
                background-position: center;
                background-origin: content-box;
                background-size: contain;
            }
        }

        @include under-to(lg) {
            flex-direction: column-reverse;
            padding-right: 0;
            margin: 0 0 20px 15px;
        }
    }

    .right_block {
        display: flex;
        flex: 2;
        flex-wrap: wrap;
        height: fit-content;

        @include under-to(lg) {
            flex: 1;
            margin-top: 15px;
        }
    }
}
</style>
