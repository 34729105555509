<template>
    <div :class="[$style.section_port, 'row']">
        <ComponentSpecifications
            v-if="linkequipment_port"
            class="col-12 mb-4"
            :specifications="dmzSpecifications"
            :image-src="linkequipment_port._equipment._model.image || null"
        >
            <template #title>
                <span>
                    {{ linkequipment_port._equipment._model._vendor.name }}
                    {{ linkequipment_port._equipment._model.name }}
                </span>
            </template>
        </ComponentSpecifications>
        <div
            v-if="isVrrp"
            class="col-12 mt-4 mb-4"
        >
            <span
                v-translate
                :class="$style.body8"
            >
                Configuration
            </span>
        </div>
        <template v-if="isVrrp">
            <Field
                v-model="physicalIpAddress"
                :errors="physicalIpAddressErrors"
                :label="$gettext('Physical IP address')"
                :required="true"
                :class="$style.margin_field"
                class="col-12 mb-4 col-lg-6"
                name="physical_ip_address"
            />
        </template>
        <div class="col-12 mt-4">
            <span
                v-translate
                :class="$style.body7"
            >
                Ports
            </span>
        </div>
        <div class="col-12 mt-4">
            <DmzItemInterfaces
                :dmz="dmz"
                :index-dmz="indexDmz"
                :dmz-equipment-configurations="dmzEquipmentConfigurations"
                :selected-interfaces="selectedInterfaces"
                :type="type_interfaces"
                :equipment-link-id="equipmentLinkId"
            />
            <div
                v-if="formErrors.interfaces"
                :class="$style.errors"
            >
                <div
                    v-for="(error, index) in formErrors.interfaces"
                    :key="index"
                    :class="$style.error"
                >
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Field from '@/components/common/field.vue';
import DmzItemInterfaces from './dmzitem-interfaces.vue';
import ComponentSpecifications from './component-specifications.vue';

export default {
    components: {
        DmzItemInterfaces,
        Field,
        ComponentSpecifications,
    },
    props: {
        dmz: {
            type: Object,
            required: true,
        },
        dmzEquipmentConfigurations: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: null,
        },
        indexDmz: {
            type: Number,
            default: 0,
        },
        equipmentLinkId: {
            type: String,
            default: null,
        },
        selectedInterfaces: {
            type: Array,
            default: (() => []),
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'capabilities',
            'equipment',
            'virtual_iplink_members',
            'virtual_linkequipment_members',
            'linkequipmentconfoptions',
        ]),
        formErrors() {
            return this.errors || {};
        },
        physicalIpAddress: {
            get() {
                return this.dmzEquipmentConfigurations.management.ipv4.ip_address;
            },
            set(value) {
                this.dmzEquipmentConfigurations.management = Object.assign(
                    {},
                    this.dmzEquipmentConfigurations.management,
                    { ipv4: { ip_address: value } },
                );
            },
        },
        physicalIpAddressErrors() {
            return ((this.formErrors.management || {}).ipv4 || {}).ip_address;
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        type_interfaces() {
            if (this.linkequipment_port) {
                if (!this.current_capabilities.includes('can_switch_interfaces')) {
                    return 'radio';
                }
            }
            return 'checkbox';
        },
        linkequipment_port() {
            if (!this.isVrrp) {
                return this.linkequipment;
            }
            return this.virtual_linkequipment_members.find(
                member => member._access.iplink === this.equipmentLinkId,
            );
        },
        current_capabilities() {
            if (!this.capabilities) {
                return [];
            }
            const currentCapabilities = this.capabilities.find(
                item => item.link_id === this.equipmentLinkId,
            ) || { capabilities: [] };
            return currentCapabilities.capabilities;
        },
        exit_wan_choices() {
            const choices = [];
            if (!this.linkequipment_port) {
                return choices;
            }
            const { members } = this.linkequipment_port._access._iplink;
            if (!members.length) {
                members.push(this.linkequipment_port.iplink);
            }

            if (this.linkequipmentconfoptions && this.linkequipmentconfoptions.actions) {
                this.linkequipmentconfoptions.actions.PUT.dmz
                    .child.children.main_exit_wan.choices.forEach((item) => {
                        if (!members.includes(item.value)) {
                            return;
                        }
                        choices.push({
                            id: item.value,
                            text: item.display_name,
                        });
                    });
            }

            return choices;
        },
        dmzSpecifications() {
            const unavailable = this.$gettext('Unavailable information');
            return [
                {
                    label: this.$gettext('IP address'),
                    value: this.dmz.ip || unavailable,
                },
                {
                    label: this.$gettext('Subnet'),
                    value: this.dmz.subnet || unavailable,
                },
                {
                    label: this.$gettext('IP Link'),
                    value: (this.linkequipment_port || {}).access,
                    display: (this.linkequipment_port || {}).access,
                },
            ];
        },
    },
    watch: {
        dmz: {
            handler() {
                if (!this.dmzEquipmentConfigurations.main_exit_wan) {
                    if (this.exit_wan_choices.length === 1) {
                        this.dmzEquipmentConfigurations.main_exit_wan = this.exit_wan_choices[0].id;
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
