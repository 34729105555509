var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {}),
        (_obj[_vm.$style.tooltip] = _vm.content),
        (_obj[_vm.$style.greyBorder] = _vm.greyBorder),
        _obj),
      attrs: { "data-content": _vm.content },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }