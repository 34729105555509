var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.disabled_section, "row"] },
    [
      _vm.title
        ? _c("Title", { staticClass: "mt-3 mb-4", attrs: { title: _vm.title } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.placeholder },
        [
          _c("MasterSvg", {
            attrs: {
              use: _vm.svgName,
              width: _vm.svgWidth,
              height: _vm.svgHeight,
              "class-css": _vm.$style.img
            }
          }),
          _vm._v(" "),
          _c(
            "h3",
            { class: _vm.$style.placeholder_title },
            [
              _vm._t("title", function() {
                return [
                  _c("translate", [
                    _vm._v(
                      "\n                    An MPLS is deployed and manages this IP link.\n                "
                    )
                  ])
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.$style.placeholder_body },
            [
              _vm._t("body", function() {
                return [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "translate",
                          rawName: "v-translate",
                          value: { network_zone: _vm.section },
                          expression: "{network_zone: section}"
                        }
                      ]
                    },
                    [
                      _vm._v(
                        "\n                    You cannot create and configure %{ network_zone } on this access.\n                "
                      )
                    ]
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }