<template>
    <div :class="$style.headerAdviceLayout">
        <slot name="before" />

        <Advice
            v-model="internalAdviceDisplay"
            :can-be-closed="adviceCanBeClosed"
            :link-url="adviceLinkUrl"
        >
            <template #title>
                <slot name="advice-title" />
            </template>
            <template #subtitle>
                <slot name="advice-subtitle" />
            </template>
            <template>
                <slot name="advice-content" />
            </template>
            <template #example>
                <slot name="advice-example" />
            </template>
        </Advice>

        <slot name="default" />
    </div>
</template>

<script>
import Advice from '../common/advice.vue';

export default {
    components: {
        Advice,
    },
    props: {
        // Advice props
        adviceCanBeClosed: {
            type: Boolean,
            default: true,
        },
        adviceDisplay: {
            type: Boolean,
            required: true,
        },
        adviceLinkUrl: {
            default: null,
            validator: item => typeof item === 'string' || item === null,
        },
    },
    computed: {
        internalAdviceDisplay: {
            get() {
                return this.adviceDisplay;
            },
            set(value) {
                this.$emit('update:adviceDisplay', value);
            },
        },
    },
};
</script>

<style lang="scss" module>
.headerAdviceLayout {
    display: flex;
    flex-direction: column;
    gap: 28px;
}
</style>
