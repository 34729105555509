<template>
    <div
        v-if="!canBeClosed || visible"
        :class="$style.adviceSection"
    >
        <div :class="$style.adviceHeader">
            <div :class="$style.adviceTitle">
                <span>
                    <slot name="title" />
                </span>
                <span
                    v-if="$slots.subtitle"
                    :class="$style.adviceSubtitle"
                >
                    <slot name="subtitle" />
                </span>
            </div>

            <Button
                v-if="canBeClosed"
                role="icon_secondary"
                icon="cross"
                :class="$style.adviceCloseButton"
                @click="hideAdvice"
            />
        </div>

        <div :class="$style.adviceContent">
            <!-- Content -->
            <slot name="default" />

            <!-- Example -->
            <Button
                v-show="haveExample && !showExample"
                :class="$style.adviceButton"
                role="link"
                :text="$gettext('Show example')"
                @click="handleShowExampleButton"
            />
            <div
                v-show="haveExample && showExample"
                :class="$style.adviceExample"
            >
                <slot name="example" />
            </div>

            <!-- Know More -->
            <Button
                v-if="linkUrl"
                :class="$style.adviceButton"
                role="link"
                knowmore
                :text="$gettext('Know more about it on the kiosque')"
                @click="handleKnowMoreButton"
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/common/button.vue';

export default {
    name: 'Advice',
    components: {
        Button,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        linkUrl: {
            type: String,
            default: null,
        },
        canBeClosed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showExample: false,
        };
    },
    computed: {
        haveExample() {
            return this.$slots.example;
        },
        visible: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        handleShowExampleButton() {
            this.showExample = true;
        },
        handleKnowMoreButton() {
            window.open(this.linkUrl, '_blank');
        },
        hideAdvice() {
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" module>
.adviceSection {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;
    text-align: left;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .adviceContent {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-right: 60px;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.57;
        text-align: justify;

        @include under-to(lg) {
            padding-right: 0;
        }

        .adviceExample {
            padding: 20px;
            text-align: justify;
            border: 1px solid #E2E2E2;
            border-radius: 4px;

            @include themify($themes) {
                background-color: themed('gray_scale_light');
            }
        }

        .adviceButton {
            display: flex;
            font-size: 12px;
        }
    }

    .adviceHeader {
        display: flex;
        justify-content: space-between;

        .adviceTitle {
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;

            .adviceSubtitle {
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 0.9px;

                @include themify($themes) {
                    color: themed('primary_color');
                }
            }
        }

        .adviceCloseButton {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 15px;

            @include themify($themes) {
                background-color: rgba(themed('primary_color'), 0.1);
            }

            :global(svg) {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 10px;
                height: 10px;
                transform: translate(-50%, -50%);

                @include themify($themes) {
                    fill: themed('primary_color');
                }
            }
        }
    }
}
</style>
