const state = {
    modalVisible: false,
    modalComponent: null,
    modalParams: null,
    modalSuccessCallback: null,
    modalErrorCallback: null,
    steps: [],
    currentStep: null,

};

const getters = {
    modalVisible: state => state.modalVisible,
    modalParams: state => state.modalParams,
    currentStep: state => state.currentStep,
    steps: state => state.steps,

};

const mutations = {
    updateModalParams(state, params) {
        state.modalParams = params;
    },
    updateModalComponent(state, component) {
        state.modalComponent = component;
    },
    updateModalSuccessCallback(state, callback) {
        state.modalSuccessCallback = callback;
    },
    updateModalErrorCallback(state, callback) {
        state.modalErrorCallback = callback;
    },
    updateModalVisible(state, visible) {
        state.modalVisible = visible;
    },
    updateModalStep(state, steps) {
        state.steps = steps;
    },
    show_modal(state, steps) {
        const firstelement = 0;

        state.modalVisible = true;
        state.steps = steps;
        state.currentStep = steps[firstelement];
        state.modalComponent = steps[firstelement].component;

        if (state.currentStep.params) {
            state.modalParams = state.currentStep.params;
        }
    },
    hide_modal(state) {
        state.modalVisible = false;
        state.modalParams = null;
        state.currentStep = null;
        state.steps = [];
    },
    next_step(state) {
        const { callback } = state.currentStep;

        if (state.currentStep.next_step_tag) {
            let foundStep = false;

            for (let i = 0; i < state.steps.length; i += 1) {
                if (!foundStep && state.steps[i].tag === state.currentStep.next_step_tag) {
                    state.currentStep = state.steps[i];
                    foundStep = true;
                    state.modalComponent = state.currentStep.component;
                }
            }
        }

        if (callback) {
            callback.call();
        }
    },
    previous_step(state) {
        if (state.currentStep.previous_step_tag) {
            let foundStep = false;

            for (let i = 0; i < state.steps.length; i += 1) {
                if (!foundStep && state.steps[i].tag === state.currentStep.previous_step_tag) {
                    state.currentStep = state.steps[i];
                    foundStep = true;
                    state.modalComponent = state.currentStep.component;
                }
            }
        } else if (state.currentStep.callback) {
            state.currentStep.callback.call();
        }
    },
    alter_current_step_sequence(state, settings) {
        if (state.currentStep.alter_step_callback) {
            state.currentStep.alter_step_callback(state.currentStep, settings);
        }
    },
};

const actions = {
    showModal({ commit }, modalParams) {
        return new Promise((resolve, reject) => {
            commit('updateModalSuccessCallback', resolve);
            commit('updateModalErrorCallback', reject);
            commit('updateModalComponent', modalParams.component);
            commit('updateModalParams', modalParams.params);
            commit('updateModalVisible', true);
            commit('updateModalStep', []);
        });
    },
    hideModal({ commit, state }, params) {
        const successCallback = state.modalSuccessCallback;
        const errorCallback = state.modalErrorCallback;
        commit('updateModalVisible', false);
        commit('updateModalSuccessCallback', null);
        commit('updateModalErrorCallback', null);
        if (errorCallback && params && params.error) {
            errorCallback(params.error);
            return;
        }
        if (successCallback) {
            successCallback((params && params.result) || null);
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
