var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListItem",
    {
      class: _vm.$style.snmpCommunitiesListItem,
      attrs: { breakpoint: "md" },
      scopedSlots: _vm._u([
        {
          key: "after",
          fn: function() {
            return [
              _c(
                "DropdownMenu",
                { attrs: { position: "right", size: "large" } },
                [
                  _c("DropdownMenuItem", {
                    attrs: {
                      icon: "trash-bin",
                      name: _vm.$gettext("Delete SNMP community"),
                      color: "red",
                      disabled: _vm.readonlyMode
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("remove")
                      }
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("Field", {
        attrs: {
          disabled: _vm.readonlyMode,
          errors: _vm.errors.name,
          label: _vm.$gettext("Community name"),
          name: "name",
          required: ""
        },
        model: {
          value: _vm.community.name,
          callback: function($$v) {
            _vm.$set(_vm.community, "name", $$v)
          },
          expression: "community.name"
        }
      }),
      _vm._v(" "),
      _c("SubnetField", {
        attrs: {
          disabled: _vm.readonlyMode,
          errors: _vm.errors.authorized_subnets,
          label: _vm.$gettext("Authorized subnets"),
          "multiple-value": "",
          placeholder: _vm.$gettext("Optional"),
          name: "authorizedSubnets"
        },
        model: {
          value: _vm.authorizedSubnets,
          callback: function($$v) {
            _vm.authorizedSubnets = $$v
          },
          expression: "authorizedSubnets"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }