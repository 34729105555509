<template>
    <Button
        v-if="deployButtonDisplay"
        :text="deployButtonText"
        size="large"
        :icon="deployButtonIcon"
        :class="[$style.deployButton, deployButtonClass]"
        :disabled="linkequipment.is_plugged && linkequipment.discharged"
        @click="handleButtonClick"
    />
    <DropdownMenu
        v-else
        mode="click"
        size="large"
        :class="[$style.deployButton, $style.dropdownMenuDeploy]"
    >
        <template #content>
            <Button
                :text="$gettext('Deploy')"
                icon="arrow-down"
                size="large"
            />
        </template>
        <DropdownMenuItem
            icon="deploy"
            :name="$gettext('Deploy now')"
            @click="showDeployConfModal(false)"
        />
        <DropdownMenuItem
            icon="arrow-time"
            :name="$gettext('Schedule a deployment')"
            @click="showDeployConfModal(true)"
        />
    </DropdownMenu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '@/components/common/button.vue';
import { showSwalError } from '@/utils/utils';
import { showErrorConf, actionsControl } from '../utils/utils';

import DropdownMenu from '../dropdown-menu.vue';
import DropdownMenuItem from '../dropdown-menu-item.vue';

export default {
    components: {
        Button,
        DropdownMenu,
        DropdownMenuItem,
    },
    props: {
        actionIsScheduled: {
            type: Boolean,
            default: false,
        },
        missingWanConfiguration: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentoptions',
            'linkequipmentconf',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        deployButtonText() {
            if (!this.linkequipment) return '';
            if (this.actionIsScheduled) return this.$gettext('Cancel deployment');
            if (!this.linkequipment.is_plugged) {
                if (this.linkequipment.is_provisioned) {
                    return this.$gettext('Deploy configuration manually again');
                }
                return this.$gettext('Deploy configuration manually');
            }
            if (this.linkequipment.synchronized || this.linkequipment.discharged) {
                return this.$gettext('Configuration deployed');
            }
            if (this.linkequipment.status === 'synchronizing') {
                return this.$gettext('Deployment in progress');
            }
            if (this.linkequipmentoptions) {
                const hotpatch = this.linkequipmentoptions.can_hotpatch;
                const deployConf = this.linkequipmentoptions.can_deploy_conf;

                if (!hotpatch && !deployConf) {
                    return this.$gettext('Deploy configuration manually');
                }
            }
            if (!this.canScheduleDeployment) {
                return 'Deploy configuration';
            }
            return '';
        },
        deployButtonClass() {
            if (this.linkequipment.discharged) {
                return [
                    this.$style.disharged,
                    this.$style.b1,
                ];
            }
            return null;
        },
        deployButtonIcon() {
            if (this.linkequipment.discharged || !this.linkequipment.is_plugged) {
                return null;
            }
            if (this.linkequipment.status === 'synchronizing') {
                return 'small-loader';
            }
            if (this.linkequipment.synchronized) {
                return 'check';
            }
            if (!this.canScheduleDeployment) {
                return 'deploy';
            }
            return null;
        },
        deployButtonDisplay() {
            if (
                !this.linkequipment
                    || !this.linkequipment.is_plugged
                    || this.linkequipment.synchronized
                    || this.linkequipment.discharged
                    || this.linkequipment.status === 'synchronizing'
                    || this.actionIsScheduled
                    || !this.canScheduleDeployment
            ) {
                return true;
            }
            if (this.linkequipmentoptions) {
                const hotpatch = this.linkequipmentoptions.can_hotpatch;
                const deployConf = this.linkequipmentoptions.can_deploy_conf;

                if (!hotpatch && !deployConf) {
                    return true;
                }
            }
            return false;
        },
        canScheduleDeployment() {
            return this.permissions.filter(
                element => element.app_label === 'equipments',
            ).find(
                element => element.codename === 'add_linkequipmentaction',
            ) !== undefined;
        },
    },
    watch: {
        linkequipment() {
            this.$forceUpdate();
        },
    },
    methods: {
        ...mapActions('equipment', [
            'set_conf_section',
        ]),
        showDeployConfModal(schedule) {
            if (
                !this.linkequipment.is_plugged
                || (
                    !this.linkequipment.synchronized
                    && this.linkequipment.status !== 'synchronizing'
                )
            ) {
                this.set_conf_section({
                    check_only: true,
                    section: null,
                    conf: this.linkequipmentconf,
                }).then(() => {
                    actionsControl(this.linkequipment).then(() => {
                        this.$emit('deploy-configuration', { schedule });
                    }).catch(showSwalError);
                }).catch((error) => {
                    showErrorConf(this.$store, error, this.$style.sweeterrors);
                });
            } else {
                this.$emit('show-deploy-configuration-modal');
            }
        },
        handleButtonClick() {
            if (this.missingWanConfiguration) {
                this.$emit('show-incomplete-wan-configuration-modal');
                return;
            }

            if (this.actionIsScheduled) {
                this.$emit('cancel-scheduled-action');
            } else {
                this.showDeployConfModal(false);
            }
        },
    },
};
</script>

<style lang="scss" module>
.deployButton {
    width: 100%;
    height: 100%;
    margin-left: 30px;

    @include under-to(md) {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
    }

    &.dropdownMenuDeploy {
        .btn svg {
            width: 10px;
            height: 5px;
            margin: 0;
            margin-left: 16px;
        }
    }

    .btn {
        flex-direction: row-reverse;
        padding: 10px 20px;
        font-size: 12px;
        font-weight: 900;

        svg {
            width: 1.5em;
            height: 1.5em;
            margin: 0;
            margin-left: 12px;
        }
    }
}
</style>
