<template>
    <div :class="$style.equipment_section">
        <div
            v-if="linkequipment"
            :class="$style.equipment"
        >
            <Header
                :missing-wan-configuration="missingWanConfiguration"
                :scheduled-action="scheduledActions[0]"
                @deploy-configuration="handleDeployConfiguration"
                @cancel-scheduled-action="handleCancelScheduledAction"
                @show-deploy-configuration-modal="showConfDeploymentModal"
                @show-incomplete-wan-configuration-modal="showIncompleteWanConfigurationModal"
            />
            <div :class="$style.equipment_content_wrapper">
                <MenuBurger
                    v-if="($mq === 'xs' || $mq === 'sm') && !menu_visible"
                    class="sticky-top"
                    :class="$style.content_menu"
                />
                <Menu
                    v-if="(($mq !== 'xs' && $mq !== 'sm') || menu_visible)"
                    :class="$style.content_menu"
                />
                <Content
                    v-if="!menu_visible"
                    :class="$style.equipment_content"
                    :can-activate-admin-account="canActivateAdminAccount"
                    :readonly="readonly"
                    :missing-wan-configuration="missingWanConfiguration"
                    :scheduled-actions="scheduledActions"
                    @deploy-configuration="handleDeployConfiguration"
                    @show-deploy-configuration-modal="showConfDeploymentModal"
                    @show-attach-backup-modem-modal="showAttachBackupModemModal"
                />
            </div>
        </div>
        <Provisionning
            v-if="!linkequipment && linkequipment_loaded"
            :iplink="iplink"
        />
        <CircleLoader v-if="showGlobalLoaderValue" />
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import VueTimers from 'vue-timers';
import VueSweetalert2 from 'vue-sweetalert2';
import CircleLoader from '@/components/common/circle-loader.vue';
import Header from '@/components/equipment/header.vue';
import Menu from '@/components/equipment/menu.vue';
import MenuBurger from '@/components/equipment/menu-burger.vue';
import Content from '@/components/equipment/content.vue';
import Provisionning from '@/components/equipment/provisionning.vue';
import {
    showSwal,
    showToast,
    showSwalError,
} from '@/utils/utils';

import DeploymentMixin from './mixins/deployment';

const ConfDeploymentModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-conf-deployment" */
    './modals/conf-deployment.vue'
);
const FirmwareModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-firmware" */
    './modals/firmware.vue'
);

const IncompleteWanConfigurationModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-incomplete-wan-configuration" */
    './modals/incomplete-wan-configuration.vue'
);

Vue.use(VueTimers);
Vue.use(VueSweetalert2);

export default {
    components: {
        Header,
        Menu,
        MenuBurger,
        Provisionning,
        Content,
        CircleLoader,
    },
    mixins: [
        DeploymentMixin,
    ],
    props: {
        iplink: {
            type: Object,
            required: true,
        },
        canActivateAdminAccount: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        scheduledActions: [],
        actionsInProgress: [],
        showGlobalLoaderValue: false,
    }),
    computed: {
        ...mapGetters('equipment', [
            'activatedAccesses',
            'linkequipment',
            'linkequipmentconf',
            'linkequipment_loaded',
            'equipment_attached',
            'adminaccount_activated',
            'section',
            'menu_visible',
            'hash_prefix',
            'virtual_linkequipment_members',
            'firmwareVersionByLinkEquipment',
            'iplink_in_deployment',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        deployed_linkequipment() {
            const usedMember = this.virtual_linkequipment_members.find(
                member => member.access === this.iplink_in_deployment,
            );
            if (usedMember) {
                return JSON.parse(JSON.stringify(usedMember));
            }
            return JSON.parse(JSON.stringify(this.linkequipment));
        },
        canUpdateFirmwareVersion() {
            return this.permissions.find(
                element => element.codename === 'can_update_firmware_version'
                    && element.app_label === 'equipments',
            ) !== undefined;
        },
        can_create_redundancy_with_different_enductomersites() {
            return this.permissions.find(
                element => element.codename === 'can_create_redundancy_with_different_enductomersites'
                    && element.app_label === 'equipments',
            ) !== undefined;
        },
        linkequipments() {
            if ((this.virtual_linkequipment_members || []).length) {
                return this.virtual_linkequipment_members;
            }
            if (this.linkequipment) return [this.linkequipment];
            return [];
        },
        readonly() {
            return (this.scheduledActions || []).length > 0;
        },
        accessesNotConfigured() {
            const mainAccess = this.linkequipment._access;
            if (mainAccess.redundancy) return [];
            if (!this.linkequipmentconf) return [];

            const currentEquipmentConf = (this.linkequipmentconf.equipments || []).find(
                item => item.link_id === mainAccess._iplink.link_id,
            ) || {};

            return this.activatedAccesses.filter(
                access => !((currentEquipmentConf || {}).wans || []).map(
                    wan => wan.name,
                ).includes(access.link_id),
            );
        },
        missingWanConfiguration() {
            return this.accessesNotConfigured.length > 0;
        },
    },
    watch: {
        linkequipments: {
            handler() {
                if (!this.linkequipments.length) return;

                let isProvisioning = false;
                let isPlugged = true;
                this.linkequipments.forEach((linkequipment) => {
                    isProvisioning = isProvisioning || linkequipment.is_provisioned;
                    isPlugged = isPlugged && linkequipment.is_plugged;
                });

                if (isProvisioning && !isPlugged) {
                    if (!this.timers.waitEquipmentToBePlugged.isRunning) {
                        this.$timer.start('waitEquipmentToBePlugged');
                    }
                } else if (isPlugged && this.timers.waitEquipmentToBePlugged.isRunning) {
                    this.fetchEquipmentInfos();
                    showToast(this.$gettext('Equipment plugged'), 'success', 7000);
                    this.$timer.stop('waitEquipmentToBePlugged');
                }
            },
            deep: true,
        },
        virtual_linkequipment_members: {
            handler() {
                const membersInDeployment = this.virtual_linkequipment_members.filter(
                    member => member.status === 'synchronizing',
                );

                if (!membersInDeployment.length || this.iplink_in_deployment) {
                    return;
                }

                if (membersInDeployment.length !== this.virtual_linkequipment_members.length) {
                    this.update_iplink_in_deployment(
                        membersInDeployment[0].access,
                    );
                }
            },
            deep: true,
        },
        linkequipment() {
            if (!this.linkequipment) {
                if (this.timers.waitEquipmentToBePlugged.isRunning) this.$timer.stop('waitEquipmentToBePlugged');
                if (this.timers.waitDeploy.isRunning) this.$timer.stop('waitDeploy');
                if (this.timers.waitScheduledActions.isRunning) this.$timer.stop('waitScheduledActions');
                if (this.timers.waitReboot.isRunning) this.$timer.stop('waitReboot');
                return;
            }

            if (!this.linkequipment._access.redundancy && this.activatedAccesses.length === 1) {
                this.fetchSecurityIplinks();
            }

            this.getActionsInProgress();
            if (this.linkequipment.synchronized) {
                this.actionsInProgress = [];
            }
            if (this.linkequipment.status === 'synchronizing') {
                if (this.timers.waitDeploy.isRunning) {
                    return;
                }
                showToast(this.$gettext('Deployment start'), 'info');

                this.$timer.start('waitDeploy');
            } else if (this.linkequipment.status === 'rebooting') {
                if (this.timers.waitReboot.isRunning) {
                    return;
                }
                showToast(this.$gettext('Rebooting'), 'info');
                this.$timer.start('waitReboot');
            } else {
                this.$timer.start('waitScheduledActions');
            }
        },
        equipment_attached() {
            if (this.equipment_attached) {
                showSwal({
                    title: this.$gettext('Equipment attached'),
                    type: 'success',
                });
            } else if (!this.linkequipment) {
                showSwal({
                    title: this.$gettext('Equipment detached'),
                    text: this.$gettext('Equipment has been detached from IP link and returned to your stock'),
                    type: 'success',
                });
            }
        },
        adminaccount_activated() {
            if (this.adminaccount_activated) {
                showSwal({
                    title: this.$gettext('Administrator account'),
                    text: this.$gettext('Your administrator account has successfuly been activated. If equipment is online, please wait for the system to apply the last configuration.'),
                    type: 'success',
                });
            } else {
                showSwal({
                    title: this.$gettext('Administrator account'),
                    text: this.$gettext('Your administrator account has successfuly been deactivated. If equipment is online, please wait for the reset.'),
                    type: 'success',
                });
            }
        },
        firmwareVersionByLinkEquipment: {
            handler(newValue, oldValue) {
                if (
                    !newValue
                    || JSON.stringify(newValue) === JSON.stringify(oldValue)
                    || !this.canUpdateFirmwareVersion
                ) {
                    return;
                }

                this.firmwareVersionByLinkEquipment.forEach((firmwareVersion) => {
                    if (firmwareVersion.current_version && !firmwareVersion.is_allowed) {
                        if (this.showFirmwareModal(
                            firmwareVersion.link_id,
                            (firmwareVersion.recommended_firmware || {}).name,
                        )) {
                            this.show_modal(
                                FirmwareModal,
                                {
                                    'link-id': firmwareVersion.link_id,
                                    'current-firmware': {
                                        ...firmwareVersion,
                                        recommended_firmware: undefined,
                                    },
                                    'recommended-firmware': firmwareVersion.recommended_firmware,
                                },
                                {
                                    'small-cross': true,
                                    maxWidth: 610,
                                    maxHeight: 400,
                                },
                            );
                        }
                    }
                });
            },
            deep: true,
        },
        $route() {
            this.changeSection();
        },
    },
    timers: {
        waitScheduledActions: {
            time: 5000,
            autostart: false,
            repeat: true,
            immediate: true,
        },
        waitDeploy: {
            time: 5000,
            autostart: false,
            repeat: true,
            immediate: true,
        },
        waitReboot: {
            time: 5000,
            autostart: false,
            repeat: true,
            immediate: true,
        },
        waitEquipmentToBePlugged: {
            time: 20000,
            autostart: false,
            repeat: true,
            immediate: true,
        },
    },
    created() {
        const currenthash = this.$route.hash;
        let sectionhash = `#${this.hash_prefix}general`;

        this.changeiplink(this.iplink);

        if (currenthash !== '') {
            sectionhash = currenthash;
        } else {
            this.$router.replace(sectionhash);
        }

        this.navigate(sectionhash.replace(this.hash_prefix, '').substr(1));
        this.$eventHub.on('show-global-loader', this.handleShowGlobalLoader);
    },
    mounted() {
        this.init_linkequipment({
            filters: {
                equipment_type: 'router',
            },
        }).then(() => {
            this.initSectionsAdviceVisible();
            this.fetchEquipmentInfos();
            this.changeSection();
        }).catch((error) => {
            if (error.status !== 404) {
                showSwalError(error);
            }
        });
    },
    beforeDestroy() {
        this.$eventHub.off('show-global-loader');
    },
    methods: {
        ...mapMutations('equipment', [
            'changeiplink',
            'navigate',
            'initSectionsAdviceVisible',
            'update_iplink_in_deployment',
        ]),
        ...mapActions('equipment', [
            'init_linkequipment',
            'post_linkequipment_testreachability',
            'fetch_security_iplinks',
            'fetchEquipmentInfos',
        ]),
        ...mapActions('equipment/actions', [
            'getLinkEquipmentActions',
            'cancelLinkEquipmentAction',
        ]),
        handleShowGlobalLoader(stateValue) {
            this.showGlobalLoaderValue = stateValue;
        },
        waitEquipmentToBePlugged() {
            this.linkequipments.forEach((linkequipment) => {
                if (linkequipment.is_provisioned && !linkequipment.is_plugged) {
                    this.post_linkequipment_testreachability({
                        link_id: linkequipment.access,
                        forceIPLink: !(linkequipment._access.redundancy),
                    }).catch(showSwalError);
                }
            });
        },
        waitScheduledActions() {
            this.getLinkEquipmentActions({
                access: this.linkequipment.access,
                status: 'scheduled',
            }).then((response) => {
                if (response.length) {
                    this.scheduledActions = response;
                    return;
                }

                if (this.scheduledActions.length) this.fetchEquipmentInfos();
                this.scheduledActions = [];
                this.$timer.stop('waitScheduledActions');
            }).catch(showSwalError);
        },
        waitDeploy() {
            const _this = this;

            this.init_linkequipment({
                filters: {
                    equipment_type: 'router',
                },
            }).then((response) => {
                if (!this.synchronization_is_complete(response)) {
                    return;
                }

                if (!this.deployed_linkequipment.synchronized) {
                    showToast(_this.$gettext('Deployment error'), 'error');
                } else {
                    this.fetchEquipmentInfos();
                    showToast(_this.$gettext('Configuration deployed'));
                }
                this.update_iplink_in_deployment(null);
                _this.$timer.stop('waitDeploy');
            }).catch(showSwalError);
        },
        waitReboot() {
            const _this = this;

            this.init_linkequipment({
                filters: {
                    equipment_type: 'router',
                },
            }).then((response) => {
                if (response.status === 'unreachable') {
                    showToast(_this.$gettext('Reboot error'), 'error');
                    _this.$timer.stop('waitReboot');
                } else if (response.status !== 'rebooting') {
                    this.fetchEquipmentInfos();
                    showToast(_this.$gettext('Equipment rebooted'));
                    _this.$timer.stop('waitReboot');
                }
            }).catch(showSwalError);
        },
        synchronization_is_complete(linkequipment) {
            if (
                this.deployed_linkequipment.status === 'synchronizing'
                || this.deployed_linkequipment.status === 'rebooting'
                || linkequipment.status === 'synchronizing'
                || linkequipment.status === 'rebooting'
            ) {
                return false;
            }

            return true;
        },
        changeSection() {
            let currenthash = this.$route.hash.replace(this.hash_prefix, '');

            [currenthash] = currenthash.split('-');
            if (currenthash !== '' && currenthash !== `#${this.section}`) {
                this.navigate(currenthash.substr(1));
            }
        },
        showIncompleteWanConfigurationModal() {
            this.show_modal(
                IncompleteWanConfigurationModal,
                {},
                {
                    hideClose: true,
                    maxHeight: 540,
                    maxWidth: 578,
                },
            ).then((data) => {
                if (data === 'attach-equipment') {
                    this.showAttachBackupModemModal();
                }
            });
        },
        showAttachBackupModemModal() {
            const currenthash = this.$route.hash;
            const sectionhash = `#${this.hash_prefix}wan`;
            const wanData = {
                linkName: (((this.linkequipment || {})._access || {})._iplink || {}).link_id,
                router: ((this.linkequipment || {})._equipment || {})._model,
            };

            if (currenthash !== sectionhash) this.$router.push(sectionhash);
            this.$nextTick(() => {
                this.$eventHub.emit('show-attach-backup-modem-modal', wanData);
            });
        },
        showConfDeploymentModal(actions) {
            this.show_modal(
                ConfDeploymentModal,
                {
                    linkEquipmentActions: actions || this.actionsInProgress,
                },
                {
                    hideClose: true,
                    maxHeight: 500,
                    maxWidth: 890,
                },
            );
        },
        showFirmwareModal(iplink, goodVersion) {
            if (!localStorage.firmware_versions) {
                return true;
            }
            const firmwareVersions = JSON.parse(localStorage.firmware_versions);
            const local = firmwareVersions.find(
                item => item.iplink === iplink && item.version === goodVersion,
            );
            if (!local) {
                return true;
            }
            return local.show_modal;
        },
        fetchSecurityIplinks() {
            if (!this.linkequipment) {
                return;
            }

            const params = {};
            if (!this.can_create_redundancy_with_different_enductomersites) {
                params.params = {
                    endcustomersite: this.linkequipment._access._iplink._endcustomersite.id,
                };
            }

            this.fetch_security_iplinks(params);
        },
        handleDeployConfiguration(params = {}) {
            this.deploy_conf(
                params.linkequipment || this.linkequipment,
                params.linkequipment !== undefined,
                params.schedule || false,
            ).then((data) => {
                const newActions = (data.result || {}).actions || [];
                if (params.schedule) {
                    this.scheduledActions = newActions;
                    showSwal({
                        title: this.$gettext('Scheduled deployment'),
                        text: this.$gettext('The scheduled deployment has been created'),
                        type: 'success',
                    });
                } else if (data.result) {
                    this.actionsInProgress = newActions;
                    this.showConfDeploymentModal();
                }
            }).catch(showSwalError);
        },
        handleCancelScheduledAction() {
            Promise.all(
                this.scheduledActions.map(
                    action => this.cancelLinkEquipmentAction(action.id),
                ),
            ).then(() => {
                this.scheduledActions = [];
                showSwal({
                    title: this.$gettext('Deployment cancelled'),
                    text: this.$gettext('The scheduled deployment has been cancelled'),
                    type: 'success',
                });
            }).catch(showSwalError);
        },
        getActionsInProgress() {
            this.getLinkEquipmentActions({
                access: this.linkequipment.access,
                method: 'deploy_linkequipment_conf',
                status: ['pending', 'running'],
            }).then((actionsInProgress) => {
                if (actionsInProgress.length && !this.actionsInProgress.length) {
                    this.actionsInProgress = actionsInProgress;
                    this.showConfDeploymentModal(actionsInProgress);
                }
            }).catch(showSwalError);
        },
    },
};
</script>

<style lang="scss" module>
.equipment_section {
    width: 100%;
    background-color: white;

    .equipment {
        .equipment_content_wrapper {
            display: flex;
            padding-top: 30px;
            padding-bottom: 30px;

            @include under-to(md) {
                display: inline-grid;
                width: 100%;
                padding-top: 0;
                padding-bottom: 20px;
                background-color: white;
            }

            .content_menu {
                flex: 0 0 195px;
            }

            .equipment_content {
                position: relative;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                gap: 20px;
                padding: 10px 20px 20px 50px;

                @include under-to(md) {
                    padding: 30px 20px 0 20px;
                }
            }
        }
    }

    .errors {
        @include themify($themes) {
            color: themed('error_color');
        }
    }

    :global(h3) {
        margin-bottom: 0;
    }
}
</style>
