import Vue from 'vue';

export const internetToLanOption = {
    title: Vue.prototype.$gettext('Internet → LAN'),
    subtitle: Vue.prototype.$gettext('Communications blocked by default'),
    description: Vue.prototype.$gettext('Allow communications from internet to your LANs'),
    button: Vue.prototype.$gettext('Allow new communication'),
    title_section: Vue.prototype.$gettext('List of allowed communications'),
    can_close_advise: true,
    can_add_rule: true,
    add_modal: {
        title: Vue.prototype.$gettext('Allow communications from internet to your LANs'),
        label_source_ip: Vue.prototype.$gettext('IPs/Subnets internet'),
        label_destination_ip: Vue.prototype.$gettext('IP/Subnet LAN'),
    },
    icon: 'wan2lan',
    category: 'wan2lan',
};

export const internetToDmzOption = {
    title: Vue.prototype.$gettext('Internet → DMZ'),
    subtitle: Vue.prototype.$gettext('Communications allowed by default'),
    description: Vue.prototype.$gettext('No firewalling action available for DMZ'),
    can_close_advise: false,
    can_add_rule: false,
    icon: 'wan2dmz',
    category: 'dmz',
};

export const lanToInternetOption = {
    title: Vue.prototype.$gettext('LAN → Internet'),
    subtitle: Vue.prototype.$gettext('Communications allowed by default'),
    description: Vue.prototype.$gettext('Block communications from your LANs to internet'),
    button: Vue.prototype.$gettext('Block new communication'),
    title_section: Vue.prototype.$gettext('List of blocked communications'),
    can_close_advise: true,
    can_add_rule: true,
    add_modal: {
        title: Vue.prototype.$gettext('Block communications from your LANs to internet'),
        label_source_ip: Vue.prototype.$gettext('IPs/Subnets LAN'),
        label_destination_ip: Vue.prototype.$gettext('IP/Subnet internet'),
    },
    icon: 'lan2wan',
    category: 'lan2wan',
};

export const mplsToLanOption = {
    title: Vue.prototype.$gettext('MPLS → LAN'),
    subtitle: Vue.prototype.$gettext('Communications allowed by default'),
    description: Vue.prototype.$gettext('Block communications from MPLS LANs to your LANs'),
    button: Vue.prototype.$gettext('Block new communication'),
    title_section: Vue.prototype.$gettext('List of blocked communications'),
    can_close_advise: true,
    can_add_rule: true,
    add_modal: {
        title: Vue.prototype.$gettext('Block communications from MPLS LANs to your LANs'),
        label_source_ip: Vue.prototype.$gettext('IPs/Subnets MPLS LAN'),
        label_destination_ip: Vue.prototype.$gettext('IP/Subnet LAN'),
    },
    icon: 'wan2lan',
    category: 'wan2lan',
};

export const lanToMplsOption = {
    title: Vue.prototype.$gettext('LAN → MPLS'),
    subtitle: Vue.prototype.$gettext('Communications allowed by default'),
    description: Vue.prototype.$gettext('Block communications from your LANs to MPLS LANs'),
    button: Vue.prototype.$gettext('Block new communication'),
    title_section: Vue.prototype.$gettext('List of blocked communications'),
    can_close_advise: true,
    can_add_rule: true,
    add_modal: {
        title: Vue.prototype.$gettext('Block communications from your LANs to MPLS LANs'),
        label_source_ip: Vue.prototype.$gettext('IPs/Subnets LAN'),
        label_destination_ip: Vue.prototype.$gettext('IP/Subnet MPLS LAN'),
    },
    icon: 'lan2wan',
    category: 'lan2wan',
};
