<template>
    <div
        v-if="visiblePorts.length"
        :class="$style.generalPortsList"
    >
        <div :class="$style.portsList">
            <template v-for="(iface, index) in visiblePorts">
                <Port
                    :key="index"
                    :configuration="equipmentConfiguration"
                    :iface="iface"
                    :loading="interfacesLoading"
                    :options="equipmentOptions"
                />
            </template>
        </div>
        <div
            v-if="needPaginate || isModified"
            :class="$style.bottom"
        >
            <Paginate
                v-if="needPaginate"
                v-model="currentPage"
                :items-count="itemsCount"
                :items-per-page="itemsPerPage"
            />
            <div
                v-if="isModified"
                :class="$style.controls"
            >
                <Button
                    role="primary"
                    :text="$gettext('Apply')"
                    size="small"
                    class="mr-3"
                    @click="apply"
                />
                <Button
                    role="secondary"
                    :text="$gettext('Cancel')"
                    size="small"
                    @click="cancel"
                />
            </div>
        </div>
    </div>
    <ComponentEmptySearch
        v-else
        size="small"
    >
        <template v-slot:title>
            <div
                v-if="filterValue == 'active'"
                v-translate
            >
                No active interface to show
            </div>
            <div
                v-else-if="filterValue == 'inactive'"
                v-translate
            >
                No inactive interface to show
            </div>
            <div
                v-else
                v-translate
            >
                No interface to show
            </div>
        </template>
        <template v-slot:text>
            <div v-translate>
                Please try with an other filter
            </div>
        </template>
    </ComponentEmptySearch>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '@/components/common/button.vue';
import Paginate from '@/components/common/paginate.vue';
import { showSwalError, showSwal } from '@/utils/utils';

import { actionsControl } from '../../utils/utils';

import Port from './general-ports-listitem.vue';

import ComponentEmptySearch from '../../component-empty-search.vue';

const PAGE_SIZE_DESKTOP = 6;
const PAGE_SIZE_MOBILE = 3;

export default {
    name: 'GeneralPortsList',
    components: {
        Button,
        ComponentEmptySearch,
        Paginate,
        Port,
    },
    props: {
        equipmentLinkId: {
            required: true,
            type: String,
        },
        filterValue: {
            default: 'all',
            type: String,
        },
    },
    data() {
        return {
            localPortsConf: JSON.stringify(this.equipmentPorts),
            currentPage: 0,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'interfaces',
            'interfacesLoading',
            'linkequipment',
            'linkequipmentconf',
            'linkequipmentconfoptions',
        ]),
        isModified() {
            return JSON.stringify(
                this.equipmentPorts,
            ) !== JSON.stringify(
                this.localPortsConf,
            );
        },
        itemsPerPage() {
            if (this.$mq !== 'xs' && this.$mq !== 'sm') {
                return PAGE_SIZE_DESKTOP;
            }
            return PAGE_SIZE_MOBILE;
        },
        itemsCount() {
            return this.filterItems.length;
        },
        needPaginate() {
            return this.itemsCount > this.itemsPerPage;
        },
        visiblePorts() {
            return this.filterItems.slice(
                this.currentPage * this.itemsPerPage, (this.currentPage + 1) * this.itemsPerPage,
            );
        },
        equipmentPorts() {
            if (!this.equipmentConfiguration) {
                return [];
            }

            let equipmentPortsData = {};
            if (this.interfaces) {
                equipmentPortsData = this.interfaces.find(
                    equipment => equipment.link_id === this.equipmentLinkId,
                ) || {};
            }

            return (this.equipmentConfiguration.interfaces || []).map(
                itm => ({
                    ...(equipmentPortsData.interfaces || []).find(
                        item => (item.name === itm.name) && item,
                    ),
                    ...itm,
                }),
            );
        },
        filterItems() {
            if (!this.localPortsConf || this.filterValue === 'all') {
                return this.localPortsConf;
            }

            return this.localPortsConf.filter(
                iface => iface.enabled === (this.filterValue === 'active'),
            );
        },
        equipmentConfiguration() {
            let equipmentConfiguration = {};

            if (this.linkequipmentconf && this.linkequipmentconf.equipments) {
                equipmentConfiguration = this.linkequipmentconf.equipments.find(
                    equipment => equipment.link_id === this.equipmentLinkId,
                ) || {};
            }

            return equipmentConfiguration;
        },
        equipmentOptions() {
            if (!this.linkequipmentconfoptions) {
                return {};
            }
            return this.linkequipmentconfoptions.equipments_informations.find(
                equipment => equipment.link_id === this.equipmentLinkId,
            ) || {};
        },
    },
    watch: {
        equipmentPorts: {
            handler(equipmentsConf) {
                this.localPortsConf = JSON.parse(JSON.stringify(equipmentsConf));
            },
            immediate: true,
            deep: true,
        },
        filterValue() {
            this.currentPage = 0;
        },
        equipmentLinkId() {
            this.currentPage = 0;
        },
    },
    methods: {
        ...mapActions('equipment', [
            'set_conf_section',
        ]),
        cancel() {
            this.localPortsConf = JSON.parse(JSON.stringify(this.equipmentPorts));
        },
        apply() {
            const equipmentsConfs = this.linkequipmentconf.equipments;
            equipmentsConfs.forEach((item) => {
                if (item.link_id === this.equipmentLinkId) {
                    item.interfaces = this.localPortsConf;
                }
            });
            actionsControl(this.linkequipment).then(() => {
                showSwal({
                    title: this.$gettext('Are you sure you want to save configuration'),
                    html: `
                        ${this.$gettext('This modification may make access to the client inaccessible.')}
                        <br/>
                        ${this.$gettext('Are you sure of your action?')}
                        <br/>
                    `,
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonText: this.$gettext('Save configuration'),
                    cancelButtonText: this.$gettext('Cancel'),
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                }).then((resultSwal) => {
                    if (!resultSwal.value) {
                        return;
                    }

                    this.set_conf_section({
                        section: 'equipments',
                        conf: equipmentsConfs,
                    }).then(() => {
                        showSwal({
                            title: this.$gettext('Ports configuration saved'),
                            text: this.$gettext('You must deploy configuration in order to apply modifications on equipment'),
                            type: 'success',
                            showCancelButton: true,
                            confirmButtonText: this.$gettext('Deploy now'),
                            cancelButtonText: this.$gettext('Later'),
                            showCloseButton: true,
                            showLoaderOnConfirm: true,
                        }).then((resultSwalDeploy) => {
                            if (!resultSwalDeploy.value) return;

                            this.$emit('deploy-configuration');
                        });
                    }).catch(showSwalError);
                });
            }).catch(showSwalError);
        },
    },
};
</script>

<style lang="scss" module>
.generalPortsList {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .portsList {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 8px;
    }

    .bottom {
        display: flex;
        gap: 10px;
        margin-top: 25px;
        margin-bottom: 4px;

        @include under-to(lg) {
            flex-direction: column;
        }

        .controls {
            display: flex;
            margin: 0 0 0 auto;

            .btn {
                min-width: 100px;
                font-weight: bold;
            }
        }
    }
}
</style>
