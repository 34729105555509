var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-2", class: _vm.$style.equipmentcontent },
    [
      _vm.section == "menu" ? _c("Menu") : _vm._e(),
      _vm._v(" "),
      _vm.section == "general"
        ? _c("General", {
            attrs: {
              "can-activate-admin-account": _vm.canActivateAdminAccount,
              readonly: _vm.readonly,
              "missing-wan-configuration": _vm.missingWanConfiguration
            },
            on: {
              "deploy-configuration": _vm.handleDeployConfiguration,
              "show-deploy-configuration-modal":
                _vm.handleShowDeployConfigurationModal,
              "show-attach-backup-modem-modal":
                _vm.handleShowAttachBackupModemModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDischargedBanner
        ? _c(
            "Banner",
            {
              attrs: {
                icon: "solid-info",
                type: "warning",
                "can-be-closed": false
              }
            },
            [
              _c("translate", [
                _vm._v(
                  "\n            The information below may not be up-to-date as the router as the router is in discharge mode and we do not have access to it.\n        "
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "dmz"
        ? _c("DMZ", {
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "wan"
        ? _c("Wans", {
            attrs: {
              "readonly-mode": _vm.readonly || _vm.linkequipment.discharged
            },
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "lan"
        ? _c("Lan", {
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "nat"
        ? _c("NAT", {
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "connectedDevices"
        ? _c("ConnectedDevices", {
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "routes"
        ? _c("Routes", {
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "firewall"
        ? _c("Firewall", {
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "confs"
        ? _c("Confs", {
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "events"
        ? _c("Events", { attrs: { "scheduled-actions": _vm.scheduledActions } })
        : _vm._e(),
      _vm._v(" "),
      _vm.section == "advanced"
        ? _c("Advanced", {
            attrs: {
              "readonly-mode": _vm.readonly || _vm.linkequipment.discharged
            },
            on: { "deploy-configuration": _vm.handleDeployConfiguration }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }