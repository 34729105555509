var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: _vm.transitionName } },
    [
      _c(_vm.choicedComponent, {
        tag: "component",
        staticClass: "bg-white",
        attrs: {
          "init-choiced-advanced": _vm.initChoicedAdvanced,
          "readonly-mode": _vm.readonlyMode
        },
        on: {
          "close-section": _vm.changeComponent,
          "click-component": _vm.changeComponent,
          "deploy-configuration": _vm.handleDeployConfiguration
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }