var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HeaderAdviceLayout",
    {
      class: _vm.$style.snmpCommunitiesSection,
      attrs: { "advice-display": _vm.adviceDisplay },
      on: {
        "update:adviceDisplay": function($event) {
          _vm.adviceDisplay = $event
        },
        "update:advice-display": function($event) {
          _vm.adviceDisplay = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "advice-title",
          fn: function() {
            return [_c("translate", [_vm._v("SNMP communities")])]
          },
          proxy: true
        },
        {
          key: "advice-content",
          fn: function() {
            return [
              _c("translate", [
                _vm._v(
                  "\n            Communication protocol that allows network administrators to manage network equipment, monitor and diagnose network and hardware issues remotely.\n        "
                )
              ]),
              _vm._v(" "),
              _c("translate", [
                _vm._v(
                  "\n            Fill in an access name and for more security you can add IP address of your server.\n        "
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm.isUpdated
        ? _c("BannerUpdatedSection", { attrs: { disabled: _vm.readonlyMode } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TitleWithAdviceState",
        {
          scopedSlots: _vm._u([
            {
              key: "before",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      class: _vm.$style.goBack,
                      on: { click: _vm.closeSection }
                    },
                    [
                      _c("MasterSvg", {
                        attrs: {
                          use: "arrow-left",
                          width: "17.5px",
                          height: "15px"
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "after",
              fn: function() {
                return [
                  _c("DesktopAndMobileButton", {
                    attrs: {
                      role: "primary",
                      icon: "add",
                      disabled: _vm.readonlyMode,
                      text: _vm.$gettext("Add community")
                    },
                    on: { click: _vm.addCommunity }
                  })
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.adviceDisplay,
            callback: function($$v) {
              _vm.adviceDisplay = $$v
            },
            expression: "adviceDisplay"
          }
        },
        [_vm._v(" "), _c("translate", [_vm._v("SNMP communities")])],
        1
      ),
      _vm._v(" "),
      !_vm.communities.length
        ? _c("StepsList", {
            attrs: { image: "snmp" },
            scopedSlots: _vm._u(
              [
                {
                  key: "step-1",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          '\n                To add an SNMP community click on "Add community"\n            '
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "step-2",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          "\n                Add a community name\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "step-3",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          "\n                For more security add IP address or subnet of your server\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              4196167297
            )
          })
        : _c("SnmpCommunitiesList", {
            attrs: {
              communities: _vm.communities,
              "form-errors": _vm.formErrors,
              "readonly-mode": _vm.readonlyMode
            },
            on: { remove: _vm.removeCommunity }
          }),
      _vm._v(" "),
      _vm.isModified
        ? _c("SubmitButtons", {
            attrs: { disabled: _vm.readonlyMode },
            on: {
              cancel: _vm.initLinkEquipmentConfSnmpCommunities,
              submit: _vm.validate
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }