import Vue from 'vue';
import { en, fr } from 'vuejs-datepicker/dist/locale';

let datepickerLocale = null;

switch (Vue.config.language.substr(0, 2)) {
    case 'fr':
        datepickerLocale = fr;
        break;
    default:
        datepickerLocale = en;
}

const state = {
    items: [],
    periods: [],
    start_date: null,
    end_date: null,
    errors: [],
    loading: false,
    actions_to_perform: [],
    locale: datepickerLocale,
};

const getters = {
    items: state => state.items,
    start_date: state => state.start_date,
    end_date: state => state.end_date,
    errors: state => state.errors,
    loading: state => state.loading,
    actions_to_perform: state => state.actions_to_perform,
    periods: state => state.periods,
    locale: state => state.locale,
};

const mutations = {
    update_items(state, items) {
        state.items = items;
    },
    add_item(state, item) {
        state.items.push(item);
    },
    update_start_date(state, startDate) {
        state.start_date = startDate;
        state.errors = [];
        state.items = [];
    },
    update_end_date(state, endDate) {
        state.end_date = endDate;
        state.errors = [];
        state.items = [];
    },
    update_errors(state, errors) {
        state.errors = errors;
    },
    add_error(state, error) {
        state.errors.push(error);
    },
    update_loading(state, loading) {
        state.loading = loading;
    },
    update_actions_to_perform(state, actionsToPerform) {
        state.actions_to_perform = actionsToPerform;
    },
    update_periods(state, periods) {
        state.periods = periods;
    },
    add_period(state, period) {
        state.periods.push(period);
    },
};

const UNEXPECTED_ERROR = Vue.prototype.$gettext('An unexpected error happened');
const UNEXPECTED_ERROR_CALLBACK_OBJECT = {
    success: false,
    errors: {
        non_field_errors: [UNEXPECTED_ERROR],
    },
    error: UNEXPECTED_ERROR,
};

const legacyBillingArchiveBaseUrl = '/api/legacy/billingarchive/';

const actions = {
    load_from_url({ state, commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            if (params.first) {
                commit('update_loading', true);
            }

            rootState.axios_api.get(params.url, { params: params.queryParams })
                .then((response) => {
                    let unit = '';
                    let responseValue = 0;

                    if (typeof response.data.count !== 'undefined') {
                        responseValue = response.data.count;
                    } else if (typeof response.data.duration !== 'undefined') {
                        unit = 'min';
                        responseValue = response.data.duration;
                    } else if (typeof response.data.cost !== 'undefined') {
                        unit = '€';
                        responseValue = response.data.cost;
                    } else if (typeof response.data.consumption !== 'undefined') {
                        unit = 'Go';
                        responseValue = response.data.consumption;
                    }

                    if (responseValue == null) {
                        responseValue = 0;
                    }

                    const idx = state.items.findIndex(element => element.name === params.val.name);

                    if (idx !== -1) {
                        let newTotal = responseValue;

                        Object.entries(state.items[idx].values).forEach((entry) => {
                            newTotal += entry[1].value;
                        });

                        state.items[idx].total = newTotal;
                        state.items[idx].values.push({
                            value: responseValue,
                            period: params.period,
                        });
                        commit('update_items', state.items);
                    } else {
                        commit('add_item', {
                            label: params.val.label,
                            name: params.val.name,
                            unit,
                            values: [
                                {
                                    value: responseValue,
                                    period: params.period,
                                },
                            ],
                            total: responseValue,
                        });
                    }

                    if (params.last) {
                        commit('update_loading', false);
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                    if (typeof error.response !== 'undefined') {
                        if (error.response.status === 500) {
                            commit('add_error', {
                                key: 'error',
                                label: error.response.statusText,
                            });
                        } else {
                            Object.entries(error.response.data).forEach((entry) => {
                                const found = state.errors.find(
                                    element => element.key === entry[0],
                                );

                                // Add error only if this error was not stored yet
                                if (!found) {
                                    commit('add_error', {
                                        key: entry[0],
                                        label: entry[1][0],
                                    });
                                }
                            });
                        }
                    } else {
                        commit('add_error', {
                            key: 'error',
                            label: 'Undefined server response',
                        });
                    }

                    if (params.last) {
                        commit('update_loading', false);
                    }

                    if (!params || !params.callback) {
                        return;
                    }

                    params.callback(UNEXPECTED_ERROR_CALLBACK_OBJECT);
                });
        });
    },

    async load_by_range({ state, commit, dispatch }) {
        commit('update_items', []);
        commit('update_errors', []);
        commit('update_periods', []);

        const dtStart = Vue.moment(state.start_date);
        const dtEnd = Vue.moment(state.end_date);

        if (dtEnd.diff(dtStart, 'days') >= 0) {
            const months = dtEnd.diff(dtStart, 'months');
            commit('add_period', dtStart.format('YYYYMM'));

            for (let i = 1; i <= months; i += 1) {
                const currentDate = Vue.moment(dtStart).add(i, 'M');
                commit('add_period', currentDate.format('YYYYMM'));
            }
        }

        const apiCalls = [];

        state.periods.map((period, periodIndex) => (
            state.actions_to_perform.map((value, index) => apiCalls.push({
                url: `${legacyBillingArchiveBaseUrl}${value.endpoint}/`,
                queryParams: {
                    ...value.params,
                    call_start_gte: state.start_date,
                    call_start_lte: state.end_date,
                    period,
                },
                val: value,
                period,
                first: !periodIndex && !index,
                last: (periodIndex === state.periods.length - 1)
                && (index === state.actions_to_perform.length - 1),
            }))
        ));

        apiCalls.reduce((previousPromise, nextID) => previousPromise.then(
            () => dispatch('load_from_url', nextID),
        ).catch(
            () => dispatch('load_from_url', nextID),
        ), Promise.resolve());
    },

    async exportByRange({ rootState, state, commit }, email) {
        commit('update_items', []);
        commit('update_errors', []);
        commit('update_periods', []);

        const dtStart = Vue.moment(state.start_date);
        const dtEnd = Vue.moment(state.end_date);

        if (dtEnd.diff(dtStart, 'days') >= 0) {
            const months = dtEnd.diff(dtStart, 'months');
            commit('add_period', dtStart.format('YYYYMM'));

            for (let i = 1; i <= months; i += 1) {
                const currentDate = Vue.moment(dtStart).add(i, 'M');
                commit('add_period', currentDate.format('YYYYMM'));
            }
        }

        const actionsExport = [];
        state.periods.map(period => (
            state.actions_to_perform.map(value => actionsExport.push({
                name: value.name,
                label: value.label,
                period,
                endpoint: value.endpoint,
                queryParams: {
                    ...value.params,
                    call_start_gte: state.start_date,
                    call_start_lte: state.end_date,
                    period,
                },
            }))
        ));

        return rootState.axios_api.post(
            `${legacyBillingArchiveBaseUrl}async_export/`,
            { email, actions: actionsExport },
        );
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
