const adviceKey = 'equipments-tooltik:hidden-advices';

export default {
    data: () => ({ adviceDisplay: false }),
    mounted() {
        this.adviceDisplay = !(JSON.parse(localStorage.getItem(adviceKey)) || [])
            .includes(this.adviceId);
    },
    watch: {
        adviceDisplay(value) {
            const localAdviceManagement = JSON.parse(localStorage.getItem(adviceKey)) || [];

            if (value === !localAdviceManagement.includes(this.adviceId)) return;

            if (!value) {
                localAdviceManagement.push(this.adviceId);
            } else {
                localAdviceManagement.pop(this.adviceId);
            }

            localStorage.setItem(adviceKey, JSON.stringify(localAdviceManagement));
        },
    },
};
