var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.interfacesRouter },
    [
      _c("Button", {
        class: [
          _vm.$style.refreshAction,
          ((_obj = {}), (_obj[_vm.$style.active] = !_vm.loading), _obj)
        ],
        attrs: { role: "icon_primary", icon: "refresh", disabled: _vm.loading },
        on: {
          click: function($event) {
            return _vm.$emit("refresh-interfaces")
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.routerPorts },
        _vm._l(_vm.interfaces, function(port, index) {
          return _c("RouterPort", {
            key: index,
            attrs: {
              "port-number": port.port_number,
              enabled: port.enabled,
              linked: port.link_active,
              used: _vm.usedInterfaces.includes(port.port_number),
              selected: _vm.selectedPort === port.port_number,
              "equipment-data": _vm.equipmentData
            },
            on: { selected: _vm.handleSelectedPort }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }