var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Table",
    {
      attrs: {
        breakpoint: "lg",
        "empty-results-icon": "equipment_result",
        "empty-results-icon-module": "equipment",
        paginate: "",
        "page-number": _vm.currentPage,
        "items-per-page": _vm.itemsPerPage,
        "items-count": _vm.filteredRoutes.length
      },
      on: {
        "update:pageNumber": function($event) {
          _vm.currentPage = $event
        },
        "update:page-number": function($event) {
          _vm.currentPage = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("translate", { style: { flex: 2 } }, [
                _vm._v("\n            Destination IP\n        ")
              ]),
              _vm._v(" "),
              _c("translate", { style: { flex: 2 } }, [
                _vm._v("\n            Netmask\n        ")
              ]),
              _vm._v(" "),
              _c("translate", { style: { flex: 2 } }, [
                _vm._v("\n            Gateway\n        ")
              ]),
              _vm._v(" "),
              _c("translate", [_vm._v("Metric")])
            ]
          },
          proxy: true
        },
        {
          key: "filters",
          fn: function() {
            return [
              _c(
                "div",
                { class: _vm.$style.routesTableFiltersBlock },
                [
                  _c("Field", {
                    class: _vm.$style.searchField,
                    attrs: {
                      placeholder: _vm.$gettext("Search for a static route"),
                      name: "search",
                      size: "small",
                      "icon-left": "search"
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _vm._v(" "),
                  _vm.ipVersionsList.length > 1
                    ? _c("ComponentSimpleFilters", {
                        style: { marginLeft: "auto" },
                        attrs: { "filter-choices": _vm.ipVersionsList },
                        model: {
                          value: _vm.internalSelectedIpVersion,
                          callback: function($$v) {
                            _vm.internalSelectedIpVersion = $$v
                          },
                          expression: "internalSelectedIpVersion"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._l(_vm.visibleRoutes, function(route) {
        return _c(
          "RoutesTableItem",
          _vm._b(
            {
              key: route.uuid,
              attrs: { "disabled-actions": _vm.disabledActions },
              on: {
                "edit-route": function($event) {
                  return _vm.$emit("edit-route", route)
                },
                "delete-route": function($event) {
                  return _vm.$emit("delete-route", route)
                }
              }
            },
            "RoutesTableItem",
            Object.assign({}, route),
            false
          )
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }