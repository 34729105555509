<script>
export default {
    functional: true,
    render: (createElement, context) => {
        const data = {
            props: {
                name: 'slide',
                mode: 'out-in',
            },
        };
        return createElement('transition', data, context.children);
    },
};
</script>

<style lang="scss">
    .slide-enter,
    .slide-leave-to {
        opacity: 0;
        transform: translateX(80%);
    }

    .slide-enter-active {
        transition: all 0.2s ease-out;
    }

    .slide-leave-active {
        transition: all 0.2s ease-in;
    }
</style>
