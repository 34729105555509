<template>
    <div
        :class="[
            $style.badge,
            $style[type]
        ]"
        @click="$emit('click')"
    >
        <slot
            v-if="$slots.before"
            name="before"
        />
        <div :class="$style.content">
            <slot name="default" />
        </div>
        <slot
            v-if="$slots.after"
            name="after"
        />
    </div>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        type: {
            type: String,
            default: 'info',
            validator(value) {
                return [
                    'info',
                    'success',
                    'error',
                    'warning',
                    'grey',
                    'help',
                ].indexOf(value) !== -1;
            },
        },
    },
};
</script>

<style lang="scss" module>
.badge {
    $badge_themes: none;

    display: flex;
    gap: 6px;
    align-items: center;
    padding: 4px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 2px;

    @include themify($themes) {
        $badge_themes: (
            (
                name: 'info',
                attributes: (
                    bg_color: rgba(themed('primary_color'), 0.1),
                    color: themed('primary_color'),
                    svg_color:  themed('primary_color'),
                    svg_fill:  themed('primary_color'),
                )
            ),
            (
                name: 'success',
                attributes: (
                    bg_color: rgba(themed('success_color'), 0.1),
                    color: themed('success_color'),
                    svg_color:  themed('success_color'),
                    svg_fill:  themed('success_color'),
                )
            ),
            (
                name: 'error',
                attributes: (
                    bg_color: rgba(themed('error_color'), 0.1),
                    color: themed('error_color'),
                    svg_color:  themed('error_color'),
                    svg_fill:  themed('error_color'),
                )
            ),
            (
                name: 'warning',
                attributes: (
                    bg_color: rgba(themed('warning_color'), 0.1),
                    color: themed('warning_color'),
                    svg_color:  themed('warning_color'),
                    svg_fill:  themed('warning_color'),
                )
            ),
            (
                name: 'help',
                attributes: (
                    bg_color: rgba(#FFB700, 0.1),
                    color: #FFB700,
                    svg_color:  #FFB700,
                    svg_fill:  #FFB700,
                )
            ),
            (
                name: 'grey',
                attributes: (
                    bg_color: themed('gray_scale_normal'),
                    color: themed('gray_scale_dark'),
                    svg_color:  themed('gray_scale_dark'),
                    svg_fill:  themed('gray_scale_dark'),
                )
            ),
        );
    }

    @each $theme in $badge_themes {
        $name: map-get($theme, name);
        $attributes: map-get($theme, attributes);

        &.#{$name} {
            color: map-get($attributes, color);
            background-color: map-get($attributes, bg_color);

            svg {
                color: map-get($attributes, svg_color);
                fill: map-get($attributes, svg_fill);
            }
        }
    }

    svg {
        width: 18px;
        height: 18px;
    }
}
</style>
