const state = {
    iplink: null,
    iplinks: null,
    iplinks_count: 0,
    iplinksloading: true,
};

const getters = {
    iplink: state => state.iplink,
    iplinks: state => state.iplinks,
    iplinks_count: state => state.iplinks_count,
    iplinksloading: state => state.iplinksloading,
};

const mutations = {
    change_iplink(state, iplink) {
        state.iplink = iplink;
    },
    change_iplinks(state, iplinks) {
        state.iplinks = iplinks;
    },
    change_iplinks_count(state, ipLinksCount) {
        state.iplinks_count = ipLinksCount;
    },
    change_iplinksloading(state, iplinksloading) {
        state.iplinksloading = iplinksloading;
    },
};

function setErrorServer(error) {
    return {
        type: 'error_server',
        status: error.request.status,
        errors: error.response.data,
    };
}

const actions = {

    fetch_iplink({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = `/api/network/iplinks/${params.link_id}/`;

            rootState.axios_api.get(url).then((response) => {
                commit('change_iplink', response.data);
                resolve(response.data);
            }).catch((error) => {
                reject(setErrorServer(error));
            });
        });
    },

    fetch_iplinks({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            const url = '/api/network/iplinks/';

            commit('change_iplinksloading', true);

            rootState.axios_api.get(url, params).then((response) => {
                commit('change_iplinks_count', response.data.count);
                commit('change_iplinksloading', false);
                commit('change_iplinks', response.data.results);
                resolve(response.data);
            }).catch((error) => {
                commit('change_iplinksloading', false);
                reject(setErrorServer(error));
            });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
