var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.values.length > 1
    ? _c(
        "span",
        { class: _vm.$style.oneOrMoreComponent },
        [
          _vm._t(
            "multiple-value",
            function() {
              return [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "translate",
                        rawName: "v-translate",
                        value: { count: _vm.values.length },
                        expression: "{count: values.length}"
                      }
                    ]
                  },
                  [_vm._v("\n            Multiple (%{count})\n        ")]
                )
              ]
            },
            { count: _vm.values.length }
          ),
          _vm._v(" "),
          _c(
            "Tooltip",
            { attrs: { content: _vm.tooltipContent } },
            [_c("MasterSvg", { attrs: { use: "info" } })],
            1
          )
        ],
        2
      )
    : _vm.values.length === 0
    ? _c(
        "span",
        [
          _vm._t("empty-value", function() {
            return [_c("translate", [_vm._v("No data")])]
          })
        ],
        2
      )
    : _c("span", [_vm._v(_vm._s(_vm.values[0]))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }