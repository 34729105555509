var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.stepsList }, [
    _c(
      "div",
      { class: _vm.$style.steps },
      _vm._l(_vm.$slots, function(slot, slotName, index) {
        return _c("div", { key: slotName, class: _vm.$style.step }, [
          _vm.numbersDisplay
            ? _c("div", { class: _vm.$style.number }, [
                _vm._v(
                  "\n                " + _vm._s(index + 1) + "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { class: _vm.$style.text }, [_vm._t(slotName)], 2)
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.pictureSteps },
      [
        _c("MasterSvg", {
          staticClass: "img-fluid",
          attrs: { use: _vm.image, "class-css": _vm.$style.img }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }