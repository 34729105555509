<template>
    <transition :name="transitionName">
        <component
            :is="choicedComponent"
            :init-choiced-advanced="initChoicedAdvanced"
            :readonly-mode="readonlyMode"
            class="bg-white"
            @close-section="changeComponent"
            @click-component="changeComponent"
            @deploy-configuration="handleDeployConfiguration"
        />
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import AdvancedList from './advanced-list.vue';

export default {
    props: {
        readonlyMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            choicedComponent: AdvancedList,
            transitionName: 'none',
            initChoicedAdvanced: '',
        };
    },
    computed: {
        ...mapGetters('equipment', ['hash_prefix']),
    },
    watch: {
        $route: {
            handler() {
                this.controlRoute();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        changeComponent(component = AdvancedList, sectionSuffix = '') {
            if (component !== AdvancedList) {
                this.transitionName = 'slide-up';
            } else {
                this.transitionName = 'slide-down';
            }
            this.changeSection(sectionSuffix);
            this.choicedComponent = component;

            this.$nextTick(() => {
                this.transitionName = 'none';
            });
        },
        controlRoute() {
            const currentHash = this.$route.hash.replace(this.hash_prefix, '');
            let sectionSuffix = '';

            if (currentHash.includes('-')) {
                // eslint-disable-next-line prefer-destructuring
                sectionSuffix = currentHash.split('-')[1];
            } else {
                this.choicedComponent = AdvancedList;
            }

            this.initChoicedAdvanced = sectionSuffix;
        },
        changeSection(sectionSuffix) {
            const nextHash = `#${this.hash_prefix}advanced${
                sectionSuffix ? `-${sectionSuffix}` : ''
            }`;
            const currentHash = this.$route.hash.replace(
                this.hash_prefix,
                '',
            );

            if (currentHash !== nextHash) {
                this.$router.push(nextHash);
            }
        },
        handleDeployConfiguration(params) {
            this.$emit('deploy-configuration', params);
        },
    },
};
</script>

<style lang="scss">
    .none-enter,
    .none-leave {
        opacity: 0;
    }

    .slide-up-enter {
        position: absolute;
        opacity: 0;
        transform: translateX(80%);
    }

    .slide-up-enter-active {
        position: absolute;
        z-index: 2100;
        transition: all 0.2s ease-out;
    }

    .slide-up-leave,
    .slide-up-leave-active {
        position: absolute;
        opacity: 0;
    }

    .slide-down-leave {
        position: absolute;
        z-index: 2100;
    }

    .slide-down-leave-to {
        position: absolute;
        z-index: 2100;
        opacity: 0;
        transform: translateX(80%);
    }

    .slide-down-leave-active {
        position: absolute;
        transition: all 0.2s ease-in;
    }

    .slide-down-enter {
        position: absolute;
        opacity: 0;
    }

    .slide-down-enter-active {
        position: absolute;
        transition: all 0s;
    }
</style>
