var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.linkequipmentconfoptions
    ? _c(
        "div",
        { class: _vm.$style.dmziteminterfaces },
        [
          _vm.type === "checkbox"
            ? _vm._l(_vm.interfaces_choices, function(choice, index) {
                var _obj
                return _c("Choice", {
                  key: index,
                  class: [
                    _vm.$style.port,
                    ((_obj = {}),
                    (_obj[_vm.$style.warning] = _vm.addWarning(choice)),
                    _obj)
                  ],
                  attrs: {
                    id:
                      "iface-" +
                      _vm.equipmentLinkId +
                      "-" +
                      _vm.indexDmz +
                      "-" +
                      choice.value,
                    type: "checkbox",
                    name:
                      "iface-" +
                      _vm.equipmentLinkId +
                      "-" +
                      _vm.indexDmz +
                      "-" +
                      choice.value +
                      "-" +
                      choice.type,
                    label: choice.display_name,
                    disabled: choice.disabled,
                    "info-tooltips": _vm.getInfoTooltips(choice)
                  },
                  model: {
                    value: choice.checked,
                    callback: function($$v) {
                      _vm.$set(choice, "checked", $$v)
                    },
                    expression: "choice.checked"
                  }
                })
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "radio"
            ? [
                _c("RadioButton", {
                  class: _vm.$style.interfaces,
                  attrs: {
                    choices: _vm.interfaces_choices,
                    "custom-class": _vm.$style.port,
                    name: "iface-" + _vm.equipmentLinkId + "-" + _vm.indexDmz
                  },
                  model: {
                    value: _vm.selected_interface,
                    callback: function($$v) {
                      _vm.selected_interface = $$v
                    },
                    expression: "selected_interface"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }