<template>
    <div :class="$style.natsection">
        <div v-if="iplink.mpls || !canIPv4">
            <ManagedSection
                :title="$gettext('Setup NAT rules')"
                :section="$gettext('NAT rules')"
            >
                <template #subtitle>
                    <h3
                        v-if="iplink.mpls"
                        v-translate
                    >
                        An MPLS is deployed and manages this IP link.
                    </h3>
                    <h3
                        v-if="canIPv4"
                        v-translate
                    >
                        This access is configured on IPv6 stack.
                    </h3>
                </template>
            </ManagedSection>
        </div>
        <div v-else>
            <Advice
                :title="$gettext('NAT management')"
                link-url="https://kiosque.unyc.io/document/administration-des-routeurs/nat-adr/"
            >
                <!-- eslint-disable max-len -->
                <template v-slot:text>
                    <p v-translate>
                        A Network Address Translation (NAT) rule allows an on-premises device behind the router to be accessible from the outside internet network.
                    </p>
                    <p>
                        <b v-translate>
                            Did you know : To allow all source IPs you will have to enter 0.0.0.0/0 in the field. To increase the security of your routed devices think about change the default passwords as they will be accessible to all from internet.
                        </b>
                    </p>
                </template>
                <template v-slot:example>
                    <p v-translate>
                        Exemple: To access a webcam on the local network having the IP address 192.168.1.100, from the source IP 6.7.8.9, via the port 1234 of the equipment 185.86.88.85. You will need to set the following rule:
                    </p>
                    <ul>
                        <li v-translate>
                            Source IP: 6.7.8.9
                        </li>
                        <li v-translate>
                            Public IP: 185.86.88.85
                        </li>
                        <li v-translate>
                            Public port: 1234
                        </li>
                        <li v-translate>
                            Private IP: 192.168.1.100
                        </li>
                        <li v-translate>
                            Private port: 1234
                        </li>
                    </ul>
                </template>
            </Advice>
            <UpdatedSection
                section="nat"
            />

            <div
                v-if="linkequipmentconf &&
                    linkequipmentconf.nat &&
                    linkequipmentconf.nat.length
                "
            >
                <div class="row mt-3 mb-4">
                    <Title
                        :title="$gettext('Setup NAT rules')"
                    />
                    <Button
                        role="primary"
                        :text="$gettext('Add NAT rule')"
                        icon="add"
                        size="large"
                        :class="[$style.rightbutton]"
                        class="col-4 ml-auto d-none d-lg-flex"
                        @click="addNatRule()"
                    />
                    <Button
                        role="icon_primary"
                        icon="add"
                        :class="$style.bottombutton"
                        class="d-lg-none"
                        @click="addNatRule()"
                    />
                </div>
                <div
                    class="row"
                    :class="$style.search"
                >
                    <Field
                        v-model="search"
                        :placeholder="$gettext('Search for a NAT rule')"
                        :class="$style.searchField"
                        name="search"
                        size="small"
                        icon-left="search"
                    />
                    <ComponentSimpleFilters
                        v-model="filteringByStatus"
                        :filter-choices="statusFiltersValues"
                    />
                </div>
                <NatList
                    :search="search"
                    :filter-by-status="filteringByStatus"
                    class="row"
                />
            </div>

            <div v-else>
                <Empty
                    :steps="emptysteps"
                    image="nat"
                    :subtitle="$gettext('Manage NAT rules')"
                    @link_clicked="addNatRule()"
                />
                <Button
                    role="primary"
                    :text="$gettext('Add NAT rule')"
                    icon="add"
                    size="large"
                    class="mt-4 col-4 d-none d-lg-flex"
                    @click="addNatRule()"
                />
                <Button
                    role="icon_primary"
                    icon="add"
                    :class="$style.bottombutton"
                    class="d-lg-none"
                    @click="addNatRule()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/common/button.vue';
import Field from '@/components/common/field.vue';
import SpecificationsMixin from './mixins/specifications';
import Advice from './advice.vue';
import NatList from './nat-list.vue';
import Empty from './component-empty.vue';
import ManagedSection from './component-managed-section.vue';
import UpdatedSection from './component-updated-section.vue';
import Title from './component-title.vue';
import ComponentSimpleFilters from './component-simple-filters.vue';

const AddNatRuleModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-addnatrule" */
    './modals/addnatrule.vue'
);

export default {
    components: {
        NatList,
        Advice,
        Button,
        Empty,
        ManagedSection,
        UpdatedSection,
        Field,
        Title,
        ComponentSimpleFilters,
    },
    mixins: [
        SpecificationsMixin,
    ],
    data() {
        return {
            filteringByStatus: 'all',
            search: '',
            emptysteps: [
                {
                    text: this.$gettext('Click on "Add NAT rule"'),
                    textmobile: this.$gettext('Click on "+" button'),
                },
                {
                    text: this.$gettext('Fill in all the fields correctly and validate by clicking on the button'),
                    textmobile: this.$gettext('Fill in all the fields correctly and validate by clicking on the button'),
                },
                {
                    text: this.$gettext('Do not forget to deploy the configuration for equipment to take new changes'),
                    textmobile: this.$gettext('Do not forget to deploy the configuration for equipment to take new changes'),
                },
            ],
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'capabilities',
            'iplink',
            'linkequipment',
            'linkequipmentconf',
        ]),
        statusFiltersValues() {
            return [
                { label: this.$gettext('Enabled'), value: true },
                { label: this.$gettext('Disabled'), value: false },
            ];
        },
        canAddMultiIPsSourceOnNatRule() {
            let canAddMultiIPsSourceOnNatRule = true;

            this.capabilities.forEach((item) => {
                canAddMultiIPsSourceOnNatRule = canAddMultiIPsSourceOnNatRule && item.capabilities.includes('can_nat_multiple_source_ips');
            });

            return canAddMultiIPsSourceOnNatRule;
        },
    },
    methods: {
        addNatRule() {
            const { canAddMultiIPsSourceOnNatRule } = this;
            this.show_modal(
                AddNatRuleModal,
                {
                    canAddMultiIPsSource: canAddMultiIPsSourceOnNatRule,
                },
                {
                    maxHeight: 702,
                },
            );
        },
    },
};
</script>

<style lang="scss" module>
.natsection {
    min-height: 800px;

    .search {
        display: flex;
        gap: 20px;
        margin: 26px 0;

        .searchField {
            flex: 1;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    @include under-to(xl) {
        .c1 {
            font-size: 13px;
            font-weight: 500;
            line-height: 1.08;
        }
    }

    .bottombutton {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1039;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}
</style>
