<template>
    <div class="container">
        <IPSubnets />
    </div>
</template>

<script>
import IPSubnets from '@/components/ipmanager/ipsubnets.vue';

export default {
    name: 'IPManager',
    components: {
        IPSubnets,
    },
};
</script>

<style lang="scss" module>

</style>
