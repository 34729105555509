var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Table",
    {
      attrs: { breakpoint: "lg", loading: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("translate", [_vm._v("Author")]),
              _vm._v(" "),
              _c("translate", [_vm._v("Date")]),
              _vm._v(" "),
              _c("translate", { style: { flex: 3 } }, [
                _vm._v("\n            Comment\n        ")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "skeleton-loader",
          fn: function() {
            return [
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("span", { style: { flex: 3 } })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._l(_vm.configurations, function(configuration) {
        return _c("ConfsTableItem", {
          key: configuration.id,
          attrs: {
            comment: configuration.synchronization_comment,
            "created-at": configuration.creation_date,
            "synchronized-by": configuration.synchronized_by,
            "disabled-actions": _vm.disabledActions
          },
          on: {
            "view-configuration": function($event) {
              return _vm.$emit("view-configuration", configuration)
            },
            "restore-configuration": function($event) {
              return _vm.$emit("restore-configuration", configuration)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }