var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.readonlyBanner }, [
    _c(
      "div",
      { class: _vm.$style.eyeIcon },
      [
        _c("MasterSvg", {
          attrs: { use: "big-eye", width: "76px", height: "90px" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { class: _vm.$style.description }, [
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          class: _vm.$style.title
        },
        [_vm._v("\n            Read only\n        ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [{ name: "translate", rawName: "v-translate" }],
          class: _vm.$style.content
        },
        [
          _vm._v(
            "\n            You have scheduled a deployment, to make changes please cancel the deployment\n        "
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.$style.background },
      [
        _c("MasterSvg", {
          attrs: { use: "sparkles", width: "90%", height: "auto" }
        }),
        _vm._v(" "),
        _c("MasterSvg", {
          attrs: { use: "pattern", width: "auto", height: "100%" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }