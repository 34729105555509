<template>
    <div class="container pt-4">
        <Select
            v-model="valueWidgetChosed"
            :data-list="widgets"
            :required="true"
            class="offset-0 col-12 offset-md-2 col-md-8 bg-white"
            :label="$gettext('Select a Widget')"
            name="widget"
        />
        <Widget
            v-if="widgetChosed"
            :init-method-name="widgetChosed.initMethodName"
            :params="widgetChosed.params"
            :widget-name="valueWidgetChosed"
        />
    </div>
</template>

<script>
/* eslint-disable no-alert */
import Widget from '@/components/widget/widget.vue';
import Select from '@/components/common/select.vue';

export default {
    components: {
        Widget,
        Select,
    },
    data() {
        return {
            valueWidgetChosed: '',
            widgetChosed: null,
            widgets: [
                {
                    text: 'Equipment',
                    id: 'equipment',
                    initMethodName: 'initWidgetEquipment',
                    params: {
                        linkId: 'IP-20190317-115019-SMARTTELECOM',
                        hashPrefix: 'modem_',
                    },
                },
                {
                    text: 'Cloud offers',
                    id: 'pw_cloud',
                    initMethodName: 'initPWCloud',
                    params: {
                        tokenPlanet: '',
                    },
                },
                {
                    text: 'Premium rates',
                    id: 'premium_rate',
                    initMethodName: 'initPremiumRateNumber',
                },
                {
                    text: 'GPS',
                    id: 'gps',
                    initMethodName: 'initGps',
                    params: {
                        initialPosition: '{lat: 53.2734, lng: -7.77832031, zoom: 16}',
                        viewOptions: '{displayCoordinates: false}',
                        positionUpdateCallback(val) {
                            alert(`lat: ${val.lat}, lng: ${val.lng}`);
                        },
                    },
                },
                {
                    text: 'Eligibility settings',
                    id: 'bundle_eligibility_wizard',
                    initMethodName: 'initWidgetEligibilityWizard',
                    params: {
                        orderId: 1400,
                        eligibilityCallback(result) {
                            alert(`status: ${result.status}, request_id: ${result.id}, address ${result.address.postal_code}`);
                        },
                    },
                },
                {
                    text: 'Eligibility results',
                    id: 'bundle_eligibility_results',
                    initMethodName: 'initWidgetEligibilityResults',
                    params: {
                        requestId: 69,
                        simultaneousCommunications: 5,
                        eligibilityResultCallback(offer) {
                            alert(`selected_offer: ${offer}`);
                        },
                    },
                },
                {
                    text: 'Add rows to quote',
                    id: 'bundle_add_to_quote_modal',
                    initMethodName: 'initWidgetAddToQuoteModal',
                    params: {
                        availableUnbundlingTypes: "['addr', 'nd']",
                        addToQuoteCallback(orderRow, quantity, unitPrice, reason) {
                            alert(`sda row: ${orderRow}, quantity: ${quantity}, unit price: ${unitPrice}, reason: ${reason}`);
                        },
                    },
                },
                {
                    text: 'Edit number settings',
                    id: 'bundle_numbers_settings_edit',
                    initMethodName: 'initWidgetNumbersSettingsEdit',
                    params: {
                        numbersSettingsEditCallback(object) {
                            alert(`unit price: ${object.unitPrice}`);
                        },
                        unitPrice: 0.40,
                        qtyNumberToCreate: 2,
                        qtyNumberToPort: 4,
                    },
                },
                {
                    text: 'Technical annex headband',
                    id: 'bundle_technical_annex_headband',
                    initMethodName: 'initWidgetTechnicalAnnexHeadBand',
                    params: {
                        orderId: 1158,
                        technicalAnnexHeadBandCallback() {
                            alert('Complete technical annexes');
                        },
                    },
                },
                {
                    text: 'Technical annex',
                    id: 'bundle_technical_annex',
                    initMethodName: 'initWidgetTechnicalAnnex',
                    params: {
                        orderId: 1158,
                        technicalAnnexCallback() {
                            alert('Back to order');
                        },
                    },
                },
            ],
        };
    },
    watch: {
        valueWidgetChosed(val) {
            this.widgets.forEach((element) => {
                if (element.id === val) {
                    this.widgetChosed = element;
                }
            });
        },
    },
};

</script>

<style lang="scss" module>

</style>
