<template>
    <div class="container">
        <IPLinks />
    </div>
</template>

<script>
import IPLinks from '@/components/iplinks/iplinks.vue';

export default {
    name: 'Iplinks',
    components: {
        IPLinks,
    },
};
</script>

<style lang="scss" module>

</style>
