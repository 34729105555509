var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.specifications }, [
    _c("div", { staticClass: "d-block d-lg-flex flex-lg-row" }, [
      _c(
        "div",
        { class: _vm.$style.left_block },
        [
          _c("div", { class: _vm.$style.title }, [_vm._t("title")], 2),
          _vm._v(" "),
          _vm._t("image", function() {
            return [
              _c("div", { class: _vm.$style.picture_background }, [
                _c("div", {
                  class: _vm.$style.picture,
                  style: "background-image: url('" + _vm.image + "')"
                })
              ])
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.right_block },
        [
          _vm._l(_vm.specifications, function(spec, index) {
            return _c("ComponentSpecificationsItem", {
              key: index,
              attrs: { label: spec.label, value: spec.value }
            })
          }),
          _vm._v(" "),
          _vm._t("specifications")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }