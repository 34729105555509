<template>
    <div class="container">
        <div class="row">
            <IPLink
                v-if="iplink"
                :iplink="iplink"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import IPLink from '@/components/iplink/iplink.vue';
import {
    showSwalError,
} from '@/utils/utils';

export default {
    components: {
        IPLink,
    },
    computed: {
        ...mapGetters('iplinks', [
            'iplink',
        ]),
    },
    created() {
        this.change_iplink(null);
    },
    mounted() {
        this.fetch_iplink({
            link_id: this.$route.params.link_id,
        }).catch(showSwalError);
    },
    methods: {
        ...mapMutations('iplinks', [
            'change_iplink',
        ]),
        ...mapActions('iplinks', [
            'fetch_iplink',
        ]),
    },
};
</script>

<style lang="scss" module>

</style>
