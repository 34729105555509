<template>
    <div :class="$style.tag">
        <div v-if="$slots.before">
            <slot name="before" />
        </div>
        <div :class="$style.content">
            <slot name="default" />
        </div>
        <div :class="[$style.statusbullet, statusClass]" />
        <div v-if="$slots.after">
            <slot name="after" />
        </div>
    </div>
</template>

<script>
/**
 * Simple tag with a green or red point indicating the status
 */
export default {
    name: 'TagStatus',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        statusClass() {
            return this.active ? this.$style.active : this.$style.inactive;
        },
    },
};
</script>

<style lang="scss" module>
.tag {
    display: flex;
    gap: 10px;
    align-items: center;

    .statusbullet {
        width: 7px;
        height: 7px;
        border-radius: 50%;

        &.active {
            @include themify($themes) {
                background-color: themed('success_color');
            }
        }

        &.inactive {
            @include themify($themes) {
                background-color: themed('gray_scale_dark');
            }
        }
    }
}
</style>
