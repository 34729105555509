var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.wans_section },
    [
      _c("Advice", {
        attrs: { title: _vm.section_title },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                WAN is the connection between customer router and operator network.\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                xDSL or 4G links require modem, either integrated in the customer router or in separated device between the customer router and operator network.\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                If necessary, WAN port can be modified.\n            "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("UpdatedSection", {
        attrs: { section: "wans", disabled: _vm.readonlyMode }
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "row mt-3 mb-4" },
            [
              _c("Title", { attrs: { title: _vm.section_title } }),
              _vm._v(" "),
              _vm.isVrrp
                ? _c("Select", {
                    staticClass: "col align-self-center",
                    class: _vm.$style.equipements_select,
                    attrs: {
                      "data-list": _vm.equipments_list,
                      role: "small",
                      name: "equipments_list"
                    },
                    model: {
                      value: _vm.equipment_link_id,
                      callback: function($$v) {
                        _vm.equipment_link_id = $$v
                      },
                      expression: "equipment_link_id"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("WansList", {
            attrs: {
              "current-equipment-conf": _vm.current_equipment_conf,
              errors: _vm.current_errors,
              "readonly-mode": _vm.readonlyMode
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_modified && !_vm.readonlyMode,
                  expression: "is_modified && !readonlyMode"
                }
              ],
              staticClass:
                "row p-0 offset-0 col-12 offset-lg-3 col-lg-9 offset-xl-5 col-xl-7",
              class: _vm.$style.validatebuttons
            },
            [
              _c("Button", {
                staticClass: "mt-3 mt-lg-0 p-0 col-12 col-lg-5",
                class: [_vm.$style.rightbutton, _vm.$style.validatebutton],
                attrs: {
                  role: "secondary",
                  text: _vm.$gettext("Cancel"),
                  size: "large"
                },
                on: {
                  click: function($event) {
                    return _vm.initEquipmentsConfigurations()
                  }
                }
              }),
              _vm._v(" "),
              _c("Button", {
                staticClass:
                  "mt-3 mt-lg-0 p-0 offset-0 col-12 offset-lg-1 col-lg-6",
                class: [_vm.$style.rightbutton, _vm.$style.validatebutton],
                attrs: {
                  role: "primary",
                  text: _vm.$gettext("Validate configuration"),
                  size: "large"
                },
                on: {
                  click: function($event) {
                    return _vm.validate()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }