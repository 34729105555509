<template>
    <div :class="$style.lansection">
        <div>
            <Advice
                :title="$gettext('LANs configuration')"
                link-url="https://kiosque.unyc.io/document/administration-des-routeurs/lan-adr/"
            >
                <!-- eslint-disable max-len -->
                <template v-slot:text>
                    <translate tag="p">
                        A LAN (Local Area Network) is a network that allows you to communicate with one another without using internet access.
                    </translate>
                    <translate tag="p">
                        Our router management interface allows you to configure and interconnect different LANs (depending on model and ports available).
                    </translate>
                    <translate tag="p">
                        IPv4 and IPv6 options allow the automatic IP configuration of the equipment connected to the router.
                    </translate>
                    <p v-if="validIPVersions.includes(4)">
                        <translate>
                            In IPv4, the automatic configuration of IP addresses of a device within a LAN is done through DHCP (Dynamic Host Configuration Protocol).
                        </translate>
                        <br>
                        <translate>
                            Assign a static IP to an equipment by specifying its MAC address.
                        </translate>
                    </p>
                    <p v-if="validIPVersions.includes(6)">
                        <translate>
                            In IPv6, the automatic configuration of IP addresses of a device within a LAN is done through the SLAAC protocol (Stateless Automatic Auto Configuration).
                        </translate>
                        <br>
                        <translate>
                            In some cases, DHCPv6 can be activated in addition to SLAAC in order to assign IPv6 addresses of a specific range to a device.
                        </translate>
                    </p>
                </template>
                <template v-slot:example>
                    <translate>
                        Exemple: On port 1, you can configure a LAN to 192.168.X.X and on port 2 a LAN to 10.0.X.X
                    </translate>
                </template>
            </Advice>
            <UpdatedSection
                section="lans"
            />

            <div
                v-if="lans.length"
                class="row mt-3 mb-4"
            >
                <Title
                    :title="$gettext('LANs configuration')"
                />
                <Button
                    ref="top_add_button"
                    role="primary"
                    :text="$gettext('Add LAN')"
                    icon="add"
                    size="large"
                    :class="[$style.rightbutton]"
                    :disabled="disabledAddButton"
                    class="col-4 ml-auto d-none d-lg-flex"
                    @click="addLan()"
                />
                <Button
                    role="icon_primary"
                    icon="add"
                    :class="[$style.bottombutton]"
                    :disabled="disabledAddButton"
                    class="d-lg-none"
                    @click="addLan()"
                />
            </div>

            <div
                v-show="lans.length"
                class="row-12"
            >
                <ul
                    v-if="linkequipmentconfoptions"
                    :class="$style.lans"
                >
                    <li
                        v-for="(lan, index) in lans"
                        :key="index"
                        :ref="'lan-' + (index + 1)"
                    >
                        <LanItem
                            :index-lan="index"
                            :lan="lan"
                            :equipments-configurations="equipments_configurations"
                            :selected-interfaces="selected_interfaces"
                            :lan-errors="lans_errors[index]"
                            :equipments-errors="equipments_errors"
                            :wan-choices="wanChoices"
                            @update-main-exit-wan="handleUpdateMainExitWan"
                            @remove="removeLan(index)"
                        />
                    </li>
                </ul>
                <ul
                    v-else
                    :class="$style.lans"
                >
                    <li>
                        <LanItemSkeleton />
                    </li>
                </ul>
            </div>
            <div
                v-show="!lans.length"
            >
                <LansEmpty
                    :steps="emptysteps"
                    :subtitle="subtitle"
                    image="lan"
                />

                <Button
                    role="primary"
                    :text="$gettext('Add LAN')"
                    icon="add"
                    size="large"
                    class="mt-4 col-4 d-none d-lg-flex"
                    @click="addLan()"
                />

                <Button
                    role="icon_primary"
                    icon="add"
                    :class="[$style.bottombutton]"
                    class="d-lg-none"
                    @click="addLan()"
                />
            </div>

            <div :class="$style.validatebuttons">
                <div>
                    <Button
                        v-if="show_bottom_button"
                        role="primary"
                        :text="$gettext('Add LAN')"
                        icon="add"
                        size="large"
                        :class="[$style.rightbutton, $style.validatebutton]"
                        :disabled="disabledAddButton"
                        class="p-0 col-12 col-lg-6"
                        @click="addLan()"
                    />
                </div>
                <div>
                    <Button
                        v-if="is_modified"
                        role="secondary"
                        :text="$gettext('Cancel')"
                        size="large"
                        :class="[$style.rightbutton, $style.validatebutton]"
                        class="p-0 col-12 col-lg-5"
                        @click="initLinkEquipmentConfLan()"
                    />
                    <Button
                        v-if="is_modified"
                        role="primary"
                        :text="$gettext('Validate configuration')"
                        size="large"
                        :class="[$style.rightbutton, $style.validatebutton]"
                        class="p-0 offset-0 col-12 offset-lg-1 col-lg-6"
                        @click="handleFormSubmit()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
    showSwal,
    showSwalError,
    isInViewport,
} from '@/utils/utils';
import {
    getFirstIPFromNetwork,
    getLastIPFromNetwork,
    getPreviousIPAddress,
    getNextIPAddress,
    generateNetworkFromMasterNetwork,
} from '@/utils/network';

import {
    actionsControl,
} from './utils/utils';
import Advice from './advice.vue';
import LanItem from './lanitem.vue';
import LanItemSkeleton from './lanitem-skeleton.vue';
import LansEmpty from './component-empty.vue';
import UpdatedSection from './component-updated-section.vue';
import Title from './component-title.vue';
import Button from '../common/button.vue';
import SpecificationsMixin from './mixins/specifications';

const IPCIDR = require('ip-cidr');

const InformationModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-information" */
    './modals/information.vue'
);

export default {
    components: {
        LanItemSkeleton,
        LanItem,
        Advice,
        LansEmpty,
        Button,
        UpdatedSection,
        Title,
    },
    mixins: [
        SpecificationsMixin,
    ],
    data() {
        return {
            search: null,
            maxLanNumber: 0,
            selected_interfaces: [],
            lans: [],
            equipments_configurations: [],
            lans_errors: [],
            equipments_errors: [],
            show_bottom_button: false,
            mostUsedMainExitWan: null,
            emptysteps: [
                {
                    text: this.$gettext('Click on "Add LAN"'),
                    textmobile: this.$gettext('Click on "+" button'),
                },
                {
                    text: this.$gettext('Fill in all the fields correctly and validate by clicking on the button'),
                    textmobile: this.$gettext('Fill in all the fields correctly and validate by clicking on the button'),
                },
                {
                    text: this.$gettext('Do not forget to deploy the configuration for equipment to take new changes'),
                    textmobile: this.$gettext('Do not forget to deploy the configuration for equipment to take new changes'),
                },
            ],
            subtitle: this.$gettext('Add LAN'),
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconf',
            'linkequipmentconfoptions',
            'virtual_linkequipment_members',
        ]),
        ...mapGetters([
            'permissions',
        ]),
        disabledAddButton() {
            const { lans, maxLanNumber } = this;
            if (maxLanNumber === null) return false;
            return lans.length >= maxLanNumber;
        },
        can_manage_router() {
            return this.permissions.find(
                element => element.codename === 'can_manage_router' && element.app_label === 'equipments',
            ) !== undefined;
        },
        is_modified() {
            if (!this.linkequipmentconf) {
                return false;
            }

            const lanIsUpdated = JSON.stringify(
                this.lans,
            ) !== JSON.stringify(
                this.linkequipmentconf.lans || [],
            );
            const equipmentIsUpdated = JSON.stringify(
                this.equipments_configurations,
            ) !== JSON.stringify(
                this.linkequipmentconf.equipments || [],
            );

            return lanIsUpdated || equipmentIsUpdated;
        },
        wanChoices() {
            const choices = [];

            if (this.linkequipmentconfoptions && this.linkequipmentconfoptions.actions) {
                this.linkequipmentconfoptions.actions.PUT.lans
                    .child.children.main_exit_wan.choices.forEach((item) => {
                        choices.push({
                            id: item.value,
                            text: item.display_name,
                        });
                    });
            }

            return choices;
        },
    },
    watch: {
        wanChoices(value) {
            if (value.length === 1) this.mostUsedMainExitWan = value[0].id;
        },
        equipments_configurations: {
            handler() {
                if (!this.equipments_configurations) {
                    return;
                }

                this.selected_interfaces = [];
                this.equipments_configurations.forEach((equipment) => {
                    let selectedInterfacesEquipment = [];
                    (equipment.lans || []).forEach((lan) => {
                        if (lan.interfaces) {
                            selectedInterfacesEquipment = Array.from([
                                ...selectedInterfacesEquipment,
                                ...lan.interfaces,
                            ]);
                        }
                    });
                    this.selected_interfaces.push({
                        link_id: equipment.link_id,
                        interfaces: selectedInterfacesEquipment,
                    });
                });
            },
            deep: true,
            immediate: true,
        },
        linkequipmentconf: {
            handler() {
                this.initLinkEquipmentConfLan();
            },
            deep: true,
            immediate: true,
        },
        linkequipmentconfoptions: {
            handler(linkequipmentconfoptions) {
                if (!linkequipmentconfoptions) {
                    return;
                }
                const nbDmz = (this.linkequipmentconf.dmz || []).length;
                const maxVirtualInterfaces = linkequipmentconfoptions.max_virtual_interfaces_number;
                this.maxLanNumber = maxVirtualInterfaces;
                if (maxVirtualInterfaces) {
                    this.maxLanNumber = maxVirtualInterfaces - nbDmz;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.initLinkEquipmentConfLan();
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        this.clearFormErrors();
    },
    mounted() {
        this.fetch_linkequipmentconf().catch(showSwalError);
    },
    methods: {
        ...mapActions('equipment', [
            'fetch_linkequipmentconf',
            'set_conf_section',
        ]),
        addLan() {
            const lanId = Math.random().toString(36).substr(2, 9);
            const addresings = this.validIPVersions.map(
                ipVersion => this[`initIPv${ipVersion}Set`](),
            );
            const defaultLan = {
                id: lanId,
                vlan_id: this.lans.length + 1,
                main_exit_wan: this.mostUsedMainExitWan,
                addressing: addresings,
            };

            if (this.isVrrp) {
                this.virtual_linkequipment_members.forEach((members, index) => {
                    if (!this.equipments_configurations[index].lans) {
                        this.equipments_configurations[index].lans = [];
                    }

                    const defaultLanManagement = {};
                    if (this.canIPv4) {
                        const ipv4Addressing = addresings.find(item => item.ip_version === 4);
                        const ipv4Cidr = new IPCIDR(`${ipv4Addressing.ip}/${ipv4Addressing.netmask}`);
                        const methodToGenerateIp = (
                            getLastIPFromNetwork(ipv4Cidr) === ipv4Addressing.ip
                                ? getPreviousIPAddress
                                : getNextIPAddress
                        );

                        defaultLanManagement.ipv4 = {
                            ip_address: methodToGenerateIp(
                                ipv4Addressing.ip,
                                ipv4Addressing.netmask,
                                index + 1,
                            ),
                        };
                    }
                    if (this.canIPv6) {
                        const ipv6Addressing = addresings.find(item => item.ip_version === 6);
                        defaultLanManagement.ipv6 = {
                            ip_address: getNextIPAddress(
                                ipv6Addressing.ip,
                                ipv6Addressing.netmask,
                                index + 1,
                            ),
                        };
                    }

                    this.equipments_configurations[index].lans.push(
                        {
                            lan_id: lanId,
                            interfaces: [],
                            management: defaultLanManagement,
                        },
                    );
                });
            } else {
                if (!this.equipments_configurations[0].lans) {
                    this.equipments_configurations[0].lans = [];
                }
                this.equipments_configurations[0].lans.push(
                    {
                        lan_id: lanId,
                        interfaces: [],
                    },
                );
            }
            this.lans.push(defaultLan);
            this.lans_errors.push({});

            this.$nextTick(() => {
                window.scrollTo(
                    0,
                    this.$refs[`lan-${this.lans.length}`][0].offsetTop,
                );
            });
        },
        removeLan(index) {
            const lan = this.lans[index];

            this.equipments_configurations.forEach((equipmentConfigurations) => {
                let exitedLan = equipmentConfigurations.lans.find(
                    lanConfig => lanConfig.lan_id === lan.id,
                );

                if (lan.uuid) {
                    exitedLan = equipmentConfigurations.lans.find(
                        lanConfig => lanConfig.lan_uuid === lan.uuid,
                    );
                }

                if (exitedLan) {
                    const lanIndex = equipmentConfigurations.lans.indexOf(exitedLan);
                    equipmentConfigurations.lans.splice(
                        lanIndex,
                        1,
                    );
                }
            });

            this.lans.splice(index, 1);
            this.lans_errors.splice(index, 1);
        },
        handleLanErrors(result) {
            this.lans_errors = result.errors.lans || [];

            let content = `<div class=${this.$style.sweeterrors}><ul>`;
            if (this.lans_errors.length) {
                content += `<li>${this.$gettext('Fields errors, check the form again')}</li>`;
            }

            (result.errors.non_field_errors || []).forEach((error) => {
                content += `<li>${error}</li>`;
            });
            content += '</ul></div>';

            showSwal({
                title: this.$gettext('LAN configuration save failed'),
                html: content,
                type: 'error',
                confirmButtonText: this.$gettext('Edit configuration'),
                showCloseButton: true,
            });
        },
        handleEquipmentsErrors(result) {
            this.equipments_errors = result.errors.equipments || [];

            let content = `<div class=${this.$style.sweeterrors}>`;
            (this.equipments_errors).forEach((equipmentErrors, index) => {
                const lanErrors = (equipmentErrors.lans || []);
                if (lanErrors.length || equipmentErrors.non_field_errors) {
                    const name = this.$gettextInterpolate(
                        this.$gettext('Equipment %{n}'),
                        { n: index + 1 },
                    );
                    content += `<span>${name}</span>`;
                }
                content += '<ul>';
                if (lanErrors.length) {
                    content += `<li>${this.$gettext('Fields errors, check the form again')}</li>`;
                }

                (equipmentErrors.non_field_errors || []).forEach((error) => {
                    content += `<li>${error}</li>`;
                });

                content += '</ul>';
            });
            content += '</div>';

            showSwal({
                title: this.$gettext('LAN configuration save failed'),
                html: content,
                type: 'error',
                confirmButtonText: this.$gettext('Edit configuration'),
                showCloseButton: true,
            });
        },
        async handleEquipmentsSuccess() {
            if (this.isMpls && !this.can_manage_router) {
                await this.show_modal(
                    InformationModal,
                    {
                        title: this.$gettext('Updating LAN configuration'),
                        type: 'info',
                        showCancelButton: false,
                        paragraphs: [
                            this.$gettext('You will add or modify a LAN to your MPLS.'),
                            this.$gettext('In order to avoid any risk of incident, we invite you to ensure that this network is not already present on another site, as the same lan cannot be routed twice within the same MPLS network.'),
                            this.$gettext('If this new network does not exit to the Internet, please contact support as configuration on the Internet exit firewall may be required.'),
                            this.$gettext('Please note that you will be asked for the updated MPLS CDC, we strongly advise you to modify this document according to your LAN additions so that it reflects the MPLS configuration as much as possible.'),
                            this.$gettext('Thank you for your understanding.'),
                        ],
                    },
                    {
                        height: 610,
                        width: 610,
                        'hide-close': true,
                    },
                );
            }

            showSwal({
                title: this.$gettext('LAN configuration saved'),
                text: this.$gettext('You must deploy configuration in order to apply modifications on equipment'),
                type: 'success',
                showCancelButton: true,
                confirmButtonText: this.$gettext('Deploy now'),
                cancelButtonText: this.$gettext('Later'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) {
                    return;
                }

                this.$emit('deploy-configuration');
            });
        },
        handleFormSubmit() {
            showSwal({
                title: this.$gettext('Are you sure you want to save configuration'),
                text: this.$gettext('Modifying LAN configuration could impact existing Route, NAT rules and Firewall rules.'),
                type: 'question',
                showCancelButton: true,
                confirmButtonText: this.$gettext('Save configuration'),
                cancelButtonText: this.$gettext('Cancel'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) {
                    return;
                }

                this.clearFormErrors();
                actionsControl(this.linkequipment).then(() => {
                    this.set_conf_section({
                        section: 'lans',
                        conf: this.lans,
                        freeze_datas: true,
                    }).then((conf) => {
                        this.linkequipmentconf.equipments.forEach((equipmentConfigurations) => {
                            let usedInterfaces = [];
                            const currentConfiguration = this.equipments_configurations.find(
                                equipment => equipment.link_id === equipmentConfigurations.link_id,
                            );

                            (equipmentConfigurations.lans || []).forEach((lan) => {
                                usedInterfaces = [...usedInterfaces, ...lan.interfaces || []];
                            });
                            currentConfiguration.interfaces.forEach((iface) => {
                                if (usedInterfaces.includes(iface.port_number)) {
                                    iface.enabled = false;
                                }
                            });
                        });
                        this.equipments_configurations.forEach((equipmentConfigurations) => {
                            let usedInterfaces = [];
                            (equipmentConfigurations.lans || []).forEach((lan, index) => {
                                lan.lan_uuid = conf.lans[index].uuid;
                                usedInterfaces = [...usedInterfaces, ...lan.interfaces];
                            });
                            equipmentConfigurations.interfaces.forEach((iface) => {
                                if (usedInterfaces.includes(iface.port_number)) {
                                    iface.enabled = true;
                                }
                            });
                        });
                        this.set_conf_section({
                            section: 'equipments',
                            conf: this.equipments_configurations,
                        }).then(
                            this.handleEquipmentsSuccess,
                        ).catch(
                            this.handleEquipmentsErrors,
                        );
                    }).catch(
                        this.handleLanErrors,
                    );
                }).catch(showSwalError);
            });
        },
        clearFormErrors() {
            this.equipments_errors = [];
            this.lans_errors = [];
        },
        initLinkEquipmentConfLan() {
            this.clearFormErrors();

            if (!this.linkequipmentconf) {
                return;
            }

            this.lans = JSON.parse(JSON.stringify(
                this.linkequipmentconf.lans || [],
            ));
            this.equipments_configurations = JSON.parse(JSON.stringify(
                this.linkequipmentconf.equipments || [],
            ));

            this.lans.forEach((lan) => {
                this.lans_errors.push({});

                if (this.isMpls && !this.isVrrp && !this.mostUsedMainExitWan) {
                    this.mostUsedMainExitWan = lan.main_exit_wan;
                }

                // Init ip sets of LAN
                const indexIPv4Set = lan.addressing.findIndex(x => x.ip_version === 4);
                if (this.canIPv4) {
                    if (indexIPv4Set === -1) {
                        lan.addressing.unshift(this.initIPv4Set());
                    }
                } else if (indexIPv4Set > -1) {
                    lan.addressing.splice(indexIPv4Set, 1);
                }

                const indexIPv6Set = lan.addressing.findIndex(x => x.ip_version === 6);
                if (this.canIPv6) {
                    if (indexIPv6Set === -1) {
                        lan.addressing.push(this.initIPv6Set());
                    }
                } else if (indexIPv6Set > -1) {
                    lan.addressing.splice(indexIPv6Set, 1);
                }

                // Init equipment configuration of LAN
                this.equipments_configurations.forEach((equipmentConfigurations, index) => {
                    if (!equipmentConfigurations.lans) {
                        equipmentConfigurations.lans = [];
                    }

                    const exitedLan = equipmentConfigurations.lans.find(
                        lanConfig => lanConfig.lan_uuid === lan.uuid,
                    );

                    const defaultLanManagement = {};
                    if (this.canIPv4) {
                        const ipv4Addressing = lan.addressing.find(item => item.ip_version === 4);
                        const ipv4Cidr = new IPCIDR(`${ipv4Addressing.ip}/${ipv4Addressing.netmask}`);
                        const methodToGenerateIp = (
                            getLastIPFromNetwork(ipv4Cidr) === ipv4Addressing.ip
                                ? getPreviousIPAddress
                                : getNextIPAddress
                        );

                        defaultLanManagement.ipv4 = {
                            ip_address: methodToGenerateIp(
                                ipv4Addressing.ip,
                                ipv4Addressing.netmask,
                                index + 1,
                            ),
                        };
                    }
                    if (this.canIPv6) {
                        const ipv6Addressing = lan.addressing.find(item => item.ip_version === 6);
                        defaultLanManagement.ipv6 = {
                            ip_address: getNextIPAddress(
                                ipv6Addressing.ip,
                                ipv6Addressing.netmask,
                                index + 1,
                            ),
                        };
                    }

                    if (!exitedLan) {
                        equipmentConfigurations.lans.push({
                            lan_uuid: lan.uuid,
                            interfaces: [],
                            management: this.isVrrp ? defaultLanManagement : null,
                        });
                    } else {
                        this.$set(exitedLan, 'interfaces', exitedLan.interfaces || []);
                        if (this.isVrrp) {
                            this.$set(
                                exitedLan,
                                'management',
                                { ...defaultLanManagement, ...exitedLan.management },
                            );
                        }
                    }
                });
            });

            this.equipments_configurations.forEach(() => {
                this.equipments_errors.push({});
            });
        },
        handleScroll() {
            if (this.$refs.top_add_button) {
                this.show_bottom_button = !isInViewport(this.$refs.top_add_button.$el);
            } else {
                this.show_bottom_button = false;
            }
        },
        init_default_addressing_block() {
            return {
                ip_version: null,
                ip: null,
                netmask: null,
                dhcp: {
                    enabled: false,
                    type: 'server',
                    lease_duration: 86400,
                    address_ranges: [],
                    static_addresses: [],
                },
            };
        },
        initIPv4Set() {
            const defaultSet = this.init_default_addressing_block();
            defaultSet.ip_version = 4;
            defaultSet.ip = this.generateNextIPv4Lan();
            defaultSet.netmask = 24;
            return defaultSet;
        },
        initIPv6Set() {
            let masterNetworkLan = this.linkequipment._access.ipv6_lan_subnet;
            if (this.isVrrp) {
                masterNetworkLan = this.virtual_linkequipment_members[0]._access.ipv6_lan_subnet;
            }
            if (Array.isArray(masterNetworkLan)) [masterNetworkLan] = masterNetworkLan;

            const defaultSet = this.init_default_addressing_block();
            defaultSet.slaac = { enabled: true };

            defaultSet.ip_version = 6;
            defaultSet.ip = this.generateNextIPv6Lan(masterNetworkLan);
            defaultSet.netmask = 64;
            return defaultSet;
        },
        generateNextIPv6Lan(cidr) {
            const usedCidrs = [];
            this.lans.forEach((lan) => {
                lan.addressing.forEach((addressing) => {
                    if (addressing.ip_version === 6 && addressing.ip && addressing.netmask) {
                        const ipv6Cidr = new IPCIDR(`${addressing.ip}/${addressing.netmask}`);
                        const firstIpAddress = ipv6Cidr.addressStart.addressMinusSuffix;
                        usedCidrs.push(`${firstIpAddress}/${addressing.netmask}`);
                    }
                });
            });

            const lanCidr = generateNetworkFromMasterNetwork(cidr, 6, 64, usedCidrs);

            return getFirstIPFromNetwork(lanCidr);
        },
        generateNextIPv4Lan() {
            const usedCidrs = [];
            this.lans.forEach((lan) => {
                lan.addressing.forEach((addressing) => {
                    if (addressing.ip_version === 4 && addressing.ip && addressing.netmask) {
                        const ipv4Cidr = new IPCIDR(`${addressing.ip}/${addressing.netmask}`);
                        const firstIpAddress = ipv4Cidr.addressStart.addressMinusSuffix;
                        usedCidrs.push(`${firstIpAddress}/${addressing.netmask}`);
                    }
                });
            });

            const lanCidr = generateNetworkFromMasterNetwork('192.168.0.0/16', 4, 24, usedCidrs, 1n);

            return getFirstIPFromNetwork(lanCidr);
        },
        handleUpdateMainExitWan(mainExitWan) {
            if (!this.isMpls || this.isVrrp) return;
            this.mostUsedMainExitWan = mainExitWan;
            this.lans.forEach((lan) => {
                if (lan.main_exit_wan !== mainExitWan) {
                    lan.main_exit_wan = mainExitWan;
                }
            });
        },
    },
};
</script>

<style lang="scss" module>
.lansection {
    .validatebuttons {
        display: flex;
        flex-direction: row;

        & > div {
            display: flex;
            flex: 1;
            flex-direction: row;

            @include under-to(lg) {
                flex-direction: column;
            }
        }

        @include under-to(lg) {
            flex-direction: column;
            margin: auto;
        }
    }

    .rightbutton {
        min-width: 200px;
    }

    .bottombutton {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1039;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .lans {
        margin-top: 30px;
    }

    .lans > * + * {
        margin-top: 40px;
    }

    .validatebutton {
        min-width: auto;
        margin-top: 30px;
    }
}
</style>
