var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListItem",
    {
      class: _vm.$style.connectedDevicesTableItem,
      attrs: { breakpoint: "xl" },
      scopedSlots: _vm._u([
        {
          key: "after",
          fn: function() {
            return [
              _vm.canAddNatRule || _vm.canFirewallRule
                ? _c(
                    "DropdownMenu",
                    { attrs: { position: "right", size: "large" } },
                    [
                      _vm.canAddNatRule
                        ? _c("DropdownMenuItem", {
                            attrs: {
                              icon: "key",
                              name: _vm.$gettext("Create NAT rule")
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "create_nat_rule",
                                  _vm.ipv4Address
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canFirewallRule
                        ? _c("DropdownMenuItem", {
                            attrs: {
                              icon: "wan2lan",
                              name: _vm.$gettext("Create Firewall rule")
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "create_firewall_rule",
                                  _vm.ipv6AddressToUseInFirewallRule
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _c("div", { style: { width: "14px" } })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "span",
        { style: { "font-weight": "bold", "text-transform": "uppercase" } },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.connectedDevice.mac_address) + "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.connectedDevice.hostname || " "))]),
      _vm._v(" "),
      _vm.canIPv4
        ? _c("span", [
            _vm._v(_vm._s(_vm.ipv4Address || _vm.$gettext("No IP address")))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canIPv6
        ? _c("OneOrMore", {
            attrs: { values: _vm.ipv6Addresses },
            scopedSlots: _vm._u(
              [
                {
                  key: "empty-value",
                  fn: function() {
                    return [_c("translate", [_vm._v("No IP address")])]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3000359729
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.connectedDevice.interface || " "))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }