<template>
    <Table
        breakpoint="lg"
        empty-results-icon="equipment_result"
        empty-results-icon-module="equipment"
        paginate
        :page-number.sync="currentPage"
        :items-per-page="itemsPerPage"
        :items-count="filteredRoutes.length"
    >
        <!-- Header -->
        <template #header>
            <translate :style="{ flex: 2 }">
                Destination IP
            </translate>
            <translate :style="{ flex: 2 }">
                Netmask
            </translate>
            <translate :style="{ flex: 2 }">
                Gateway
            </translate>
            <translate>Metric</translate>
        </template>

        <!-- Filter -->
        <template #filters>
            <div :class="$style.routesTableFiltersBlock">
                <Field
                    v-model="search"
                    :placeholder="$gettext('Search for a static route')"
                    :class="$style.searchField"
                    name="search"
                    size="small"
                    icon-left="search"
                />
                <ComponentSimpleFilters
                    v-if="ipVersionsList.length > 1"
                    v-model="internalSelectedIpVersion"
                    :filter-choices="ipVersionsList"
                    :style="{ marginLeft: 'auto' }"
                />
            </div>
        </template>

        <!-- Content -->
        <RoutesTableItem
            v-for="route in visibleRoutes"
            :key="route.uuid"
            v-bind="{...route}"
            :disabled-actions="disabledActions"
            @edit-route="$emit('edit-route', route)"
            @delete-route="$emit('delete-route', route)"
        />
    </Table>
</template>

<script>
import Field from '@/components/common/field.vue';

import Table from '../layout/table.vue';

import ComponentSimpleFilters from '../component-simple-filters.vue';

import SpecificationsMixin from '../mixins/specifications';

import RoutesTableItem from './routes-table-item.vue';

export default {
    components: {
        ComponentSimpleFilters,
        Field,
        RoutesTableItem,
        Table,
    },
    mixins: [
        SpecificationsMixin,
    ],
    props: {
        routes: {
            type: Array,
            required: true,
        },
        disabledActions: {
            type: Boolean,
            default: false,
        },
        selectedIpVersion: {
            type: [String, Number],
            default: 'all',
        },
    },
    data: () => ({
        search: '',
        currentPage: 0,
        itemsPerPage: 5,
    }),
    computed: {
        internalSelectedIpVersion: {
            get() {
                return this.selectedIpVersion;
            },
            set(value) {
                this.$emit('update:selectedIpVersion', value);
            },
        },
        ipVersionsList() {
            const choices = [];
            if (this.validIPVersions.includes(4)) {
                choices.push({ label: 'IPv4', value: 4 });
            }
            if (this.validIPVersions.includes(6)) {
                choices.push({ label: 'IPv6', value: 6 });
            }
            return choices;
        },
        filteredRoutes() {
            const matchesSearch = str => str.toLowerCase().includes(this.search.toLowerCase());
            return this.routes.filter((item) => {
                if (![item.ip_version, 'all'].includes(this.internalSelectedIpVersion)) {
                    return false;
                }

                return matchesSearch(item.gateway) || matchesSearch(item.destination);
            });
        },
        visibleRoutes() {
            return this.filteredRoutes.slice(
                this.currentPage * this.itemsPerPage,
                (this.currentPage + 1) * this.itemsPerPage,
            );
        },
    },
};
</script>

<style lang="scss" module>
.routesTableFiltersBlock {
    display: flex;
    gap: 10px;

    .searchField {
        flex: 1;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}
</style>
