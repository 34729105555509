<template>
    <div :class="$style.lanSectionSkeleton">
        <div :class="$style.lanItemWrapper">
            <div :class="$style.header">
                <span :class="$style.lanTitle" />
                <span
                    class="ml-2 mr-auto ml-lg-3"
                    :class="$style.lanName"
                />
                <div :class="$style.removeButton" />
                <span :class="$style.separate" />
                <div :class="$style.hideArrow" />
            </div>

            <ul :class="$style.categories">
                <li :class="$style.category" />
                <li :class="$style.category" />
                <li :class="$style.category" />
            </ul>
            <div :class="$style.lanForm">
                <div :class="$style.content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" module>
@import '@/assets/css/_skeleton.scss';

.lanSectionSkeleton {
    .categories {
        display: flex;
        flex-direction: row;
        padding: 10px 10px 0 10px;
        margin: 6px;

        @include under-to(lg) {
            margin: 10px 0;
            overflow: scroll;
            overflow-y: hidden;
        }

        @include themify($themes) {
            border-bottom: solid 1px rgba(themed('gray_scale_dark'), 0.1);
        }

        .category {
            @extend .skeleton;

            width: 100px;
            height: 25px;
            padding: 10px 10px 0 10px;
            margin: 10px 10px 8px 10px;
        }
    }

    .lanItemWrapper {
        @include themify($themes) {
            box-shadow: 0 0 10px 0 rgba(black, 0.1);
        }
    }

    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 63px;
        padding: 0 24px;
        margin-bottom: 10px;

        @include themify($themes) {
            border-bottom: 1px solid rgba(black, 0.1);
        }

        @include under-to(lg) {
            flex-direction: row;
            align-items: center;
            padding: 10px 20px;
        }

        .lanTitle,
        .lanName,
        .removeButton {
            @extend .skeleton;

            width: 100px;
            height: 25px;
        }

        .hideArrow {
            @extend .skeleton;

            width: 20px;
            height: 20px;
            margin-left: 42px;
        }

        &:after {
            position: absolute;
            right: 62px;
            width: 1px;
            height: 100%;
            content: '';

            @include themify($themes) {
                background-color: rgba(black, 0.1);
            }
        }
    }

    .lanForm {
        display: flex;
        padding: 25px 24px;
        background-color: white;

        @include under-to(lg) {
            padding: 10px 20px;
        }

        .content {
            @extend .skeleton;

            width: 100%;
            height: 300px;
        }
    }
}
</style>
