<template>
    <div :class="$style.containers_ipsubnets">
        <div
            v-translate
            :class="$style.ipsubnets_header"
        >
            Declared IP blocks
        </div>
        <Table
            :rows="rows"
            :columns="columns"
            :class="$style.ipsubnets"
            :total-items="ipsubnets_count"
            :is-loading="ipsubnets_loading"
            :sort-options="{
                initialSortBy: {field: 'cidr_address', type: 'asc'},
            }"
            :pagination-options="{
                position: 'top',
                perPageDropdown: [10, 20, 30, 40, 50],
            }"
            :search-options="{
                enabled: true,
            }"
            @load_data="change_api_params"
        >
            <template v-slot:table-row="props">
                <router-link
                    v-if="props.column.type == 'link'"
                    tag="a"
                    :to="{
                        name: 'ipsubnet',
                        params: {subnet_id: props.row.id}
                    }"
                    exact
                >
                    {{ props.formattedRow[props.column.field] }}
                </router-link>
                <span
                    v-else-if="props.column.type == 'bullet'"
                    :class="[
                        $style.statusbullet,
                        {[$style.active]: props.row.active},
                        {[$style.inactive]: !props.row.active},
                    ]"
                />
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </Table>
        <div :class="$style.action">
            <Button
                role="primary"
                :text="$gettext('Add a new subnet')"
                size="large"
                @click="addSubnet"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Table from '@/components/common/table.vue';
import Button from '@/components/common/button.vue';
import {
    showSwalError,
} from '@/utils/utils';

const AddIPSubnetModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "ipmanager-addipsubnet" */
    './modals/addipsubnet.vue'
);

export default {
    name: 'IPSubnets',
    components: {
        Table,
        Button,
    },
    data() {
        return {
            api_params: {},
        };
    },
    computed: {
        ...mapGetters('ipmanager', [
            'ipsubnets',
            'ipsubnets_loading',
            'ipsubnets_count',
            'ipsubnets_options',
        ]),
        rows() {
            return this.ipsubnets || [];
        },
        columns() {
            const columns = [];
            const filterDropdownAddressTypes = [];
            const filterDropdownUsages = [];
            const filterDropdownStatus = [];

            if (this.ipsubnets_options && this.ipsubnets_options.actions) {
                this.ipsubnets_options.actions.POST.address_type.choices.forEach((item) => {
                    filterDropdownAddressTypes.push({
                        value: item.value,
                        text: item.display_name,
                    });
                });
                this.ipsubnets_options.actions.POST.usage.choices.forEach((item) => {
                    filterDropdownUsages.push({
                        value: item.value,
                        text: item.display_name,
                    });
                });
                this.ipsubnets_options.actions.POST.status.choices.forEach((item) => {
                    filterDropdownStatus.push({
                        value: item.value,
                        text: item.display_name,
                    });
                });
            }

            columns.push({
                label: this.$gettext('Address'),
                field: 'cidr_address',
                type: 'link',
                sortable: true,
                filterOptions: {
                    enabled: true,
                    placeholder: this.$gettext('Subnet filter'),
                },
            });
            columns.push({
                label: this.$gettext('Type'),
                field: 'address_type_display',
                sortable: true,
                filterOptions: {
                    enabled: true,
                    filterDropdownItems: filterDropdownAddressTypes,
                },
            });
            columns.push({
                label: this.$gettext('Usage'),
                field: 'usage_display',
                sortable: true,
                filterOptions: {
                    enabled: true,
                    filterDropdownItems: filterDropdownUsages,
                },
            });
            columns.push({
                label: this.$gettext('Name'),
                field: 'name',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('Status'),
                field: 'status_display',
                sortable: true,
                filterOptions: {
                    enabled: true,
                    filterDropdownItems: filterDropdownStatus,
                },
            });
            columns.push({
                label: '',
                field: 'active',
                type: 'bullet',
                sortable: false,
            });
            return columns;
        },
    },
    watch: {
        api_params() {
            this.fetch_ipsubnets(this.api_params).catch(showSwalError);
        },
    },
    created() {
        this.fetch_ipsubnets_options().catch(showSwalError);
    },
    methods: {
        ...mapActions('ipmanager', [
            'fetch_ipsubnets',
            'fetch_ipsubnets_options',
        ]),
        change_api_params(params) {
            const apiParams = {
                params: {
                    limit: params.limit,
                    offset: params.offset,
                    search: params.search,
                    parent_block: null,
                    cidr_address: params.columnFilters.cidr_address,
                    address_type: params.columnFilters.address_type_display,
                    usage: params.columnFilters.usage_display,
                    name: params.columnFilters.name,
                    status: params.columnFilters.status_display,
                },
            };

            if (params.sort.length) {
                apiParams.params.ordering = params.sortList;
            }

            this.api_params = apiParams;
        },
        addSubnet() {
            this.show_modal(
                AddIPSubnetModal,
                {},
                {
                    height: 'auto',
                },
            ).then((result) => {
                if (result !== 'reload') {
                    return;
                }
                this.fetch_ipsubnets(this.api_params).catch(showSwalError);
            });
        },
    },
};
</script>

<style lang="scss" module>
.containers_ipsubnets {
    display: flex;
    flex-direction: column;

    .ipsubnets_header {
        margin: 20px 0;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    .ipsubnets {
        .statusbullet {
            position: relative;
            margin: 0 auto;

            &:before {
                position: absolute;
                width: 12px;
                height: 12px;
                content: ' ';
                border-radius: 50%;
                transform: translateY(50%);
            }

            &.active {
                &:before {
                    @include themify($themes) {
                        background-color: themed('success_color');
                    }
                }
            }

            &.inactive {
                &:before {
                    @include themify($themes) {
                        background-color: themed('error_color');
                    }
                }
            }
        }
    }

    .action {
        align-self: flex-end;
        margin: 20px 0;

        .prettybutton {
            min-width: 200px;
        }
    }
}
</style>
