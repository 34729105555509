var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageCount > 1
    ? _c(
        "div",
        { class: _vm.$style.paginate },
        [
          _c("paginate", {
            attrs: {
              "page-count": _vm.pageCount,
              "prev-text": _vm.$gettext("Prev"),
              "prev-link-class": _vm.$style.page_link,
              "container-class": _vm.$style.pages,
              "next-text": _vm.$gettext("Next"),
              "next-link-class": _vm.$style.page_link,
              "disabled-class": _vm.$style.disabled,
              "active-class": _vm.$style.active,
              "page-range": _vm.pageRange
            },
            model: {
              value: _vm.internalPage,
              callback: function($$v) {
                _vm.internalPage = $$v
              },
              expression: "internalPage"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }