<template>
    <div :class="$style.error_section">
        <div :class="$style.error">
            <div :class="$style.message">
                {{ error.message }}
            </div>
            <div :class="$style.code">
                <span
                    v-for="(code, index) in splitedCode"
                    :key="`code-${index}`"
                    :class="{[$style.middle]: index % 2}"
                >
                    {{ code }}
                </span>
            </div>
            <div :class="$style.description">
                {{ error.description }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        error: {
            type: Object,
            default: null,
        },
    },
    computed: {
        splitedCode() {
            return (`${this.error.code}`).split('');
        },
    },
};
</script>

<style lang="scss" module>
.error_section {
    & > .error {
        text-align: center;

        .code {
            margin-top: -30px;
            font-size: 175px;
            font-weight: 900;
            letter-spacing: -15px;
            -webkit-text-stroke-width: 2.5px;
            -webkit-text-stroke-color: white;

            @include under-to(md) {
                font-size: 140px;
                -webkit-text-stroke-width: 2px;
                letter-spacing: -13px;
            }

            .middle {
                @include themify($themes) {
                    color: themed('primary_color');
                }
            }
        }

        .message {
            font-size: 12px;
        }

        .description {
            margin-top: -50px;
            font-size: 14px;

            @include under-to(md) {
                margin-top: -40px;
            }
        }
    }
}
</style>
