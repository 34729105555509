<template>
    <div :class="$style.connectedDevicesSection">
        <Advice :title="$gettext('Connected devices')">
            <!-- eslint-disable max-len -->
            <template v-slot:text>
                <p v-translate>
                    You will find here the MAC addresses and corresponding IPv4 or IPv6 addresses of the devices currently connected to the router. The list is fetched when loading this page or clicking on "refresh".
                </p>
                <p>
                    <b v-translate>
                        Important:
                    </b>
                    <span v-translate>
                        the list below only shows connected devices to the local network at the time you connect to this page (or click on "refresh").
                    </span>
                </p>
                <p>
                    <b v-translate>
                        Did you know:
                    </b>
                    <span v-translate>
                        from this tool, you can create NAT rules (for an IPv4 device) or firewall rules (for an IPv6 device).
                    </span>
                </p>
            </template>
        </Advice>

        <div v-if="is_synchronized && linkequipment.is_plugged">
            <div
                class="row mt-3 mb-4"
            >
                <Title :title="section_title" />
            </div>
            <div class="row">
                <Select
                    v-if="isVrrp"
                    v-model="equipment_link_id"
                    :data-list="equipments_list"
                    role="small"
                    class="col-lg-3 align-self-center"
                    :class="$style.equipements_select"
                    name="equipments_list"
                />
                <Field
                    v-model="search"
                    :placeholder="$gettext('Search for an connected device')"
                    class="col-lg-4 align-self-center"
                    :class="[
                        $style.searchField,
                        {
                            'mt-3 mt-lg-0': isVrrp,
                            'offset-lg-3': !isVrrp
                        }
                    ]"
                    name="search"
                    size="small"
                    icon-left="search"
                />
                <div class="d-flex flex-lg-row-reverse mt-2 col-lg-5">
                    <Button
                        role="inverted"
                        icon="refresh"
                        :text="refresh_text"
                        :class="[$style.refresh_action, {[$style.active]: !connectedDevicesTableLoading}]"
                        class="d-none p-2 d-lg-block"
                        :disabled="connectedDevicesTableLoading"
                        @click="fetchConnectedDevicesTableFromEquipment()"
                    />
                    <Button
                        role="icon_primary"
                        icon="refresh"
                        :class="[
                            $style.bottombutton,
                            $style.refresh_action,
                            {[$style.active]: !connectedDevicesTableLoading}
                        ]"
                        class="d-lg-none"
                        :disabled="connectedDevicesTableLoading"
                        @click="fetchConnectedDevicesTableFromEquipment()"
                    />
                    <div
                        class="p-2 align-self-center"
                        :class="[$style.refresh_text, $style.body1]"
                    >
                        <translate class="d-lg-none">
                            Last synchro:
                        </translate>
                        <span>
                            {{ lastSynchronizationDate }}
                        </span>
                    </div>
                </div>
            </div>
            <ConnectedDevicesList
                :equipment-link-id="equipment_link_id"
            />
        </div>
        <div v-else>
            <Empty
                :steps="emptysteps"
                image="connectedDevices"
                :subtitle="$gettext('Connected devices update')"
            />
            <Button
                role="primary"
                :text="refresh_text"
                icon="refresh"
                size="large"
                :class="[$style.refresh_action, {[$style.active]: !connectedDevicesTableLoading}]"
                class="mt-4 col-4 d-none d-lg-flex"
                :disabled="connectedDevicesTableLoading"
                @click="fetchConnectedDevicesTableFromEquipment()"
            />
            <Button
                role="icon_primary"
                icon="refresh"
                :class="[
                    $style.bottombutton,
                    $style.refresh_action,
                    {[$style.active]: !connectedDevicesTableLoading}
                ]"
                class="d-lg-none"
                :disabled="connectedDevicesTableLoading"
                @click="fetchConnectedDevicesTableFromEquipment()"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { showSwalError } from '@/utils/utils';
import Field from '@/components/common/field.vue';
import Button from '@/components/common/button.vue';
import Select from '@/components/common/select.vue';
import Advice from './advice.vue';
import ConnectedDevicesList from './connected-devices-list.vue';
import Empty from './component-empty.vue';
import Title from './component-title.vue';


export default {
    components: {
        Advice,
        ConnectedDevicesList,
        Empty,
        Button,
        Field,
        Title,
        Select,
    },
    data() {
        return {
            search: null,
            emptysteps: [
                {
                    text: this.$gettext('Check that your equipment is connected to the network'),
                    textmobile: this.$gettext('Check that your equipment is connected to the network'),
                },
                {
                    text: this.$gettext('Connect devices on equipment'),
                    textmobile: this.$gettext('Connect devices on equipment'),
                },
                {
                    text: this.$gettext('Refresh connected devices'),
                    textmobile: this.$gettext('Refresh connected devices'),
                },
            ],
            equipment_link_id: null,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'connectedDevicesTable',
            'connectedDevicesTableEntriesSearch',
            'connectedDevicesTableLoading',
            'linkequipment',
            'virtual_iplink_members',
        ]),
        totalConnectedDevicesTableEntries() {
            if (!this.connectedDevicesTable) {
                return 0;
            }
            let total = 0;
            this.connectedDevicesTable.forEach(
                (item) => {
                    total += item.entries_count || 0;
                },
            );
            return total;
        },
        is_synchronized() {
            if (!this.connectedDevicesTable) {
                return false;
            }
            let isSynchronized = true;
            this.connectedDevicesTable.forEach(
                (item) => {
                    isSynchronized += !!item.last_synchronization_date;
                },
            );
            return isSynchronized;
        },
        currentConnectedDevicesTable() {
            if (!this.connectedDevicesTable) {
                return {};
            }
            return this.connectedDevicesTable.find(
                item => item.link_id === this.equipment_link_id,
            ) || {};
        },
        lastSynchronizationDate() {
            if (this.currentConnectedDevicesTable.last_synchronization_date) {
                moment.locale(Vue.config.language);
                return moment(this.currentConnectedDevicesTable.last_synchronization_date).format('LLL');
            }
            return this.$gettext('Never synchronized');
        },
        refresh_text() {
            if (this.connectedDevicesTableLoading) {
                return this.$gettext('Refreshing');
            }
            return this.$gettext('Refresh');
        },
        section_title() {
            const translated = this.$gettext(
                'Devices connected to equipment interfaces (%{count})',
            );

            return this.$gettextInterpolate(
                translated,
                { count: this.currentConnectedDevicesTable.entries_count || 0 },
            );
        },
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        equipments_list() {
            const choices = [];

            if (this.virtual_iplink_members) {
                this.virtual_iplink_members.forEach((item, index) => {
                    choices.push({
                        id: item.iplink,
                        text: this.$gettextInterpolate(
                            this.$gettext('Equipment %{n}'),
                            { n: index + 1 },
                        ),
                        selected: index === 0,
                    });
                });
            }

            return choices;
        },
    },
    watch: {
        search(value) {
            this.fetchConnectedDevicesTableEntries({
                search: value,
            }).catch(
                this.showConnectedDevicesError,
            );
        },
        linkequipment: {
            handler() {
                if (!this.linkequipment || this.equipment_link_id) {
                    return;
                }
                this.equipment_link_id = this.linkequipment.access;
            },
            deep: true,
            immediate: true,
        },
        virtual_iplink_members: {
            handler() {
                if (!this.virtual_iplink_members.length || !this.isVrrp) {
                    this.equipment_link_id = this.linkequipment.access;
                    return;
                }
                this.equipment_link_id = this.virtual_iplink_members[0].iplink;
            },
            deep: true,
            immediate: true,
        },
        connectedDevicesTableLoading(newValue, oldValue) {
            if (oldValue && !newValue) {
                this.fetchConnectedDevicesTableEntries().catch(this.showConnectedDevicesError);
            }
        },
    },
    mounted() {
        this.refreshConnectedDevicesTable();
    },
    methods: {
        ...mapActions('equipment', [
            'fetchConnectedDevicesTableEntries',
            'fetchConnectedDevicesTable',
        ]),
        refreshConnectedDevicesTable() {
            this.fetchConnectedDevicesTableFromEquipment();
        },
        fetchConnectedDevicesTableFromEquipment() {
            this.fetchConnectedDevicesTable().then(() => {
                this.fetchConnectedDevicesTableEntries().catch(this.showConnectedDevicesError);
            }).catch(
                this.showConnectedDevicesError,
            );
        },
        showConnectedDevicesError(error) {
            error.errors = {
                detail: this.$gettext('Failed to fetch connected devices from equipment'),
            };

            showSwalError(error);
        },
    },
};
</script>

<style lang="scss" module>
.connectedDevicesSection {
    min-height: 800px;

    .refresh_text {
        text-align: center;
    }

    .searchField {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .bottombutton {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1039;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .equipements_select {
        font-size: 12px;
    }

    .refresh_action {
        min-width: 180px;

        @include under-to(lg) {
            min-width: auto;
        }

        &:not(.active) {
            :global(svg) {
                animation: rotating 2s linear infinite;
            }
        }
    }
}
</style>
