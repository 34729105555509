require('qs');

const state = {
    simultaneous_communications: 0,
    max_allowed_communications: 0,
    customer_phone_number: null,
    phone_number: null,
    customer_install_address: {
        city: null,
        rivoli_code: null,
        insee_code: null,
        postal_code: null,
        street_label: null,
        street_number: null,
    },
    install_address: {
        city: null,
        rivoli_code: null,
        insee_code: null,
        postal_code: null,
        street_label: null,
        street_number: null,
    },
    order_id: null,
    catalog_line: {},
    pricing_catalog_lines: [],
    order_ref: null,
    mandatory_annex: null,
    optional_annex: null,
    order: null,
};

const getters = {
    simultaneous_communications: state => state.simultaneous_communications,
    max_allowed_communications: state => state.max_allowed_communications,
    customer_phone_number: state => state.customer_phone_number,
    phone_number: state => state.phone_number,
    install_address: state => state.install_address,
    customer_install_address: state => state.customer_install_address,
    catalog_line: state => state.catalog_line,
    order_id: state => state.order_id,
    pricing_catalog_lines: state => state.pricing_catalog_lines,
    order_ref: state => state.order_ref,
    mandatory_annex: state => state.mandatory_annex,
    optional_annex: state => state.optional_annex,
    order: state => state.order,
};

const mutations = {
    update_simultaneous_communications(state, nb) {
        nb = parseInt(nb, 10);

        if (Number.isNaN(nb) || nb < 0) {
            state.simultaneous_communications = 0;
        } else if (nb > state.max_allowed_communications) {
            state.simultaneous_communications = state.max_allowed_communications;
        } else {
            state.simultaneous_communications = nb;
        }
    },
    update_max_allowed_communications(state, nb) {
        state.max_allowed_communications = parseInt(nb, 10);
    },
    update_customer_phone_number(state, phoneNumber) {
        state.customer_phone_number = phoneNumber;
    },
    update_phone_number(state, phoneNumber) {
        state.phone_number = phoneNumber;
    },
    update_install_address(state, address) {
        state.install_address = address;
    },
    update_customer_install_address(state, address) {
        state.customer_install_address = address;
    },
    add_pricing_catalog_line(state, pricingCatalogLine) {
        state.pricing_catalog_lines.push(pricingCatalogLine);
    },
    set_order(state, order) {
        state.order = order;
        state.order_ref = order.reference;
        state.order_id = order.id;
    },
};

const actions = {
    fetch_order_informations({ commit, rootState }, orderId) {
        return new Promise((resolve, reject) => {
            rootState.axios_api.get(
                `/api/legacy/order/${orderId}/`,
            ).then((response) => {
                commit('set_order', response.data);

                response.data._addresses.forEach((address) => {
                    if (address.type === 'INSTALL') {
                        commit('update_customer_install_address', {
                            city: address.city,
                            rivoli_code: address.rivoli,
                            insee_code: address.insee,
                            postal_code: address.postal_code,
                            street_label: address.street,
                            street_number: address.street_number,
                        });
                    }
                });

                response.data._contacts.forEach((contact) => {
                    if (contact.type === 'TECH') {
                        commit('update_customer_phone_number', contact.phone);
                    }
                });

                resolve(response.data);
            }).catch(() => {
                reject();
            });
        });
    },
    fetch_products_channels_count({ rootState }) {
        return new Promise((resolve, reject) => {
            rootState.axios_api.get('/api/legacy/producttelephony/', {
                param: {
                    active: true,
                },
            }).then((response) => {
                const channelsCount = [];

                response.data.forEach((product) => {
                    channelsCount.push(product.channels_count);
                });

                resolve(channelsCount.sort((a, b) => a - b));
            }).catch(reject);
        });
    },
    fetch_max_allowed_channels({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            rootState.axios_api.get(
                '/api/legacy/producttelephony/max_channels/', {
                    param: {
                        active: true,
                    },
                },
            ).then((response) => {
                commit('update_max_allowed_communications', response.data.channels_count);
                resolve();
            }).catch(() => {
                reject();
            });
        });
    },
    fetch_princing_catalog_line({ rootState, commit }, paramsRequest) {
        return new Promise((resolve, reject) => {
            const url = '/api/legacy/pricingcataloglines/';
            const params = {
                product_code: paramsRequest.product_code,
                commitment: paramsRequest.commitment,
            };
            rootState.axios_api.get(url, { params })
                .then((response) => {
                    commit('add_pricing_catalog_line', response.data[0]);
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
