<template>
    <div :class="$style.stepsList">
        <div :class="$style.steps">
            <div
                v-for="(slot, slotName, index) in $slots"
                :key="slotName"
                :class="$style.step"
            >
                <div
                    v-if="numbersDisplay"
                    :class="$style.number"
                >
                    {{ index + 1 }}
                </div>

                <div :class="$style.text">
                    <slot :name="slotName" />
                </div>
            </div>
        </div>
        <div :class="$style.pictureSteps">
            <MasterSvg
                :use="image"
                class="img-fluid"
                :class-css="$style.img"
            />
        </div>
    </div>
</template>

<script>
// import MasterSvg from '@/components/common/master-svg.vue';
import MasterSvg from '../master-svg.vue';

export default {
    components: {
        MasterSvg,
    },
    props: {
        image: {
            type: String,
            default: null,
        },
        numbersDisplay: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" module>
.stepsList {
    display: flex;
    gap: 30px;

    @include under-to(lg) {
        flex-direction: column;
    }

    & > div {
        flex: 1;
    }

    .steps {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .step {
            display: flex;
            gap: 15px;
            padding: 16px 33px;
            font-size: 14px;
            border-radius: 4px;

            @include themify($themes) {
                background-color: themed("gray_scale_light");
            }

            .text {
                align-self: center;
                font-size: 14px;
                line-height: 1.57;
            }

            .number {
                min-width: 40px;
                font-size: 35px;
                font-weight: 700;

                @include themify($themes) {
                    color: themed("primary_color");
                }
            }
        }
    }

    .pictureSteps {
        .img {
            width: 100%;
            max-height: 340px;
        }
    }
}
</style>
