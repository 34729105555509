<template>
    <!-- eslint-disable max-len -->
    <HeaderAdviceLayout
        :advice-display.sync="adviceDisplay"
        advice-link-url="https://kiosque.unyc.io/document/administration-des-routeurs/configuration-sauvegardees-adr/"
    >
        <!-- Advice -->
        <template v-slot:advice-title>
            <translate>List of saved configurations</translate>
        </template>
        <template v-slot:advice-content>
            <translate>
                You will find in this section the last deployed configurations of your equipment. You can view or restore them by clicking on the respective icons.
            </translate>
        </template>

        <!-- Content -->
        <TitleWithAdviceState v-model="adviceDisplay">
            <translate>List of saved configurations</translate>
        </TitleWithAdviceState>

        <!-- Steps -->
        <StepsList
            v-if="!visibleConfigurations.length && !equipmentconfsloading"
            image="confs"
        >
            <template #step-1>
                <translate>
                    Deploy your first configuration
                </translate>
            </template>
            <template #step-2>
                <translate>
                    Modify your configuration
                </translate>
            </template>
            <template #step-3>
                <translate>
                    Previously deployed configuration will be listed here and will be available for restore
                </translate>
            </template>
        </StepsList>
        <!-- Table -->
        <ConfsTable
            v-else
            :configurations="visibleConfigurations"
            :loading="equipmentconfsloading"
            @view-configuration="viewConfiguration"
            @restore-configuration="restoreConfiguration"
        />
    </HeaderAdviceLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { showSwal, showSwalError } from '@/utils/utils';

import { actionsControl } from '../utils/utils';

import HeaderAdviceLayout from '../layout/header-advice-layout.vue';
import StepsList from '../common/steps-list.vue';
import TitleWithAdviceState from '../common/title-with-advice-state.vue';

import ConfsTable from './confs-table.vue';

import AdviceMixin from '../mixins/advice.js';

const ShowConfModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-showconf" */
    '../modals/showconf.vue'
);

export default {
    components: {
        ConfsTable,
        HeaderAdviceLayout,
        StepsList,
        TitleWithAdviceState,
    },
    mixins: [
        AdviceMixin,
    ],
    data: () => ({
        adviceId: 'configurations',
    }),
    computed: {
        ...mapGetters('equipment', [
            'confs',
            'equipmentconfsloading',
        ]),
        visibleConfigurations() {
            return (this.confs || []).filter(conf => !conf.current);
        },
    },
    methods: {
        ...mapActions('equipment', [
            'restore_conf',
        ]),
        controlActionBeforeRestore(configuration) {
            actionsControl(this.linkequipment)
                .then(() => {
                    this.restoreConfiguration(configuration);
                })
                .catch(showSwalError);
        },
        restoreConfiguration(configuration) {
            showSwal({
                title: this.$gettext('Restore this configuration'),
                html: `
                    ${this.$gettext('Do you want to replace the current configuration with this configuration?')}
                    <br>
                    ${this.$gettext('A deployment will be required in order to apply the changes to your equipment.')}
                    <br>
                `,
                type: 'question',
                confirmButtonText: this.$gettext('Restore configuration'),
                cancelButtonText: this.$gettext('Cancel'),
                showCancelButton: true,
                showCloseButton: true,
            }).then((resultSwal) => {
                if (!resultSwal.value) {
                    return;
                }

                this.restore_conf({
                    conf: configuration.id,
                }).then(() => {
                    this.$emit('deploy-configuration');
                }).catch(showSwalError);
            });
        },
        viewConfiguration(configuration) {
            this.show_modal(
                ShowConfModal,
                {
                    conf: configuration.conf,
                },
            );
        },
    },
};
</script>
