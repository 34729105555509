<template>
    <CollapsableBlockLayout breakpoint="md">
        <template #title>
            <translate>List of configurations</translate>
        </template>
        <div :class="$style.general_confs">
            <ul
                v-if="confs && confs.length"
                :class="$style.confs"
            >
                <li
                    v-for="(conf, index) in confs.slice(0, 10)"
                    :key="`general-confs-${index}`"
                    :class="$style.confItem"
                >
                    <div :class="$style.who">
                        {{ conf.synchronized_by || 'unyc' }}
                    </div>
                    <div :class="$style.when">
                        {{ conf.creation_date | moment }}
                    </div>
                    <div
                        :class="$style.see"
                        @click="showConf(conf)"
                    >
                        <Tooltip :content="$gettext('View configuration')">
                            <MasterSvg use="eye" />
                        </Tooltip>
                    </div>
                </li>
            </ul>
            <div
                v-else
                :class="[$style.placeholder, {[$style.loading]: confs === null}]"
            >
                <div :class="$style.wrapper">
                    <MasterSvg use="confs" />
                    <p
                        v-translate
                        :class="[$style.title, $style.body5]"
                    >
                        No configuration has been deployed yet
                    </p>
                    <!-- eslint-disable max-len -->
                    <p
                        v-translate
                        :class="[$style.text, $style.body1]"
                    >
                        You'll find here the last 10 configurations deployed on your equipment. You'll be able to restore them in order to configure the equipment back to a previous configuration
                    </p>
                </div>
            </div>
        </div>
    </CollapsableBlockLayout>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Tooltip from '@/components/common/tooltip.vue';
import MasterSvg from './master-svg.vue';

import CollapsableBlockLayout from './layout/collapsable-block.vue';

const ShowConfModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-showconf" */
    './modals/showconf.vue'
);

export default {
    components: {
        CollapsableBlockLayout,
        MasterSvg,
        Tooltip,
    },
    filters: {
        moment(date) {
            moment.locale(Vue.config.language);
            return moment(date).format('LLL');
        },
    },
    data: () => ({
        loading: true,
    }),
    computed: {
        ...mapGetters('equipment', [
            'confs',
        ]),
    },
    methods: {
        showConf(conf) {
            this.show_modal(
                ShowConfModal,
                {
                    conf: conf.conf,
                },
            );
        },
    },
};
</script>

<style lang="scss" module>
.general_confs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .confs {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & .confItem {
            display: flex;
            flex-direction: row;
            gap: 8px;
            padding: 12px;
            border-radius: 4px;

            @include themify($themes) {
                border: 1px solid themed('grayscale_extralight');
            }

            @include under-to(md) {
                flex-direction: column;
                padding-bottom: 20px;
                text-align: center;

                &:first-child {
                    margin-top: 15px;
                }
            }

            & .who {
                flex: 1;
                align-self: center;
                overflow: hidden;
                font-weight: bold;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            & .when {
                flex: 1;

                @include under-to(md) {
                    margin-top: 5px;
                }
            }

            & .see {
                flex: 0;
                align-self: center;
                width: auto;
                margin-left: 10px;
                text-align: right;
                cursor: pointer;

                & svg {
                    width: 25px;
                    height: 14px;

                    @include themify($themes) {
                        fill: themed('primary_color');
                    }

                    @include under-to(md) {
                        width: 30px;
                        height: 17px;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .placeholder {
        display: flex;
        flex-grow: 1;

        .wrapper {
            text-align: center;
        }

        .wrapper > * {
            margin: auto;
        }

        svg {
            width: 100%;
        }

        .title {
            margin-top: 20px;
            text-align: center;

            @include under-to(md) {
                text-align: left;
            }
        }

        img {
            width: 50%;

            @include under-to(md) {
                width: auto;
                height: 236px;
            }
        }

        .text {
            width: 85%;
            margin-top: 15px;

            @include under-to(md) {
                width: 100%;
                text-align: left;
            }
        }
    }
}
</style>
