import Vue from 'vue';

const state = {
    partners: [],
    selected_partner: { id: 0, label: '' },
    error_messages: [],
    success_messages: [],
    loading: false,
};

const getters = {
    partners: state => state.partners,
    selected_partner: state => state.selected_partner,
    error_messages: state => state.error_messages,
    success_messages: state => state.success_messages,
    loading: state => state.loading,
};

const mutations = {
    update_partners(state, partners) {
        state.partners = partners;
    },
    update_selected_partner(state, selectedPartner) {
        state.selected_partner = selectedPartner;
    },
    update_error_messages(state, errorMessages) {
        state.error_messages = errorMessages;
    },
    add_error_message(state, errorMessage) {
        state.error_messages.push(errorMessage);
    },
    update_success_messages(state, successMessages) {
        state.success_messages = successMessages;
    },
    add_success_message(state, successMessage) {
        state.success_messages.push(successMessage);
    },
    update_loading(state, loading) {
        state.loading = loading;
    },
};

const UNEXPECTED_ERROR = Vue.prototype.$gettext('An unexpected error happened');
const UNEXPECTED_ERROR_CALLBACK_OBJECT = {
    success: false,
    errors: {
        non_field_errors: [UNEXPECTED_ERROR],
    },
    error: UNEXPECTED_ERROR,
};

const actions = {

    async import_file({ state, commit, rootState }, params) {
        commit('update_error_messages', []);
        commit('update_success_messages', []);
        commit('update_loading', true);

        rootState.axios_api.put(
            `/api/legacy/customerfileupload/?partner_id=${state.selected_partner.id}`,
            params,
            {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=utf-8',
                    'Content-Disposition': 'attachment; filename="filename.csv"',
                },
            },
        ).then(() => {
            commit('update_loading', false);
            commit('add_success_message', {
                key: 'success',
                label: Vue.prototype.$gettext('Customers imported successfully'),
            });
        }).catch((error) => {
            let errorLabel = '';

            switch (error.response.status) {
                case 412:
                    errorLabel = error.response.data.detail;
                    break;
                default:
                    errorLabel = error.response.statusText;
            }

            commit('update_loading', false);
            commit('add_error_message', {
                key: 'error',
                label: `${Vue.prototype.$gettext('Error when loading customers')} : ${errorLabel}`,
            });

            if (!params || !params.callback) {
                return;
            }

            params.callback(UNEXPECTED_ERROR_CALLBACK_OBJECT);
        });
    },

    async load_partners({ commit, rootState }, params) {
        commit('update_error_messages', []);
        commit('update_success_messages', []);
        commit('update_loading', true);

        rootState.axios_api.get('/api/legacy/partners/')
            .then((response) => {
                commit('update_partners', response.data);
                commit('update_loading', false);
            })
            .catch((error) => {
                let errorLabel = '';

                switch (error.response.status) {
                    case 412:
                        errorLabel = error.detail;
                        break;
                    default:
                        errorLabel = error.response.statusText;
                }

                commit('add_error_message', {
                    key: 'error',
                    label: `${Vue.prototype.$gettext('Error when importing partners')} : ${errorLabel}`,
                });

                commit('update_loading', false);

                if (!params || !params.callback) {
                    return;
                }

                params.callback(UNEXPECTED_ERROR_CALLBACK_OBJECT);
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
