var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.submitButtons },
    [
      _c("Button", {
        class: _vm.$style.cancelButton,
        attrs: {
          role: "secondary",
          text: _vm.$gettext("Cancel"),
          disabled: _vm.disabled,
          size: "large"
        },
        on: {
          click: function($event) {
            return _vm.$emit("cancel")
          }
        }
      }),
      _vm._v(" "),
      _c("Button", {
        class: _vm.$style.validateButton,
        attrs: {
          role: "primary",
          text: _vm.$gettext("Validate configuration"),
          disabled: _vm.disabled,
          size: "large"
        },
        on: {
          click: function($event) {
            return _vm.$emit("submit")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }