var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.$style.prettyinputradio,
        ((_obj = {}), (_obj[_vm.$style.error] = _vm.errors), _obj)
      ]
    },
    [
      _c(
        "div",
        _vm._l(_vm.choices, function(choice, index) {
          var _obj
          return _c(
            "div",
            {
              key: _vm.name + "-" + index,
              class: [
                ((_obj = {}),
                (_obj[_vm.$style.selected] =
                  choice.value === _vm.selected_value),
                (_obj[_vm.$style.disabled] = choice.disabled),
                (_obj[_vm.customClass] = _vm.customClass),
                (_obj[_vm.$style.flyinginfo] = choice.info_tooltips),
                _obj),
                choice.custom_class,
                _vm.$style.inputwrapper_radio
              ],
              attrs: { "data-content": choice.info_tooltips },
              on: {
                click: function($event) {
                  return _vm.toggleCheck(choice.id)
                }
              }
            },
            [
              _c("label", { class: _vm.$style.prettyradio_label }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected_value,
                      expression: "selected_value"
                    }
                  ],
                  ref: choice.id,
                  refInFor: true,
                  attrs: {
                    id: choice.id,
                    type: "radio",
                    name: _vm.name,
                    disabled: choice.disabled
                  },
                  domProps: {
                    value: choice.value,
                    checked: _vm._q(_vm.selected_value, choice.value)
                  },
                  on: {
                    change: function($event) {
                      _vm.selected_value = choice.value
                    }
                  }
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(choice.display_name) +
                    "\n            "
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.errors
        ? _c(
            "div",
            { class: _vm.$style.errors },
            _vm._l(_vm.errors, function(error, index) {
              return _c("div", { key: index, class: _vm.$style.error }, [
                _vm._v("\n            " + _vm._s(error) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }