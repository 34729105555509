<template>
    <div :class="$style.subnet_details">
        <div class="row">
            <div
                class="col-12"
                :class="$style.title"
            >
                <h2 v-translate>
                    Subnet details
                </h2>
            </div>
        </div>
        <div
            class="row"
            :class="[$style.info_row]"
        >
            <div
                v-translate
                class="col-6"
            >
                Address type
            </div>
            <div class="col-6">
                {{ ipsubnet.address_type }}
            </div>
        </div>
        <div
            class="row"
            :class="[$style.info_row]"
        >
            <div
                v-translate
                class="col-6"
            >
                CIDR address
            </div>
            <div class="col-6">
                {{ ipsubnet.cidr_address }}
            </div>
        </div>
        <div
            class="row"
            :class="[$style.info_row]"
        >
            <div
                v-translate
                class="col-6"
            >
                Prefix size
            </div>
            <div class="col-6">
                {{ ipsubnet.prefix_size }}
            </div>
        </div>
        <div
            class="row"
            :class="[$style.info_row]"
        >
            <div
                v-translate
                class="col-6"
            >
                Usage
            </div>
            <div class="col-6">
                {{ ipsubnet.usage }}
            </div>
        </div>
        <div
            class="row"
            :class="[$style.info_row]"
        >
            <div
                v-translate
                class="col-6"
            >
                Name
            </div>
            <div class="col-6">
                {{ ipsubnet.name }}
            </div>
        </div>
        <div
            class="row"
            :class="[$style.info_row]"
        >
            <div
                v-translate
                class="col-6"
            >
                Description
            </div>
            <div class="col-6">
                {{ ipsubnet.description }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'IPSubnet',
    props: {
        ipsubnet: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
        };
    },
    created() {
    },
};
</script>

<style lang="scss" module >
.subnet_details {
    width: 100%;
    background-color: white;

    .title {
        margin-top: 20px;
        text-align: center;
    }

    .info_row {
        align-items: center;

        &:global(.row) {
            padding: 10px 0;
            margin: 30px;

            @include under-to(md) {
                margin: 30px 0;
            }
        }
    }
}
</style>
