var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.firewallsection },
    [
      _c(
        "div",
        { class: _vm.$style.groups_firewall },
        _vm._l(_vm.groupsFirewall, function(group) {
          return _c("LittleCard", {
            key: group.category,
            attrs: {
              title: group.title,
              description: group.description,
              icon: group.icon,
              disabled: !!group.disabled,
              selected: _vm.current_group === group
            },
            on: {
              click: function($event) {
                return _vm.change_current_group(group)
              }
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("SlideTransition", [
        _vm.current_group
          ? _c(
              "div",
              [
                _c("Advice", {
                  attrs: {
                    title: _vm.current_group.title,
                    subtitle: _vm.current_group.subtitle,
                    "can-be-closed": _vm.current_group.can_close_advise,
                    "link-url":
                      "https://kiosque.unyc.io/document/administration-des-routeurs/pare-feu-adr/"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "text",
                        fn: function() {
                          return [
                            _vm.current_group.category == "dmz"
                              ? [
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "translate",
                                          rawName: "v-translate"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                            A DMZ (or demilitarized zone) is an isolated subnetwork, separated from the local network (LAN) and the Internet by a firewall.\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "translate",
                                          rawName: "v-translate"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                            The firewall rules must be declared on the router positioned in the DMZ.\n                        "
                                      )
                                    ]
                                  )
                                ]
                              : [
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "translate",
                                          rawName: "v-translate"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Firewall is an essential element in the security of a computer network.\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    [
                                      _c("translate", [
                                        _vm._v(
                                          "\n                                A firewall is a filter gateway that protects a computer or computer network from intrusion from a third party. This firewall makes it possible to filter the data packets exchanged with the network according to various criteria:\n                            "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        { class: _vm.$style.list_items },
                                        [
                                          _c(
                                            "li",
                                            {
                                              directives: [
                                                {
                                                  name: "translate",
                                                  rawName: "v-translate"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Source / Destination IP address\n                                "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            {
                                              directives: [
                                                {
                                                  name: "translate",
                                                  rawName: "v-translate"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    Destination port number\n                                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.current_group.category == "lan2wan"
                                    ? _c("p", [
                                        _c(
                                          "b",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Did you know : To block all destination ips you will have to enter 0.0.0.0/0 in the field.\n                            "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm.current_group.category == "wan2lan" &&
                                      _vm.iplink.mpls
                                    ? _c("p", [
                                        _c(
                                          "b",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Did you know : To block all source ips you will have to enter 0.0.0.0/0 in the field.\n                            "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ]
                          ]
                        },
                        proxy: true
                      },
                      _vm.current_group.category != "dmz"
                        ? {
                            key: "example",
                            fn: function() {
                              return [
                                _vm.current_group.category == "lan2lan"
                                  ? [
                                      _c(
                                        "p",
                                        {
                                          directives: [
                                            {
                                              name: "translate",
                                              rawName: "v-translate"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Exemple: You want block communications between LAN 1 (192.168.0.0/24) and LAN 2 (192.168.100.0/24). You will need to set the following rule:\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate",
                                                value: {
                                                  name_field:
                                                    _vm.current_group.add_modal
                                                      .label_source_ip
                                                },
                                                expression:
                                                  "{name_field: current_group.add_modal.label_source_ip}"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                %{name_field}: 192.168.0.0/24\n                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate",
                                                value: {
                                                  name_field:
                                                    _vm.current_group.add_modal
                                                      .label_destination_ip
                                                },
                                                expression:
                                                  "{name_field: current_group.add_modal.label_destination_ip}"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                %{name_field}: 192.168.100.0/24\n                            "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  : _vm.current_group.category == "lan2wan"
                                  ? [
                                      _c(
                                        "p",
                                        {
                                          directives: [
                                            {
                                              name: "translate",
                                              rawName: "v-translate"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Exemple: You want block communications from LAN 1 (192.168.0.0/24) to 32.65.32.15 IP address. You will need to set the following rule:\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate",
                                                value: {
                                                  name_field:
                                                    _vm.current_group.add_modal
                                                      .label_source_ip
                                                },
                                                expression:
                                                  "{name_field: current_group.add_modal.label_source_ip}"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                %{name_field}: 192.168.0.0/24\n                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate",
                                                value: {
                                                  name_field:
                                                    _vm.current_group.add_modal
                                                      .label_destination_ip
                                                },
                                                expression:
                                                  "{name_field: current_group.add_modal.label_destination_ip}"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                %{name_field}: 32.65.32.15\n                            "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  : _vm.current_group.category == "wan2lan" &&
                                    _vm.iplink.mpls
                                  ? [
                                      _c(
                                        "p",
                                        {
                                          directives: [
                                            {
                                              name: "translate",
                                              rawName: "v-translate"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Exemple: You want block communications from everyone to LAN 1 (192.168.0.0/24). You will need to set the following rule:\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate",
                                                value: {
                                                  name_field:
                                                    _vm.current_group.add_modal
                                                      .label_source_ip
                                                },
                                                expression:
                                                  "{name_field: current_group.add_modal.label_source_ip}"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                %{name_field}: 0.0.0.0/0\n                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            directives: [
                                              {
                                                name: "translate",
                                                rawName: "v-translate",
                                                value: {
                                                  name_field:
                                                    _vm.current_group.add_modal
                                                      .label_destination_ip
                                                },
                                                expression:
                                                  "{name_field: current_group.add_modal.label_destination_ip}"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "\n                                %{name_field}: 192.168.0.0/24\n                            "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _vm.current_group.can_add_rule
                  ? _c("UpdatedSection", { attrs: { section: "firewall" } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.containsRules && _vm.current_group.can_add_rule
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "row mt-3 mb-4" },
                          [
                            _c("Title", {
                              attrs: { title: _vm.current_group.title_section }
                            }),
                            _vm._v(" "),
                            _c("Button", {
                              staticClass: "col-4 ml-auto d-none d-lg-flex",
                              class: [
                                _vm.$style.rightbutton,
                                ((_obj = {}),
                                (_obj[_vm.$style.flyinginfotop] =
                                  _vm.current_group.info_button),
                                _obj)
                              ],
                              attrs: {
                                role: "primary",
                                text: _vm.current_group.button,
                                disabled:
                                  _vm.current_group.disabled_button || false,
                                size: "large",
                                "data-content": _vm.current_group.info_button
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addFirewallRule()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("Button", {
                              staticClass: "d-lg-none",
                              class: _vm.$style.bottombutton,
                              attrs: {
                                role: "icon_primary",
                                icon: "add",
                                disabled:
                                  _vm.current_group.disabled_button || false
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addFirewallRule()
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row", class: _vm.$style.search },
                          [
                            _c("Field", {
                              class: _vm.$style.searchField,
                              attrs: {
                                placeholder: _vm.$gettext(
                                  "Search for a firewall rule"
                                ),
                                name: "search",
                                size: "small",
                                "icon-left": "search"
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            }),
                            _vm._v(" "),
                            _c("Select", {
                              class: _vm.$style.selectField,
                              attrs: {
                                role: "small",
                                name: "status",
                                "data-list": _vm.statusFiltersValues
                              },
                              model: {
                                value: _vm.filteringByStatus,
                                callback: function($$v) {
                                  _vm.filteringByStatus = $$v
                                },
                                expression: "filteringByStatus"
                              }
                            }),
                            _vm._v(" "),
                            _vm.IpVersions.length > 1
                              ? _c("ComponentSimpleFilters", {
                                  attrs: { "filter-choices": _vm.IpVersions },
                                  model: {
                                    value: _vm.selectedIpVersion,
                                    callback: function($$v) {
                                      _vm.selectedIpVersion = $$v
                                    },
                                    expression: "selectedIpVersion"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("FirewallList", {
                          attrs: {
                            search: _vm.search,
                            "filter-by-status": _vm.filteringByStatus,
                            "filter-by-ip-version": _vm.selectedIpVersion,
                            group: _vm.current_group
                          }
                        })
                      ],
                      1
                    )
                  : _vm.current_group.can_add_rule
                  ? _c(
                      "div",
                      [
                        _c("Empty", {
                          attrs: {
                            steps: _vm.emptysteps,
                            image: "firewall",
                            subtitle: _vm.current_group.title_section
                          },
                          on: {
                            link_clicked: function($event) {
                              return _vm.addFirewallRule()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("Button", {
                          staticClass: "mt-4 col-4 d-none d-lg-flex",
                          class:
                            ((_obj$1 = {}),
                            (_obj$1[_vm.$style.flyinginfotop] =
                              _vm.current_group.info_button),
                            _obj$1),
                          attrs: {
                            role: "primary",
                            text: _vm.current_group.button,
                            disabled:
                              _vm.current_group.disabled_button || false,
                            "data-content": _vm.current_group.info_button,
                            size: "large"
                          },
                          on: {
                            click: function($event) {
                              return _vm.addFirewallRule()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("Button", {
                          staticClass: "d-lg-none",
                          class: _vm.$style.bottombutton,
                          attrs: {
                            role: "icon_primary",
                            icon: "add",
                            disabled: _vm.current_group.disabled_button || false
                          },
                          on: {
                            click: function($event) {
                              return _vm.addFirewallRule()
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }