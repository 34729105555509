<template>
    <div :class="$style.interfacesRouter">
        <Button
            role="icon_primary"
            icon="refresh"
            :class="[
                $style.refreshAction,
                {[$style.active]: !loading}
            ]"
            :disabled="loading"
            @click="$emit('refresh-interfaces')"
        />
        <div :class="$style.routerPorts">
            <RouterPort
                v-for="(port, index) in interfaces"
                :key="index"
                :port-number="port.port_number"
                :enabled="port.enabled"
                :linked="port.link_active"
                :used="usedInterfaces.includes(port.port_number)"
                :selected="selectedPort === port.port_number"
                :equipment-data="equipmentData"
                @selected="handleSelectedPort"
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/common/button.vue';
import RouterPort from './general-interfaces-router-port.vue';

export default {
    components: {
        Button,
        RouterPort,
    },
    props: {
        interfaces: {
            type: Array,
            required: true,
        },
        equipmentData: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        usedInterfaces: {
            type: Array,
            default: () => [],
        },
        selectedPort: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        handleSelectedPort(portNumber) {
            this.$emit('update:selected-port', portNumber);
        },
    },
};
</script>

<style lang="scss" module>
.interfacesRouter {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    width: fit-content;
    padding: 10px 15px;
    margin-top: 20px;
    border-radius: 8px;

    @include themify($themes) {
        background-color: themed('tertiary_color');
        border: 1.5px solid themed('main_color');
    }

    &:after {
        position: absolute;
        top: 60px;
        left: -2px;
        width: 2px;
        height: 10px;
        content: " ";

        @include themify($themes) {
            background-color: white;
        }
    }

    &:before {
        display: inline-flex;
        justify-content: center;
        width: 27px;
        height: 22px;
        margin: 0 15px;
        content: "";
        mask-image: url('../../assets/router-start.svg?inline');
        mask-repeat: no-repeat;
        mask-position: center;

        @include themify($themes) {
            background-color: themed('main_color');
        }
    }

    .refreshAction {
        width: 30px;
        min-width: 0;
        height: 30px;
        margin-right: 20px;

        &:not(.active) {
            :global(svg) {
                animation: rotating 2s linear infinite;
            }
        }
    }

    .routerPorts {
        display: flex;
        gap: 10px;
        padding: 10px 15px 20px 15px;
        background-color: white;
        border-radius: 4px;

        @include themify($themes) {
            border: 0.8px solid themed('quaternary_color');
        }
    }
}
</style>
