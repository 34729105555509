var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$props.initMethodName
      ? _c(
          "div",
          { staticClass: "form-container py-4", class: _vm.$style.widget },
          [
            _c(
              "div",
              { attrs: { id: "params-form" } },
              [
                _vm.$props.params
                  ? _c(
                      "h3",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ],
                        staticClass: "my-3 offset-0 col-12 offset-md-2 col-md-8"
                      },
                      [_vm._v("\n                Params Form\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.$props.params, function(item, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "form-group bg-white" },
                    [
                      _c("Field", {
                        staticClass: "offset-0 col-12 offset-md-2 col-md-8",
                        attrs: { required: true, label: key, name: key },
                        model: {
                          value: _vm.params[key],
                          callback: function($$v) {
                            _vm.$set(_vm.params, key, $$v)
                          },
                          expression: "params[key]"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("Button", {
              staticClass: "offset-2 col-8 offset-md-3 col-md-6",
              attrs: {
                role: "primary",
                text: _vm.$gettext("Validate"),
                size: "large"
              },
              on: { click: _vm.loadWidget }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "pb-5", attrs: { id: "widget-container" } },
      [_c("div", { attrs: { id: "widget" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }