<template>
    <div
        :class="$style.prettybutton"
    >
        <button
            v-if="(role==='primary' || role==='secondary' || role==='ghost' || role==='inverted')"
            :class="[
                $style.btn,
                $style[role],
                $style[size],
                {[$style.disabled]: disabled},
                {[$style.knowmore]: knowmore}
            ]"
            @click.prevent="clickEvent()"
        >
            <MasterSvg
                v-if="display_icon"
                :use="display_icon"
            />
            <span v-if="text">
                {{ text }}
            </span>
        </button>

        <div
            v-else-if="(role==='icon_primary' || role==='icon_secondary')"
            :class="[
                $style[role],
                {[$style.disabled]: disabled},
                {[$style.knowmore]: knowmore}
            ]"
            @click="clickEvent()"
        >
            <MasterSvg :use="display_icon" />
            <span v-if="text">
                {{ text }}
            </span>
        </div>

        <div
            v-else
            :class="[
                $style.link,
                {[$style.disabled]: disabled},
                {[$style.knowmore]: knowmore}
            ]"
            @click="clickEvent()"
        >
            <span>
                {{ text ? text : "Link" }}
            </span>
            <MasterSvg
                v-if="display_icon"
                :use="display_icon"
            />
        </div>
    </div>
</template>

<script>
import MasterSvg from './master-svg.vue';

export default {
    name: 'Btn',
    components: {
        MasterSvg,
    },
    props: {
        role: {
            type: String,
            required: false,
            default: 'primary',
            validator(value) {
                return [
                    'primary',
                    'secondary',
                    'inverted',
                    'ghost',
                    'link',
                    'icon_primary',
                    'icon_secondary',
                ].indexOf(value) !== -1;
            },
        },
        text: {
            type: String,
            required: false,
            default: null,
        },
        size: {
            type: String,
            required: false,
            default: null,
            validator(value) {
                return [
                    'small',
                    'large',
                ].indexOf(value) !== -1;
            },
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        knowmore: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            display_icon: this.icon,
        };
    },
    watch: {
        icon() {
            this.display_icon = null;
            this.$nextTick(() => {
                this.display_icon = this.icon;
            });
        },
    },
    methods: {
        clickEvent() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" module>
.prettybutton {
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 500;
        color: white;
        letter-spacing: -0.4px;
        border: none;
        border-radius: 4px;
        outline: none;
        transition: 0.3s background-color;

        @include themify($themes) {
            background-color: themed("primary_color");
            border: 1px solid themed("primary_color");
        }

        &:hover,
        &:active,
        &.hover {
            @include themify($themes) {
                background-color: themed("secondary_color");
            }
        }

        &.ghost,
        &.secondary {
            background-color: white;
            border: 1px solid white;
        }

        &.ghost {
            @include themify($themes) {
                color: themed("primary_color");
            }

            &:hover,
            &:active,
            &.hover {
                @include themify($themes) {
                    background-color: themed("tertiary_color");
                    border: 1px solid themed("tertiary_color");
                }
            }
        }

        &[disabled],
        &.disabled {
            pointer-events: none;

            @include themify($themes) {
                color: themed("gray_scale_dark");
                background-color: themed("gray_scale_normal");
                border: 1px solid themed("gray_scale_normal");
            }
        }

        &.secondary {
            border: 1px solid currentColor;

            @include themify($themes) {
                color: themed("primary_color");
            }

            &[disabled],
            &.disabled {
                pointer-events: none;
                background-color: white;

                @include themify($themes) {
                    color: themed("gray_scale_dark");
                }
            }

            &:hover,
            &:active,
            &.hover {
                @include themify($themes) {
                    background-color: themed("tertiary_color");
                }
            }
        }

        &.inverted {
            @include themify($themes) {
                color: themed("primary_color");
                background-color: rgba(themed("tertiary_color"), 0.5);
                border: 1px solid rgba(themed("tertiary_color"), 0.5);
            }

            &:hover,
            &:active,
            &.hover {
                @include themify($themes) {
                    background-color: themed("tertiary_color");
                    border: 1px solid themed("tertiary_color");
                }
            }

            &[disabled],
            &.disabled {
                pointer-events: none;
                background-color: white;
                border: 1px solid white;

                @include themify($themes) {
                    color: themed("gray_scale_dark");
                }
            }

            &:hover svg {
                animation: rotating 0.6s ease-out;
            }
        }

        &.small {
            width: fit-content;
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.35px;
        }

        :global(svg),
        :global(span) {
            display: inline-block;
            vertical-align: middle;
        }

        :global(svg) {
            width: 1.2em;
            height: 1.2em;
            margin-right: 10px;
            fill: currentColor;
        }
    }

    .link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        font-weight: 500;
        cursor: pointer;
        background: none !important;
        border: none;

        @include themify($themes) {
            color: themed("primary_color");
        }

        &:hover {
            text-decoration: underline;
        }

        &[disabled],
        &.disabled {
            pointer-events: none;
            background-color: white;

            @include themify($themes) {
                color: themed("gray_scale_dark");
            }
        }

        :global(svg) {
            width: 1.2em;
            height: 1.2em;
            margin-left: 8px;
            vertical-align: middle;
            fill: currentColor;
        }
    }

    .icon_primary {
        display: flex;
        width: inherit;
        height: inherit;
        cursor: pointer;
        border-radius: 31.5px;

        @include themify($themes) {
            background-color: themed("primary_color");
        }

        &:hover,
        &:active,
        &.hover {
            @include themify($themes) {
                background-color: themed("secondary_color");
            }
        }

        &[disabled],
        &.disabled {
            pointer-events: none;

            @include themify($themes) {
                background-color: themed("gray_scale_normal");
                box-shadow: 0 0 8px 0 themed("gray_scale_dark");

                svg {
                    color: themed("gray_scale_dark");
                    fill: themed("gray_scale_dark");
                }
            }
        }

        & svg {
            width: 50%;
            height: 50%;
            margin: auto;
            fill: white;
        }
    }

    .icon_secondary {
        width: 30px;
        height: 30px;
        cursor: pointer;
        border-radius: 15px;

        @include themify($themes) {
            background-color: themed('tertiary_color');
        }

        &:hover,
        &:active,
        &.hover {
            @include themify($themes) {
                background-color: themed('quaternary_color');
            }
        }

        & svg {
            height: 15px;

            @include themify($themes) {
                fill: themed("primary_color");
            }
        }
    }

    .knowmore:after {
        content: '→';
    }
}
</style>
