<template>
    <div>
        <ListItem
            v-for="(linkequipment, index) in linkequipments"
            :key="index"
            :index="index + 1"
            :linkequipment="linkequipment"
            class="col"
            @deploy-configuration="handleDeployConfiguration"
            @show-deploy-configuration-modal="handleShowDeployConfigurationModal"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListItem from './general-vrrpmember-listitem.vue';

export default {
    components: {
        ListItem,
    },
    computed: {
        ...mapGetters('equipment', [
            'virtual_iplink_members',
            'virtual_linkequipment_members',
        ]),
        linkequipments() {
            if (
                !(this.virtual_linkequipment_members || []).length
                || !(this.virtual_iplink_members || []).length
            ) {
                return [];
            }

            const linkequipments = [];
            this.virtual_linkequipment_members.forEach((member) => {
                linkequipments.push(member);
            });

            linkequipments.sort((first, second) => {
                const firstMember = this.virtual_iplink_members.find(
                    member => member.iplink === first.access,
                );
                const secondMember = this.virtual_iplink_members.find(
                    member => member.iplink === second.access,
                );

                return firstMember.priority - secondMember.priority;
            });
            return linkequipments;
        },
    },
    methods: {
        handleDeployConfiguration(params) {
            this.$emit('deploy-configuration', params);
        },
        handleShowDeployConfigurationModal(params) {
            this.$emit('show-deploy-configuration-modal', params);
        },
    },
};
</script>

<style lang="scss" module>
</style>
