var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.menu }, [
    _c(
      "ul",
      {
        class: [
          _vm.$style.items,
          _vm.$style.tab,
          { "sticky-top": _vm.$mq !== "xs" && _vm.$mq !== "sm" }
        ]
      },
      [
        _c("Item", {
          attrs: {
            section: "general",
            active: _vm.section == "general",
            "updated-section": _vm.updated_sections.includes("interfaces"),
            name: _vm.$gettext("General")
          },
          on: {
            click: function($event) {
              return _vm.change_section("general")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCapability("wans"),
              expression: "hasCapability('wans')"
            }
          ],
          attrs: {
            section: "wan",
            active: _vm.section == "wan",
            "updated-section": _vm.updated_sections.includes("wans"),
            name: _vm.$gettext("WAN")
          },
          on: {
            click: function($event) {
              return _vm.change_section("wan")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCapability("lans") && !_vm.linkequipment.discharged,
              expression: "hasCapability('lans') && !linkequipment.discharged"
            }
          ],
          attrs: {
            section: "lan",
            active: _vm.section == "lan",
            "updated-section": _vm.updated_sections.includes("lans"),
            name: _vm.$gettext("LAN")
          },
          on: {
            click: function($event) {
              return _vm.change_section("lan")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCapability("nat") && !_vm.linkequipment.discharged,
              expression: "hasCapability('nat') && !linkequipment.discharged"
            }
          ],
          attrs: {
            section: "nat",
            active: _vm.section == "nat",
            "updated-section": _vm.updated_sections.includes("nat"),
            name: _vm.$gettext("NAT")
          },
          on: {
            click: function($event) {
              return _vm.change_section("nat")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.hasCapability("firewall") && !_vm.linkequipment.discharged,
              expression:
                "hasCapability('firewall') && !linkequipment.discharged"
            }
          ],
          attrs: {
            section: "firewall",
            active: _vm.section == "firewall",
            "updated-section": _vm.updated_sections.includes("firewall"),
            name: _vm.$gettext("Firewall")
          },
          on: {
            click: function($event) {
              return _vm.change_section("firewall")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.hasCapability("routes") && !_vm.linkequipment.discharged,
              expression: "hasCapability('routes') && !linkequipment.discharged"
            }
          ],
          attrs: {
            section: "routes",
            active: _vm.section == "routes",
            "updated-section": _vm.updated_sections.includes("routes"),
            name: _vm.$gettext("Routes")
          },
          on: {
            click: function($event) {
              return _vm.change_section("routes")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCapability("dmz") && !_vm.linkequipment.discharged,
              expression: "hasCapability('dmz') && !linkequipment.discharged"
            }
          ],
          attrs: {
            section: "dmz",
            active: _vm.section == "dmz",
            "updated-section": _vm.updated_sections.includes("dmz"),
            name: _vm.$gettext("DMZ")
          },
          on: {
            click: function($event) {
              return _vm.change_section("dmz")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.linkequipment.discharged,
              expression: "!linkequipment.discharged"
            }
          ],
          attrs: {
            section: "connectedDevices",
            active: _vm.section == "connectedDevices",
            name: _vm.$gettext("Connected devices")
          },
          on: {
            click: function($event) {
              return _vm.change_section("connectedDevices")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.linkequipment.discharged,
              expression: "!linkequipment.discharged"
            }
          ],
          attrs: {
            section: "confs",
            active: _vm.section == "confs",
            name: _vm.$gettext("Saved configurations")
          },
          on: {
            click: function($event) {
              return _vm.change_section("confs")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canReadEvents && !_vm.linkequipment.discharged,
              expression: "canReadEvents && !linkequipment.discharged"
            }
          ],
          attrs: {
            section: "events",
            active: _vm.section == "events",
            name: _vm.$gettext("Events")
          },
          on: {
            click: function($event) {
              return _vm.change_section("events")
            }
          }
        }),
        _vm._v(" "),
        _c("Item", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.hasCapability("snmp_communities") ||
                _vm.hasCapability("wifis"),
              expression:
                "hasCapability('snmp_communities') || hasCapability('wifis')"
            }
          ],
          attrs: {
            section: "advanced",
            active: _vm.section == "advanced",
            "updated-section": _vm.advancedSectionIsUpdated,
            name: _vm.$gettext("Advanced")
          },
          on: {
            click: function($event) {
              return _vm.change_section("advanced")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }