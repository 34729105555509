var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HeaderAdviceLayout",
    {
      attrs: {
        "advice-display": _vm.adviceDisplay,
        "advice-link-url":
          "https://kiosque.unyc.io/document/administration-des-routeurs/configuration-sauvegardees-adr/"
      },
      on: {
        "update:adviceDisplay": function($event) {
          _vm.adviceDisplay = $event
        },
        "update:advice-display": function($event) {
          _vm.adviceDisplay = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "advice-title",
          fn: function() {
            return [_c("translate", [_vm._v("List of saved configurations")])]
          },
          proxy: true
        },
        {
          key: "advice-content",
          fn: function() {
            return [
              _c("translate", [
                _vm._v(
                  "\n            You will find in this section the last deployed configurations of your equipment. You can view or restore them by clicking on the respective icons.\n        "
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "TitleWithAdviceState",
        {
          model: {
            value: _vm.adviceDisplay,
            callback: function($$v) {
              _vm.adviceDisplay = $$v
            },
            expression: "adviceDisplay"
          }
        },
        [_c("translate", [_vm._v("List of saved configurations")])],
        1
      ),
      _vm._v(" "),
      !_vm.visibleConfigurations.length && !_vm.equipmentconfsloading
        ? _c("StepsList", {
            attrs: { image: "confs" },
            scopedSlots: _vm._u(
              [
                {
                  key: "step-1",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          "\n                Deploy your first configuration\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "step-2",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          "\n                Modify your configuration\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "step-3",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          "\n                Previously deployed configuration will be listed here and will be available for restore\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1930980004
            )
          })
        : _c("ConfsTable", {
            attrs: {
              configurations: _vm.visibleConfigurations,
              loading: _vm.equipmentconfsloading
            },
            on: {
              "view-configuration": _vm.viewConfiguration,
              "restore-configuration": _vm.restoreConfiguration
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }