<template>
    <div
        v-if="show_component"
        :class="$style.updated_section"
        class="row"
    >
        <div class="col">
            <div :class="$style.text_updated_sections">
                <h4 v-translate>
                    Some changes in this section are not yet deployed on the equipment.
                </h4>
                <Button
                    :class="$style.b1"
                    role="link"
                    :knowmore="true"
                    :disabled="disabled"
                    :text="$gettext('Restore previous conf')"
                    @click="restore_configuration"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '@/components/common/button.vue';
import {
    showSwal,
    showSwalError,
} from '@/utils/utils';
import {
    actionsControl,
} from './utils/utils';

export default {
    components: {
        Button,
    },
    props: {
        section: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'updated_sections',
        ]),
        show_component() {
            return this.updated_sections.includes(this.section);
        },
    },
    methods: {
        ...mapActions('equipment', [
            'restore_conf',
        ]),
        restore_configuration() {
            actionsControl(this.linkequipment).then(() => {
                showSwal({
                    title: this.$gettext('Restore the deployed configuration'),
                    type: 'warning',
                    confirmButtonText: this.$gettext('Restore configuration'),
                    cancelButtonText: this.$gettext('Cancel'),
                    showCancelButton: true,
                    showCloseButton: true,
                }).then((resultSwal) => {
                    if (!resultSwal.value) {
                        return;
                    }

                    this.restore_conf({
                        conf: this.linkequipment.deployed_conf,
                    }).then(() => {
                        showSwal({
                            title: this.$gettext('Success restore'),
                            type: 'success',
                        });
                    }).catch(showSwalError);
                });
            }).catch(showSwalError);
        },
    },
};
</script>

<style lang="scss" module>
.updated_section {
    margin-bottom: 30px;

    .text_updated_sections {
        padding: 30px 95px 30px 30px;
        text-align: left;
        border-radius: 4px;

        @include themify($themes) {
            background-color: rgba(themed('primary_color'), 0.1);
        }

        @include under-to(md) {
            padding: 30px 20px;
        }

        .link {
            justify-content: left;
        }
    }
}
</style>
