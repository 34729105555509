<template>
    <div
        :class="cssWrapperClasses"
        @click="handleClick"
        @mouseleave="handleMouseLeave"
        @mouseover="handleMouseOver"
    >
        <slot name="before" />

        <div :class="[$style.listHeaderContent, customContentClass]">
            <slot name="default" />
        </div>
        <slot name="after" />
    </div>
</template>

<script>
export default {
    name: 'ListHeader',
    props: {
        breakpoint: {
            type: String,
            default: 'sm',
            validator(value) {
                return ['xs', 'sm', 'md', 'lg', 'xl'].includes(value);
            },
        },
        customContentClass: {
            type: [String, Array, Object],
            default: null,
        },
        customWrapperClass: {
            type: [String, Array, Object],
            default: null,
        },
    },
    computed: {
        cssWrapperClasses() {
            const {
                bordered,
                breakpoint,
                highlighted,
                clickable,
                customWrapperClass,
                $style,
            } = this;
            return [
                $style.listHeader,
                {
                    [$style[breakpoint]]: breakpoint,
                    [$style.border]: bordered,
                    [$style.highlight]: highlighted,
                    [$style.clickable]: clickable,
                },
                customWrapperClass,
            ];
        },
    },
    methods: {
        handleClick(event) {
            if (!this.clickable) return;
            this.$emit('click', event);
        },
        handleMouseLeave(event) {
            this.$emit('mouseleave', event);
        },
        handleMouseOver(event) {
            this.$emit('mouseover', event);
        },
    },
};
</script>

<style lang="scss" module>
@import '@/assets/css/_skeleton.scss';

.listHeader {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 60px;
    padding: 0 10px;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    border: 1px solid transparent;
    border-radius: 4px;

    @include themify($themes) {
        color: themed('main_secondary_color');
    }

    & + & {
        margin-top: 15px;
    }

    .listHeaderContent {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 100%;
        min-width: 0;
        margin: 0 10px;

        & > * {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    @mixin resized {
        &.listHeader {
            display: none;
        }
    }

    &.xs {
        @include under-to(xs) {
            @include resized;
        }
    }

    &.sm {
        @include under-to(sm) {
            @include resized;
        }
    }

    &.md {
        @include under-to(md) {
            @include resized;
        }
    }

    &.lg {
        @include under-to(lg) {
            @include resized;
        }
    }

    &.xl {
        @include under-to(xl) {
            @include resized;
        }
    }
}
</style>
