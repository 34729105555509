<template>
    <Banner
        icon="solid-info"
        type="info"
        :can-be-closed="false"
    >
        <translate>
            Some changes in this section are not yet deployed on the equipment.
        </translate>
        <template v-slot:action>
            <Button
                role="link"
                :text="$gettext('Restore previous conf')"
                :disabled="disabled"
                size="large"
                :class="$style.b1"
                @click="$emit('restore-configuration')"
            />
        </template>
    </Banner>
</template>

<script>
import Banner from '@/components/common/banner.vue';
import Button from '@/components/common/button.vue';

export default {
    name: 'BannerUpdatedSection',
    components: {
        Banner,
        Button,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
