<template>
    <div
        v-if="ready"
        id="site"
    >
        <template v-if="connected">
            <Header v-if="!$route.query.hide_header" />
            <router-view />
        </template>
        <template v-else>
            <Connect />
        </template>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/header.vue';
import Connect from '@/components/connect.vue';

export default {
    name: 'Site',
    components: {
        Header,
        Connect,
    },
    data() {
        return {
            connected: false,
            ready: false,
        };
    },
    computed: {
        ...mapGetters([
            'permissions',
            'axios_api',
        ]),
    },
    created() {
        this.init_axios();
    },
    mounted() {
        this.axios_api.get(
            '/api/account/login/',
        ).then((response) => {
            this.connected = response.data.connected;
            this.ready = true;
            this.fetch_permissions();
        }).catch(() => {
            this.ready = true;
        });
    },
    methods: {
        ...mapActions([
            'init_axios',
            'fetch_permissions',
        ]),
    },
};

</script>

<style lang="scss" module>
:global(#site) {
    height: 100%;
    font-size: 14px;

    & > :global(.container) {
        min-height: 100%;
    }
}
</style>
