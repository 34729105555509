<template>
    <div>
        <Table
            :rows="rows"
            :columns="columns"
            :total-items="iplinks_count"
            :is-loading="iplinksloading"
            :sort-options="{
                initialSortBy: {field: 'link_id', type: 'asc'},
            }"
            :pagination-options="{
                position: 'top',
                perPageDropdown: [10, 20, 30, 40, 50],
            }"
            :search-options="{
                enabled: true,
            }"
            @load_data="loadData($event)"
        >
            <template v-slot:table-row="props">
                <span v-if="props.column.type == 'link'">
                    <router-link
                        tag="a"
                        :to="{
                            name: 'iplink',
                            params: {'link_id': props.row[props.column.field]}
                        }"
                        exact
                    >
                        {{ props.row[props.column.field] }}
                    </router-link>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </Table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Table from '@/components/common/table.vue';
import {
    showSwalError,
} from '@/utils/utils';

export default {
    components: {
        Table,
    },
    computed: {
        ...mapGetters('iplinks', [
            'iplinks',
            'iplinksloading',
            'iplinks_count',
        ]),
        rows() {
            return this.iplinks || [];
        },
        columns() {
            const columns = [];
            columns.push({
                label: this.$gettext('Link ID'),
                field: 'link_id',
                type: 'link',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('Partner'),
                field: '_endcustomersite._endcustomer._partner.name',
                sort_field: 'partner',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('End customer'),
                field: '_endcustomersite._endcustomer.name',
                sort_field: 'endcustomer',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('IP stack'),
                field: 'ipstack_display',
                sort_field: 'ipstack',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('IPv4 WAN address'),
                field: 'ipv4_wan_subnet',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('IPv6 WAN address'),
                field: 'ipv6_wan_subnet',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('IPv6 LAN address'),
                field: 'ipv6_lan_subnet',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });
            columns.push({
                label: this.$gettext('Link type'),
                field: 'link_type_display',
                sort_field: 'link_type',
                sortable: true,
                filterOptions: {
                    enabled: true,
                },
            });

            return columns;
        },
    },
    methods: {
        ...mapActions('iplinks', [
            'fetch_iplinks',
        ]),

        loadData(params) {
            const apiParams = {
                params: {
                    limit: params.limit,
                    offset: params.offset,
                    search: params.search,
                    link_id: params.columnFilters.link_id,
                    partner: params.columnFilters['_endcustomersite._endcustomer._partner.name'],
                    end_customer: params.columnFilters['_endcustomersite._endcustomer.name'],
                    ipstack: params.columnFilters.ipstack_display,
                    ipv4_wan_subnet: params.columnFilters.ipv4_wan_subnet,
                    ipv6_wan_subnet: params.columnFilters.ipv6_wan_subnet,
                    ipv6_lan_subnet: params.columnFilters.ipv6_lan_subnet,
                    link_type: params.columnFilters.link_type_display,
                },
            };

            if (params.sort.length) {
                apiParams.params.ordering = params.sortList;
            }

            this.fetch_iplinks(apiParams).catch(showSwalError);
        },
    },
};

</script>

<style lang="scss" module>

</style>
