<template>
    <div :class="$style.interfacesList">
        <div
            v-if="items.length"
            :class="$style.items"
        >
            <div
                v-for="(filteredItems, indexLabel) in itemsPerLabel"
                :key="`${indexLabel}-${filteredItems.length}`"
                :class="$style.itemsGrouped"
            >
                <span :class="$style.typeLabel">
                    {{ filteredItems[0].label }}
                </span>
                <InterfacesListItem
                    v-for="(item, index) in filteredItems"
                    :key="index"
                    v-bind="{...item}"
                    :interfaces-data="interfacesList"
                    :selected-port="selectedPort"
                />
            </div>
        </div>
        <PaginateVue
            v-if="pageNumber > 1"
            v-model="currentPage"
            :items-count="items.length"
            :items-per-page="itemsPerPage"
        />
        <InterfacesRouter
            class="d-none d-lg-flex"
            :interfaces="interfacesList"
            :used-interfaces="usedInterfaces"
            :equipment-data="equipmentData"
            :selected-port.sync="selectedPort"
            :loading="interfacesLoading"
            @refresh-interfaces="$emit('refresh-interfaces')"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PaginateVue from '@/components/common/paginate.vue';

import SpecificationsMixin from '../../mixins/specifications';

import InterfacesRouter from './general-interfaces-router.vue';
import InterfacesListItem from './general-interfaces-list-item.vue';

const ITEMS_PER_PAGE_DESKTOP = 4;
const ITEMS_PER_PAGE_MOBILE = 3;

export default {
    components: {
        InterfacesListItem,
        InterfacesRouter,
        PaginateVue,
    },
    mixins: [
        SpecificationsMixin,
    ],
    props: {
        items: {
            type: Array,
            required: true,
        },
        interfacesList: {
            type: Array,
            required: true,
        },
        equipmentData: {
            type: Object,
            required: true,
        },
        interfacesLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentPage: 0,
            selectedPort: null,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipment',
            'linkequipmentconf',
            'linkequipmentconfoptions',
            'virtual_iplink_members',
        ]),
        itemsPerPage() {
            if (this.$mq !== 'xs' && this.$mq !== 'sm') {
                return ITEMS_PER_PAGE_DESKTOP;
            }
            return ITEMS_PER_PAGE_MOBILE;
        },
        pageNumber() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
        visibleItems() {
            return this.items.slice(
                this.currentPage * this.itemsPerPage,
                (this.currentPage + 1) * this.itemsPerPage,
            );
        },
        equipmentInfos() {
            if (!this.linkequipmentconfoptions) {
                return {};
            }
            return this.linkequipmentconfoptions.equipments_informations.find(
                equipment => equipment.link_id === this.equipmentLinkId,
            ) || {};
        },
        usedInterfaces() {
            return this.items.map(item => item.interfaces).flat(1);
        },
        itemsPerLabel() {
            return this.visibleItems.reduce((groupedItems, item) => {
                (groupedItems[item.label] = groupedItems[item.label] || []).push(item);
                return groupedItems;
            }, {});
        },
    },
    watch: {
        linkequipment: {
            handler() {
                if (!this.linkequipment || this.equipmentLinkId) {
                    return;
                }
                this.equipmentLinkId = this.linkequipment.access;
            },
            deep: true,
            immediate: true,
        },
        virtual_iplink_members: {
            handler() {
                if (!this.virtual_iplink_members.length || !this.isVrrp) {
                    this.equipmentLinkId = this.linkequipment.access;
                    return;
                }
                this.equipmentLinkId = this.virtual_iplink_members[0].iplink;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions('equipment', [
            'set_conf_section',
            'fetch_interfaces',
        ]),
        changeselectedport(port) {
            if (this.selected_port === port.port_number) {
                this.selected_port = null;
            } else {
                this.selected_port = port.port_number;
            }
        },
        led_classes(iface) {
            if (!this.currentInterfacesList) {
                return null;
            }
            if (!iface.enabled) {
                return this.$style.port_down;
            }

            const interfaceFromRouter = this.currentInterfacesList.filter(
                item => item.port_number === iface.port_number,
            )[0];
            if (!('link_active' in interfaceFromRouter)) {
                return null;
            }
            if (interfaceFromRouter.link_active) {
                return this.$style.port_up;
            }
            return this.$style.port_warning;
        },
        displayPortNumber(iface) {
            let firstInterfaceNumber = 0;
            if (this.equipmentInfos && this.equipmentInfos.first_interface_number) {
                firstInterfaceNumber = this.equipmentInfos.first_interface_number;
            }

            return iface.port_number + firstInterfaceNumber;
        },
    },
};
</script>

<style lang="scss" module>
.interfacesList {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .typeLabel {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }

    .items,
    .itemsGrouped {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
    }
}
</style>
