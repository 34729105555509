import Vue from 'vue';

const state = {
    error_messages: [],
    success_messages: [],
    loading: false,
};

const getters = {
    error_messages: state => state.error_messages,
    success_messages: state => state.success_messages,
    loading: state => state.loading,
};

const mutations = {
    update_error_messages(state, errorMessages) {
        state.error_messages = errorMessages;
    },
    add_error_message(state, errorMessage) {
        state.error_messages.push(errorMessage);
    },
    update_success_messages(state, successMessages) {
        state.success_messages = successMessages;
    },
    add_success_message(state, successMessage) {
        state.success_messages.push(successMessage);
    },
    update_loading(state, loading) {
        state.loading = loading;
    },
};

const UNEXPECTED_ERROR = Vue.prototype.$gettext('An unexpected error happened');
const UNEXPECTED_ERROR_CALLBACK_OBJECT = {
    success: false,
    errors: {
        non_field_errors: [UNEXPECTED_ERROR],
    },
    error: UNEXPECTED_ERROR,
};

const actions = {
    async import_file({ commit, rootState }, params) {
        commit('update_error_messages', []);
        commit('update_success_messages', []);
        commit('update_loading', true);

        rootState.axios_api.put(
            '/api/legacy/nrafileupload/',
            params,
            {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=utf-8',
                    'Content-Disposition': 'attachment; filename="filename.csv"',
                },
            },
        ).then((success) => {
            commit('update_loading', false);
            success.data.success_messages.forEach((message) => {
                commit('add_success_message', {
                    key: 'success',
                    label: message,
                });
            });

            success.data.error_messages.forEach((message) => {
                commit('add_error_message', {
                    key: 'error',
                    label: message,
                });
            });
        }).catch((error) => {
            let errorLabel = '';

            switch (error.response.status) {
                case 412:
                    errorLabel = error.response.data.detail;
                    break;
                default:
                    errorLabel = error.response.statusText;
            }

            commit('update_loading', false);
            commit('add_error_message', {
                key: 'error',
                label: `${Vue.prototype.$gettext('Error when importing NRAs')} : ${errorLabel}`,
            });

            if (!params || !params.callback) {
                return;
            }

            params.callback(UNEXPECTED_ERROR_CALLBACK_OBJECT);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
