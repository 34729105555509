var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Fade", [
    _vm.show_current_banner
      ? _c(
          "div",
          {
            class: [
              _vm.$style.banner,
              _vm.$style[_vm.type],
              ((_obj = {}),
              (_obj[_vm.$style.background] = _vm.background),
              _obj)
            ]
          },
          [
            _vm.icon || _vm.$slots.svg
              ? _c(
                  "div",
                  {
                    class:
                      ((_obj$1 = {}),
                      (_obj$1[_vm.$style.small_icon] = _vm.smallIcon),
                      (_obj$1[_vm.$style.large_icon] = !_vm.smallIcon),
                      _obj$1)
                  },
                  [
                    _vm._t("svg", function() {
                      return [
                        _c("MasterSvg", {
                          attrs: {
                            use: _vm.icon,
                            width: _vm.mainWidthIcon,
                            height: _vm.mainHeightIcon
                          }
                        })
                      ]
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { class: _vm.$style.content }, [_vm._t("default")], 2),
            _vm._v(" "),
            _vm.haveAction
              ? _c("div", { class: _vm.$style.action }, [_vm._t("action")], 2)
              : _vm._e(),
            _vm._v(" "),
            _vm.canBeClosed
              ? _c("div", { class: _vm.$style.cross }, [
                  _c(
                    "span",
                    { on: { click: _vm.close } },
                    [
                      _c("MasterSvg", {
                        attrs: { use: "cross", width: "10px", height: "10px" }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }