var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.general },
    [
      _vm.alertsEquipment.length
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              _vm._l(_vm.alertsEquipment, function(alert, index) {
                return _c(
                  "Banner",
                  {
                    key: index,
                    attrs: {
                      icon: "solid-info",
                      type: "error",
                      "can-be-closed": false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action",
                          fn: function() {
                            return [
                              _c("Button", {
                                class: _vm.$style.b1,
                                attrs: {
                                  role: "link",
                                  text: alert.button.text,
                                  size: "large"
                                },
                                on: { click: alert.button.click }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(_vm._s(alert.message))])]
                )
              }),
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.readonly
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [_c("ReadonlyBanner")], 1)
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment.discharged && _vm.show_conf_template_banner
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "Banner",
                  {
                    attrs: {
                      id: "apply_template",
                      icon: "solid-info",
                      type: "info",
                      "can-be-closed": false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action",
                          fn: function() {
                            return [
                              _c("Button", {
                                class: _vm.$style.b1,
                                attrs: {
                                  role: "link",
                                  text: _vm.$gettext("Choose a template"),
                                  size: "large"
                                },
                                on: { click: _vm.showConfTemplateChoicesModal }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      772197544
                    )
                  },
                  [
                    _c("translate", { attrs: { tag: "b" } }, [
                      _vm._v(
                        "\n                    Available templates:\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("translate", [
                      _vm._v(
                        "\n                    You can use a previously saved template to help you configure this equipment.\n                "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment.discharged && _vm.show_change_equipment_banner
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "Banner",
                  {
                    attrs: {
                      id: "change_equipment",
                      icon: "solid-info",
                      type: "info",
                      "can-be-closed": false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action",
                          fn: function() {
                            return [
                              _c("Button", {
                                class: _vm.$style.b1,
                                attrs: {
                                  role: "link",
                                  text: _vm.$gettext("Change equipment"),
                                  size: "large"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showAttachModal(
                                      _vm.linkequipment
                                    )
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1449665859
                    )
                  },
                  [
                    _c("translate", { attrs: { tag: "b" } }, [
                      _vm._v(
                        "\n                    Equipment not recommended:\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.notRecommendedEquipmentReason
                      ? [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.notRecommendedEquipmentReason) +
                              "\n                "
                          )
                        ]
                      : [
                          _c("translate", [
                            _vm._v(
                              "\n                        The flow rate is not supported by this equipment.\n                    "
                            )
                          ])
                        ]
                  ],
                  2
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment.discharged && _vm.show_security_banner
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "Banner",
                  {
                    attrs: {
                      id: "security",
                      type: "help",
                      "small-icon": false,
                      icon: "ic-idea"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action",
                          fn: function() {
                            return [
                              _c("Button", {
                                class: _vm.$style.b1,
                                attrs: {
                                  role: "link",
                                  text: _vm.$gettext("Secure the installation"),
                                  size: "large"
                                },
                                on: { click: _vm.showSecurityModal }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3382595852
                    )
                  },
                  [
                    _c("translate", { attrs: { tag: "b" } }, [
                      _vm._v(
                        "\n                    Possible security:\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("translate", [
                      _vm._v(
                        "\n                    One or more additional access links are available on this site to enable you to secure this installation.\n                "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        : !_vm.linkequipment.discharged && _vm.show_eligibility_banner
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "Banner",
                  {
                    attrs: {
                      id: "eligibility",
                      type: "help",
                      "small-icon": false,
                      icon: "ic-idea"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "action",
                        fn: function() {
                          return [
                            _c("Button", {
                              class: _vm.$style.b1,
                              attrs: {
                                role: "link",
                                text: _vm.$gettext("Make eligibility"),
                                size: "large"
                              },
                              on: { click: _vm.redirectToEligibility }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("translate", { attrs: { tag: "b" } }, [
                      _vm._v(
                        "\n                    Securing:\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("translate", [
                      _vm._v(
                        "\n                    To secure your installation you must have a second access link.\n                "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment.discharged && _vm.missingWanConfiguration
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "Banner",
                  {
                    attrs: {
                      icon: "modem-4g",
                      type: "help",
                      "can-be-closed": "",
                      "main-width-icon": "40px",
                      "main-height-icon": "40px",
                      "small-icon": false
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action",
                          fn: function() {
                            return [
                              _c("Button", {
                                class: _vm.$style.b1,
                                attrs: {
                                  role: "link",
                                  text: _vm.$gettext("Attach equipment"),
                                  size: "large"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "show-attach-backup-modem-modal"
                                    )
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2529526901
                    )
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ],
                        class: _vm.$style.b4gTitle
                      },
                      [
                        _vm._v(
                          "\n                    Backup 4G is waiting for deployment\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("translate", [
                      _vm._v(
                        "\n                    To deploy 4G backup, you need to attach a compatible device and then deploy your device's configuration.\n                "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isVrrp
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { class: _vm.$style.box },
                [_c("GeneralEquipmentSpecs")],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.virtual_iplink_members && !_vm.isVrrp
        ? _c(
            "div",
            { staticClass: "row d-block d-lg-flex flex-lg-row" },
            _vm._l(_vm.virtual_iplink_members, function(member, index) {
              return _c(
                "div",
                { key: index, staticClass: "col" },
                [_c("IplinkMember", { attrs: { "iplink-member": member } })],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVrrp
        ? _c("VRRPMemberList", {
            staticClass: "row d-block d-lg-flex flex-lg-row",
            on: {
              "deploy-configuration": _vm.handleDeployConfiguration,
              "show-deploy-configuration-modal":
                _vm.handleShowDeployConfigurationModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment.discharged
        ? _c(
            "div",
            {
              staticClass: "row d-block",
              class: {
                "d-lg-flex": _vm.iplink.mpls,
                "d-md-flex": !_vm.iplink.mpls
              }
            },
            [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style.connectedDevices,
                      _vm.$style.box,
                      _vm.$style.stat,
                      _vm.$style.clickable
                    ],
                    on: {
                      click: function($event) {
                        return _vm.change_section("connectedDevices")
                      }
                    }
                  },
                  [
                    _c("div", { class: _vm.$style.value }, [
                      _c("h1", [_vm._v(_vm._s(_vm.connectedDevices))])
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.body1 }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.connectedDevicesText) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.connectedDevicesTable && _vm.connectedDevicesTable[0]
                      ? _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.connectedDevicesTable[0]
                                      .last_synchronization_date,
                                  expression:
                                    "connectedDevicesTable[0].last_synchronization_date"
                                }
                              ],
                              class: _vm.$style.b1
                            },
                            [
                              _c("translate", [_vm._v("Last synchro:")]),
                              _vm._v(" "),
                              _c("span", { class: _vm.$style.c1 }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        _vm.connectedDevicesTable[0]
                                          .last_synchronization_date
                                      )
                                    ) +
                                    "\n                        "
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.connectedDevicesTable[0]
                                    .last_synchronization_date,
                                  expression:
                                    "!connectedDevicesTable[0].last_synchronization_date"
                                },
                                { name: "translate", rawName: "v-translate" }
                              ],
                              class: _vm.$style.b1
                            },
                            [
                              _vm._v(
                                "\n                        Never synchronized\n                    "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    class: [
                      _vm.$style.active_ports,
                      _vm.$style.box,
                      _vm.$style.stat
                    ]
                  },
                  [
                    _c("div", { class: _vm.$style.value }, [
                      _c("h1", [_vm._v(_vm._s(_vm.active_ports))])
                    ]),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.body1 }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.active_ports_text) +
                          "\n                "
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.iplink.mpls
                ? _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$style.configuration_mpls,
                          _vm.$style.box,
                          _vm.$style.stat,
                          _vm.$style.clickable
                        ],
                        on: {
                          click: function($event) {
                            return _vm.showMplsRulesModal()
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { class: [_vm.$style.value, _vm.$style.good] },
                          [
                            _c(
                              "h1",
                              {
                                directives: [
                                  { name: "translate", rawName: "v-translate" }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n                        VPN MPLS\n                    "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "translate",
                                rawName: "v-translate",
                                value: { count: _vm.active_mplsrules },
                                expression: "{count: active_mplsrules}"
                              }
                            ],
                            class: _vm.$style.body1,
                            attrs: {
                              "translate-n": _vm.active_mplsrules,
                              "translate-plural": "%{count} active rules"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    %{count} active rule\n                "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment.discharged && _vm.has_capability("interfaces")
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { class: _vm.$style.box },
                [
                  _c("GeneralPorts", {
                    on: {
                      "deploy-configuration": _vm.handleDeployConfiguration
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.linkequipment.discharged && _vm.linkequipmentconf
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { class: _vm.$style.box }, [_c("GeneralInterfaces")], 1)
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 col-lg-6 col-sm-12" }, [
          _c(
            "div",
            {
              class: [
                _vm.$style.last_config,
                _vm.$style.box,
                _vm.$style.textbox
              ]
            },
            [
              _c("div", { class: _vm.$style.title }, [
                _c(
                  "h3",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                        Last configuration deployed\n                    "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.linkequipment.last_synchronization_date
                ? _c("div", [
                    _c(
                      "div",
                      { class: [_vm.$style.text, _vm.$style.body1] },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.linkequipment.last_synchronization_by) +
                            "\n                        "
                        ),
                        _c("translate", [_vm._v("on")]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("moment")(
                                _vm.linkequipment.last_synchronization_date
                              )
                            ) +
                            "\n                    "
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { class: _vm.$style.comment }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.linkequipment.last_synchronization_comment
                          ) +
                          "\n                    "
                      )
                    ])
                  ])
                : _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          { name: "translate", rawName: "v-translate" }
                        ],
                        class: [_vm.$style.text, _vm.$style.body1]
                      },
                      [
                        _vm._v(
                          "\n                        Configuration has not yet been deployed\n                    "
                        )
                      ]
                    )
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.$style.config_comments,
                _vm.$style.box,
                _vm.$style.textbox
              ]
            },
            [
              _c("div", { class: _vm.$style.title }, [
                _c(
                  "h3",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                        Comment attached to equipment\n                    "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.$style.body1 },
                [
                  _c("span", { staticClass: "d-inline" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.linkequipment.comment ||
                            _vm.$gettext("No comment")
                        ) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.linkequipment.discharged
                    ? _c("Button", {
                        staticClass: "d-inline-flex",
                        attrs: { role: "link", text: _vm.$gettext("(modify)") },
                        on: {
                          click: function($event) {
                            return _vm.showCommentModal()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.linkequipment.comment,
                      expression: "linkequipment.comment"
                    }
                  ],
                  staticClass: "mt-auto",
                  class: _vm.$style.b1
                },
                [
                  _c("translate", [_vm._v("Commented by:")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { class: [_vm.$style.c1, _vm.$style.commented_by] },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.linkequipment.commented_by || "unyc") +
                          "\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ],
                      class: [_vm.$style.c1, _vm.$style.commented_by]
                    },
                    [
                      _vm._v(
                        "\n                        on\n                    "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { class: [_vm.$style.c1, _vm.$style.commented_by] },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("moment")(
                              _vm.linkequipment.comment_creation_date
                            )
                          ) +
                          "\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12 col-lg-6 col-sm-12" }, [
          _c(
            "div",
            { class: [_vm.$style.box, _vm.$style.previous_confs] },
            [_c("Confs")],
            1
          )
        ])
      ]),
      _vm._v(" "),
      (_vm.has_capability("admin_credentials") || _vm.can_manage_router) &&
      _vm.canActivateAdminAccount
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [_c("Admin")], 1)
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }