var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.section_port, "row"] },
    [
      _vm.linkequipment_port
        ? _c("ComponentSpecifications", {
            staticClass: "col-12 mb-4",
            attrs: {
              specifications: _vm.lanSpecifications,
              "image-src":
                _vm.linkequipment_port._equipment._model.image || null
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.linkequipment_port._equipment._model._vendor
                                .name
                            ) +
                            "\n                " +
                            _vm._s(
                              _vm.linkequipment_port._equipment._model.name
                            ) +
                            "\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1983875937
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVrrp
        ? _c("div", { staticClass: "col-12 mt-4 mb-4" }, [
            _c(
              "span",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                class: _vm.$style.body8
              },
              [_vm._v("\n            Configuration\n        ")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isVrrp
        ? [
            _vm.canIPv4
              ? _c("Field", {
                  staticClass: "col-12 mb-4 col-md-6",
                  class: _vm.$style.margin_field,
                  attrs: {
                    errors: _vm.physicalIpv4AddressErrors,
                    label: _vm.$gettext("Physical IPv4 address"),
                    required: true,
                    name: "physical_ipv4_address"
                  },
                  model: {
                    value: _vm.physicalIpv4Address,
                    callback: function($$v) {
                      _vm.physicalIpv4Address = $$v
                    },
                    expression: "physicalIpv4Address"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.canIPv6
              ? _c("Field", {
                  staticClass: "col-12 mb-4 col-md-6",
                  class: _vm.$style.margin_field,
                  attrs: {
                    errors: _vm.physicalIpv6AddressErrors,
                    label: _vm.$gettext("Physical IPv6 address"),
                    name: "physical_ipv6_address"
                  },
                  model: {
                    value: _vm.physicalIpv6Address,
                    callback: function($$v) {
                      _vm.physicalIpv6Address = $$v
                    },
                    expression: "physicalIpv6Address"
                  }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 mt-4 mb-4" }, [
        _c(
          "span",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            class: _vm.$style.body8
          },
          [_vm._v("\n            Ports\n        ")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("LanItemInterfaces", {
            attrs: {
              lan: _vm.lan,
              "index-lan": _vm.indexLan,
              "lan-equipment-configurations": _vm.lanEquipmentConfigurations,
              "selected-interfaces": _vm.selectedInterfaces,
              type: _vm.type_interfaces,
              "can-trunk": _vm.can_trunk,
              "equipment-link-id": _vm.equipmentLinkId
            }
          }),
          _vm._v(" "),
          _vm.formErrors.interfaces
            ? _c(
                "div",
                { class: _vm.$style.errors },
                _vm._l(_vm.formErrors.interfaces, function(error, index) {
                  return _c("div", { key: index, class: _vm.$style.error }, [
                    _vm._v(
                      "\n                " + _vm._s(error) + "\n            "
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }