<template>
    <div class="row">
        <div :class="[$style.lanIpAddressTitle, 'col-12', 'mb-4', 'mt-4']">
            <span :class="$style.body8">
                IPv{{ ipVersion }}
            </span>
            <Badge
                v-if="ipVersion === 6"
                :type="slaac.enabled ? 'success' : 'grey'"
            >
                SLAAC
            </Badge>
            <Badge :type="dhcp.enabled ? 'success' : 'grey'">
                DHCP
            </Badge>
        </div>
        <Field
            v-model="addressing.ip"
            :errors="errors.ip"
            :label="$gettext('IP address')"
            :required="true"
            :class="$style.margin_field"
            class="col-12 col-lg-6"
            name="gateway"
        />
        <Select
            v-model="addressing.netmask"
            :disabled="!canUpdateNetmask"
            :errors="errors.netmask"
            :data-list="netmasks"
            :label="$gettext('Netmask')"
            :required="true"
            :class="$style.margin_field"
            class="col-12 col-lg-6"
            name="netmask"
        />
        <Choice
            v-if="!isVrrp"
            v-model="useSecondaryLan"
            name="useSecondaryLan"
            :label="$gettext('Use secondary subnet')"
            class="col-12 col-lg-5 mb-3"
            type="checkbox"
        />
        <div class="w-100  mb-3" />
        <template v-if="useSecondaryLan && !isVrrp">
            <Field
                v-model="addressing.secondary_ip"
                :errors="errors.secondary_ip"
                :label="$gettext('Secondary IP Address')"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="secondaryIP"
            />
            <Select
                v-model="addressing.secondary_netmask"
                :disabled="!canUpdateNetmask"
                :errors="errors.secondary_netmask"
                :data-list="netmasks"
                :label="$gettext('Secondary Netmask')"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="secondaryNetmask"
            />
        </template>
    </div>
</template>

<script>
import Badge from '@/components/common/badge.vue';
import Field from '@/components/common/field.vue';
import Choice from '@/components/common/choice.vue';
import Select from '@/components/common/select.vue';

export default {
    components: {
        Badge,
        Field,
        Choice,
        Select,
    },
    props: {
        ipVersion: {
            type: Number,
            required: true,
        },
        addressing: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => {},
        },
        netmasks: {
            type: Array,
            default: () => [],
        },
        defaultNetmask: {
            type: Number,
            default: 0,
        },
        canUpdateNetmask: {
            type: Boolean,
            default: false,
        },
        isVrrp: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        previousSecondaryIp: null,
        useSecondaryLan: false,
    }),
    computed: {
        dhcp() {
            return this.addressing.dhcp || {};
        },
        slaac() {
            return this.addressing.slaac || {};
        },
    },
    watch: {
        addressing: {
            handler() {
                if (!this.addressing) {
                    return;
                }

                if (this.addressing.secondary_ip && !this.isVrrp) {
                    this.useSecondaryLan = true;
                }
            },
            deep: true,
            immediate: true,
        },
        useSecondaryLan(value) {
            if (JSON.stringify(this.addressing || {}) === JSON.stringify({})) return;

            if (value && !this.addressing.secondary_netmask) {
                if (this.previousSecondaryIp) {
                    this.addressing.secondary_ip = this.previousSecondaryIp;
                }
                this.addressing.secondary_netmask = this.defaultNetmask;
            }
            if (!value) {
                this.previousSecondaryIp = this.addressing.secondary_ip;
                this.addressing.secondary_ip = null;
                this.addressing.secondary_netmask = null;
            }
        },
    },
};
</script>

<style lang="scss" module>
.lanIpAddressTitle {
    display: flex;
    gap: 15px;
}

.margin_field {
    margin-bottom: 32px;
}
</style>
