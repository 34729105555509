import Vue from 'vue';

const state = {
    selected_grid_type: 'selling',
    selected_import_scope: 'indirect_selling',
    starting_grid_date: Vue.moment().subtract(-1, 'days').format('YYYY-MM-DD').toString(),
    error_messages: [],
    success_messages: [],
    loading: false,
};

const getters = {
    selected_grid_type: state => state.selected_grid_type,
    selected_import_scope: state => state.selected_import_scope,
    starting_grid_date: state => state.starting_grid_date,
    error_messages: state => state.error_messages,
    success_messages: state => state.success_messages,
    loading: state => state.loading,
};

const mutations = {
    update_selected_grid_type(state, selectedScopeImport) {
        state.selected_grid_type = selectedScopeImport;
    },
    update_selected_import_scope(state, selectedGridType) {
        state.selected_import_scope = selectedGridType;
    },
    update_starting_grid_date(state, selectedSartingGridDate) {
        state.starting_grid_date = selectedSartingGridDate;
    },
    update_error_messages(state, errorMessages) {
        state.error_messages = errorMessages;
    },
    add_error_message(state, errorMessage) {
        state.error_messages.push(errorMessage);
    },
    update_success_messages(state, successMessages) {
        state.success_messages = successMessages;
    },
    add_success_message(state, successMessage) {
        state.success_messages.push(successMessage);
    },
    update_loading(state, loading) {
        state.loading = loading;
    },
};

function setErrorServer(error) {
    return {
        type: 'error_server',
        status: error.request.status,
        errors: error.response.data,
    };
}

const actions = {
    import_file({ commit, rootState }, params) {
        return new Promise((resolve, reject) => {
            commit('update_error_messages', []);
            commit('update_success_messages', []);
            commit('update_loading', true);

            const url = '/api/legacy/gridratefileupload/';

            rootState.axios_api.put(
                url,
                params,
            )
                .then((success) => {
                    success.data.success_messages.forEach((message) => {
                        commit('add_success_message', {
                            key: 'success',
                            label: message,
                        });
                    });
                    resolve(success);
                })
                .catch((error) => {
                    reject(setErrorServer(error));
                })
                .finally(() => {
                    commit('update_loading', false);
                });
        });
    },
    async export_grids({
        commit,
        rootState,
    }) {
        commit('update_loading', true);
        const fileDownload = require('js-file-download'); // eslint-disable-line
        return new Promise((resolve, reject) => {
            rootState.axios_api.get('/api/legacy/gridrateforexport/')
                .then((response) => {
                    commit('update_loading', false);
                    fileDownload(response.data, 'export_current_grid.csv');
                    resolve(response);
                }).catch((response) => {
                    commit('update_loading', false);
                    reject(response);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
