<template>
    <div :class="$style.addressrangelist">
        <div
            v-show="visible_addresses.length"
            :class="$style.addresses"
        >
            <AddressRangeItem
                v-for="(address, index) in visible_addresses"
                :key="index"
                :address="address"
                :disabled="disabled"
                :errors="(errors && errors[index]) || {}"
                @remove="$emit('removeAddressRange', index)"
            />
        </div>
        <div :class="$style.bottom">
            <PaginateVue
                v-if="nb_pages > 1"
                v-model="current_page"
                :items-count="addresses.length"
                :items-per-page="page_size"
            />
        </div>
        <div
            v-show="!visible_addresses.length"
            :class="[$style.empty, $style.body1]"
        >
            <div>
                <span v-translate>
                    There is no address range,
                </span>
                &nbsp;
                <span
                    v-translate
                    :class="[$style.textButton, {[$style.disabled]: disabled}]"
                    @click="handleAddButton"
                >
                    Add a first range
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import PaginateVue from '@/components/common/paginate.vue';
import AddressRangeItem from './lan-item-address-range-list-item.vue';

const PAGE_SIZE_DESKTOP = 3;
const PAGE_SIZE_MOBILE = 3;

export default {
    components: {
        AddressRangeItem,
        PaginateVue,
    },
    props: {
        addresses: {
            type: Array,
            required: true,
        },
        errors: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            current_page: 0,
        };
    },
    computed: {
        page_size() {
            if (this.$mq !== 'xs' && this.$mq !== 'sm' && this.$mq !== 'md') {
                return PAGE_SIZE_DESKTOP;
            }
            return PAGE_SIZE_MOBILE;
        },
        nb_pages() {
            return Math.ceil(this.addresses.length / this.page_size);
        },
        visible_addresses() {
            if (this.current_page > (this.nb_pages - 1)) {
                this.setPage(this.nb_pages - 1);
            }

            return this.addresses.slice(
                this.current_page * this.page_size,
                (this.current_page + 1) * this.page_size,
            );
        },
    },
    methods: {
        setPage(page) {
            if (page < 0) page = 0;
            this.current_page = page;
        },
        handleAddButton() {
            if (this.disabled) return;
            this.$emit('addFirstAddressRange');
        },
    },
};
</script>

<style lang="scss" module>
.addressrangelist {
    .empty {
        box-sizing: initial;
        display: block;
        flex-wrap: wrap;
        width: auto;
        padding: 26px;
        padding-left: 40px;
        margin: 10px -40px 10px -40px;
        text-align: left;
        border-radius: 0;

        @include themify($themes) {
            background-color: themed('gray_scale_light');
        }

        @include under-to(lg) {
            padding-left: 26px;
            margin-right: -20px;
            margin-left: -20px;
        }

        .textButton {
            text-decoration: underline;
            cursor: pointer;

            @include themify($themes) {
                color: themed("primary_color");
            }

            &.disabled {
                cursor: not-allowed;

                @include themify($themes) {
                    color: themed("grayscale_bold");
                }
            }
        }
    }

    .bottom {
        margin-top: 10px;
    }
}
</style>
