var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItemOption",
    {
      class: _vm.$style.dhcpConfiguration,
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_c("translate", [_vm._v("DHCP Configuration")])]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.$style.fieldsForm },
        [
          _c("Choice", {
            attrs: {
              disabled: _vm.disabled,
              "activate-label": _vm.$gettext("Enabled"),
              "deactivate-label": _vm.$gettext("Disabled"),
              name: "enable_dhcp",
              type: "switch"
            },
            model: {
              value: _vm.dhcp.enabled,
              callback: function($$v) {
                _vm.$set(_vm.dhcp, "enabled", $$v)
              },
              expression: "dhcp.enabled"
            }
          }),
          _vm._v(" "),
          _c("Select", {
            attrs: {
              disabled: _vm.disabled,
              errors: _vm.formErrors.type,
              "data-list": _vm.dhcp_type_choices,
              label: _vm.$gettext("Type"),
              required: true,
              name: "type"
            },
            model: {
              value: _vm.dhcp.type,
              callback: function($$v) {
                _vm.$set(_vm.dhcp, "type", $$v)
              },
              expression: "dhcp.type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.show_server_fields && _vm.ipVersion === 4
        ? _c("DnsConfiguration", {
            attrs: {
              disabled: _vm.disabled,
              "ip-version": _vm.ipVersion,
              "primary-dns": _vm.dhcp.primary_dns,
              "secondary-dns": _vm.dhcp.secondary_dns,
              errors: _vm.formErrors
            },
            on: {
              "update:primaryDns": function($event) {
                return _vm.$set(_vm.dhcp, "primary_dns", $event)
              },
              "update:primary-dns": function($event) {
                return _vm.$set(_vm.dhcp, "primary_dns", $event)
              },
              "update:secondaryDns": function($event) {
                return _vm.$set(_vm.dhcp, "secondary_dns", $event)
              },
              "update:secondary-dns": function($event) {
                return _vm.$set(_vm.dhcp, "secondary_dns", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_relay_fields
        ? _c(
            "div",
            { class: _vm.$style.body4 },
            [_c("translate", [_vm._v("\n            Relay server\n        ")])],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_relay_fields
        ? _c("Field", {
            attrs: {
              disabled: _vm.disabled,
              errors: _vm.formErrors.primary_relay_server_ip,
              label: _vm.$gettext("Primary relay server"),
              required: true,
              name: "primary_relay_server_ip"
            },
            model: {
              value: _vm.dhcp.primary_relay_server_ip,
              callback: function($$v) {
                _vm.$set(_vm.dhcp, "primary_relay_server_ip", $$v)
              },
              expression: "dhcp.primary_relay_server_ip"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_relay_fields
        ? _c("Field", {
            attrs: {
              disabled: _vm.disabled,
              errors: _vm.formErrors.secondary_relay_server_ip,
              label: _vm.$gettext("Secondary relay server"),
              name: "secondary_relay_server_ip"
            },
            model: {
              value: _vm.dhcp.secondary_relay_server_ip,
              callback: function($$v) {
                _vm.$set(_vm.dhcp, "secondary_relay_server_ip", $$v)
              },
              expression: "dhcp.secondary_relay_server_ip"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_server_fields
        ? _c(
            "div",
            { class: _vm.$style.body4 },
            [
              _c("translate", [
                _vm._v("\n            Lease duration\n        ")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_server_fields
        ? _c("Field", {
            class: _vm.$style.halfField,
            attrs: {
              disabled: _vm.disabled,
              errors: _vm.formErrors.lease_duration,
              label: _vm.$gettext("Lease duration"),
              name: "lease_duration"
            },
            model: {
              value: _vm.dhcp.lease_duration,
              callback: function($$v) {
                _vm.$set(_vm.dhcp, "lease_duration", $$v)
              },
              expression: "dhcp.lease_duration"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.show_server_fields
        ? _c(
            "div",
            { class: _vm.$style.subtitle },
            [
              _c("div", [
                _c(
                  "span",
                  { class: _vm.$style.body4 },
                  [
                    _c("translate", [_vm._v("Address ranges")]),
                    _vm._v(
                      "\n                (" +
                        _vm._s(_vm.max_dhcp_ranges) +
                        "/" +
                        _vm._s(_vm.nb_max_address_ranges_dhcp) +
                        ")\n            "
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("Button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.max_dhcp_ranges,
                    expression: "max_dhcp_ranges"
                  }
                ],
                class: [_vm.$style.b1, _vm.$style.additembutton],
                attrs: {
                  role: "link",
                  text: _vm.$gettext("Add address range"),
                  size: "large",
                  disabled:
                    _vm.max_dhcp_ranges >= _vm.nb_max_address_ranges_dhcp ||
                    _vm.disabled,
                  icon: "add"
                },
                on: {
                  click: function($event) {
                    return _vm.addAddressRange()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_server_fields
        ? _c("AddressRangeList", {
            attrs: {
              disabled: _vm.disabled,
              addresses: _vm.dhcp.address_ranges || [],
              errors: _vm.formErrors.address_ranges || []
            },
            on: {
              addFirstAddressRange: function($event) {
                return _vm.addAddressRange()
              },
              removeAddressRange: _vm.removeAddressRange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.formErrors.address_ranges_errors
        ? _c(
            "div",
            { class: _vm.$style.errors },
            _vm._l(_vm.formErrors.address_ranges_errors, function(
              error,
              index
            ) {
              return _c("div", { key: index, class: _vm.$style.error }, [
                _vm._v("\n            " + _vm._s(error) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_server_fields
        ? _c(
            "div",
            { class: _vm.$style.subtitle },
            [
              _c("div", [
                _c(
                  "span",
                  { class: _vm.$style.body4 },
                  [
                    _c("translate", [_vm._v("Static addresses")]),
                    _vm._v(
                      "\n                (" +
                        _vm._s(_vm.nb_static_addresses_dhcp) +
                        ")\n            "
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("Button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.nb_static_addresses_dhcp,
                    expression: "nb_static_addresses_dhcp"
                  }
                ],
                class: [_vm.$style.b1, _vm.$style.additembutton],
                attrs: {
                  role: "link",
                  disabled: _vm.disabled,
                  text: _vm.$gettext("Add static address"),
                  size: "large",
                  icon: "add"
                },
                on: {
                  click: function($event) {
                    return _vm.addStaticAddress()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_server_fields
        ? _c("StaticAddressList", {
            attrs: {
              disabled: _vm.disabled,
              addresses: _vm.dhcp.static_addresses || [],
              errors: _vm.formErrors.static_addresses || []
            },
            on: {
              addFirstStaticAddress: function($event) {
                return _vm.addStaticAddress()
              },
              removeStaticAddress: _vm.removeStaticAddress
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.formErrors.static_addresses_errors
        ? _c(
            "div",
            { class: _vm.$style.errors },
            _vm._l(_vm.formErrors.static_addresses_errors, function(
              error,
              index
            ) {
              return _c("div", { key: index, class: _vm.$style.error }, [
                _vm._v("\n            " + _vm._s(error) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }