<template>
    <div :class="$style.lanItemOption">
        <div :class="$style.body8">
            <slot name="title" />
        </div>
        <div :class="$style.form">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LanItemOption',
};
</script>

<style lang="scss" module>
.lanItemOption {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: inherit;

    .form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: inherit;
    }
}
</style>
