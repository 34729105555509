<template>
    <div :class="$style.section_general">
        <div class="row">
            <div class="col-12 mb-4">
                <span
                    v-translate
                    :class="$style.body7"
                >
                    Description
                </span>
            </div>
            <Field
                v-model="dmz.name"
                :errors="formErrors.name"
                :label="$gettext('Name')"
                :required="true"
                class="col-12 col-lg-6"
                name="name"
            />
        </div>
        <div class="row">
            <div class="col-12 mt-4 mb-4">
                <span
                    v-translate
                    :class="$style.body7"
                >
                    General
                </span>
            </div>
            <Field
                v-model="dmz.ip"
                :errors="formErrors.ip"
                :label="$gettext('IP address')"
                :required="true"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="ip"
            />
            <Field
                v-model="dmz.subnet"
                :errors="formErrors.subnet"
                :label="$gettext('Subnet')"
                :required="true"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="subnet"
                :disabled="true"
            />
            <Select
                v-if="exit_wan_choices.length > 1"
                v-model="dmz.main_exit_wan"
                :errors="formErrors.main_exit_wan"
                :data-list="exit_wan_choices"
                :label="$gettext('Priority WAN exit')"
                :required="true"
                :class="$style.margin_field"
                class="col-12 col-lg-6"
                name="main_exit_wan"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Field from '@/components/common/field.vue';
import Select from '@/components/common/select.vue';

export default {
    components: {
        Field,
        Select,
    },
    props: {
        dmz: {
            type: Object,
            default: null,
        },
        errors: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconfoptions',
        ]),
        formErrors() {
            return this.errors || {};
        },
        exit_wan_choices() {
            const choices = [];

            if (this.linkequipmentconfoptions && this.linkequipmentconfoptions.actions) {
                this.linkequipmentconfoptions.actions.PUT.dmz
                    .child.children.main_exit_wan.choices.forEach((item) => {
                        choices.push({
                            id: item.value,
                            text: item.display_name,
                        });
                    });
            }

            return choices;
        },
    },
    watch: {
        dmz: {
            handler() {
                if (this.exit_wan_choices.length === 1 && !this.dmz.main_exit_wan) {
                    this.dmz.main_exit_wan = this.exit_wan_choices[0].id;
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" module>
.section_general {
    background-color: inherit;

    .margin_field {
        margin-bottom: 32px;
    }
}
</style>
