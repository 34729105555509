import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('equipment', [
            'iplink',
            'linkequipment',
        ]),
        isVrrp() {
            return this.linkequipment._access.redundancy === 'vrrp';
        },
        isMpls() {
            return this.iplink.mpls;
        },
        isMultiwan() {
            return this.linkequipment._access.redundancy === 'multiwan';
        },
        canIPv4() {
            return ['dualstack', 'ipv4'].some(type => type === this.iplink.ipstack);
        },
        canIPv6() {
            return ['dualstack', 'ipv6'].some(type => type === this.iplink.ipstack);
        },
        validIPVersions() {
            const validIPVersions = [];
            if (this.canIPv4) {
                validIPVersions.push(4);
            }
            if (this.canIPv6) {
                validIPVersions.push(6);
            }
            return validIPVersions;
        },
    },
};
