<template>
    <div
        v-if="pageCount > 1"
        :class="$style.paginate"
    >
        <paginate
            v-model="internalPage"
            :page-count="pageCount"
            :prev-text="$gettext('Prev')"
            :prev-link-class="$style.page_link"
            :container-class="$style.pages"
            :next-text="$gettext('Next')"
            :next-link-class="$style.page_link"
            :disabled-class="$style.disabled"
            :active-class="$style.active"
            :page-range="pageRange"
        />
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate';
import Vue from 'vue';

Vue.component('paginate', Paginate);

export default {
    props: {
        itemsCount: {
            type: Number,
            default: 10,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        pageRange: {
            type: Number,
            default: 10,
        },
        value: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    computed: {
        pageCount() {
            return Math.ceil(this.itemsCount / this.itemsPerPage);
        },
        internalPage: {
            get() {
                return this.value + 1;
            },
            set(value) {
                this.$emit('input', value - 1);
                this.$emit('pagination-update', value - 1);
            },
        },
    },
    watch: {
        pageCount() {
            if (this.pageCount < this.internalPage) {
                this.internalPage = this.pageCount ? this.pageCount : 1;
            }
        },
    },
};
</script>

<style lang="scss" module>
.paginate {
    .pages {
        display: flex;
        gap: 15px;
        justify-content: flex-start;
        font-size: 13px;

        & > * {
            display: inline-block;
            flex-direction: row;
            align-items: flex-start;
        }

        :global(a) {
            outline: none;
        }

        :global(li) {
            padding: 2px 0;
        }

        .page_link {
            color: black;
            text-decoration: underline;

            &:hover {
                @include themify($themes) {
                    color: themed('primary_color');
                }
            }
        }

        .disabled {
            :global(a) {
                color: #666;
                text-decoration: none;
                pointer-events: none;
            }
        }

        .active {
            position: relative;
            margin: 0 5px;
            font-weight: bold;
            cursor: default;

            @include themify($themes) {
                color: themed('primary_color');
            }

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 24px;
                height: 24px;
                content: '';
                border-radius: 50%;
                transform: translate(-50%, -50%);

                @include themify($themes) {
                    border: 1px solid themed('primary_color');
                }
            }
        }
    }
}
</style>
