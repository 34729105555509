var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container pt-4" },
    [
      _c("Select", {
        staticClass: "offset-0 col-12 offset-md-2 col-md-8 bg-white",
        attrs: {
          "data-list": _vm.widgets,
          required: true,
          label: _vm.$gettext("Select a Widget"),
          name: "widget"
        },
        model: {
          value: _vm.valueWidgetChosed,
          callback: function($$v) {
            _vm.valueWidgetChosed = $$v
          },
          expression: "valueWidgetChosed"
        }
      }),
      _vm._v(" "),
      _vm.widgetChosed
        ? _c("Widget", {
            attrs: {
              "init-method-name": _vm.widgetChosed.initMethodName,
              params: _vm.widgetChosed.params,
              "widget-name": _vm.valueWidgetChosed
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }