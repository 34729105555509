<template>
    <div :class="$style.prettyselect">
        <div
            v-click-outside="focusOut"
            :class="$style.selectwrapper"
            tabindex="-1"
            @click.prevent="toggleOptions"
        >
            <Field
                :name="name"
                :label="label"
                :value="inputValue.text"
                :class="{[$style.placeholder]: inputValue.id === null}"
                :disabled="disabled"
                :errors="errors"
                :required="required"
                :type="`select_${role}`"
                :info-tooltips="infoTooltips"
                :info="info"
                :readonly="true"
                @navigationUp="navigationUp"
                @navigationDown="navigationDown"
                @navigationEnter="navigationEnter"
            />
            <div
                v-show="showOptions"
                ref="options"
                :class="[
                    $style.options,
                    $style[role],
                    {[customScrollbarClassName]: customScrollbarClassName},
                ]"
                :style="`max-height: ${resultsMaxHeight}`"
            >
                <div
                    v-for="(data, index) in dataList"
                    :ref="data === inputValue ? 'selectedOptions' : null"
                    :key="data.id"
                    :class="[
                        $style.option,
                        {
                            [$style.selected]: data === inputValue,
                            [$style.hover]: navigationIndex === index,
                            [$style.disabled]: data.disabled
                        }
                    ]"
                    @click="chooseOption(data)"
                >
                    <span>{{ data.text }}</span>
                    <span
                        v-if="data.subtext"
                        :class="$style.subtext"
                    >
                        {{ data.subtext }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Field from './field.vue';

export default {
    name: 'Select',
    components: {
        Field,
    },
    directives: {
        'click-outside': {
            bind(el, binding) {
                const { bubble } = binding.modifiers;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;

                document.addEventListener('click', handler);
            },
            unbind(el) {
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    },
    props: {
        role: {
            type: String,
            default: 'large',
            validator(value) {
                return [
                    'large',
                    'small',
                ].indexOf(value) !== -1;
            },
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        dataList: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number, Boolean],
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        errors: {
            type: Array,
            default: (() => null),
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        infoTooltips: {
            type: String,
            default: '',
        },
        info: {
            type: String,
            default: '',
        },
        resultsMaxHeight: {
            type: String,
            default: null,
        },
        customScrollbarClassName: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            showOptions: false,
            inputValue: {
                id: '',
                text: '',
            },
            navigationIndex: -1,
        };
    },
    watch: {
        dataList: {
            handler() {
                this.dataList.forEach((element) => {
                    if (element.id === this.value || element.selected) {
                        this.inputValue = element;
                    }
                });
            },
            immediate: true,
            deep: true,
        },
        value() {
            const item = this.dataList.find(
                element => element.id === this.value,
            );

            this.inputValue = item || { id: null, text: '' };
        },
        inputValue: {
            handler(value) {
                this.$emit('input', value.id);
            },
            deep: true,
        },
        showOptions(value) {
            const { selectedOptions } = this.$refs;
            if (value && selectedOptions) {
                this.$nextTick(() => {
                    this.$refs.options.scrollTop = selectedOptions[0].offsetTop;
                });
            }
        },
    },
    created() {
        this.dataList.forEach((element) => {
            if (element.id === this.value) {
                this.inputValue = element;
            }
        });
    },
    methods: {
        adaptScroll() {
            if (this.dataList && this.dataList.length > 0) {
                const elemHeight = this.$refs.options.scrollHeight / this.dataList.length;
                this.$refs.options.scrollTop = elemHeight * (this.navigationIndex - 1);
            }
        },
        toggleOptions() {
            if (this.disabled) {
                return;
            }

            this.navigationIndex = this.dataList.indexOf(this.inputValue);
            this.showOptions = !this.showOptions;
        },
        focusOut() {
            const self = this;

            window.setTimeout(() => {
                self.showOptions = false;
            }, 100);
        },
        chooseOption(choice) {
            this.inputValue = choice;
            this.focusOut();
        },
        navigationUp() {
            this.navigationIndex -= 1;

            if (this.navigationIndex < 0) {
                this.navigationIndex = this.dataList.length - 1;
            }

            this.adaptScroll();
        },
        navigationDown() {
            this.navigationIndex += 1;

            if (this.navigationIndex >= this.dataList.length) {
                this.navigationIndex = 0;
            }

            this.adaptScroll();
        },
        navigationEnter() {
            this.inputValue = this.dataList[this.navigationIndex];
            this.showOptions = false;
        },
    },
};
</script>

<style lang="scss" module>
.prettyselect {
    background-color: inherit;

    .selectwrapper {
        position: relative;
        background-color: inherit;

        .placeholder {
            input {
                @include themify($themes) {
                    color: themed('grayscale_semi') !important;
                }
            }
        }

        .options {
            position: absolute;
            right: 0;
            left: 0;
            z-index: 10000;
            max-height: 200px;
            margin-left: 0;
            overflow: auto;
            background-color: white;
            border-radius: 4px;
            -webkit-overflow-scrolling: touch;

            @include themify($themes) {
                box-shadow: 0 0 15px 0 rgba(themed('primary_color'), 0.2);
            }

            &.large {
                top: 46px;

                .option {
                    padding: 15px 20px;
                }
            }

            &.small {
                top: 30px;

                .option {
                    padding: 10px 20px;
                }
            }
        }

        .option {
            display: flex;
            flex-direction: column;
            color: black;
            cursor: pointer;
            border-left: 2px solid transparent;

            &.disabled {
                @include themify($themes) {
                    color: themed('grayscale_medium');
                }
            }

            &.selected {
                @include themify($themes) {
                    border-color: themed('primary_color');
                }
            }

            &:hover,
            &.hover {
                border-color: transparent;
            }

            &:hover,
            &.hover,
            &.selected {
                font-weight: 500;
                color: black;

                @include themify($themes) {
                    background-color: rgba(themed('primary_color'), 0.05);
                }

                .subtext {
                    font-weight: 500;
                }
            }

            .subtext {
                font-size: 12px;
                font-weight: 300;

                @include themify($themes) {
                    color: themed('grayscale_semi');
                }
            }
        }
    }
}
</style>
