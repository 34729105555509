<template>
    <div
        class="row p-4 mb-3 mx-1 mx-xl-0"
        :class="[
            $style.firewall_listitem,
            $style.item_store,
            {[$style.disabled]: firewallrule.nat_uuid}
        ]"
    >
        <div class="px-xl-0 col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none mb-1"
                :class="[$style.body2, $style.title]"
            >
                Rule name
            </div>
            <div
                class="flex-grow-1"
                :class="[$style.c1, $style.bold, $style.rule_name]"
            >
                {{ firewallrule.name }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none mt-2 mb-1"
                :class="[$style.body2, $style.title]"
            >
                Protocol
            </div>
            <div :class="[$style.c1]">
                {{ protocol }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none mt-2 mb-1"
                :class="[$style.body2, $style.title]"
            >
                IP / Subnet source
            </div>
            <div
                v-if="firewallrule.source_ips.length > 1"
                :class="[$style.c1, $style.list_ips]"
            >
                <translate>
                    Multiple
                </translate>
                <span>
                    ({{ firewallrule.source_ips.length }})
                </span>
                <div
                    :class="$style.flyinginfo"
                    :data-content="firewallrule.source_ips | displayIPsList"
                >
                    <MasterSvg use="info" />
                </div>
            </div>
            <div
                v-else
                :class="$style.c1"
            >
                <div>
                    {{ firewallrule.source_ips[0] }}
                </div>
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none mt-2 mb-1"
                :class="[$style.body2, $style.title]"
            >
                Destination IP
            </div>
            <div :class="$style.c1">
                {{ firewallrule.destination_ip }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-2 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none mt-2 mb-1"
                :class="[$style.body2, $style.title]"
            >
                Port
            </div>
            <div
                v-if="firewallrule.nat_uuid"
                :class="$style.c1"
            >
                {{ get_nat_public_port() }} > {{ firewallrule.ports }}
            </div>
            <div
                v-else
                :class="$style.c1"
            >
                {{ firewallrule.ports || '*' }}
            </div>
        </div>
        <div class="col-12 pb-1 col-xl-1 pb-xl-0 align-self-center">
            <div
                v-translate
                class="d-xl-none mb-1"
                :class="[$style.body2, $style.title]"
            >
                State
            </div>
            <div
                :class="$style.flyinginfo"
                :data-content="firewallrule | statusRule"
            >
                <Choice
                    v-model="firewallrule.enabled"
                    :disabled="!!firewallrule.nat_uuid"
                    name="enable_rule"
                    type="switchButton"
                    class="mt-1 mt-xl-0"
                    @click="$emit('toggle_activation')"
                />
            </div>
        </div>
        <div
            v-if="!firewallrule.nat_uuid"
            class="
                px-xl-0 offset-0 mt-4 col-xl-1 m-xl-0 d-xl-flex
                justify-content-xl-end align-self-center"
            :class="[$style.bold, $style.actions]"
        >
            <div
                class="d-none d-xl-flex"
                :class="[$style.svg, $style.flyinginfo]"
                :data-content="$gettext('Edit Firewall rule')"
                @click="$emit('edit_rule')"
            >
                <MasterSvg
                    use="underline-pen"
                    width="20px"
                    height="20px"
                />
            </div>
            <div
                class="d-xl-none"
                @click="$emit('edit_rule')"
            >
                <translate>
                    Edit Firewall rule
                </translate>
            </div>
            <div
                class="d-none ml-3 d-xl-flex"
                :class="[$style.svg, $style.flyinginfo]"
                :data-content="$gettext('Delete Firewall rule')"
                @click="$emit('delete_rule')"
            >
                <MasterSvg
                    use="trash-bin"
                    width="20px"
                    height="20px"
                />
            </div>
            <div
                class="mt-3 d-xl-none"
                @click="$emit('delete_rule')"
            >
                <translate>
                    Delete Firewall rule
                </translate>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Choice from '@/components/common/choice.vue';
import MasterSvg from './master-svg.vue';

const NUMBER_OF_DISPLAYED_IPS = 5;

export default {
    components: {
        MasterSvg,
        Choice,
    },
    filters: {
        displayIPsList(ipsList) {
            let textReturn = '';
            const others = ipsList.length - NUMBER_OF_DISPLAYED_IPS;

            if (ipsList.length > 0) {
                ipsList.forEach((ip, index) => {
                    if (index >= NUMBER_OF_DISPLAYED_IPS) {
                        if (index === NUMBER_OF_DISPLAYED_IPS) {
                            const translated = Vue.prototype.$ngettext(
                                '+ %{ n } other', '+ %{ n } others', others,
                            );
                            const interpolated = Vue.prototype.$gettextInterpolate(
                                translated, { n: others },
                            );
                            textReturn += interpolated;
                        }
                    } else {
                        textReturn += `${ip}\u000A`;
                    }
                });
            }

            return textReturn;
        },
        statusRule(rule) {
            if (rule.enabled) {
                return Vue.prototype.$gettext('Enabled');
            }
            return Vue.prototype.$gettext('Disabled');
        },
    },
    props: {
        firewallrule: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            numberOfDisplayedIps: NUMBER_OF_DISPLAYED_IPS,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconfoptions',
            'linkequipmentconf',
        ]),
        protocol() {
            let protocol = '';

            if (this.linkequipmentconfoptions && this.linkequipmentconfoptions.actions) {
                const protocols = this.linkequipmentconfoptions.actions.PUT.firewall
                    .child.children.protocol.choices;

                protocol = protocols.filter(
                    item => item.value === this.firewallrule.protocol,
                )[0].display_name;
            }

            return protocol;
        },
    },
    methods: {
        get_nat_public_port() {
            if (!this.firewallrule.nat_uuid) {
                return '';
            }

            const natRule = this.linkequipmentconf.nat.find(
                rule => rule.uuid === this.firewallrule.nat_uuid,
            );

            return natRule.public_destination.ports;
        },
    },
};
</script>

<style lang="scss" module>
.firewall_listitem {
    display: flex;
    border-radius: 4px;

    .rule_name {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .prettyswitchText {
        @include respond-from(xl) {
            position: absolute;
            transform: translateY(-50%);
        }
    }

    .flyinginfo {
        position: relative;
        display: flex;
        width: fit-content;

        &:before {
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 5;
            display: none;
            width: 10px;
            height: 10px;
            margin-top: 2px;
            content: " ";
            background: white;
            transform: rotate(45deg) translate(-25%, 25%);
        }

        &:after {
            position: absolute;
            top: calc(100% + 7px);
            left: 50%;
            z-index: 4;
            display: none;
            width: max-content;
            width: intrinsic;
            padding: 5px 20px;
            font-size: 12px;
            font-style: normal;
            font-weight: normal;
            line-height: 1.57;
            color: black;
            text-align: center;
            white-space: pre;
            content: attr(data-content);
            background-color: white;
            border-radius: 4px;
            transition: 0.3s ease all;
            transform: translateX(-50%);

            @include themify($themes) {
                box-shadow: 0 0 10px 0 rgba(themed('primary_color'), 0.2);
            }
        }

        &:hover {
            &:after,
            &:before {
                display: block;
                transition: 0.3s ease all;
            }
        }
    }

    .list_ips {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        & > * {
            margin-right: 2px;
        }

        svg {
            width: 16px;
            height: 16px;

            @include themify($themes) {
                fill: themed('primary_color');
            }
        }

        &:hover .flyinginfo:after,
        &:hover .flyinginfo:before {
            display: block;
            transition: 0.3s ease all;
        }
    }

    &.item_store {
        transition: 0.3s ease all;
        animation: fade-up 0.3s ease-out;

        @include themify($themes) {
            box-shadow: 0 0 8px 0 rgba(themed('secondary_color'), 0.3);
        }

        &:hover {
            @include themify($themes) {
                box-shadow: 0 0 8px 0 rgba(themed('secondary_color'), 0.6);
            }
        }
    }

    &.item_store.disabled {
        @include themify($themes) {
            background-color: themed('gray_scale_light');
        }

        @include themify($themes) {
            box-shadow: 0 0 8px 0 themed('gray_scale_light');
        }

        &:hover {
            @include themify($themes) {
                box-shadow: 0 0 8px 0 themed('gray_scale_light');
            }
        }
    }

    .title {
        color: #A0A0A0;
        text-transform: uppercase;
    }

    .bold {
        font-weight: bold !important;
    }

    .actions {
        width: auto;
        opacity: 0;

        @include themify($themes) {
            color: themed('primary_color');
            fill: themed('primary_color');
        }

        @include under-to(xl) {
            width: fit-content;
            cursor: pointer;
            opacity: 1;
        }

        .flyinginfo:after {
            padding: 5px 20px;
            color: black;
        }

        .svg {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    &.item_store:hover .actions {
        opacity: 1;
    }
}

.main {
    .firewall_listitem {
        @include under-to(xl) {
            .body2 {
                font-size: 12px;
                font-style: normal;
                font-weight: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
            }

            .c1 {
                margin-top: 5px;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                font-stretch: normal;
                line-height: 1.22;
                color: #000;
                letter-spacing: normal;
            }
        }
    }
}
</style>
