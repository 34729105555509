var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.wansList },
    [
      _vm._l(_vm.mainWans, function(wan, index) {
        return _c("ListItem", {
          key: wan.uuid,
          class: _vm.$style.wanItem,
          attrs: {
            "main-wan": wan,
            "backup-wans": _vm.backupsOfWan(wan.name),
            equipment: _vm.current_equipment,
            "index-wan": index,
            errors: _vm.errors.wans || [],
            interfaces: _vm.currentEquipmentConf.interfaces || [],
            "show-delete-button": _vm.mainWans.length > 1,
            "show-placeholder-backup": !_vm.checkConfigOfBackupExists(wan.name),
            "readonly-mode": _vm.readonlyMode
          },
          on: {
            "add-modem": function($event) {
              return _vm.showAttachModal(wan.name, $event)
            }
          }
        })
      }),
      _vm._v(" "),
      _vm.errors.non_field_errors
        ? _c(
            "div",
            { class: [_vm.$style.globalErrors, _vm.$style.errors] },
            _vm._l(_vm.errors.non_field_errors, function(error, index) {
              return _c("div", { key: index, class: _vm.$style.error }, [
                _vm._v("\n            " + _vm._s(error) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }