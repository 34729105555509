<template>
    <div
        :class="[
            $style.generalInterfacesListItem,
            {
                [$style.selected]: interfaces.includes(selectedPort),
                [$style.addIndent]: indent != 0,
            }
        ]"
        :style="`--indentValue: ${indent}0px`"
    >
        <div :class="$style.informationBlock">
            <div :class="$style.name">
                {{ name }}
            </div>
            <OneOrMore :values="formattedInterfaces">
                <template
                    slot="multiple-value"
                    slot-scope="{count}"
                >
                    <span v-translate="{count}">Switch %{count}</span>
                </template>
                <template #empty-value>
                    <translate>No port attached</translate>
                </template>
            </OneOrMore>
        </div>
        <div
            v-for="addressing in addressingList"
            :key="`${name}_${addressing.ipVersion}`"
            :class="$style.informationBlock"
        >
            <div :class="$style.subnet">
                <span>{{ addressing.ipAddress }}</span>
                <Tooltip
                    v-if="addressing.secondaryIpAddress"
                    :content="getSecondaryIpAddress(addressing)"
                >
                    <MasterSvg use="info" />
                </Tooltip>
            </div>
            <div :class="$style.tagsList">
                <TagStatus
                    v-if="addressing.dhcp && addressing.ipVersion === 6"
                    :active="getSlaacStatus(addressing)"
                >
                    SLAAC
                </TagStatus>
                <TagStatus
                    v-if="addressing.dhcp"
                    :active="getDhcpStatus(addressing)"
                >
                    DHCP
                </TagStatus>
            </div>
        </div>
        <div :class="$style.informationBlock">
            <span v-if="vlanId">VLAN {{ vlanId }}</span>
            <span
                v-else
                v-translate
            >
                Unavailable information
            </span>
        </div>
    </div>
</template>

<script>
import MasterSvg from '@/components/common/master-svg.vue';
import OneOrMore from '@/components/common/one-or-more.vue';
import Tooltip from '@/components/common/tooltip.vue';
import TagStatus from '@/components/common/tag-status.vue';

export default {
    components: {
        MasterSvg,
        OneOrMore,
        Tooltip,
        TagStatus,
    },
    props: {
        indent: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        interfaces: {
            type: Array,
            required: true,
        },
        vlanId: {
            type: Number,
            default: null,
        },
        addressingList: {
            type: Array,
            default: () => [],
        },
        interfacesData: {
            type: Array,
            required: true,
        },
        selectedPort: {
            type: Number,
            default: null,
        },
    },
    computed: {
        formattedInterfaces() {
            return this.interfaces.map(
                number => (
                    this.interfacesData[number] || { interface: number }
                ).name,
            );
        },
    },
    methods: {
        getSecondaryIpAddress(addressing) {
            if (addressing && addressing.secondaryIpAddress) {
                let textReturn = `${this.$gettext('Secondary IP:')}\u000A`;
                textReturn += addressing.secondaryIpAddress;
                return textReturn;
            }
            return '';
        },
        getDhcpStatus(addressing) {
            if (addressing && addressing.dhcp) {
                return addressing.dhcp.enabled;
            }
            return false;
        },
        getSlaacStatus(addressing) {
            if (addressing && addressing.slaac) {
                return addressing.slaac.enabled;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" module>
.generalInterfacesListItem {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 4px;

    @include themify($themes) {
        border: 1px solid themed('grayscale_extralight');
    }

    @include under-to(lg) {
        flex-direction: column;
        gap: 8px;
        padding-bottom: 20px;

        &:first-child {
            margin-top: 15px;
        }
    }

    &.selected {
        @include themify($themes) {
            box-shadow: 0 0 8px 0 rgba(themed('primary_color'), 0.4);
        }
    }

    &.addIndent {
        position: relative;
        margin-left: var(--indentValue);

        &:before {
            position: absolute;
            top: 0;
            left: calc(-1 * var(--indentValue));
            height: 100%;
            content: '';

            @include themify($themes) {
                border-left: 1px solid themed('grayscale_light');
            }
        }
    }

    & > * {
        flex: 1;
    }

    .informationBlock {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-self: flex-start;
        width: 100%;

        @include under-to(lg) {
            gap: 8px;
            align-items: center;
        }

        & > * {
            font-size: 14px;
            line-height: 1.43;

            &:not(:first-child) {
                font-weight: 300;

                @include themify($themes) {
                    color: themed('grayscale_bold');
                }
            }

            &.name {
                @include under-to(lg) {
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .subnet {
            display: flex;
            flex-direction: row;
            gap: 3px;
            text-align: center;

            svg {
                width: 16px;
                height: 16px;

                @include themify($themes) {
                    fill: themed('primary_color');
                }
            }
        }

        .tagsList {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
    }
}
</style>
