<template>
    <div :class="$style.snmpCommunitiesList">
        <SnmpCommunitiesListItem
            v-for="(community, index) in communities"
            :key="`community-${index + 1}`"
            :community="community"
            :errors="formErrors[index]"
            :readonly-mode="readonlyMode"
            @remove="$emit('remove', index)"
        />
    </div>
</template>

<script>
import SnmpCommunitiesListItem from './snmp-communities-list-item.vue';

export default {
    components: {
        SnmpCommunitiesListItem,
    },
    props: {
        formErrors: {
            type: Array,
            required: true,
        },
        communities: {
            type: Array,
            required: true,
        },
        readonlyMode: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
.snmpCommunitiesList {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
</style>
