<template>
    <div :class="$style.connectedDevicesList">
        <ListHeader
            v-if="visibleConnectedDevicesTableEntries.length"
            breakpoint="xl"
        >
            <span v-translate>MAC address</span>
            <span v-translate>Hostname</span>
            <span
                v-if="canIPv4"
                v-translate
            >
                IPv4 address
            </span>
            <span
                v-if="canIPv6"
                v-translate
            >
                IPv6 addresses
            </span>
            <span v-translate>Interface</span>
            <!-- Actions -->
            <template #after>
                <span :style="{ width: '14px' }" />
            </template>
        </ListHeader>
        <template v-if="connectedDevicesTableEntriesLoading">
            <ListItem
                v-for="index in page_size"
                :key="index"
                disabled
                :column-number="columnNumber"
                breakpoint="xl"
                :class="$style.oldListItem"
            />
        </template>
        <div
            v-show="
                !connectedDevicesTableEntriesLoading
                    && visibleConnectedDevicesTableEntries.length
            "
        >
            <ConnectedDevicesItem
                v-for="(connectedDevicesEntry, index) in visibleConnectedDevicesTableEntries"
                :key="index"
                :connected-device="connectedDevicesEntry"
                @create_nat_rule="createNatRule"
                @create_firewall_rule="createFirewallRule"
            />
        </div>
        <div :class="$style.bottom">
            <PaginateVue
                v-if="nb_pages > 1"
                v-model="current_page"
                :items-count="connectedDevicesTableEntriesEquipments.length"
                :items-per-page="page_size"
            />
        </div>
        <ComponentEmptySearch
            v-show="
                !connectedDevicesTableEntriesLoading
                    && !visibleConnectedDevicesTableEntries.length
            "
            size="small"
        >
            <template v-slot:title>
                <div v-translate>
                    No result found
                </div>
            </template>
            <template v-slot:text>
                <span
                    v-if="connectedDevicesTableEntries && connectedDevicesTableEntries.length"
                    v-translate
                >
                    Please try again with an other search
                </span>
                <span
                    v-else
                    v-translate
                >
                    Please connect a device to your equipment and refresh the list
                </span>
            </template>
        </ComponentEmptySearch>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
import PaginateVue from '@/components/common/paginate.vue';
import ListItem from '@/components/common/list-item.vue';
import ListHeader from '@/components/common/list-header.vue';
import ConnectedDevicesItem from './connected-devices-list-item.vue';
import ComponentEmptySearch from './component-empty-search.vue';

import SpecificationsMixin from './mixins/specifications';
import { internetToLanOption, mplsToLanOption } from './utils/firewall';

const AddNatRuleModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-addnatrule" */
    './modals/addnatrule.vue'
);

const AddFirewallRuleModal = () => import(
    /* webpackPreload: true */
    /* webpackChunkName: "equipment-addfirewallrule" */
    './modals/addfirewallrule.vue'
);

const PAGE_SIZE_DESKTOP = 10;
const PAGE_SIZE_MOBILE = 5;

export default {
    components: {
        ConnectedDevicesItem,
        PaginateVue,
        ListItem,
        ComponentEmptySearch,
        ListHeader,
    },
    mixins: [
        SpecificationsMixin,
    ],
    props: {
        equipmentLinkId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            current_page: 0,
            nb_pages: 0,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'capabilities',
            'connectedDevicesTable',
            'connectedDevicesTableEntries',
            'connectedDevicesTableEntriesLoading',
        ]),
        columnNumber() {
            let columnNumber = 3;
            if (this.canIPv4) columnNumber += 1;
            if (this.canIPv6) columnNumber += 1;
            return columnNumber;
        },
        page_size() {
            if (this.$mq !== 'xs' && this.$mq !== 'sm' && this.$mq !== 'md') {
                return PAGE_SIZE_DESKTOP;
            }
            return PAGE_SIZE_MOBILE;
        },
        visibleConnectedDevicesTableEntries() {
            if (!this.connectedDevicesTableEntries) {
                return [];
            }

            return this.connectedDevicesTableEntriesEquipments.slice(
                this.current_page * this.page_size,
                (this.current_page + 1) * this.page_size,
            );
        },
        connectedDevicesTableEntriesEquipments() {
            if (!this.connectedDevicesTableEntries) {
                return [];
            }

            return this.connectedDevicesTableEntries.filter(
                entry => entry.link_id === this.equipmentLinkId,
            );
        },
        canAddMultiIPsSourceOnNatRule() {
            let canAddMultiIPsSourceOnNatRule = true;

            this.capabilities.forEach((item) => {
                canAddMultiIPsSourceOnNatRule = canAddMultiIPsSourceOnNatRule && item.capabilities.includes('can_nat_multiple_source_ips');
            });

            return canAddMultiIPsSourceOnNatRule;
        },
    },
    watch: {
        nb_pages() {
            return Math.ceil(this.visibleConnectedDevicesTableEntries.length / this.page_size);
        },
        connectedDevicesTableEntriesEquipments(value) {
            this.nb_pages = Math.ceil(value.length / this.page_size);
        },
    },
    methods: {
        createNatRule(ipAddress) {
            const { canAddMultiIPsSourceOnNatRule } = this;
            this.show_modal(
                AddNatRuleModal,
                {
                    canAddMultiIPsSource: canAddMultiIPsSourceOnNatRule,
                    'default-rule': {
                        protocol: 'tcp_udp',
                        enabled: true,
                        lan_destination: { ip: ipAddress },
                    },
                },
                {
                    maxHeight: 702,
                },
            );
        },
        createFirewallRule(ipAddress) {
            const firewallOption = this.isMpls ? mplsToLanOption : internetToLanOption;
            this.show_modal(
                AddFirewallRuleModal,
                {
                    'default-rule': {
                        protocol: 'all',
                        enabled: true,
                        source_ips: ['::/0'],
                        destination_ip: ipAddress,
                        ip_version: 6,
                    },
                    group: firewallOption,
                },
                {
                    maxHeight: 702,
                },
            );
        },
    },
};
</script>

<style lang="scss" module>
.connectedDevicesList {
    .oldListItem {
        margin-top: 15px;
    }

    .bottom {
        margin-top: 10px;
    }
}

.main {
    .connectedDevicesList {
        .body2 {
            font-weight: normal;

            @include themify($themes) {
                color: themed("gray_scale_dark");
            }
        }
    }
}
</style>
