<template>
    <ListItem
        :class="$style.snmpCommunitiesListItem"
        breakpoint="md"
    >
        <Field
            v-model="community.name"
            :disabled="readonlyMode"
            :errors="errors.name"
            :label="$gettext('Community name')"
            name="name"
            required
        />
        <SubnetField
            v-model="authorizedSubnets"
            :disabled="readonlyMode"
            :errors="errors.authorized_subnets"
            :label="$gettext('Authorized subnets')"
            multiple-value
            :placeholder="$gettext('Optional')"
            name="authorizedSubnets"
        />
        <template #after>
            <DropdownMenu
                position="right"
                size="large"
            >
                <DropdownMenuItem
                    icon="trash-bin"
                    :name="$gettext('Delete SNMP community')"
                    color="red"
                    :disabled="readonlyMode"
                    @click="$emit('remove')"
                />
            </DropdownMenu>
        </template>
    </ListItem>
</template>

<script>
import ListItem from '@/components/common/list-item.vue';

import Field from '@/components/common/field.vue';
import SubnetField from '../../subnet-field.vue';

import DropdownMenu from '../../dropdown-menu.vue';
import DropdownMenuItem from '../../dropdown-menu-item.vue';

export default {
    components: {
        DropdownMenu,
        DropdownMenuItem,
        Field,
        ListItem,
        SubnetField,
    },
    props: {
        community: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
        readonlyMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        authorizedSubnets: {
            get() {
                return (this.community.authorized_subnets || []);
            },
            set(value) {
                this.$set(this.community, 'authorized_subnets', value);
            },
        },
    },
};
</script>

<style lang="scss" module>
.snmpCommunitiesListItem {
    max-height: none;
}
</style>
