<template>
    <div
        v-if="!canBeClosed || current_section_advice_visible"
        class="row"
        :class="$style.advicesection"
    >
        <div class="col">
            <div :class="$style.container">
                <Button
                    v-if="canBeClosed"
                    role="icon_secondary"
                    icon="cross"
                    :class="$style.closeadvice"
                    @click="hideCurrentSectionAdvice()"
                />
                <div :class="$style.advice_title">
                    <span>
                        {{ title }}
                    </span>
                    <span
                        v-if="subtitle"
                        :class="$style.subtitle"
                    >
                        {{ subtitle }}
                    </span>
                </div>
                <p :class="$style.content">
                    <slot name="text" />
                </p>
                <div
                    v-show="haveExample && showexample"
                    :class="$style.example"
                    class="mb-2"
                >
                    <slot name="example" />
                </div>

                <Button
                    v-show="haveExample && !showexample"
                    :class="[$style.showexample, $style.b1]"
                    class="mb-2"
                    role="link"
                    :text="$gettext('Show example')"
                    @click="showExample()"
                />

                <Button
                    v-if="linkUrl"
                    :class="[$style.know_more, $style.b1]"
                    role="link"
                    :knowmore="true"
                    :text="$gettext('Know more about it on the kiosque')"
                    @click="knowMore()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Button from '@/components/common/button.vue';

export default {
    components: {
        Button,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            default: '',
        },
        linkUrl: {
            type: String,
            default: null,
        },
        canBeClosed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showexample: false,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'current_section_advice_visible',
        ]),
        haveExample() {
            return this.$slots.example;
        },
    },
    methods: {
        ...mapMutations('equipment', [
            'hideCurrentSectionAdvice',
        ]),
        showExample() {
            this.showexample = true;
        },
        knowMore() {
            window.open(this.linkUrl, '_blank');
        },
    },
};
</script>

<style lang="scss" module>
.advicesection {
    margin-bottom: 30px;

    @include under-to(md) {
        margin-top: 20px;
    }

    .showexample {
        cursor: pointer;

        @include themify($themes) {
            color: themed('primary_color');
        }

        & .link {
            justify-content: left;
        }
    }

    .example {
        padding: 20px;
        margin-bottom: 20px;
        text-align: justify;
        border: 1px solid #E2E2E2;
        border-radius: 4px;

        @include themify($themes) {
            background-color: themed('gray_scale_light');
        }
    }

    .container {
        padding: 25px 75px 30px 30px;
        text-align: left;
        border-radius: 4px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        @include under-to(md) {
            padding: 30px 20px;
        }

        .content {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.57;
            text-align: justify;
        }

        .closeadvice {
            position: absolute;
            top: 15px;
            right: 35px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            border-radius: 15px;

            @include themify($themes) {
                background-color: rgba(themed('primary_color'), 0.1);
            }

            :global(svg) {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 10px;
                height: 10px;
                transform: translate(-50%, -50%);

                @include themify($themes) {
                    fill: themed('primary_color');
                }
            }
        }

        .advice_title {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.22;

            .subtitle {
                margin-top: 10px;
                font-size: 11px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 0.9px;

                @include themify($themes) {
                    color: themed('primary_color');
                }
            }
        }

        .know_more {
            @include themify($themes) {
                color: themed('primary_color');
            }

            display: flex;
            cursor: pointer;
        }
    }
}
</style>
