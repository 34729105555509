import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import './init-pluggins';
import router from '@/routers/ng';
import store from '@/stores/store';
import App from './app.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAJ7qCGBZVSUg7XmCkJko1DlSWJTu-6_aY',
        libraries: 'places',
    },
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
