import utils from '@/stores/utils';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {

    async load_shortcuts({ rootState }, params) {
        const url = `/api/dashboard/shortcuts/?section=${params.section}`;

        rootState.axios_api.get(url)
            .then((response) => {
                if (params && params.callback) {
                    params.callback({ success: true, data: response.data });
                }
            })
            .catch((xhrError) => {
                const error = utils.handleError(xhrError);
                if (params && params.callback) {
                    params.callback(error);
                }
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
