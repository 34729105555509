var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$style.section_port, "row"] },
    [
      _vm.linkequipment_port
        ? _c("ComponentSpecifications", {
            staticClass: "col-12 mb-4",
            attrs: {
              specifications: _vm.dmzSpecifications,
              "image-src":
                _vm.linkequipment_port._equipment._model.image || null
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.linkequipment_port._equipment._model._vendor
                                .name
                            ) +
                            "\n                " +
                            _vm._s(
                              _vm.linkequipment_port._equipment._model.name
                            ) +
                            "\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1983875937
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVrrp
        ? _c("div", { staticClass: "col-12 mt-4 mb-4" }, [
            _c(
              "span",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                class: _vm.$style.body8
              },
              [_vm._v("\n            Configuration\n        ")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isVrrp
        ? [
            _c("Field", {
              staticClass: "col-12 mb-4 col-lg-6",
              class: _vm.$style.margin_field,
              attrs: {
                errors: _vm.physicalIpAddressErrors,
                label: _vm.$gettext("Physical IP address"),
                required: true,
                name: "physical_ip_address"
              },
              model: {
                value: _vm.physicalIpAddress,
                callback: function($$v) {
                  _vm.physicalIpAddress = $$v
                },
                expression: "physicalIpAddress"
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 mt-4" }, [
        _c(
          "span",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            class: _vm.$style.body7
          },
          [_vm._v("\n            Ports\n        ")]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 mt-4" },
        [
          _c("DmzItemInterfaces", {
            attrs: {
              dmz: _vm.dmz,
              "index-dmz": _vm.indexDmz,
              "dmz-equipment-configurations": _vm.dmzEquipmentConfigurations,
              "selected-interfaces": _vm.selectedInterfaces,
              type: _vm.type_interfaces,
              "equipment-link-id": _vm.equipmentLinkId
            }
          }),
          _vm._v(" "),
          _vm.formErrors.interfaces
            ? _c(
                "div",
                { class: _vm.$style.errors },
                _vm._l(_vm.formErrors.interfaces, function(error, index) {
                  return _c("div", { key: index, class: _vm.$style.error }, [
                    _vm._v(
                      "\n                " + _vm._s(error) + "\n            "
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }