var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.focusOut,
          expression: "focusOut"
        }
      ],
      class: [
        _vm.$style.dropdownMenu,
        _vm.$style[_vm.color],
        _vm.$style[_vm.mode]
      ],
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleShow.apply(null, arguments)
        }
      }
    },
    [
      _vm._t("content", function() {
        return [
          _c("MasterSvg", {
            attrs: {
              "class-css": _vm.$style.dropdownMenuIcon,
              module: "equipment",
              use: "menu"
            }
          })
        ]
      }),
      _vm._v(" "),
      _c("div", { class: _vm.cssWrapperClasses }, [
        _c(
          "div",
          { class: _vm.$style.dropdownMenuItemsContent },
          [_vm._t("default")],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }