<template>
    <div
        v-if="linkequipmentconfoptions"
        :class="$style.dmziteminterfaces"
    >
        <template v-if="type === 'checkbox'">
            <Choice
                v-for="(choice, index) in interfaces_choices"
                :id="`iface-${equipmentLinkId}-${indexDmz}-${choice.value}`"
                :key="index"
                v-model="choice.checked"
                :class="[
                    $style.port,
                    {[$style.warning]: addWarning(choice)}
                ]"
                type="checkbox"
                :name="`iface-${equipmentLinkId}-${indexDmz}-${choice.value}-${choice.type}`"
                :label="choice.display_name"
                :disabled="choice.disabled"
                :info-tooltips="getInfoTooltips(choice)"
            />
        </template>
        <template v-if="type === 'radio'">
            <RadioButton
                v-model="selected_interface"
                :class="$style.interfaces"
                :choices="interfaces_choices"
                :custom-class="$style.port"
                :name="`iface-${equipmentLinkId}-${indexDmz}`"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Choice from '@/components/common/choice.vue';
import RadioButton from '@/components/common/radiobutton.vue';

export default {
    components: {
        Choice,
        RadioButton,
    },
    props: {
        dmz: {
            type: Object,
            default: null,
        },
        indexDmz: {
            type: Number,
            default: 0,
        },
        dmzEquipmentConfigurations: {
            type: Object,
            default: null,
        },
        selectedInterfaces: {
            type: Array,
            default: (() => []),
        },
        equipmentLinkId: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'checkbox',
            validator(value) {
                return [
                    'checkbox',
                    'radio',
                ].indexOf(value) !== -1;
            },
        },
    },
    data() {
        return {
            interfaces_choices: [],
            dmz_interfaces: this.dmzEquipmentConfigurations.interfaces || [],
            selected_interface: null,
        };
    },
    computed: {
        ...mapGetters('equipment', [
            'linkequipmentconf',
            'linkequipmentconfoptions',
        ]),
        equipmentInfos() {
            if (!this.linkequipmentconfoptions) {
                return {};
            }
            return this.linkequipmentconfoptions.equipments_informations.find(
                equipment => equipment.link_id === this.equipmentLinkId,
            ) || {};
        },
        equipmentPorts() {
            if (!(this.linkequipmentconf || {}).equipments) {
                return {};
            }
            const equipmentConf = this.linkequipmentconf.equipments.find(
                equipment => equipment.link_id === this.equipmentLinkId,
            ) || {};
            return equipmentConf.interfaces || {};
        },
    },
    watch: {
        equipmentPorts: {
            handler() {
                const activeInterfaces = this.dmz_interfaces;

                if (!this.equipmentPorts) {
                    return;
                }

                let firstInterfaceNumber = 0;
                if (this.equipmentInfos && this.equipmentInfos.first_interface_number) {
                    firstInterfaceNumber = this.equipmentInfos.first_interface_number;
                }

                this.interfaces_choices = [];
                [this.selected_interface] = activeInterfaces;
                this.equipmentPorts.forEach((choice) => {
                    const portNumber = choice.port_number + firstInterfaceNumber;
                    const active = activeInterfaces.find(
                        element => element === choice.port_number,
                    ) !== undefined;

                    this.interfaces_choices.push({
                        value: choice.port_number,
                        id: `iface${choice.port_number}`,
                        display_name: `${this.$gettext('Port')} ${portNumber}`,
                        port_number: choice.port_number,
                        checked: active,
                        type: choice.type,
                        disabled: this.disabledChoice(choice),
                        custom_class: {
                            [this.$style.warning]: this.addWarning(choice),
                        },
                        info_tooltips: this.getInfoTooltips(choice),
                    });
                });
            },
            deep: true,
            immediate: true,
        },
        interfaces_choices: {
            handler() {
                const interfaces = [];

                this.interfaces_choices.forEach((choice) => {
                    if (choice.checked) {
                        interfaces.push(choice.value);
                    }
                });

                this.dmzEquipmentConfigurations.interfaces = interfaces;
            },
            deep: true,
        },
        dmzEquipmentConfigurations: {
            handler() {
                if (!this.dmzEquipmentConfigurations.interfaces) {
                    this.dmzEquipmentConfigurations.interfaces = [];
                }

                if (JSON.stringify(this.dmz_interfaces) !== JSON.stringify(
                    this.dmzEquipmentConfigurations.interfaces,
                )) {
                    this.dmz_interfaces = this.dmzEquipmentConfigurations.interfaces;

                    if (this.interfaces_choices.length) {
                        this.interfaces_choices.forEach((iface) => {
                            iface.checked = false;
                        });

                        [this.selected_interface] = this.dmz_interfaces;
                        this.dmz_interfaces.forEach((value) => {
                            this.interfaces_choices.find(
                                iface => iface.value === value,
                            ).checked = true;
                        });
                    }
                }
            },
            deep: true,
        },
        selected_interface() {
            if (this.selected_interface == null || this.type !== 'radio') {
                return;
            }

            this.dmzEquipmentConfigurations.interfaces = [this.selected_interface];
        },
        selectedInterfaces: {
            handler(newValue, oldValue) {
                if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
                    return;
                }

                this.interfaces_choices.forEach((element) => {
                    element.custom_class = {
                        [this.$style.warning]: this.addWarning(element),
                    };
                    element.info_tooltips = this.getInfoTooltips(element);
                });
            },
            deep: true,
        },
    },
    methods: {
        disabledChoice(choice) {
            if (!(this.equipmentInfos.lan_interfaces || []).includes(choice.port_number)) {
                return true;
            }

            return this.disabled;
        },
        addWarning(choice) {
            const selectedInterfacesExcludeLocales = JSON.parse(JSON.stringify(
                this.selectedInterfaces,
            ));

            this.dmzEquipmentConfigurations.interfaces.forEach((iface) => {
                const index = selectedInterfacesExcludeLocales.indexOf(iface);

                if (index > -1) {
                    selectedInterfacesExcludeLocales.splice(index, 1);
                }
            });

            const iface = choice.port_number;
            if (selectedInterfacesExcludeLocales.includes(iface)) {
                return true;
            }

            return ['wan'].includes(choice.type);
        },
        getInfoTooltips(choice) {
            if (!this.addWarning(choice)) {
                return '';
            }
            return this.$gettext('This interface is already in use.\u000APlease choose another.');
        },
    },
};
</script>

<style lang="scss" module>
.dmziteminterfaces {
    margin: 0 -7px;

    .interfaces {
        flex-wrap: wrap;
    }

    .port {
        display: inline-block;
        width: 110px;
        margin: 7px;
        transition: 0.3s all;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    .warning {
        :global(label):before {
            @include themify($themes) {
                background-color: themed('warning_color');
            }
        }

        .prettycheckbox,
        &.inputwrapper_radio {
            @include themify($themes) {
                background-color: rgba(themed('warning_color'), 0.09);
            }

            &.selected {
                @include themify($themes) {
                    background-color: rgba(themed('warning_color'), 0.09);
                }
            }

            .prettyradio_label {
                :global(input[type="radio"]) {
                    &:checked {
                        &:before {
                            @include themify($themes) {
                                border: 2px solid themed('warning_color');
                            }
                        }

                        &:after {
                            @include themify($themes) {
                                background-color: themed('warning_color');
                            }
                        }
                    }
                }
            }
        }

        .checked {
            @include themify($themes) {
                background-color: rgba(themed('warning_color'), 0.09);
            }
        }
    }
}
</style>
