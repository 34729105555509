var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row",
      class: [
        _vm.$style.results,
        _vm.$style.empty,
        ((_obj = {
          "flex-column-reverse": _vm.size === "small"
        }),
        (_obj[_vm.$style.empty_trans] = _vm.size === "large"),
        _obj)
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.$style.result_text,
            {
              "col-xl-6 col-12": _vm.size === "large",
              "mt-4 col-8 col-lg-5": _vm.size === "small"
            }
          ]
        },
        [
          _c(
            "div",
            {
              staticClass: "col-12",
              class: [
                _vm.$style.no_result_title,
                {
                  "text-left": _vm.size === "large",
                  "mb-0 text-center": _vm.size === "small"
                }
              ]
            },
            [_vm._t("title")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-12",
              class: [
                _vm.$style.no_result_body,
                {
                  "mt-3 text-left": _vm.size === "large",
                  "mt-1 text-center": _vm.size === "small"
                }
              ]
            },
            [_vm._t("text")],
            2
          ),
          _vm._v(" "),
          _vm._t("action")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            _vm.$style.result_img,
            ((_obj$1 = {
              "col-xl-6 col-12": _vm.size === "large",
              "mt-3 col-8 col-lg-5 col-xl-4": _vm.size === "small"
            }),
            (_obj$1[_vm.$style.img_reverse] = _vm.size === "small"),
            _obj$1)
          ]
        },
        [_c("MasterSvg", { attrs: { use: "equipment" } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }