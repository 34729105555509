var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HeaderAdviceLayout",
    {
      attrs: {
        "advice-display": _vm.adviceDisplay,
        "advice-link-url":
          "https://kiosque.unyc.io/document/administration-des-routeurs/route-adr/"
      },
      on: {
        "update:adviceDisplay": function($event) {
          _vm.adviceDisplay = $event
        },
        "update:advice-display": function($event) {
          _vm.adviceDisplay = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "advice-title",
          fn: function() {
            return [_c("translate", [_vm._v("Routes")])]
          },
          proxy: true
        },
        {
          key: "advice-content",
          fn: function() {
            return [
              _c("translate", [
                _vm._v(
                  "\n            Entering a static route manually determines the path that a packet must take to reach a network B from a network A.\n        "
                )
              ])
            ]
          },
          proxy: true
        },
        {
          key: "advice-example",
          fn: function() {
            return [
              _c(
                "p",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [
                  _vm._v(
                    "\n            Exemple: You want packets destined for the 32.15.26.0/24 subnet to pass through the 192.168.0.62 IP address. You will need to set the following rule:\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                Destination IP: 32.15.26.0\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("\n                Netmask: /24\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [
                    _vm._v(
                      "\n                Gateway: 192.168.0.62\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("\n                Metric: 1\n            ")]
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _vm.isUpdated ? _c("BannerUpdatedSection") : _vm._e(),
      _vm._v(" "),
      _c(
        "TitleWithAdviceState",
        {
          scopedSlots: _vm._u([
            {
              key: "after",
              fn: function() {
                return [
                  _c("DesktopAndMobileButton", {
                    attrs: {
                      role: "primary",
                      icon: "add",
                      text: _vm.$gettext("Add route")
                    },
                    on: { click: _vm.addRoute }
                  })
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.adviceDisplay,
            callback: function($$v) {
              _vm.adviceDisplay = $$v
            },
            expression: "adviceDisplay"
          }
        },
        [_c("translate", [_vm._v("Routes")])],
        1
      ),
      _vm._v(" "),
      !_vm.routes.length
        ? _c("StepsList", {
            attrs: { image: "route" },
            scopedSlots: _vm._u(
              [
                {
                  key: "step-1",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          '\n                Click on "Add route"\n            '
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "step-2",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          "\n                Fill in all the fields correctly and validate by clicking on the button\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "step-3",
                  fn: function() {
                    return [
                      _c("translate", [
                        _vm._v(
                          "\n                Do not forget to deploy the configuration for equipment to take new changes\n            "
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2040617882
            )
          })
        : _c("RoutesTable", {
            attrs: {
              routes: _vm.routes,
              "selected-ip-version": _vm.selectedIpVersion
            },
            on: {
              "update:selectedIpVersion": function($event) {
                _vm.selectedIpVersion = $event
              },
              "update:selected-ip-version": function($event) {
                _vm.selectedIpVersion = $event
              },
              "edit-route": _vm.editRoute,
              "delete-route": _vm.deleteRoute
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }