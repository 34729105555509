<!-- eslint-disable max-len -->
<template>
    <div
        :class="$style.equipmentcontent"
        class="pt-2"
    >
        <Menu v-if="section == 'menu'" />
        <General
            v-if="section == 'general'"
            :can-activate-admin-account="canActivateAdminAccount"
            :readonly="readonly"
            :missing-wan-configuration="missingWanConfiguration"
            @deploy-configuration="handleDeployConfiguration"
            @show-deploy-configuration-modal="handleShowDeployConfigurationModal"
            @show-attach-backup-modem-modal="handleShowAttachBackupModemModal"
        />
        <Banner
            v-if="showDischargedBanner"
            icon="solid-info"
            type="warning"
            :can-be-closed="false"
        >
            <translate>
                The information below may not be up-to-date as the router as the router is in discharge mode and we do not have access to it.
            </translate>
        </Banner>
        <DMZ
            v-if="section == 'dmz'"
            @deploy-configuration="handleDeployConfiguration"
        />
        <Wans
            v-if="section == 'wan'"
            :readonly-mode="readonly || linkequipment.discharged"
            @deploy-configuration="handleDeployConfiguration"
        />
        <Lan
            v-if="section == 'lan'"
            @deploy-configuration="handleDeployConfiguration"
        />
        <NAT
            v-if="section == 'nat'"
            @deploy-configuration="handleDeployConfiguration"
        />
        <ConnectedDevices
            v-if="section == 'connectedDevices'"
            @deploy-configuration="handleDeployConfiguration"
        />
        <Routes
            v-if="section == 'routes'"
            @deploy-configuration="handleDeployConfiguration"
        />
        <Firewall
            v-if="section == 'firewall'"
            @deploy-configuration="handleDeployConfiguration"
        />
        <Confs
            v-if="section == 'confs'"
            @deploy-configuration="handleDeployConfiguration"
        />
        <Events
            v-if="section == 'events'"
            :scheduled-actions="scheduledActions"
        />
        <Advanced
            v-if="section == 'advanced'"
            :readonly-mode="readonly || linkequipment.discharged"
            @deploy-configuration="handleDeployConfiguration"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Banner from '@/components/common/banner.vue';

import Menu from './menu.vue';
import General from './general.vue';
import DMZ from './dmz.vue';
import Wans from './wans.vue';
import Lan from './lan.vue';
import NAT from './nat.vue';
import ConnectedDevices from './connected-devices.vue';
import Routes from './routes/routes.vue';
import Firewall from './firewall.vue';
import Confs from './configurations/confs.vue';
import Events from './events/events.vue';
import Advanced from './advanced/advanced.vue';

export default {
    components: {
        Banner,
        Menu,
        General,
        DMZ,
        Wans,
        Lan,
        NAT,
        ConnectedDevices,
        Routes,
        Firewall,
        Confs,
        Events,
        Advanced,
    },
    props: {
        canActivateAdminAccount: {
            type: Boolean,
            default: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        missingWanConfiguration: {
            type: Boolean,
            default: false,
        },
        scheduledActions: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters('equipment', [
            'section',
            'iplink',
            'linkequipment',
        ]),
        showDischargedBanner() {
            return (
                this.linkequipment.discharged
                && !['general', 'menu'].includes(this.section)
            );
        },
    },
    methods: {
        handleShowAttachBackupModemModal() {
            this.$emit('show-attach-backup-modem-modal');
        },
        handleDeployConfiguration(params) {
            this.$emit('deploy-configuration', params);
        },
        handleShowDeployConfigurationModal(params) {
            this.$emit('show-deploy-configuration-modal', params);
        },
    },
};
</script>

<style lang="scss" module>
.equipmentcontent {
    min-height: 500px;
}
</style>
