var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.wifilistitem }, [
    _c(
      "div",
      { class: _vm.$style.header },
      [
        _c("span", { class: ["mr-auto", _vm.$style.body6] }, [
          _vm._v("\n            " + _vm._s(_vm.name) + "\n        ")
        ]),
        _vm._v(" "),
        _vm.wifiIndex > 0
          ? _c("Button", {
              class: _vm.$style.b1,
              attrs: {
                role: "link",
                text: _vm.$gettext("Delete this SSID"),
                icon: "error",
                size: "large",
                disabled: _vm.readonlyMode
              },
              on: {
                click: function($event) {
                  return _vm.$emit("remove")
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("span", { class: _vm.$style.separate }),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              _vm.$style.hidearrow,
              ((_obj = {}), (_obj[_vm.$style.bottom] = _vm.collapsed), _obj)
            ],
            on: {
              click: function($event) {
                return _vm.toggleCollapse()
              }
            }
          },
          [_c("MasterSvg", { attrs: { use: "arrow", width: "20px" } })],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.collapsed,
            expression: "!collapsed"
          }
        ],
        class: _vm.$style.wifiItemContent
      },
      [
        _c(
          "span",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            class: _vm.$style.body8
          },
          [_vm._v("\n            State\n        ")]
        ),
        _vm._v(" "),
        _c("Choice", {
          class: [
            _vm.$style.enablewifi,
            ((_obj$1 = {}),
            (_obj$1[_vm.$style.newLine] = !_vm.canManageWps),
            _obj$1)
          ],
          attrs: {
            disabled: _vm.readonlyMode,
            "activate-label": _vm.$gettext("SSID enabled"),
            "deactivate-label": _vm.$gettext("SSID enabled"),
            name: "enableWifi",
            type: "switch"
          },
          model: {
            value: _vm.enabled,
            callback: function($$v) {
              _vm.enabled = $$v
            },
            expression: "enabled"
          }
        }),
        _vm._v(" "),
        _vm.canManageWps
          ? _c(
              "div",
              { class: [_vm.$style.wpsTitle, _vm.$style.body8] },
              [
                _c(
                  "span",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }]
                  },
                  [_vm._v("WPS")]
                ),
                _vm._v(" "),
                _c(
                  "Tooltip",
                  {
                    class: _vm.$style.icon,
                    attrs: {
                      content: _vm.$gettext(
                        "WPS can be enabled only for the primary SSID."
                      )
                    }
                  },
                  [
                    _c("MasterSvg", {
                      attrs: {
                        use: "ic-interrogation",
                        width: "18px",
                        height: "18px"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.canManageWps
          ? _c("Choice", {
              class: _vm.$style.enablewifi,
              attrs: {
                disabled: _vm.readonlyMode,
                "activate-label": _vm.$gettext("WPS enabled"),
                "deactivate-label": _vm.$gettext("WPS enabled"),
                name: "enableWPS",
                type: "switch"
              },
              model: {
                value: _vm.enabledWps,
                callback: function($$v) {
                  _vm.enabledWps = $$v
                },
                expression: "enabledWps"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("hr", { class: _vm.$style.line }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [{ name: "translate", rawName: "v-translate" }],
            class: _vm.$style.body8
          },
          [_vm._v("\n            Credentials\n        ")]
        ),
        _vm._v(" "),
        _c("Choice", {
          class: [_vm.$style.enablewifi, _vm.$style.newLine],
          attrs: {
            "activate-label": _vm.$gettext("Use custom values"),
            "deactivate-label": _vm.$gettext("Use custom values"),
            disabled: !_vm.canHaveDefaultCredentials || _vm.readonlyMode,
            name: "usePersonnalCredentials",
            type: "switch"
          },
          model: {
            value: _vm.usePersonnalCredentials,
            callback: function($$v) {
              _vm.usePersonnalCredentials = $$v
            },
            expression: "usePersonnalCredentials"
          }
        }),
        _vm._v(" "),
        _c("Field", {
          class: _vm.$style.newLine,
          attrs: {
            disabled: !_vm.usePersonnalCredentials || _vm.readonlyMode,
            errors: _vm.errors.ssid,
            label: _vm.$gettext("SSID"),
            placeholder: _vm.$gettext("Default SSID"),
            "info-tooltips": _vm.$gettext(
              "You can find the default SSID behind your equipment"
            ),
            name: "ssid"
          },
          model: {
            value: _vm.ssid,
            callback: function($$v) {
              _vm.ssid = $$v
            },
            expression: "ssid"
          }
        }),
        _vm._v(" "),
        _c("Select", {
          attrs: {
            disabled: !_vm.usePersonnalCredentials || _vm.readonlyMode,
            "data-list": _vm.encryptionTypes,
            errors: _vm.errors.encryption_type,
            required: _vm.usePersonnalCredentials,
            label: _vm.$gettext("Encryption type"),
            name: "encryptionType"
          },
          model: {
            value: _vm.encryptionType,
            callback: function($$v) {
              _vm.encryptionType = $$v
            },
            expression: "encryptionType"
          }
        }),
        _vm._v(" "),
        _c("Field", {
          attrs: {
            disabled: !_vm.usePersonnalCredentials || _vm.readonlyMode,
            errors: _vm.errors.key,
            label: _vm.$gettext("Key"),
            required: _vm.usePersonnalCredentials,
            "info-tooltips": _vm.infoDefaultKey,
            placeholder: _vm.keyPlaceholder,
            name: "key"
          },
          model: {
            value: _vm.key,
            callback: function($$v) {
              _vm.key = $$v
            },
            expression: "key"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }